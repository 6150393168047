import React, { useMemo, useState } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Typography,
  Button,
  Input,
  Tab,
  Tabs,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Autocomplete,
  TextField,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Formik, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { CustomTextInput, Paragraph, Title } from 'app/common/Typography';
import { DocIcon, DownloadDragIcon, DownloadIcon, PdfIcon } from 'app/common/icons';
import notify from 'app/utils/notify';
import { useProject } from 'app/contexts/ProjectContext';
import { handleAxiosError } from 'app/utils/helpers';
import { closingDocuments, contractDocuments, dueDiligence } from 'app/utils/constant';

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '4px',
  marginTop: '10px',
  border: '2px dashed lightgray',
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 0,
    backgroundColor: 'transparent',
  },
}));

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: '#F8F8F8',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '40vh',
  overflowY: 'auto',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
  flexGrow: 1,
});

const validationSchema = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().required('Document is required'),
      docName: Yup.string().required('Document Name is required'),
      type: Yup.string().required('Document Type is required'),
    }),
  ),
});

const LeadsDocumentDetails = () => {
  const { isOnHoldOrCancelled, documents, uploadDocuments, deleteDocument } = useProject();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loader, setLoader] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const initialDocuments = useMemo(
    () => documents.filter((doc) => doc.metadata.documentType.startsWith('application/')),
    [documents],
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getFilteredDocumentsByType = (documents) => {
    const types = [dueDiligence, contractDocuments, closingDocuments];
    return documents.filter((doc) => doc.metadata.type === types[selectedTab]);
  };

  const documentTypes = [
    { label: dueDiligence },
    { label: contractDocuments },
    { label: closingDocuments },
  ];

  const filteredDocuments = getFilteredDocumentsByType(initialDocuments);

  const handleSubmit = async (values, { setSubmitting, validateForm }) => {
    if (values.documents.length === 0) {
      notify.error('Please add at least one document.');
      setSubmitting(false);
      return;
    }

    const errors = await validateForm();

    if (Object.keys(errors).length > 0) {
      notify.error('Document name and type are required!');
      setSubmitting(false);
      return;
    }

    const data = new FormData();
    let docIndex = 0;

    values.documents.forEach((doc) => {
      data.append(`docReq[${docIndex}].file`, doc.file);
      data.append(`docReq[${docIndex}].docName`, doc.docName);
      data.append(`docReq[${docIndex}].type`, doc.type);
      docIndex++;
    });

    try {
      setLoader(true);
      await uploadDocuments(data);
      setOpenModal(false);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoader(false);
      setSubmitting(false);
    }
  };

  const handleOpenDeleteModal = (document) => {
    setSelectedDocument(document);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedDocument(null);
  };

  const handleDelete = async () => {
    try {
      setLoader(true);
      await deleteDocument(selectedDocument.metadata.fileId);
      handleCloseDeleteModal();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoader(false);
    }
  };

  const handleDownload = (content, fileName, mimeType) => {
    try {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const getFileIcon = (mimeType) => {
    switch (mimeType) {
      case 'application/pdf':
        return <PdfIcon />;
      case 'application/msword':
        return <DocIcon />;
      default:
        return <DocIcon />;
    }
  };

  const calculateSize = (content) => {
    const decodedContent = atob(content);
    const sizeInBytes = decodedContent.length;
    const sizeInKB = (sizeInBytes / 1024).toFixed(2);
    return `${sizeInKB} KB`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <SubtitleDivider />
      <Box
        marginLeft={3}
        marginRight={5}
        justifyContent={'space-between'}
        display={'flex'}
        alignItems={'center'}
      >
        <Title sx={{ marginLeft: '18px' }}>Documents</Title>
        <LoadingButton
          color='primary'
          variant='contained'
          sx={{ my: 2 }}
          style={{ boxShadow: 'none' }}
          onClick={() => setOpenModal(true)}
          disabled={isOnHoldOrCancelled}
        >
          + Add Document
        </LoadingButton>
      </Box>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label='document tabs'
        sx={{ marginLeft: 5, marginRight: 5, backgroundColor: '#EEF4FC' }}
      >
        <Tab label='Due Diligence' />
        <Tab label='Contract Docs' />
        <Tab label='Closing Docs' />
      </Tabs>

      <Box overflow='auto' margin={5} marginTop={3} border={'2px solid #E5E5E7'}>
        <ProductTable>
          <TableHead bgcolor='#F2F9FC'>
            <TableRow color='#64748B'>
              <TableCell sx={{ px: 2, color: '#64748B' }} colSpan={1}>
                S.NO.
              </TableCell>
              <TableCell sx={{ px: 0, color: '#64748B' }} colSpan={2}>
                DOCUMENT
              </TableCell>
              <TableCell sx={{ px: 0, color: '#64748B' }} colSpan={2}>
                SIZE
              </TableCell>
              <TableCell sx={{ px: 0, color: '#64748B' }} colSpan={3}>
                UPLOADED
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredDocuments.map((doc, index) => (
              <TableRow key={index} hover>
                <TableCell
                  align='left'
                  sx={{
                    px: 0,
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    fontSize: '14px',
                    color: '#272937',
                  }}
                >
                  {`${(index + 1).toString().padStart(2, '0')}`}
                </TableCell>

                <TableCell align='left' colSpan={2} sx={{ px: 0, textTransform: 'capitalize' }}>
                  <Box display='flex' alignItems='center'>
                    <Paragraph sx={{ fontWeight: 500, fontSize: '14px', color: '#272937' }}>
                      {doc.metadata.documentName}
                      <IconButton
                        onClick={() =>
                          handleDownload(
                            doc.content,
                            `${doc.metadata.documentName}`,
                            doc.metadata.documentType,
                          )
                        }
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Paragraph>
                    {/* {getFileIcon(doc.metadata.documentType)} */}
                  </Box>
                </TableCell>

                <TableCell
                  sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                  align='left'
                  colSpan={2}
                >
                  {calculateSize(doc.content)}
                </TableCell>

                <TableCell
                  sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                  align='left'
                  colSpan={2}
                >
                  {formatDate(doc.uploadDate)}
                </TableCell>

                <TableCell
                  sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                  align='left'
                  colSpan={1}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteModal(doc)}
                    disabled={isOnHoldOrCancelled}
                  >
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ProductTable>
      </Box>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '40%',
          }}
        >
          <Box
            sx={{
              p: 2,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Upload Documents</Title>
            <IconButton onClick={() => setOpenModal(false)}>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: '#272937',
                }}
              />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Formik
              initialValues={{
                documents: [],
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                validateForm,
              }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    validateForm().then((errors) => {
                      if (Object.keys(errors).length > 0) {
                        notify.error('Document name and type are required!');
                      } else {
                        handleSubmit(e);
                      }
                    });
                  }}
                >
                  <Container
                    onDragOver={(event) => {
                      event.preventDefault();
                    }}
                    onDrop={(event) => {
                      event.preventDefault();
                      const files = Array.from(event.dataTransfer.files);
                      files.forEach((file) => {
                        setFieldValue('documents', [
                          ...values.documents,
                          { file, docName: '', type: '' },
                        ]);
                      });
                    }}
                  >
                    <Typography variant='body1' color={'#64748B'} fontSize={'16px'}>
                      <DownloadDragIcon />
                      {` Drag and Drop file here or `}
                      <label htmlFor='outlined-button-file'>
                        <StyledButton variant='outlined' component='span'>
                          Browse files
                        </StyledButton>
                      </label>
                    </Typography>
                    <Input
                      id='outlined-button-file'
                      sx={{ display: 'none' }}
                      type='file'
                      size='small'
                      name='file'
                      onChange={(event) => {
                        const files = Array.from(event.target.files);
                        files.forEach((file) => {
                          setFieldValue('documents', [
                            ...values.documents,
                            { file, docName: '', type: '' },
                          ]);
                        });
                      }}
                      inputProps={{ accept: '.pdf, .doc, .docx' }}
                    />
                  </Container>
                  <Typography variant='body1' color={'#64748B'} fontSize={'12px'} pt={2}>
                    {`File format only Jpeg or Pdf, Size should not exceed 10MB`}
                  </Typography>
                  <FileContainerDetails>
                    <FieldArray name='documents'>
                      {({ remove }) =>
                        values.documents.map((doc, index) => (
                          <FileContainer key={index}>
                            <ImageContainer>
                              {getFileIcon(doc.file.type)}
                              <FileDetails>
                                <Typography
                                  variant='body1'
                                  style={{ fontWeight: 'bold', marginLeft: '10px' }}
                                >
                                  {doc.file.name}
                                </Typography>
                                <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                  {(doc.file.size / 1024).toFixed(2)} KB
                                </Typography>
                              </FileDetails>
                              <IconButton onClick={() => remove(index)}>
                                <DeleteIcon style={{ color: 'red' }} />
                              </IconButton>
                            </ImageContainer>
                            <ModalDivider />
                            <Grid container spacing={2} mt={0.3}>
                              <Grid item xs={6}>
                                <CustomTextInput
                                  name={`documents[${index}].docName`}
                                  placeholder='Document Name'
                                  value={doc.docName}
                                  onChange={handleChange}
                                  error={Boolean(
                                    getIn(errors, `documents[${index}].docName`) &&
                                      getIn(touched, `documents[${index}].docName`),
                                  )}
                                  helperText={
                                    getIn(errors, `documents[${index}].docName`) &&
                                    getIn(touched, `documents[${index}].docName`)
                                      ? 'Document Name is required'
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Autocomplete
                                  size='small'
                                  sx={{ width: '220px' }}
                                  options={documentTypes}
                                  getOptionLabel={(option) => option.label}
                                  onChange={(event, newValue) => {
                                    setFieldValue(
                                      `documents[${index}].type`,
                                      newValue?.label || '',
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant='outlined'
                                      label='Document Type'
                                      className='textfiled'
                                      error={Boolean(
                                        getIn(errors, `documents[${index}].type`) &&
                                          getIn(touched, `documents[${index}].type`),
                                      )}
                                      helperText={
                                        getIn(errors, `documents[${index}].type`) &&
                                        getIn(touched, `documents[${index}].type`)
                                          ? 'Document Type is required'
                                          : ''
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </FileContainer>
                        ))
                      }
                    </FieldArray>
                  </FileContainerDetails>
                  <Box
                    sx={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      pt: 2,
                    }}
                  >
                    <LoadingButton
                      className='cancelButton'
                      color='error'
                      variant='outlined'
                      onClick={() => setOpenModal(false)}
                      style={{ boxShadow: 'none' }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      type='submit'
                      loading={isSubmitting}
                      style={{ boxShadow: 'none' }}
                    >
                      Upload
                    </LoadingButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>

      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Delete Document</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the document "{selectedDocument?.metadata.documentName}"?
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleCloseDeleteModal} color='primary' disabled={loader}>
            No
          </LoadingButton>
          <LoadingButton onClick={handleDelete} color='primary' autoFocus loading={loader}>
            Yes
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeadsDocumentDetails;
