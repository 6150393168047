import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
  Box,
  Collapse,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material//KeyboardArrowRight';
import { useState } from 'react';
import ExpenseInvoice from './ExpenseInvoice';
const TableHeader = () => (
  <TableHead>
    <TableRow>
      {/* <TableCell>S.No.</TableCell> */}
      <TableCell>Name</TableCell>
      <TableCell>Total Amount</TableCell>
      <TableCell>Paid Amount</TableCell>
      <TableCell>Balance</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Paid To</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Invoice No.</TableCell>
      <TableCell>Invoice</TableCell>
      {/* <TableCell align='right' style={{ width: 48 }}></TableCell> */}
    </TableRow>
  </TableHead>
);

function ExpensesTable({ expenses, onExpenseClick }) {
  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);

  return (
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {expenses &&
            expenses.map((expense, index) => {
              const latestPaymentDetail =
                expense?.paymentDetails?.length > 0 ? expense?.paymentDetails?.at(-1) : {};
              return (
                <>
                  <StyledTableRow key={latestPaymentDetail.id}>
                    <TableCell>
                      <div
                        style={{
                          fontWeight: 100,
                          fontSize: '14px',
                          align: 'left',
                          width: '60px',
                        //   color: 'var(--tableBody-cell-color)',
                        }}
                        onClick={() => handleRowClick(expense.id)}
                      >
                        <span>
                          {openRow === expense.id ? (
                            <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />
                          ) : (
                            <KeyboardArrowRightIcon sx={{ marginBottom: '-7px' }} />
                          )}
                        </span>
                        <span>
                          {/* {(index + 1).toString().padStart(2, '0')} */}
                          {latestPaymentDetail?.expensesName}
                        </span>
                      </div>
                    </TableCell>
                    {/* <TableCell>{latestPaymentDetail?.expensesName}</TableCell> */}
                    <TableCell>{expense?.totalAmount}</TableCell>
                    <TableCell>{latestPaymentDetail?.paidAmount}</TableCell>
                    <TableCell>{latestPaymentDetail?.balance || '00'}</TableCell>
                    <TableCell>{latestPaymentDetail?.expenseType}</TableCell>
                    <TableCell>{latestPaymentDetail?.paidTo}</TableCell>
                    <TableCell>{latestPaymentDetail?.status}</TableCell>
                    <TableCell>{expense?.invoiceNo}</TableCell>
                    <TableCell></TableCell>
                    {/* <TableCell align='right'>
                      <IconButton
                        size='small'
                        //   onClick={(event) => handleMenuOpen(event, member)}
                        //   disabled={isOnHoldOrCancelled}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell> */}
                  </StyledTableRow>
                {openRow === expense.id && 
                  <TableRow>
                    <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
                      <Collapse in={openRow === expense.id} timeOut='auto' unmountOnExit>
                        <Box>
                          <ExpenseInvoice
                            selectedExpense={expense}
                            // expensesDocuments={expensesDocuments}
                          />
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                }
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

export default ExpensesTable;
