import Loadable from 'app/common/Loadable';
import { lazy } from 'react';

const SessionsGuard = Loadable(lazy(() => import('../../auth/SessionsGuard')));
const SessionsLayout = Loadable(
  lazy(() => import('../../components/Layout/Sessions/SessionsLayout')),
);
const Login = Loadable(lazy(() => import('./Login')));
const Register = Loadable(lazy(() => import('./Register')));
const OTP = Loadable(lazy(() => import('./OTP')));
const NotRegistered = Loadable(lazy(() => import('./NotRegistered')));

const sessionsRoutes = [
  {
    element: (
      <SessionsGuard>
        <SessionsLayout />
      </SessionsGuard>
    ),
    children: [
      { path: 'signin', element: <Login /> },
      { path: 'otp', element: <OTP /> },
      { path: 'signup', element: <Register /> },
      { path: 'not-registered', element: <NotRegistered /> },
    ],
  },
];

export default sessionsRoutes;
