import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchUserDetailsById, userGetInvestmentDetails } from 'app/contexts/leadActions';
import { formatCurrency, handleAxiosError } from 'app/utils/helpers';
import { Loading } from 'app/components';
import RequestActions from './RequestActions';

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const SectionDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const InvestmentDetailItem = ({ value, label }) => (
  <Box>
    <Typography variant='h6' fontWeight='bold'>
      {value}
    </Typography>
    <Typography variant='subtitle1' color='text.secondary'>
      {label}
    </Typography>
  </Box>
);
function DetailSection({ title, details }) {
  return (
    <>
      <Typography variant='h6' fontWeight='normal'>
        {title.key}{' '}
        <Typography variant='h6' component='span' fontWeight='bold'>
          {title.value}
        </Typography>
      </Typography>
      <Grid container columnSpacing={1} rowSpacing={1} columns={{ xs: 3, sm: 6, md: 12 }}>
        {details.map(({ key, value }) => (
          <Grid item xs={12} sm={6} md={4} key={key}>
            <Typography variant='subtitle1' color='text.secondary'>
              {key}
            </Typography>
            <Typography variant='subtitle1' fontWeight='bold'>
              {value || '-'}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function ViewInvestmentsRequest({ request, respondToRequest, closeModal }) {
  const [investorDetails, setInvestorDetails] = useState({
    investor: {},
    investments: {},
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getInvestmentDetails = async () => {
      try {
        setIsLoading(true);

        const responseList = await userGetInvestmentDetails(
          request.projectId,
          request.investorUserId,
        );

        const lastRequest = responseList.at(-1);

        const investorData = (await fetchUserDetailsById(request.investorUserId))[
          request.investorUserId
        ];

        setInvestorDetails((prevDetails) => ({
          ...prevDetails,
          investor: investorData,
          investments: lastRequest,
        }));
      } catch (error) {
        handleAxiosError(error);
        closeModal();
      } finally {
        setIsLoading(false);
      }
    };

    getInvestmentDetails();
  }, [request.projectId, closeModal, request.investorUserId]);

  const investmentDetailsPairs = investorDetails.investments
    ? [
        {
          label: 'Interested Amount',
          value: formatCurrency(investorDetails.investments.interestedAmount),
        },
        {
          label: 'Interested Shares',
          value: investorDetails.investments.interestedShares,
        },
        {
          label: 'Invested Shares',
          value: investorDetails.investments?.paymentDetails?.at(-1)?.investedShares || 0,
        },
        {
          label: 'Invested Amount',
          value: formatCurrency(
            investorDetails.investments?.paymentDetails?.at(-1)?.investedAmount,
          ),
        },
      ]
    : [];

  const investorDetailsPairs = [
    {
      title: {
        key: 'Investor name:',
        value: `${investorDetails.investor.name} ${investorDetails.investor.surname}`,
      },
      details: [
        { key: 'Mobile number', value: investorDetails.investor.phone },
        { key: 'Email', value: investorDetails.investor.email },
        { key: 'Address', value: investorDetails.investor.address },
      ],
    },
  ];

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { xs: '80vw', sm: '70vw', md: '60vw', lg: '45vw' },
          },
        },
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ p: 1 }}>
            <DialogTitle>Investment details</DialogTitle>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Box>
          <DialogDivider />
          <Box display='flex' flexDirection='column' gap={2} mb={2}>
            <Box
              sx={{
                mx: 3,
                my: 2,
                mb: 0,
                p: 3,
                bgcolor: 'rgba(242, 243, 245, 1)',
                borderRadius: 1,
              }}
            >
              <Grid container spacing={2}>
                {investmentDetailsPairs.map((pair) => (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    key={pair.label}
                    sx={{
                      '& .MuiTypography-h6': {
                        fontSize: { xs: '1rem', md: '1.25rem' },
                      },
                      '& .MuiTypography-subtitle1': {
                        fontSize: { xs: '0.875rem', md: '1rem' },
                      },
                    }}
                  >
                    <InvestmentDetailItem {...pair} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box display='flex' flexDirection='column' gap={2} mx={3} my={2}>
              <SectionDivider />
              {investorDetailsPairs.map((pair) => (
                <DetailSection key={pair.title.key} {...pair} />
              ))}
            </Box>
            <Box mx={3} my={2}>
              <RequestActions request={request} respondToRequest={respondToRequest} />
            </Box>
          </Box>
        </>
      )}
    </Dialog>
  );
}

export default ViewInvestmentsRequest;
