import React, { useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import { Formik } from 'formik';
import { StandaloneSearchBox } from '@react-google-maps/api';
import * as Yup from 'yup';
import { parseAddressComponents } from 'app/utils/helpers';
import { LoadingButton } from '@mui/lab';

const validationSchema = Yup.object().shape({
  llcName: Yup.string().required('LLC Name is required'),
  einNum: Yup.string().required('EIN Number is required'),
  address1: Yup.string().required('Address1 is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  zipCode: Yup.string().required('Zip Code is required'),
});

const LLCDialog = ({ open, onClose, initialValues, onSubmit }) => {
  const searchInputRef = useRef(null);

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({
          values,
          isSubmitting,
          errors,
          touched,
          setValues,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>LLC Details</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                margin='normal'
                label='LLC Name'
                type='text'
                name='llcName'
                value={values.llcName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.llcName && errors.llcName}
                error={Boolean(errors.llcName && touched.llcName)}
                disabled={isSubmitting}
              />
              <TextField
                fullWidth
                margin='normal'
                label='EIN Number'
                type='text'
                name='einNum'
                value={values.einNum}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.einNum && errors.einNum}
                error={Boolean(errors.einNum && touched.einNum)}
                disabled={isSubmitting}
              />
              <StandaloneSearchBox
                onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                onUnmount={() => (searchInputRef.current = null)}
                onPlacesChanged={() => handlePlacesChanged(setValues)}
              >
                <TextField
                  fullWidth
                  margin='normal'
                  label='Address Line 1'
                  placeholder='Search With Place, Landmark'
                  type='search'
                  name='address1'
                  value={values.address1}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (!value) {
                      setValues((prevState) => ({
                        ...prevState,
                        address1: '',
                        address2: '',
                        country: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        latitude: null,
                        longitude: null,
                      }));
                    }
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  helperText={touched.address1 && errors.address1}
                  error={Boolean(errors.address1 && touched.address1)}
                  disabled={isSubmitting}
                />
              </StandaloneSearchBox>
              <TextField
                fullWidth
                margin='normal'
                label='Address Line 2'
                type='text'
                name='address2'
                value={values.address2}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.address2 && errors.address2}
                error={Boolean(errors.address2 && touched.address2)}
                disabled={isSubmitting}
              />
              <TextField
                fullWidth
                margin='normal'
                label='City'
                type='text'
                name='city'
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.city && errors.city}
                error={Boolean(errors.city && touched.city)}
                disabled={isSubmitting}
              />
              <TextField
                fullWidth
                margin='normal'
                label='State'
                type='text'
                name='state'
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.state && errors.state}
                error={Boolean(errors.state && touched.state)}
                disabled={isSubmitting}
              />
              <TextField
                fullWidth
                margin='normal'
                label='Country'
                type='text'
                name='country'
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.country && errors.country}
                error={Boolean(errors.country && touched.country)}
                disabled={isSubmitting}
              />
              <TextField
                fullWidth
                margin='normal'
                label='Zip Code'
                name='zipCode'
                type='text'
                value={values.zipCode}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.zipCode && errors.zipCode}
                error={Boolean(errors.zipCode && touched.zipCode)}
                disabled={isSubmitting}
              />
            </DialogContent>
            <DialogActions>
              <LoadingButton variant='outlined' onClick={onClose} disabled={isSubmitting}>
                Cancel
              </LoadingButton>
              <LoadingButton
                type='submit'
                color='primary'
                variant='outlined'
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default LLCDialog;
