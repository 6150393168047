import { useState } from 'react';
import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { calculateTomorrow, formatDecimal, handleAxiosError } from 'app/utils/helpers';
import { Form, Formik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useProject } from 'app/contexts/ProjectContext';
import { validationSchemas } from 'app/utils/validationSchemas';
import { StyledEditIcon, Title } from 'app/common/Typography';
import { useProjectContract } from './hooks/useContract';
import { useProjectContractCreateUpdate } from './hooks/useContractCreateUpdate';
import { convertDateArrayToFormattedString } from 'app/utils/helpers';
import { useProjectClosing } from '../Closing/hooks/useProjectClosing';
import EditIcon from '@mui/icons-material/Edit';


const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--dialogDivider)',
});

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& th, & td': { border: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  backgroundColor: '#fff',
  marginBottom: '20px'
}));

const LeadsContractDetails = () => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { data: closing } = useProjectClosing(projectId);
  const { data: contract } = useProjectContract(projectId);
  const { mutate: updateContract } = useProjectContractCreateUpdate(projectId);

  const [open, setOpen] = useState(false);

  const initialValues = {
    ...contract,
    effectiveDate: contract?.effectiveDate
      ? convertDateArrayToFormattedString(contract?.effectiveDate)
      : null,
    feasibilityEndDate: contract?.feasibilityEndDate
      ? convertDateArrayToFormattedString(contract?.feasibilityEndDate)
      : null,
    estimatedClosingDate: contract?.estimatedClosingDate
      ? convertDateArrayToFormattedString(contract?.estimatedClosingDate)
      : null,
  };

  console.log(closing, 'cclosing in ContractSection');

  const infoPairs = [
    {
      key: 'Effective date',
      value: initialValues?.effectiveDate
        ? initialValues?.effectiveDate.format('MM-DD-YYYY')
        : 'N/A',
    },
    {
      key: 'Feasibility due date',
      value: initialValues?.feasibilityEndDate
        ? initialValues?.feasibilityEndDate.format('MM-DD-YYYY')
        : 'N/A',
    },
    {
      key: 'Estimated Closing date',
      value: initialValues?.estimatedClosingDate
        ? initialValues?.estimatedClosingDate.format('MM-DD-YYYY')
        : '/N/A',
    },
    { key: 'Earnest Money', value: initialValues.earnestMoney },
    { key: 'Options money', value: initialValues.optionsMoney },
  ];

  const closingDetailsInfo = [
    { key: 'Purchase Price', value: closing?.purchasePrice },
    { key: 'Closing Cost', value: closing?.closingCost },
    { key: 'Realtor Rebate', value: closing?.realtorRebate },
    { key: 'Closing Date', value: convertDateArrayToFormattedString(closing?.closingDate) },
  ];

  const titleFirmDetails = [
    { key: 'Title Firm Name', value: closing?.companyDetails?.companyName },
    { key: 'Contact Person', value: closing?.companyDetails?.contactPerson },
    { key: 'Contact Number', value: closing?.companyDetails?.contactPersonNumber },
    { key: 'Email', value: closing?.companyDetails?.contactPersonEmail },
  ];

  const lenderDetails = [
    { key: 'Lender Name', value: closing?.lenderDetails?.lenderName },
    { key: 'Contact Number', value: closing?.lenderDetails?.lenderContactNumber },
    { key: 'Down Payment', value: closing?.lenderDetails?.downPayment },
    { key: 'Loan Amount', value: closing?.lenderDetails?.loanAmount },
    { key: 'Intrest Rate', value: closing?.lenderDetails?.interestRate },
    { key: 'Loan Term    ', value: closing?.lenderDetails?.leaseTerm },
  ];

  const toggleModal = () => setOpen((prev) => !prev);

  const handleSubmit = (values, { setSubmitting }) => {
    const formattedDates = {
      effectiveDate: values.effectiveDate.toISOString(),
      feasibilityEndDate: values.feasibilityEndDate.toISOString(),
      estimatedClosingDate: values.estimatedClosingDate.toISOString(),
    };

    updateContract(
      { ...values, ...formattedDates },
      {
        onSuccess: toggleModal,
        onSettled: () => setSubmitting(false),
      },
    );
  };

  return (
    <div className='viewlead'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          padding: 2,
          borderRadius: '6px 6px 0 0',
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
        }}
      >
        <Title sx={{ m: 0 }}>Contract Details</Title>
        <StyledEditIcon variant='outlined' onClick={toggleModal} disabled={isOnHoldOrCancelled} startIcon={<EditIcon />}>
          Contract Details
        </StyledEditIcon>

      </Box>
      <Divider />
      <ProductTable>
        <TableHead>
          <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {infoPairs.map(({ key }, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: '0.875rem',
                  fontSize: '500',
                  color: '#17181B',
                  flex: 1, // Ensures each header item aligns correctly
                }}
              >
                {key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {infoPairs.map(({ key, value }, index) => {
              let output;
              switch (true) {
                case typeof value === 'string':
                  output = value;
                  break;
                case typeof value === 'number' && !isNaN(value):
                  output = `$${value.toFixed(2)}`;
                  break;
                default:
                  output = 'N/A';
                  break;
              }
              return (
                <TableCell
                  key={index}
                  sx={{
                    color: '#282A2D',
                    fontSize: '1rem',
                    fontWeight: '600',
                    flex: 1, // Ensures content cells align correctly
                  }}
                >
                  {output}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </ProductTable>

      {closing &&
        Object.keys(closing.companyDetails || {}).length > 0 &&
        Object.keys(closing.lenderDetails || {}).length > 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                padding: 2,
                borderRadius: '6px 6px 0 0',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
              }}
            >
              <Title sx={{ m: 0 }}>Closing Details</Title>
              <StyledEditIcon variant='outlined' onClick={toggleModal} disabled={isOnHoldOrCancelled} startIcon={<EditIcon />}>
                Closing Details
              </StyledEditIcon>
            </Box>
            <Divider />
            <ProductTable>
              <TableHead>
                <TableRow>
                  {closingDetailsInfo.map(({ key }, index) => {
                    return (
                      <>
                        <TableCell
                          sx={{ fontWeight: '0.875rem', fontSize: '500', color: '#17181B' }}
                        >
                          {key}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  {closingDetailsInfo.map(({ key, value }, index) => {
                    let output;
                    if (typeof value === 'string') {
                      output = value;
                    } else if (typeof value === 'number' && !isNaN(value)) {
                      output = value;
                    } else if (value && value.$isDayjsObject) {
                      output = value.format('MM-DD-YYYY');
                    } else {
                      output = 'N/A';
                    }

                    return (
                      <TableCell sx={{ color: '#282A2D', fontSize: '1rem', fontWeight: '600' }}>
                        {output}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </ProductTable>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                padding: 2,
                borderRadius: '6px 6px 0 0',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
              }}
            >
              <Title sx={{ m: 0 }}>Title Firm</Title>
              <StyledEditIcon variant='outlined' onClick={toggleModal} disabled={isOnHoldOrCancelled} startIcon={<EditIcon />}>
                Title Firm
              </StyledEditIcon>
            </Box>
            <Divider />
            <ProductTable>
              <TableHead>
                <TableRow>
                  {titleFirmDetails.map(({ key }, index) => {
                    return (
                      <>
                        <TableCell
                          sx={{ fontWeight: '0.875rem', fontSize: '500', color: '#17181B' }}
                        >
                          {key}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  {titleFirmDetails.map(({ key, value }, index) => {
                    let output;

                    if (typeof value === 'string') {
                      output = value;
                    } else if (typeof value === 'number' && !isNaN(value)) {
                      output = value;
                    } else if (value && value.$isDayjsObject) {
                      output = value.format('MM-DD-YYYY');
                    } else {
                      output = 'N/A';
                    }

                    return (
                      <TableCell sx={{ color: '#282A2D', fontSize: '1rem', fontWeight: '600' }}>
                        {output}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </ProductTable>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                padding: 2,
                borderRadius: '6px 6px 0 0',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
              }}
            >
              <Title sx={{ m: 0 }}>Lender Details</Title>
              <StyledEditIcon variant='outlined' onClick={toggleModal} disabled={isOnHoldOrCancelled} startIcon={<EditIcon />}>
                Lender Details
              </StyledEditIcon>
            </Box>
            <Divider />
            <ProductTable>
              <TableHead>
                <TableRow>
                  {lenderDetails.map(({ key }, index) => {
                    return (
                      <>
                        <TableCell
                          sx={{ fontWeight: '0.875rem', fontSize: '500', color: '#17181B' }}
                        >
                          {key}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  {lenderDetails.map(({ key, value }, index) => {
                    let output;

                    if (typeof value === 'string') {
                      output = value;
                    } else if (typeof value === 'number' && !isNaN(value)) {
                      output = value;
                    } else if (value && value.$isDayjsObject) {
                      output = value.format('MM-DD-YYYY');
                    } else {
                      output = 'N/A';
                    }

                    return (
                      <TableCell sx={{ color: '#282A2D', fontSize: '1rem', fontWeight: '600' }}>
                        {output}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </ProductTable>
          </>
        )}

      <Dialog open={open} onClose={toggleModal}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle>Contract Details</DialogTitle>
          <IconButton onClick={toggleModal} sx={{ marginRight: 2 }}>
            <Close />
          </IconButton>
        </Box>
        <DialogDivider />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.contract()}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, errors, touched, setFieldValue, handleBlur, handleChange }) => {
            const handleDateChange = (name, value) => {
              handleChange({
                target: {
                  name,
                  value,
                },
              });
            };

            const handlePriceChange = (e) => {
              const { name, value } = e.target;

              setFieldValue(name, formatDecimal(value, 2));
            };

            return (
              <Form>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid item xs={12}>
                        <DatePicker
                          disablePast
                          name='effectiveDate'
                          label='Effective Date'
                          value={values.effectiveDate}
                          onChange={(value) => handleDateChange('effectiveDate', value)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              onBlur: handleBlur,
                              error: Boolean(touched.effectiveDate && errors.effectiveDate),
                              helperText: touched.effectiveDate && errors.effectiveDate,
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          disablePast
                          label='Feasibility Due Date'
                          name='feasibilityEndDate'
                          minDate={calculateTomorrow(values.effectiveDate)}
                          value={values.feasibilityEndDate}
                          onChange={(value) => handleDateChange('feasibilityEndDate', value)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              onBlur: handleBlur,
                              helperText: touched.feasibilityEndDate && errors.feasibilityEndDate,
                              error: Boolean(
                                errors.feasibilityEndDate && touched.feasibilityEndDate,
                              ),
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          disablePast
                          label='Closing Date'
                          name='estimatedClosingDate'
                          minDate={calculateTomorrow(values.feasibilityEndDate)}
                          value={values.estimatedClosingDate}
                          onChange={(value) => handleDateChange('estimatedClosingDate', value)}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              onBlur: handleBlur,
                              helperText:
                                touched.estimatedClosingDate && errors.estimatedClosingDate,
                              error: Boolean(
                                errors.estimatedClosingDate && touched.estimatedClosingDate,
                              ),
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Earnest money'
                        type='number'
                        name='earnestMoney'
                        value={values.earnestMoney}
                        onChange={handlePriceChange}
                        onBlur={handleBlur}
                        helperText={touched.earnestMoney && errors.earnestMoney}
                        error={Boolean(errors.earnestMoney && touched.earnestMoney)}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Options money'
                        type='number'
                        name='optionsMoney'
                        value={values.optionsMoney}
                        onChange={handlePriceChange}
                        onBlur={handleBlur}
                        helperText={touched.optionsMoney && errors.optionsMoney}
                        error={Boolean(errors.optionsMoney && touched.optionsMoney)}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      flexDirection: { xs: 'column', sm: 'row' },
                      gap: 2,
                    }}
                  >
                    <LoadingButton
                      color='error'
                      variant='outlined'
                      onClick={toggleModal}
                      disabled={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      type='submit'
                      loading={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
};

export default LeadsContractDetails;
