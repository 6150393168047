import { SvgIcon } from '@mui/material';

export const DownloadDragIcon = () => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.666667 16C0.489856 16 0.320287 16.0702 0.195262 16.1953C0.070238 16.3203 0 16.4899 0 16.6667C0 16.8435 0.070238 17.013 0.195262 17.1381C0.320287 17.2631 0.489856 17.3333 0.666667 17.3333H15.3333C15.5101 17.3333 15.6797 17.2631 15.8047 17.1381C15.9298 17.013 16 16.8435 16 16.6667C16 16.4899 15.9298 16.3203 15.8047 16.1953C15.6797 16.0702 15.5101 16 15.3333 16H0.666667Z'
        fill='#272937'
      />
      <path
        d='M7.89508 0.00811903C7.75595 0.0302557 7.62741 0.095895 7.52789 0.195619L2.86123 4.86229C2.73623 4.98731 2.66602 5.15685 2.66602 5.33364C2.66602 5.51043 2.73623 5.67997 2.86123 5.80499C2.98625 5.92999 3.1558 6.00021 3.33258 6.00021C3.50937 6.00021 3.67892 5.92999 3.80394 5.80499L7.33258 2.27635V14.0003C7.33258 14.1771 7.40282 14.3467 7.52784 14.4717C7.65287 14.5967 7.82244 14.667 7.99925 14.667C8.17606 14.667 8.34563 14.5967 8.47065 14.4717C8.59568 14.3467 8.66592 14.1771 8.66592 14.0003V2.27635L12.1946 5.80499C12.3196 5.92999 12.4891 6.00021 12.6659 6.00021C12.8427 6.00021 13.0122 5.92999 13.1373 5.80499C13.2623 5.67997 13.3325 5.51043 13.3325 5.33364C13.3325 5.15685 13.2623 4.98731 13.1373 4.86229L8.4706 0.195619C8.39627 0.121178 8.30537 0.0653828 8.20535 0.032795C8.10532 0.000207278 7.999 -0.00824849 7.89508 0.00811903Z'
        fill='#272937'
      />
    </svg>
  );
};

export const PdfIcon = () => {
  return (
    <svg width='24' height='32' viewBox='0 0 24 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.1659 0H14.8645L23.932 9.45128V27.8341C23.932 30.1369 22.0689 32 19.7741 32H4.1659C1.86306 32 6.58073e-10 30.1369 6.58073e-10 27.8341V4.1659C-4.04313e-05 1.86306 1.86302 0 4.1659 0Z'
        fill='#E5252A'
      />
      <path
        opacity='0.302'
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.8555 0V9.37931H23.9309L14.8555 0Z'
        fill='white'
      />
      <path
        d='M4.62891 23.8763V18.0312H7.11567C7.73137 18.0312 8.21914 18.1992 8.58695 18.543C8.95476 18.8788 9.13868 19.3346 9.13868 19.9023C9.13868 20.47 8.95476 20.9258 8.58695 21.2616C8.21914 21.6055 7.73137 21.7734 7.11567 21.7734H6.12416V23.8763H4.62891ZM6.12416 20.502H6.94776C7.17163 20.502 7.34755 20.4541 7.46751 20.3421C7.58743 20.2382 7.65143 20.0942 7.65143 19.9024C7.65143 19.7105 7.58747 19.5665 7.46751 19.4626C7.34759 19.3506 7.17167 19.3027 6.94776 19.3027H6.12416V20.502ZM9.75434 23.8763V18.0312H11.8253C12.2331 18.0312 12.6169 18.0872 12.9767 18.2072C13.3365 18.3271 13.6644 18.495 13.9522 18.7269C14.2401 18.9508 14.472 19.2546 14.6399 19.6385C14.7998 20.0223 14.8878 20.4621 14.8878 20.9578C14.8878 21.4456 14.7998 21.8853 14.6399 22.2691C14.472 22.6529 14.2401 22.9568 13.9522 23.1807C13.6643 23.4125 13.3365 23.5804 12.9767 23.7004C12.6169 23.8203 12.2331 23.8763 11.8253 23.8763H9.75434ZM11.2176 22.605H11.6494C11.8813 22.605 12.0972 22.581 12.2971 22.525C12.489 22.4691 12.6729 22.3811 12.8488 22.2612C13.0167 22.1412 13.1526 21.9733 13.2486 21.7494C13.3445 21.5255 13.3925 21.2616 13.3925 20.9578C13.3925 20.6459 13.3445 20.3821 13.2486 20.1582C13.1526 19.9343 13.0167 19.7664 12.8488 19.6465C12.6729 19.5265 12.489 19.4386 12.2971 19.3826C12.0972 19.3266 11.8813 19.3026 11.6494 19.3026H11.2176V22.605ZM15.6394 23.8763V18.0312H19.7973V19.3026H17.1347V20.2381H19.2616V21.5015H17.1347V23.8763H15.6394Z'
        fill='white'
      />
    </svg>
  );
};

export const DocIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='#FFF'
      stroke-miterlimit='10'
      stroke-width='2'
      width='28'
      height='32'
      viewBox='0 0 96 96'
    >
      <path
        stroke='#979593'
        d='M67.1716 7H27c-1.1046 0-2 .8954-2 2v78c0 1.1046.8954 2 2 2h58c1.1046 0 2-.8954 2-2V26.8284c0-.5304-.2107-1.0391-.5858-1.4142L68.5858 7.5858C68.2107 7.2107 67.702 7 67.1716 7z'
      />
      <path fill='none' stroke='#979593' d='M67 7v18c0 1.1046.8954 2 2 2h18' />
      <path
        fill='#C8C6C4'
        d='M79 61H48v-2h31c.5523 0 1 .4477 1 1s-.4477 1-1 1zm0-6H48v-2h31c.5523 0 1 .4477 1 1s-.4477 1-1 1zm0-6H48v-2h31c.5523 0 1 .4477 1 1s-.4477 1-1 1zm0-6H48v-2h31c.5523 0 1 .4477 1 1s-.4477 1-1 1zm0 24H48v-2h31c.5523 0 1 .4477 1 1s-.4477 1-1 1z'
      />
      <path
        fill='#185ABD'
        d='M12 74h32c2.2091 0 4-1.7909 4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091 0-4 1.7909-4 4v32c0 2.2091 1.7909 4 4 4z'
      />
      <path d='M21.6245 60.6455c.0661.522.109.9769.1296 1.3657h.0762c.0306-.3685.0889-.8129.1751-1.3349.0862-.5211.1703-.961.2517-1.319L25.7911 44h4.5702l3.6562 15.1272c.183.7468.3353 1.6973.457 2.8532h.0608c.0508-.7979.1777-1.7184.3809-2.7615L37.8413 44H42l-5.1183 22h-4.86l-3.4885-14.5744c-.1016-.4197-.2158-.9663-.3428-1.6417-.127-.6745-.2057-1.1656-.236-1.4724h-.0608c-.0407.358-.1195.8896-.2364 1.595-.1169.7062-.211 1.2273-.2819 1.565L24.1 66h-4.9357L14 44h4.2349l3.1843 15.3882c.0709.3165.1392.7362.2053 1.2573z' />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.832682 14.166H15.8327C16.0537 14.166 16.2657 14.2538 16.4219 14.4101C16.5782 14.5664 16.666 14.7783 16.666 14.9993C16.666 15.2204 16.5782 15.4323 16.4219 15.5886C16.2657 15.7449 16.0537 15.8327 15.8327 15.8327H0.832682C0.611668 15.8327 0.399706 15.7449 0.243425 15.5886C0.0871449 15.4323 -0.000652313 15.2204 -0.000652313 14.9993C-0.000652313 14.7783 0.0871449 14.5664 0.243425 14.4101C0.399706 14.2538 0.611668 14.166 0.832682 14.166Z'
        fill='#5B7E17'
      />
      <path
        d='M8.33253 0C8.55354 0 8.7655 0.0877973 8.92178 0.244078C9.07806 0.400358 9.16586 0.61232 9.16586 0.833333V9.65833L11.9075 6.90833C12.0644 6.75141 12.2773 6.66326 12.4992 6.66326C12.7211 6.66326 12.9339 6.75141 13.0909 6.90833C13.2478 7.06525 13.3359 7.27808 13.3359 7.5C13.3359 7.72192 13.2478 7.93475 13.0909 8.09167L8.92419 12.2583C8.84673 12.3364 8.75456 12.3984 8.65301 12.4407C8.55146 12.4831 8.44254 12.5048 8.33253 12.5048C8.22252 12.5048 8.1136 12.4831 8.01205 12.4407C7.9105 12.3984 7.81833 12.3364 7.74086 12.2583L3.5742 8.09167C3.4965 8.01397 3.43486 7.92173 3.39281 7.82021C3.35076 7.71869 3.32912 7.60988 3.32912 7.5C3.32912 7.39012 3.35076 7.28131 3.39281 7.17979C3.43486 7.07827 3.4965 6.98603 3.5742 6.90833C3.65189 6.83064 3.74414 6.769 3.84565 6.72695C3.94717 6.6849 4.05598 6.66326 4.16586 6.66326C4.27574 6.66326 4.38455 6.6849 4.48607 6.72695C4.58759 6.769 4.67983 6.83064 4.75753 6.90833L7.49919 9.65833V0.833333C7.49919 0.61232 7.58699 0.400358 7.74327 0.244078C7.89955 0.0877973 8.11151 0 8.33253 0Z'
        fill='#5B7E17'
      />
    </svg>
  );
};

export const EditProfileIcon = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_341_19)'>
        <path
          d='M2.16037 10.1041C2.28037 10.1041 2.30437 10.0921 2.41237 10.0681L4.57237 9.63607C4.80037 9.57607 5.02837 9.46807 5.20837 9.28807L10.4404 4.05607C11.2444 3.25207 11.2444 1.87207 10.4404 1.06807L9.99637 0.60007C9.19237 -0.20393 7.80037 -0.20393 6.99637 0.60007L1.76437 5.84407C1.59637 6.01207 1.47637 6.25207 1.41637 6.48007L0.960369 8.66407C0.900369 9.07207 1.02037 9.46807 1.30837 9.75607C1.53637 9.98407 1.87237 10.1041 2.16037 10.1041ZM2.56837 6.70807L7.80037 1.46407C8.14837 1.11607 8.78437 1.11607 9.12037 1.46407L9.57637 1.92007C9.98437 2.32807 9.98437 2.90407 9.57637 3.30007L4.35637 8.54407L2.13637 8.91607L2.56837 6.70807Z'
          fill='#4F90F0'
        />
        <path
          d='M10.3929 10.8481H1.53694C1.18894 10.8481 0.960938 11.0761 0.960938 11.4241C0.960938 11.7721 1.24894 12.0001 1.53694 12.0001H10.3449C10.6929 12.0001 10.9809 11.7721 10.9809 11.4241C10.9689 11.0761 10.6809 10.8481 10.3929 10.8481Z'
          fill='#4F90F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_341_19'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DeleteIcon = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.33268 6.6665C5.70088 6.6665 5.99935 6.96497 5.99935 7.33317V11.3332C5.99935 11.7014 5.70087 11.9998 5.33268 11.9998C4.96449 11.9998 4.66602 11.7014 4.66602 11.3332V7.33317C4.66602 6.96497 4.9645 6.6665 5.33268 6.6665Z'
        fill='#F41F1F'
      />
      <path
        d='M9.99935 7.33317C9.99935 6.96497 9.70088 6.6665 9.33268 6.6665C8.96448 6.6665 8.66602 6.96497 8.66602 7.33317V11.3332C8.66602 11.7014 8.96448 11.9998 9.33268 11.9998C9.70088 11.9998 9.99935 11.7014 9.99935 11.3332V7.33317Z'
        fill='#F41F1F'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.33333 2.66667V2C3.33333 0.895433 4.22877 0 5.33333 0H9.33333C10.4379 0 11.3333 0.895433 11.3333 2V2.66667H14C14.3682 2.66667 14.6667 2.96515 14.6667 3.33333C14.6667 3.70152 14.3682 4 14 4H13.3333V14C13.3333 15.1046 12.4379 16 11.3333 16H3.33333C2.22877 16 1.33333 15.1046 1.33333 14V4H0.666667C0.29848 4 0 3.70152 0 3.33333C0 2.96515 0.29848 2.66667 0.666667 2.66667H3.33333ZM10 2V2.66667H4.66667V2C4.66667 1.63181 4.96515 1.33333 5.33333 1.33333H9.33333C9.70153 1.33333 10 1.63181 10 2ZM2.66667 4V14C2.66667 14.3682 2.96515 14.6667 3.33333 14.6667H11.3333C11.7015 14.6667 12 14.3682 12 14V4H2.66667Z'
        fill='#F41F1F'
      />
    </svg>
  );
};

export const RightArrow = () => {
  return (
    <svg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_594_140)'>
        <path
          d='M8.00082 7.501C8.00103 7.63908 7.97569 7.77585 7.92624 7.90342C7.87679 8.03099 7.80422 8.14684 7.71271 8.24431L1.67793 14.692C1.49284 14.8859 1.24364 14.9938 0.984496 14.9924C0.725352 14.991 0.477207 14.8804 0.293962 14.6845C0.110717 14.4887 0.00718078 14.2235 0.00587194 13.9465C0.00456309 13.6695 0.105588 13.4032 0.286973 13.2054L5.62626 7.49888L0.286973 1.79238C0.105588 1.59456 0.00456309 1.32823 0.00587194 1.05127C0.00718078 0.774302 0.110717 0.509077 0.293962 0.31323C0.477207 0.117382 0.725352 0.00673676 0.984496 0.00533867C1.24364 0.00393963 1.49284 0.111899 1.67793 0.305758L7.71271 6.75557C7.80446 6.85329 7.87716 6.96949 7.92662 7.09744C7.97607 7.2254 8.0013 7.36256 8.00082 7.501Z'
          fill='#4F90F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_594_140'>
          <rect width='8' height='15' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DownArrow = () => {
  return (
    <svg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_594_140)'>
        <path
          d='M7.49965 8.00002C7.36157 7.99981 7.2248 7.97447 7.09723 7.92502C6.96966 7.87557 6.85381 7.803 6.75634 7.71149L0.308602 1.67671C0.114739 1.49163 0.0068579 1.24243 0.00827415 0.983283C0.00969041 0.724132 0.120287 0.475988 0.316131 0.292743C0.511976 0.109498 0.777204 0.00696143 1.05417 0.00565259C1.33113 0.00434375 1.59636 0.105369 1.79221 0.286754L7.49871 5.62604L13.2052 0.286754C13.403 0.105369 13.6693 0.00434375 13.9463 0.00565259C14.2232 0.00696143 14.4884 0.109498 14.6843 0.292743C14.8802 0.475988 14.9919 0.724132 14.9932 0.983283C14.9945 1.24243 14.8855 1.49163 14.7041 1.67671L8.25432 7.71149C8.1566 7.80324 8.0404 7.876 7.91245 7.92546C7.78449 7.97491 7.64733 8.00014 7.49965 8.00002Z'
          fill='#4F90F0'
        />
      </g>
      <defs>
        <clipPath id='clip0_594_140'>
          <rect width='15' height='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RequestsIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#000'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.2915 9.16699V10.8337C7.2915 11.3312 7.489 11.8078 7.84067 12.1595C8.19234 12.5112 8.669 12.7087 9.1665 12.7087H10.8332C11.3307 12.7087 11.8073 12.5112 12.159 12.1595C12.5107 11.8078 12.7082 11.3312 12.7082 10.8337V9.16699C12.7082 8.66949 12.5107 8.19283 12.159 7.84116C11.8073 7.48949 11.3307 7.29199 10.8332 7.29199H9.1665C8.669 7.29199 8.19234 7.48949 7.84067 7.84116C7.489 8.19283 7.2915 8.66949 7.2915 9.16699Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M1.0415 2.91699V4.58366C1.0415 5.08116 1.239 5.55783 1.59067 5.90949C1.94234 6.26116 2.419 6.45866 2.9165 6.45866H4.58317C5.08067 6.45866 5.55734 6.26116 5.909 5.90949C6.26067 5.55783 6.45817 5.08116 6.45817 4.58366V2.91699C6.45817 2.41949 6.26067 1.94283 5.909 1.59116C5.55734 1.23949 5.08067 1.04199 4.58317 1.04199H2.9165C2.419 1.04199 1.94234 1.23949 1.59067 1.59116C1.239 1.94283 1.0415 2.41949 1.0415 2.91699Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13.5415 15.417V17.0837C13.5415 17.5812 13.739 18.0578 14.0907 18.4095C14.4423 18.7612 14.919 18.9587 15.4165 18.9587H17.0832C17.5807 18.9587 18.0573 18.7612 18.409 18.4095C18.7607 18.0578 18.9582 17.5812 18.9582 17.0837V15.417C18.9582 14.9195 18.7607 14.4428 18.409 14.0912C18.0573 13.7395 17.5807 13.542 17.0832 13.542H15.4165C14.919 13.542 14.4423 13.7395 14.0907 14.0912C13.739 14.4428 13.5415 14.9195 13.5415 15.417Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.83301 4.375H16.6663C16.943 4.375 17.2072 4.485 17.403 4.68C17.598 4.87583 17.708 5.14 17.708 5.41667V8.33333C17.708 8.61 17.598 8.87417 17.403 9.07C17.2072 9.265 16.943 9.375 16.6663 9.375H14.1663C13.8213 9.375 13.5413 9.655 13.5413 10C13.5413 10.345 13.8213 10.625 14.1663 10.625H16.6663C17.2738 10.625 17.8572 10.3833 18.2872 9.95417C18.7163 9.52417 18.958 8.94083 18.958 8.33333C18.958 7.43083 18.958 6.31917 18.958 5.41667C18.958 4.80917 18.7163 4.22583 18.2872 3.79583C17.8572 3.36667 17.2738 3.125 16.6663 3.125C13.263 3.125 5.83301 3.125 5.83301 3.125C5.48801 3.125 5.20801 3.405 5.20801 3.75C5.20801 4.095 5.48801 4.375 5.83301 4.375Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M10.8332 15.625H3.33317C3.0565 15.625 2.79234 15.515 2.5965 15.32C2.4015 15.1242 2.2915 14.86 2.2915 14.5833C2.2915 13.6808 2.2915 12.5692 2.2915 11.6667C2.2915 11.39 2.4015 11.1258 2.5965 10.93C2.79234 10.735 3.0565 10.625 3.33317 10.625H7.9165C8.2615 10.625 8.5415 10.345 8.5415 10C8.5415 9.655 8.2615 9.375 7.9165 9.375H3.33317C2.72567 9.375 2.14234 9.61667 1.71234 10.0458C1.28317 10.4758 1.0415 11.0592 1.0415 11.6667V14.5833C1.0415 15.1908 1.28317 15.7742 1.71234 16.2042C2.14234 16.6333 2.72567 16.875 3.33317 16.875H10.8332C11.1782 16.875 11.4582 16.595 11.4582 16.25C11.4582 15.905 11.1782 15.625 10.8332 15.625Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M16.2746 11.2254L15.0505 10.0004L16.2746 8.77544C16.5188 8.53211 16.5188 8.13544 16.2746 7.89211C16.0313 7.64794 15.6346 7.64794 15.3913 7.89211L13.7246 9.55878C13.4805 9.80294 13.4805 10.1979 13.7246 10.4421L15.3913 12.1088C15.6346 12.3529 16.0313 12.3529 16.2746 12.1088C16.5188 11.8654 16.5188 11.4688 16.2746 11.2254Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M10.4415 18.3588L12.1081 16.6921C12.3523 16.4479 12.3523 16.0529 12.1081 15.8088L10.4415 14.1421C10.1981 13.8979 9.80146 13.8979 9.55813 14.1421C9.31396 14.3854 9.31396 14.7821 9.55813 15.0254L10.7823 16.2504L9.55813 17.4754C9.31396 17.7188 9.31396 18.1154 9.55813 18.3588C9.80146 18.6029 10.1981 18.6029 10.4415 18.3588Z'
      fill='white'
    />
  </svg>
);

export const NoRequestsIcon = () => (
  <svg
    width='218'
    height='147'
    viewBox='0 0 218 147'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-label='No Requests'
  >
    <g clipPath='url(#clip0_2596_392)'>
      <path
        d='M147.462 144.699H1.06174C0.475218 144.699 0 145.171 0 145.753C0 146.334 0.475218 146.806 1.06174 146.806H147.461C148.047 146.806 148.522 146.334 148.522 145.753C148.522 145.171 148.047 144.699 147.461 144.699H147.462Z'
        fill='#E0E8FD'
      />
      <path
        d='M103.197 54.5011L101.675 42.6879C101.675 42.6879 110.704 34.2278 104.491 27.8416C100.007 23.2327 93.1257 30.4207 87.4511 31.0957C81.7756 31.7707 76.9092 33.0266 72.9275 44.5728C68.9457 56.119 60.4537 74.1915 58.5654 79.6357L101.323 77.0548L103.197 54.5001V54.5011Z'
        fill='black'
      />
      <path
        d='M129.592 101.684C129.592 101.684 118.364 88.9829 113.798 83.0461C110.021 78.1357 104.406 74.9613 98.2296 74.2239C94.664 73.7985 90.2264 72.2613 85.1355 72.196C78.254 72.1067 70.2654 72.9392 63.2639 74.2239C53.9366 75.9359 45.7002 81.3158 40.3412 89.0885L27.5684 107.616L42.0494 114.628L38.3 144.699H116.132L129.591 101.684H129.592Z'
        fill='#5479F5'
      />
      <path
        d='M46.0003 114.322L31.0837 109.317C31.0837 109.317 13.0041 134.028 24.2826 144.7C35.561 155.371 65.6778 122.082 71.1588 114.573C71.1588 114.573 74.6876 115.386 74.1833 113.071C74.1833 113.071 78.468 114.635 78.468 111.445C78.468 111.445 82.8785 112.579 81.9958 108.445C81.9958 108.445 86.2331 113.371 87.5339 112.714C89.5316 111.706 87.0993 107.566 85.273 104.437C83.4457 101.309 77.0888 93.5709 63.0946 102.063C51.6865 108.985 46.0003 114.324 46.0003 114.324V114.322Z'
        fill='white'
      />
      <path
        d='M29.8205 147.001C27.549 147.001 25.6491 146.284 24.1353 144.853C21.5173 142.375 20.2523 138.948 20.3743 134.663C20.471 131.26 21.4408 127.311 23.2565 122.926C26.3411 115.477 30.8658 109.255 30.9113 109.193C30.98 109.098 31.1136 109.077 31.2084 109.145C31.3033 109.214 31.3246 109.346 31.2559 109.44C31.2104 109.502 26.7118 115.689 23.6475 123.092C20.8369 129.883 18.6912 139.117 24.4286 144.545C27.398 147.355 31.9837 147.246 38.0599 144.222C38.1644 144.169 38.2932 144.211 38.3454 144.316C38.3977 144.421 38.3561 144.547 38.2506 144.599C35.0373 146.199 32.2208 146.999 29.8196 146.999L29.8205 147.001Z'
        fill='black'
      />
      <path
        d='M29.1174 128.454C29.0554 128.454 28.9944 128.428 28.9528 128.378C28.8783 128.287 28.8909 128.154 28.9819 128.08L45.0947 114.947C45.1857 114.873 45.3202 114.885 45.3948 114.975C45.4693 115.066 45.4567 115.199 45.3657 115.273L29.2529 128.406C29.2132 128.439 29.1657 128.454 29.1174 128.454Z'
        fill='black'
      />
      <path
        d='M70.674 114.782C70.6652 114.782 70.6565 114.782 70.6478 114.78C68.835 114.563 67.1074 113.869 65.6527 112.772C65.5588 112.701 65.5404 112.568 65.6121 112.476C65.6827 112.383 65.8163 112.365 65.9102 112.436C67.3048 113.487 68.9608 114.153 70.6991 114.361C70.8162 114.376 70.8995 114.48 70.8849 114.596C70.8724 114.704 70.7804 114.783 70.674 114.783V114.782Z'
        fill='black'
      />
      <path
        d='M74.3956 113.308C74.3588 113.308 74.322 113.298 74.2882 113.279C73.6078 112.884 72.9796 112.404 72.4202 111.852C72.337 111.77 72.336 111.636 72.4202 111.554C72.5034 111.471 72.638 111.47 72.7212 111.554C73.2545 112.08 73.8546 112.539 74.503 112.915C74.6047 112.974 74.6385 113.103 74.5795 113.204C74.5398 113.271 74.4682 113.309 74.3956 113.309V113.308Z'
        fill='black'
      />
      <path
        d='M78.611 111.561C78.5529 111.561 78.4958 111.538 78.4542 111.493L76.9075 109.822C76.8282 109.735 76.834 109.602 76.9201 109.523C77.0072 109.445 77.1417 109.45 77.2211 109.536L78.7677 111.206C78.8471 111.293 78.8413 111.426 78.7552 111.505C78.7145 111.543 78.6622 111.561 78.611 111.561Z'
        fill='black'
      />
      <path
        d='M81.9955 108.656C81.9355 108.656 81.8755 108.631 81.8338 108.582C81.618 108.332 81.4554 108.121 81.2831 107.898C81.0808 107.636 80.8718 107.364 80.565 107.019C80.4875 106.932 80.4953 106.799 80.5834 106.721C80.6714 106.644 80.806 106.652 80.8844 106.739C81.2018 107.094 81.4157 107.372 81.6219 107.641C81.7903 107.86 81.95 108.066 82.1571 108.306C82.2336 108.394 82.2239 108.528 82.1339 108.604C82.0942 108.638 82.0439 108.655 81.9955 108.655V108.656Z'
        fill='black'
      />
      <path
        d='M106.968 113.523L151.265 75.2053C151.265 75.2053 158.012 67.7707 162.581 69.1197C168.023 70.7261 158.642 74.4718 158.01 80.0139C157.378 85.5551 157.588 92.9735 157.588 92.9735C157.588 92.9735 135.737 124.126 118.807 124.49C112.484 124.626 106.968 113.524 106.968 113.524V113.523Z'
        fill='white'
      />
      <path
        d='M145.48 107.83C145.428 107.83 145.376 107.812 145.335 107.773C145.248 107.693 145.244 107.56 145.323 107.475C147.934 104.682 152.995 99.1267 153.458 98.083C153.505 97.9764 153.631 97.9284 153.739 97.9745C153.846 98.0216 153.895 98.1464 153.848 98.253C153.24 99.6212 145.945 107.43 145.635 107.762C145.593 107.807 145.536 107.829 145.479 107.829L145.48 107.83Z'
        fill='black'
      />
      <path
        d='M183.26 64.6478C200.065 64.6478 213.688 51.133 213.688 34.4616C213.688 17.7902 200.065 4.27539 183.26 4.27539C166.456 4.27539 152.833 17.7902 152.833 34.4616C152.833 51.133 166.456 64.6478 183.26 64.6478Z'
        fill='#DFE9FF'
      />
      <path
        d='M197.822 52.486H168.315C168.197 52.486 168.103 52.3909 168.103 52.2747C168.103 52.1585 168.198 52.0635 168.315 52.0635H197.822C197.94 52.0635 198.034 52.1585 198.034 52.2747C198.034 52.3909 197.939 52.486 197.822 52.486Z'
        fill='white'
      />
      <path
        d='M205.635 42.151H162.348C162.23 42.151 162.135 42.0559 162.135 41.9398C162.135 41.8236 162.231 41.7285 162.348 41.7285H205.635C205.753 41.7285 205.848 41.8236 205.848 41.9398C205.848 42.0559 205.752 42.151 205.635 42.151Z'
        fill='white'
      />
      <path
        d='M208.479 31.817H156.547C156.429 31.817 156.334 31.722 156.334 31.6058C156.334 31.4896 156.43 31.3945 156.547 31.3945H208.479C208.597 31.3945 208.692 31.4896 208.692 31.6058C208.692 31.722 208.596 31.817 208.479 31.817Z'
        fill='white'
      />
      <path
        d='M205.635 21.4821H162.774C162.656 21.4821 162.562 21.387 162.562 21.2708C162.562 21.1546 162.657 21.0596 162.774 21.0596H205.635C205.753 21.0596 205.848 21.1546 205.848 21.2708C205.848 21.387 205.752 21.4821 205.635 21.4821Z'
        fill='white'
      />
      <path
        d='M197.821 11.1471H169.647C169.528 11.1471 169.434 11.052 169.434 10.9358C169.434 10.8197 169.529 10.7246 169.647 10.7246H197.821C197.939 10.7246 198.034 10.8197 198.034 10.9358C198.034 11.052 197.938 11.1471 197.821 11.1471Z'
        fill='white'
      />
      <path
        d='M194.668 66.9954C186.843 69.7108 178.392 69.5659 170.591 66.5365C152.759 59.6107 143.933 39.5842 150.914 21.8938C157.895 4.20339 178.081 -4.55346 195.913 2.37233C204.551 5.7272 211.355 12.219 215.072 20.6504C218.789 29.0827 218.973 38.4454 215.591 47.015C212.209 55.5846 205.665 62.3347 197.167 66.0218C196.342 66.379 195.508 66.7045 194.668 66.9964V66.9954ZM154.994 23.479C148.894 38.937 156.608 56.4363 172.189 62.4884C179.737 65.4198 187.985 65.2604 195.411 62.038C202.837 58.8157 208.555 52.9182 211.51 45.4298C214.465 37.9413 214.304 29.7596 211.056 22.3921C207.809 15.0246 201.864 9.35189 194.315 6.42045C178.734 0.368428 161.095 8.02108 154.994 23.479Z'
        fill='black'
      />
      <path
        d='M148.889 107.078C148.299 107.283 147.63 107.265 147.021 106.974C145.806 106.393 145.295 104.944 145.88 103.737L165.674 62.9723C166.259 61.7663 167.72 61.2593 168.937 61.8402C170.152 62.4212 170.663 63.8701 170.078 65.077L150.284 105.842C149.991 106.445 149.48 106.873 148.889 107.078Z'
        fill='black'
      />
      <path
        d='M146.52 81.7787C147.074 83.5195 147.986 85.3333 149.541 86.3972C151.182 87.5206 152.767 87.0396 154.522 86.2647C155.666 85.7587 156.188 84.6756 156.865 83.6559C156.935 82.2022 157.854 81.3774 158.009 80.013C158.641 74.4717 168.034 70.6886 162.581 69.1187C157.895 67.7697 151.264 75.2044 151.264 75.2044L146.019 79.7412C146.144 80.4306 146.308 81.1114 146.52 81.7778V81.7787Z'
        fill='white'
      />
      <path
        d='M151.147 87.4349C150.867 87.4349 150.698 87.4147 150.689 87.4128C150.573 87.3965 150.491 87.2899 150.507 87.1746C150.522 87.0594 150.629 86.9788 150.747 86.9941C150.985 87.0258 156.58 87.6903 157.799 79.981C158.276 76.9612 160.502 74.7076 162.128 73.0629C163.603 71.5698 164.407 70.695 163.791 70.0652C162.58 68.8275 160.6 68.9398 157.558 70.4185C157.452 70.4704 157.324 70.4262 157.273 70.3215C157.221 70.2169 157.265 70.0901 157.371 70.0392C160.602 68.4684 162.74 68.3839 164.097 69.7714C165.033 70.7277 163.885 71.8895 162.433 73.3596C160.759 75.0533 158.676 77.1609 158.221 80.0472C157.142 86.8722 152.611 87.4358 151.147 87.4358L151.147 87.4349Z'
        fill='black'
      />
      <path
        d='M125.74 97.4958C125.681 97.4958 125.621 97.4708 125.58 97.4238C125.502 97.3354 125.512 97.202 125.6 97.1251L151.115 75.0544C151.297 74.8518 153.896 71.9876 157.359 70.0432C157.462 69.9856 157.592 70.0211 157.65 70.1229C157.708 70.2247 157.672 70.3533 157.569 70.411C154.061 72.3803 151.451 75.3146 151.425 75.3434C151.419 75.3501 151.412 75.3569 151.405 75.3636L125.881 97.443C125.84 97.4775 125.791 97.4948 125.74 97.4948V97.4958Z'
        fill='black'
      />
      <path
        d='M158.623 89.1039C158.623 89.1039 160.699 90.0075 161.765 88.4913C162.832 86.9752 161.346 85.2392 159.78 85.2891C158.214 85.3391 156.145 88.0401 158.623 89.1039Z'
        fill='white'
      />
      <path
        d='M159.97 89.5769C159.206 89.5769 158.567 89.3109 158.537 89.2984C157.23 88.7367 157.197 87.8082 157.293 87.3051C157.506 86.1941 158.711 85.113 159.772 85.0784C160.695 85.0477 161.624 85.5835 162.075 86.4092C162.468 87.1274 162.418 87.9311 161.939 88.6119C161.405 89.3723 160.639 89.5769 159.97 89.5769ZM159.844 85.499C159.825 85.499 159.805 85.499 159.786 85.499C158.932 85.5258 157.885 86.4764 157.711 87.3819C157.583 88.0463 157.928 88.5744 158.706 88.9086C158.784 88.9422 160.637 89.7218 161.588 88.368C162.114 87.621 161.883 86.945 161.699 86.608C161.338 85.9474 160.581 85.497 159.843 85.497L159.844 85.499Z'
        fill='black'
      />
      <path
        d='M163.505 78.2882C163.505 78.2882 165.819 79.6978 167.284 78.4448C168.748 77.1917 167.245 75.0956 165.398 74.7634C163.552 74.4302 160.742 76.6204 163.506 78.2882H163.505Z'
        fill='white'
      />
      <path
        d='M165.781 79.1786C164.584 79.1786 163.469 78.5142 163.395 78.469C161.958 77.601 162.052 76.6649 162.236 76.1838C162.643 75.1238 164.197 74.3326 165.436 74.5553C166.644 74.7733 167.729 75.6893 168.015 76.734C168.211 77.4464 168.001 78.1109 167.422 78.6054C166.923 79.0327 166.342 79.1796 165.78 79.1796L165.781 79.1786ZM165.01 74.9413C164.047 74.9413 162.926 75.5703 162.634 76.3336C162.394 76.9616 162.733 77.5751 163.615 78.108C163.637 78.1215 165.814 79.4206 167.143 78.2847C167.716 77.795 167.71 77.2314 167.603 76.8444C167.361 75.963 166.396 75.1574 165.359 74.9711C165.246 74.9509 165.128 74.9413 165.009 74.9413H165.01Z'
        fill='black'
      />
      <path
        d='M160.627 85.5126C160.627 85.5126 163.34 86.7061 164.359 85.4386C165.379 84.1712 164.832 82.9537 164.832 82.9537C164.832 82.9537 166.591 82.3391 166.807 80.75C167.023 79.1609 163.267 77.086 161.341 77.6688C159.415 78.2517 159.553 80.2508 160.33 81.1677C160.33 81.1677 156.544 83.8044 160.628 85.5126H160.627Z'
        fill='white'
      />
      <path
        d='M162.868 86.2334C161.768 86.2334 160.632 85.7456 160.542 85.7062C159.292 85.1829 158.614 84.5098 158.527 83.7042C158.401 82.5366 159.573 81.4881 160.03 81.1271C159.608 80.5145 159.465 79.6743 159.676 78.9667C159.828 78.4578 160.229 77.7837 161.277 77.4669C162.644 77.053 164.783 77.8865 165.981 78.8956C166.729 79.5255 167.096 80.1938 167.017 80.7786C166.828 82.166 165.587 82.8545 165.097 83.0734C165.218 83.4987 165.372 84.5175 164.525 85.5708C164.122 86.072 163.501 86.2334 162.868 86.2334ZM162.083 77.7799C161.838 77.7799 161.608 77.8087 161.402 77.8711C160.716 78.0785 160.261 78.4991 160.086 79.0867C159.896 79.7243 160.063 80.5241 160.493 81.032C160.531 81.0772 160.549 81.1367 160.541 81.1953C160.533 81.2538 160.501 81.3067 160.452 81.3412C160.436 81.3527 158.825 82.4915 158.952 83.6601C159.022 84.3015 159.613 84.8584 160.71 85.3173C160.711 85.3173 160.712 85.3183 160.714 85.3193C160.739 85.3308 163.289 86.4321 164.193 85.3068C165.12 84.1526 164.642 83.0503 164.637 83.0398C164.613 82.986 164.613 82.9246 164.636 82.8708C164.659 82.817 164.705 82.7748 164.761 82.7546C164.777 82.7488 166.4 82.1612 166.596 80.7219C166.654 80.2908 166.331 79.7425 165.707 79.2173C164.783 78.4386 163.258 77.7799 162.084 77.7799H162.083Z'
        fill='black'
      />
      <path
        d='M163.949 82.8228C163.905 82.8228 163.862 82.8103 163.824 82.7825C162.557 81.8761 160.306 81.3787 160.283 81.3739C160.168 81.3489 160.095 81.2366 160.12 81.1223C160.145 81.008 160.26 80.936 160.374 80.961C160.47 80.9821 162.733 81.4814 164.073 82.4397C164.169 82.5079 164.19 82.6404 164.121 82.7345C164.08 82.7921 164.015 82.8218 163.948 82.8218L163.949 82.8228Z'
        fill='black'
      />
      <path
        d='M94.0495 73.5628L99.9554 74.4961C99.9554 74.4961 98.9798 81.3162 94.9322 83.2452C88.042 86.5281 68.5068 73.3755 68.5068 73.3755L78.1506 72.1638C80.2344 71.531 81.8516 69.8901 82.4449 67.8074L86.3599 54.0586L97.2493 59.4884L94.0505 73.5637L94.0495 73.5628Z'
        fill='white'
      />
      <path
        d='M87.021 50.7214C87.021 50.7214 84.3827 46.2949 81.4926 47.1879C77.0889 48.5494 77.3734 56.3663 84.6314 55.9899C84.6314 55.9899 93.2173 66.8841 101.456 63.8605C109.694 60.8369 103.652 42.4139 102.054 39.2549C102.054 39.2549 91.6184 40.1959 90.2934 44.3602C89.1281 48.0223 90.7405 50.7214 87.021 50.7214Z'
        fill='white'
      />
      <path
        d='M98.4674 64.5942C95.7816 64.5942 92.848 63.4593 89.8264 61.2288C86.9789 59.126 84.928 56.691 84.5322 56.2061C80.6192 56.3674 78.8296 54.2339 78.457 52.1676C78.0417 49.866 79.3193 47.6393 81.4283 46.9864C84.2234 46.1223 86.7021 49.8151 87.1386 50.5093C89.3241 50.4738 89.4024 49.4358 89.5428 47.5779C89.6125 46.6619 89.6986 45.5221 90.0887 44.296C91.4408 40.0472 101.601 39.0832 102.033 39.0438C102.12 39.0361 102.203 39.0822 102.243 39.159C103.528 41.7006 107.649 53.9372 105.07 60.4251C104.35 62.237 103.159 63.4593 101.529 64.0575C100.554 64.4156 99.5291 64.5933 98.4664 64.5933L98.4674 64.5942ZM84.6309 55.7788C84.6958 55.7788 84.7587 55.8086 84.7984 55.8604C84.8835 55.968 93.3842 66.5981 101.381 63.6629C102.891 63.1079 104 61.9672 104.674 60.2725C107.161 54.0188 103.262 42.2508 101.927 39.4816C100.669 39.6161 91.6808 40.704 90.4961 44.4247C90.1206 45.6038 90.0403 46.6696 89.9686 47.6105C89.8225 49.5395 89.717 50.9337 87.0205 50.9337C86.9451 50.9337 86.8754 50.8944 86.8376 50.83C86.8125 50.7868 84.2515 46.5582 81.556 47.3907C79.3861 48.0618 78.5557 50.3144 78.877 52.0936C79.2216 54.0015 80.9066 55.9718 84.6203 55.7798C84.6241 55.7798 84.628 55.7798 84.6309 55.7798V55.7788Z'
        fill='black'
      />
      <path
        d='M85.488 53.4797C85.4357 53.4797 85.3825 53.4605 85.3409 53.4211C84.3179 52.4571 82.8235 52.0289 81.4414 52.3025C81.3262 52.3256 81.214 52.2507 81.1907 52.1364C81.1675 52.0222 81.243 51.9108 81.3582 51.8877C82.8738 51.5882 84.5124 52.0577 85.6342 53.1139C85.7193 53.1945 85.7232 53.328 85.6419 53.4125C85.6003 53.4567 85.5441 53.4788 85.487 53.4788L85.488 53.4797Z'
        fill='black'
      />
      <path
        d='M108.921 108.726L96.4484 142.151H58.1396C57.6025 142.151 57.1234 142.482 56.9366 142.983C56.6259 143.815 57.2463 144.699 58.1396 144.699H133.716C134.653 144.699 135.491 144.12 135.816 143.248L148.258 109.906C148.672 108.797 147.845 107.618 146.654 107.618H110.524C109.809 107.618 109.169 108.06 108.92 108.726H108.921Z'
        fill='black'
      />
      <path
        d='M208.479 134.393H139.2C139.082 134.393 138.987 134.298 138.987 134.182C138.987 134.065 139.083 133.97 139.2 133.97H208.266V124.491C208.266 124.373 208.362 124.279 208.479 124.279C208.597 124.279 208.692 124.374 208.692 124.491V134.182C208.692 134.299 208.597 134.393 208.479 134.393Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_2596_392'>
        <rect width='218' height='147' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
