import React, { useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import LeadInformation from 'app/views/dashboard/leads/NewLeads/LeadInformation';
import Location from 'app/views/dashboard/leads/NewLeads/Location';
import Document from 'app/views/dashboard/leads/NewLeads/Document';
import Images from 'app/views/dashboard/leads/NewLeads/Images';
import LlcDetailes from 'app/views/dashboard/leads/NewLeads/LlcDetailes';
import Members from 'app/views/dashboard/leads/NewLeads/Members';
import { useNavigate } from 'react-router-dom';
import ContractDetails from 'app/views/dashboard/leads/NewLeads/ContractDetails';
import InvestMentDetails from 'app/views/dashboard/leads/NewLeads/InvestMentDetails';
import {
  projectDeleteLeadInformation,
  uploadProjectDocuments,
  projectLeadInformation,
  createUpdateProjectLLC,
  createUpdateProjectLocation,
  addProjectMember,
} from 'app/contexts/projectActions';
import { handleAxiosError } from 'app/utils/helpers';
import {
  ButtonSkip,
  ModalButtonCancle,
  ModalButtonSave,
} from 'app/views/dashboard/profile/StyleComponents';
import { TickIcon } from './icons';
import { documentTypes } from 'app/utils/constant';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'app/react-query/queryKeys';
import { createUpdateProjectInvestments } from 'app/contexts/projectActions';
import { convertDayJSDateToUTC } from 'app/utils/helpers';
import { createUpdateProjectContract } from 'app/contexts/projectActions';
import { useProjectUpdateStage } from 'app/views/dashboard/leads/LeadsData/Stages/hooks/useProjectUpdateStage';
import { projectTypes } from 'app/utils/constant';

const steps = ['Project Details', 'Location', 'Documents', 'Images', 'Organization', 'Members', 'Contract Details', 'Investment Details'];

const ContentBox = styled(Box)({
  display: 'flex',
  height: '100vh',
});

const StepContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '10px',
  border: `1px solid ${theme.palette.text.border}`,
  maxHeight: 'calc(100vh - 150px)',
  overflowY: 'auto',
}));

const Sidebar = styled(Paper)(({ theme }) => ({
  width: '200px',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
  backgroundColor: '#F9F9F9',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  height: '100%',
  paddingLeft: theme.spacing(1),
}));

const StepButton = styled(Box)(({ active, theme }) => ({
  display: 'block',
  width: '100%',
  padding: '8px',
  textAlign: 'left',
  backgroundColor: active ? `${theme.palette.text.white}` : 'transparent',
  color: `${theme.palette.text.black}`,
  fontWeight: active ? 'bold' : 'normal',
  borderRadius: '0 8px 8px 0',
  border: active ? `1px solid ${theme.palette.text.border}` : '1px solid transparent',
  borderLeft: active ? `5px solid ${theme.palette.text.buttonbg}` : 'none',
  textTransform: 'none',
}));

function getStepContent(
  stepIndex,
  formData,
  setFormData,
  leadLocationData,
  setLeadLocationData,
  document,
  setDocuments,
  LLCDetails,
  setLLCData,
  members,
  setMembers,
  contractDetails,
  setContractDetails,
  investmentDetails,
  setInvestmentDetails,
  setDisable,
  projectId,
  type
) {
  switch (stepIndex) {
    case 0:
      return (
        <LeadInformation formData={formData} setFormData={setFormData} setDisable={setDisable} />
      );
    case 1:
      return (
        <Location
          formData={leadLocationData}
          setFormData={setLeadLocationData}
          setDisable={setDisable}
        />
      );
    case 2:
      return <Document formData={document} setFormData={setDocuments} setDisable={setDisable} />;
    case 3:
      return <Images formData={document} setFormData={setDocuments} setDisable={setDisable} />;
    case 4:
      return <LlcDetailes formData={LLCDetails} setFormData={setLLCData} setDisable={setDisable} />;
    case 5:
      return (
        <Members
          formData={members}
          setFormData={setMembers}
          setDisable={setDisable}
          projectId={projectId}
        />
      );
    case 6:
      return type !== "lead" ? (
        <ContractDetails
          formData={contractDetails}
          setFormData={setContractDetails}
          setDisable={setDisable}
        />
      ) : null;
    case 7:
      return type !== "lead" ? (
        <InvestMentDetails
          formData={investmentDetails}
          setFormData={setInvestmentDetails}
          setDisable={setDisable}
        />
      ) : null;
    default:
      return 'Step not found';
  }
}



export default function StepperForm({ type }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    projectName: '',
    estimatedPrice: '',
    size: '',
    propertyType: '',
    subType: '',
    referenceLink: ''
  });
  const [leadLocationData, setLeadLocationData] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    latitude: null,
    longitude: null,
  });
  const [document, setDocuments] = useState({
    documents: [{ docName: '', file: null, type: '' }],
  });  
  const [LLCDetails, setLLCData] = useState({
    llcName: '',
    einNum: '',
    address1: '',
    address2: '',
    country: '',
    city: '',
    state: '',
    zipCode: '',
    latitude: '',
    longitude: '',
  });
  const [members, setMembers] = useState({
    members: [{ name: '', phone: '', email: '', userRoles: [] }],
  });
  const [contractDetails, setContractDetails] = useState({
    effectiveDate: '',
    feasibilityDueDate: '',
    earnestMoney: '',
    closingDate: '',
    optionsMoney: '',
    documents: []
  });
  const [investmentDetails, setInvestmentDetails] = useState({
    estimatedTotalInvestment: '',
    landCost: '',
    softCost: '',
    totalShares: '',
    eachShareValue: ''
  });
  const [disable, setDisable] = useState(true);
  const [projectId, setProjectId] = useState('');
  const [completedSteps, setCompletedSteps] = useState([false, false, false, false, false, false, false, false]);
  const { mutate: projectMoveToNew } = useProjectUpdateStage(projectId, projectTypes.NEW_PROJECT, { previousPType: 'newproject' });
  const [savedContractData, setSavedContractData] = useState(null);

  const handleCancel = async () => {
    if (projectId) {
      try {
        await projectDeleteLeadInformation(projectId);
      } catch (error) {
        handleAxiosError(error);
      }
    }

    if (type === 'lead') {
      navigate('/listing');
    } else {
      navigate('/myprojects');
    }
  };
  const handleNext = async () => {
    setLoading(true);
    let nextStep = activeStep;
    try {
      switch (activeStep) {
        case 0: {
          const data = {
            projectTitle: formData.projectName,
            estimatedPrice: formData.estimatedPrice,
            size: formData.size,
            description: formData.description,
            propertyType: formData.propertyType.label,
            subType: formData.subType.label,
            referenceLink: formData.referenceLink,
          };
          const result = await projectLeadInformation({ previousProjectType: 'lead', ...data });
          if (result && result.data) {
            setProjectId(result.data.data);
          }
          nextStep = 1;
          break;
        }
        case 1: {
          const data = {
            address1: leadLocationData.address1,
            address2: leadLocationData.address2,
            city: leadLocationData.city,
            state: leadLocationData.state,
            country: leadLocationData.country,
            zipCode: leadLocationData.zipCode,
            latitude: leadLocationData.latitude,
            longitude: leadLocationData.longitude,
          };
          await createUpdateProjectLocation(projectId, data);
          nextStep = 2;
          break;
        }
        case 2:
          nextStep = 3;
          break;
          case 3: {
            const data = new FormData();
            data.append('projectId', projectId);
          
            document.documents.forEach((doc, index) => {
              if (doc.file) {
                data.append(`docReq[${index}].file`, doc.file);
                data.append(`docReq[${index}].docName`, doc.docName);
                data.append(`docReq[${index}].type`, doc.type || documentTypes.DUE_DILIGENCE);
              }
            });
          
            await uploadProjectDocuments(projectId, data);
            nextStep = 4;
            break;
          }
          
        case 4: {
          const data = {
            llcName: LLCDetails.llcName,
            einNum: LLCDetails.einNum,
            address1: LLCDetails.address1,
            address2: LLCDetails.address2,
            country: LLCDetails.country,
            state: LLCDetails.state,
            city: LLCDetails.city,
            zipCode: LLCDetails.zipCode,
          };
          await createUpdateProjectLLC(projectId, data);
          nextStep = 5;
          break;
        }
        case 5: {
          console.log("in members")
          const memberRequests = members.members.map((member) => {
            const memberData = {
              name: member.name,
              phone: member.phone,
              email: member.email,
              userRoles: member.userRoles.map((role) => role.name),
            };
            return addProjectMember(projectId, memberData);
          });
          await Promise.allSettled(memberRequests);
          await queryClient.invalidateQueries({ queryKey: [queryKeys.projectsCounts] });

          if (type === 'lead') {
            navigate('/listing');
            nextStep = 0;
            break;
          }
          nextStep = 6;
          break;
        }
        case 6: {
          const contractData = {
            effectiveDate: convertDayJSDateToUTC(contractDetails.effectiveDate),
            feasibilityEndDate: convertDayJSDateToUTC(contractDetails.feasibilityDueDate),
            earnestMoney: contractDetails.earnestMoney,
            estimatedClosingDate: convertDayJSDateToUTC(contractDetails.closingDate),
            optionsMoney: contractDetails.optionsMoney,
          };
          setSavedContractData(contractData);
          nextStep = 7;
          break;
        }
        case 7: {
          const investmentData = {
            projectId: projectId,
            totalInvestment: investmentDetails.estimatedTotalInvestment,
            landCost: investmentDetails.landCost,
            softCost: investmentDetails.softCost,
            totalShare: investmentDetails.totalShares,
            eachSharePrice: investmentDetails.eachShareValue,
          };
          projectMoveToNew({ documents: contractDetails.documents, contract: savedContractData, investments: investmentData }, {
            onSuccess: () => {
              navigate(`/myprojects`);
            },
            onSettled: () => setLoading(false),
          }
          );
          nextStep = 7;
          break;
        }
        default:
          break;
      }
      if (activeStep < 7) {
        const newCompletedSteps = [...completedSteps];
        newCompletedSteps[activeStep] = true;
        setCompletedSteps(newCompletedSteps);
      } else {
        setCompletedSteps([false, false, false, false, false, false, false, false]);
      }

      setActiveStep(nextStep);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };


  const uploadDocuments = async () => {
    const data = new FormData();
    data.append('projectId', projectId);
    let docIndex = 0;

    document.documents.forEach((doc) => {
      if (doc.file) {
        data.append(`docReq[${docIndex}].file`, doc.file);
        data.append(`docReq[${docIndex}].docName`, doc.docName);
        data.append(`docReq[${docIndex}].type`, documentTypes.DUE_DILIGENCE);
        docIndex++;
      }
    });

    await uploadProjectDocuments(projectId, data);
  };

  const handleSkip = () => {
    if (type === 'lead' && activeStep === 5) {
      navigate('/listing');
      setCompletedSteps(Array(steps.slice(0, -2).length).fill(false));
      setActiveStep(0);
    } else if (type !== 'lead' && activeStep === 7) {
      navigate('/myprojects');
      setCompletedSteps(Array(steps.length).fill(false));
      setActiveStep(0);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  return (
    <ContentBox>
      <Sidebar>
        {type === 'lead'
          ? steps.slice(0, -2).map((step, index) => (
            <Box key={step} display="flex" alignItems="center" width="100%" marginBottom="8px">
              {completedSteps[index] && <TickIcon />}
              <StepButton active={activeStep === index}>{step}</StepButton>
            </Box>
          ))
          : steps.map((step, index) => (
            <Box key={step} display="flex" alignItems="center" width="100%" marginBottom="8px">
              {completedSteps[index] && <TickIcon />}
              <StepButton active={activeStep === index}>{step}</StepButton>
            </Box>
          ))}
      </Sidebar>

      <StepContentBox>
        <Typography variant="h5" gutterBottom>
          {type === 'lead' ? steps.slice(0, -2)[activeStep] : steps[activeStep]}
        </Typography>

        {getStepContent(
          activeStep,
          formData,
          setFormData,
          leadLocationData,
          setLeadLocationData,
          document,
          setDocuments,
          LLCDetails,
          setLLCData,
          members,
          setMembers,
          contractDetails,
          setContractDetails,
          investmentDetails,
          setInvestmentDetails,
          setDisable,
          projectId,
          type
        )}

        <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
          {activeStep > 0 && activeStep < 6 && (
            <ButtonSkip
              className="skipbtn"
              variant="outlined"
              onClick={handleSkip}
              style={{ marginRight: "auto" }}
            >
              Skip
            </ButtonSkip>
          )}

          <Box display="flex" gap={2} justifyContent="flex-end" width="100%">
            <ModalButtonCancle variant="outlined" onClick={handleCancel}>
              Cancel
            </ModalButtonCancle>
            <ModalButtonSave
              variant="contained"
              color="primary"
              onClick={
                  handleNext
              }
              loading={loading}
              disabled={disable}
            >
              {type === 'lead'
                ? activeStep === steps.slice(0, -2).length - 1
                  ? 'Finish'
                  : 'Save & Continue'
                : activeStep === steps.length - 1
                  ? 'Finish'
                  : 'Save & Continue'}
            </ModalButtonSave>
          </Box>
        </Box>
      </StepContentBox>
    </ContentBox>
  );
}