import { Box, styled, Modal, Typography, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '4px',
  marginTop: '10px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const LeadsSuccess = ({ open, handleClose, to }) => (
  <Modal open={open} onClose={handleClose}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        width: '30%',
        height: '35%',
      }}
    >
      <Box sx={{ marginLeft: '4px' }}>
        <Container>
          <Typography>
            <CheckCircleIcon
              style={{
                fontSize: '115px',
                color: 'green',
              }}
            />
          </Typography>
          <Typography style={{ fontSize: '22px', fontWeight: '550' }}>
            Project Successfully moved to
            <Grid style={{ marginLeft: '55px' }}>{`the ${to}`}</Grid>
          </Typography>
        </Container>
        <ModalDivider />
      </Box>
    </Box>
  </Modal>
);

export default LeadsSuccess;
