import DialogContent from '@mui/material/DialogContent';
import { Formik } from 'formik';
import { formatDecimal } from 'app/utils/helpers';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectInvestmentsCreateUpdate } from './hooks/useProjectInvestmentsCreateUpdate';
import { IconButton, Modal } from '@mui/material';
import { StyledLabel } from '../../listings/Modals/StyledComponents';
import CloseIcon from '@mui/icons-material/Close';
import { ModalCancel, ModalDivider, ModalSave, ModalStyledBox, ModalStyledSubBox, StyledBoxFooter, StyledTextField, Title } from 'app/common/Typography';

function EditInvestments({ investments, toggleModal }) {
  const { mutate: updateProjectInvestments } = useProjectInvestmentsCreateUpdate(
    investments.projectId,
  );

  const initialValues = { ...investments };

  const handleSubmit = (values, { setSubmitting }) => {
    const investedShares = investments.totalShare - investments.availableShares;
    const updatedAvailableShares = values.totalShare - investedShares;
    const updatedValues = { ...values, availableShares: updatedAvailableShares };

    updateProjectInvestments(updatedValues, {
      onSuccess: toggleModal,
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Modal open={true} onClose={toggleModal}>
      <ModalStyledBox sx={{ width: '35%' }}>
        <ModalStyledSubBox>
          <Title>Update Investments</Title>
          <IconButton onClick={toggleModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemas.editInvestments({
          investedAmount: investments.investedAmount,
          investedShares: investments.totalShare - investments.availableShares,
        })}
        onSubmit={handleSubmit}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          setValues,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => {
          const calculateTotalInvestment = (cost1, cost2) => {
            const total = cost1 + cost2;
            return isNaN(total) ? '' : formatDecimal(total, 2);
          };

          const calculateTotalShare = (investment, sharePrice) => {
            const total = investment / sharePrice;
            return isNaN(total) ? '' : formatDecimal(total, 2);
          };

          const handleFieldChange = (e) => {
            const { name, value } = e.target;
            const parsedValue = parseFloat(value);

            const calculationMap = {
              landCost: (() => {
                const softCost = parseFloat(values.softCost);
                const totalInvestment = calculateTotalInvestment(parsedValue, softCost);
                const totalShare = calculateTotalShare(totalInvestment, values.eachSharePrice);

                return {
                  landCost: formatDecimal(value, 2),
                  totalInvestment,
                  totalShare,
                };
              })(),
              softCost: (() => {
                const landCost = parseFloat(values.landCost);
                const totalInvestment = calculateTotalInvestment(landCost, parsedValue);
                const totalShare = calculateTotalShare(totalInvestment, values.eachSharePrice);

                return {
                  softCost: formatDecimal(value, 2),
                  totalInvestment,
                  totalShare,
                };
              })(),
              eachSharePrice: (() => ({
                eachSharePrice: formatDecimal(value, 2),
                totalShare: calculateTotalShare(values.totalInvestment, parsedValue),
              }))(),
            };

            setValues({
              ...values,
              ...calculationMap[name],
            });
          };

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <StyledLabel>Land Cost</StyledLabel>
                <StyledTextField
                  fullWidth
                  variant='outlined'
                  name='landCost'
                  type='number'
                  value={values.landCost}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  placeholder='e.g. $30000.00'
                  helperText={touched.landCost && errors.landCost}
                  error={Boolean(errors.landCost && touched.landCost)}
                  disabled={isSubmitting}
                />
                <StyledLabel>Soft Cost</StyledLabel>
                <StyledTextField
                  fullWidth
                  variant='outlined'
                  name='softCost'
                  type='number'
                  value={values.softCost}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  placeholder='e.g. $30000.00'
                  helperText={touched.softCost && errors.softCost}
                  error={Boolean(errors.softCost && touched.softCost)}
                  disabled={isSubmitting}
                />
                <StyledLabel>Each share price</StyledLabel>
                <StyledTextField
                  fullWidth
                  variant='outlined'
                  name='eachSharePrice'
                  type='number'
                  value={values.eachSharePrice}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  placeholder='e.g. $30000.00'
                  helperText={touched.eachSharePrice && errors.eachSharePrice}
                  error={Boolean(errors.eachSharePrice && touched.eachSharePrice)}
                  disabled={isSubmitting}
                />
                <StyledLabel>Total Investment</StyledLabel>
                <StyledTextField
                  fullWidth
                  variant='outlined'
                  name='totalInvestment'
                  type='number'
                  value={values.totalInvestment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='e.g. $30000.00'
                  helperText={touched.totalInvestment && errors.totalInvestment}
                  error={Boolean(errors.totalInvestment && touched.totalInvestment)}
                  inputProps={{
                    readOnly: true,
                  }}
                />
                <StyledLabel>Total Share</StyledLabel>
                <StyledTextField
                  fullWidth
                  variant='outlined'
                  name='totalShare'
                  type='number'
                  value={values.totalShare}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='e.g. $30000.00'
                  helperText={touched.totalShare && errors.totalShare}
                  error={Boolean(errors.totalShare && touched.totalShare)}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </DialogContent>
              <StyledBoxFooter>
                <ModalCancel onClick={toggleModal} disabled={isSubmitting} variant='outlined'>
                  Cancel
                </ModalCancel>
                <ModalSave variant='contained' type='submit' color='primary' sx={{ textTransform: 'none' }} loading={isSubmitting}>
                  Update
                </ModalSave>
              </StyledBoxFooter>
            </form>
          );
        }}
      </Formik>
      </ModalStyledBox>
    </Modal>
  );
}

export default EditInvestments;
