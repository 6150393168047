import React from 'react';
import { Typography, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledBox1,
  StyledModalTitle,
  StyledCrossIcon,
  StyledBox2,
  StyledBox3,
  StyledBox4,
  ModalButtonCancle,
  ModalButtonSave,
  StyledBox,
  SectionContainer,
  SectionTitle,
  PDSubHeadings,
  PDSubValues,
} from './StyledComponents';
import { useProfileLLC } from 'app/views/dashboard/profile/hooks/useProfileLLC';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { ModalCancel, ModalContainer, ModalDivider, ModalSave, ModalStyledBox, ModalStyledSubBox, StyledBoxFooter, Title } from 'app/common/Typography';

const ChangeOrgModal = ({ handleCloseModal, assignedLLC }) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState('Rockwell Ventures');
  const { userNameJWT } = useUserAuth();
  const { data: llcdata, isLoading, isError } = useProfileLLC(userNameJWT);

  const formatLLCAddress = (llc) => {
    if (!llc) return '';

    const { address1, address2, city, state, zipCode, country } = llc;
    const addressParts = [address1, address2, city, state, country, zipCode];
    return addressParts.filter((part) => part).join(' \n');
  };

  const address = assignedLLC ? formatLLCAddress(assignedLLC) : "";

  const organizations = [
    {
      category: 'Assigned Company',
      items: assignedLLC ? [{
        name: assignedLLC?.llcName,
        ein: assignedLLC?.einNum,
        address: address,
      }] : [],
    },
    {
      category: 'Available Companies',
      items: llcdata ? llcdata.map((item) => ({
        name: item.llcName,
        ein: item.einNum,
        address: formatLLCAddress(item),
      })) : [],
    },
  ];

  const handleSave = () => {
    if (selectedOrganization) {
      console.log('Selected Organization Details:', selectedOrganization);
    } else {
      console.log('No organization selected.');
    }
    handleCloseModal();
  };

  const isEmpty = organizations.every(section => section.items.length === 0);

  return (
    <>
      <ModalStyledBox sx={{ width: '35%' }}>
        <ModalStyledSubBox>
          <Title>Change Organization</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
        {/* <ModalContainer> */}
          <StyledBox3 sx={{padding: '20px', paddingTop: '0px'}}>
            {isEmpty ? (
              <Grid container justifyContent='center' alignItems='center' sx={{ height: '200px' }}>
                <Typography variant='h6' color='textSecondary'>
                  No available companies
                </Typography>
              </Grid>
            ) : (
              organizations.map((section) => (
                <SectionContainer key={section.category}>
                  <SectionTitle>{section.category}</SectionTitle>
                  {section.items.length === 0 ? (
                    <Typography variant='body2' color='textSecondary' sx={{ textAlign: 'center' }}>
                      No {section.category} available
                    </Typography>
                  ) : (
                    section.items.map((item) => (
                      <StyledBox
                        key={`${section.category}-${item.name}`}
                        isSelected={selectedOrganization === item.name}
                        onClick={() => setSelectedOrganization(item.name)}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{ fontWeight: '600', fontSize: '20px', color: '#17181B' }}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <PDSubHeadings>EIN/License Number:</PDSubHeadings>
                            <PDSubValues>{item.ein}</PDSubValues>
                          </Grid>
                          <Grid item xs={6}>
                            <PDSubHeadings>Address:</PDSubHeadings>
                            <PDSubValues>{item.address}</PDSubValues>
                          </Grid>
                        </Grid>
                      </StyledBox>
                    ))
                  )}
                </SectionContainer>
              ))
            )}
          </StyledBox3>
        {/* </ModalContainer> */}
        <StyledBoxFooter>
          <ModalCancel variant='outlined' onClick={handleCloseModal}>
            Cancel
          </ModalCancel>
          <ModalSave type='submit' onClick={handleSave}>
            Save
          </ModalSave>
        </StyledBoxFooter>
      </ModalStyledBox>
    </>
  );
};

export default ChangeOrgModal;
