import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomGCExpensesInputText } from 'app/common/Typography';
import { DownloadDragIcon, PdfIcon, DocIcon } from 'app/common/icons';
import {
  Box,
  IconButton,
  styled,
  Input,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
// import { parseAddressComponents } from 'app/utils/helpers';
import notify from 'app/utils/notify';
import { Formik, getIn, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Correct Date Adapter
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '10px',
  marginBottom: '1em',
  paddingRight: '10px',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const SearchBoxContainer = styled('div')({
  width: '100%',
  '& input': {
    padding: '0.3em 0.5em',
    height: '2.710em',
    width: '100%',
    boxSizing: 'border-box',
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  border: '1px solid lightgray',
  backgroundColor: '#F8F8F8',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '20vh',
  overflowY: 'auto',
  width: '100%',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

const validationSchema = Yup.object().shape({
  lenderName: Yup.string().required('Lender Name is required'),
  lenderEmail: Yup.string().email('Invalid email format').required('Email is required'),
  lenderPhone: Yup.string().required('Phone Number is required')
    .matches(/^[0-9]+$/, 'Phone Number must only contain digits')
    .length(10, 'Phone Number must be exactly 10 digits long'),
  lenderAddress: Yup.string().required('Address is required').min(5, 'Address must be at least 5 characters long').max(100, 'Address must be 100 characters or less'),
  lenderCity: Yup.string().required('City is required'),
  lenderState: Yup.string().required('State is required'),
  lenderZipCode: Yup.string().required('ZipCode is required'),
  lenderCountry: Yup.string().required('Country is required'),

  lenderContactPersonName: Yup.string().required('Contact Person Name is required'),
  lenderContactPersonPhone: Yup.string().required('Phone Number is required')
    .matches(/^[0-9]+$/, 'Phone Number must only contain digits')
    .length(10, 'Phone Number must be exactly 10 digits long'),
  lenderContactPersonEmail: Yup.string().email('Invalid email format').required('Contact Person Email is required'),

  loanApprovedAmount: Yup.number()
    .positive('Approved Amount must be positive')
    .required('Loan Approved Amount is required'),

  loanWithdrawalAmount: Yup.number()
    .min(0, 'Withdrawal Amount must be at least 0')
    .max(Yup.ref('loanApprovedAmount'), 'Withdrawal Amount cannot be greater than Loan Approved Amount')
    .required('Loan Withdrawal Amount is required'),
  interestRate: Yup.number().positive('InterestRate must be positive').required('Interest Rate is required'),
  loanType: Yup.string().required('LoanType is required'),
  loanStartDate: Yup.date().required('Loan Start Date is required').typeError('Invalid Date formate'),
  loanTerm: Yup.number().integer().positive('LoanTerm must be a positive integer').required('Loan Term is required'),
  comments: Yup.string(),
  documents: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().required('Document is required'),
      docName: Yup.string().required('Document Name is required'),
    })
  ),
});

const ConstructionLenderDetailsModal = ({ contractors, contractor, handleCloseModal, formatDate }) => {
  const { documents, createConstructionLender, updateConstructionLender, uploadDocuments } = useProject();

  const mapRef = React.useRef(null);
  const markerRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  const parseAddressComponents = (place) => {
    let lenderAddress = '';
    let address2 = '';
    let lenderCountry = '';
    let lenderCity = '';
    let lenderState = '';
    let lenderZipCode = '';
    let latitude = null;
    let longitude = null;
  
    if (place.address_components) {
      const {
        address_components,
        geometry: { location },
        name,
      } = place;
      const { lat, lng } = location;
      latitude = lat();
      longitude = lng();
  
      address_components.forEach((component) => {
        const [type] = component.types;
        const longName = component.long_name;
  
        switch (type) {
          case 'landmark':
          case 'subpremise':
          case 'premise':
            address2 += address2 ? `, ${longName}` : longName;
            break;
          case 'route':
          case 'street_address':
          case 'street_number':
            lenderAddress += lenderAddress ? `, ${longName}` : longName;
            break;
          case 'country':
            lenderCountry = longName;
            break;
          case 'locality':
          case 'postal_town':
          case 'administrative_area_level_3':
            lenderCity ||= longName;
            break;
          case 'administrative_area_level_1':
            lenderState = longName;
            break;
          case 'postal_code':
            lenderZipCode = longName;
            break;
          default:
            break;
        }
      });
  
      if (!lenderAddress && name) {
        lenderAddress = name;
      }
    }
    return {
      lenderAddress,
      address2,
      lenderCountry,
      lenderCity,
      lenderState,
      lenderZipCode,
      latitude,
      longitude,
    };
  };
  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));

    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  function handleFileChange(event, values, setFieldValue) {
    const files = Array.from(event.target.files);
    const updatedDocuments = [
      ...values.documents,
      ...files.map((file) => ({ file, docName: '' })),
    ];
    setFieldValue('documents', updatedDocuments);
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDeleteFile = (index, values, setFieldValue) => {
    const updatedDocuments = [...values.documents];
    updatedDocuments.splice(index, 1);
    setFieldValue('documents', updatedDocuments);
  };

  const handleDrop = (event, values, setFieldValue) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const updatedDocuments = [
      ...values.documents,
      ...files.map((file) => ({ file, docName: '' })),
    ];
    setFieldValue('documents', updatedDocuments);
  };

  function base64ToFile(base64String, fileName, mimeType) {
    const byteString = atob(base64String);
    const byteNumbers = new Array(byteString.length)
      .fill(null)
      .map((_, i) => byteString.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], fileName, { type: mimeType });
  }

  const initialValues = {
    lenderName: contractor?.lenderName || '',
    lenderPhone: contractor?.lenderPhone || '',
    lenderEmail: contractor?.lenderEmail || '',
    lenderAddress: contractor?.lenderAddress || '',
    lenderCity: contractor?.lenderCity || '',
    lenderState: contractor?.lenderState || '',
    lenderCountry: contractor?.lenderCountry || '',
    lenderZipCode: contractor?.lenderZipCode || '',

    lenderContactPersonName: contractor?.lenderContactPerson?.lenderContactPersonName || '',
    lenderContactPersonPhone: contractor?.lenderContactPerson?.lenderContactPersonPhone || '',
    lenderContactPersonEmail: contractor?.lenderContactPerson?.lenderContactPersonEmail || '',

    loanApprovedAmount: contractor?.loanDetails?.loanApprovedAmount || '',
    loanWithdrawalAmount: contractor?.loanDetails?.loanWithdrawalAmount !== undefined && contractor?.loanDetails?.loanWithdrawalAmount !== null ? contractor?.loanDetails?.loanWithdrawalAmount : '',
    interestRate: contractor?.loanDetails?.interestRate || '',
    loanType: contractor?.loanDetails?.loanType || '',
    loanStartDate: contractor?.loanDetails?.loanStartDate || '',
    loanTerm: contractor?.loanDetails?.loanTerm || '',
    comments: contractor?.comments || '',
    documents: documents.filter((doc) => contractor?.loanDetails?.loanDocuments?.[0]?.includes(doc.metadata.fileId))
      .map((doc, index) => {
        const mimeType = doc?.metadata?.documentType || 'application/pdf';
        const extension = mimeType === 'application/pdf' ? 'pdf' : 'docx';
        return {
          file: base64ToFile(doc.content, `${doc.metadata.documentName}.${extension}`, mimeType),
          docName: doc.metadata.documentName || `Document ${index + 1}`,
        };
      }),
  };
  const convertToUTC = (date) => {
    const localDate = new Date(date);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    return utcDate.toISOString();
  };


  const handleFormSubmit = async (values, { setSubmitting }) => {

    if (values.documents.length === 0) {
      notify.error('Please add at least one document.');
      setSubmitting(false);
      return;
    }

    const data = new FormData();
    const documentIds = [];

    for (let index = 0; index < values.documents.length; index++) {
      const doc = values.documents[index];
      const isExistingDocument = initialValues?.documents.some(
        (existingDoc) => existingDoc.docName === doc.docName && existingDoc.docContent === doc.docContent
      );

      if (!isExistingDocument && doc.file instanceof File) {
        data.append(`docReq[${index}].file`, doc.file);
        data.append(`docReq[${index}].docName`, doc.docName);
        data.append(`docReq[${index}].type`, "Construction Lender documents");
      } else {
        documentIds.push(contractor?.loanDetails?.loanDocuments?.[index]);
      }
    }


    try {
      if (data.has('docReq[0].file')) {
        const uploadResponse = await uploadDocuments(data);
        const uploadedDocumentIds = uploadResponse.data.match(/Document IDs: \[([^\]]+)\]/)[1].split(', ');
        documentIds.push(...uploadedDocumentIds);
      }

      const contractorDetails = {
        lenderName: values.lenderName,
        lenderPhone: values.lenderPhone,
        lenderEmail: values.lenderEmail,
        lenderAddress: values.lenderAddress,
        lenderCity: values.lenderCity,
        lenderState: values.lenderState,
        lenderCountry: values.lenderCountry,
        lenderZipCode: values.lenderZipCode,
        lenderContactPerson: {
          lenderContactPersonName: values.lenderContactPersonName,
          lenderContactPersonPhone: values.lenderContactPersonPhone,
          lenderContactPersonEmail: values.lenderContactPersonEmail,
        },
        loanDetails: {
          loanApprovedAmount: values.loanApprovedAmount,
          loanWithdrawalAmount: values.loanWithdrawalAmount,
          interestRate: values.interestRate,
          loanType: values.loanType,
          loanStartDate: convertToUTC(values.loanStartDate),
          loanTerm: values.loanTerm,
          loanDocuments: documentIds,
        },
        loanPayments: contractor?.loanPayments || [],
        loanWithdrawal: contractor?.loanWithdrawal || [],
        comments: values.comments,
      };

      const updateData = {
        ...contractor,
        ...contractorDetails
      }

      if (contractor) {
        await updateConstructionLender(contractor?.projectId, contractor?.id, updateData);
      } else {
        await createConstructionLender(contractorDetails);
      }
      handleCloseModal();
    } catch (error) {
      if (error.response.data.error) {
        notify.error(error.response.data.errors[0].errorMessage);
      } else {
        notify.error(`Error in Construction Lender: ${error.message}`);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '90vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{contractor ? 'Edit' : 'Add'} Construction Lender Details</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon style={{ fontSize: 20, color: '#272937' }} />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setValues,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>LENDER NAME</InputLabel></Inputlabel>
                      <TextField
                        type='text'
                        name='lenderName'
                        value={values.lenderName}
                        onChange={handleChange}
                        placeholder="e.g. : John Doe"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderName && errors.lenderName)}
                        helperText={touched.lenderName && errors.lenderName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>Email Address</InputLabel></Inputlabel>
                      <TextField
                        type='text'
                        name='lenderEmail'
                        value={values.lenderEmail}
                        onChange={handleChange}
                        placeholder="e.g. : john.doe@example.com"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderEmail && errors.lenderEmail)}
                        helperText={touched.lenderEmail && errors.lenderEmail}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>Phone Number</InputLabel></Inputlabel>
                      <TextField
                        type='tel'
                        name='lenderPhone'
                        value={values.lenderPhone}
                        onChange={handleChange}
                        placeholder="e.g. 9876543210"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderPhone && errors.lenderPhone)}
                        helperText={touched.lenderPhone && errors.lenderPhone}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>Address</InputLabel></Inputlabel>
                      <SearchBoxContainer>
                        <StandaloneSearchBox
                          onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                          onUnmount={() => (searchInputRef.current = null)}
                          onPlacesChanged={() => handlePlacesChanged(setValues)}
                        >
                          <TextField
                            sx={{ marginTop: '0px', height: '0px' }}
                            fullWidth
                            margin='normal'
                            variant='outlined'
                            type='search'
                            name='lenderAddress'
                            id='standard-basic'
                            placeholder='Search With Place, Landmark'
                            value={values?.lenderAddress}
                            onChange={(e) => {
                              const { value } = e.target;

                              if (!value) {
                                setValues((prevState) => ({
                                  ...prevState,
                                  search: '',
                                  lenderAddress: '',
                                  lenderCountry: '',
                                  lenderCity: '',
                                  lenderState: '',
                                  lenderZipCode: '',
                                  latitude: null,
                                  longitude: null,
                                }));
                                if (markerRef.current) {
                                  markerRef.current.setPosition(null);
                                }
                              }
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            helperText={touched.lenderAddress && errors.lenderAddress}
                            error={Boolean(errors.lenderAddress && touched.lenderAddress)}
                            disabled={isSubmitting}
                          />
                        </StandaloneSearchBox>
                      </SearchBoxContainer>

                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>City</InputLabel></Inputlabel>
                      <TextField
                        type='text'
                        name='lenderCity'
                        value={values.lenderCity}
                        onChange={handleChange}
                        placeholder="e.g., Houston"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderCity && errors.lenderCity)}
                        helperText={touched.lenderCity && errors.lenderCity}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>State</InputLabel></Inputlabel>
                      <TextField
                        type='text'
                        name='lenderState'
                        value={values.lenderState}
                        onChange={handleChange}
                        placeholder="e.g., Texas"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderState && errors.lenderState)}
                        helperText={touched.lenderState && errors.lenderState}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>Zip Code</InputLabel></Inputlabel>
                      <TextField
                        name='lenderZipCode'
                        value={values.lenderZipCode}
                        onChange={handleChange}
                        placeholder="e.g. 123456"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderZipCode && errors.lenderZipCode)}
                        helperText={touched.lenderZipCode && errors.lenderZipCode}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>Country</InputLabel></Inputlabel>
                      <TextField
                        type='text'
                        name='lenderCountry'
                        value={values.lenderCountry}
                        onChange={handleChange}
                        placeholder="e.g., United States"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderCountry && errors.lenderCountry)}
                        helperText={touched.lenderCountry && errors.lenderCountry}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>LENDER CONTACT NAME</InputLabel></Inputlabel>
                      <TextField
                        type='text'
                        name='lenderContactPersonName'
                        value={values.lenderContactPersonName}
                        onChange={handleChange}
                        placeholder="e.g. : John Doe"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderContactPersonName && errors.lenderContactPersonName)}
                        helperText={touched.lenderContactPersonName && errors.lenderContactPersonName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>Email Address</InputLabel></Inputlabel>
                      <TextField
                        type='text'
                        name='lenderContactPersonEmail'
                        value={values.lenderContactPersonEmail}
                        onChange={handleChange}
                        placeholder="e.g. : john.doe@example.com"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderContactPersonEmail && errors.lenderContactPersonEmail)}
                        helperText={touched.lenderContactPersonEmail && errors.lenderContactPersonEmail}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>Phone Number</InputLabel></Inputlabel>
                      <TextField
                        type='tel'
                        name='lenderContactPersonPhone'
                        value={values.lenderContactPersonPhone}
                        onChange={handleChange}
                        placeholder="e.g. 9876543210"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.lenderContactPersonPhone && errors.lenderContactPersonPhone)}
                        helperText={touched.lenderContactPersonPhone && errors.lenderContactPersonPhone}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>LOAN APPROVED AMOUNT</InputLabel></Inputlabel>
                      <TextField
                        name='loanApprovedAmount'
                        value={values.loanApprovedAmount}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: Boolean(contractor?.loanBalance)||contractor?.loanBalance===0,
                          sx: Boolean(contractor?.loanBalance)||contractor?.loanBalance===0
                            ? {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                            : {},
                        }}
                        placeholder="e.g. 300000"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.loanApprovedAmount && errors.loanApprovedAmount)}
                        helperText={touched.loanApprovedAmount && errors.loanApprovedAmount}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>LOAN WITHDRAWAL AMOUNT</InputLabel></Inputlabel>
                      <TextField
                        name='loanWithdrawalAmount'
                        value={values.loanWithdrawalAmount}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: Boolean(contractor?.loanBalance)||contractor?.loanBalance===0,
                          sx: Boolean(contractor?.loanBalance)||contractor?.loanBalance===0
                            ? {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                            : {},
                        }}
                        placeholder="e.g. 20000"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.loanWithdrawalAmount && errors.loanWithdrawalAmount)}
                        helperText={touched.loanWithdrawalAmount && errors.loanWithdrawalAmount}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>INTEREST RATE</InputLabel></Inputlabel>
                      <TextField
                        name='interestRate'
                        value={values.interestRate}
                        onChange={handleChange}
                        placeholder="e.g. 2%"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.interestRate && errors.interestRate)}
                        helperText={touched.interestRate && errors.interestRate}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>LOAN TYPE</InputLabel></Inputlabel>
                      <TextField
                        name='loanType'
                        value={values.loanType}
                        onChange={handleChange}
                        placeholder="e.g. Home equity"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.loanType && errors.loanType)}
                        helperText={touched.loanType && errors.loanType}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel><InputLabel>LOAN TERM</InputLabel></Inputlabel>
                      <TextField
                        name='loanTerm'
                        value={values.loanTerm}
                        onChange={handleChange}
                        placeholder="e.g. 20 years"
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.loanTerm && errors.loanTerm)}
                        helperText={touched.loanTerm && errors.loanTerm}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Inputlabel><InputLabel>LOAN START DATE</InputLabel></Inputlabel>
                        <DatePicker
                          name="loanStartDate"
                          value={dayjs(values.loanStartDate)}
                          onChange={(newValue) => setFieldValue('loanStartDate', newValue)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              variant: 'outlined',
                              error: Boolean(errors.loanStartDate && touched.loanStartDate),
                              helperText: touched.loanStartDate && errors.loanStartDate
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px', marginLeft: '3%' }} container spacing={2}>
                    <Typography style={{ fontSize: '15px', fontWeight: '450', marginTop: '0.8em', marginBottom: '0.5em' }}>
                      Loan Document
                    </Typography>
                    <Container
                      style={{ border: '2px dashed lightgray', width: '97%' }}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, values, setFieldValue)}
                    >
                      <Typography variant='body1' marginTop={1} marginBottom={1} color={'#64748B'} fontSize={'16px'}>
                        <DownloadDragIcon />
                        {` Drag and Drop file here or `}
                        <label htmlFor='outlined-button-file'>
                          <StyledButton
                            disabled={values.documents?.length > 0}
                            variant='outlined' component='span'>
                            Browse files
                          </StyledButton>
                        </label>
                      </Typography>
                      <Input
                        id={`outlined-button-file`}
                        sx={{
                          display: 'none',
                        }}
                        type='file'
                        size='small'
                        name='file'
                        onChange={(e) => handleFileChange(e, values, setFieldValue)}
                        inputProps={{ accept: '.pdf, .doc, .docx' }}
                        error={Boolean(
                          getIn(errors, `documents[0].file`) &&
                          getIn(touched, `documents[0].file`),
                        )}
                        helperText={
                          getIn(errors, `documents[0].file`) &&
                            getIn(touched, `documents[0].file`)
                            ? 'Document is required'
                            : ''
                        }
                      />
                    </Container>
                    <Typography variant='body1' color={'#64748B'} fontSize={'12px'} >
                      {`File format only word or Pdf, Size should not exceed 10MB`}
                    </Typography>
                  </Grid>
                  <FileContainerDetails>
                    <FieldArray name='documents'>
                      {({ remove }) =>
                        values?.documents?.map((doc, index) => (
                          <FileContainer key={index}>
                            <ImageContainer>
                              {doc.file.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                              <FileDetails>
                                <Typography
                                  variant='body1'
                                  style={{ fontWeight: 'bold', marginLeft: '10px' }}
                                >
                                  {doc.file.name}
                                </Typography>
                                <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                  {(doc.file.size / 1024).toFixed(2)} KB
                                </Typography>
                              </FileDetails>
                              <IconButton
                                onClick={() => handleDeleteFile(index, values, setFieldValue)}
                              >
                                <DeleteIcon style={{ color: 'red' }} />
                              </IconButton>
                            </ImageContainer>
                            <ModalDivider />
                            <Grid container spacing={2} >
                              <Grid item xs={12}>

                                <CustomGCExpensesInputText
                                  name={`documents[${index}].docName`}
                                  placeholder='Document Name'
                                  touched={touched}
                                  value={doc.docName}
                                  onChange={handleChange}
                                  error={Boolean(
                                    getIn(errors, `documents[${index}].docName`) &&
                                    getIn(touched, `documents[${index}].docName`),
                                  )}
                                  helperText={
                                    getIn(errors, `documents[${index}].docName`) &&
                                      getIn(touched, `documents[${index}].docName`)
                                      ? 'Document Name is required'
                                      : ''
                                  }

                                />
                              </Grid>
                            </Grid>
                          </FileContainer>
                        ))
                      }
                    </FieldArray>
                  </FileContainerDetails>
                </Container>
                <Grid item xs={8}>
                  <TextField
                    label='Comments'
                    name='comments'
                    value={values.comments}
                    onChange={handleChange}
                    fullWidth
                    size='small'
                    type='text'
                    variant='outlined'
                    placeholder='Write Here'
                    multiline
                    rows={1.5}
                    sx={{ mb: 1, ml: 1.5, width: '95%' }}
                  />
                </Grid>
                <Box
                  marginLeft={1.5}
                  marginRight={1}
                  sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <LoadingButton
                    className='cancelButton'
                    color='error'
                    variant='outlined'
                    onClick={handleCloseModal}
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    style={{ boxShadow: 'none', marginRight: '10px' }}
                    type='submit'
                    loading={isSubmitting}
                  >
                    {contractor ? "Update" : "Save"}
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default ConstructionLenderDetailsModal;


