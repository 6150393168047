import { styled, Box } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useRef, useState } from 'react';
import { Formik } from 'formik';
import { GoogleMap, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import notify from 'app/utils/notify';
import { delay, handleAxiosError, parseAddressComponents } from 'app/utils/helpers';
import * as Yup from 'yup';
import { DEFAULT_CENTER } from 'app/utils/constant';
import { useProject } from 'app/contexts/ProjectContext';
import { LoadingButton } from '@mui/lab';

const noLocationSelectedToastId = 'noLocationSelectedToastId';

const validationSchema = Yup.object().shape({
  address1: Yup.string().required('Address1 is required!'),
  city: Yup.string().required('City is required!'),
  state: Yup.string().required('State is required!'),
  zipCode: Yup.string().required('Zip Code is required!'),
  country: Yup.string().required('Country is required'),
});

const Container = styled('div')(() => ({
  marginTop: '5px',
  marginBottom: '24px',
  overflow: 'hidden',
}));

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '90px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#272937',
  fontWeight: 'bold',
  display: 'block',
});

// REVIEW: review to create one reusable component for new and existing lead both
const LeadLocationDetails = () => {
  const { isOnHoldOrCancelled, location, updateDetailsLocation } = useProject();

  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const searchInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formikValues, setFormikValues] = useState({
    address1: '',
    address2: '',
    state: '',
    zipCode: '',
    city: '',
    country: '',
    latitude: '',
    longitude: '',
    ...location,
  });

  const handleEditClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    try {
      setIsLoading(true);
      const geocoder = new window.google.maps.Geocoder();

      const results = await new Promise((resolve, reject) => {
        geocoder.geocode(
          { location: new window.google.maps.LatLng(lat, lng) },
          (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
              resolve(results[0]);
            } else {
              console.error('Geocode failed:', status);
              reject(new Error('Geocode was not successful for the following reason: ' + status));
            }
          },
        );
      });

      const addressData = parseAddressComponents(results);

      setFormikValues((prevState) => ({
        ...prevState,
        ...addressData,
      }));

      if (markerRef.current) {
        markerRef.current.setPosition({ lat, lng });
      }

      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
      }
    } catch (error) {
      console.error('Places search or details error:', error);
    } finally {
      setIsLoading(false);
      handleEditClick();
    }
  };

  const handleChangeWithValidation = (e, handleChange, values) => {
    if (!values.latitude || !values.longitude) {
      if (!notify.isActive(noLocationSelectedToastId)) {
        notify.error('Please select a location from either the search or the map first.', {
          toastId: noLocationSelectedToastId,
        });
      }
      return;
    }

    handleChange(e);
  };
  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces(); // BUG: Can`t await for this, sync operation
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));

    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  const handleMapLoad = (map) => {
    const lat = Number(location?.latitude);
    const lng = Number(location?.longitude);
    mapRef.current = map;

    if (!lat || !lng) {
      mapRef.current.setCenter(DEFAULT_CENTER);
      return;
    }
    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }

    if (mapRef.current) {
      mapRef.current.setCenter({ lat, lng });
    }
  };

  const handleCancel = async () => {
    handleClose();
    await delay(150);
    setFormikValues((prevState) => ({
      ...prevState,
      ...location,
    }));
  };

  const formatLocation = () => {
    if (location) {
      const { address1, address2, city, state, zipCode, country } = location;
      const addressParts = [address1, address2, city, state, zipCode, country];
      return addressParts.filter((part) => part).join(', ');
    }
    return 'No location provided';
  };

  const locationInformation = formatLocation();

  return (
    <div className='viewlead-1'>
      <SubtitleDivider />
      <Box lg={6} md={6} sm={12} xs={12} padding={2}>
        <InfoContainer item lg={6} md={6} sm={12} xs={12} marginRight={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box>
              <Key>PROPERTY ADDRESS</Key>
              <Value className='basicvalues'>{locationInformation}</Value>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                style={{ color: '#4F90F0', fontSize: '14px' }}
                onClick={handleEditClick}
                disabled={isLoading || isOnHoldOrCancelled}
              >
                <BorderColorIcon sx={{ fontSize: '13.5px' }} />
                Edit
              </Button>
            </Box>
          </Box>
        </InfoContainer>
        <GoogleMap
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '250px' }}
          onLoad={handleMapLoad}
          onUnmount={() => {
            mapRef.current = null;
          }}
          onClick={isOnHoldOrCancelled ? null : handleMapClick}
        >
          <Marker
            animation={window.google.maps.Animation.DROP}
            draggable={!isOnHoldOrCancelled}
            onLoad={(marker) => {
              markerRef.current = marker;
            }}
            onUnmount={() => {
              markerRef.current = null;
            }}
            onDragEnd={handleMapClick}
          />
        </GoogleMap>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <Formik
          initialValues={formikValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await updateDetailsLocation(values);
              handleClose();
            } catch (e) {
              handleAxiosError(e);
            }
          }}
        >
          {({
            values,
            isSubmitting,
            errors,
            touched,
            setValues,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>Update Location Details</DialogTitle>
              <DialogContent>
                <StandaloneSearchBox
                  onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                  onUnmount={() => (searchInputRef.current = null)}
                  onPlacesChanged={() => handlePlacesChanged(setValues)}
                >
                  <TextField
                    fullWidth
                    margin='normal'
                    variant='outlined'
                    type='search'
                    name='address1'
                    label='Address line 1'
                    id='standard-basic'
                    placeholder='Search With Place, Landmark'
                    value={values?.address1}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (!value) {
                        setValues((prevState) => ({
                          ...prevState,
                          search: '',
                          address1: '',
                          address2: '',
                          country: '',
                          city: '',
                          state: '',
                          zipCode: '',
                          latitude: null,
                          longitude: null,
                        }));
                        if (markerRef.current) {
                          markerRef.current.setPosition(null);
                        }
                      }
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    helperText={touched.address1 && errors.address1}
                    error={Boolean(errors.address1 && touched.address1)}
                    disabled={isSubmitting}
                  />
                </StandaloneSearchBox>
                <TextField
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  type='text'
                  name='address2'
                  label='Address line 2 (optional)'
                  value={values?.address2}
                  onChange={(e) => handleChangeWithValidation(e, handleChange, values)}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
                <TextField
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  label='City'
                  name='city'
                  value={values?.city}
                  onChange={(e) => handleChangeWithValidation(e, handleChange, values)}
                  onBlur={handleBlur}
                  helperText={touched.city && errors.city}
                  error={Boolean(errors.city) && touched.city}
                  disabled={isSubmitting}
                />
                <TextField
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  type='text'
                  name='state'
                  label='State'
                  value={values?.state}
                  onChange={(e) => handleChangeWithValidation(e, handleChange, values)}
                  onBlur={handleBlur}
                  helperText={touched.state && errors.state}
                  error={Boolean(errors.state && touched.state)}
                  disabled={isSubmitting}
                />
                <TextField
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  type='text'
                  label='Zip Code'
                  name='zipCode'
                  value={values?.zipCode}
                  onChange={(e) => handleChangeWithValidation(e, handleChange, values)}
                  onBlur={handleBlur}
                  helperText={touched.zipCode && errors.zipCode}
                  error={Boolean(errors.zipCode && touched.zipCode)}
                  disabled={isSubmitting}
                />
                <TextField
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  name='country'
                  label='Country'
                  value={values?.country}
                  onChange={(e) => handleChangeWithValidation(e, handleChange, values)}
                  onBlur={handleBlur}
                  helperText={touched.country && errors.country}
                  error={Boolean(errors.country && touched.country)}
                  disabled={isSubmitting}
                />
              </DialogContent>
              <DialogActions>
                <LoadingButton onClick={handleCancel} disabled={isSubmitting}>
                  Cancel
                </LoadingButton>
                <LoadingButton type='submit' color='primary' loading={isSubmitting}>
                  Update
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default LeadLocationDetails;
