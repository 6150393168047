// import React from 'react'
// import { IconButton, Divider, ListItemText, } from "@mui/material";
// import CloseIcon from '@mui/icons-material/Close';
// import { DescriptionIcon, LCDeleteIcon } from 'app/common/icons';
// import LoadingButton from '@mui/lab/LoadingButton';
// import {
//     StyledBox1, StyledModalTitle, StyledCrossIcon, StyledBox2,
//     StyledBox3, StyledBox4, FileDropArea, DragDropText,
//     SupportedFilesText, UploadLink, UploadedList, ModalButtonCancle,
//     ModalButtonSave, FilesListSec, ItemText, ItemIcon,
// } from './StyledComponents';

// const AddImagesModal = ({ handleCloseModal }) => {

//     const [uploadedFiles, setUploadedFiles] = React.useState([]);


//     const handleFileUpload = (e) => {
//         const files = Array.from(e.target.files);
//         const validFiles = files.filter(
//             (file) =>
//                 ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type) &&
//                 file.size <= 10 * 1024 * 1024
//         );
//         setUploadedFiles((prev) => [...prev, ...validFiles]);
//     };
    

//     const handleRemoveFile = (index) => {
//         setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
//     };

//     const handleSave = () => {
//         // Logic to save uploaded files
//         handleCloseModal();
//     };
//     return (
//         <>
//             <StyledBox1>
//                 <StyledBox2  >
//                     <StyledModalTitle>Add Images</StyledModalTitle>
//                     <StyledCrossIcon onClick={handleCloseModal}>
//                         <CloseIcon />
//                     </StyledCrossIcon>
//                 </StyledBox2>
//                 <StyledBox3>

//                     <FileDropArea>
//                         <input
//                             type="file"
//                             multiple
//                             style={{ display: "none" }}
//                             id="file-upload"
//                             onChange={handleFileUpload}
//                         />
//                         <label htmlFor="file-upload">
//                             <DragDropText>
//                                 Drag and Drop or <span style={{ textDecoration: "underline" }}>Click To Upload</span>
//                             </DragDropText>
//                         </label>
//                     </FileDropArea>
//                     <SupportedFilesText>
//                         Supported files : .png, .jpg, .jpeg Max file size :10MB
//                     </SupportedFilesText>
//                     <Divider />
//                     <UploadLink>
//                         Uploaded Images
//                     </UploadLink>
//                     <UploadedList>
//                         {uploadedFiles.map((doc, index) => (
//                             <FilesListSec key={doc.id}>
//                                 <ItemIcon>
//                                     <DescriptionIcon />
//                                 </ItemIcon>
//                                 <ListItemText
//                                     primary={
//                                         <ItemText>
//                                             {doc?.name}
//                                         </ItemText>
//                                     }
//                                 />
//                                 <IconButton edge="end" onClick={() => handleRemoveFile(index)}>
//                                     <LCDeleteIcon />
//                                 </IconButton>
//                             </FilesListSec>
//                         ))}
//                     </UploadedList>
//                 </StyledBox3>

//                 <StyledBox4  >
//                     <ModalButtonCancle
//                         variant='outlined'
//                         onClick={handleCloseModal}
//                     // disabled={isSubmitting}
//                     >
//                         Cancel
//                     </ModalButtonCancle>
//                     <ModalButtonSave
//                         type='submit'
//                     // loading={isSubmitting}
//                     >
//                         {true ? 'Update' : 'Save'}
//                     </ModalButtonSave>
//                 </StyledBox4>
//             </StyledBox1>
//         </>
//     )
// }

// export default AddImagesModal


import React, { useState } from 'react';
import { Typography, IconButton, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DescriptionIcon, DocIcon, LCDeleteIcon, PdfIcon } from 'app/common/icons';
import {
  ModalCancel,
  ModalDivider,
  ModalSave,
  ModalStyledBox,
  ModalStyledSubBox,
  StyledBoxFooter,
  Title,
} from 'app/common/Typography';
import { Formik } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectDocumentsUpload } from '../hooks/useProjectDocumentsUpload';
import { notify } from 'app/services/notify';
import { FileDropArea, UploadLink, UploadedList,  FilesListSec, ItemText, ItemIcon, StyledDocumentBox } from './StyledComponents';


const AddImagesModal = ({ handleCloseModal, projectId }) => {
  const { mutate: uploadDocuments } = useProjectDocumentsUpload(projectId);
  const [isLoading, setIsLoading] = useState(false);
  const handleSave = (values) => {
    setIsLoading(true);
    if (values.documents.length === 0) {
      notify.error('Please upload at least one document!');
      setIsLoading(false);
      return;
    }

    uploadDocuments(values.documents, {
      onSuccess: () => {
        notify.success('Documents uploaded successfully!');
        setIsLoading(false);
        handleCloseModal();
      },
      onError: (error) => {
        setIsLoading(false);
        console.error('An error occurred while uploading the documents.');
      },
    });
  };

  const handleRemoveFile = (index) => {
    // setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
};
  const getFileIcon = (mimeType) => {
    switch (mimeType) {
      case 'application/pdf':
        return <PdfIcon />;
      case 'application/msword':
        return <DocIcon />;
      default:
        return <DocIcon />;
    }
  };

  return (
    <>
      <ModalStyledBox sx={{ width: '40%' }}>
        <ModalStyledSubBox>
          <Title>Add Image</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon style={{ fontSize: 20, color: 'var(--closeIcon)' }} />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
        <StyledDocumentBox>
          <Formik
            initialValues={{
              documents: [],
            }}
            // validationSchema={validationSchemas.documents()}
            onSubmit={(values) => handleSave(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              validateForm,
            }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validateForm().then((errors) => {
                    if (Object.keys(errors).length > 0) {
                      notify.error('Document name and type are required!');
                    } else {
                      handleSubmit(e);
                    }
                  });
                }}
              >
                <FileDropArea>
                <input
                 accept='image/*'
                  type="file"
                  multiple
                  style={{ display: 'none' }}
                  id="file-upload"
                  onChange={(event) => {
                    const files = Array.from(event.target.files);
                    setFieldValue(
                      'documents',
                      [...values.documents, ...files.map((file) => ({
                        file,
                        docName: file.name,
                        type: 'Due Diligence', 
                      }))]
                    );
                  }}
                />

                  <label htmlFor="file-upload">
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#282A2D',
                      }}
                    >
                      Drag and Drop or{' '}
                      <span style={{ textDecoration: 'underline' }}>Click To Upload</span>
                    </Typography>
                  </label>
                </FileDropArea>
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#282A2D',
                    marginLeft: '4%',
                    marginTop: '0.5rem',
                    marginBottom: '2rem',
                  }}
                >
                  Supported files: .png, .jpeg, .jpg | Max file size: 10MB
                </Typography>
                <UploadLink>
                        Uploaded Images
                    </UploadLink>
                    <UploadedList>
                        {values.documents.map((doc, index) => (
                            <FilesListSec key={doc.id}>
                                <ItemIcon>
                                    <DescriptionIcon />
                                </ItemIcon>
                                <ListItemText
                                    primary={
                                        <ItemText>
                                            {doc.file.name}
                                        </ItemText>
                                    }
                                />
                                <IconButton edge="end" onClick={() => handleRemoveFile(index)}>
                                    <LCDeleteIcon />
                                </IconButton>
                            </FilesListSec>
                        ))}
                    </UploadedList>
                
                <StyledBoxFooter sx={{ marginTop: '7rem' }}>
                  <ModalCancel variant="outlined" onClick={handleCloseModal}>
                    Cancel
                  </ModalCancel>
                  <ModalSave
                    type="submit"
                    loading={isLoading}
                  >
                    Save
                  </ModalSave>
                </StyledBoxFooter>
              </form>
            )}
          </Formik>
        </StyledDocumentBox>
      </ModalStyledBox>
    </>
  );
};

export default AddImagesModal;
