import { API_ENDPOINTS } from 'app/contexts/apiConstants';
import axiosService from 'app/contexts/axiosService';

export const getComments = async (projectId) => {
  try {
    const comments = await axiosService.Get(API_ENDPOINTS.COMMENTS.GET_ALL_COMMENTS(projectId));
    return comments;
  } catch (error) {
    throw error;
  }
};

export const createComment = async (projectId, parentId = null, obj) => {
  try {
    if (parentId) {
      await axiosService.Post(
        API_ENDPOINTS.COMMENTS.CREATE_COMMENT_REPLY(projectId, parentId),
        obj,
      );
    } else {
      await axiosService.Post(API_ENDPOINTS.COMMENTS.CREATE_COMMENT(projectId), obj);
    }
  } catch (error) {
    throw error;
  }
};

export const updateComment = async (commentId, projectId, obj) => {
  try {
    await axiosService.Put(API_ENDPOINTS.COMMENTS.UPDATE_COMMENT(commentId, projectId), obj);
  } catch (error) {
    throw error;
  }
};

export const deleteComment = async (commentId, projectId) => {
  try {
    await axiosService.Delete(API_ENDPOINTS.COMMENTS.DELETE_COMMENT(commentId, projectId));
  } catch (error) {
    throw error;
  }
};
