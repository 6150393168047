import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form } from 'formik';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, FormControlLabel, ListItemText, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { selectAllOptions } from 'app/utils/constant';
import { useProjectRolesCreateUpdate } from './hooks/useProjectRolesCreateUpdate';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { usePermissionsExpansion } from './hooks/usePermissionsExpansion';
import { Footer, ModalContent } from 'app/common/Typography';

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

function RoleAddEdit({ projectId, selectedRole, closeModal }) {
  const { services } = useUserAuth();
  const { mutate: createUpdateRole } = useProjectRolesCreateUpdate(projectId, selectedRole);
  const [expandedPermissions, handlePermissionsExpand] = usePermissionsExpansion();

  const isEditingMode = !!selectedRole;

  const selectAllOption = [selectAllOptions.services.name, selectAllOptions.services];

  const isAllServicesSelected = selectedRole?.services?.length === services.length;

  const initialServices = isAllServicesSelected
    ? [selectAllOption, ...selectedRole?.services]
    : selectedRole?.services || [];

  const initialValues = {
    searchTerm: '',
    name: selectedRole?.name || '',
    services: initialServices,
    permissions: selectedRole?.permissions || {},
  };

  const handleSubmit = (values, { setSubmitting }) => {
    createUpdateRole(
      {
        data: values,
        allServices: services,
      },
      {
        onSuccess: closeModal,
        onSettled: () => setSubmitting(false),
      },
    );
  };

  const servicesOptions = [selectAllOption, ...services];

  return (
    <Modal
      open={true}
      onClose={closeModal}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '0%',
          transform: 'translate(0%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '60%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <ModalContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DialogTitle>{`${isEditingMode ? 'Edit' : 'Add'} Role`}</DialogTitle>
          <IconButton onClick={closeModal} sx={{ marginRight: 2 }}>
            <Close />
          </IconButton>
        </Box>
        <DialogDivider />
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.role()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
              setValues,
            }) => {
              const handleServiceSelect = (_, newServices, reason, { option } = { option: [] }) => {
                const [selectedServiceName] = option;
                const isSelectAllOption = selectedServiceName === selectAllOptions.services.name;

                const actionsMap = {
                  clear: () => {},
                  selectOption: () => {
                    const newPermissions = { ...values.permissions };

                    if (isSelectAllOption) {
                      services.forEach(([serviceName]) => {
                        if (!newPermissions[serviceName]) {
                          newPermissions[serviceName] = {
                            read: [],
                            write: [],
                          };
                        }
                      });

                      setValues({
                        ...values,
                        services: servicesOptions,
                        permissions: newPermissions,
                      });

                      return;
                    }

                    setValues({
                      ...values,
                      services: newServices,
                      permissions: {
                        ...newPermissions,
                        [selectedServiceName]: {
                          read: [],
                          write: [],
                        },
                      },
                    });
                  },
                  removeOption: () => {
                    if (isSelectAllOption) {
                      setValues({
                        ...values,
                        services: [],
                        permissions: {},
                      });
                      return;
                    }

                    const { [selectedServiceName]: removedService, ...newPermissions } =
                      values.permissions;

                    setValues({
                      ...values,
                      services: newServices,
                      permissions: newPermissions,
                    });
                  },
                };

                actionsMap[reason]();
              };

              return (
                <Form>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <InputLabel
                        htmlFor='name'
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: '#272937',
                        }}
                      >
                        Role name
                      </InputLabel>
                      <TextField
                        fullWidth
                        id='name'
                        name='name'
                        placeholder='e.g. Role 1'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        disabled={isSubmitting}
                        InputProps={{
                          readOnly: isEditingMode,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: '#272937',
                        }}
                        htmlFor='services'
                      >
                        Services
                      </InputLabel>
                      <Autocomplete
                        multiple
                        id='services'
                        options={servicesOptions}
                        disableCloseOnSelect
                        noOptionsText='No services found'
                        getOptionLabel={([serviceName]) => serviceName}
                        value={values.services}
                        isOptionEqualToValue={([serviceName], [selectedServiceName]) =>
                          serviceName === selectedServiceName
                        }
                        onChange={handleServiceSelect}
                        inputValue={values.searchTerm}
                        onInputChange={(_, value, reason) => {
                          if (reason === 'reset') {
                            return;
                          }
                          setFieldValue('searchTerm', value);
                        }}
                        ListboxProps={{
                          sx: {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                          },
                        }}
                        renderOption={(props, [serviceName, service], { selected }) => {
                          const isSelectAllOption = serviceName === selectAllOptions.services.name;

                          return (
                            <MenuItem
                              {...props}
                              key={serviceName}
                              value={service}
                              sx={{
                                whiteSpace: 'normal',
                                wordBreak: 'break-word',
                                gridColumn: isSelectAllOption ? 'span 3' : 'span 1',
                              }}
                            >
                              <Checkbox
                                checked={
                                  isSelectAllOption
                                    ? values.services.length === servicesOptions.length
                                    : selected
                                }
                              />
                              <ListItemText primary={service.displayName} />
                            </MenuItem>
                          );
                        }}
                        renderTags={() => null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder='Select services'
                            name='searchTerm'
                            onBlur={handleBlur}
                            error={touched.searchTerm && Boolean(errors.services)}
                            helperText={touched.searchTerm && errors.services}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={2}>
                    {values.services.map(([serviceName, service]) => {
                      const isSelectAllOption = serviceName === selectAllOptions.services.name;

                      if (isSelectAllOption) {
                        return null;
                      }

                      const readPermissions = Object.values(service.actions.read);
                      const writePermissions = Object.values(service.actions.write);

                      const handleSelectAll = (e, isReadPermissions) => {
                        const { checked } = e.target;

                        if (!checked) {
                          setFieldValue(
                            `permissions.${serviceName}.${isReadPermissions ? 'read' : 'write'}`,
                            [],
                          );
                          return;
                        }

                        setFieldValue(
                          `permissions.${serviceName}.${isReadPermissions ? 'read' : 'write'}`,
                          isReadPermissions
                            ? readPermissions.map((permission) => permission.identifier)
                            : writePermissions.map((permission) => permission.identifier),
                        );
                      };

                      const handleSelectReadPermission = (e) => {
                        const hasWritePermissions =
                          values.permissions[serviceName]?.write?.length > 0;

                        if (hasWritePermissions) {
                          return;
                        }

                        handleChange(e);
                      };

                      const handleSelectWritePermission = (e) => {
                        const { checked } = e.target;

                        if (checked) {
                          handleSelectAll(e, true);
                        }

                        handleChange(e);
                        handlePermissionsExpand(`${serviceName}-read`, true);
                      };

                      const serviceError = errors.permissions?.[serviceName];
                      const serviceTouched = touched.permissions?.[serviceName];

                      return (
                        <Grid item xs={12} key={serviceName}>
                          <FormControl fullWidth error={serviceTouched && Boolean(serviceError)}>
                            <Accordion
                              elevation={0}
                              disableGutters
                              sx={{
                                backgroundColor: '#F2F3F5',
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMore fontSize='large' fontWeight='600' />}
                                sx={{
                                  flexDirection: 'row-reverse',
                                  '& .MuiAccordionSummary-expandIconWrapper': {
                                    transform: 'rotate(-90deg)',
                                  },
                                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                    transform: 'rotate(0deg)',
                                  },
                                }}
                              >
                                <Typography variant='h6' fontWeight='600'>
                                  {service.displayName}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={1}>
                                  {readPermissions.length > 0 && (
                                    <Grid item xs={12}>
                                      <Accordion
                                        elevation={0}
                                        disableGutters
                                        expanded={expandedPermissions.includes(
                                          `${serviceName}-read`,
                                        )}
                                        onChange={(_, shouldExpand) =>
                                          handlePermissionsExpand(
                                            `${serviceName}-read`,
                                            shouldExpand,
                                          )
                                        }
                                        sx={{
                                          backgroundColor: 'inherit',
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={
                                            expandedPermissions.includes(`${serviceName}-read`) ? (
                                              <RemoveIcon />
                                            ) : (
                                              <AddIcon />
                                            )
                                          }
                                          sx={{
                                            display: 'inline-flex',
                                            flexDirection: 'row-reverse',
                                            gap: 1,
                                            '& .MuiAccordionSummary-expandIconWrapper': {
                                              transform: 'none !important',
                                            },
                                          }}
                                        >
                                          <Typography>Read Permissions</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ ml: 4 }}>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <FormControlLabel
                                                aria-label={`read-${serviceName}-${selectAllOptions.permissions.identifier}`}
                                                label={selectAllOptions.permissions.displayName}
                                                name={`permissions.${serviceName}.read`}
                                                onChange={(event) => handleSelectAll(event, true)}
                                                onBlur={handleBlur}
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      values.permissions[serviceName]?.read
                                                        ?.length === readPermissions.length
                                                    }
                                                  />
                                                }
                                              />
                                            </Grid>
                                            {readPermissions.map((permission) => (
                                              <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={permission.identifier}
                                              >
                                                <FormControlLabel
                                                  aria-label={`read-${serviceName}`}
                                                  label={permission.displayName}
                                                  name={`permissions.${serviceName}.read`}
                                                  onChange={handleSelectReadPermission}
                                                  onBlur={handleBlur}
                                                  control={
                                                    <Checkbox
                                                      value={permission.identifier}
                                                      checked={values.permissions[
                                                        serviceName
                                                      ]?.read?.includes(permission.identifier)}
                                                    />
                                                  }
                                                />
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                  )}
                                  {writePermissions.length > 0 && (
                                    <Grid item xs={12}>
                                      <Accordion
                                        elevation={0}
                                        disableGutters
                                        expanded={expandedPermissions.includes(
                                          `${serviceName}-write`,
                                        )}
                                        onChange={(_, shouldExpand) =>
                                          handlePermissionsExpand(
                                            `${serviceName}-write`,
                                            shouldExpand,
                                          )
                                        }
                                        sx={{
                                          backgroundColor: 'inherit',
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={
                                            expandedPermissions.includes(`${serviceName}-write`) ? (
                                              <RemoveIcon />
                                            ) : (
                                              <AddIcon />
                                            )
                                          }
                                          sx={{
                                            display: 'inline-flex',
                                            flexDirection: 'row-reverse',
                                            gap: 1,
                                            '& .MuiAccordionSummary-expandIconWrapper': {
                                              transform: 'none !important',
                                            },
                                          }}
                                        >
                                          <Typography>Write Permissions</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ ml: 4 }}>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <FormControlLabel
                                                aria-label={`write-${serviceName}-${selectAllOptions.permissions.identifier}`}
                                                label={selectAllOptions.permissions.displayName}
                                                name={`permissions.${serviceName}.write`}
                                                onChange={(event) => handleSelectAll(event, false)}
                                                onBlur={handleBlur}
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      values.permissions[serviceName]?.write
                                                        ?.length === writePermissions.length
                                                    }
                                                  />
                                                }
                                              />
                                            </Grid>
                                            {writePermissions.map((permission) => (
                                              <Grid
                                                key={permission.identifier}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                              >
                                                <FormControlLabel
                                                  aria-label={`write-${serviceName}`}
                                                  key={permission.identifier}
                                                  label={permission.displayName}
                                                  name={`permissions.${serviceName}.write`}
                                                  onChange={handleSelectWritePermission}
                                                  onBlur={handleBlur}
                                                  control={
                                                    <Checkbox
                                                      value={permission.identifier}
                                                      checked={values.permissions[
                                                        serviceName
                                                      ]?.write?.includes(permission.identifier)}
                                                    />
                                                  }
                                                />
                                              </Grid>
                                            ))}
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                  )}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                            {serviceTouched && serviceError && (
                              <FormHelperText>{serviceError}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Footer>
                      <LoadingButton
                        className='cancelButton'
                        color='error'
                        variant='outlined'
                        onClick={closeModal}
                        style={{ boxShadow: 'none' }}
                        disabled={isSubmitting}
                        sx={{ textTransform: 'none' }}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        color='primary'
                        size='large'
                        variant='contained'
                        type='submit'
                        loading={isSubmitting}
                        sx={{ textTransform: 'none', marginLeft: 2 }}
                      >
                        Save
                      </LoadingButton>
                      </Footer>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        </ModalContent>
      </Box>
    </Modal>
  );
}

export default RoleAddEdit;
