import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import styles from './modules/RequestCard.module.css';
import { LoadingButton } from '@mui/lab';
import RequestActions from './RequestActions';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { convertUTCDateToLocalDate } from 'app/utils/helpers';
import { useState } from 'react';
import ViewInvestmentsRequest from './ViewInvestmentsRequest';
import { requestsTypes } from 'app/utils/constant';

function RequestCard({ request, respondToRequest }) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const rolesText = request.roles?.join(', ');

  const textsByInvitationType = {
    null: `${request.invitedPerson} wants to add you as ${rolesText} in ${request.projectTitle}`,
    INVESTMENT_MAIL: `${request.investorUserName} wants to invest in ${request.projectTitle}`,
    PAYMENT_REQUEST_MAIL: `${request.investorUserName} wants to add investment payment for ${request.projectTitle}`,
    OUTGOING_USER_INVESTMENT: `You have invested in ${request.projectTitle}`,
    OUTGOING_PROJECT_INVITATION: `${request.invitedPerson} has invited someone as ${rolesText} in ${request.projectTitle}`,
  };

  const primaryText = textsByInvitationType[request.invitationType];

  const isInvestmentRequest =
    request.invitationType === requestsTypes.INVESTMENT_MAIL ||
    request.invitationType === requestsTypes.PAYMENT_REQUEST_MAIL;

  const convertedCreatedTime = convertUTCDateToLocalDate(request.createdTime);

  const handleViewDetails = () => {
    if (!isInvestmentRequest) {
      return;
    }
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  return (
    <>
      <ListItem className={styles.requestCard} disablePadding>
        <ListItemText primary={primaryText} secondary={<TimeAgo date={convertedCreatedTime} />} />
        <Box className={styles.buttonsContainer}>
          <LoadingButton variant='text' sx={{ color: '#4F90F0' }} onClick={handleViewDetails}>
            <Link to={!isInvestmentRequest && `/project/${request.projectId}`}>View Details</Link>
          </LoadingButton>
          <RequestActions request={request} respondToRequest={respondToRequest} />
        </Box>
      </ListItem>
      {isOpenDialog && (
        <ViewInvestmentsRequest
          request={request}
          respondToRequest={respondToRequest}
          closeModal={handleCloseDialog}
        />
      )}
    </>
  );
}

export default RequestCard;
