import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Modal,
  IconButton,
  InputAdornment,
  Input,
  styled,
  InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  ImageContainer,
  FileContainer,
  FileDetails,
  StyledButton,
  FileContainerDetails,
  Container,
} from 'app/common/Typography';
import { DownloadDragIcon, PdfIcon, DocIcon } from 'app/common/icons';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import TenantAddEdit from './TenantAddEdit';
import { Formik, getIn, FieldArray } from 'formik';
import dayjs from 'dayjs';
import { CustomGCExpensesInputText } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { notify } from 'app/services/notify';
import { base64ToFile, convertUTCDateToLocal, formatDateToLocalISOString } from 'app/utils/helpers';
import { documentTypes } from 'app/utils/constant';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments';
import { validateCompleteDate, validationSchemas } from 'app/utils/validationSchemas';

const ModalDivider = styled('div')({
  height: '1px',
  marginBottom: '20px',
  backgroundColor: 'var(--modalDivider)',
});

const currencies = [
  { value: 'INR', label: 'INR' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
];

const CustomDropdown = styled(TextField)({
  '& .MuiInputBase-root': {
    '&::after': {
      backgroundColor: '#DBE9FF',
    },
  },
});

function calculateLeaseEndDate(startDate, leaseTerm, unit) {
  if (!startDate || !leaseTerm) return null;
  let newEndDate = dayjs(startDate);

  switch (unit) {
    case 'Months':
      newEndDate = newEndDate.add(leaseTerm, 'month');
      break;
    case 'Years':
      newEndDate = newEndDate.add(leaseTerm, 'year');
      break;
    case 'Days':
      newEndDate = newEndDate.add(leaseTerm, 'day');
      break;
    default:
      return null;
  }
  return newEndDate;
}

const LeaseAddEdit = ({ open, onClose, selectedBuilding, selectedUnit, leaseData, tenantData, leaseId }) => {
  const [currency, setCurrency] = useState('INR');
  const [openModal, setOpenModal] = useState(false);
  const [submittedLeaseData, setSubmittedLeaseData] = useState(null);
  const [dropdownValue, setDropdownValue] = useState('Months');

  const { projectId } = useProject();
  const { data: documents } = useProjectDocuments(projectId);

  const handleLeaseTermChange = (e, values, setFieldValue) => {
    const leaseTerm = e.target.value;
    setFieldValue('leaseTerm', leaseTerm);

    const endDate = calculateLeaseEndDate(values.leaseStartDate, leaseTerm, dropdownValue);
    if (endDate) setFieldValue('leaseEndDate', endDate);
  };

  const handleLeaseStartDateChange = (date, values, setFieldValue) => {
    setFieldValue('leaseStartDate', date);

    const endDate = calculateLeaseEndDate(date, values.leaseTerm, dropdownValue);
    if (endDate) setFieldValue('leaseEndDate', endDate);
  };

  const initialValues = {
    leaseSpace: selectedUnit?.space || '',
    rentSqFtPerYear: leaseData?.rentSqFtPerYear || '',
    leaseTerm: leaseData?.leaseTerm || '',
    rentIncreasePerYear: leaseData?.rentIncreasePerYear || '',
    leaseExecutionDate:
      leaseData?.leaseExecutionDate &&
      formatDateToLocalISOString(new Date(...leaseData?.leaseExecutionDate)),
    leaseFixturizationDate:
      leaseData?.leaseFixturizationDate &&
      formatDateToLocalISOString(new Date(...leaseData?.leaseFixturizationDate)),
    leaseStartDate:
      leaseData?.leaseStartDate &&
      formatDateToLocalISOString(new Date(...leaseData?.leaseStartDate)),
    leaseEndDate:
      leaseData?.leaseEndDate && formatDateToLocalISOString(new Date(...leaseData?.leaseEndDate)),
    currentMonthRent: leaseData?.currentMonthRent || '',
    freeRentMonths: leaseData?.freeRentMonths || '',
    nnn: leaseData?.nnn || '',
    securityDeposit: leaseData?.securityDeposit || '',
    firstMonthRentDate:
      leaseData?.firstMonthRentDate &&
      formatDateToLocalISOString(new Date(...leaseData?.firstMonthRentDate)),
    realtorCommission: leaseData?.realtorCommission || '',
    realtorRebate: leaseData?.realtorRebate || '',
    comments: leaseData?.comments || '',
    leaseDocuments: documents
      ?.filter((doc) => leaseData?.leaseDocuments?.[0]?.includes(doc.metadata.fileId))
      .map((doc) => ({
        file: base64ToFile(doc.content, doc.metadata.documentName, doc.metadata.documentType),
        docName: doc.metadata.documentName,
        type: doc.metadata.type,
      })),
  };

  const handleBack = () => {
    setOpenModal(false);
  };

  const handleClose = () => {
    handleBack();
    onClose();
  };

  const handleFormSubmit = (values) => {
    setSubmittedLeaseData(values);
    setOpenModal(true);
  };

  return (
    <>
      <Modal open={open} onClose={onClose} disableEnforceFocus={true}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '0%',
            transform: 'translate(0%, -50%)',
            width: '60%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            overflowY: 'auto',
            height: '100%'
            
          }}
        >
          <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
            <Typography variant='h6'>Lease Details</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex' }} mb={2}>
            <Typography sx={{ fontWeight: 500 }}>1/2 Lease Details</Typography>
            <Typography sx={{ fontWeight: 500, color: 'var(--typography-color)', ml: 1 }}>
              Next: LLC Details
            </Typography>
          </Box>
          <ModalDivider />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.lease()}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              const handleFileInput = (event) => {
                event.preventDefault();
                if (isSubmitting) return;

                const fileAccess = {
                  change: event.target.files,
                  drop: event.dataTransfer?.files,
                };

                const inputFile = fileAccess[event.type];

                const newDocument = Array.from(inputFile).map((file) => ({
                  file,
                  docName: '',
                  type: documentTypes.LEASE_DOCUMENTS,
                }));

                setFieldValue('leaseDocuments', [...values.leaseDocuments, ...newDocument]);
              };

              const handleDragOver = (event) => event.preventDefault();
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='leaseSpace'
                      >
                        Lease space (sq ft)
                      </InputLabel>
                      <TextField
                        type='number'
                        name='leaseSpace'
                        id='leaseSpace'
                        placeholder='Enter space area'
                        size='small'
                        value={selectedUnit?.space || leaseData?.leaseSpace || ''}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        onKeyDown={(e) => {
                          // Block unwanted keys
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        error={touched.leaseSpace && Boolean(errors.leaseSpace)}
                        helperText={touched.leaseSpace && errors.leaseSpace}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='rentSqFtPerYear'
                      >
                        Rent/Sq ft per year
                      </InputLabel>
                      <TextField
                        id='rentSqFtPerYear'
                        name='rentSqFtPerYear'
                        size='small'
                        value={values.rentSqFtPerYear}
                        placeholder='Enter amount'
                        onChange={handleChange}
                        fullWidth
                        type='number'
                        error={touched.rentSqFtPerYear && Boolean(errors.rentSqFtPerYear)}
                        helperText={touched.rentSqFtPerYear && errors.rentSqFtPerYear}
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          // Block unwanted keys
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='leaseTerm'
                      >
                        Lease Term
                      </InputLabel>
                      <TextField
                        id='leaseTerm'
                        name='leaseTerm'
                        size='small'
                        type='number'
                        value={values.leaseTerm}
                        placeholder={`Enter ${dropdownValue.toLowerCase()}`}
                        onChange={(e) => handleLeaseTermChange(e, values, setFieldValue)}
                        fullWidth
                        error={touched.leaseTerm && Boolean(errors.leaseTerm)}
                        helperText={touched.leaseTerm && errors.leaseTerm}
                        inputProps={{ min: 0 }}
                        onKeyDown={(e) => {
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) e.preventDefault();
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='rentIncreasePerYear'
                      >
                        Rent Increase/Year
                      </InputLabel>
                      <TextField
                        type='number'
                        name='rentIncreasePerYear'
                        placeholder='Enter amount'
                        size='small'
                        value={values.rentIncreasePerYear}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (Number(newValue) >= 0) {
                            handleChange(e);
                          }
                        }}
                        fullWidth
                        error={touched.rentIncreasePerYear && Boolean(errors.rentIncreasePerYear)}
                        helperText={touched.rentIncreasePerYear && errors.rentIncreasePerYear}
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          // Block unwanted keys
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='leaseExecutionDate'
                      >
                        Lease Execution Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          id='leaseExecutionDate'
                          name='leaseExecutionDate'
                          value={dayjs(values.leaseExecutionDate) || null}
                          onChange={(date) => {
                            setFieldValue('leaseExecutionDate', date);
                            if (dayjs(values.leaseFixturizationDate).isBefore(date)) {
                              setFieldValue('leaseFixturizationDate', null);
                            }
                            if (dayjs(values.leaseStartDate).isBefore(date)) {
                              setFieldValue('leaseStartDate', null);
                            }
                            if (dayjs(values.leaseEndDate).isBefore(date)) {
                              setFieldValue('leaseEndDate', null);
                            }
                          }}
                          minDate={dayjs()}
                          shouldDisableDate={(date) => date.isBefore(dayjs(), 'day')}
                          slotProps={{
                            textField: {
                              size: 'small',
                              placeholder: 'Select date',
                              onBlur: handleBlur,
                              fullWidth: true,
                              variant: 'outlined',
                              error: Boolean(
                                touched.leaseExecutionDate && errors.leaseExecutionDate,
                              ),
                              helperText: touched.leaseExecutionDate && errors.leaseExecutionDate,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='leaseFixturizationDate'
                      >
                        Lease Fixturization Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          id='leaseFixturizationDate'
                          value={dayjs(values.leaseFixturizationDate) || null}
                          onChange={(date) => {
                            setFieldValue('leaseFixturizationDate', date);
                            if (dayjs(values.leaseStartDate).isBefore(date)) {
                              setFieldValue('leaseStartDate', null);
                            }
                            if (dayjs(values.leaseEndDate).isBefore(date)) {
                              setFieldValue('leaseEndDate', null);
                            }
                          }}
                          minDate={
                            values.leaseExecutionDate ? dayjs(values.leaseExecutionDate) : dayjs()
                          }
                          shouldDisableDate={(date) => date.isBefore(dayjs(), 'day')}
                          slotProps={{
                            textField: {
                              size: 'small',
                              placeholder: 'Select date',
                              fullWidth: true,
                              variant: 'outlined',
                              error: Boolean(
                                touched.leaseFixturizationDate && errors.leaseFixturizationDate,
                              ),
                              helperText:
                                touched.leaseFixturizationDate && errors.leaseFixturizationDate,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='leaseStartDate'
                      >
                        Lease Start Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          id='leaseStartDate'
                          value={dayjs(values.leaseStartDate) || null}
                          onChange={(date) =>
                            handleLeaseStartDateChange(date, values, setFieldValue)
                          }
                          minDate={dayjs()}
                          slotProps={{
                            textField: {
                              size: 'small',
                              placeholder: 'Select date',
                              fullWidth: true,
                              variant: 'outlined',
                              error: Boolean(touched.leaseStartDate && errors.leaseStartDate),
                              helperText: touched.leaseStartDate && errors.leaseStartDate,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='leaseEndDate'
                      >
                        Lease End Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          id='leaseEndDate'
                          value={dayjs(values.leaseEndDate) || null}
                          onChange={(date) => setFieldValue('leaseEndDate', date)}
                          minDate={values.leaseStartDate ? dayjs(values.leaseStartDate) : dayjs()}
                          slotProps={{
                            textField: {
                              size: 'small',
                              placeholder: 'Select date',
                              fullWidth: true,
                              variant: 'outlined',
                              error: Boolean(touched.leaseEndDate && errors.leaseEndDate),
                              helperText: touched.leaseEndDate && errors.leaseEndDate,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='currentMonthRent'
                      >
                        Current Month Rent
                      </InputLabel>
                      <TextField
                        type='number'
                        name='currentMonthRent'
                        size='small'
                        placeholder='Enter amount'
                        value={values.currentMonthRent}
                        onChange={handleChange}
                        fullWidth
                        error={touched.currentMonthRent && Boolean(errors.currentMonthRent)}
                        helperText={touched.currentMonthRent && errors.currentMonthRent}
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          // Block unwanted keys
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='freeRentMonths'
                      >
                        Free Rent Months
                      </InputLabel>
                      <TextField
                        type='number'
                        name='freeRentMonths'
                        size='small'
                        value={values.freeRentMonths}
                        placeholder='Enter months'
                        onChange={handleChange}
                        fullWidth
                        error={touched.freeRentMonths && Boolean(errors.freeRentMonths)}
                        helperText={touched.freeRentMonths && errors.freeRentMonths}
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          // Block unwanted keys
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='nnn'
                      >
                        NNN
                      </InputLabel>
                      <TextField
                        type='number'
                        name='nnn'
                        size='small'
                        placeholder='Enter NNN'
                        value={values.nnn}
                        onKeyDown={(e) => {
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (Number(newValue) >= 0) {
                            handleChange(e);
                          }
                        }}
                        fullWidth
                        error={touched.nnn && Boolean(errors.nnn)}
                        helperText={touched.nnn && errors.nnn}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='securityDeposit'
                      >
                        Security Deposit
                      </InputLabel>
                      <TextField
                        id='securityDeposit'
                        type='number'
                        name='securityDeposit'
                        placeholder='Enter amount'
                        size='small'
                        value={values.securityDeposit}
                        onChange={handleChange}
                        fullWidth
                        error={touched.securityDeposit && Boolean(errors.securityDeposit)}
                        helperText={touched.securityDeposit && errors.securityDeposit}
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          // Block unwanted keys
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='firstMonthRentDate'
                      >
                        First Month Rent Date
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          id='firstMonthRentDate'
                          value={dayjs(values.firstMonthRentDate) || null}
                          onChange={(date) => setFieldValue('firstMonthRentDate', date)}
                          minDate={
                            validateCompleteDate(values.leaseStartDate)
                              ? dayjs(values.leaseStartDate)
                              : null
                          }
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              placeholder: 'Select date',
                              variant: 'outlined',
                              error: Boolean(
                                touched.firstMonthRentDate && errors.firstMonthRentDate,
                              ),
                              helperText: touched.firstMonthRentDate && errors.firstMonthRentDate,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='realtorCommission'
                      >
                        Realtor Commission
                      </InputLabel>
                      <TextField
                        type='number'
                        name='realtorCommission'
                        size='small'
                        value={values.realtorCommission}
                        placeholder='Enter amount'
                        onChange={handleChange}
                        fullWidth
                        // onBlur={handleBlur}
                        error={touched.realtorCommission && Boolean(errors.realtorCommission)}
                        helperText={touched.realtorCommission && errors.realtorCommission}
                        inputProps={{
                          min: 0,
                        }}
                        onKeyDown={(e) => {
                          // Block unwanted keys
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='realtorRebate'
                      >
                        Realtor Rebate
                      </InputLabel>
                      <TextField
                        id='realtorRebate'
                        name='realtorRebate'
                        size='small'
                        type='number'
                        value={values.realtorRebate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (Number(newValue) >= 0) {
                            handleChange(e);
                          }
                        }}
                        fullWidth
                        onKeyDown={(e) => {
                          const blockedKeys = [
                            'e',
                            'E',
                            '+',
                            '-',
                            '.',
                            '/',
                            "'",
                            '[',
                            ']',
                            '{',
                            '}',
                            '\\',
                            ';',
                            ':',
                          ];
                          if (blockedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        placeholder='Enter amount'
                        error={touched.realtorRebate && Boolean(errors.realtorRebate)}
                        helperText={touched.realtorRebate && errors.realtorRebate}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          color: 'var(--inputLabel-color)',
                        }}
                      >
                        Lease Document
                      </InputLabel>
                      <Container onDragOver={handleDragOver} onDrop={handleFileInput}>
                        <Typography
                          variant='body1'
                          marginTop={1}
                          color={'var(--drag-Drop-Text)'}
                          fontSize={'16px'}
                        >
                          <DownloadDragIcon />
                          {` Drag and Drop file here or `}
                          <label htmlFor='outlined-button-file'>
                            <StyledButton
                              disabled={values.leaseDocuments?.length > 0}
                              variant='outlined'
                              component='span'
                            >
                              Browse files
                            </StyledButton>
                          </label>
                        </Typography>
                        <Input
                          id={`outlined-button-file`}
                          key={values.leaseDocuments?.length}
                          sx={{
                            display: 'none',
                          }}
                          type='file'
                          size='small'
                          name='file'
                          onChange={handleFileInput}
                          inputProps={{ accept: '.pdf, .doc, .docx' }}
                          error={Boolean(
                            getIn(errors, `leaseDocuments[0].file`) &&
                              getIn(touched, `leaseDocuments[0].file`),
                          )}
                          helperText={
                            getIn(errors, `leaseDocuments[0].file`) &&
                            getIn(touched, `leaseDocuments[0].file`)
                              ? 'Document is required'
                              : ''
                          }
                          disabled={isSubmitting || values.leaseDocuments.length > 0}
                        />
                      </Container>
                      <Typography
                        variant='body1'
                        color={'var(--fileFormate-Text)'}
                        fontSize={'12px'}
                        pt={1}
                      >
                        {`File format only word or Pdf, Size should not exceed 10MB`}
                      </Typography>
                    </Grid>
                    <FileContainerDetails>
                      <FieldArray name='leaseDocuments'>
                        {({ remove }) =>
                          values?.leaseDocuments?.map((doc, index) => (
                            <FileContainer key={index}>
                              <ImageContainer>
                                {doc.file.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                                <FileDetails>
                                  <Typography
                                    variant='body1'
                                    style={{ fontWeight: 'bold', marginLeft: '10px' }}
                                  >
                                    {doc.file.name}
                                  </Typography>
                                  <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                    {(doc.file.size / 1024).toFixed(2)} KB
                                  </Typography>
                                </FileDetails>
                                <IconButton onClick={() => remove(index)}>
                                  <DeleteIcon style={{ color: 'var(--deleteIcon)' }} />
                                </IconButton>
                              </ImageContainer>
                              <ModalDivider />
                              <Grid container spacing={2}>
                                <Grid paddingLeft={2} xs={12}>
                                  <CustomGCExpensesInputText
                                    name={`leaseDocuments[${index}].docName`}
                                    placeholder='Document Name'
                                    touched={touched}
                                    value={doc.docName}
                                    onChange={handleChange}
                                    error={Boolean(
                                      getIn(errors, `leaseDocuments[${index}].docName`) &&
                                        getIn(touched, `leaseDocuments[${index}].docName`),
                                    )}
                                    helperText={
                                      getIn(errors, `leaseDocuments[${index}].docName`) &&
                                      getIn(touched, `leaseDocuments[${index}].docName`)
                                        ? 'Document Name is required'
                                        : ''
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </FileContainer>
                          ))
                        }
                      </FieldArray>
                    </FileContainerDetails>
                    <Grid item xs={12}>
                      <InputLabel
                        sx={{
                          fontWeight: 700,
                          fontSize: 14,
                          mb: 1,
                          color: 'var(--inputLabel-color)',
                        }}
                        htmlFor='comments'
                      >
                        Comments
                      </InputLabel>
                      <TextField
                        id='comments'
                        rows={2}
                        name='comments'
                        placeholder='Write here...'
                        value={values.comments}
                        onChange={handleChange}
                        multiline
                        fullWidth
                        error={touched.comments && Boolean(errors.comments)}
                        helperText={touched.comments && errors.comments}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      textAlign='center'
                      display='flex'
                      justifyContent='space-between'
                    >
                      <Button variant='outlined' color='error' onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ boxShadow: 'none', marginRight: '0.1em' }}
                        type='submit'
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <TenantAddEdit
        openModal={openModal}
        onClose={handleClose}
        onBack={handleBack}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        leaseData={submittedLeaseData}
        tenantData={tenantData}
        leaseId={leaseId}
      />
    </>
  );
};

export default LeaseAddEdit;
