// import React, { useState } from 'react';
// import { Box, Button, Grid, TextField, Modal, IconButton, Typography } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { useFormik } from 'formik';
// import { ModalCancel, ModalContent, ModalSave, StyledBoxFooter } from 'app/common/Typography';
// import { StyledLabel } from '../../../listings/Modals/StyledComponents';
// import { StandaloneSearchBox } from '@react-google-maps/api';
// import { parseAddressComponents } from 'app/utils/helpers';

// const InvestmentModal = ({
//   open,
//   onClose,
//   saleData,
//   buyerData,
//   handleBackToSaleForm,
//   selectedBuilding,
//   selectedUnit,
//   saleId,
//   onBack,
//   handleBuyerModalOpen,
//   isEdit,
//   showPrevious = true,
// }) => {
//   const [openTitleCompanyModal, setOpenTitleCompanyModal] = useState(false);
//   const searchInputRef = React.useRef(null);
//   const handleClose = () => {
//     onClose();
//   };


//   const handlePlacesChanged = (setValues) => {
//     const [place] = searchInputRef.current.getPlaces();
//     if (!place) return;

//     const addressData = parseAddressComponents(place);

//     setValues((prevState) => ({
//       ...prevState,
//       ...addressData,
//     }));
//   };
//   const formik = useFormik({
//     initialValues: {
//       buyerName: '',
//       buyerAddress: '',
//       buyerPhone: '',
//       buyerEmail: '',
//       buyerCity: '',
//       buyerState: '',
//       buyerZip: '',
//       buyerCountry: '',
//       totalPrice: '',
//       paidAmount: '',
//       balanceAmount: '',
//       paymentDate: '',
//       modeOfPayment: '',
//       transactionNumber: '',
//     },
//     // validationSchema: validationSchemas.buyerInvestment(),
//     onSubmit: (values, { setSubmitting }) => {
//       console.log(values);
//       setSubmitting(false);
//       //   handleClose();
//       setOpenTitleCompanyModal(true);
//       handleClose();
//     },
//   });

//   return (
//     <>
//       <Modal open={open}>
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             right: '0%',
//             transform: 'translate(0%, -50%)',
//             width: '50%',
//             bgcolor: 'background.paper',
//             boxShadow: 24,
//             p: 4,
//             overflowY: 'auto',
//             height: '100%',
//             paddingLeft: '40px',
//           }}
//         >
//           <ModalContent>
//             <Box sx={{ justifyContent: 'space-between', display: 'flex', marginBottom: '20px' }}>
//               <Typography variant='h6'>{`${
//                 isEdit ? 'Edit' : 'Add'
//               } Investment Information`}</Typography>
//               <IconButton onClick={handleClose}>
//                 <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
//               </IconButton>
//             </Box>

//             <form onSubmit={formik.handleSubmit} noValidate>
//               <Grid container spacing={2}>

//                 {/* Investment Section */}
//                 <Grid item xs={6}>
//                   <StyledLabel>Total Price</StyledLabel>
//                   <TextField
//                     id='totalPrice'
//                     name='totalPrice'
//                     size='small'
//                     type='number'
//                     fullWidth
//                     value={formik.values.totalPrice}
//                     onChange={(e) => formik.setFieldValue('totalPrice', Math.abs(e.target.value))}
//                     onBlur={formik.handleBlur}
//                     error={formik.touched.totalPrice && Boolean(formik.errors.totalPrice)}
//                     helperText={formik.touched.totalPrice && formik.errors.totalPrice}
//                   />
//                 </Grid>

//                 <Grid item xs={6}>
//                   <StyledLabel>Paid Amount</StyledLabel>
//                   <TextField
//                     id='paidAmount'
//                     name='paidAmount'
//                     size='small'
//                     type='number'
//                     fullWidth
//                     value={formik.values.paidAmount}
//                     onChange={(e) => formik.setFieldValue('paidAmount', Math.abs(e.target.value))}
//                     onBlur={formik.handleBlur}
//                     error={formik.touched.paidAmount && Boolean(formik.errors.paidAmount)}
//                     helperText={formik.touched.paidAmount && formik.errors.paidAmount}
//                   />
//                 </Grid>

//                 <Grid item xs={6}>
//                   <StyledLabel>Balance Amount</StyledLabel>
//                   <TextField
//                     id='balanceAmount'
//                     name='balanceAmount'
//                     size='small'
//                     type='number'
//                     fullWidth
//                     value={formik.values.balanceAmount}
//                     onChange={(e) =>
//                       formik.setFieldValue('balanceAmount', Math.abs(e.target.value))
//                     }
//                     onBlur={formik.handleBlur}
//                     error={formik.touched.balanceAmount && Boolean(formik.errors.balanceAmount)}
//                     helperText={formik.touched.balanceAmount && formik.errors.balanceAmount}
//                   />
//                 </Grid>

//                 <Grid item xs={6}>
//                   <StyledLabel>Payment Date</StyledLabel>
//                   <TextField
//                     id='paymentDate'
//                     name='paymentDate'
//                     type='date'
//                     size='small'
//                     fullWidth
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     value={formik.values.paymentDate}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={formik.touched.paymentDate && Boolean(formik.errors.paymentDate)}
//                     helperText={formik.touched.paymentDate && formik.errors.paymentDate}
//                   />
//                 </Grid>

//                 <Grid item xs={6}>
//                   <StyledLabel>Mode Of Payment</StyledLabel>
//                   <TextField
//                     id='modeOfPayment'
//                     name='modeOfPayment'
//                     size='small'
//                     fullWidth
//                     value={formik.values.modeOfPayment}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     error={formik.touched.modeOfPayment && Boolean(formik.errors.modeOfPayment)}
//                     helperText={formik.touched.modeOfPayment && formik.errors.modeOfPayment}
//                   />
//                 </Grid>

//                 <Grid item xs={6}>
//                   <StyledLabel>Transaction Number</StyledLabel>
//                   <TextField
//                     id='transactionNumber'
//                     name='transactionNumber'
//                     size='small'
//                     fullWidth
//                     value={formik.values.transactionNumber}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                   />
//                 </Grid>
//               </Grid>
//               {showPrevious ? (
//                 <Grid
//                   item
//                   xs={12}
//                   textAlign='center'
//                   sx={{
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     alignItems: 'center',
//                     marginTop: '20px',
//                   }}
//                 >
//                   <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
//                     <Button
//                       variant='text'
//                       color='inherit'
//                       onClick={onBack}
//                       // startIcon={<ArrowBackIcon size='small' />}
//                       disabled={formik.isSubmitting}
//                       sx={{ textTransform: 'none' }}
//                     >
//                       Previous
//                     </Button>
//                   </Box>
//                   <StyledBoxFooter>
//                     <ModalCancel
//                       className='cancelButton'
//                       variant='outlined'
//                       onClick={handleClose}
//                       style={{ boxShadow: 'none' }}
//                       sx={{ textTransform: 'none' }}
//                     >
//                       Cancel
//                     </ModalCancel>
//                     <ModalSave
//                       color='primary'
//                       size='large'
//                       variant='contained'
//                       type='submit'
//                       sx={{ textTransform: 'none', marginLeft: 2 }}
//                     >
//                       Save
//                     </ModalSave>
//                   </StyledBoxFooter>
//                 </Grid>
//               ) : (
//                 <StyledBoxFooter>
//                   <ModalCancel
//                     className='cancelButton'
//                     variant='outlined'
//                     onClick={handleClose}
//                     style={{ boxShadow: 'none' }}
//                     sx={{ textTransform: 'none' }}
//                   >
//                     Cancel
//                   </ModalCancel>
//                   <ModalSave
//                     color='primary'
//                     size='large'
//                     variant='contained'
//                     type='submit'
//                     sx={{ textTransform: 'none', marginLeft: 2 }}
//                   >
//                     Save
//                   </ModalSave>
//                 </StyledBoxFooter>
//               )}
//             </form>
//           </ModalContent>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// export default InvestmentModal;


import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  styled,
  Button,
  Grid,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Modal,
} from '@mui/material';
import { Formik, Form } from 'formik';
import {
  Title,
  StyledTextField,
  StyledBoxFooter,
  ModalCancel,
  ModalSave,
  ModalDivider,
  ModalStyledBox,
  ModalStyledSubBox,
  ModalContainer,
} from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { paymentOptions } from 'app/utils/constant';
import { base64ToFile } from 'app/utils/helpers';
import { StyledLabel } from '../../../listings/Modals/StyledComponents';

const InvestmentModal = ({ open, onClose, isEdit = false, saleId, selectedUnit, selectedBuilding, saleData, buyerData }) => {

    let selectedExpense = {}
//   const isEditingMode = !!selectedExpense;

  const latestPaymentDetails = selectedExpense?.paymentDetails?.at(-1);

  const initialValues = {
    expensesName: latestPaymentDetails?.expensesName,
    totalAmount: selectedExpense?.totalAmount,
    paidAmount: latestPaymentDetails?.paidAmount,
    balance: selectedExpense?.balance,
    invoiceNo: selectedExpense?.invoiceNo,
    expenseType: latestPaymentDetails?.expenseType,
    paidTo: latestPaymentDetails?.paidTo,
    modeOfPayment: latestPaymentDetails?.modeOfPayment,
    comments: latestPaymentDetails?.comments,
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    const document = values.documents[0];
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalStyledBox>
        <ModalStyledSubBox>
          <Title>{isEdit ? 'Update' : 'Add'} Investments</Title>
          <IconButton onClick={onClose}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
            overflowY: 'auto',
            maxHeight: 'calc(100% - 75px)',
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form>
                  <ModalContainer>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <StyledLabel>Investment Name</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          name='expensesName'
                          value={values.expensesName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.expensesName && errors.expensesName)}
                          helperText={touched.expensesName && errors.expensesName}
                          InputProps={{
                            readOnly: Boolean(initialValues.expensesName),
                            sx: Boolean(initialValues.expensesName) && {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledLabel>Total Amount</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={
                            Boolean(initialValues.totalAmount) && {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                          }
                          type='number'
                          name='totalAmount'
                          value={values.totalAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.totalAmount && errors.totalAmount)}
                          helperText={touched.totalAmount && errors.totalAmount}
                          InputProps={{
                            min: 0,
                            readOnly: Boolean(initialValues.totalAmount),
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <StyledLabel>Paid Amount</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          name='paidAmount'
                          value={values.paidAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.paidAmount && errors.paidAmount)}
                          helperText={touched.paidAmount && errors.paidAmount}
                          InputProps={{ min: 0 }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledLabel>Balance Amount</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#f0f0f0f0',
                          }}
                          type='number'
                          name='balance'
                          value={
                            isEdit ? values?.balance : values.totalAmount - values.paidAmount
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <StyledLabel>Invoice Number</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={
                            Boolean(initialValues.invoiceNo) && {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                          }
                          name='invoiceNo'
                          value={values.invoiceNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.invoiceNo && errors.invoiceNo)}
                          helperText={touched.invoiceNo && errors.invoiceNo}
                          InputProps={{
                            readOnly: Boolean(initialValues.invoiceNo),
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <StyledLabel>Paid To</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          type='text'
                          name='paidTo'
                          value={values.paidTo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.paidTo && errors.paidTo)}
                          helperText={touched.paidTo && errors.paidTo}
                          InputProps={{ min: 0 }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          error={touched.modeOfPayment && Boolean(errors.modeOfPayment)}
                        >
                          <StyledLabel htmlFor='modeOfPaymentInput'>Mode Of Payment</StyledLabel>
                          <Select
                            fullWidth
                            size='small'
                            id='modeOfPaymentInput'
                            name='modeOfPayment'
                            value={values.modeOfPayment || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                          >
                            {paymentOptions.map(({ label }) => (
                              <MenuItem key={label} value={label}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.modeOfPayment && errors.modeOfPayment && (
                            <FormHelperText>{errors.modeOfPayment}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ModalContainer>
                  
                  <Grid item xs={12}>
                    <StyledLabel>Comments</StyledLabel>
                    <StyledTextField
                      rows={2}
                      multiline
                      fullWidth
                      size='small'
                      variant='outlined'
                      sx={{ mb: 1, mt: 1 }}
                      type='text'
                      name='comments'
                      value={values.comments}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <StyledBoxFooter>
                    <ModalCancel
                      className='cancelButton'
                      variant='outlined'
                      onClick={onClose}
                      style={{ boxShadow: 'none' }}
                      disabled={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </ModalCancel>
                    <ModalSave
                      variant='contained'
                      color='primary'
                      style={{ boxShadow: 'none', marginRight: '10px' }}
                      type='submit'
                      loading={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      {isEdit ? 'Update' : 'Save'}
                    </ModalSave>
                  </StyledBoxFooter>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalStyledBox>
    </Modal>
  );
};
export default InvestmentModal;
