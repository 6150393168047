import Suspense from 'app/common/SuspenseLoader';
import useSettings from 'app/hooks/useSettings';
import { SideNavLayout } from './index';

const Layout = (props) => {
  const { settings } = useSettings();
  const Layout = SideNavLayout[settings.activeLayout];

  return (
    <Suspense>
      <Layout {...props} />
    </Suspense>
  );
};

export default Layout;
