import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  styled,
  Grid,
  InputLabel,
  TextField,
  Modal,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { Form, Formik } from 'formik';
import {
  ModalCancel,
  ModalSave,
  ModalStyledBox,
  ModalStyledSubBox,
  StyledBoxFooter,
  StyledLabel,
  StyledTextField,
  Title,
} from 'app/common/Typography';
import { paymentOptions } from 'app/utils/constant';
import { useProjectGCPaymentAddUpdate } from './hooks/useProjectGCPaymentAddUpdate';
import { validationSchemas } from 'app/utils/validationSchemas';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '0.6250em',
  paddingRight: '0.6250em',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const GCPaymentAddEdit = ({ contractor, payment, closeDialog }) => {
  const { mutate: addUpdateProjectGCPayment } = useProjectGCPaymentAddUpdate(contractor, payment);

  const isEditingMode = !!payment;
  const latestPayment = payment?.paymentHistory?.at(-1);

  const initialValues = {
    totalAmount: latestPayment?.totalAmount || '',
    amountPaid: latestPayment?.amountPaid || '',
    balance: latestPayment?.balance,
    paidBy: latestPayment?.paidBy || '',
    paidTo: latestPayment?.paidTo || '',
    paymentType: latestPayment?.paymentType || '',
    comments: latestPayment?.comments || '',
  };

  const handleSubmit = (values, { setSubmitting }) =>
    addUpdateProjectGCPayment(values, {
      onSuccess: closeDialog,
      onSettled: () => setSubmitting(false),
    });

  return (
    <Modal open={true} onClose={closeDialog}>
      <ModalStyledBox>
        <ModalStyledSubBox>
          <Title>{isEditingMode ? 'Edit' : 'Add'} Payment Details</Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
            overflowY: 'auto',
            maxHeight: 'calc(100% - 75px)',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.gcPayment()}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <Container>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <StyledLabel>Total Amount</StyledLabel>
                      <StyledTextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        sx={
                          Boolean(initialValues.totalAmount) && {
                            fontWeight: 'bold',
                            backgroundColor: 'var(--TextField-bg-color)',
                          }
                        }
                        type='number'
                        name='totalAmount'
                        value={values.totalAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.totalAmount && errors.totalAmount)}
                        helperText={touched.totalAmount && errors.totalAmount}
                        InputProps={{
                          readOnly: Boolean(initialValues.totalAmount),
                          min: 0,
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledLabel>Paid Amount</StyledLabel>
                      <StyledTextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='number'
                        name='amountPaid'
                        value={values.amountPaid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.amountPaid && errors.amountPaid)}
                        helperText={touched.amountPaid && errors.amountPaid}
                        inputProps={{ min: 0 }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <StyledLabel>Balance Amount</StyledLabel>
                      <StyledTextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        sx={{
                          fontWeight: 'bold',
                          backgroundColor: 'var(--TextField-bg-color1)',
                        }}
                        type='number'
                        name='balance'
                        value={
                          isEditingMode ? values?.balance : values.totalAmount - values.amountPaid
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledLabel>Paid By</StyledLabel>
                      <StyledTextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='text'
                        name='paidBy'
                        value={values.paidBy}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.paidBy && errors.paidBy)}
                        helperText={touched.paidBy && errors.paidBy}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <StyledLabel>Paid To</StyledLabel>
                      <StyledTextField
                        fullWidth
                        variant='outlined'
                        size='small'
                        type='text'
                        name='paidTo'
                        value={values.paidTo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.paidTo && errors.paidTo)}
                        helperText={touched.paidTo && errors.paidTo}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <StyledLabel>Mode Of Payment</StyledLabel>
                      <FormControl
                        fullWidth
                        error={touched.paymentType && Boolean(errors.paymentType)}
                      >
                        <Select
                          fullWidth
                          size='small'
                          name='paymentType'
                          value={values.paymentType || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                        >
                          {paymentOptions.map(({ label }) => (
                            <MenuItem key={label} value={label}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.paymentType && errors.paymentType && (
                          <FormHelperText>{errors.paymentType}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledLabel>Comments</StyledLabel>
                      <StyledTextField
                        fullWidth
                        multiline
                        variant='outlined'
                        rows={4}
                        type='text'
                        name='comments'
                        value={values.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.comments && errors.comments)}
                        helperText={touched.comments && errors.comments}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Container>
                <StyledBoxFooter>
                  <ModalCancel
                    className='cancelButton'
                    variant='outlined'
                    onClick={closeDialog}
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                    sx={{ textTransform: 'none' }}
                  >
                    Cancel
                  </ModalCancel>
                  <ModalSave
                    variant='contained'
                    color='primary'
                    style={{ boxShadow: 'none', marginRight: '10px' }}
                    type='submit'
                    loading={isSubmitting}
                    sx={{ textTransform: 'none' }}
                  >
                    {isEditingMode ? 'Update' : 'Save'}
                  </ModalSave>
                </StyledBoxFooter>
              </Form>
            )}
          </Formik>
        </Box>
      </ModalStyledBox>
    </Modal>
  );
};

export default GCPaymentAddEdit;
