import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  TextField,
  Autocomplete,
  MenuItem,
  styled,
  Container,
  Box,
  Typography,
  Button,
  Modal,
  IconButton,
} from '@mui/material';
import { useProject } from 'app/contexts/ProjectContext';
import { Title, Paragraph } from 'app/common/Typography';
import LeadsExpensesModal from './LeadsExpensesModal.jsx';
import LeadsExpenseForm from './LeadsExpenseForm.jsx';
import { LoadingButton } from '@mui/lab';
import { DownloadIcon } from 'app/common/icons';
import LeadsExpenseInvoice from './LeadsExpenseInvoice.jsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { expenseTypes } from 'app/utils/constant.js';
import notify from 'app/utils/notify.js';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  minWidth: 160,
}));

const UserMenu = styled('div')(() => ({
  cursor: 'pointer',
}));

const AutoComplete = styled(Autocomplete)(() => ({
  width: 170,
  marginRight: 10,
  '& .MuiOutlinedInput-root': {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
  },
}));

const cellStyle = {
  width: '12.5%',
  textAlign: 'left',
  padding: '8px',
};

const tableBodyCellStyle = {
  fontWeight: 500,
  fontSize: '14px',
  align: 'left',
  paddingLeft: '0.7%',
};

const LeadsExpenseInformation = () => {
  const { isOnHoldOrCancelled, documents, expenses } = useProject();
  const [openRow, setOpenRow] = useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [allExpenses, setAllExpenses] = useState(expenses);
  const [open, setOpen] = React.useState(false);
  const [handleViewData, setHandleViewData] = React.useState(null);
  const [selectedExpenseType, setSelectedExpenseType] = useState(null);

  useEffect(() => {
    setAllExpenses(expenses);
  }, [expenses]);

  const initialDocuments = useMemo(
    () => documents.filter((doc) => doc.metadata.documentType.startsWith('application/')),
    [documents],
  );

  const handleRowClick = (rowId) => {
    setOpenRow(openRow === rowId ? null : rowId);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleView = (index) => {
    setHandleViewData(index);
  };

  const handleInvoiceDownload = (initialDocuments, expense) => {
    try {
      initialDocuments.forEach((doc) => {
        if (
          expense?.paymentDetails[expense?.paymentDetails?.length - 1].paymentDocuments?.includes(
            doc.metadata.fileId,
          )
        ) {
          handleDownload(doc.content, doc.metadata.documentName, doc.metadata.documentType);
        }
      });
    } catch (error) {
      notify.error(error);
    }
  };

  const handleDownload = (content, fileName, mimeType) => {
    try {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notify.error(error);
    }
  };


  const filterExpensesByType = (allExpenses, selectedOption) => {
    if (!selectedOption) {
      return allExpenses;
    }

    return allExpenses.filter((expense) => {
      const latestDetail = expense.paymentDetails[expense.paymentDetails.length - 1];
      if (latestDetail) {
        return latestDetail.expenseType === selectedOption.label;
      }
      return false;
    });
  };

  const filteredExpenses = filterExpensesByType(allExpenses, selectedOption);


  return (
    <>
      <SubtitleDivider />
      <Box alignItems='center' mt={3}>
        {
          (allExpenses?.length === 0) ? (<>
            <Title>Expenses</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }} >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }} variant="body1" color="#272937" gutterBottom>
                  No details added
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It seems you have not added any details related to Expenses.
                </Typography>
                <Button onClick={handleClickOpen} variant="contained" color="primary">
                  Add Details
                </Button>
              </Box>
            </Container>
            <Modal open={open} onClose={handleClose}>
              <LeadsExpensesModal contractors={allExpenses} handleCloseModal={handleClose} />
            </Modal>
          </>) :
            <>
              <Box
                marginLeft={3}
                marginRight={3}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
                  <Title>Expenses</Title>
                  <AutoComplete
                    sx={{ marginLeft: '5%' }}
                    value={selectedOption}
                    onChange={(event, newValue) => {
                      setSelectedOption(newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    options={expenseTypes}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} placeholder="Expense Type" variant='outlined' fullWidth />}
                  />
                </Box>
                <LoadingButton
                  color='primary'
                  variant='contained'
                  sx={{ marginBottom: '1%', my: 1, boxShadow: 'none' }}
                  disabled={isOnHoldOrCancelled}
                >
                  <LeadsExpenseForm />
                </LoadingButton>
              </Box>
              <Box
                sx={{
                  marginLeft: 3,
                  marginRight: 3,
                  marginTop: 1,
                  marginBottom: 1,
                  overflowY: 'auto',
                  border: '2px solid #E5E5E7',
                  maxHeight: '400px',
                  position: 'relative',
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: '#F2F9FC'
                    }}>
                    <TableRow color='#64748B'>
                      <TableCell />
                      <TableCell sx={{ ...cellStyle, paddingLeft: '1.5%  ' }}>S.NO.</TableCell>
                      <TableCell sx={cellStyle}>EXPENSES NAME</TableCell>
                      <TableCell sx={cellStyle}>TOTAL AMOUNT</TableCell>
                      <TableCell sx={cellStyle}>PAID AMOUNT</TableCell>
                      <TableCell sx={cellStyle}>BALANCE</TableCell>
                      <TableCell sx={cellStyle}>EXPENSE TYPE</TableCell>
                      <TableCell sx={cellStyle}>PAID TO</TableCell>
                      <TableCell sx={cellStyle}>STATUS</TableCell>
                      <TableCell sx={cellStyle}>INVOICE NO.</TableCell>
                      <TableCell sx={cellStyle}>INVOICE</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredExpenses.length > 0 ? (filteredExpenses.map((expense, index1) => {
                      const latestPaymentDetail = Array.isArray(expense?.paymentDetails) && expense?.paymentDetails.length > 0
                        ? expense?.paymentDetails[expense?.paymentDetails.length - 1]
                        : {};
                      return (
                        <>
                          <TableRow hover key={index1} sx={{ cursor: 'pointer',color: '#272937' }} onClick={() => handleRowClick(expense.id)}>
                            <TableCell />
                            <TableCell sx={tableBodyCellStyle}>
                              <div style={{
                                fontWeight: 100,
                                fontSize: '14px',
                                align: 'left',
                                width: '60px',
                                color:'#4F90F0'
                              }}>
                                <span >{openRow === expense.id ? <KeyboardArrowUpIcon sx={{ marginBottom: '-7px' }} /> : <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />}</span>
                                <span style={{textDecoration: 'underline'}} >{(index1 + 1).toString().padStart(2, '0')}</span>
                              </div>
                            </TableCell>
                            <TableCell sx={tableBodyCellStyle}>{latestPaymentDetail?.expensesName}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>{expense?.totalAmount}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>{latestPaymentDetail?.paidAmount}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>{latestPaymentDetail?.balance || '00'}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>{latestPaymentDetail?.expenseType}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>{latestPaymentDetail?.paidTo}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>{latestPaymentDetail?.status}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>{expense?.invoiceNo}</TableCell>
                            <TableCell sx={tableBodyCellStyle}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                  {Array.isArray(expense?.paymentDetails) && expense?.paymentDetails.length > 0
                                    ? initialDocuments
                                      ?.filter(doc =>
                                        latestPaymentDetail?.paymentDocuments?.includes(doc.metadata.fileId)
                                      )
                                      .map(doc => (
                                        <div key={doc.metadata.fileId}>{doc.metadata.documentName}</div>
                                      ))
                                    : 'N/A'}
                                </div>
                                <IconButton onClick={(e) => {
                                  e.stopPropagation();
                                  handleInvoiceDownload(initialDocuments, expense);
                                }}
                                  disabled={!Array.isArray(expense?.paymentDetails) || expense?.paymentDetails.length === 0}>
                                  <DownloadIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
                              <Collapse in={openRow === expense.id} timeOut="auto" unmountOnExit>
                                <Box>
                                  <LeadsExpenseInvoice setHandleViewData={handleView} handleDownload={handleDownload} initialDocuments={initialDocuments} id={expense.id} expensesDetails={allExpenses} />
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })) : (
                      <TableRow>
                        <TableCell sx={tableBodyCellStyle} colSpan={12}>No Records Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </>
        }
      </Box>
    </>
  );
};

export default LeadsExpenseInformation;