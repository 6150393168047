import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  IconButton,
  styled,
  Modal,
  Typography,
  Button,
  Input,
  TextField,
  Grid,
  Divider,
} from '@mui/material';
import {
  CustomInputText,
  customNumberInput,
  ModalStyledBox,
  ModalStyledSubBox,
  StyledLabel,
  Title,
} from 'app/common/Typography';
import { DocIcon, DownloadDragIcon, LinksAddIcon, PdfIcon } from 'app/common/icons';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { notify } from 'app/services/notify';
import { documentTypes, projectTypes } from 'app/utils/constant';
import { useProjectUpdateStage } from './hooks/useProjectUpdateStage';
import { convertDayJSDateToUTC } from 'app/utils/helpers';
import { AddLinkButton } from '../../listings/Modals/StyledComponents';

const SubtitleDivider = styled('div')({
  // height: '3px',
  // backgroundColor: 'var(--subtitleDivider)',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderRadius: '4px',
  marginTop: '10px',
  border: '2px dashed lightgray',
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 0,
    backgroundColor: 'transparent',
  },
}));

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  padding: '8px',
  border: '1px solid var(--fileContainer-border)',
  backgroundColor: 'var(--fileContainer-bg)',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '40vh',
  overflowY: 'auto',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
  flexGrow: 1,
});

export const StyledBox1 = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '0',
  right: '0',
  transform: 'translate(0, 0)',
  backgroundColor: theme.palette.text.white,
  borderRadius: '4px',
  border: '1px solid #D6DEE9',
  width: '42%',
  height: '100vh',
  overflowY: 'auto',
  zIndex: 9999,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: '500',
  color: theme.palette.text.primary,
  textTransform: 'none',
  marginLeft: '1%',
}));

export const StyledCrossIcon = styled(IconButton)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.text.cancleIcon,
}));

export const StyledBox3 = styled(Box)(({ theme }) => ({
  padding: '4px',
  paddingTop: '0',
  margin: '0 10px 0 10px',
  overflowY: 'auto',
  maxHeight: 'calc(100%-75px)',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '44px',
  },
  width: '98%',
  color: theme.palette.text.textfield,
  marginLeft: '2%',
  borderRadius: '3px',
  border: '1px  #BDC1C6',
  '& .MuiInputLabel-root': {
    color: theme.palette.text.textfield,
  },
}));

export const StyledBox4 = styled(Box)(({ theme }) => ({
  display: 'flex',
  float: 'right',
  gap: '8%',
  marginTop: '4%',
  marginBottom: '3%',
}));

export const ModalButtonCancle = styled(LoadingButton)(({ theme }) => ({
  boxShadow: 'none',
  height: '30px',
  backgroundColor: theme.palette.text.cancleButtonBG,
  color: theme.palette.text.cancleButton,
  width: '88px',
  border: 'none',
  padding: '20px',
  textTransform: 'none',
}));

export const ModalButtonSave = styled(LoadingButton)(({ theme }) => ({
  boxShadow: 'none',
  height: '30px',
  padding: '20px',
  backgroundColor: theme.palette.text.buttonbg,
  color: theme.palette.text.white,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.text.buttonbg,
  },
}));

const OnGoingDialog = ({ isOpenDialog, closeDialog, selectedOption, projectId }) => {
  const { mutate: projectMoveToOnGoing, isPending: isMovingToOnGoing } = useProjectUpdateStage(
    projectId,
    projectTypes.ON_GOING,
  );

  const [newDocument, setNewDocuments] = useState({
    documents: [],
  });
  const [purchasePrice, setPurchasePrice] = useState('');
  const [closingCost, setClosingCost] = useState('');
  const [realtorRebate, setRealtorRebate] = useState('');
  const [closingDate, setClosingDate] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactPersonNumber, setContactPersonNumber] = useState('');
  const [contactPersonEmail, setContactPersonEmail] = useState('');
  const [lenderName, setLenderName] = useState('');
  const [lenderContactNumber, setLenderContactNumber] = useState('');
  const [lenderEmail, setLenderEmail] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [credits, setCredits] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [leaseTerm, setLeaseTerm] = useState('');
  const [errors, setErrors] = useState({});

  const [contactPersons, setContactPersons] = useState([{ name: '', phoneNumber: '', email: '' }]);

  const handleAddContactPerson = () => {
    setContactPersons([...contactPersons, { name: '', phoneNumber: '', email: '' }]);
  };

  const handleContactPersonChange = (index, field, value) => {
    const updatedContacts = [...contactPersons];
    updatedContacts[index][field] = value;
    setContactPersons(updatedContacts);
  };

  const handleRemoveContactPerson = (index) => {
    const updatedContacts = contactPersons.filter((_, i) => i !== index);
    setContactPersons(updatedContacts);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.map((file) => ({
      file,
      docName: '',
      type: documentTypes.CLOSING,
    }));
    setNewDocuments((prevState) => ({
      documents: [...prevState.documents, ...newFiles],
    }));
  };

  const handleDocumentNameChange = (event, index) => {
    const { value } = event.target;
    const updatedDocuments = [...newDocument.documents];
    updatedDocuments[index].docName = value;
    setNewDocuments({
      ...newDocument,
      documents: updatedDocuments,
    });
    if (errors[`docName_${index}`]) {
      const updatedErrors = { ...errors };
      delete updatedErrors[`docName_${index}`];
      setErrors(updatedErrors);
    }
  };

  const handleDeleteFile = (index) => {
    const updatedDocuments = [...newDocument.documents];
    updatedDocuments.splice(index, 1);
    setNewDocuments({
      ...newDocument,
      documents: updatedDocuments,
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const newFiles = files.map((file) => ({ file, docName: '' }));
    setNewDocuments((prevState) => ({
      documents: [...prevState.documents, ...newFiles],
    }));
  };

  const handleChange = (setter, field) => (event) => {
    setter(event.target.value);
    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[+]?[0-9]{10,14}$/;

    if (!purchasePrice || parseFloat(purchasePrice) <= 0)
      newErrors.purchasePrice = 'Purchase price is required and should be greater than 0';
    if (!closingCost || parseFloat(closingCost) <= 0)
      newErrors.closingCost = 'Closing cost is required and should be greater than 0';
    if (!realtorRebate || parseFloat(realtorRebate) <= 0)
      newErrors.realtorRebate = 'Realtor rebate is required and should be greater than 0';
    if (!closingDate) newErrors.closingDate = 'Closing date is required';
    if (!companyName) newErrors.companyName = 'Company name is required';
    if (!contactPerson) newErrors.contactPerson = 'Contact person is required';
    if (!contactPersonNumber || !phonePattern.test(contactPersonNumber))
      newErrors.contactPersonNumber =
        'Contact person number is required and should be a valid phone number';
    if (!contactPersonEmail || !emailPattern.test(contactPersonEmail))
      newErrors.contactPersonEmail = 'Contact person email is required and should be a valid email';
    if (!lenderName && selectedOption === 'loan') newErrors.lenderName = 'Lender name is required';
    if (!lenderContactNumber && selectedOption === 'loan')
      newErrors.lenderContactNumber =
        'Lender contact number is required and should be a valid phone number';
    if (!lenderEmail && selectedOption === 'loan')
      newErrors.lenderEmail = 'Lender email is required and should be a valid email';
    if ((!downPayment || parseFloat(downPayment) <= 0) && selectedOption === 'loan')
      newErrors.downPayment = 'Down payment is required and should be greater than 0';
    if ((!loanAmount || parseFloat(loanAmount) <= 0) && selectedOption === 'loan')
      newErrors.loanAmount = 'Loan amount is required and should be greater than 0';
    if ((!interestRate || parseFloat(interestRate) <= 0) && selectedOption === 'loan')
      newErrors.interestRate = 'Interest rate is required and should be greater than 0';
    if ((!leaseTerm || parseFloat(leaseTerm) <= 0) && selectedOption === 'loan')
      newErrors.leaseTerm = 'Lease term is required and should be greater than 0';

    newDocument.documents.forEach((doc, index) => {
      if (!doc.docName) {
        newErrors[`docName_${index}`] = 'Document name is required';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (newDocument.documents.length === 0) {
      notify.error('Please add at least one document.');
      return;
    }
    if (!validateForm()) {
      return;
    }

    const closingContractDetails = {
      purchasePrice: parseFloat(purchasePrice),
      closingCost: parseFloat(closingCost),
      realtorRebate: realtorRebate,
      closingDate: closingDate ? convertDayJSDateToUTC(closingDate) : null,
      companyDetails: {
        companyName: companyName,
        contactPerson: contactPerson,
        contactPersonNumber: contactPersonNumber,
        contactPersonEmail: contactPersonEmail,
      },
      lenderDetails: {
        lenderName: lenderName,
        lenderContactNumber: lenderContactNumber,
        lenderEmail: lenderEmail,
        downPayment: parseFloat(downPayment),
        loanAmount: parseFloat(loanAmount),
        interestRate: parseFloat(interestRate),
        leaseTerm: parseInt(leaseTerm),
      },
    };

    const { documents } = newDocument;

    projectMoveToOnGoing(
      { documents, closing: closingContractDetails },
      {
        onSuccess: () => {
          notify.success(
            <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
              Project successfully moved to
              <Grid>{`the Ongoing Project`}</Grid>
            </Typography>,
          );
          closeDialog();
        },
      },
    );
  };

  return (
    <>
      <Modal open={isOpenDialog} onClose={closeDialog}>
        <ModalStyledBox>
          <ModalStyledSubBox>
            <Title>New Project to Ongoing</Title>
            <IconButton onClick={closeDialog}>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: 'var(--closeIcon)',
                }}
              />
            </IconButton>
          </ModalStyledSubBox>
          <Divider />

          <Box sx={{ p: 2, paddingX: 4 }}>
            <Grid>
              <Typography style={{ fontSize: '18px', fontWeight: '600', marginBottom: '4px' }}>
                Closing details
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Purchase Price</StyledLabel>
                <StyledTextField
                  type='number'
                  value={purchasePrice}
                  onChange={handleChange(setPurchasePrice, 'purchasePrice')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.purchasePrice}
                  helperText={errors.purchasePrice}
                  sx={customNumberInput}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Closing Cost</StyledLabel>
                <StyledTextField
                  type='number'
                  value={closingCost}
                  onChange={handleChange(setClosingCost, 'closingCost')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.closingCost}
                  helperText={errors.closingCost}
                  sx={customNumberInput}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            </Grid>

            <Grid style={{ marginBottom: '0.5%' }} container spacing={2}>
              <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Realtor Rebate</StyledLabel>
                <StyledTextField
                  type='number'
                  value={realtorRebate}
                  onChange={handleChange(setRealtorRebate, 'realtorRebate')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.realtorRebate}
                  helperText={errors.realtorRebate}
                  inputProps={{ min: 0 }}
                  sx={customNumberInput}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Closing Date</StyledLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <DatePicker
                      name='closingDate'
                      value={closingDate}
                      onChange={(newValue) => {
                        setClosingDate(newValue);
                        if (errors.closingDate) {
                          setErrors((prevErrors) => ({ ...prevErrors, closingDate: null }));
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          error: !!errors.closingDate,
                          helperText: errors.closingDate,
                        },
                      }}
                      sx={{ width: '100%' }}
                      minDate={dayjs()}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
          <ModalDivider />
          <Box sx={{ p: 2, paddingX: 4 }}>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                style={{
                  fontSize: '17px',
                  fontWeight: '600',
                  marginTop: '6px',
                  marginBottom: '4px',
                }}
              >
                Title Company Details
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Name</StyledLabel>
                <StyledTextField
                  type='text'
                  value={companyName}
                  onChange={handleChange(setCompanyName, 'companyName')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Phone Number</StyledLabel>
                <StyledTextField
                  type='number'
                  value={contactPersonNumber}
                  onChange={handleChange(setContactPersonNumber, 'contactPersonNumber')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPersonNumber}
                  helperText={errors.contactPersonNumber}
                  sx={customNumberInput}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Contact Person</StyledLabel>
                <StyledTextField
                  type='text'
                  value={contactPerson}
                  onChange={handleChange(setContactPerson, 'contactPerson')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPerson}
                  helperText={errors.contactPerson}
                />
              </Grid> */}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Email</StyledLabel>

                <StyledTextField
                  type='email'
                  value={contactPersonEmail}
                  onChange={handleChange(setContactPersonEmail, 'contactPersonEmail')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPersonEmail}
                  helperText={errors.contactPersonEmail}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Contact Person Name</StyledLabel>
                <StyledTextField
                  type='text'
                  value={contactPerson}
                  onChange={handleChange(setContactPerson, 'contactPerson')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPerson}
                  helperText={errors.contactPerson}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Contact Person Phone Number</StyledLabel>
                <StyledTextField
                  type='number'
                  value={contactPersonNumber}
                  onChange={handleChange(setContactPersonNumber, 'contactPersonNumber')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPersonNumber}
                  helperText={errors.contactPersonNumber}
                  sx={customNumberInput}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledLabel sx={{ marginLeft: '2%' }}>Contact Person Email</StyledLabel>

                <StyledTextField
                  type='email'
                  value={contactPersonEmail}
                  onChange={handleChange(setContactPersonEmail, 'contactPersonEmail')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPersonEmail}
                  helperText={errors.contactPersonEmail}
                />
              </Grid>
            </Grid>
          </Box>
          <ModalDivider />
          {selectedOption === 'loan' && (
            <>
              <Box sx={{ p: 2, paddingX: 4 }}>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    style={{
                      fontSize: '17px',
                      fontWeight: '600',
                      marginTop: '6px',
                      marginBottom: '4px',
                    }}
                  >
                    Lender Details
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledLabel sx={{ marginLeft: '2%' }}>Lender Name</StyledLabel>
                    <StyledTextField
                      type='text'
                      value={lenderName}
                      onChange={handleChange(setLenderName, 'lenderName')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.lenderName}
                      helperText={errors.lenderName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledLabel sx={{ marginLeft: '2%' }}>Phone Number</StyledLabel>
                    <StyledTextField
                      type='text'
                      value={lenderContactNumber}
                      onChange={handleChange(setLenderContactNumber, 'lenderContactNumber')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.lenderContactNumber}
                      helperText={errors.lenderContactNumber}
                      sx={customNumberInput}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledLabel sx={{ marginLeft: '2%' }}>Email Address</StyledLabel>
                    <StyledTextField
                      type='email'
                      value={lenderEmail}
                      onChange={handleChange(setLenderEmail, 'lenderEmail')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.lenderEmail}
                      helperText={errors.lenderEmail}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledLabel sx={{ marginLeft: '2%' }}>Credits</StyledLabel>
                    <StyledTextField
                      type='number'
                      value={credits}
                      onChange={handleChange(setCredits, 'credits')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.downPayment}
                      helperText={errors.downPayment}
                      sx={customNumberInput}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledLabel sx={{ marginLeft: '2%' }}>Down Payment</StyledLabel>
                    <StyledTextField
                      type='number'
                      value={downPayment}
                      onChange={handleChange(setDownPayment, 'downPayment')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.downPayment}
                      helperText={errors.downPayment}
                      sx={customNumberInput}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledLabel sx={{ marginLeft: '2%' }}>Loan Amount</StyledLabel>
                    <StyledTextField
                      type='number'
                      value={loanAmount}
                      onChange={handleChange(setLoanAmount, 'loanAmount')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.loanAmount}
                      helperText={errors.loanAmount}
                      sx={customNumberInput}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledLabel sx={{ marginLeft: '2%' }}>Loan Term</StyledLabel>
                    <StyledTextField
                      type='number'
                      value={leaseTerm}
                      onChange={handleChange(setLeaseTerm, 'leaseTerm')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.leaseTerm}
                      helperText={errors.leaseTerm}
                      sx={customNumberInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledLabel sx={{ marginLeft: '2%' }}>Interest Rate</StyledLabel>
                    <StyledTextField
                      type='number'
                      value={interestRate}
                      onChange={handleChange(setInterestRate, 'interestRate')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.interestRate}
                      helperText={errors.interestRate}
                      sx={customNumberInput}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {contactPersons.map((person, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={6}>
                        <StyledLabel sx={{ marginLeft: '2%' }}>Contact Person Name</StyledLabel>
                        <StyledTextField
                          type='text'
                          value={person.name}
                          onChange={(e) => handleContactPersonChange(index, 'name', e.target.value)}
                          fullWidth
                          variant='outlined'
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledLabel sx={{ marginLeft: '2%' }}>
                          Contact Person Phone Number
                        </StyledLabel>
                        <StyledTextField
                          type='number'
                          value={person.phoneNumber}
                          onChange={(e) =>
                            handleContactPersonChange(index, 'phoneNumber', e.target.value)
                          }
                          fullWidth
                          variant='outlined'
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledLabel sx={{ marginLeft: '2%' }}>Contact Person Email</StyledLabel>
                        <StyledTextField
                          type='email'
                          value={person.email}
                          onChange={(e) =>
                            handleContactPersonChange(index, 'email', e.target.value)
                          }
                          fullWidth
                          variant='outlined'
                          size='small'
                        />
                      </Grid>
                      {index > 0 && (
                        <Grid item xs={12} sx={{ textAlign: 'right' }}>
                          <Button
                            color='error'
                            onClick={() => handleRemoveContactPerson(index)}
                            sx={{ textTransform: 'none' }}
                          >
                            Remove
                          </Button>
                        </Grid>
                      )}
                    </React.Fragment>
                  ))}
                </Grid>

                <AddLinkButton startIcon={<LinksAddIcon />} onClick={handleAddContactPerson}>
                  Contact Person
                </AddLinkButton>
              </Box>
              <ModalDivider />
            </>
          )}
          <Box sx={{ p: 2, paddingX: 4 }}>
            <Typography style={{ fontSize: '15px', fontWeight: '600', marginTop: '6px' }}>
              Closing Document
            </Typography>
            <Container onDragOver={handleDragOver} onDrop={handleDrop}>
              <Typography variant='body1' color={'var(--drag-Drop-Text)'} fontSize={'16px'}>
                <DownloadDragIcon />
                {` Drag and Drop file here or `}
                <label htmlFor='outlined-button-file'>
                  <StyledButton
                    variant='outlined'
                    component='span'
                    sx={{
                      textTransform: 'none',
                      textDecoration: 'underline',
                      color: '#282A2D',
                    }}
                  >
                    Click to upload
                  </StyledButton>
                </label>
              </Typography>
              <Input
                id='outlined-button-file'
                sx={{
                  display: 'none',
                }}
                type='file'
                size='small'
                name='file'
                onChange={handleFileChange}
                inputProps={{ accept: '.pdf, .doc, .docx' }}
              />
            </Container>
            <Typography variant='body1' color={'var(--fileFormate-Text)'} fontSize={'12px'} pt={2}>
              {`File format only word or Pdf, Size should not exceed 10MB`}
            </Typography>
            <FileContainerDetails>
              {newDocument &&
                newDocument.documents &&
                newDocument.documents.map((fileData, index) => (
                  <FileContainer key={index}>
                    <ImageContainer>
                      {fileData.file.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                      <FileDetails>
                        {fileData.file && (
                          <>
                            <Typography
                              variant='body1'
                              style={{ fontWeight: 'bold', marginLeft: '10px' }}
                            >
                              {fileData.file.name}
                            </Typography>
                            <Typography variant='caption' style={{ marginLeft: '10px' }}>
                              {(fileData.file.size / 1024).toFixed(2)} KB
                            </Typography>
                          </>
                        )}
                      </FileDetails>
                      <IconButton onClick={() => handleDeleteFile(index)}>
                        <DeleteIcon style={{ color: 'var(--deleteIcon)' }} />
                      </IconButton>
                    </ImageContainer>
                    <CustomInputText
                      value={fileData.docName}
                      onChange={(e) => handleDocumentNameChange(e, index)}
                    />
                    {errors[`docName_${index}`] && (
                      <Typography variant='caption' style={{ color: 'var(--error-text)' }}>
                        {errors[`docName_${index}`]}
                      </Typography>
                    )}
                  </FileContainer>
                ))}
            </FileContainerDetails>
            <StyledBox4>
              <ModalButtonCancle
                color='error'
                variant='outlined'
                sx={{ height: '40px', textTransform: 'none' }}
                onClick={closeDialog}
                style={{ boxShadow: 'none' }}
                disabled={isMovingToOnGoing}
              >
                Cancel
              </ModalButtonCancle>
              <ModalButtonSave
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                loading={isMovingToOnGoing}
                style={{ boxShadow: 'none' }}
                sx={{ textTransform: 'none' }}
              >
                Save
              </ModalButtonSave>
            </StyledBox4>
          </Box>
        </ModalStyledBox>
      </Modal>
    </>
  );
};

export default OnGoingDialog;
