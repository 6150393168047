import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField, Modal } from '@mui/material';
import { Form, Formik } from 'formik';
import { Title } from 'app/common/Typography';
import { useProjectPMContactPersonCreateUpdate } from './hooks/useProjectPMContactPersonCreateUpdate';
import { validationSchemas } from 'app/utils/validationSchemas';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '5%',
  paddingRight: '5%',
  marginBottom: '4%',
  borderRadius: '4px',
  marginTop: '0px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const PMContactPersonAddEdit = ({ property, contactPerson, isOpenDialog, closeModal }) => {
  const { mutate: createUpdatePMContactPerson } = useProjectPMContactPersonCreateUpdate(
    property,
    contactPerson?.contactId,
  );

  const isEditingMode = !!contactPerson;

  const initialValues = {
    name: contactPerson?.name || '',
    email: contactPerson?.email || '',
    phoneNumber: contactPerson?.phoneNumber || '',
  };

  const handleSubmit = (values, { setSubmitting }) =>
    createUpdatePMContactPerson(values, {
      onSuccess: closeModal,
      onSettled: () => setSubmitting(false),
    });

  return (
    <Modal open={isOpenDialog} onClose={closeModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '0%',
          transform: 'translate(0%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '30%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{isEditingMode ? 'Edit' : 'Add'} Contact Person Details</Title>
          <IconButton onClick={closeModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--inputLabel-color)',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.PMContactPerson()}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, isSubmitting, touched, errors }) => (
            <Form>
              <Container>
                <Grid style={{ marginTop: '1px' }} container spacing={2}>
                  <Grid item xs={12}>
                      <InputLabel>Contact Person</InputLabel>
                    <TextField
                      fullWidth
                      size='small'
                      variant='outlined'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                      <InputLabel>Email Address</InputLabel>
                    <TextField
                      fullWidth
                      variant='outlined'
                      size='small'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: '1px' }} container spacing={2}>
                  <Grid item xs={12}>
                      <InputLabel>Phone Number</InputLabel>
                    <TextField
                      fullWidth
                      variant='outlined'
                      size='small'
                      name='phoneNumber'
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Container>
              <Box
                marginBottom={2.5}
                marginLeft={3.5}
                marginRight={2.5}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <LoadingButton
                  className='cancelButton'
                  color='error'
                  variant='outlined'
                  onClick={closeModal}
                  style={{ boxShadow: 'none' }}
                  disabled={isSubmitting}
                  sx={{ textTransform: 'none' }}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  style={{ boxShadow: 'none', marginRight: '10px' }}
                  type='submit'
                  loading={isSubmitting}
                  sx={{ textTransform: 'none' }}
                >
                  {isEditingMode ? 'Update' : 'Save'}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default PMContactPersonAddEdit;
