import { TableHead, TableRow, TableCell, TableBody, Icon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Span, StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import React, { useState } from 'react';
import { SideNavMenu } from 'app/components';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropertyView from './PropertyView';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>S.No</TableCell>
      <TableCell>Property Name</TableCell>
      <TableCell>Email Address</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function PMTable({ properties }) {
  const [selectedPropertyIndex, setSelectedPropertyIndex] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const handleSelectProperty = (propertyIndex) => setSelectedPropertyIndex(propertyIndex);

  const selectedProperty = properties?.[selectedPropertyIndex];

  return selectedProperty ? (
    <PropertyView property={selectedProperty} handleBack={() => handleSelectProperty(null)} />
  ) : (
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {properties &&
            properties.map((property, index) => {
              return (
                <>
                  <StyledTableRow key={property.id}>
                    <TableCell>{`${index + 1}`.padStart(2, '0')}</TableCell>
                    <TableCell>{property?.propertyName}</TableCell>
                    <TableCell>{property?.email}</TableCell>
                    <TableCell>{property?.phoneNumber}</TableCell>
                    <TableCell align='left' sx={{ color: 'var(--tableBody-row-color)' }}>
                      <SideNavMenu
                        menuButton={
                          <UserMenu>
                            <MoreVertIcon />
                          </UserMenu>
                        }
                      >
                        <StyledItem onClick={() => handleSelectProperty(index)}>
                          <Icon>
                            <VisibilityIcon />
                          </Icon>
                          <Span>View</Span>
                        </StyledItem>
                      </SideNavMenu>
                    </TableCell>
                  </StyledTableRow>
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

export default PMTable;
