import Grid from '@mui/material/Grid';
import { StyledIcon, Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useCallback, useReducer, useRef } from 'react';
import { Card } from '@mui/material';
import { StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';
import SaleAmenitiesTableData from './SaleAmenitiesTableData';

const SET_MENU_AND_MODAL = 'SET_MENU_AND_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

// TODO: chopstxx - custom hook for modal state (members section also)
const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  anchorEl: null,
  modalState: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
};


// Dummy Data
const dummyRoles = [
  {
    name: 'Role 1',
    createdBy: 'Admin',
    services: [
      ['service1', { displayName: 'Service 1' }],
    ],
    subtypes: [
      { name: 'Sub Type 1', price: '$100' },
      { name: 'Sub Type 2', price: '$150' },
    ],
  },
  {
    name: 'Role 2',
    createdBy: 'Admin',
    services: [
      ['service3', { displayName: 'Service 3' }],
    ],
    subtypes: [
      { name: 'Sub Type A', price: '$200' },
      { name: 'Sub Type B', price: '$250' },
    ],
  },
];


function SaleAmenitiesSection() {
  const { isOnHoldOrCancelled, projectId } = useProject();

  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedRoleRef = useRef(null);

  const openModal = (action) => {
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: {
        modalState: { [action]: true },
      },
    });
  };


  const handleMenuOpen = useCallback((event, role) => {
    selectedRoleRef.current = role;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: event.currentTarget, modalState: {} },
    });
  }, []);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ px: 2, py: 1 }} elevation={0}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item display='flex' justifyContent='flex-start' alignItems='center' gap={0.5}>
                <Title>Amenities</Title>
              </Grid>
              <Grid item>
                <StyledAddButton startIcon={<StyledIcon />} onClick={() => openModal('add')}>
                  Amenities
                </StyledAddButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableFilter title={'Amenities'} />
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <SaleAmenitiesTableData
            roles={dummyRoles}
            isOnHoldOrCancelled={isOnHoldOrCancelled}
            handleMenuOpen={handleMenuOpen}
            openModal={openModal}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SaleAmenitiesSection;
