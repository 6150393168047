import React, { useState } from 'react';
import { Box, Typography, Grid, Button, styled, Card, IconButton, Modal } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LoanWithDrawalDetails from './LoanWithdrawalDetails';
import ConstructionLenderPaymentDetails from './ConstructionLenderPaymentDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConstructionLenderDetailsModal from './ConstructionLenderDetailsModal';
import { DownloadIcon } from 'app/common/icons';

const Title = styled('span')({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
});

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '120px',
  marginTop: '-1px',
  rowGap: '20px',
  borderRadius: '5px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ConstructionLenderDetails = ({ handleInvoiceDownload, contractors, id, setContractData, initialDocuments }) => {
  const [open, setOpen] = useState(false);

  const contractor = contractors[id];
  
  if (!contractor) {
    return <Typography>No contractor data available</Typography>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const contract = [
    { key: 'LENDER NAME', value: contractor?.lenderName },
    { key: 'EMAIL ADDRESS', value: contractor?.lenderEmail },
    { key: 'PHONE NUMBER', value: contractor?.lenderPhone },
    { key: 'ADDRESS',  value: [
      [contractor?.lenderAddress, contractor?.lenderCity].filter(Boolean).join(', '),
      [contractor?.lenderState, contractor?.lenderZipCode, contractor?.lenderCountry].filter(Boolean).join(', ')
    ].filter(Boolean).join('\n') },
  ];

  const contactPerson = [
    { key: 'LENDER CONTACT NAME', value: contractor?.lenderContactPerson?.lenderContactPersonName },
    { key: 'EMAIL ADDRESS', value: contractor?.lenderContactPerson?.lenderContactPersonEmail },
    { key: 'PHONE NUMBER', value: contractor?.lenderContactPerson?.lenderContactPersonPhone },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <IconButton style={{ margin: '1%' }} onClick={() => setContractData(null)}>
          <ArrowBackIcon />
        </IconButton>
        <Box marginLeft={3} marginRight={3}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
              <Title>Contruction Lender Details</Title>
              <Button
                style={{ color: '#4F90F0', fontSize: '14px' }}
                onClick={handleClickOpen}
              >
                <BorderColorIcon sx={{ fontSize: '13.5px' }} />
                Edit
              </Button>
            </Box>
            <Modal open={open} onClose={handleClose}>
              <ConstructionLenderDetailsModal contractors={contractors} contractor={contractor} handleCloseModal={handleClose} setContractData={setContractData} formatDate={formatDate} />
            </Modal>
            <Box marginTop={0} marginLeft={2}>
              <InfoContainer>
                {contract?.map(({ key, value }, index) => (
                  <Box key={index}>
                    <Key>{key}</Key>
                    <ValueContainer>
                      <Value>{value}</Value>
                    </ValueContainer>
                  </Box>
                ))}
              </InfoContainer>
            </Box>
          </Box>
        </Box>
        <Box marginLeft={3} marginRight={3} marginTop={5}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
              <Title>Lender Contact Person Details</Title>
            </Box>
            <Box marginTop={0} marginLeft={2}>
              <InfoContainer>
                {contactPerson?.map(({ key, value }, index) => (
                  <Box key={index}>
                    <Key>{key}</Key>
                    <ValueContainer>
                      <Value>{value}</Value>
                    </ValueContainer>
                  </Box>
                ))}
              </InfoContainer>
            </Box>
          </Box>
        </Box>
        <Box marginLeft={3} marginRight={3} marginTop={5}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
              <Title>Loan Details</Title>
            </Box>
            <Box marginTop={0} marginLeft={2}>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={3}>
                  <Key>
                    LOAN APPROVED AMOUNT
                  </Key>
                  <Value >{contractor?.loanDetails?.loanApprovedAmount}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>
                    LOAN WITHDRAWAL AMOUNT
                  </Key>
                  <Value >{contractor?.loanDetails?.loanWithdrawalAmount}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>
                    INTEREST RATE
                  </Key>
                  <Value >{contractor?.loanDetails?.interestRate}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>
                    LOAN TYPE
                  </Key>
                  <Value >{contractor?.loanDetails?.loanType}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>
                    LOAN TERM
                  </Key>
                  <Value >{contractor?.loanDetails?.loanTerm}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>
                    LOAN START DATE
                  </Key>
                  <Value >{formatDate(contractor?.loanDetails?.loanStartDate)}</Value>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>
                    LOAN DOCUMENT
                  </Key>
                  <Value sx={{display:'flex'}}>
                    {initialDocuments?.filter(doc => contractor?.loanDetails?.loanDocuments?.includes(doc.metadata.fileId))
                      .map(doc => (
                        <div key={doc.metadata.fileId}>{doc.metadata.documentName}</div>
                      ))}
                    <IconButton
                      onClick={() =>
                        handleInvoiceDownload(initialDocuments, contractor?.loanDetails)
                      }
                    >
                      <DownloadIcon />
                    </IconButton>

                  </Value>

                </Grid>
                <Grid item xs={12} sm={3}>
                  <Key>
                    COMMENTS
                  </Key>
                  <Value >{contractor?.comments}</Value>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <ConstructionLenderPaymentDetails gcpaymentData={contractors} clPaymentsIndex={id} />
        </Card>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <LoanWithDrawalDetails changeOrderData={contractors} clLoanwithdrawlIndex={id} />
        </Card>
      </div>
    </>
  );
};

export default ConstructionLenderDetails;
