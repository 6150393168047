import { useState, useEffect, useRef } from 'react';

const useImagePagination = (images) => {
  const imageContainerRef = useRef(null);
  const [imagesPerPage, setImagesPerPage] = useState(3); // Default images per page
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(images.length / imagesPerPage);
  const imagesToShow = images.slice(currentPage * imagesPerPage, (currentPage + 1) * imagesPerPage);

  useEffect(() => {
    const updateImagesPerPage = () => {
      if (imageContainerRef.current) {
        const containerWidth = imageContainerRef.current.offsetWidth;
        const imageWidth = 120 + 8; // Image width + gap
        const perPage = Math.floor(containerWidth / imageWidth);

        setImagesPerPage(perPage);
      }
    };

    window.addEventListener('resize', updateImagesPerPage);
    updateImagesPerPage(); // Initial calculation

    return () => window.removeEventListener('resize', updateImagesPerPage);
  }, [images]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    imagesToShow,
    currentPage,
    totalPages,
    handleNextPage,
    handlePrevPage,
    imageContainerRef,
  };
};

export default useImagePagination;
