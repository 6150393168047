import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  IconButton,
  styled,
  Grid,
  Button,
  Typography,
  Input,
  TextField,
  Modal,
  FormControl,
  FormHelperText,
} from '@mui/material';
import {
  CustomGCExpensesInputText,
  ModalCancel,
  ModalSave,
  ModalStyledBox,
  ModalStyledSubBox,
  StyledBoxFooter,
  StyledLabel,
  StyledTextField,
} from 'app/common/Typography';
import { DownloadDragIcon, PdfIcon, DocIcon } from 'app/common/icons';
import { Formik, FieldArray, Form } from 'formik';
import { Title } from 'app/common/Typography';
import { base64ToFile } from 'app/utils/helpers';
import { documentTypes } from 'app/utils/constant';
import { validationSchemas } from 'app/utils/validationSchemas';
import useProjectGCChangeOrderAddUpdate from './hooks/useProjectGCChangeOrderAddUpdate';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '4px',
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

const ModalDivider = styled('div')({
  height: '0.1em',
  backgroundColor: 'var(--modalDivider)',
});

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  border: '1px solid var(--fileContainer-border)',
  backgroundColor: 'var(--fileContainer-bg)',
  borderRadius: '4px',
  marginTop: '10px',
});
const FileContainerDetails = styled('div')({
  maxHeight: '20vh',
  overflowY: 'auto',
  width: '100%',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

const GCChangeOrderAddEdit = ({ contractor, changeOrder, changeOrderDocuments, closeDialog }) => {
  const { mutate: addUpdateProjectGCChangeOrder } = useProjectGCChangeOrderAddUpdate(
    contractor,
    changeOrder,
  );

  const isEditingMode = !!changeOrder;

  const initialValues = {
    changeOrderName: changeOrder?.changeOrderName || '',
    initialEstimate: changeOrder?.initialEstimate || '',
    cost: changeOrder?.cost || '',
    documents:
      changeOrderDocuments &&
      changeOrderDocuments
        ?.filter((doc) => changeOrder?.documentIds[0].includes(doc.metadata.fileId))
        .map((doc) => ({
          file: base64ToFile(doc.content, doc.metadata.documentName, doc.metadata.documentType),
          docName: doc.metadata.documentName,
          type: doc.metadata.type,
        })) || [],
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const document = values?.documents[0];
    const isDocumentSameAsPrevious = initialValues?.documents?.[0]?.docName === document?.docName;
    const previousDocumentID = changeOrder?.documentIds?.[0];

    addUpdateProjectGCChangeOrder(
      {
        data: values,
        isDocumentSameAsPrevious,
        previousDocumentID,
      },
      {
        onSuccess: closeDialog,
        onSettled: () => setSubmitting(false),
      },
    );
  };

  return (
    <Modal open={true} onClose={closeDialog}>
      <ModalStyledBox sx={{width: '35%'}}>
        <ModalStyledSubBox>
          <Title>{isEditingMode ? 'Edit' : 'Add'} Change Order Details</Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
            overflowY: 'auto',
            maxHeight: 'calc(100% - 75px)',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.gcChangeOrder()}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
            }) => {
              const handleFileInput = (event) => {
                event.preventDefault();
                if (isSubmitting) return;
              
                const fileAccess = {
                  change: event.target.files,
                  drop: event.dataTransfer?.files,
                };
              
                const inputFile = fileAccess[event.type];
              
                // ✅ Ensure inputFile is valid before using Array.from()
                if (!inputFile || inputFile.length === 0) return;
              
                const newDocument = Array.from(inputFile).map((file) => ({
                  file,
                  docName: '',
                  type: documentTypes.GC_CHANGE_ORDERS,
                }));
              
                setFieldValue('documents', [...(values?.documents || []), ...newDocument]);
              };              

              const handleDragOver = (event) => event.preventDefault();

              return (
                <Form>
                  <Container>
                    <Grid container sx={{ marginTop: '0.1em' }} spacing={1.5}>
                      <Grid item xs={12}>
                        <StyledLabel>Change Order Name</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          name='changeOrderName'
                          value={values.changeOrderName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.changeOrderName && errors.changeOrderName)}
                          helperText={touched.changeOrderName && errors.changeOrderName}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledLabel>Initial Estimates</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='number'
                          name='initialEstimate'
                          value={values.initialEstimate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.initialEstimate && errors.initialEstimate)}
                          helperText={touched.initialEstimate && errors.initialEstimate}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={1.5}>
                      <Grid item xs={12}>
                        <StyledLabel>Cost</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='number'
                          name='cost'
                          value={values.cost}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.cost && errors.cost)}
                          helperText={touched.cost && errors.cost}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px', marginLeft: '3%' }} container spacing={2}>
                      <Typography
                        style={{
                          fontSize: '15px',
                          fontWeight: '450',
                          marginTop: '0.8em',
                          marginBottom: '0.5em',
                        }}
                      >
                        Change Order Document
                      </Typography>
                      <FormControl fullWidth error={touched.documents && Boolean(errors.documents)}>
                        <Container
                          style={{ border: '2px dashed lightgray', height: '3em', width: '97%' }}
                          onDragOver={handleDragOver}
                          onDrop={handleFileInput}
                        >
                          <Typography
                            variant='body1'
                            marginTop={1}
                            color={'var(--drag-Drop-Text)'}
                            fontSize={'16px'}
                          >
                            <DownloadDragIcon />
                            {` Drag and Drop file here or `}
                            <label htmlFor='outlined-button-file'>
                              <StyledButton
                                disabled={values?.documents?.length > 0}
                                variant='outlined'
                                component='span'
                              >
                                Browse files
                              </StyledButton>
                            </label>
                          </Typography>
                          <Input
                            id='outlined-button-file'
                            key={values?.documents?.length}
                            sx={{
                              display: 'none',
                            }}
                            size='small'
                            type='file'
                            name='file'
                            onChange={handleFileInput}
                            inputProps={{ accept: '.pdf, .doc, .docx' }}
                            disabled={isSubmitting || values?.documents?.length > 0}
                          />
                        </Container>
                        <Typography
                          variant='body1'
                          color={'var(--fileFormate-Text)'}
                          fontSize={'12px'}
                          pt={1}
                        >
                          File format only word or PDF, Size should not exceed 10MB
                        </Typography>
                        <FileContainerDetails>
                          <FieldArray name='documents'>
                            {({ remove }) =>
                              values?.documents?.map((doc, index) => (
                                <FileContainer key={index}>
                                  <ImageContainer>
                                    {doc.file.type === 'application/pdf' ? (
                                      <PdfIcon />
                                    ) : (
                                      <DocIcon />
                                    )}
                                    <FileDetails>
                                      <Typography
                                        variant='body1'
                                        style={{ fontWeight: 'bold', marginLeft: '10px' }}
                                      >
                                        {doc.file.name}
                                      </Typography>
                                      <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                        {(doc.file.size / 1024).toFixed(2)} KB
                                      </Typography>
                                    </FileDetails>
                                    <IconButton onClick={() => remove(index)}>
                                      <DeleteIcon style={{ color: 'var(--deleteIcon)' }} />
                                    </IconButton>
                                  </ImageContainer>
                                  <ModalDivider />
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <CustomGCExpensesInputText
                                        name={`documents[${index}].docName`}
                                        value={doc.docName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(
                                          touched.documents?.[index].docName &&
                                            errors.documents?.[index].docName,
                                        )}
                                        helperText={
                                          touched.documents?.[index].docName &&
                                          errors.documents?.[index].docName
                                        }
                                        disabled={isSubmitting}
                                      />
                                    </Grid>
                                  </Grid>
                                </FileContainer>
                              ))
                            }
                          </FieldArray>
                          {touched?.documents && errors?.documents && (
                            <FormHelperText>
                              {!Array.isArray(errors.documents) && errors.documents}
                            </FormHelperText>
                          )}
                        </FileContainerDetails>
                      </FormControl>
                    </Grid>
                  </Container>
                  <StyledBoxFooter>
                    <ModalCancel
                      className='cancelButton'
                      variant='outlined'
                      onClick={closeDialog}
                      style={{ boxShadow: 'none' }}
                      disabled={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </ModalCancel>
                    <ModalSave
                      variant='contained'
                      color='primary'
                      style={{ boxShadow: 'none', marginRight: '10px' }}
                      type='submit'
                      loading={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      {isEditingMode ? 'Update' : 'Save'}
                    </ModalSave>
                  </StyledBoxFooter>
                  {/* <Box
                    marginTop={1.5}
                    sx={{
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}
                  >
                    <LoadingButton
                      className='cancelButton'
                      color='error'
                      variant='outlined'
                      onClick={closeDialog}
                      style={{ boxShadow: 'none' }}
                      disabled={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      style={{ boxShadow: 'none', marginRight: '0.1em' }}
                      type='submit'
                      loading={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      {isEditingMode ? 'Update' : 'Save'}
                    </LoadingButton>
                  </Box> */}
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalStyledBox>
    </Modal>
  );
};

export default GCChangeOrderAddEdit;
