import { TableHead, TableRow, TableCell, TableBody, Box, Collapse } from '@mui/material';
import { StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useState } from 'react';
import GCPaymentHistory from './GCPaymentHistory';
import GCPaymentAddEdit from './GCPaymentAddEdit';
const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>S.No.</TableCell>
      <TableCell>Total Amount</TableCell>
      <TableCell>Paid</TableCell>
      <TableCell>Balance</TableCell>
      <TableCell>Paid By</TableCell>
      <TableCell>Paid To</TableCell>
      <TableCell>Paid Type</TableCell>
    </TableRow>
  </TableHead>
);

function GCPaymentViewTable({ contractor, payments }) {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  return (
    <>
      <TableWrapper>
        <StyledTable>
          <TableHeader />
          <TableBody>
            {payments &&
              payments.map((payment, index) => {
                return (
                  <>
                    <StyledTableRow key={payment.id}>
                      <TableCell>
                        <div
                          style={{
                            fontWeight: 100,
                            fontSize: '14px',
                            align: 'left',
                            width: '60px',
                            color: '#645BBB',
                          }}
                          onClick={() => handleRowClick(payment.paymentId)}
                        >
                          <span>
                            {openRow === payment.paymentId ? (
                              <KeyboardArrowUpIcon sx={{ marginBottom: '-7px' }} />
                            ) : (
                              <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />
                            )}
                          </span>
                          <span>
                            {(index + 1).toString().padStart(2, '0')}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>{payment?.paymentHistory?.at(-1)?.totalAmount}</TableCell>
                      <TableCell>{payment?.paymentHistory?.at(-1)?.amountPaid}</TableCell>
                      <TableCell>{payment?.paymentHistory?.at(-1)?.balance}</TableCell>
                      <TableCell>{payment?.paymentHistory?.at(-1)?.paidBy}</TableCell>
                      <TableCell>{payment?.paymentHistory?.at(-1)?.paidTo}</TableCell>
                      <TableCell> {payment?.paymentHistory?.at(-1)?.paymentType}</TableCell>
                    </StyledTableRow>
                    {openRow === payment.paymentId && (
                      <TableRow>
                        <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
                          <Collapse
                            in={openRow === payment?.paymentId}
                            timeOut='auto'
                            unmountOnExit
                          >
                            <Box>
                              <GCPaymentHistory contractor={contractor} payment={payment} />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })}
          </TableBody>
        </StyledTable>
      </TableWrapper>
      {isOpenDialog && <GCPaymentAddEdit contractor={contractor} closeDialog={toggleDialog} />}
    </>
  );
}

export default GCPaymentViewTable;
