import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Grid,
  IconButton,
  TextField,
  styled,
  Autocomplete,
  Box,
  InputLabel,
  Avatar,
  Typography,
} from '@mui/material';
import { H1, Paragraph } from 'app/common/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, FieldArray } from 'formik';
import '../../dashboard.css';
import { validationSchemas } from 'app/utils/validationSchemas';
import { fetchRoles, fetchAllUsers } from 'app/contexts/leadActions';

import { handleAxiosError } from 'app/utils/helpers';
import useUserAuth from 'app/hooks/userUserAuth';

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  fontWeight: 500,
}));

// REVIEW: onchange functions are too big, change them after stepperForm rebuild
const Members = ({ formData, setFormData, setDisable }) => {
  const { user: currentUser } = useUserAuth();
  const [roles, setRoles] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const isFormValid = formData.members.every((member) => {
      const isEmailValid = member.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(member.email);
      const areRolesValid = member.roles.length > 0;
      return member.name && member.phone && isEmailValid && areRolesValid;
    });
    setDisable(!isFormValid);
  }, [formData.members, setDisable]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [rolesResponse, usersResponse] = await Promise.all([fetchRoles(), fetchAllUsers()]);
        setRoles(rolesResponse);
        setAllUsers(usersResponse);
      } catch (error) {
        handleAxiosError(error);
      }
    };
    fetchData();
  }, []);

  const usersMap = useMemo(() => {
    const users = new Map(allUsers.map((user) => [user.email, user]));
    users.delete(currentUser.email);
    return users;
  }, [allUsers, currentUser.email]);

  const selectedUsersMap = useMemo(() => new Map(), []);
  const availableRoles = useMemo(() => roles.filter((role) => role.name !== 'Investor'), [roles]);

  const isUserExists = (member) => {
    const user = selectedUsersMap.get(member.email);
    if (user) {
      const existingFullName = `${user.name} ${user.surName}`.toLowerCase().trim();
      const inputFullName = member.name.toLowerCase().trim();
      const existingPhone = user.contactPhone;
      const inputPhone = member.phone;

      return (
        existingFullName === inputFullName &&
        existingPhone === inputPhone &&
        user.email === member.email
      );
    }
    return false;
  };

  return (
    <div className='lead'>
      <H1>Members</H1>
      <Paragraph>The members will have access to the project, you can also restrict it</Paragraph>
      <Formik
        initialValues={formData}
        validationSchema={validationSchemas.members([currentUser])}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ values, errors, touched, handleBlur, setFieldValue }) => {
          function selectUserInMap(email, value) {
            selectedUsersMap.set(email, value);
            usersMap.delete(email);
          }

          function removeUserFromMap(email) {
            const deletedMember = selectedUsersMap.get(email);
            if (deletedMember) {
              usersMap.set(email, deletedMember);
              selectedUsersMap.delete(email);
            }
          }

          function handleUserSelection(value, index) {
            if (value) {
              selectUserInMap(value.email, value);

              setFieldValue(`members[${index}].name`, `${value.name} ${value.surName}`);
              setFieldValue(`members[${index}].phone`, value.contactPhone);
              setFieldValue(`members[${index}].email`, value.email);

              const updatedMembers = [...formData.members];
              updatedMembers[index] = {
                ...updatedMembers[index],
                name: `${value.name} ${value.surName}`,
                phone: value.contactPhone,
                email: value.email,
              };
              setFormData({ ...formData, members: updatedMembers });
            }
          }

          function handleUserRemoval(index) {
            const member = formData.members[index];
            removeUserFromMap(member.email);

            setFieldValue(`members[${index}].phone`, '');
            setFieldValue(`members[${index}].email`, '');
            setFieldValue(`members[${index}].roles`, []);

            const updatedMembers = [...formData.members];
            updatedMembers[index] = {
              ...updatedMembers[index],
              phone: '',
              email: '',
              roles: [],
            };
            setFormData({ ...formData, members: updatedMembers });
          }

          function filterUserOptions(options, state) {
            const inputValue = state.inputValue.trim().toLowerCase();
            if (inputValue.length < 3) return [];
            const inputParts = inputValue.split(/\s+/);
            return options.filter((option) => {
              const fullName = `${option.name} ${option.surName}`.toLowerCase();
              return inputParts.every((part) => fullName.includes(part));
            });
          }
          return (
            <Form>
              <FieldArray name='members'>
                {({ push, remove }) => (
                  <div>
                    {values.members.map((member, index) => (
                      <Grid container spacing={3} key={index}>
                        <Grid item lg={5.5} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                          <InputLabel
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              mb: 1,
                              color: '#272937',
                            }}
                          >
                            Member Name
                          </InputLabel>
                          <Autocomplete
                            freeSolo
                            options={Array.from(usersMap.values())}
                            getOptionLabel={(user) => `${user.name} ${user.surName}`}
                            noOptionsText={values.members[index].name.length < 3 && ''}
                            filterOptions={filterUserOptions}
                            onChange={(_, value) => handleUserSelection(value, index)}
                            inputValue={member.name}
                            onInputChange={(_, newInputValue) => {
                              setFieldValue(`members[${index}].name`, newInputValue);
                              const updatedMembers = [...formData.members];
                              updatedMembers[index].name = newInputValue;
                              setFormData({ ...formData, members: updatedMembers });
                              if (!newInputValue) {
                                handleUserRemoval(index);
                              }
                            }}
                            renderOption={(props, user) => (
                              <Box
                                component='li'
                                sx={{ display: 'flex', alignItems: 'center' }}
                                {...props}
                                key={user.email}
                              >
                                <Avatar
                                  sx={{
                                    bgcolor: 'grey',
                                    width: 30,
                                    height: 30,
                                    marginRight: 2,
                                    fontSize: 12,
                                    fontWeight: 700,
                                  }}
                                >
                                  {`${user?.name?.[0]?.toUpperCase()}${user?.surName?.[0]?.toUpperCase()}`}
                                </Avatar>
                                <Box>
                                  <Typography variant='subtitle2' noWrap>
                                    {`${user?.name} ${user?.surName}`}
                                  </Typography>
                                  <Typography variant='body2' color='text.secondary' noWrap>
                                    {user?.email}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder='e.g. John Doe'
                                name={`members[${index}].name`}
                                onBlur={handleBlur}
                                error={
                                  touched.members?.[index]?.name &&
                                  Boolean(errors.members?.[index]?.name)
                                }
                                helperText={
                                  touched.members?.[index]?.name && errors.members?.[index]?.name
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={5.5} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                            }}
                          >
                            <InputLabel
                              sx={{
                                fontWeight: 700,
                                fontSize: 14,
                                color: '#272937',
                                mb: 1,
                              }}
                            >
                              Select Roles
                            </InputLabel>
                            {values.members.length > 1 && (
                              <IconButton
                                onClick={() => {
                                  handleUserRemoval(index);
                                  const updatedMembers = [...formData.members];
                                  updatedMembers.splice(index, 1);
                                  setFormData({ ...formData, members: updatedMembers });
                                  remove(index);
                                }}
                                size='small'
                                sx={{ padding: 0 }}
                              >
                                <CloseIcon style={{ color: 'red' }} />
                              </IconButton>
                            )}
                          </Box>
                          <Autocomplete
                            multiple
                            options={availableRoles || []}
                            getOptionLabel={(role) => role.name}
                            value={values.members[index].roles}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                            onChange={(event, newValue) => {
                              const updatedMembers = [...formData.members];
                              updatedMembers[index].roles = newValue;
                              setFormData({ ...formData, members: updatedMembers });
                              setFieldValue(`members[${index}].roles`, newValue);
                            }}
                            filterSelectedOptions={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                label='Select category'
                                name={`members[${index}].roles`}
                                onBlur={handleBlur}
                                error={
                                  touched.members?.[index]?.roles &&
                                  Boolean(errors.members?.[index]?.roles)
                                }
                                helperText={
                                  touched.members?.[index]?.roles && errors.members?.[index]?.roles
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={5.5} md={12} sm={12} xs={12}>
                          <InputLabel
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              mb: 1,
                              color: '#272937',
                            }}
                          >
                            Contact Number
                          </InputLabel>
                          <TextField
                            fullWidth
                            name={`members[${index}].phone`}
                            placeholder='e.g. +08012345678'
                            value={values.members[index].phone}
                            onChange={(e) => {
                              setFieldValue(`members[${index}].phone`, e.target.value);
                              const updatedMembers = [...formData.members];
                              updatedMembers[index].phone = e.target.value;
                              setFormData({ ...formData, members: updatedMembers });
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.members?.[index]?.phone &&
                              Boolean(errors.members?.[index]?.phone)
                            }
                            helperText={
                              touched.members?.[index]?.phone && errors.members?.[index]?.phone
                            }
                            InputProps={{
                              readOnly: isUserExists(member),
                            }}
                          />
                        </Grid>
                        <Grid item lg={5.5} md={12} sm={12} xs={12}>
                          <InputLabel
                            sx={{
                              fontWeight: 700,
                              fontSize: 14,
                              mb: 1,
                              color: '#272937',
                            }}
                          >
                            Email Address
                          </InputLabel>
                          <TextField
                            fullWidth
                            name={`members[${index}].email`}
                            placeholder='e.g. johndoe@gmail.com'
                            value={values.members[index].email}
                            onChange={(e) => {
                              setFieldValue(`members[${index}].email`, e.target.value);
                              const updatedMembers = [...formData.members];
                              updatedMembers[index].email = e.target.value;
                              setFormData({ ...formData, members: updatedMembers });
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.members?.[index]?.email &&
                              Boolean(errors.members?.[index]?.email)
                            }
                            helperText={
                              touched.members?.[index]?.email && errors.members?.[index]?.email
                            }
                            InputProps={{
                              readOnly: isUserExists(member),
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                    <StyledButton
                      type='button'
                      disabled={errors.members}
                      onClick={() => {
                        const newMember = { name: '', phone: '', email: '', roles: [] };
                        setFormData({
                          ...formData,
                          members: [...formData.members, newMember],
                        });
                        push(newMember);
                      }}
                    >
                      + Add Member
                    </StyledButton>
                  </div>
                )}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Members;
