import React, { useState } from 'react';
import { Box, Grid, TextField, Modal, IconButton, styled, Typography, Button } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import CloseIcon from '@mui/icons-material/Close';
import {
  Footer,
  ModalCancel,
  ModalContent,
  ModalDivider,
  ModalSave,
  StyledBoxFooter,
} from 'app/common/Typography';
import { useFormik } from 'formik';
import SaleAddEdit from './SaleAddEdit';
import { parseAddressComponents } from 'app/utils/helpers';
import { validationSchemas } from 'app/utils/validationSchemas';
import { StyledLabel } from '../../listings/Modals/StyledComponents';
import { LoadingButton } from '@mui/lab';
import BuyerInvestmentModal from './NuyerinvestmentAddEdit';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectBuildingUnitSaleCreateUpdate } from './hooks/useProjectBuildingUnitSaleCreateUpdate';

const BuyerAddEdit = ({
  open,
  onClose,
  selectedBuilding,
  selectedUnit,
  saleData,
  buyerData,
  saleId,
  isEdit = false,
  onBack,
  handleBuyerSaleFormClick,
  showPrevious = true,
}) => {
  console.log('sale data', saleData);
  const searchInputRef = React.useRef(null);
  const [openBuyerInvestmentModal, setOpenBuyerInvestmentModal] = useState(false);
const { projectId } = useProject();
  const { mutate: createUpdateSale } = useProjectBuildingUnitSaleCreateUpdate(projectId, saleData);
  const handleSaveSubmit = (values, { setSubmitting }) => {
    if(showPrevious) {
      setOpenBuyerInvestmentModal(true);
      onClose();
      setSubmitting(false);
    } else {
      createUpdateSale(
        {
          buyerData: formik.values,
          updatedSaleData: saleData,
          selectedBuilding,
          selectedUnit,
          saleId,
        },
        {
          onSettled: () => setSubmitting(false),
          onSuccess: handleClose,
        },
      );
    }
    
  };

  const formik = useFormik({
    initialValues: {
      buyerName: buyerData?.buyerName || '',
      buyerContactPerson: buyerData?.contactPerson || '',
      address1: buyerData?.address || '',
      city: buyerData?.city || '',
      state: buyerData?.state || '',
      country: buyerData?.country || '',
      zipCode: buyerData?.zipCode || '',
      phoneNo: buyerData?.phoneNo || '',
      email: buyerData?.email || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchemas.buyer(),
    onSubmit: handleSaveSubmit,
  });

  const handleBackToBuyerInvestmentModal = () => setOpenBuyerInvestmentModal(false);

  const handleBackTobuyerForm = () => {
    setOpenBuyerInvestmentModal(false);
    handleBuyerSaleFormClick();
  };

  const handleBuyerModalOpen = () => {
    setOpenBuyerInvestmentModal(true);
  };

  const handleClose = () => {
    formik.resetForm();
    handleBackToBuyerInvestmentModal();
    onClose();
  };

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '0%',
            transform: 'translate(0%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            height: '100%',
            paddingLeft: '40px',
          }}
        >
          <ModalContent>
            <Box sx={{ justifyContent: 'space-between', display: 'flex', marginBottom: '20px' }}>
              <Typography variant='h6'>{`${isEdit ? 'Edit' : 'Add'} Buyer Details`} </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
              </IconButton>
            </Box>
            {/* <ModalDivider /> */}

            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledLabel>Buyer Name</StyledLabel>
                  <TextField
                    name='buyerName'
                    id='buyerName'
                    size='small'
                    fullWidth
                    type='text'
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.buyerName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.buyerName && Boolean(formik.errors.buyerName)}
                    helperText={formik.touched.buyerName && formik.errors.buyerName}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Contact Person</StyledLabel>
                  <TextField
                    name='buyerContactPerson'
                    id='buyerContactPerson'
                    size='small'
                    type='text'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.buyerContactPerson}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.buyerContactPerson && Boolean(formik.errors.buyerContactPerson)
                    }
                    helperText={
                      formik.touched.buyerContactPerson && formik.errors.buyerContactPerson
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Email</StyledLabel>
                  <TextField
                    name='email'
                    id='email'
                    size='small'
                    type='email'
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Phone Number</StyledLabel>
                  <TextField
                    name='phoneNo'
                    id='phoneNo'
                    size='small'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        'e',
                        'E',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.phoneNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                    helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StyledLabel>Address</StyledLabel>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                    onUnmount={() => (searchInputRef.current = null)}
                    onPlacesChanged={() => handlePlacesChanged(formik.setValues)}
                  >
                    <TextField
                      id='address1'
                      name='address1'
                      size='small'
                      fullWidth
                      value={formik.values.address1}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (!value) {
                          formik.setValues((prevState) => ({
                            ...prevState,
                            search: '',
                            address1: '',
                            country: '',
                            city: '',
                            state: '',
                            zipCode: '',
                            latitude: null,
                            longitude: null,
                          }));
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.address1 && Boolean(formik.errors.address1)}
                      helperText={formik.touched.address1 && formik.errors.address1}
                    />
                  </StandaloneSearchBox>
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>City</StyledLabel>
                  <TextField
                    name='city'
                    id='city'
                    size='small'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>State</StyledLabel>
                  <TextField
                    name='state'
                    id='state'
                    size='small'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Country</StyledLabel>
                  <TextField
                    id='country'
                    name='country'
                    size='small'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Zip Code</StyledLabel>
                  <TextField
                    name='zipCode'
                    size='small'
                    id='zipCode'
                    type='number'
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    value={formik.values.zipCode}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (Number(newValue) >= 0) {
                        formik.setFieldValue('zipCode', newValue);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                  />
                </Grid>
              </Grid>
              {showPrevious ? (
                <Grid
                  item
                  xs={12}
                  textAlign='center'
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={onBack}
                      // startIcon={<ArrowBackIcon size='small' />}
                      disabled={formik.isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Previous
                    </Button>
                  </Box>
                  <StyledBoxFooter>
                    <ModalCancel
                      className='cancelButton'
                      variant='outlined'
                      onClick={handleClose}
                      style={{ boxShadow: 'none' }}
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </ModalCancel>
                    <ModalSave
                      color='primary'
                      size='large'
                      variant='contained'
                      type='submit'
                      sx={{ textTransform: 'none', marginLeft: 2 }}
                      loading={formik.isSubmitting}
                    >
                      Save
                    </ModalSave>
                  </StyledBoxFooter>
                </Grid>
              ) : (
                <StyledBoxFooter>
                  <ModalCancel
                    className='cancelButton'
                    variant='outlined'
                    onClick={handleClose}
                    style={{ boxShadow: 'none' }}
                    sx={{ textTransform: 'none' }}
                  >
                    Cancel
                  </ModalCancel>
                  <ModalSave
                    color='primary'
                    size='large'
                    variant='contained'
                    type='submit'
                    sx={{ textTransform: 'none', marginLeft: 2 }}
                  >
                    Save
                  </ModalSave>
                </StyledBoxFooter>
              )}
            </form>
          </ModalContent>
        </Box>
      </Modal>
      <BuyerInvestmentModal
        open={openBuyerInvestmentModal}
        onClose={() => setOpenBuyerInvestmentModal(false)}
        buyerData={formik.values}
        saleData={saleData}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        saleId={saleId}
        onBack={handleBackTobuyerForm}
        handleBuyerModalOpen={handleBuyerModalOpen}
        isEdit={isEdit}
      />
    </>
  );
};

export default BuyerAddEdit;
