import React from 'react';
import { Box, Grid, Divider, Modal } from '@mui/material';
import { PDSubHeadings, PDSubValues } from './StyledComponent';
import { EditIcon } from 'app/common/icons';
import ViewPropertyDetailsModal from './Modals/ViewPropertyDetailsModal';
import EditPropertyDetailsModal from './Modals/EditPropertyDetailsModal';
import { useParams } from 'react-router-dom';
import {
  StyledEditIcon,
  PropertyBodySec,
  SecHeading,
  SecValue,
  ViewAllLink,
} from './StyledComponent';
import { Loading } from 'app/components';
import { useProjectBasic } from '../LeadsData/Basic/hooks/useProjectBasic';

const PropertyInfoSection = () => {
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const { projectId } = useParams();
  const { data: basic, isPending } = useProjectBasic(projectId);
  const isResidential =
    basic?.subType === 'Residential - Single Family' ||
    basic?.subType === 'Residential - Multi Family';
  const handleOpenViewModal = () => {
    setOpenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const truncateDescription = (text, wordLimit = 20) => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  return (
    <>
      <Box>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ margin: '20px 20px 20px 20px' }}
        >
          <PDSubHeadings sx={{ fontWeight: '600', fontSize: '24px' }}>
            Property Details
          </PDSubHeadings>
          <StyledEditIcon
            variant='outlined'
            size='small'
            startIcon={<EditIcon />}
            onClick={handleOpenEditModal}
          >
            Property Details
          </StyledEditIcon>
        </Box>
        <Divider />
      </Box>
      {isPending ? (
        <Loading />
      ) : (
        <>
          <Modal open={openEditModal} onClose={handleCloseEditModal}>
            <EditPropertyDetailsModal
              handleCloseEditModal={handleCloseEditModal}
              property={basic}
            />
          </Modal>

          <Box sx={{ marginTop: '30px', marginLeft: '22px' }}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <PDSubHeadings>Property Type</PDSubHeadings>
                <PDSubValues>{basic?.propertyType}</PDSubValues>
              </Grid>
              <Grid item xs={4}>
                <PDSubHeadings>Sub Type</PDSubHeadings>
                <PDSubValues>{basic?.subType}</PDSubValues>
              </Grid>
              <Grid item xs={4}>
                <PDSubHeadings>Area(in acres)</PDSubHeadings>
                <PDSubValues>{basic?.size}</PDSubValues>
              </Grid>
              <Grid item xs={4}>
                <PDSubHeadings>Zone</PDSubHeadings>
                <PDSubValues>-</PDSubValues>
              </Grid>
              <Grid item xs={8}>
                <PDSubHeadings>Estimated Price</PDSubHeadings>
                <PDSubValues>${basic?.estimatedPrice}</PDSubValues>
              </Grid>
            </Grid>
          </Box>
          {basic?.description && (
            <PropertyBodySec>
              <SecHeading>Description:</SecHeading>
              <SecValue
                dangerouslySetInnerHTML={{
                  __html: truncateDescription(basic?.description),
                }}
              />
            </PropertyBodySec>
          )}
          {basic?.referenceLink && (
            <PropertyBodySec>
              <SecHeading>Reference Link:</SecHeading>
              <SecValue>{basic?.referenceLink}</SecValue>
            </PropertyBodySec>
          )}
          {isResidential && (
            <PropertyBodySec>
              <SecHeading>Standard Amenities:</SecHeading>
              <Box sx={{ marginTop: '5px' }}>
                <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <PDSubHeadings>Flooring</PDSubHeadings>
                    <PDSubValues>Marbles</PDSubValues>
                  </Grid>
                  <Grid item xs={3}>
                    <PDSubHeadings>Gym Facility</PDSubHeadings>
                    <PDSubValues>Cardio</PDSubValues>
                  </Grid>
                  <Grid item xs={3}>
                    <PDSubHeadings>Swimming Pool</PDSubHeadings>
                    <PDSubValues>Adult</PDSubValues>
                  </Grid>
                  <Grid item xs={3}>
                    <PDSubHeadings>Tennis Court</PDSubHeadings>
                    <PDSubValues>Singles</PDSubValues>
                  </Grid>
                </Grid>
              </Box>
            </PropertyBodySec>
          )}
          <Box
            sx={{
              marginTop: ' 2rem',
              display: 'flex',
              alignContent: 'flexStart',
              marginLeft: '1.5rem',
              marginBottom: '1.5rem',
            }}
          >
            <ViewAllLink onClick={handleOpenViewModal} type='button'>
              View Full Description
            </ViewAllLink>
            <Modal open={openViewModal} onClose={handleCloseViewModal}>
              <ViewPropertyDetailsModal
                handleCloseViewModal={handleCloseViewModal}
                handleOpenEditModal={handleOpenEditModal}
                property={basic}
              />
            </Modal>
          </Box>
        </>
      )}
    </>
  );
};

export default PropertyInfoSection;
