import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import { Button, Select, MenuItem, TextField } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useProject } from 'app/contexts/ProjectContext';
import { formatCurrency, handleAxiosError } from 'app/utils/helpers';
import { LoadingButton } from '@mui/lab';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '90px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const LeadsBasicinformation = () => {
  const { basic, isOnHoldOrCancelled, updateDetailsBasic } = useProject();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    estimatedPrice: '',
    size: '',
    propertyType: '',
    subType: '',
    description: '',
    referenceLink: ''
  });

  const handleOpen = () => {
    setFormData({
      estimatedPrice: basic?.estimatedPrice,
      size: basic?.size,
      propertyType: basic?.propertyType,
      subType: basic?.subType,
      description: basic?.description,
      referenceLink: basic?.referenceLink
    });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const updatedData = {
      ...basic,
      estimatedPrice: formData.estimatedPrice,
      size: formData.size,
      propertyType: formData.propertyType,
      subType: formData.subType,
      description: formData.description,
      referenceLink: formData.referenceLink
    };

    try {
      setLoading(true);
      await updateDetailsBasic(updatedData);
      handleClose();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  const infoPairs = [
    { key: 'ESTIMATED COST', value: `${formatCurrency(basic.estimatedPrice)}` },
    { key: 'PROPERTY SIZE', value: `${parseFloat(basic.size).toFixed(2)} Acres` },
    { key: 'PROPERTY TYPE', value: basic.propertyType },
    { key: 'SUB TYPE', value: basic.subType },
    { key: 'Description', value: basic.description },
    { key: 'Reference Link', value: basic.referenceLink },
  ];

  const propertyTypeOptions = [{ label: 'Land' }];
  const subTypeOptions = [
    { label: 'Commercial' },
    { label: 'Residential' },
    { label: 'Industrial' },
  ];

  return (
    <div className='viewlead'>
      <Box sx={{ height: '3px', backgroundColor: '#4F90F033' }} />
      <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title>basic Details :</Title>
          <Button
            onClick={handleOpen}
            style={{ color: '#4F90F0', fontSize: '14px' }}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
          </Button>
        </Box>
        <InfoContainer item lg={6} md={6} sm={12} xs={12}>
          {infoPairs.map(({ key, value }, index) => (
            <Box key={index}>
              <Key>{key}</Key>
              <Value className='basicvalues'>{value ?? ''}</Value>
            </Box>
          ))}
        </InfoContainer>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit basic Details</DialogTitle>
        <DialogContent>
          <TextField
            label='Estimated Cost'
            name='estimatedPrice'
            value={formData.estimatedPrice}
            onChange={handleChange}
            fullWidth
            margin='normal'
          />
          <TextField
            label='Property Size'
            name='size'
            value={formData.size}
            onChange={handleChange}
            fullWidth
            margin='normal'
          />
          <Select
            name='propertyType'
            value={formData.propertyType}
            onChange={handleChange}
            fullWidth
            margin='normal'
            style={{ marginBottom: '7px' }}
          >
            {propertyTypeOptions.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Select
            name='subType'
            value={formData.subType}
            onChange={handleChange}
            fullWidth
            margin='normal'
          >
            {subTypeOptions.map((option) => (
              <MenuItem key={option.label} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label='Description'
            name='description'
            value={formData.description}
            onChange={handleChange}
            fullWidth
            margin='normal'
          />
          <TextField
            label='Reference Link'
            name='referenceLink'
            value={formData.referenceLink}
            onChange={handleChange}
            fullWidth
            margin='normal'
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleClose} variant='outlined' disabled={loading}>
            Cancel
          </LoadingButton>
          <LoadingButton
            onClick={handleSubmit}
            color='primary'
            variant='outlined'
            loading={loading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeadsBasicinformation;
