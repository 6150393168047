import React, { useMemo, useState } from 'react';
import {
  DialogTitle,
  IconButton,
  Box,
  Grid,
  Button,
  FormControl,
  Input,
  Typography,
  FormHelperText,
  Modal,
  Divider,
  Autocomplete,
  Avatar,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Formik, Form, getIn } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectUpdateStage } from './hooks/useProjectUpdateStage';
import { notify } from 'app/services/notify';
import { useProject } from 'app/contexts/ProjectContext';
import {
  StyledBox1,
  StyledBox2,
  StyledBox3,
  StyledCrossIcon,
  StyledLabel,
  StyledModalTitle,
} from 'app/views/dashboard/profile/StyleComponents';
import {
  ModalStyledBox,
  ModalStyledSubBox,
  StyledButton,
  StyledTextField,
  Title,
} from 'app/common/Typography';
import { DocIcon, DownloadDragIcon, LinksAddIcon, PdfIcon } from 'app/common/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { calculateTomorrow, convertDayJSDateToUTC, formatDecimal } from 'app/utils/helpers';
import { documentTypes, projectTypes } from 'app/utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAllUsers } from '../Members/hooks/useAllUsers';
import { useProjectMembers } from '../Members/hooks/useProjectMembers';
import { AddLinkButton } from '../../listings/Modals/StyledComponents';
import { useServices } from 'app/hooks/useServices';
import { useProjectRoles } from '../Roles/hooks/useProjectRoles';
import MembersAddEdit from '../Members/MembersAddEdit';

const StyledTypography = styled(Typography)({
  fontSize: '18px',
  fontWeight: '500',
});

const StepButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px',
});
const FileContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: 'var(--fileContainer-bg)',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '40vh',
  overflowY: 'auto',
});

const NewProjectDialog = ({ closeModal, projectId, previousPType }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const { data: members } = useProjectMembers(projectId);

  const { data: allUsers, isPending: areUsersLoading } = useAllUsers(null);
  const { data: services } = useServices();
  const { data: roles } = useProjectRoles(projectId, services);
  const { mutate: projectMoveToNew } = useProjectUpdateStage(
    projectId,
    projectTypes.NEW_PROJECT,
    previousPType,
  );
  const calculateTotalInvestment = (landCost, softCost) => {
    return parseFloat(landCost) + parseFloat(softCost);
  };

  const calculateTotalShares = (totalInvestment, sharePrice) => {
    return totalInvestment / sharePrice;
  };

  const selectedUsersMap = useMemo(() => {
    const selectedUsers = new Map();

    members.forEach((member) => {
      selectedUsers.set(member.email, member);
    });

    return selectedUsers;
  }, [members]);

  const usersMap = useMemo(() => {
    const users = new Map(allUsers?.map((user) => [user.email, user]));

    members.forEach((member) => {
      users.delete(member.email);
    });

    return users;
  }, [allUsers, members]);

  const handleSubmit = (values, { setSubmitting }) => {
    const {
      landCost,
      softCost,
      totalInvestment,
      eachSharePrice,
      totalShare,
      effectiveDate,
      feasibilityEndDate,
      estimatedClosingDate,
      earnestMoney,
      optionsMoney,
      documents,
    } = values;

    const availableShares = totalShare;

    const contract = {
      effectiveDate: convertDayJSDateToUTC(effectiveDate),
      estimatedClosingDate: convertDayJSDateToUTC(estimatedClosingDate),
      feasibilityEndDate: convertDayJSDateToUTC(feasibilityEndDate),
      earnestMoney,
      optionsMoney,
      extended: false,
    };

    const investments = {
      landCost,
      softCost,
      totalInvestment,
      eachSharePrice,
      totalShare,
      availableShares,
    };

    projectMoveToNew(
      { documents, contract, investments },
      {
        onSuccess: () => {
          closeModal();
          notify.success(
            <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
              Project successfully moved to
              <Grid>{`the New Project`}</Grid>
            </Typography>,
          );
          navigate(`/myprojects/${projectId}`);
        },
        onSettled: () => setSubmitting(false),
      },
    );
  };

  const handleNextStep = async (values, validateForm, setTouched) => {
    const documents = values?.documents || [];
  
    // ✅ Safely check if there's at least one document with file and docName
    const hasValidDocument = documents.some(
      (doc) => doc.file && doc.docName && doc.docName.trim() !== ''
    );
  
    if (!hasValidDocument) {
      notify.error('At least one valid document with a name is required');
      return;
    }
  
    // Proceed with full form validation
    const formErrors = await validateForm();
  
    if (Object.keys(formErrors).length === 0) {
      setCurrentStep(2);
    } else {
      setTouched(
        Object.keys(formErrors).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    }
  };
  

  const handlePrevStep = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  return (
    <>
      <Modal open={true} onClose={closeModal}>
        <ModalStyledBox>
          <ModalStyledSubBox>
            <Title>Listing to New Project</Title>
            <IconButton onClick={closeModal}>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: 'var(--closeIcon)',
                }}
              />
            </IconButton>
          </ModalStyledSubBox>
          <Divider />
          <StyledBox3>
            <Formik
              initialValues={{
                landCost: '',
                softCost: '',
                totalInvestment: '',
                eachSharePrice: '',
                totalShare: '',
                effectiveDate: null,
                estimatedClosingDate: null,
                feasibilityEndDate: null,
                earnestMoney: '',
                optionsMoney: '',
                documents: [],
              }}
              validationSchema={
                currentStep === 1
                  ? validationSchemas.newProjectStep1()
                  : validationSchemas.newProjectStep2()
              }
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
                validateForm,
                setTouched,
              }) => {
                const handleFileInput = (event) => {
                  event.preventDefault();
                  console.log(event);

                  const filesAccess = {
                    change: event.target.files,
                    drop: event.dataTransfer?.files,
                  };

                  const newFiles = Array.from(filesAccess[event.type]).map((file) => ({
                    file,
                    docName: '',
                    type: documentTypes.CONTRACT
                  }));

                  setFieldValue('documents', [...values.documents, ...newFiles]);
                };

                const handleDocumentNameChange = (event, index) => {
                  const updatedDocuments = [...values.documents];
                  updatedDocuments[index] = {
                    ...updatedDocuments[index],
                    docName: event.target.value,
                    type: documentTypes.CONTRACT
                  };

                  setFieldValue('documents', updatedDocuments);
                };

                const handleDeleteFile = (index) => {
                  const updatedDocuments = [...values.documents];
                  updatedDocuments.splice(index, 1);

                  setFieldValue('documents', updatedDocuments);
                };

                const handleDragOver = (event) => {
                  event.preventDefault();
                };
                const handleDateChange = (name, value) => {
                  handleChange({ target: { name, value } });
                };

                const handlePriceChange = (e) => {
                  const { name, value } = e.target;
                  setFieldValue(name, formatDecimal(value, 2));
                };

                function selectUserInMap(email, value) {
                  selectedUsersMap.set(email, value);
                  usersMap.delete(email);
                }

                function removeUserFromMap(email) {
                  const deletedMember = selectedUsersMap.get(email);
                  if (deletedMember) {
                    usersMap.set(email, deletedMember);
                    selectedUsersMap.delete(email);
                  }
                }

                function handleUserSelection(value) {
                  if (value) {
                    selectUserInMap(value.email, value);

                    setFieldValue(`members.name`, `${value.name} ${value.surName}`);
                    setFieldValue(`members.phone`, value.contactPhone);
                    setFieldValue(`members.email`, value.email);
                  }
                }

                function handleUserRemoval() {
                  const member = values.members;
                  removeUserFromMap(member.email);

                  setFieldValue(`members.phone`, '');
                  setFieldValue(`members.email`, '');
                  setFieldValue(`members.userRoles`, []);
                }

                function filterUserOptions(options, state) {
                  const inputValue = state.inputValue.trim().toLowerCase();

                  const addMemberOption = options.find((option) => option.id === 'add-member');
                  const userOptions = options.filter((option) => option.id !== 'add-member');

                  if (inputValue.length < 3) return addMemberOption ? [addMemberOption] : [];

                  const inputParts = inputValue.split(/\s+/);

                  const filteredUsers = userOptions.filter((option) => {
                    const fullName = `${option.name} ${option.surName || ''}`.toLowerCase();
                    return inputParts.every((part) => fullName.includes(part));
                  });

                  return addMemberOption ? [...filteredUsers, addMemberOption] : filteredUsers;
                }

                return (
                  <Form>
                    <Box sx={{ p: 2, overflowY: 'auto' }}>
                      {currentStep === 1 && (
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <label>Land Cost</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                type='number'
                                name='landCost'
                                value={values.landCost}
                                onChange={(e) => {
                                  handleChange(e);
                                  const totalInvestment = calculateTotalInvestment(
                                    e.target.value,
                                    values.softCost,
                                  );
                                  const totalShares = calculateTotalShares(
                                    totalInvestment,
                                    values.eachSharePrice,
                                  );
                                  setFieldValue('landCost', e.target.value);
                                  setFieldValue('totalInvestment', totalInvestment);
                                  setFieldValue('totalShare', totalShares);
                                }}
                                onBlur={handleBlur}
                                error={Boolean(touched.landCost && errors.landCost)}
                                helperText={touched.landCost && errors.landCost}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Soft Cost</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                type='number'
                                name='softCost'
                                value={values.softCost}
                                onChange={(e) => {
                                  handleChange(e);
                                  const totalInvestment = calculateTotalInvestment(
                                    values.landCost,
                                    e.target.value,
                                  );
                                  const totalShares = calculateTotalShares(
                                    totalInvestment,
                                    values.eachSharePrice,
                                  );
                                  setFieldValue('softCost', e.target.value);
                                  setFieldValue('totalInvestment', totalInvestment);
                                  setFieldValue('totalShare', totalShares);
                                }}
                                onBlur={handleBlur}
                                error={Boolean(touched.softCost && errors.softCost)}
                                helperText={touched.softCost && errors.softCost}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <label>Total Investment</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                type='number'
                                name='totalInvestment'
                                value={values.totalInvestment}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.totalInvestment && errors.totalInvestment)}
                                helperText={touched.totalInvestment && errors.totalInvestment}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Divider />
                            <Grid item xs={6}>
                              <label>Each Share Value</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                type='number'
                                name='eachSharePrice'
                                value={values.eachSharePrice}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  handleChange(e);
                                  const parsedSharePrice = parseFloat(value);

                                  const totalInvestment = calculateTotalInvestment(
                                    values.landCost,
                                    values.softCost,
                                  );
                                  const totalShares = calculateTotalShares(
                                    totalInvestment,
                                    parsedSharePrice,
                                  );

                                  // Update the corresponding fields
                                  setFieldValue('eachSharePrice', value);
                                  setFieldValue('totalInvestment', totalInvestment);
                                  setFieldValue('totalShare', totalShares);
                                }}
                                onBlur={handleBlur}
                                error={Boolean(touched.eachSharePrice && errors.eachSharePrice)}
                                helperText={touched.eachSharePrice && errors.eachSharePrice}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Total Shares</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                // label="Total Share"
                                type='number'
                                name='totalShare'
                                value={values.totalShare}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.totalShare && errors.totalShare)}
                                helperText={touched.totalShare && errors.totalShare}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Earnest Money</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                // label="Earnest Money"
                                name='earnestMoney'
                                value={values.earnestMoney}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.earnestMoney && errors.earnestMoney)}
                                helperText={touched.earnestMoney && errors.earnestMoney}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Options Money</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                // label="Options Money"
                                name='optionsMoney'
                                value={values.optionsMoney}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.optionsMoney && errors.optionsMoney)}
                                helperText={touched.optionsMoney && errors.optionsMoney}
                              />
                            </Grid>
                            {/* </Grid>
                        <Grid container spacing={2}> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid item xs={6}>
                                <label>Effective Date</label>
                                <DatePicker
                                  // disablePast
                                  name='effectiveDate'
                                  value={values.effectiveDate}
                                  // shouldDisableDate={(date) => date.isBefore(dayjs(), 'day')}
                                  minDate={dayjs()}
                                  onChange={(value) => handleDateChange('effectiveDate', value)}
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      onBlur: handleBlur,
                                      error: Boolean(touched.effectiveDate && errors.effectiveDate),
                                      helperText: touched.effectiveDate && errors.effectiveDate,
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <label>Feasibility Due Date</label>
                                <DatePicker
                                  disablePast
                                  name='feasibilityEndDate'
                                  minDate={calculateTomorrow(values.effectiveDate)}
                                  value={values.feasibilityEndDate}
                                  onChange={(value) =>
                                    handleDateChange('feasibilityEndDate', value)
                                  }
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      onBlur: handleBlur,
                                      helperText:
                                        touched.feasibilityEndDate && errors.feasibilityEndDate,
                                      error: Boolean(
                                        errors.feasibilityEndDate && touched.feasibilityEndDate,
                                      ),
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <label>Closing Date</label>
                                <DatePicker
                                  disablePast
                                  name='estimatedClosingDate'
                                  minDate={calculateTomorrow(values.feasibilityEndDate)}
                                  value={values.estimatedClosingDate}
                                  onChange={(value) =>
                                    handleDateChange('estimatedClosingDate', value)
                                  }
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      onBlur: handleBlur,
                                      helperText:
                                        touched.estimatedClosingDate && errors.estimatedClosingDate,
                                      error: Boolean(
                                        errors.estimatedClosingDate && touched.estimatedClosingDate,
                                      ),
                                    },
                                  }}
                                />
                              </Grid>
                            </LocalizationProvider>

                            <Grid container spacing={2} mb={0} mt={0.5} ml={1}>
                              <Grid item xs={12}>
                                <StyledTypography>Contract Documents</StyledTypography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                onDragOver={handleDragOver}
                                onDrop={handleFileInput}
                              >
                                <FormControl
                                  error={touched.documents && Boolean(errors.documents)}
                                  fullWidth
                                >
                                  <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    border='2px dashed lightgray'
                                    borderRadius='4px'
                                    p={2}
                                  >
                                    <Typography variant='subtitle1' color='text.secondary'>
                                      <DownloadDragIcon />
                                      {` Drag and Drop file here or `}&nbsp;
                                    </Typography>
                                    <label htmlFor='outlined-button-file'>
                                      <StyledButton
                                        variant='outlined'
                                        component='span'
                                        sx={{
                                          textTransform: 'none',
                                          textDecoration: 'underline',
                                          color: '#282A2D',
                                        }}
                                      >
                                        Click to upload
                                      </StyledButton>
                                    </label>
                                    <Input
                                      id='outlined-button-file'
                                      key={values.documents.length}
                                      sx={{ display: 'none' }}
                                      type='file'
                                      size='small'
                                      name='file'
                                      onChange={handleFileInput}
                                      inputProps={{ accept: '.pdf, .doc, .docx', multiple: true }}
                                      error={Boolean(
                                        getIn(errors, `documents[0].file`) &&
                                          getIn(touched, `documents[0].file`),
                                      )}
                                      helperText={
                                        getIn(errors, `documents[0].file`) &&
                                        getIn(touched, `documents[0].file`)
                                          ? 'Document is required'
                                          : ''
                                      }
                                    />
                                  </Box>
                                  <FileContainerDetails>
                                    {values.documents.map((documentData, index) => (
                                      <FileContainer key={index}>
                                        <Box
                                          display='flex'
                                          justifyContent='space-between'
                                          alignItems='center'
                                          p={1}
                                        >
                                          <Box display='flex' alignItems='center'>
                                            {documentData.file.type === 'application/pdf' ? (
                                              <PdfIcon />
                                            ) : (
                                              <DocIcon />
                                            )}
                                            <Box>
                                              {documentData.file && (
                                                <>
                                                  <Typography
                                                    variant='body1'
                                                    fontWeight='bold'
                                                    ml={1}
                                                  >
                                                    {documentData.file.name}
                                                  </Typography>
                                                  <Typography variant='caption' ml={1}>
                                                    {(documentData.file.size / 1024).toFixed(2)} KB
                                                  </Typography>
                                                </>
                                              )}
                                            </Box>
                                          </Box>
                                          <IconButton onClick={() => handleDeleteFile(index)}>
                                            <DeleteIcon style={{ color: 'red' }} />
                                          </IconButton>
                                        </Box>
                                        <StyledTextField
                                          fullWidth
                                          size='small'
                                          variant='standard'
                                          placeholder='Document Name'
                                          name={`documents.docName`}
                                          value={documentData.docName}
                                          onChange={(e) => handleDocumentNameChange(e, index)}
                                          onBlur={handleBlur}
                                          error={Boolean(
                                            getIn(errors, `documents[${index}].docName`) &&
                                              getIn(touched, `documents[${index}].docName`),
                                          )}
                                          helperText={
                                            getIn(errors, `documents[${index}].docName`) &&
                                            getIn(touched, `documents[${index}].docName`)
                                              ? 'Document Name is required'
                                              : ''
                                          }
                                        />
                                      </FileContainer>
                                    ))}
                                    {touched?.documents && errors?.documents && (
                                      <FormHelperText>
                                        {!Array.isArray(errors.documents) && errors.documents}
                                      </FormHelperText>
                                    )}
                                  </FileContainerDetails>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {currentStep === 2 && (
                        <Grid container spacing={2}>
                          <Grid style={{ marginTop: '1px' }} container spacing={2} p={2}>
                            <Grid item xs={6}>
                              <label>Seller LLC Name</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                name='sellerLlcName'
                                value={values.sellerLlcName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.sellerLlcName && Boolean(errors.sellerLlcName)}
                                helperText={touched.sellerLlcName && errors.sellerLlcName}
                                disabled={isSubmitting}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Contact Person</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                name='contactPerson'
                                value={values.contactPerson}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.contactPerson && Boolean(errors.contactPerson)}
                                helperText={touched.contactPerson && errors.contactPerson}
                                disabled={isSubmitting}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Email Address</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                name='emailId'
                                value={values.emailId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.emailId && Boolean(errors.emailId)}
                                helperText={touched.emailId && errors.emailId}
                                disabled={isSubmitting}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <label>Phone Number</label>
                              <StyledTextField
                                fullWidth
                                size='small'
                                variant='outlined'
                                name='phoneNo'
                                value={values.phoneNo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.phoneNo && Boolean(errors.phoneNo)}
                                helperText={touched.phoneNo && errors.phoneNo}
                                disabled={isSubmitting}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                              <label>Seller Agent Name</label>
                              <Autocomplete
                                freeSolo
                                // options={Array.from(usersMap.values())}
                                options={[
                                  ...Array.from(usersMap.values()),
                                  { name: 'Add Member', id: 'add-member' },
                                ]}
                                getOptionLabel={(user) =>
                                  user.id === 'add-member'
                                    ? user.name
                                    : `${user.name} ${user.surName || ''}`
                                }
                                filterOptions={filterUserOptions}
                                renderOption={(props, user) => (
                                  <Box
                                    component='li'
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    {...props}
                                    key={user.email || user.id}
                                  >
                                    {user.id === 'add-member' ? (
                                      <AddLinkButton startIcon={<LinksAddIcon />} onClick={toggleDialog}>
                                        Add Member
                                      </AddLinkButton>
                                    ) : (
                                      <>
                                        <Avatar
                                          sx={{
                                            bgcolor: 'var(--avatar-bgColor)',
                                            width: 30,
                                            height: 30,
                                            marginRight: 2,
                                            fontSize: 12,
                                            fontWeight: 700,
                                          }}
                                        >
                                          {`${user?.name?.[0]?.toUpperCase()}${user?.surName?.[0]?.toUpperCase()}`}
                                        </Avatar>
                                        <Box>
                                          <Typography variant='subtitle2' noWrap>
                                            {`${user?.name} ${user?.surName}`}
                                          </Typography>
                                          <Typography variant='body2' color='text.secondary' noWrap>
                                            {user?.email}
                                          </Typography>
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                )}
                                onChange={(_, value) => {
                                  handleUserSelection(value);
                                }}
                                onInputChange={(_, value) => {
                                  setFieldValue(`members.name`, value);
                                  if (!value) {
                                    handleUserRemoval();
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name={`members.name`}
                                    onBlur={handleBlur}
                                    error={touched.members?.name && Boolean(errors.members?.name)}
                                    helperText={touched.members?.name && errors.members?.name}
                                    fullWidth
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                              <label>Buyer Agent Name</label>
                              <Autocomplete
                                freeSolo
                                options={[
                                  ...Array.from(usersMap.values()),
                                  { name: 'Add Member', id: 'add-member' },
                                ]}
                                getOptionLabel={(user) =>
                                  user.id === 'add-member'
                                    ? user.name
                                    : `${user.name} ${user.surName || ''}`
                                }
                                filterOptions={filterUserOptions}
                                renderOption={(props, user) => (
                                  <Box
                                    component='li'
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    {...props}
                                    key={user.email || user.id}
                                  >
                                    {user.id === 'add-member' ? (
                                      <AddLinkButton startIcon={<LinksAddIcon />} onClick={toggleDialog}>
                                        Add Member
                                      </AddLinkButton>
                                    ) : (
                                      <>
                                        <Avatar
                                          sx={{
                                            bgcolor: 'var(--avatar-bgColor)',
                                            width: 30,
                                            height: 30,
                                            marginRight: 2,
                                            fontSize: 12,
                                            fontWeight: 700,
                                          }}
                                        >
                                          {`${user?.name?.[0]?.toUpperCase()}${user?.surName?.[0]?.toUpperCase()}`}
                                        </Avatar>
                                        <Box>
                                          <Typography variant='subtitle2' noWrap>
                                            {`${user?.name} ${user?.surName}`}
                                          </Typography>
                                          <Typography variant='body2' color='text.secondary' noWrap>
                                            {user?.email}
                                          </Typography>
                                        </Box>
                                      </>
                                    )}
                                  </Box>
                                )}
                                onChange={(_, value) => {
                                  handleUserSelection(value);
                                }}
                                onInputChange={(_, value) => {
                                  setFieldValue(`members.name`, value);
                                  if (!value) {
                                    handleUserRemoval();
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name={`members.name`}
                                    onBlur={handleBlur}
                                    error={touched.members?.name && Boolean(errors.members?.name)}
                                    helperText={touched.members?.name && errors.members?.name}
                                    fullWidth
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <StepButtonContainer>
                        <Button
                          variant='outlined'
                          color='secondary'
                          onClick={handlePrevStep}
                          disabled={currentStep === 1}
                          sx={{ textTransform: 'none' }}
                        >
                          Previous
                        </Button>
                        <Box>
                          {currentStep === 1 ? (
                            <Button
                            variant='contained'
                            color='primary'
                            onClick={() => handleNextStep(values, validateForm, setTouched)}
                            sx={{ textTransform: 'none' }}
                          >
                            Next
                          </Button>
                          
                          ) : (
                            <LoadingButton
                              variant='contained'
                              color='primary'
                              type='submit'
                              loading={isSubmitting}
                              sx={{ textTransform: 'none' }}
                            >
                              Submit
                            </LoadingButton>
                          )}
                        </Box>
                      </StepButtonContainer>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </StyledBox3>
        </ModalStyledBox>
      </Modal>
      {isOpenDialog && (
        <MembersAddEdit
          selectedMember={null}
          roles={roles}
          projectMembers={members}
          closeModal={toggleDialog}
          title={'Member'}
        />
      )}
    </>
  );
};

export default NewProjectDialog;
