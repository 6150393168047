// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestsLayout_container__quNxb {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.RequestsLayout_card__IhtkN {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
}
.RequestsLayout_content__3LvQt {
  flex-grow: 1;
  overflow-y: auto;
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Layout/Requests/RequestsLayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  overflow: hidden;\r\n}\r\n\r\n.card {\r\n  display: flex;\r\n  height: 100%;\r\n  flex-direction: column;\r\n  flex-grow: 1;\r\n}\r\n.content {\r\n  flex-grow: 1;\r\n  overflow-y: auto;\r\n  max-width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RequestsLayout_container__quNxb`,
	"card": `RequestsLayout_card__IhtkN`,
	"content": `RequestsLayout_content__3LvQt`
};
export default ___CSS_LOADER_EXPORT___;
