const LayoutSettings = {
  leftSidebar: {
    show: true,
    mode: 'full',
    theme: 'slateDark1',
    status: 'open',
  },
  topbar: {
    show: true,
    fixed: true,
    theme: 'whiteBlue',
  },
};

export default LayoutSettings;
