import React, { useState } from 'react';
import { Box, Card, Divider, Typography } from '@mui/material';
import AddEditBuilding from './AddEditBuilding';
import { StyledIcon, Title } from 'app/common/Typography';
import BuildingView from './BuildingView';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectBuldings } from './hooks/useProjectBuldings';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';

const LeaseSaleSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [view, setView] = useState('list');
  const [selectedBuildingIndex, setSelectedBuildingIndex] = useState(null);
  const { projectId } = useProject();
  const { data: buildings } = useProjectBuldings(projectId);

  const handleViewUnits = (index) => {
    setSelectedBuildingIndex(index);
    setView('details');
  };

  const handleBackToBuildings = () => {
    setView('list');
    setSelectedBuildingIndex(null);
  };

  const toggleModal = () => setShowModal((prevState) => !prevState);

  return (
    <>
    
    {view === 'list' ? (
    <Card
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        border: '1px solid #DADCE0'
      }}
    >
        <>
          <Box
            sx={{ margin: '20px 38px 20px 20px' }}
            display='flex'
            justifyContent='space-between'
          >
            <StledCardTitle>Buildings</StledCardTitle>
            <StyledAddButton startIcon={<StyledIcon />} onClick={() => setShowModal(true)}>
              Buildings
            </StyledAddButton>
          </Box>
          <Divider />
          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {!buildings ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Buildings Available
                </Typography>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '20px',
                  maxWidth: '100%', // Limits the width of the row
                }}
              >
                {buildings &&
                  buildings.map((building, index) => (
                    <Card
                      key={building.id}
                      style={{
                        border: '1px solid #E8EAED',
                        borderRadius: '8px',
                        padding: '20px',
                        width: '300px',
                        backgroundColor: '#F8F9FA',
                        flex: '1 1 calc(33.33% - 20px)', // Ensures three cards per row with spacing
                        boxShadow: 'none',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleViewUnits(index)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px',
                        }}
                      >
                        <Title
                          style={{
                            margin: 0,
                            fontSize: '18px',
                            color: 'var(--title-BuildingName)',
                            fontWeight: 700
                          }}
                        >
                          {building.buildingName}
                        </Title>
                        <img
                          src='/assets/images/units.png'
                          alt='Building'
                          style={{ width: '50px', height: '50px' }}
                        />
                      </div>
                      <Typography
                        variant='subtitle1'
                        style={{
                          margin: '0px',
                          color: 'var(--typography-created-text)',
                          fontSize: '12px',
                        }}
                      >
                        Created on {convertUTCDateToLocal(new Date(...building.createdDate))}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        style={{
                          marginTop: '20px',
                          color: 'var(--typography-Noof-units-text)',
                          fontWeight: '500',
                          fontSize: '14px',
                        }}
                      >
                        {building?.units?.length} units Available
                      </Typography>
                    </Card>
                  ))}
              </div>
            )}
          </Box>
        </>

    </Card>
    
  ) : (
    <BuildingView
      selectedBuilding={buildings?.at(selectedBuildingIndex)}
      handleBackToBuildings={handleBackToBuildings}
    />
  )}
    <AddEditBuilding open={showModal} onClose={toggleModal} />
    </>
  );
};

export default LeaseSaleSection;
