import { useMemo, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { Formik, Form, FieldArray } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { Loading } from 'app/components';
import { useProject } from 'app/contexts/ProjectContext';
import { useAllUsers } from './hooks/useAllUsers';
import { useProjectMembersAdd } from './hooks/useProjectMembersAdd';
import { useProjectMemberUpdateRole } from './hooks/useProjectMemberUpdateRole';
import RoleAddEdit from '../Roles/RoleAddEdit';
import { Button } from '@mui/material';

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--dialogDivider)',
});

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 500,
}));

const MembersAddEdit = ({ selectedMember, roles, projectMembers, closeModal, title }) => {
  const { projectId } = useProject();
  const { data: allUsers, isPending: areUsersLoading } = useAllUsers(selectedMember);
  const { mutate: addMembers } = useProjectMembersAdd(projectId);
  const { mutate: updateMemberRole } = useProjectMemberUpdateRole(projectId, selectedMember);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const isEditing = !!selectedMember;

  const initialValues = isEditing
    ? {
        members: [
          {
            name: selectedMember?.memberName,
            phone: selectedMember?.phone,
            email: selectedMember?.email,
            userRoles: selectedMember?.userRoles.map((role) => ({ name: role })),
          },
        ],
      }
    : {
        members: [{ name: '', phone: '', email: '', userRoles: [] }],
      };

  const usersMap = useMemo(() => {
    const users = new Map(allUsers?.map((user) => [user.email, user]));

    projectMembers.forEach((member) => {
      users.delete(member.email);
    });

    return users;
  }, [allUsers, projectMembers]);

  const selectedUsersMap = useMemo(() => {
    const selectedUsers = new Map();

    projectMembers.forEach((member) => {
      selectedUsers.set(member.email, member);
    });

    return selectedUsers;
  }, [projectMembers]);

  const isUserExists = (member) => {
    const user = selectedUsersMap.get(member.email);

    if (user) {
      const existingFullName = `${user.name} ${user.surName}`.toLowerCase().trim();
      const inputFullName = member.name.toLowerCase().trim();
      const existingPhone = user.contactPhone;
      const inputPhone = member.phone;

      return (
        existingFullName === inputFullName &&
        existingPhone === inputPhone &&
        user.email === member.email
      );
    }
    return false;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const membersData = values.members.map((member) => ({
      ...member,
      userRoles: member.userRoles.map((role) => role.name),
    }));

    const mutationOptions = {
      onSuccess: closeModal,
      onSettled: () => setSubmitting(false),
    };

    !isEditing
      ? addMembers(membersData, mutationOptions)
      : updateMemberRole(membersData[0], mutationOptions);
  };

  const isPending = areUsersLoading;
  const isGeneralPartner = title === 'General Partner';
  return (
    <>
      <Dialog
        open={true}
        onClose={closeModal}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '95vw', sm: '80vw', md: '65vw', lg: '55vw' },
            },
          },
        }}
      >
        {isPending ? (
          <Loading />
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <DialogTitle>{isEditing ? `Edit ${title}` : `Add ${title}`}</DialogTitle>
              <IconButton onClick={closeModal} sx={{ marginRight: 2 }}>
                <Close />
              </IconButton>
            </Box>
            <DialogDivider />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas.members(projectMembers, isEditing)}
              validateOnMount={true}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
              }) => {
                function selectUserInMap(email, value) {
                  selectedUsersMap.set(email, value);
                  usersMap.delete(email);
                }

                function removeUserFromMap(email) {
                  const deletedMember = selectedUsersMap.get(email);
                  if (deletedMember) {
                    usersMap.set(email, deletedMember);
                    selectedUsersMap.delete(email);
                  }
                }

                function handleUserSelection(value, index) {
                  if (value) {
                    selectUserInMap(value.email, value);

                    setFieldValue(`members[${index}].name`, `${value.name} ${value.surName}`);
                    setFieldValue(`members[${index}].phone`, value.contactPhone);
                    setFieldValue(`members[${index}].email`, value.email);
                  }
                }

                function handleUserRemoval(index) {
                  const member = values.members[index];
                  removeUserFromMap(member.email);

                  setFieldValue(`members[${index}].phone`, '');
                  setFieldValue(`members[${index}].email`, '');
                  setFieldValue(`members[${index}].userRoles`, []);
                }

                function filterUserOptions(options, state) {
                  const inputValue = state.inputValue.trim().toLowerCase();
                  if (inputValue.length < 3) return [];
                  const inputParts = inputValue.split(/\s+/);
                  return options.filter((option) => {
                    const fullName = `${option.name} ${option.surName}`.toLowerCase();
                    return inputParts.every((part) => fullName.includes(part));
                  });
                }

                if (isGeneralPartner && values.members[0].userRoles.length === 0) {
                  setFieldValue('members[0].userRoles', [{ name: 'OWNER_ROLE' }]);
                }
                return (
                  <Form>
                    <DialogContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FieldArray name='members'>
                            {({ push, remove }) => (
                              <>
                                {values.members.map((member, index) => (
                                  <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={6}>
                                      <InputLabel
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: 14,
                                          mb: 1,
                                          color: 'var(--inputLabel-color)',
                                        }}
                                      >
                                        {title} Name
                                      </InputLabel>
                                      <Autocomplete
                                        freeSolo
                                        readOnly={isEditing}
                                        options={Array.from(usersMap.values())}
                                        getOptionLabel={(user) => `${user.name} ${user.surName}`}
                                        noOptionsText={member.name?.length < 3 && ''}
                                        inputValue={member.name}
                                        filterOptions={filterUserOptions}
                                        renderOption={(props, user) => (
                                          <Box
                                            component='li'
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            {...props}
                                            key={user.email}
                                          >
                                            <Avatar
                                              sx={{
                                                bgcolor: 'var(--avatar-bgColor)',
                                                width: 30,
                                                height: 30,
                                                marginRight: 2,
                                                fontSize: 12,
                                                fontWeight: 700,
                                              }}
                                            >
                                              {`${user?.name?.[0]?.toUpperCase()}${user?.surName?.[0]?.toUpperCase()}`}
                                            </Avatar>
                                            <Box>
                                              <Typography variant='subtitle2' noWrap>
                                                {`${user?.name} ${user?.surName}`}
                                              </Typography>
                                              <Typography
                                                variant='body2'
                                                color='text.secondary'
                                                noWrap
                                              >
                                                {user?.email}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        )}
                                        onChange={(_, value) => handleUserSelection(value, index)}
                                        onInputChange={(_, value) => {
                                          setFieldValue(`members[${index}].name`, value);
                                          if (!value) {
                                            handleUserRemoval(index);
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name={`members[${index}].name`}
                                            placeholder='e.g. John Doe'
                                            onBlur={handleBlur}
                                            error={
                                              touched.members?.[index]?.name &&
                                              Boolean(errors.members?.[index]?.name)
                                            }
                                            helperText={
                                              touched.members?.[index]?.name &&
                                              errors.members?.[index]?.name
                                            }
                                            fullWidth
                                            InputProps={{
                                              ...params.InputProps,
                                              readOnly: isEditing,
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <InputLabel
                                          sx={{
                                            fontWeight: 700,
                                            fontSize: 14,
                                            color: 'var(--inputLabel-color)',
                                            mb: 1,
                                          }}
                                        >
                                          Select Roles
                                        </InputLabel>
                                        {values.members.length > 1 && (
                                          <IconButton
                                            onClick={() => {
                                              handleUserRemoval(index);
                                              remove(index);
                                            }}
                                            size='small'
                                            sx={{ padding: 0 }}
                                            aria-label='removeMember'
                                          >
                                            <Close style={{ color: 'var(--close-icon)' }} />
                                          </IconButton>
                                        )}
                                      </Box>
                                      <Autocomplete
                                        multiple
                                        options={roles}
                                        filterSelectedOptions={true}
                                        getOptionLabel={(role) => role.name}
                                        isOptionEqualToValue={(option, value) =>
                                          option.name === value.name
                                        }
                                        onChange={(_, newValue) => {
                                          setFieldValue(`members[${index}].userRoles`, newValue);
                                        }}
                                        value={member.userRoles}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            size='medium'
                                            variant='outlined'
                                            name={`members[${index}].userRoles`}
                                            onBlur={handleBlur}
                                            error={
                                              touched.members?.[index]?.userRoles &&
                                              Boolean(errors.members?.[index]?.userRoles)
                                            }
                                            helperText={
                                              touched.members?.[index]?.userRoles &&
                                              errors.members?.[index]?.userRoles
                                            }
                                            fullWidth
                                          />
                                        )}
                                        renderTags={(tagValue, getTagProps) =>
                                          tagValue.map((option, index) => {
                                            const { key, ...rest } = getTagProps({ index });
                                            return <Chip key={key} label={option.name} {...rest} />;
                                          })
                                        }
                                        noOptionsText={
                                          <StyledButton type='button' onClick={toggleDialog}>
                                            + Add Role
                                          </StyledButton>
                                        }
                                        disabled={isGeneralPartner}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <InputLabel
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: 14,
                                          mb: 1,
                                          color: 'var(--inputLabel-color)',
                                        }}
                                      >
                                        Contact Number
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        name={`members[${index}].phone`}
                                        value={member.phone}
                                        placeholder='e.g. +08012345678'
                                        onChange={(e) => handleChange(e)}
                                        onBlur={handleBlur}
                                        error={
                                          touched.members?.[index]?.phone &&
                                          Boolean(errors.members?.[index]?.phone)
                                        }
                                        helperText={
                                          touched.members?.[index]?.phone &&
                                          errors.members?.[index]?.phone
                                        }
                                        inputProps={{
                                          readOnly: isEditing || isUserExists(member),
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <InputLabel
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: 14,
                                          mb: 1,
                                          color: 'var(--inputLabel-color)',
                                        }}
                                      >
                                        Email Address
                                      </InputLabel>
                                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <TextField
                                          fullWidth
                                          name={`members[${index}].email`}
                                          value={member.email}
                                          onChange={(e) => handleChange(e)}
                                          error={
                                            touched.members?.[index]?.email &&
                                            Boolean(errors.members?.[index]?.email)
                                          }
                                          placeholder='e.g. johndoe@gmail.com'
                                          onBlur={handleBlur}
                                          helperText={
                                            touched.members?.[index]?.email &&
                                            errors.members?.[index]?.email
                                          }
                                          inputProps={{
                                            readOnly: isEditing || isUserExists(member),
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                ))}
                                {!isEditing && (
                                  <Grid item xs={12}>
                                    <LoadingButton
                                      color='primary'
                                      variant='text'
                                      sx={{ textTransform: 'none' }}
                                      disabled={isSubmitting || Boolean(errors?.members?.length)}
                                      onClick={() =>
                                        push({ name: '', phone: '', email: '', userRoles: [] })
                                      }
                                    >
                                      + Add {title}
                                    </LoadingButton>
                                  </Grid>
                                )}
                              </>
                            )}
                          </FieldArray>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              mt: 2,
                              flexDirection: { xs: 'column', sm: 'row' },
                              gap: 2,
                            }}
                          >
                            <LoadingButton
                              color='error'
                              size='large'
                              className='cancelButton'
                              sx={{ textTransform: 'none' }}
                              disabled={isSubmitting}
                              onClick={closeModal}
                            >
                              Cancel
                            </LoadingButton>
                            <LoadingButton
                              color='primary'
                              size='large'
                              sx={{ textTransform: 'none' }}
                              variant='contained'
                              type='submit'
                              loading={isSubmitting}
                            >
                              Save
                            </LoadingButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </Dialog>
      {isOpenDialog && <RoleAddEdit projectId={projectId} closeModal={toggleDialog} />}
    </>
  );
};

export default MembersAddEdit;
