import { useProject } from 'app/contexts/ProjectContext';
import { requestStatusMessages, requestsTypes } from 'app/utils/constant';
import RequestsList from 'app/views/dashboard/Requests/RequestsList';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { useProjectRequests } from './hooks/useProjectRequests';
import { Card, Container, Divider, Grid, styled } from '@mui/material';
import { Title } from 'app/common/Typography';
import { StledCardTitle } from '../../listings/StyledComponent';

const currentType = 'PENDING';

function RequestsSection() {
  const { projectId } = useProject();
  const { data: requests } = useProjectRequests(projectId);

  const filteredAndMappedRequests = useMemo(
    () =>
      requests
        .filter(
          (request) =>
            request.status === currentType &&
            (request.invitationType === requestsTypes.INVESTMENT_MAIL ||
              request.invitationType === requestsTypes.PAYMENT_REQUEST_MAIL),
        )
        .map((request) => ({
          ...request,
          projectTitle: 'this project',
        })),
    [requests],
  );

  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <Box
            sx={{ margin: '20px 38px 20px 20px' }}
            display='flex'
            justifyContent='space-between'
          >
            <StledCardTitle>Requests</StledCardTitle>
          </Box>
          <Divider />
        {/* <Box sx={{ overflowY: 'auto' }}>
          {filteredAndMappedRequests.length > 0 ? (
            <RequestsList requests={filteredAndMappedRequests} />
          ) : (
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No Requests
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  {requestStatusMessages[currentType].message}
                </Typography>
              </Box>
            </Container>
          )}
        </Box> */}
        <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {filteredAndMappedRequests.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Requests Available
                </Typography>
              </div>
            ) : (
              <RequestsList requests={filteredAndMappedRequests} />
            )}
          </Box>
      </Card>
    </>
  );
}

export default RequestsSection;
