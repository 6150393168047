import AuthGuard from 'app/auth/AuthGuard';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import sessionsRoutes from 'app/views/sessions/SessionsRoutes';
import { Layout } from './components';
import NotFound from './NotFound';

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [...dashboardRoutes],
  },
  ...sessionsRoutes,
  { path: '*', element: <NotFound /> },
];

export default routes;
