import React, { useState } from 'react';
import { Button, IconButton, Typography, Modal, Box } from '@mui/material';
import { H1, Paragraph } from 'app/common/Typography';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useProject } from 'app/contexts/ProjectContext';
import { dueDiligence } from 'app/utils/constant';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid lightgrey',
  paddingBottom: '10px',
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
  gap: '10px',
};

const ImagesModal = ({ open, handleClose, projectId }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state for save button
  const { uploadDocuments } = useProject();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setImages([...images, ...files]);
  };

  const handleDeleteFile = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setImages([...images, ...files]);
  };

  const handleCancel = () => {
    setImages([]);
    handleClose();
  };

  const handleSaveImages = async () => {
    if (images.length === 0) {
      alert('Please add at least one image before saving.');
      return;
    }

    setLoading(true); // Set loading to true when save is clicked
    const data = new FormData();
    data.append('projectId', projectId);

    let imageIndex = 0;

    images.forEach((image) => {
      data.append(`docReq[${imageIndex}].file`, image);
      data.append(`docReq[${imageIndex}].docName`, image.name ? image.name : '');
      data.append(`docReq[${imageIndex}].type`, dueDiligence);
      imageIndex++;
    });

    try {
      await uploadDocuments(data);
      handleClose();
      setImages([]);
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setLoading(false); // Reset loading state after the API call
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      aria-labelledby='image-upload-modal'
      aria-describedby='image-upload-description'
    >
      <Box sx={style}>
        <div style={headerStyle}>
          <H1>Images</H1>
          <IconButton onClick={handleCancel}>
            <Icon icon='mdi:close' style={{ color: 'grey' }} />
          </IconButton>
        </div>

        <div className='lead'>
          <div className='navigation-header'>
            <Paragraph>You need to upload images with .jpeg format</Paragraph>
            <div className='Container' onDragOver={handleDragOver} onDrop={handleDrop}>
              <img src='/assets/images/uploadImages.png' alt='' />
              <Typography variant='body1'>
                Drag and Drop the files you want to upload from your computer
              </Typography>
              <label htmlFor='outlined-button-file'>
                <Button className='StyledButton' variant='outlined' component='span'>
                  Browse files
                </Button>
              </label>
              <input
                accept='image/*'
                className='input'
                id='outlined-button-file'
                multiple
                type='file'
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
            <div
              style={{ border: '1px solid lightgrey', marginTop: '20px', marginBottom: '20px' }}
            ></div>

            {images.map((file, index) => (
              <div className='ImageContainer' key={index}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Selected File ${index + 1}`}
                  style={{ height: '100%', borderRadius: '4px', width: '70px' }}
                />
                <div className='FileDetails'>
                  <Typography variant='body1' style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                    {file.name}
                  </Typography>
                  <Typography variant='caption' style={{ marginLeft: '10px' }}>
                    {(file.size / 1024).toFixed(2)} KB
                  </Typography>
                </div>
                <IconButton onClick={() => handleDeleteFile(index)}>
                  <Icon icon='material-symbols-light:delete-rounded' style={{ color: 'red' }} />
                </IconButton>
              </div>
            ))}
          </div>
        </div>

        <div style={footerStyle}>
          <LoadingButton variant='outlined' onClick={handleCancel} color='error'>
            Cancel
          </LoadingButton>
          <LoadingButton
            variant='contained'
            color='primary'
            onClick={handleSaveImages}
            loading={loading} // Show loading indicator during save
            disabled={images.length === 0 || loading} // Disable button if no images or loading
          >
            Save
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default ImagesModal;
