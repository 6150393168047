import { Card, Grid } from '@mui/material';
import { Box, styled } from '@mui/system';
import { Carousel } from 'react-responsive-carousel'; // Import the Carousel component
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { useLocation } from "react-router-dom";
const NumberContainer = styled('div')({
  backgroundColor: 'lightgrey',
  padding: '3px 6px',
  borderRadius: '4px',
  fontSize: '0.7rem',
  marginLeft: '10px',
});

const CardTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '1.5rem',
  fontWeight: '500',
  textTransform: 'capitalize',
}));

const KeyValueWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '8px',
  margin: '10px',
}));

const CardRoot = styled(Card)(({ theme }) => ({
  border: '1px solid #ded4d4',
  borderRadius: '5px',
  margin: '10px',
}));

const KeyValueRow = styled('div')({
  display: 'flex',
  marginBottom: '8px',
});

const KeyValueItem = styled('div')({
  flex: '1',
  marginRight: '8px',
  paddingLeft: 2,
});

const Key = styled('div')({
  fontWeight: '500',
  color: 'grey',
});

const Value = styled('div')({
  fontWeight: '500',
});

const ImageBox = styled(Box)({
  width: '100%',
  height: '200px',
  overflow: 'hidden',
  objectFit: 'contain',
  '& img': {
    width: '100%',
    height: '100%',
  },
  padding: '5px',
});

const Separator = styled('span')({
  display: 'inline-block',
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  backgroundColor: 'grey',
  margin: '0 10px',
});

const ProjectCard = ({ title, keyValuePairs, additionalNumbers, images, reason }) => {
  const location = useLocation();
  const isCancelled = location.pathname.endsWith('/cancelled'); // Ensures it only matches the exact '/cancelled' path
  const valuesWithSeparators = [];
  keyValuePairs.forEach((pair, index) => {
    valuesWithSeparators.push(<Value key={index}>{pair.value}</Value>);
    if (index < keyValuePairs.length - 1) {
      valuesWithSeparators.push(<Separator key={`separator-${index}`} />);
    }
  });
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CardRoot className='cardroot' elevation={6}>
          <ImageBox onClick={(e) => e.stopPropagation()}>
            <Carousel
              showThumbs={false}
              showArrows={true}
              showStatus={false}
              showIndicators={false}
              className='carousel-bg'
            >
              {images &&
                images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={`data:${image.documentType};base64,${image.content}`}
                      alt={image.metadata.documentName}
                    />
                  </div>
                ))}
            </Carousel>
          </ImageBox>
          <KeyValueWrapper className='value-card'>
            <CardTitle className='subtitle-card'>
              <div>{title}</div>
              {additionalNumbers && (
                <NumberContainer className='number-card'>{additionalNumbers}</NumberContainer>
              )}
            </CardTitle>
            <KeyValueRow>
              <KeyValueItem>
                <div style={{ display: 'inline-flex', alignItems: 'center', color: 'gray' }}>
                  {valuesWithSeparators}
                </div>
              </KeyValueItem>
            </KeyValueRow>
          </KeyValueWrapper>

          {reason && (
            <div
              style={{
                borderTop: '1px solid #f0f0f0',
                padding: '10px',
                backgroundColor: '#F8EAED',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: '#ffffff',
                  border: '1px solid #F16588',
                  borderRadius: '20%',
                  padding: '5px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '10px',
                }}
              >
                <WarningAmberRoundedIcon style={{ color: '#F16588' }} />
              </div>
              <span style={{ color: '#F16588' }}>
                {isCancelled
                  ? `This project is cancelled ${reason}`
                  : `This project is on hold ${reason}`}
              </span>
            </div>
          )}
        </CardRoot>
      </Grid>
    </Grid>
  );
};

export { KeyValueWrapper, Key, Value, ProjectCard };
