import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, styled, Grid, Modal } from '@mui/material';
import { Form, Formik } from 'formik';
import {
  ModalCancel,
  ModalSave,
  ModalStyledBox,
  ModalStyledSubBox,
  StyledBoxFooter,
  StyledLabel,
  StyledTextField,
  Title,
} from 'app/common/Typography';
import { useProjectGCManagerAddUpdate } from './hooks/useProjectGCManagerAddUpdate';
import { managersTitles } from 'app/utils/constant';
import { validationSchemas } from 'app/utils/validationSchemas';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '5%',
  paddingRight: '5%',
  marginBottom: '4%',
  borderRadius: '4px',
  marginTop: '0px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});


const GCManagerAddEdit = ({ contractor, manager, managerKey, closeDialog }) => {
  const { mutate: addUpdateGCManager } = useProjectGCManagerAddUpdate(
    contractor,
    manager,
    managerKey,
  );

  const isEditingMode = !!manager;

  const initialValues = {
    contactPerson: manager?.contactPerson || '',
    emailId: manager?.emailId || '',
    phoneNo: manager?.phoneNo || '',
  };

  const handleFormSubmit = (values, { setSubmitting }) =>
    addUpdateGCManager(values, {
      onSuccess: closeDialog,
      onSettled: () => setSubmitting(false),
    });

  const title = managersTitles[managerKey];

  return (
    <Modal open={true} onClose={closeDialog}>
      <ModalStyledBox>
        <ModalStyledSubBox>
          <Title>
            {isEditingMode ? 'Edit' : 'Add'} {title}
          </Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />

        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
            overflowY: 'auto',
            maxHeight: 'calc(100% - 75px)',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.gcManager()}
            onSubmit={handleFormSubmit}
          >
            {({ values, isSubmitting, touched, errors, handleChange, handleBlur }) => (
              <Form>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={12}>
                      <StyledLabel>Contact Person</StyledLabel>

                      <StyledTextField
                        fullWidth
                        size='small'
                        variant='outlined'
                        placeholder='Contact Person'
                        name='contactPerson'
                        value={values.contactPerson}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.contactPerson && Boolean(errors.contactPerson)}
                        helperText={touched.contactPerson && errors.contactPerson}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledLabel>Email Address</StyledLabel>

                      <StyledTextField
                        fullWidth
                        size='small'
                        variant='outlined'
                        placeholder='Email Address'
                        name='emailId'
                        value={values.emailId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.emailId && Boolean(errors.emailId)}
                        helperText={touched.emailId && errors.emailId}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={12}>
                      <StyledLabel>Phone Number</StyledLabel>

                      <StyledTextField
                        fullWidth
                        size='small'
                        variant='outlined'
                        placeholder='Phone Number'
                        name='phoneNo'
                        value={values.phoneNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.phoneNo && Boolean(errors.phoneNo)}
                        helperText={touched.phoneNo && errors.phoneNo}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                <StyledBoxFooter>
                  <ModalCancel
                    className='cancelButton'
                    variant='outlined'
                    onClick={closeDialog}
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                    sx={{ textTransform: 'none' }}
                  >
                    Cancel
                  </ModalCancel>
                  <ModalSave
                    variant='contained'
                    color='primary'
                    style={{ boxShadow: 'none', marginRight: '10px' }}
                    type='submit'
                    loading={isSubmitting}
                    sx={{ textTransform: 'none' }}
                  >
                    {isEditingMode ? 'Update' : 'Save'}
                  </ModalSave>
                </StyledBoxFooter>
              </Form>
            )}
          </Formik>
        </Box>
      </ModalStyledBox>
    </Modal>
  );
};

export default GCManagerAddEdit;
