import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Button from '@mui/material/Button';
import { Title } from 'app/common/Typography';
import InvestmentsDiagram from './InvestmentsDiagram';
import EditInvestments from './EditInvestments';
import { useState } from 'react';
import { formatCurrency } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';
import UserInvestments from './UserInvestments';
import useUserAuth from 'app/hooks/userUserAuth';
import { investorRoles } from 'app/utils/constant';

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: '#4F90F0',
});

const SectionDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const InvestmentsSection = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { user } = useUserAuth();
  const { isOnHoldOrCancelled, investments, members } = useProject();

  const currentUserMember = members.find((member) => member.email === user.email);
  const isCurrentMemberInvestor = currentUserMember.userRoles.some((role) =>
    investorRoles.includes(role),
  );

  const { totalInvestment, landCost, softCost, investedAmount, remainingAmount } = investments;

  const toggleModal = () => setIsOpenModal(!isOpenModal);

  const infoPairs = [
    { key: 'LAND COST', value: formatCurrency(landCost) },
    { key: 'SOFT COST', value: formatCurrency(softCost) },
    { key: 'TOTAL INVESTMENT', value: formatCurrency(totalInvestment) },
    { key: 'INVESTED AMOUNT', value: formatCurrency(investedAmount) },
    { key: 'REMAINING AMOUNT', value: formatCurrency(remainingAmount) },
  ];

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <Box display='flex' justifyContent='space-between' alignItems='center' p={2} pb={0}>
          <Title sx={{ m: 0 }}>Investment Details:</Title>
          <Button
            style={{ color: '#4F90F0', fontSize: '14px' }}
            onClick={toggleModal}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} />
            Edit
          </Button>
        </Box>
        <Box display='flex' flexDirection='column' p={2} pt={0} gap={2}>
          <Grid container columnSpacing={3} rowSpacing={2} my={0} justifyContent='flex-start'>
            {infoPairs.map(({ key, value }, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <span className='key'>{key}</span>
                <span className='value'>{value ?? ''}</span>
              </Grid>
            ))}
          </Grid>
          <SectionDivider />
          <Card
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              bgcolor: 'rgba(242, 243, 245, 1)',
              borderRadius: 1,
            }}
          >
            <InvestmentsDiagram />
            {isCurrentMemberInvestor && <UserInvestments member={currentUserMember} />}
          </Card>
        </Box>
      </div>
      {isOpenModal && <EditInvestments toggleModal={toggleModal} />}
    </>
  );
};

export default InvestmentsSection;
