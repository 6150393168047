import React, { useState, useRef } from 'react';
import { Box, Typography, IconButton, Divider, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Downloadicon, ReplaceIcon, ViewIcon, LCDeleteIcon } from 'app/common/icons';
import AddImagesModal from './Modals/AddImagesModal';
import { EditIcon } from 'app/common/icons';
import ImagesViewModal from './Modals/ImagesViewModal';
import EditAddressModal from './Modals/EditAddressModal';
import { useParams } from 'react-router-dom';
import { getAddressString } from 'app/utils/helpers';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  MapSecTitle, StyledEditIcon,
  MapFooterSec, ViewAllLink,
  StyledAddButton, StyledIcon, MapContainer, StyledOverImageIcons,
  AddressContainer, ControlsContainer, FooterContainer,
  FooterRightSec, FlyerText, StyledReplaceIcon, RightFlexIcons,
} from './StyledComponent';
import { DEFAULT_CENTER } from 'app/utils/constant';
import { useProjectLocation } from './hooks/useProjectLocation'
import { notify } from 'app/services/notify';
import ModalDeleteImages from './Modals/DeleteImages';
import DocumentModal from './Modals/DocumentModal';

const IconDivider = styled(Box)(({ theme }) => ({
  height: '2.5rem',
  alignSelf: 'center',
  border: '1px solid #DADCE0'
}))

export const VerticalDivider = styled(Divider)(({ theme }) => ({
  border: '1px solid #DADCE0',
}))
const StyledCarousel = styled(Carousel)`
  .control-arrow {
    background-color: white !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    position: absolute;
    z-index: 10;
    font-size: 16px !important;
    font-weight: bold;
    color: black !important;
  }

  .control-prev, .control-next {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .control-prev:before {
    content: "<" !important; /* Left arrow symbol */
    transform: scaleY(1.5); /* Stretch the left arrow vertically */
    border-right: 0 !important;
  }

  .control-next:before {
    content: ">" !important; /* Right arrow symbol */
    transform: scaleY(1.5); /* Stretch the right arrow vertically */
    border-left: 0 !important;
  }

  .control-prev {
    left: 10px !important; /* Position the left arrow */
  }

  .control-next {
    right: 10px !important; /* Position the right arrow */
  }
`;

const MapViewSection = () => {
  const [view, setView] = useState('gallery');
  const [openModal, setOpenModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [deleteImageModal, setDeteleImageModal] = useState(false);
  const [editAddModal, setEditAddModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { projectId } = useParams();
  const { data: locationT } = useProjectLocation(projectId);


  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [currentPosition, setCurrentPosition] = useState(DEFAULT_CENTER);
  const [openDocModal, setOpenDocModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenImageModal = () => setOpenImageModal(true);
  const handleCloseImageModal = () => setOpenImageModal(false);

  const handleOpenDeleteImageModal = () => setDeteleImageModal(true);
  const handleCloseDeleteImageModal = () => setDeteleImageModal(false);

  const handleOpenEditAddModal = () => setEditAddModal(true);
  const handleCloseEditAddModal = () => setEditAddModal(false);

  const handleCloseDocModal = () => {
    setOpenDocModal(false);
  }
  const handleOpenDocModal = () => {
      setOpenDocModal(true);
  }
  const geocodeAddress = async (address) => {
    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
          const location = results[0].geometry.location;
          resolve({
            lat: location.lat(),
            lng: location.lng(),
          });
        } else {
          reject(new Error('Geocode failed: ' + status));
        }
      });
    });
  };

  const updateMapCenter = async () => {
    if (!locationT) return;
    const fullAddress = `${locationT?.address1} ${locationT?.address2} ${locationT?.city} ${locationT?.state} ${locationT?.zipCode} ${locationT?.country}`;
    try {
      const newPosition = await geocodeAddress(fullAddress);
      setCurrentPosition(newPosition);
      if (mapRef.current) {
        mapRef.current.setCenter(newPosition);
      }
      if (markerRef.current) {
        markerRef.current.setPosition(newPosition);
      }
    } catch (error) {
      notify.error('Error in geocoding address:', error);
      setCurrentPosition(DEFAULT_CENTER);
    }
  };

  const handleMapLoad = async (map) => {
    mapRef.current = map;
    updateMapCenter();
  };

  const handleMarkerLoad = (marker) => {
    markerRef.current = marker;
  };

  React.useEffect(() => {
    updateMapCenter();
  }, [locationT]);

  const getHeading = () => {
    switch (view) {
      case 'gallery':
        return 'Gallery View';
      case 'map':
        return 'Map View';
      default:
        return 'View Section';
    }
  };

  const renderViewContent = () => {
    switch (view) {
      case 'gallery':
        return (
          <StyledCarousel
            showArrows={true} // Show side navigation arrows
            infiniteLoop={true} // Infinite loop of images
            showThumbs={false} // Disable thumbnails under images
            dynamicHeight={true} // Dynamic height based on image size
            autoPlay={true} // Auto-play the StyledCarousel
            interval={3000} // Interval between auto-play slides (3 seconds)
          >
            {/* <div>
              <img
                src="https://img.freepik.com/premium-photo/land-plot-building-house-aerial-view-land-field-with-pins-pin-location-housing-subdivision-residential-development-owned-sale-rent-buy-investment-home-house-expand-city-suburb_73523-7855.jpg?w=2000"
                alt="Gallery Image 1"
                style={{ objectFit: "cover" }}
              />
            </div> */}
            <div>
              <img
                src="/assets/images/construction_1.jpg"
                alt="Construction Site Image 1"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div>
              <img
                src="/assets/images/construction_4.jpg"
                alt="Construction Site Image 4"
                style={{ objectFit: "cover" }}
              />
            </div>
          </StyledCarousel>
        );
      case 'map':
        return (
          <GoogleMap
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '23rem' }}
            center={currentPosition}
            onLoad={handleMapLoad}
          >
            <Marker position={currentPosition} onLoad={handleMarkerLoad} />
          </GoogleMap>
        );
      default:
        return null;
    }
  };

  const rightFooterRightSection = () => {
      return (
        // <>
        //   <FlyerText>
        //     Flyer.pdf
        //   </FlyerText>


        //   <StyledReplaceIcon
        //     variant="outlined"
        //     startIcon={<ReplaceIcon />}
        //   >
        //     Replace
        //   </StyledReplaceIcon></>
        <>
          <FlyerText>
            No Flyer Added
          </FlyerText>


          <StyledAddButton
            startIcon={
              <StyledIcon />
            }
            onClick={handleOpenDocModal}
          >
            Flyer / Broucher
          </StyledAddButton>
          <Modal open={openDocModal} onClose={handleCloseDocModal}>
              <DocumentModal handleCloseModal={handleCloseDocModal} isFlyer={true} />
          </Modal>
          </>
      );
  };


  const renderFooterContent = () => {
    if (view === 'gallery') {
      return (
        <>
          <MapFooterSec>
            <ViewAllLink
              onClick={handleOpenImageModal}
            >
              View All
            </ViewAllLink>

            <Modal open={openImageModal} onClose={handleCloseImageModal}>
              <ImagesViewModal handleCloseImageModal={handleCloseImageModal} />
            </Modal>
            <StyledAddButton
              startIcon={
                <StyledIcon />
              }
              onClick={handleOpenModal}
            >
              Images
            </StyledAddButton>
          </MapFooterSec>
          <Modal open={openModal} onClose={handleCloseModal}>
            <AddImagesModal handleCloseModal={handleCloseModal} />
          </Modal>
        </>
      );
    } else if (view === 'map') {
      return (
        <>
          <StyledEditIcon
            variant="outlined"
            size="small"
            startIcon={<EditIcon />}
            onClick={handleOpenEditAddModal}
          >
            Edit Address
          </StyledEditIcon>
          <Modal open={editAddModal} onClose={handleCloseEditAddModal}>
            <EditAddressModal handleCloseEditAddModal={handleCloseEditAddModal} location={locationT} projectId={projectId} />
          </Modal>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <MapSecTitle>
        {getHeading()}
      </MapSecTitle>

      <MapContainer>
        {renderViewContent()}

        <AddressContainer>
          {/* {getAddressString(locationT)} */}
        </AddressContainer>
        <ControlsContainer>
          <StyledOverImageIcons
            size="small"
            onClick={() => setView('gallery')}
          >
            <img src='/assets/images/gallery_img.jpg' alt='' style={{ width: '60px', height: '60px', borderRadius: '5px' }} />
          </StyledOverImageIcons>

          <StyledOverImageIcons
            size="small"
            onClick={() => setView('map')}

          >
            <img src='/assets/images/map_img.jpg' alt='' style={{ width: '60px', height: '60px', borderRadius: '5px' }} />
          </StyledOverImageIcons>
        </ControlsContainer>
      </MapContainer>

      <FooterContainer>
        {renderFooterContent()}
        <VerticalDivider orientation="vertical" flexItem />
        <FooterRightSec>

          {rightFooterRightSection()}

          {/* <RightFlexIcons>
            <IconButton
              sx={{
                color: "#1976d2",
                padding: '8px',
              }}
            >
              <Downloadicon />
            </IconButton>

            <IconDivider
              orientation="vertical"
              flexItem
            />

            <IconButton
              sx={{
                color: "#000000",
                padding: '8px',
              }}
            >
              <ViewIcon />
            </IconButton>
            <IconDivider
              orientation="vertical"
              flexItem
            />

            <IconButton sx={{ color: "#d32f2f", padding: '8px' }}>
              <LCDeleteIcon />
            </IconButton>
          </RightFlexIcons> */}
        </FooterRightSec>
      </FooterContainer>
    </>
  );
};

export default MapViewSection;

