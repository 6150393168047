// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MemberView_dialogContainer__R0km9 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.MemberView_headerContainer__LNHDU {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MemberView_subtitleContainer__qW-JC {
  position: relative;
}

.MemberView_backgroundImage__O8lsy {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.MemberView_subtitleContent__DtNDQ {
  position: relative;
  padding: 1rem 1.5rem;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MemberView_subtitleContent__DtNDQ h1 {
  color: rgba(107, 94, 170, 0.1);
  font-weight: 600;
}

.MemberView_contentContainer__ujB9I {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.MemberView_contentBox__ppKrj {
  border: 1px solid rgba(181, 237, 245, 1);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.MemberView_text__TgFMS {
    font-weight: 500;
  overflow-wrap: anywhere;
}

@media (max-width: 600px) {
  .MemberView_subtitleContent__DtNDQ {
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    & h1 {
      font-size: 4rem;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/views/dashboard/leads/LeadsData/Members/modules/MemberView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;AACA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,SAAS;EACT,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,wCAAwC;EACxC,mBAAmB;EACnB,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;IACI,gBAAgB;EAClB,uBAAuB;AACzB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;IACT;MACE,eAAe;IACjB;EACF;AACF","sourcesContent":[".dialogContainer {\r\n  display: flex;\r\n  width: 100%;\r\n  flex-direction: column;\r\n}\r\n\r\n.headerContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.subtitleContainer {\r\n  position: relative;\r\n}\r\n\r\n.backgroundImage {\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover;\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n}\r\n.subtitleContent {\r\n  position: relative;\r\n  padding: 1rem 1.5rem;\r\n  gap: 1rem;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n.subtitleContent h1 {\r\n  color: rgba(107, 94, 170, 0.1);\r\n  font-weight: 600;\r\n}\r\n\r\n.contentContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 1rem;\r\n}\r\n\r\n.contentBox {\r\n  border: 1px solid rgba(181, 237, 245, 1);\r\n  border-radius: 10px;\r\n  padding: 1rem 1.5rem;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 0.5rem;\r\n}\r\n\r\n.text {\r\n    font-weight: 500;\r\n  overflow-wrap: anywhere;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n  .subtitleContent {\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    gap: 1rem;\r\n    & h1 {\r\n      font-size: 4rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": `MemberView_dialogContainer__R0km9`,
	"headerContainer": `MemberView_headerContainer__LNHDU`,
	"subtitleContainer": `MemberView_subtitleContainer__qW-JC`,
	"backgroundImage": `MemberView_backgroundImage__O8lsy`,
	"subtitleContent": `MemberView_subtitleContent__DtNDQ`,
	"contentContainer": `MemberView_contentContainer__ujB9I`,
	"contentBox": `MemberView_contentBox__ppKrj`,
	"text": `MemberView_text__TgFMS`
};
export default ___CSS_LOADER_EXPORT___;
