import React from 'react';
import { ButtonBase, Icon, Popover } from '@mui/material';
import { Span } from '../../common/Typography';
import { Box, styled } from '@mui/system';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavExpandRoot = styled('div')(({ theme }) => ({
  '& .expandIcon': {
    transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
    transform: 'rotate(90deg)',
  },
  '& .collapseIcon': {
    transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
    transform: 'rotate(0deg)',
  },
  '& .expansion-panel': {
    overflow: 'hidden',
    transition: 'max-height 0.3s cubic-bezier(0, 0, 0.2, 1)',
  },
  '& .highlight': {
    background: theme.palette.primary.main,
  },
  '&.compactNavItem': {
    width: 44,
    overflow: 'hidden',
    justifyContent: 'center !important',
    '& .itemText': { display: 'none' },
    '& .itemIcon': { display: 'none' },
  },
}));

const BaseButton = styled(ButtonBase)(({ theme }) => ({
  height: 44,
  width: '100%',
  whiteSpace: 'pre',
  overflow: 'hidden',
  paddingRight: '16px',
  borderRadius: '4px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'space-between !important',
  color: theme.palette.text.primary,
  '&:hover': { background: 'rgba(255, 255, 255, 0.08)' },
  '& .icon': {
    width: 36,
    fontSize: '18px',
    paddingLeft: '16px',
    paddingRight: '16px',
    verticalAlign: 'middle',
    color: 'black',
  },
}));

const BulletIcon = styled('div')(({ theme }) => ({
  width: 4,
  height: 4,
  color: 'inherit',
  overflow: 'hidden',
  marginLeft: '20px',
  marginRight: '8px',
  borderRadius: '300px !important',
  background: theme.palette.text.primary,
}));

const ItemText = styled('span')(() => ({
  fontSize: '0.875rem',
  paddingLeft: '0.8rem',
  verticalAlign: 'middle',
}));

const BadgeValue = styled('div')(() => ({
  padding: '1px 4px',
  overflow: 'hidden',
  borderRadius: '300px',
}));

const StyledText = styled(Span)(({ mode }) => ({
  fontSize: '0.875rem',
  paddingLeft: '0.8rem',
  display: mode === 'compact' && 'none',
}));

const VerticalNavExpansionPanel = ({ item, children, mode }) => {
  const projectsCounter = useSelector((state) => state.projectsCounter);
  const [collapsed, setCollapsed] = useState(true);
  const elementRef = useRef(null);
  const componentHeight = useRef(0);
  const { pathname } = useLocation();
  const { name, icon, iconText, badge } = item;

  useEffect(() => {
    setCollapsed(mode === 'compact');
  }, [mode]);

  const handleClick = (event) => {
    if (mode === 'compact') {
      setAnchorEl(event.currentTarget);
      return;
    }
    componentHeight.current = 0;
    calcaulateHeight(elementRef.current);
    setCollapsed(!collapsed);
  };

  const calcaulateHeight = useCallback((node) => {
    if (node.name !== 'child') {
      for (let child of node.children) {
        calcaulateHeight(child);
      }
    }

    if (node.name === 'child') componentHeight.current += node.scrollHeight;
    else componentHeight.current += 44;
    return;
  }, []);

  useEffect(() => {
    if (!elementRef) return;

    calcaulateHeight(elementRef.current);
    for (let child of elementRef.current.children) {
      if (child.getAttribute('href') === pathname) {
        setCollapsed(false);
      }
    }
  }, [pathname, calcaulateHeight]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    if (mode === 'compact') {
      setAnchorEl(event.currentTarget);
      return;
    }
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <NavExpandRoot>
      <BaseButton
        className={clsx({
          'has-submenu compactNavItem': true,
          compactNavItem: mode === 'compact',
          open: !collapsed,
        })}
        onClick={handleClick}
        onMouseEnter={handlePopoverOpen}
      >
        <Box display='flex' alignItems='center'>
          {icon && <Icon className='icon'>{icon}</Icon>}
          {iconText && <BulletIcon />}
          <ItemText className='sidenavHoverShow'>{name}</ItemText>
        </Box>

        {badge && <BadgeValue className='sidenavHoverShow itemIcon'>{badge.value}</BadgeValue>}

        <div
          className={clsx({
            sidenavHoverShow: true,
            collapseIcon: collapsed,
            expandIcon: !collapsed,
          })}
        >
          <Icon fontSize='small' sx={{ verticalAlign: 'middle' }}>
            chevron_right
          </Icon>
        </div>
      </BaseButton>

      <div
        ref={elementRef}
        className='expansion-panel submenu'
        style={collapsed ? { maxHeight: '0px' } : { maxHeight: componentHeight.current + 'px' }}
      >
        {children}
        <Popover
          id={1}
          open={open}
          anchorEl={anchorEl}
          onMouseLeave={handlePopoverClose}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'right',
            horizontal: 'right',
          }}
        >
          <Box sx={{ p: '10px', background: '#1976d2' }}>
            {item.children.map((i, index) => {
              return (
                <NavLink to={i.path} key={index} onClick={handleClose}>
                  <Box
                    sx={
                      i.path == pathname
                        ? {
                            p: '.5rem',
                            backgroundColor: 'rgba(204, 204, 204, 0.3)',
                            borderRadius: '5px',
                          }
                        : { p: '.5rem' }
                    }
                  >
                    {i.name}({projectsCounter[i.APIFieldName]})
                  </Box>
                </NavLink>
              );
            })}
          </Box>
        </Popover>
      </div>
    </NavExpandRoot>
  );
};

export default VerticalNavExpansionPanel;
