import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  IconButton,
  styled,
  Modal,
  Typography,
  Button,
  Input,
  TextField,
  Grid,
} from '@mui/material';
import { CustomInputText, customNumberInput, Title } from 'app/common/Typography';
import { DocIcon, DownloadDragIcon, PdfIcon } from 'app/common/icons';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import LeadsSuccess from './LeadsSuccess';

import { delay, handleAxiosError } from 'app/utils/helpers';
import dayjs from 'dayjs';
import { useProject } from 'app/contexts/ProjectContext';
import notify from 'app/utils/notify';
import { closingDocuments } from 'app/utils/constant';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '4px',
  marginTop: '10px',
  border: '2px dashed lightgray',
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 0,
    backgroundColor: 'transparent',
  },
}));

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: '#F8F8F8',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '40vh',
  overflowY: 'auto',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
  flexGrow: 1,
});

const LeadsOngoing = ({ ongoingStatus, handleOngoing, selectedOption }) => {
  const { projectMoveToOngoing } = useProject();
  const [newDocument, setNewDocuments] = useState({
    documents: [],
  });
  const [purchasePrice, setPurchasePrice] = useState('');
  const [closingCost, setClosingCost] = useState('');
  const [realtorRebate, setRealtorRebate] = useState('');
  const [closingDate, setClosingDate] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactPersonNumber, setContactPersonNumber] = useState('');
  const [contactPersonEmail, setContactPersonEmail] = useState('');
  const [lenderName, setLenderName] = useState('');
  const [lenderContactNumber, setLenderContactNumber] = useState('');
  const [lenderEmail, setLenderEmail] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [leaseTerm, setLeaseTerm] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add state for loading

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.map((file) => ({ file, docname: '' }));
    setNewDocuments((prevState) => ({
      documents: [...prevState.documents, ...newFiles],
    }));
  };

  const handleDocumentNameChange = (event, index) => {
    const { value } = event.target;
    const updatedDocuments = [...newDocument.documents];
    updatedDocuments[index].docname = value;
    setNewDocuments({
      ...newDocument,
      documents: updatedDocuments,
    });
    if (errors[`docname_${index}`]) {
      const updatedErrors = { ...errors };
      delete updatedErrors[`docname_${index}`];
      setErrors(updatedErrors);
    }
  };

  const handleDeleteFile = (index) => {
    const updatedDocuments = [...newDocument.documents];
    updatedDocuments.splice(index, 1);
    setNewDocuments({
      ...newDocument,
      documents: updatedDocuments,
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const newFiles = files.map((file) => ({ file, docname: '' }));
    setNewDocuments((prevState) => ({
      documents: [...prevState.documents, ...newFiles],
    }));
  };

  const handleChange = (setter, field) => (event) => {
    setter(event.target.value);
    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[+]?[0-9]{10,14}$/;

    if (!purchasePrice || parseFloat(purchasePrice) <= 0)
      newErrors.purchasePrice = 'Purchase price is required and should be greater than 0';
    if (!closingCost || parseFloat(closingCost) <= 0)
      newErrors.closingCost = 'Closing cost is required and should be greater than 0';
    if (!realtorRebate || parseFloat(realtorRebate) <= 0)
      newErrors.realtorRebate = 'Realtor rebate is required and should be greater than 0';
    if (!closingDate) newErrors.closingDate = 'Closing date is required';
    if (!companyName) newErrors.companyName = 'Company name is required';
    if (!contactPerson) newErrors.contactPerson = 'Contact person is required';
    if (!contactPersonNumber || !phonePattern.test(contactPersonNumber))
      newErrors.contactPersonNumber =
        'Contact person number is required and should be a valid phone number';
    if (!contactPersonEmail || !emailPattern.test(contactPersonEmail))
      newErrors.contactPersonEmail = 'Contact person email is required and should be a valid email';
    if (!lenderName && selectedOption === 'loan') newErrors.lenderName = 'Lender name is required';
    if (!lenderContactNumber && selectedOption === 'loan')
      newErrors.lenderContactNumber =
        'Lender contact number is required and should be a valid phone number';
    if (!lenderEmail && selectedOption === 'loan')
      newErrors.lenderEmail = 'Lender email is required and should be a valid email';
    if ((!downPayment || parseFloat(downPayment) <= 0) && selectedOption === 'loan')
      newErrors.downPayment = 'Down payment is required and should be greater than 0';
    if ((!loanAmount || parseFloat(loanAmount) <= 0) && selectedOption === 'loan')
      newErrors.loanAmount = 'Loan amount is required and should be greater than 0';
    if ((!interestRate || parseFloat(interestRate) <= 0) && selectedOption === 'loan')
      newErrors.interestRate = 'Interest rate is required and should be greater than 0';
    if ((!leaseTerm || parseFloat(leaseTerm) <= 0) && selectedOption === 'loan')
      newErrors.leaseTerm = 'Lease term is required and should be greater than 0';

    newDocument.documents.forEach((doc, index) => {
      if (!doc.docname) {
        newErrors[`docname_${index}`] = 'Document name is required';
      }
    });

    if (Object.keys(newErrors).length === 0) {
      setLoading(false);
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (newDocument.documents.length === 0) {
      notify.error('Please add at least one document.');
      return;
    }
    if (!validateForm()) {
      return;
    }
    const formatDateToLocalISOString = (date) => {
      return date
        ? dayjs(date).startOf('day').add(dayjs(date).utcOffset(), 'minute').toISOString()
        : null;
    };

    const closingContractDetails = {
      purchasePrice: parseFloat(purchasePrice),
      closingCost: parseFloat(closingCost),
      realtorRebate: realtorRebate,
      closingDate: closingDate ? formatDateToLocalISOString(closingDate) : null,
      companyDetails: {
        companyName: companyName,
        contactPerson: contactPerson,
        contactPersonNumber: contactPersonNumber,
        contactPersonEmail: contactPersonEmail,
      },
      lenderDetails: {
        lenderName: lenderName,
        lenderContactNumber: lenderContactNumber,
        lenderEmail: lenderEmail,
        downPayment: parseFloat(downPayment),
        loanAmount: parseFloat(loanAmount),
        interestRate: parseFloat(interestRate),
        leaseTerm: parseInt(leaseTerm),
      },
      // closingDocumentIds: newDocument.map((doc, index) => `doc${index}`),
    };

    const documentsData = new FormData();
    let docIndex = 0;

    newDocument.documents.forEach((doc, index) => {
      documentsData.append(`docReq[${docIndex}].file`, doc.file);
      documentsData.append(`docReq[${docIndex}].docName`, doc.docname);
      documentsData.append(`docReq[${docIndex}].type`, closingDocuments);
      docIndex++;
    });

    try {
      setLoading(true);
      await projectMoveToOngoing({ documents: documentsData }, closingContractDetails);
      // setSuccessModalOpen(true);
      notify.success(
        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
          Project successfully moved to
          <Grid>{`the Ongoing Project`}</Grid>
        </Typography>
      );
      // await delay(5000);
      // setSuccessModalOpen(false);
      handleOngoing();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SubtitleDivider />
      <Modal open={ongoingStatus} onClose={handleOngoing}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '40%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Title>New Project</Title>
              <TrendingFlatIcon />
              <Title>Ongoing Project</Title>
            </Box>
            <IconButton onClick={handleOngoing}>
              <CloseIcon
                style={{
                  fontSize: 18,
                  color: '#272937',
                }}
              />
            </IconButton>
          </Box>

          <ModalDivider />
          <Box sx={{ p: 2, overflowY: 'auto', maxHeight: 'calc(100% - 48px)' }}>
            <Grid>
              <Typography style={{ fontSize: '18px', fontWeight: '500', marginBottom: '5px' }}>
                Closing Details
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label='Purchase Price'
                  placeholder='e.g. $2000.00'
                  type='number'
                  value={purchasePrice}
                  onChange={handleChange(setPurchasePrice, 'purchasePrice')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.purchasePrice}
                  helperText={errors.purchasePrice}
                  sx={customNumberInput}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Closing Cost'
                  type='number'
                  placeholder='e.g. $2000.00'
                  value={closingCost}
                  onChange={handleChange(setClosingCost, 'closingCost')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.closingCost}
                  helperText={errors.closingCost}
                  sx={customNumberInput}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            </Grid>

            <Grid style={{ marginTop: '1px' }} container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label='Realtor Rebate'
                  placeholder='e.g. 100'
                  type='number'
                  value={realtorRebate}
                  onChange={handleChange(setRealtorRebate, 'realtorRebate')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.realtorRebate}
                  helperText={errors.realtorRebate}
                  inputProps={{ min: 0 }}
                  sx={customNumberInput}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <DatePicker
                      name='closingDate'
                      label='Closing Date'
                      value={closingDate}
                      onChange={(newValue) => {
                        setClosingDate(newValue);
                        if (errors.closingDate) {
                          setErrors((prevErrors) => ({ ...prevErrors, closingDate: null }));
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          error: !!errors.closingDate,
                          helperText: errors.closingDate,
                        },
                      }}
                      sx={{ width: '100%' }}
                      minDate={dayjs()}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                style={{
                  fontSize: '17px',
                  fontWeight: '500',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                Title Form Details
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label='Title Form'
                  type='text'
                  placeholder='e.g. sample company'
                  value={companyName}
                  onChange={handleChange(setCompanyName, 'companyName')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type='text'
                  label='Contact Person'
                  placeholder='e.g. John Doe'
                  value={contactPerson}
                  onChange={handleChange(setContactPerson, 'contactPerson')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPerson}
                  helperText={errors.contactPerson}
                />
              </Grid>
            </Grid>

            <Grid style={{ marginTop: '1px' }} container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  type='number'
                  label='Contact Person Number'
                  placeholder='e.g. +919182523776'
                  value={contactPersonNumber}
                  onChange={handleChange(setContactPersonNumber, 'contactPersonNumber')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPersonNumber}
                  helperText={errors.contactPersonNumber}
                  sx={customNumberInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type='email'
                  label='Contact Email'
                  placeholder='e.g. johndoe@gmail.com'
                  value={contactPersonEmail}
                  onChange={handleChange(setContactPersonEmail, 'contactPersonEmail')}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.contactPersonEmail}
                  helperText={errors.contactPersonEmail}
                />
              </Grid>
            </Grid>
            {selectedOption === 'loan' && (
              <>
                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    style={{
                      fontSize: '17px',
                      fontWeight: '500',
                      marginTop: '10px',
                      marginBottom: '5px',
                    }}
                  >
                    Lender Details
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Lender Name'
                      type='text'
                      placeholder='e.g. Michelle John'
                      value={lenderName}
                      onChange={handleChange(setLenderName, 'lenderName')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.lenderName}
                      helperText={errors.lenderName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='Contact Number'
                      type='text'
                      placeholder='e.g. 098-765-4321'
                      value={lenderContactNumber}
                      onChange={handleChange(setLenderContactNumber, 'lenderContactNumber')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.lenderContactNumber}
                      helperText={errors.lenderContactNumber}
                      sx={customNumberInput}
                    />
                  </Grid>
                </Grid>

                <Grid style={{ marginTop: '1px' }} container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Email Address'
                      type='email'
                      placeholder='e.g. johndoe@gmail.com'
                      value={lenderEmail}
                      onChange={handleChange(setLenderEmail, 'lenderEmail')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.lenderEmail}
                      helperText={errors.lenderEmail}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type='number'
                      label='Down Payment'
                      placeholder='e.g. $2000'
                      value={downPayment}
                      onChange={handleChange(setDownPayment, 'downPayment')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.downPayment}
                      helperText={errors.downPayment}
                      sx={customNumberInput}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>

                <Grid style={{ marginTop: '1px' }} container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Loan Amount'
                      type='number'
                      placeholder='e.g. $300000'
                      value={loanAmount}
                      onChange={handleChange(setLoanAmount, 'loanAmount')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.loanAmount}
                      helperText={errors.loanAmount}
                      sx={customNumberInput}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type='number'
                      placeholder='e.g. 16%'
                      label='Interest Rate'
                      value={interestRate}
                      onChange={handleChange(setInterestRate, 'interestRate')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.interestRate}
                      helperText={errors.interestRate}
                      sx={customNumberInput}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>

                <Grid style={{ marginTop: '1px' }} container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label='Loan Term'
                      type='number'
                      placeholder='e.g. 25YRS'
                      value={leaseTerm}
                      onChange={handleChange(setLeaseTerm, 'leaseTerm')}
                      fullWidth
                      variant='outlined'
                      size='small'
                      error={!!errors.leaseTerm}
                      helperText={errors.leaseTerm}
                      sx={customNumberInput}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Typography style={{ fontSize: '15px', fontWeight: '450', marginTop: '8px' }}>
              Closing Document
            </Typography>
            <Container onDragOver={handleDragOver} onDrop={handleDrop}>
              <Typography variant='body1' color={'#64748B'} fontSize={'16px'}>
                <DownloadDragIcon />
                {` Drag and Drop file here or `}
                <label htmlFor='outlined-button-file'>
                  <StyledButton variant='outlined' component='span'>
                    Browse files
                  </StyledButton>
                </label>
              </Typography>
              <Input
                id='outlined-button-file'
                sx={{
                  display: 'none',
                }}
                type='file'
                size='small'
                name='file'
                onChange={handleFileChange}
                inputProps={{ accept: '.pdf, .doc, .docx' }}
              />
            </Container>
            <Typography variant='body1' color={'#64748B'} fontSize={'12px'} pt={2}>
              {`File format only word or Pdf, Size should not exceed 10MB`}
            </Typography>
            <FileContainerDetails>
              {newDocument &&
                newDocument.documents &&
                newDocument.documents.map((fileData, index) => (
                  <FileContainer key={index}>
                    <ImageContainer>
                      {fileData.file.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                      <FileDetails>
                        {fileData.file && (
                          <>
                            <Typography
                              variant='body1'
                              style={{ fontWeight: 'bold', marginLeft: '10px' }}
                            >
                              {fileData.file.name}
                            </Typography>
                            <Typography variant='caption' style={{ marginLeft: '10px' }}>
                              {(fileData.file.size / 1024).toFixed(2)} KB
                            </Typography>
                          </>
                        )}
                      </FileDetails>
                      <IconButton onClick={() => handleDeleteFile(index)}>
                        <DeleteIcon style={{ color: 'red' }} />
                      </IconButton>
                    </ImageContainer>
                    <ModalDivider />
                    <CustomInputText
                      placeholder='Document Name'
                      value={fileData.docname}
                      onChange={(e) => handleDocumentNameChange(e, index)}
                    />
                    {errors[`docname_${index}`] && (
                      <Typography variant='caption' style={{ color: 'red' }}>
                        {errors[`docname_${index}`]}
                      </Typography>
                    )}
                  </FileContainer>
                ))}
            </FileContainerDetails>
            <ModalDivider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                pt: 2,
              }}
            >
              <LoadingButton
                color='error'
                variant='outlined'
                sx={{ height: '40px', mt: 0.5 }}
                onClick={handleOngoing}
                style={{ boxShadow: 'none' }}
                disabled={loading}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                loading={loading} // Add loading state
                style={{ boxShadow: 'none' }}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* <LeadsSuccess
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        to={'Ongoing Project'}
      /> */}
    </>
  );
};

export default LeadsOngoing;
