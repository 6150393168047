import { CircularProgress } from '@mui/material';
import { Box, styled } from '@mui/system';

const FullScreenLoading = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
  '& img': {
    width: 'auto',
    height: '25px',
  },
  '& .circleProgress': {
    position: 'absolute',
    left: -7,
    right: 0,
    top: 'calc(50% - 25px)',
  },
}));

const Loading = () => {
  return (
    <FullScreenLoading>
      <Box position='relative'>
        <img src='/assets/images/logo-circle.svg' alt='' />
        <CircularProgress className='circleProgress' />
      </Box>
    </FullScreenLoading>
  );
};

export default Loading;
