import { styled, Box } from '@mui/material';
import {
    Modal,
    Button,
    TextField,
    Typography,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useRef, useState } from 'react';
import { Formik } from 'formik';
import { GoogleMap, StandaloneSearchBox, Marker } from '@react-google-maps/api';
import { notify } from 'app/services/notify';
import { delay, parseAddressComponents } from 'app/utils/helpers';
import { DEFAULT_CENTER } from 'app/utils/constant';
import LoadingButton from '@mui/lab/LoadingButton';
import { validationSchemas } from 'app/utils/validationSchemas';
import { projectLeadLocationInformation } from 'app/contexts/leadActions';
import { useProjectLocationCreateUpdate } from '../hooks/useProjectLocationCreateUpdate';
import {
    StyledBox1, StyledModalTitle, StyledCrossIcon, StyledBox2,
    StyledBox3, StyledTextField, StyledLabel
} from './StyledComponents';
import { ModalCancel, ModalSave, StyledBoxFooter } from 'app/common/Typography';

const EditAddressModal = ({ handleCloseEditAddModal, location, projectId }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const searchInputRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { mutate: updateLocation } = useProjectLocationCreateUpdate(projectId);
    const [formikValues, setFormikValues] = useState({
        address1: location?.address1 || '',
        address2: location?.address2 || '',
        city: location?.city || '',
        state: location?.state || '',
        zipCode: location?.zipCode || '',
        country: location?.country || '',
        latitude: location?.latitude || '',
        longitude: location?.longitude || '',
    });

    const toggleModal = () => setOpen((prevState) => !prevState);

    const handleMapClick = async (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        try {
            setIsLoading(true);
            const geocoder = new window.google.maps.Geocoder();

            const results = await new Promise((resolve, reject) => {
                geocoder.geocode(
                    { location: new window.google.maps.LatLng(lat, lng) },
                    (results, status) => {
                        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
                            resolve(results[0]);
                        } else {
                            console.error('Geocode failed:', status);
                            reject(new Error('Geocode was not successful for the following reason: ' + status));
                        }
                    },
                );
            });

            const addressData = parseAddressComponents(results);

            setFormikValues((prevState) => ({
                ...prevState,
                ...addressData,
            }));

            if (markerRef.current) {
                markerRef.current.setPosition({ lat, lng });
            }

            if (mapRef.current) {
                mapRef.current.panTo({ lat, lng });
            }
        } catch (error) {
            console.error('Places search or details error:', error);
        } finally {
            setIsLoading(false);
            toggleModal();
        }
    };

    const handlePlacesChanged = (setValues) => {
        const [place] = searchInputRef.current.getPlaces();
        if (!place) return;

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        const addressData = parseAddressComponents(place);

        setValues((prevState) => ({
            ...prevState,
            ...addressData,
            latitude: lat,
            longitude: lng,
        }));

        if (markerRef.current) {
            markerRef.current.setPosition({ lat, lng });
        }

        if (mapRef.current) {
            mapRef.current.panTo({ lat, lng });
        }
    };

    const geocodeAddress = async (address) => {
        const geocoder = new window.google.maps.Geocoder();
        return new Promise((resolve, reject) => {
            geocoder.geocode({ address }, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
                    const location = results[0].geometry.location;
                    resolve(location);
                } else {
                    reject(new Error('Geocode failed: ' + status));
                }
            });
        });
    };
    const handleMapLoad = async (map) => {
        mapRef.current = map;
        const fullAddress = `${location?.address1} ${location?.address2} ${location?.city} ${location?.state} ${location?.zipCode} ${location?.country}`;

        try {
            const geoLocation = await geocodeAddress(fullAddress);
            const position = {
                lat: geoLocation.lat(),
                lng: geoLocation.lng(),
            };

            mapRef.current.setCenter(position);
            if (markerRef.current) {
                markerRef.current.setPosition(position);
            }
        } catch (error) {
            console.error("Error in geocoding address:", error);
            mapRef.current.setCenter(DEFAULT_CENTER);
        }
    };

    const handleMarkerLoad = (marker) => {
        markerRef.current = marker;
    };

    const handleFormSubmit = (values, { setSubmitting }) => {
        const locationDetails = {
            projectId: location?.projectId,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zipCode: values.zipCode,
            country: values.country
        }

        updateLocation(locationDetails, {
            onSuccess: handleCloseEditAddModal(),
            onSettled: () => setSubmitting(false)
        })
    };



    return (
        <>
            <StyledBox1>
                <StyledBox2 sx={{marginBottom: '1rem'}}>
                    <StyledModalTitle>Edit Address</StyledModalTitle>
                    <StyledCrossIcon onClick={handleCloseEditAddModal}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>
                <StyledBox3>

                    <GoogleMap
                        zoom={15}
                        mapContainerStyle={{ width: '100%', height: '250px' }}
                        onLoad={handleMapLoad}
                        onClick={handleMapClick}
                    >
                        <Marker
                            animation={window.google.maps.Animation.DROP}
                            draggable
                            onLoad={handleMarkerLoad}
                        />
                    </GoogleMap>


                    <Formik
                        initialValues={formikValues}
                        enableReinitialize
                        validationSchema={validationSchemas.location()}
                        onSubmit={handleFormSubmit}
                    >
                        {({
                            values,
                            isSubmitting,
                            errors,
                            touched,
                            setValues,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <StyledLabel sx={{marginLeft: '2%'}}>Address1</StyledLabel>
                                <StandaloneSearchBox
                                    onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                                    onPlacesChanged={() => handlePlacesChanged(setValues)}
                                >
                                    <StyledTextField
                                        fullWidth
                                        type="search"
                                        name="address1"
                                        value={values?.address1}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.address1 && errors.address1}
                                        error={Boolean(errors.address1 && touched.address1)}
                                        disabled={isSubmitting}
                                    />
                                </StandaloneSearchBox>
                                <StyledLabel sx={{marginLeft: '2%'}}>Address2</StyledLabel>
                                <StyledTextField
                                    type="text"
                                    name="address2"
                                    onChange={handleChange}
                                    value={values?.address2}
                                    fullWidth
                                    variant='outlined'
                                    size='small'
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                />
                                <StyledLabel sx={{marginLeft: '2%'}}>City</StyledLabel>
                                <StyledTextField
                                    fullWidth
                                    name="city"
                                    value={values?.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.city && errors.city}
                                    error={Boolean(errors.city) && touched.city}
                                    disabled={isSubmitting}
                                />
                                <StyledLabel sx={{marginLeft: '2%'}}>State</StyledLabel>
                                <StyledTextField
                                    fullWidth
                                    type="text"
                                    name="state"
                                    value={values?.state}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.state && errors.state}
                                    error={Boolean(errors.state && touched.state)}
                                    disabled={isSubmitting}
                                />
                                <StyledLabel sx={{marginLeft: '2%'}}>Zip Code</StyledLabel>
                                <StyledTextField
                                    fullWidth
                                    type="text"
                                    name="zipCode"
                                    value={values?.zipCode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.zipCode && errors.zipCode}
                                    error={Boolean(errors.zipCode && touched.zipCode)}
                                    disabled={isSubmitting}
                                />
                                <StyledLabel sx={{marginLeft: '2%'}}>Country</StyledLabel>
                                <StyledTextField
                                    fullWidth
                                    name="country"
                                    value={values?.country}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.country && errors.country}
                                    error={Boolean(errors.country && touched.country)}
                                    disabled={isSubmitting}
                                />
                                <StyledBoxFooter sx={{marginTop: '4rem'}}>
                                    <ModalCancel
                                        variant='outlined'
                                        onClick={handleCloseEditAddModal}
                                        disabled={isSubmitting}
                                    >
                                        Cancle
                                    </ModalCancel>
                                    <ModalSave
                                        type='submit'
                                        loading={isSubmitting}
                                    >
                                        Update
                                    </ModalSave>
                                </StyledBoxFooter>
                            </form>
                        )}
                    </Formik>
                </StyledBox3>
            </StyledBox1>
        </>
    );
};

export default EditAddressModal;
