import React from 'react';
import { Box, Button, Grid, TextField, Modal, IconButton, styled, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { useFormik } from 'formik';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectBuildingUnitSaleCreateUpdate } from './hooks/useProjectBuildingUnitSaleCreateUpdate';
import { useProject } from 'app/contexts/ProjectContext';
import { LoadingButton } from '@mui/lab';

const ModalDivider = styled('div')({
  height: '1px',
  marginBottom: '20px',
  backgroundColor: 'var(--modalDivider)',
});

const SaleAddEdit = ({
  open,
  saleData,
  buyerData,
  onBack,
  onClose,
  selectedBuilding,
  selectedUnit,
  saleId
}) => {
  console.log("sale",saleData);
  console.log("buy",buyerData);
  console.log(selectedBuilding);
  console.log(selectedUnit);
  const { projectId } = useProject();
  const { mutate: createUpdateSale } = useProjectBuildingUnitSaleCreateUpdate(projectId, saleData);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleSaveSubmit = (values, { setSubmitting }) => {
    createUpdateSale(
      {
        buyerData,
        updatedSaleData: values,
        selectedBuilding,
        selectedUnit,
        saleId
      },
      {
        onSettled: () => setSubmitting(false),
        onSuccess: handleClose,
      },
    );
  };

  const formik = useFormik({
    initialValues: {
      buyerRealtor: saleData?.buyerRealtor || '',
      saleSpace: selectedUnit?.space || '',
      unitNo: selectedUnit?.unitName || '',
      salePrice: saleData?.salePrice || '',
      saleExecutionDate: saleData?.saleExecutionDate || '',
      saleFixturizationDate: saleData?.saleFixturizationDate || '',
      saleStartDate: saleData?.saleStartDate || '',
      saleEndDate: saleData?.saleEndDate || '',
      buyerSaleCommission: saleData?.buyerSaleCommission || '',
      sellerSaleCommission: saleData?.sellerSaleCommission || '',
      closingCost: saleData?.closingCost || '',
      titlePolicyCost: saleData?.titlePolicyCost || '',
      comments: saleData?.comments || '',
    },
    validationSchema: validationSchemas.sale(),
    enableReinitialize: true,
    onSubmit: handleSaveSubmit,
  });

  return (
    <Modal open={open} closeAfterTransition>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '0%',
          transform: 'translate(0%, -50%)',
          width: '60%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          height: '100%',
        }}
      >
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Title>Sale Details 2/2</Title>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
          </IconButton>
        </Box>

        <ModalDivider />

        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='buyerRealtor'
              >
                Buyer Realtor
              </InputLabel>
              <TextField
                name='buyerRealtor'
                id='buyerRealtor'
                placeholder='Enter name'
                size='small'
                fullWidth
                onKeyDown={(e) => {
                  // Block unwanted keys
                  const blockedKeys = ['+', '-', '.', '/', "'", '[', ']', '{', '}', '\\', ';', ':'];
                  if (blockedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={formik.values.buyerRealtor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.buyerRealtor && Boolean(formik.errors.buyerRealtor)}
                helperText={formik.touched.buyerRealtor && formik.errors.buyerRealtor}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='saleSpace'
              >
                Sale Space
              </InputLabel>
              <TextField
                name='saleSpace'
                id='saleSpace'
                placeholder='Enter space'
                size='small'
                onKeyDown={(e) => {
                  // Block unwanted keys
                  const blockedKeys = ['+', '-', '.', '/', "'", '[', ']', '{', '}', '\\', ';', ':'];
                  if (blockedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={selectedUnit?.space || saleData?.saleSpace || ''}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                error={formik.touched.saleSpace && Boolean(formik.errors.saleSpace)}
                helperText={formik.touched.saleSpace && formik.errors.saleSpace}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='unitNo'
              >
                Unit Name
              </InputLabel>
              <TextField
                placeholder='Enter unit name'
                id='unitNo'
                name='unitNo'
                size='small'
                type='text'
                fullWidth
                value={selectedUnit?.unitName || saleData?.unitNo || ''}
                InputProps={{
                  readOnly: true, // Set to read-only
                }}
                error={formik.touched.unitNo && Boolean(formik.errors.unitNo)}
                helperText={formik.touched.unitNo && formik.errors.unitNo}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='salePrice'
              >
                Sale Price
              </InputLabel>
              <TextField
                placeholder='Enter sale price'
                id='salePrice'
                name='salePrice'
                type='number'
                size='small'
                onKeyDown={(e) => {
                  // Block unwanted keys
                  const blockedKeys = [
                    'e',
                    'E',
                    '+',
                    '-',
                    '.',
                    '/',
                    "'",
                    '[',
                    ']',
                    '{',
                    '}',
                    '\\',
                    ';',
                    ':',
                  ];
                  if (blockedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                fullWidth
                value={formik.values.salePrice}
                onChange={(e) => {
                  const value = e.target.value;

                  formik.setFieldValue('salePrice', value === '' ? '' : Math.abs(value));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
                helperText={formik.touched.salePrice && formik.errors.salePrice}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='buyerSaleCommission'
              >
                Buyer Sale Commission
              </InputLabel>
              <TextField
                name='buyerSaleCommission'
                size='small'
                type='number'
                id='buyerSaleCommission'
                placeholder='Enter buyer sale commission'
                fullWidth
                onKeyDown={(e) => {
                  // Block unwanted keys
                  const blockedKeys = [
                    'e',
                    'E',
                    '+',
                    '-',
                    '.',
                    '/',
                    "'",
                    '[',
                    ']',
                    '{',
                    '}',
                    '\\',
                    ';',
                    ':',
                  ];
                  if (blockedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={formik.values.buyerSaleCommission}
                onChange={(e) => {
                  const value = e.target.value;
                  formik.setFieldValue('buyerSaleCommission', value === '' ? '' : Math.abs(value));
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.buyerSaleCommission && Boolean(formik.errors.buyerSaleCommission)
                }
                helperText={formik.touched.buyerSaleCommission && formik.errors.buyerSaleCommission}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='sellerSaleCommission'
              >
                Seller Sale Commission
              </InputLabel>
              <TextField
                id='sellerSaleCommission'
                placeholder='Enter seller sale commission'
                name='sellerSaleCommission'
                size='small'
                type='number'
                fullWidth
                onKeyDown={(e) => {
                  // Block unwanted keys
                  const blockedKeys = [
                    'e',
                    'E',
                    '+',
                    '-',
                    '.',
                    '/',
                    "'",
                    '[',
                    ']',
                    '{',
                    '}',
                    '\\',
                    ';',
                    ':',
                  ];
                  if (blockedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={formik.values.sellerSaleCommission}
                onChange={(e) => {
                  const value = e.target.value;
                  formik.setFieldValue('sellerSaleCommission', value === '' ? '' : Math.abs(value));
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.sellerSaleCommission && Boolean(formik.errors.sellerSaleCommission)
                }
                helperText={
                  formik.touched.sellerSaleCommission && formik.errors.sellerSaleCommission
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='closingCost'
              >
                Closing Cost
              </InputLabel>
              <TextField
                id='closingCost'
                placeholder='Enter closing cost'
                name='closingCost'
                size='small'
                type='number'
                fullWidth
                onKeyDown={(e) => {
                  // Block unwanted keys
                  const blockedKeys = [
                    'e',
                    'E',
                    '+',
                    '-',
                    '.',
                    '/',
                    "'",
                    '[',
                    ']',
                    '{',
                    '}',
                    '\\',
                    ';',
                    ':',
                  ];
                  if (blockedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={formik.values.closingCost}
                onChange={(e) => {
                  const value = e.target.value;
                  formik.setFieldValue('closingCost', value === '' ? '' : Math.abs(value));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.closingCost && Boolean(formik.errors.closingCost)}
                helperText={formik.touched.closingCost && formik.errors.closingCost}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='titlePolicyCost'
              >
                Title Policy Cost
              </InputLabel>
              <TextField
                id='titlePolicyCost'
                placeholder='Enter title policy cost'
                name='titlePolicyCost'
                size='small'
                type='number'
                fullWidth
                onKeyDown={(e) => {
                  // Block unwanted keys
                  const blockedKeys = [
                    'e',
                    'E',
                    '+',
                    '-',
                    '.',
                    '/',
                    "'",
                    '[',
                    ']',
                    '{',
                    '}',
                    '\\',
                    ';',
                    ':',
                  ];
                  if (blockedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={formik.values.titlePolicyCost}
                onChange={(e) => {
                  const value = e.target.value;
                  formik.setFieldValue('titlePolicyCost', value === '' ? '' : Math.abs(value));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.titlePolicyCost && Boolean(formik.errors.titlePolicyCost)}
                helperText={formik.touched.titlePolicyCost && formik.errors.titlePolicyCost}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  fontWeight: 700,
                  fontSize: 14,
                  mb: 1,
                  color: 'var(--inputLabel-color)',
                }}
                htmlFor='comments'
              >
                Comments
              </InputLabel>
              <TextField
                id='comments'
                placeholder='Write here...'
                name='comments'
                size='small'
                fullWidth
                multiline
                rows={4}
                value={formik.values.comments}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              item
              xs={12}
              textAlign='center'
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Button
                  variant='text'
                  color='inherit'
                  onClick={onBack}
                  startIcon={<ArrowBackIcon size='small' />}
                  disabled={formik.isSubmitting}
                >
                  Back
                </Button>
                <Button
                  variant='outlined'
                  color='error'
                  onClick={handleClose}
                  disabled={formik.isSubmitting}
                >
                  Cancel
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  sx={{ textTransform: 'none' }}
                >
                  Save
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default SaleAddEdit;
