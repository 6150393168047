import { createContext, useMemo, useState } from 'react';
import { useProject } from './ProjectContext';

export const ImagesContext = createContext();

const ImagesProvider = ({ children }) => {
  const { documents } = useProject();
  const [activeStep, setActiveStep] = useState(0);
  const [isOpenStepper, setIsOpenStepper] = useState(false);

  // REVIEW: consider to optimize it to only have one useMemo
  const images = useMemo(
    () => documents.filter((document) => document.metadata.documentType.startsWith('image/')),
    [documents],
  );

  const imageElements = useMemo(
    () =>
      images.map((image, index) => (
        <img
          src={`data:${image.metadata.documentType};base64,${image.content}`}
          alt={image.documentName}
          data-index={index}
        />
      )),
    [images],
  );

  const totalImagesCount = imageElements.length;
  const currentImage = imageElements[activeStep];

  const handleImageClick = (event) => {
    const target = event.target;
    if (target.tagName === 'IMG') {
      const index = parseInt(target.getAttribute('data-index'), 10);
      if (!isNaN(index)) {
        setActiveStep(index);
        setIsOpenStepper(true);
      }
    }
  };
  const handleCloseStepper = () => {
    setIsOpenStepper(false);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % totalImagesCount);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + totalImagesCount) % totalImagesCount);
  };

  return (
    <ImagesContext.Provider
      value={{
        imageElements,
        activeStep,
        isOpenStepper,
        currentImage,
        setIsOpenStepper,
        totalImagesCount,
        handleNext,
        handleBack,
        handleImageClick,
        handleCloseStepper,
      }}
    >
      {children}
    </ImagesContext.Provider>
  );
};

export default ImagesProvider;
