import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import LeadLeaseSuccess from './LeadLeaseSuccess';
import CloseIcon from '@mui/icons-material/Close';
import { FixedFooter, Footer, ModalCancel, ModalContent, ModalSave, StyledBoxFooter, StyledIcon, StyledLinkIcon, Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectBuildingCreateUpdate } from './hooks/useProjectBuildingCreateUpdate';
import { StyledCardTitle } from 'app/views/dashboard/profile/StyleComponents';
import {
  StledCardTitle,
  StledCardUnitTitle,
  StyledAddButton,
  StyledAddLinkButton,
} from '../../listings/StyledComponent';
import { LoadingButton } from '@mui/lab';
import { StyledLabel } from '../../listings/Modals/StyledComponents';

const AddEditBuilding = ({ open, onClose, selectedBuilding }) => {
  const [successOpen, setSuccessOpen] = useState(false);
  const [submittedBuildingName, setSubmittedBuildingName] = useState(
    selectedBuilding?.buildingName,
  );
  const { projectId } = useProject();

  const { mutate: createUpdateBuilding } = useProjectBuildingCreateUpdate(
    projectId,
    selectedBuilding,
  );

  const handleSuccessClose = useCallback(() => {
    setSuccessOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    let timer;

    if (successOpen) {
      timer = setTimeout(handleSuccessClose, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [successOpen, handleSuccessClose]);

  const initialValues = {
    buildingName: selectedBuilding?.buildingName || '',
    units: [{ unitName: '', space: '' }],
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (!selectedBuilding) {
      setSubmittedBuildingName(values.buildingName);
    }

    createUpdateBuilding(values, {
      onSettled: () => setSubmitting(false),
      onSuccess: () => setSuccessOpen(true),
    });
  };

  return (
    <>
      <Modal open={open} onClose={false}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '0%',
            transform: 'translate(0%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '40%',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingLeft: '5px'
          }}
        >
          <ModalContent>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas.addBuilding()}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setFieldValue, setTouched }) => (
                <Form>
                  <Box
                    sx={{
                      padding: 2,
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}
                  >
                    <Title fontSize='22px' fontWeight='600' color='var(--add-Building)'>
                      {selectedBuilding ? `Add Units` : `Add Building`}
                    </Title>
                    <IconButton onClick={onClose}>
                      <CloseIcon style={{ fontSize: 20, color: 'var(--closeIcon)' }} />
                    </IconButton>
                  </Box>

                  <StyledLabel sx={{paddingLeft: '20px'}}>
                    Building Name
                  </StyledLabel>
                  <Field
                    as={TextField}
                    fullWidth
                    name='buildingName'
                    size='small'
                    sx={{ marginTop: 1, paddingX: 2 }}
                    error={touched.buildingName && Boolean(errors.buildingName)}
                    helperText={<ErrorMessage name='buildingName' />}
                    InputProps={{
                      readOnly: !!selectedBuilding,
                    }}
                  />
                  <Box
                    sx={{ margin: '30px 20px 20px 20px' }}
                    display='flex'
                    justifyContent='space-between'
                  >
                    <StledCardUnitTitle>Units</StledCardUnitTitle>
                    <StyledAddButton
                      startIcon={<StyledIcon />}
                      onClick={() => {
                        setTouched({
                          buildingName: true,
                          units: values.units.map((_, index) => ({
                            unitName: true,
                            space: true,
                          })),
                        });

                        if (!values.buildingName.trim()) {
                          setFieldValue('buildingName', values.buildingName, true);
                        } else {
                          const lastUnit = values.units[values.units.length - 1];
                          if (!lastUnit || (lastUnit.unitName && lastUnit.space)) {
                            setFieldValue('units', [...values.units, { unitName: '', space: '' }]);
                          }
                        }
                      }}
                    >
                      Units
                    </StyledAddButton>
                  </Box>

                  {values.units.length > 0 && (
                    <div
                      style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        paddingInline: '20px',
                        marginBottom: '20px',
                      }}
                    >
                      <Grid container spacing={2}>
                        {values.units.map((unit, index) => (
                          <>
                            <Grid item xs={12} sm={5.5}>
                              <StyledLabel sx={{mb: '10px'}}>
                                Unit Name
                              </StyledLabel>
                              <Field
                                as={TextField}
                                name={`units[${index}].unitName`}
                                id={`units[${index}].unitName`}
                                size='small'
                                fullWidth
                                error={
                                  touched.units &&
                                  touched.units[index]?.unitName &&
                                  Boolean(errors.units?.[index]?.unitName)
                                }
                                helperText={<ErrorMessage name={`units[${index}].unitName`} />}
                                style={{ marginRight: '10px' }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5.5}>
                              <StyledLabel sx={{mb: '10px'}}>
                                Space (Sq ft)
                              </StyledLabel>
                              <Field
                                as={TextField}
                                name={`units[${index}].space`}
                                id={`units[${index}].space`}
                                size='small'
                                type='number'
                                fullWidth
                                inputProps={{ min: 1 }}
                                error={
                                  touched.units &&
                                  touched.units[index]?.space &&
                                  Boolean(errors.units?.[index]?.space)
                                }
                                helperText={<ErrorMessage name={`units[${index}].space`} />}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={1}
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                              mt={2}
                            >
                              <IconButton
                                onClick={() => {
                                  const updatedUnits = values.units.filter((_, i) => i !== index);
                                  setFieldValue('units', updatedUnits);
                                }}
                                color='error'
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </div>
                  )}
                  <FormControl error={touched?.units && errors?.units}>
                    {touched?.units && errors?.units && (
                      <FormHelperText>
                        {!Array.isArray(errors.units) && errors.units}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <StyledBoxFooter>
                    <ModalCancel
                      size='large'
                      className='cancelButton'
                      sx={{ textTransform: 'none' }}
                      onClick={onClose}
                    >
                      Cancel
                    </ModalCancel>
                    <ModalSave
                      color='primary'
                      size='large'
                      sx={{ textTransform: 'none', marginLeft: 2 }}
                      variant='contained'
                      type='submit'
                    >
                      Save
                    </ModalSave>
                  </StyledBoxFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Box>
      </Modal>
      <LeadLeaseSuccess
        open={successOpen}
        buildingName={submittedBuildingName}
        handleClose={handleSuccessClose}
      />
    </>
  );
};

export default AddEditBuilding;
