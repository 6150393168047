import React, { useMemo } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Button,
  Container,
  Modal,
  Typography,
  Icon,
} from '@mui/material';
import { SideNavMenu } from 'app/components/index.js';
import { Span } from 'app/common/Typography.js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConstructionLenderDetails from './ConstructionLenderDetails';
import ConstructionLenderDetailsModal from './ConstructionLenderDetailsModal';
import { useProject } from 'app/contexts/ProjectContext';
import { Title} from 'app/common/Typography';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const LeadsContructionLenderInformation = () => {
  const { constructionLender, documents } = useProject();
  const [contractData, setContractData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [allCL, setAllCL] = React.useState(constructionLender);

  React.useEffect(() => {
    setAllCL(structuredClone(constructionLender));
  }, [constructionLender]);

  const initialDocuments = useMemo(
    () => documents.filter((doc) => doc.metadata.documentType.startsWith('application/')),
    [documents],
  );

  const handleInvoiceDownload = (initialDocuments, clDocs) => {
    try {
      initialDocuments.forEach((doc) => {
        if (clDocs?.loanDocuments?.includes(doc.metadata.fileId)) {
          handleDownload(doc.content, doc.metadata.documentName, doc.metadata.documentType);
        }
      });
    } catch (error) {}
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const handleDownload = (content, fileName, mimeType) => {
    try {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
  };

  const handleView = (index) => {
    setContractData(index);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SubtitleDivider />
      <Box alignItems='center' mt={3}>
      {(allCL == null || allCL == undefined || allCL?.length === 0) ? (
        <>
          <Title> Construction Lender</Title>
          <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }} >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }} variant="body1" color="#272937" gutterBottom>
                  No details added
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It seems you have not added any details related to Construction Lender
                </Typography>
                <Button onClick={handleClickOpen} variant="contained" color="primary">
                  Add Details
                </Button>
              </Box>
            </Container>
          <Modal open={open} onClose={handleClose}>
            <ConstructionLenderDetailsModal contractors={allCL}  handleCloseModal={handleClose} formatDate={formatDate} />
          </Modal>
        </>
      ) : (contractData !== null && contractData !== undefined) ? (<ConstructionLenderDetails handleInvoiceDownload={handleInvoiceDownload} contractors={allCL} id={contractData} setContractData={handleView} initialDocuments={initialDocuments} />) : (
        <>
          <Box
            marginLeft={3}
            marginRight={3}
            marginTop={1}
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}
          >
            <Title>Construction Lender</Title>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Button onClick={handleClickOpen}>+ ADD</Button>
            </Box>
          </Box>
          <Modal open={open} onClose={handleClose}>
            <ConstructionLenderDetailsModal contractors={allCL} handleCloseModal={handleClose} formatDate={formatDate} />
          </Modal>
          <Box overflow='auto'
            border={'2px solid #E5E5E7'}
            marginLeft={3}
            marginRight={3}
            marginTop={1}
            marginBottom={1}>
            <ProductTable>
              <TableHead bgcolor='#F2F9FC'>
                <TableRow color='#64748B'>
                  <TableCell sx={{ px: 2, color: '#64748B' }}>S.NO</TableCell>
                  <TableCell sx={{ px: 0, color: '#64748B' }}>LENDER NAME</TableCell>
                  <TableCell sx={{ px: 0, color: '#64748B' }}>EMAIL ADDRESS</TableCell>
                  <TableCell sx={{ px: 0, color: '#64748B' }}>PHONE NUMBER</TableCell>
                  <TableCell sx={{ px: 0, width: '5%', color: '#64748B' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allCL?.map((lender, index) => (
                  <TableRow key={lender.id} hover>
                    <TableCell
                      align='left'
                      sx={{
                        px: 2,
                        textTransform: 'capitalize',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: '#272937',
                      }}
                    >
                      {`${(index + 1).toString().padStart(2, '0')}`}
                    </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {lender?.lenderName}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {lender?.lenderEmail}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {lender?.lenderPhone}
                      </TableCell>

                      <TableCell align='left' sx={{ color: '#272937' }}>
                        <SideNavMenu
                          menuButton={
                            <UserMenu>
                              <MoreVertIcon />
                            </UserMenu>
                          }
                        >
                          <StyledItem onClick={() => handleView(index)}>
                            <Icon>
                              <VisibilityIcon />
                            </Icon>
                            <Span> View </Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ProductTable>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default LeadsContructionLenderInformation;
