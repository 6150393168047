import { Box, Button, styled, TextField, Typography } from '@mui/material';
import clsx from 'clsx';

const StyledBox = styled(Box)(({ texttransformstyle, ellipsis }) => ({
  textTransform: texttransformstyle || 'none',
  whiteSpace: ellipsis ? 'nowrap' : 'normal',
  overflow: ellipsis ? 'hidden' : '',
  textOverflow: ellipsis ? 'ellipsis' : '',
}));

export const CenteredText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const Title = styled('h1')(() => ({
  fontSize: '20px',
  fontWeight: '600',
  color: '#272937',
  textTransform: 'capitalize',
}));
export const H1 = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      component='h1'
      mb={0}
      mt={0}
      fontSize='28px'
      fontWeight='500'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Paragraph = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='p'
      mb={0}
      mt={0}
      fontSize='14px'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Small = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='small'
      fontSize='12px'
      fontWeight='500'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Span = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='span'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const CustomGCExpensesInputText = ({ placeholder, error, touched, ...props }) => {
  return (
    <>
      <input
        type='text'
        placeholder={placeholder}
        style={{
          padding: '10px',
          borderRadius: '5px',
          border: '0px',
          outline: 'none',
          width: '100%',
          backgroundColor: '#F8F8F8',
        }}
        {...props}
      />
      {error && (
        <Typography color='error' variant='body2'>
          {props.helperText}
        </Typography>
      )}
    </>
  );
};

export const CustomInputText = ({ placeholder, ...props }) => {
  return (
    <>
      <input
        type='text'
        placeholder={placeholder}
        style={{
          padding: '10px',
          borderRadius: '5px',
          border: '0px',
          outline: 'none',
          width: '100%',
          backgroundColor: '#F8F8F8',
        }}
        {...props}
      />
    </>
  );
};

export const CustomTextInput = ({ placeholder, ...props }) => {
  return (
    <TextField
      type='text'
      placeholder={placeholder}
      sx={{
        '& .MuiInputBase-root': {
          height: '40px',
        },
      }}
      {...props}
    />
  );
};

export const customNumberInput = {
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
};

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '4px',
  width: '31vw',
  marginTop: '10px',
  border: '2px dashed lightgray',
});
export const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

export const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
});

export const FileContainer = styled('div')({
  alignItems: 'center',
  // padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: '#F8F8F8',
  borderRadius: '4px',
  marginTop: '10px',
  marginLeft: '20px',
});
export const FileContainerDetails = styled('div')({
  maxHeight: '20vh',
  overflowY: 'auto',
  width: '100%',
});

export const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // marginLeft: '8px',
  flexGrow: 1,
});
