import { styled } from '@mui/material';
import { Box, Button, TextField, Table, TableRow } from '@mui/material';

// Reusing relevant styles from listings
export const Container = styled(Box)(({ theme }) => ({
  padding: '1px 16px',
  backgroundColor: '#EEEEF4',
  borderRadius: '10px',
  width: '100%',
  height: '4.375rem',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
}));

export const SearchInput = styled(TextField)(({ theme }) => ({
  flex: 1,
  '& .MuiOutlinedInput-root': {
    height: '35px',
    borderRadius: '17.5px',
    backgroundColor: '#FFFFFF',
    '& fieldset': {
      border: '1px solid #BDC1C6',
    },
  },
  '& input::placeholder': {
    fontSize: '14px',
    color: '#80868B',
  },
}));

export const FilterButton = styled(Button)(({ theme }) => ({
  height: '36px',
  borderRadius: '20px',
  textTransform: 'none',
  border: '1px solid #BDC1C6',
  color: '#111827',
  padding: '0 16px',
  backgroundColor: '#FFFFFF',
  fontSize: '13px',
  '&:hover': {
    borderColor: '#D1D5DB',
    backgroundColor: '#F9FAFB',
  },
}));

export const TableWrapper = styled(Box)({
  overflowX: 'auto',
  width: '100%',
});

export const StyledMembersTable = styled(Table)({
  minWidth: 900,
  marginTop: '-1rem',
  borderCollapse: 'separate',
  borderSpacing: '0 1rem',
  backgroundColor: '#F3F4F6',
  '& .MuiTableHead-root': {
    '& .MuiTableCell-head': {
      backgroundColor: '#FFFFFF',
      border: 'none',
      color: '#282A2D',
      fontSize: '1.1rem',
      fontWeight: 600,
      padding: '16px',
      '&:first-of-type': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
      '&:last-child': {
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },
    },
    '& .MuiTableRow-root': {
      backgroundColor: '#FFFFFF',
    },
  },
  '& .MuiTableBody-root': {
    backgroundColor: '#FFFFFF',

    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: '#F9FAFB',
      },
    },
    '& .MuiTableCell-body': {
      padding: '16px',
      fontSize: '0.875rem',
      color: '#282A2D',
      borderBottom: '1px solid #E5E7EB',
    },
  },
});

export const MemberName = styled(Box)({
  color: '#645BBB',
  fontSize: '1rem',
  fontWeight: 500,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const StatusChip = styled(Box)(({ status }) => {
  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return {
          bg: '#4CB64C',
        };
      case 'pending':
        return {
          bg: '#7697F2',
        };
      default:
        return {
          bg: '#4CB64C',
        };
    }
  };

  const colors = getStatusColor(status);

  return {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 500,
    backgroundColor: colors.bg,
    color: '#FFF',
  };
});

export const HeaderStatusChip = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '2px 8px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  fontWeight: 500,
  backgroundColor: '#4caf50',
  color: '#FFFFFF',
});

export const ContactDetails = styled(Box)({
  '& > *:first-of-type': {
    fontSize: '0.875rem',
    fontWeight: 500,
    marginBottom: '4px',
  },
  '& > *:last-child': {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
});

export const StyledTableRow = styled(TableRow)({
  backgroundColor: '#FFFFFF',
  '& td': {
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #E5E7EB',
    '&:first-of-type': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
  '&:hover td': {
    backgroundColor: '#F9FAFB',
  },
});
