import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useProjectDocumentsUpload } from '../../Documents/hooks/useProjectDocumentsUpload';
import { formatDateToLocalISOString } from 'app/utils/helpers';
import { queryKeys } from 'app/react-query/queryKeys';
import { createUpdateProjectBuildingLease } from 'app/contexts/projectActions';

export function useProjectBuildingUnitLeaseCreateUpdate(projectId, tenantData) {
  const queryClient = useQueryClient();

  const isEditingMode = !!tenantData;

  const { mutateAsync: uploadDocument } = useProjectDocumentsUpload(projectId);

  return useMutation({
    mutationFn: async ({ updatedTenantData, selectedBuilding, selectedUnit, leaseData, leaseId }) => {
      const documentID = [''];
      if (leaseData?.leaseDocuments && leaseData.leaseDocuments.length > 0) {
        const response = await uploadDocument(leaseData.leaseDocuments);
        const parsedDocumentID = response.match(/Document IDs: \[([^\]]+)\]/)[1];
        documentID.push(parsedDocumentID);
      }
      // const response = await uploadDocument(leaseData.leaseDocuments);
      // const parsedDocumentID = response.match(/Document IDs: \[([^\]]+)\]/)[1];
      // documentID.push(parsedDocumentID);

      const tenantDetails = {
        contactPerson: updatedTenantData.contactPerson,
        email: updatedTenantData.tenantEmail,
        phoneNo: updatedTenantData.tenantPhoneNo,
        address: updatedTenantData.address1,
        country: updatedTenantData.country,
        state: updatedTenantData.state,
        city: updatedTenantData.city,
        zipCode: updatedTenantData.zipCode,
        llcDetails: {
          llcName: updatedTenantData.LLCName,
          llcNumber: updatedTenantData.LLCNumber,
          email: updatedTenantData.LLCEmail,
          phoneNo: updatedTenantData.LLCPhoneNumber,
          address: updatedTenantData.LLCAddress1,
          country: updatedTenantData.LLCCountry,
          state: updatedTenantData.LLCState,
          city: updatedTenantData.LLCCity,
          zipCode: updatedTenantData.LLCZIPCode,
        },
      };

      const finalObject = {
        buildingName: selectedBuilding?.buildingName,
        unitId: selectedUnit?.unitId,
        unitDetails: {
          tenantDetails,
          leaseDetails: {
            ...leaseData,
            leaseStartDate: formatDateToLocalISOString(leaseData?.leaseStartDate),
            leaseEndDate: formatDateToLocalISOString(leaseData?.leaseEndDate),
            leaseExecutionDate: formatDateToLocalISOString(leaseData?.leaseExecutionDate),
            leaseFixturizationDate: formatDateToLocalISOString(leaseData?.leaseFixturizationDate),
            firstMonthRentDate: formatDateToLocalISOString(leaseData?.firstMonthRentDate),
            leaseDocuments: documentID,
          },
        },
      };

      const updatedObject = {
        buildingName: selectedBuilding?.buildingName,
        unitId: selectedUnit?.unitId,
        id: leaseId,
        unitDetails: {
          tenantDetails,
          leaseDetails: {
            ...leaseData,
            leaseExecutionDate: formatDateToLocalISOString(leaseData?.leaseExecutionDate),
            leaseFixturizationDate: formatDateToLocalISOString(leaseData?.leaseFixturizationDate),
            leaseStartDate: formatDateToLocalISOString(leaseData?.leaseStartDate),
            leaseEndDate: formatDateToLocalISOString(leaseData?.leaseEndDate),
            firstMonthRentDate: formatDateToLocalISOString(leaseData?.firstMonthRentDate),
            leaseDocuments: documentID,
            rentCommencementDate: '2024-06-01T10:30:00',
            freeRentMonths: 5,
            rentPerYear: '100',
          },
        },
      };

      const payloadData = isEditingMode ? updatedObject : finalObject;

      return await createUpdateProjectBuildingLease(projectId, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.buildingUnitLeases, projectId],
      }),
  });
}
