import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  TextField,
  Autocomplete,
  styled,
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Card,
} from '@mui/material';
import { useProject } from 'app/contexts/ProjectContext';
import { StyledIcon, Title } from 'app/common/Typography';
import AddUpdateExpensesDialog from './AddUpdateExpensesDialog.jsx';
import { DownloadIcon } from 'app/common/icons';
import ExpenseInvoice from './ExpenseInvoice.jsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { documentTypes, expensesTypes } from 'app/utils/constant.js';
import { notify } from 'app/services/notify.js';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments.js';
import { useProjectExpenses } from './hooks/useProjectExpenses.js';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { StyledAddButton } from '../../listings/StyledComponent.js';
import MembersFilter from '../Members/MembersFilter.jsx';
import ExpensesTable from './Expensestable.jsx';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const AutoComplete = styled(Autocomplete)(() => ({
  width: 170,
  marginRight: 10,
  '& .MuiOutlinedInput-root': {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
  },
}));

const cellStyle = {
  width: '12.5%',
  textAlign: 'left',
  padding: '8px',
};

const tableBodyCellStyle = {
  fontWeight: 500,
  fontSize: '14px',
  align: 'left',
  paddingLeft: '0.7%',
};

const expenseTypeParam = 'expenseType';

const ExpensesSection = () => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: documents } = useProjectDocuments(projectId);
  const { data: expenses } = useProjectExpenses(projectId);

  const [openRow, setOpenRow] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const expensesDocuments = useMemo(
    () => documents.filter((doc) => doc.metadata.type === documentTypes.EXPENSES),
    [documents],
  );

  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);

  const toggleModal = () => setIsOpenDialog((prevState) => !prevState);

  const handleInvoiceDownload = (expense) => {
    try {
      expensesDocuments.forEach((doc) => {
        if (
          expense?.paymentDetails[expense?.paymentDetails?.length - 1].paymentDocuments?.includes(
            doc.metadata.fileId,
          )
        ) {
          handleDownload(doc.content, doc.metadata.documentName, doc.metadata.documentType);
        }
      });
    } catch (error) {
      notify.error(error);
    }
  };

  const handleDownload = (content, fileName, mimeType) => {
    try {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notify.error(error);
    }
  };

  const availableExpensesTypes = expensesTypes.map((type) => type.label);

  const handleFilterChange = (_, newValue) =>
    setSearchParams((searchParams) => {
      availableExpensesTypes.includes(newValue)
        ? searchParams.set(expenseTypeParam, newValue)
        : searchParams.delete(expenseTypeParam);
      return searchParams;
    });

  const expensesFilter = useMemo(() => {
    const expensesFilterURLString = searchParams.get(expenseTypeParam);
    const validFilter = availableExpensesTypes.includes(expensesFilterURLString)
      ? expensesFilterURLString
      : '';

    return validFilter;
  }, [searchParams, availableExpensesTypes]);

  const filteredExpenses = useMemo(
    () =>
      expensesFilter
        ? expenses?.filter((expense) =>
            expense.paymentDetails.some((detail) => detail.expenseType === expensesFilter),
          )
        : expenses,
    [expenses, expensesFilter],
  );

  return (
    <>
      <SubtitleDivider />
      <Box alignItems='center' mt={3}>
        {!expenses || expenses?.length === 0 ? (
          <>
            <Title>Expenses</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Expenses.
                </Typography>
                <Button onClick={toggleModal} variant='contained' color='primary'>
                  Add Details
                </Button>
              </Box>
            </Container>
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card sx={{ px: 2, py: 1 }} elevation={0}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid
                      item
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      gap={0.5}
                    >
                      {/* <IconButton sx={{ color: 'var(--backButton)' }}>
                        <KeyboardBackspaceIcon />
                      </IconButton> */}
                      <Title>Expenses</Title>
                    </Grid>
                    <Grid item>
                      <StyledAddButton startIcon={<StyledIcon />} onClick={toggleModal}>
                        Expenses
                      </StyledAddButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <MembersFilter title={'Expense'}/>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <ExpensesTable
                  expenses={filteredExpenses}
                  onExpenseClick={handleRowClick}
                  openRow={openRow}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {isOpenDialog && <AddUpdateExpensesDialog closeDialog={toggleModal} />}
    </>
  );
};

export default ExpensesSection;
