import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import { Title } from 'app/common/Typography';
import styles from './modules/RequestActions.module.css';
import { useState } from 'react';
import { handleAxiosError } from 'app/utils/helpers';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const initialValues = {
  declineReason: '',
};

const validationSchema = Yup.object({
  declineReason: Yup.string().required('Reason for decline is required'),
});

function RequestActions({ request, respondToRequest }) {
  const [state, setState] = useState({
    openModal: false,
    confirmationModal: false,
    isSubmitting: false,
    modalAction: null,
  });

  const toggleModal = (action = null) => {
    setState((prevState) => ({
      ...prevState,
      openModal: !prevState.openModal,
      modalAction: action,
    }));
  };

  const toggleConfirmationModal = () => {
    setState((prevState) => ({
      ...prevState,
      confirmationModal: !state.confirmationModal,
    }));
  };

  const handleYesProceed = async (values) => {
    const declineReason = values?.declineReason;
    try {
      setState((prevState) => ({ ...prevState, isSubmitting: true }));
      await respondToRequest(request, state.modalAction, declineReason);
      toggleConfirmationModal();
      toggleModal();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  return (
    <>
      <Box className={styles.respondButtons}>
        <LoadingButton
          className={`${styles.button} ${styles.declineButton}`}
          variant='contained'
          color='error'
          onClick={() => toggleModal('decline')}
        >
          Decline
        </LoadingButton>
        <LoadingButton
          className={`${styles.button} ${styles.acceptButton}`}
          variant='contained'
          color='success'
          onClick={() => toggleModal('accept')}
        >
          Accept
        </LoadingButton>
      </Box>
      <Modal open={state.openModal} onClose={toggleModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <Box display='flex' justifyContent='space-between' alignItems='center' p={2}>
            <Title sx={{ m: 0 }}>{state.modalAction}</Title>
            <IconButton onClick={toggleModal}>
              <Close />
            </IconButton>
          </Box>
          <ModalDivider />
          <Typography sx={{ p: 3, fontSize: '1rem', fontWeight: '450' }}>
            Are you sure want to {state.modalAction} this request?
          </Typography>
          <ModalDivider />
          <Box display='flex' justifyContent='flex-end' gap={2} p={2}>
            <LoadingButton color='error' variant='outlined' onClick={toggleModal}>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={() =>
                state.modalAction === 'accept' ? handleYesProceed() : toggleConfirmationModal()
              }
              loading={state.isSubmitting}
            >
              Yes Proceed
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      <Modal open={state.confirmationModal} onClose={toggleConfirmationModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '30%',
          }}
        >
          <Box py={1} px={2} justifyContent='space-between' alignItems='center' display='flex'>
            <Title>Confirmation</Title>
            <IconButton onClick={toggleConfirmationModal}>
              <Close />
            </IconButton>
          </Box>
          <ModalDivider />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleYesProceed}
          >
            {({ values, touched, errors, handleChange, handleBlur }) => (
              <Form>
                <Box p={2}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name='declineReason'
                    placeholder='Reason for decline'
                    value={values.declineReason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.declineReason && Boolean(errors.declineReason)}
                    helperText={touched.declineReason && errors.declineReason}
                  />
                </Box>
                <Box display='flex' justifyContent='flex-end' gap={2} p={2}>
                  <LoadingButton
                    color='error'
                    variant='outlined'
                    onClick={toggleConfirmationModal}
                    disabled={state.isSubmitting}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    type='submit'
                    loading={state.isSubmitting}
                  >
                    Yes Proceed
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}

export default RequestActions;
