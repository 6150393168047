import React, { useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Collapse,
  Container,
  TableRow,
  Table,
  Modal,
  Button,
  Icon
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span } from 'app/common/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import PaymentDetailsModal from './PaymentDetailsModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PaymentDetailsView from './PaymentDetailsView';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const cellStyle = {
  width: '14%',
  textAlign: 'left',
  padding: '8px',
};

const tableBodyCellStyle = {
  fontWeight: 500,
  fontSize: '14px',
  align: 'left',
  paddingLeft: '0.7%',
};
const GCPaymentDetails = ({ gcPayment, gcpaymentIndex }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedPD, setSelectedPD] = React.useState(null);
  const [openRow, setOpenRow] = useState(null);

  const handleClickOpen = () => {
    setSelectedPD(null);
    setOpen(true);
  }

  const handleClickUpdate = (payment) => {
    setSelectedPD(payment);
    setOpen(true);
  };

  const handleRowClick = (rowId) => {
    setOpenRow(openRow === rowId ? null : rowId);
  };

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <SubtitleDivider />
      {
        gcPayment[gcpaymentIndex]?.payments?.length === 0 ? (<>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>Payment Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }} >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }} variant="body1" color="#272937" gutterBottom>
                  No details added
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It seems you have not added any details related to Payment Details.
                </Typography>
                <Button onClick={handleClickOpen} variant="contained" color="primary">
                  Add Details
                </Button>
              </Box>
            </Container>
            <Modal open={open} onClose={handleClose}>
              <PaymentDetailsModal gcpayments={gcPayment} PaymentDetails={selectedPD} handleCloseModal={handleClose} gcpaymentIndex={gcpaymentIndex} />
            </Modal>
          </Box>
        </>) : (
          <>
            <Box
              marginLeft={3}
              marginRight={3}
              justifyContent={'space-between'}
              display={'flex'}
              alignItems={'center'}
            >

              <Title>Payment Details</Title>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Button onClick={handleClickOpen}>+ ADD</Button>
              </Box>
            </Box>
            <Modal open={open} onClose={handleClose}>
              <PaymentDetailsModal gcpayments={gcPayment} PaymentDetails={selectedPD} handleCloseModal={handleClose} gcpaymentIndex={gcpaymentIndex} />
            </Modal>
            <Box
              sx={{
                marginLeft: 3,
                marginRight: 3,
                marginTop: 1,
                marginBottom: 1,
                overflowY: 'auto',
                border: '2px solid #E5E5E7',
                maxHeight: '300px',
                position: 'relative',
              }}
            >
              <Table marginLeft={3} marginRight={3}>
                <TableHead sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: '#F2F9FC',
                  maxWidth: '100%'
                }}>
                  <TableRow color='#64748B' >
                    <TableCell sx={{ ...cellStyle, width: '15%', paddingLeft: '1.5%' }}>S.NO.</TableCell>
                    <TableCell sx={cellStyle}>TOTAL AMOUNT</TableCell>
                    <TableCell sx={cellStyle}>PAID</TableCell>
                    <TableCell sx={cellStyle}>BALANCE</TableCell>
                    <TableCell sx={cellStyle}>PAID BY</TableCell>
                    <TableCell sx={cellStyle}>PAID TO</TableCell>
                    <TableCell sx={{ ...cellStyle, width: '15%' }}>PAID TYPE</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    gcPayment[gcpaymentIndex]?.payments?.map((payment, index) => (
                      <>
                        <TableRow hover key={index} sx={{ cursor: 'pointer', color: '#272937' }} onClick={() => handleRowClick(payment.paymentId)}>
                          <TableCell sx={{ ...tableBodyCellStyle, paddingLeft: '1%' }}>
                            <div style={{
                              fontWeight: 100,
                              fontSize: '14px',
                              align: 'left',
                              width: '60px',
                              color: '#4F90F0'
                            }}>
                              <span >{openRow === payment.paymentId ? <KeyboardArrowUpIcon sx={{ marginBottom: '-7px' }} /> : <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />}</span>
                              <span style={{ textDecoration: 'underline' }} >{(index + 1).toString().padStart(2, '0')}</span>
                            </div>
                          </TableCell>

                          <TableCell sx={tableBodyCellStyle}>
                            {payment?.paymentHistory[payment?.paymentHistory.length - 1]?.totalAmount} {/* {contractData.contractorName} */}
                          </TableCell>

                          <TableCell sx={tableBodyCellStyle}>
                            {payment?.paymentHistory[payment?.paymentHistory.length - 1]?.amountPaid}
                          </TableCell>
                          <TableCell sx={tableBodyCellStyle}>
                            {/* {payment.totalPayment - payment.amountPaid} */}
                            {payment?.paymentHistory[payment?.paymentHistory.length - 1]?.balance || '00'}
                          </TableCell>
                          <TableCell sx={tableBodyCellStyle}>
                            {payment?.paymentHistory[payment?.paymentHistory.length - 1]?.paidBy}
                          </TableCell>

                          <TableCell sx={tableBodyCellStyle}>
                            {payment?.paymentHistory[payment?.paymentHistory.length - 1]?.paidTo}
                          </TableCell>
                          <TableCell sx={tableBodyCellStyle}>
                            {payment?.paymentHistory[payment?.paymentHistory.length - 1]?.paymentType}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
                            <Collapse in={openRow === payment?.paymentId} timeOut="auto" unmountOnExit>
                              <Box>
                                <PaymentDetailsView payment={payment} gcPayment={gcPayment} gcpaymentIndex={gcpaymentIndex} />
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>

                    ))
                  }
                </TableBody>
              </Table>
            </Box>
          </>)
      }
    </>
  )
}

export default GCPaymentDetails;