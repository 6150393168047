import Alert from '@mui/material/Alert';

function CustomToast({ message, severity = 'error' }) {
  return (
    <Alert
      severity={severity}
      sx={{ '.MuiAlert-message': { paddingRight: '5px' } }}
      variant='filled'
    >
      {message}
    </Alert>
  );
}

export default CustomToast;
