import React from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Button,
  Container,
  Typography,
  Icon,
  Card,
  Divider,
  Grid,
} from '@mui/material';
import { SideNavMenu } from 'app/components/index.js';
import { Span, StyledIcon } from 'app/common/Typography.js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GeneralContractorAddEdit from './GeneralContractorAddEdit';
import { useProject } from 'app/contexts/ProjectContext';
import { Title } from 'app/common/Typography';
import { useProjectGCAll } from './hooks/useProjectGCAll';
import GeneralContractorView from './GeneralContractorView';
import { StyledCardTitle } from 'app/views/dashboard/profile/StyleComponents';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';
import GeneralContractorTable from './GeneralContractorTable';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const GeneralContactorSection = () => {
  const { projectId } = useProject();
  const { data: generalContractors } = useProjectGCAll(projectId);
  const [selectedContractorIndex, setSelectedContractorIndex] = React.useState(null);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleSelectGC = (contractorIndex) => setSelectedContractorIndex(contractorIndex);

  const selectedContractor = generalContractors?.[selectedContractorIndex];

  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <>
          {(!generalContractors || (Array.isArray(generalContractors) && generalContractors.length === 0)) && (
              <>
                <Box
                  sx={{ margin: '20px 38px 20px 20px' }}
                  display='flex'
                  justifyContent='space-between'
                >
                  <StledCardTitle>General Contractor</StledCardTitle>
                  <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                    General Contractor
                  </StyledAddButton>
                </Box>
                <Divider />
              </>
            )}
          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {!generalContractors || generalContractors?.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No General Contractor Available
                </Typography>
              </div>
            ) 
              : selectedContractor ? (
                <GeneralContractorView
                  contractor={selectedContractor}
                  handleBack={() => handleSelectGC(null)}
                />
              ) : (
                <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <Card sx={{ px: 2, py: 1 }} elevation={0}> */}
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid
                        item
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Title>General Contractor Details</Title>
                      </Grid>
                      <Grid item>
                        <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                        General Contractor
                        </StyledAddButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TableFilter title={'General Contractor'} />
                    </Grid>
                    {/* </Card> */}
                  </Grid>

                  <Grid item xs={12}>
                    <GeneralContractorTable
                      generalContractors={generalContractors}
                    />
                  </Grid>
                </Grid>
              </>
                // <>
                //   <Box
                //     marginLeft={3}
                //     marginRight={3}
                //     marginTop={1}
                //     justifyContent={'space-between'}
                //     display={'flex'}
                //     alignItems={'center'}
                //   >
                //     <Title sx={{ marginLeft: '0px' }}>General Contractor Details</Title>
                //     <Box display='flex' justifyContent='space-between' alignItems='center'>
                //       <Button onClick={toggleDialog}>+ ADD</Button>
                //     </Box>
                //   </Box>
                //   <Box
                //     overflow='auto'
                //     border={'2px solid #E5E5E7'}
                //     marginLeft={3}
                //     marginRight={3}
                //     marginTop={1}
                //     marginBottom={1}
                //   >
                //     <ProductTable>
                //       <TableHead style={{ backgroundColor: 'var(--tableHead-bg-color)' }}>
                //         <TableRow color='var(--tableHead-row-color)'>
                //           <TableCell sx={{ px: 2, color: 'var(--tableHead-row-color)' }}>S.NO</TableCell>
                //           <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                //             CONTRACTOR NAME
                //           </TableCell>
                //           <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                //             EMAIL ADDRESS
                //           </TableCell>
                //           <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
                //             PHONE NUMBER
                //           </TableCell>
                //           <TableCell
                //             sx={{ px: 0, width: '5%', color: 'var(--tableHead-row-color)' }}
                //           ></TableCell>
                //         </TableRow>
                //       </TableHead>
                //       <TableBody>
                //         {generalContractors?.map((contractor, index) => (
                //           <TableRow key={contractor.id} hover>
                //             <TableCell
                //               align='left'
                //               sx={{
                //                 px: 2,
                //                 textTransform: 'capitalize',
                //                 fontWeight: 500,
                //                 fontSize: '14px',
                //                 color: 'var(--tableBody-row-color)',
                //               }}
                //             >
                //               {`${(index + 1).toString().padStart(2, '0')}`}
                //             </TableCell>
                //             <TableCell
                //               sx={{
                //                 px: 0,
                //                 fontWeight: 500,
                //                 fontSize: '14px',
                //                 color: 'var(--tableBody-row-color)',
                //               }}
                //               align='left'
                //             >
                //               {contractor.contractorDetails?.contractName}
                //             </TableCell>
                //             <TableCell
                //               sx={{
                //                 px: 0,
                //                 fontWeight: 500,
                //                 fontSize: '14px',
                //                 color: 'var(--tableBody-row-color)',
                //               }}
                //               align='left'
                //             >
                //               {contractor.contractorDetails?.emailId}
                //             </TableCell>
                //             <TableCell
                //               sx={{
                //                 px: 0,
                //                 fontWeight: 500,
                //                 fontSize: '14px',
                //                 color: 'var(--tableBody-row-color)',
                //               }}
                //               align='left'
                //             >
                //               {contractor.contractorDetails?.phoneNum}
                //             </TableCell>
                //             <TableCell align='left' sx={{ color: 'var(--tableBody-row-color)' }}>
                //               <SideNavMenu
                //                 menuButton={
                //                   <UserMenu>
                //                     <MoreVertIcon />
                //                   </UserMenu>
                //                 }
                //               >
                //                 <StyledItem onClick={() => handleSelectGC(index)}>
                //                   <Icon>
                //                     <VisibilityIcon />
                //                   </Icon>
                //                   <Span>View</Span>
                //                 </StyledItem>
                //               </SideNavMenu>
                //             </TableCell>
                //           </TableRow>
                //         ))}
                //       </TableBody>
                //     </ProductTable>
                //   </Box>
                // </>
              )}
            </Box>
            {isOpenDialog && <GeneralContractorAddEdit closeDialog={toggleDialog} />}
            {/* )}
          </Box> */}
        </>
      </Card>
      </>
  );
};

export default GeneralContactorSection;
