import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box className='not-found-root flex-box'>
      <Box className='justify-box flex-box'>
        <img src='/assets/images/illustrations/404.svg' alt='' className='img' />
        <Button
          color='primary'
          variant='contained'
          sx={{ textTransform: 'capitalize' }}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
