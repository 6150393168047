import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Title } from 'app/common/Typography';
import { convertUTCDateToLocalDate, formatCurrency } from 'app/utils/helpers';
import Divider from '@mui/material/Divider';

const color = {
  PENDING: 'orange',
  ACCEPTED: 'green',
  DECLINED: 'red',
};

function UserInvestmentsHistory({ paymentDetails }) {
  const [showHistory, setShowHistory] = useState(false);

  return (
    <Box sx={{ mt: 2 }}>
      <Box
        display='flex'
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={() => setShowHistory(!showHistory)}
      >
        <Title variant='h6' sx={{ my: 0 }}>
          Payment History
        </Title>
        <IconButton
          sx={{
            transform: !showHistory ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 0.3s',
            ml: 1,
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>

      <Collapse in={showHistory} timeout='auto' unmountOnExit>
        <Box
          sx={{
            mt: 2,
            borderRadius: 1,
            bgcolor: 'background.paper',
            boxShadow: 1,
          }}
        >
          {paymentDetails?.map((payment, index) => (
            <>
              <Box key={payment.investmentSource + index} p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Typography variant='subtitle1' color='textSecondary'>
                      PAYMENT DATE
                    </Typography>
                    <Typography variant='subtitle1' fontWeight={500}>
                      {convertUTCDateToLocalDate(payment.paymentDate).toLocaleString('en-US', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Typography variant='subtitle1' color='textSecondary'>
                      INVESTED AMOUNT
                    </Typography>
                    <Typography variant='subtitle1' color='green' fontWeight={500}>
                      {formatCurrency(payment.investedAmount)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography variant='subtitle1' color='textSecondary'>
                      INVESTED SHARES
                    </Typography>
                    <Typography variant='subtitle1' color='primary' fontWeight={500}>
                      {payment.investedShares}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography variant='subtitle1' color='textSecondary'>
                      PAYMENT MODE
                    </Typography>
                    <Typography variant='subtitle1' fontWeight={500}>
                      {payment.paymentMode}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography variant='subtitle1' color='textSecondary'>
                      STATUS
                    </Typography>
                    <Typography variant='subtitle1' color={color[payment.status]} fontWeight={500}>
                      {payment.status}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {index < paymentDetails.length - 1 && <Divider />}
            </>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
}

export default UserInvestmentsHistory;
