import React, { useState, useEffect } from 'react';
import { Box, Grid, InputAdornment, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  StyledBox1,
  StyledModalTitle,
  StyledBox2,
  StyledCrossIcon,
  StyledTextField,
  StyledLabel,
  StyledBox4,
  ModalButtonCancle,
  ModalButtonSave,
  CountryCodeField,
  StyledVerticalDivider,
  VerifyButton,
  OtpBox,
  ContactTextBox,
  OtpInput,
  ExpiryText,
  Code,
} from '../StyleComponents';
import { countryCodes } from 'app/utils/constant';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { useProfileEditDetails } from '../hooks/useProfileEditDetails';
import { handleAxiosError } from 'app/utils/helpers';

const validationSchema = Yup.object().shape({
  countryCode: Yup.string().required('Country code is required'),
  mobile: Yup.string()
    .matches(/^\d{10,15}$/, 'Entered a valid contact number')
    .required('Contact number is required'),
  otp: Yup.string()
    .matches(/^\d{6}$/, 'OTP must be 6 digits')
    .when('showOTP', {
      is: true,
      then: Yup.string().required('OTP is required'),
    }),
});

const ContactNumberModal = ({ handleCloseModal }) => {
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const { user, userNameJWT } = useUserAuth();
  const { mutate: userEditDetails } = useProfileEditDetails(userNameJWT);

  useEffect(() => {
    if (showOTP && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [showOTP, timeLeft]);

  const initialValues = {
    countryCode: '+1',
    mobile: '',
    otp: '',
  };

  const handleFormSubmit = async (values) => {
    console.log('value', values);
    try {
      if (showOTP) {
        setLoading(true);
        const mobile = `${values.countryCode}${values.mobile}`;
        const updatedValues = {
          mobile: mobile,
        };
        await userEditDetails(updatedValues);
        handleCloseModal();
      } else {
        console.log('Submitted Contact Number:', values);
        setShowOTP(true);
      }
    } catch (error) {
      console.error('Error submitting contact number:', error);
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledBox1>
      <StyledBox2>
        <StyledModalTitle>Add Contact Number</StyledModalTitle>
        <StyledCrossIcon onClick={handleCloseModal}>
          <CloseIcon />
        </StyledCrossIcon>
      </StyledBox2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleChange, setFieldValue, handleSubmit, errors, touched, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ margin: '0 2% 0 2%' }}>
              <StyledLabel>Contact Number</StyledLabel>
              <StyledTextField
                type='text'
                name='mobile'
                value={values.mobile}
                onChange={handleChange}
                variant='outlined'
                fullWidth
                error={touched.mobile && Boolean(errors.mobile)}
                helperText={touched.mobile && errors.mobile}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <CountryCodeField
                        select
                        name='countryCode'
                        value={values.countryCode}
                        onChange={(e) => setFieldValue('countryCode', e.target.value)}
                        variant='standard'
                        InputProps={{
                          disableUnderline: true,
                        }}
                      >
                        {countryCodes.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            <Code>{option.code}</Code>
                            <Code style={{ marginLeft: '10px', marginRight: '10px' }}>
                              {option.country}
                            </Code>
                          </MenuItem>
                        ))}
                      </CountryCodeField>
                      <StyledVerticalDivider orientation='vertical' />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <VerifyButton
                        variant='text'
                        size='small'
                        disabled={!values.mobile || errors.mobile}
                        onClick={() => setShowOTP(true)}
                      >
                        Verify
                      </VerifyButton>
                    </InputAdornment>
                  ),
                }}
              />

              {showOTP && (
                <OtpBox>
                  <ContactTextBox
                    sx={{ marginTop: '29px', marginBottom: '10px', fontSize: '30px' }}
                  >
                    Verify Number
                  </ContactTextBox>
                  <Box sx={{ fontSize: '16px', fontWeight: '500', color: '#5F6368' }}>
                    We have sent you a 6-digit login code to
                  </Box>
                  <ContactTextBox sx={{ fontSize: '16px', marginBottom: '32px' }}>
                    {values.mobile}
                  </ContactTextBox>
                  <Box
                    sx={{
                      fontSize: '16px',
                      fontWeight: '500',
                      color: '#5F6368',
                      marginBottom: '14px',
                    }}
                  >
                    Please enter the 6-digit code:
                  </Box>
                  <Grid container>
                    {[...Array(6)].map((_, index) => (
                      <Grid item key={index}>
                        <OtpInput
                          type='text'
                          inputProps={{ maxLength: 1 }}
                          value={values.otp[index] || ''}
                          isFilled={Boolean(values.otp[index])}
                          onChange={(e) => {
                            const newOtp = values.otp.split('');
                            newOtp[index] = e.target.value;
                            if (e.target.value && index < 5) {
                              document.getElementById(`otp-${index + 1}`).focus();
                            }
                            setFieldValue('otp', newOtp.join(''));
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Backspace' && !values.otp[index] && index > 0) {
                              document.getElementById(`otp-${index - 1}`).focus();
                            }
                          }}
                          id={`otp-${index}`}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <ExpiryText>Expires in {timeLeft}s</ExpiryText>

                  <StyledBox4 sx={{ marginRight: '10px', marginTop: '14rem' }}>
                    <ModalButtonCancle
                      variant='outlined'
                      onClick={handleCloseModal}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </ModalButtonCancle>
                    <ModalButtonSave type='submit' loading={isSubmitting}>
                      Verify
                    </ModalButtonSave>
                  </StyledBox4>
                </OtpBox>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </StyledBox1>
  );
};

export default ContactNumberModal;
