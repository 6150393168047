import { useProject } from 'app/contexts/ProjectContext';
import { requestStatusMessages, requestsTypes } from 'app/utils/constant';
import RequestsList from 'app/views/dashboard/Requests/RequestsList';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import Typography from '@mui/material/Typography';

function RequestsSection() {
  const { requests, respondToRequest } = useProject();
  const currentType = 'PENDING';

  const filteredAndMappedRequests = useMemo(
    () =>
      requests
        .filter(
          (request) =>
            request.status === currentType &&
            (request.invitationType === requestsTypes.INVESTMENT_MAIL ||
              request.invitationType === requestsTypes.PAYMENT_REQUEST_MAIL),
        )
        .map((request) => ({
          ...request,
          projectTitle: 'this project',
        })),
    [requests, currentType],
  );

  return (
    <Box sx={{ overflowY: 'auto' }}>
      {filteredAndMappedRequests.length > 0 ? (
        <RequestsList requests={filteredAndMappedRequests} respondToRequest={respondToRequest} />
      ) : (
        <Typography variant='h6'>{requestStatusMessages[currentType].message}</Typography>
      )}
    </Box>
  );
}

export default RequestsSection;
