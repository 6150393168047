import React, { useState } from 'react';
import { Grid, Typography, Box, Card, Divider } from '@mui/material';
import SaleView from './SaleView';
import LeadDeleteConfirmation from 'app/common/LeadsDeleteConfirmation';
import BuyerAddEdit from './BuyerAddEdit';
import { useProjectBuildingUnitSaleDelete } from './hooks/useProjectBuildingUnitSaleDelete';
import { useProject } from 'app/contexts/ProjectContext';
import { SideNavMenu } from 'app/components';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import { EditIcon, ViewIcon } from 'app/common/icons';
import { Span } from 'app/common/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaleAddEdit from './SaleAddEdit';
import SaleDetailsView from './SaleDetailsView';

const SaleCard = ({ saleId, unitDetails, selectedBuilding, selectedUnit, viewModalState, handleViewSaleDetails }) => {
  const [openModal, setOpenModal] = useState(false);

  const { projectId } = useProject();
  const { mutateAsync: deleteSale } = useProjectBuildingUnitSaleDelete(projectId);

  const saleData = unitDetails?.saleDetails;
  const buyerData = unitDetails?.buyerDetails;

  const handleOpenModal = (action) => setOpenModal(action);
  const handleCloseModal = () => setOpenModal(null);

  const handleDelete = async () => await deleteSale(saleId);

  return (
    <div>
        <Card style={styles.saleCard}>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent='space-between'
              color='#565960'
              alignItems='center'
              paddingX={2}
              paddingY={1}
            >
              <Typography variant='body' color={'#17181B'} fontSize={'16px'}>
                Sale Space: <strong>{saleData?.saleSpace} Sq fts</strong>
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <SideNavMenu
                  menuButton={
                    <UserMenu>
                      <MoreVertIcon />
                    </UserMenu>
                  }
                >
                  <StyledItem onClick={() => {handleOpenModal('view');handleViewSaleDetails(saleId)}}>
                    <ViewIcon />
                    <Span style={{ marginLeft: '5px' }}>View</Span>
                  </StyledItem>
                  <StyledItem onClick={() => handleOpenModal('edit')}>
                    <EditIcon />
                    <Span style={{ color: '#645BBB', marginLeft: '5px' }}>Edit</Span>
                  </StyledItem>
                  <StyledItem onClick={() => handleOpenModal('delete')}>
                    <img
                      src='/assets/images/Delete_icon.svg'
                      alt=''
                      style={{ height: '13px', marginBottom: '1px' }}
                    />
                    <Span style={{ color: '#CE443C', marginLeft: '5px' }}>Delete</Span>
                  </StyledItem>
                </SideNavMenu>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ width: '100%' }} />
          <Grid item xs={12}>
            <Grid
              container
              direction='column' // Stack items vertically
              justifyContent='flex-start'
              alignItems='flex-start'
              paddingX={2}
              paddingBottom={2}
            >
              <Typography
                sx={{
                  color: '#17181B',
                  fontSize: '14px',
                }}
                variant='body2'
              >
                <div>
                  <span>
                    {saleData?.salePrice && <strong>${saleData?.salePrice}</strong>}
                    {saleData?.salePrice && ' Sale Price'}
                    {saleData?.salePrice && <span style={{ margin: '0 8px 0px 10px' }}> • </span>}

                    {saleData?.closingCost && <strong>{saleData?.closingCost}</strong>}
                    {saleData?.closingCost && ' Closing Cost'}
                    {saleData?.closingCost && <span style={{ margin: '0 8px 0px 10px' }}> • </span>}

                    {saleData?.titlePolicyCost && <strong>${saleData?.titlePolicyCost}</strong>}
                    {saleData?.titlePolicyCost && ' Title Policy Cost'}
                  </span>
                </div>
              </Typography>

              <Typography
                fontSize='13px'
                fontWeight='500'
                variant='body2'
                backgroundColor='#82B485'
                color='#fff'
                borderRadius='5px'
                p={1}
                marginTop={1}
                display='inline-block'
              >
                  Total Commission: {Number(saleData?.buyerSaleCommission) + Number(saleData?.sellerSaleCommission)} i.e.{' '}
                  Buyer Commission: {saleData?.buyerSaleCommission} & Seller Commission: {saleData?.sellerSaleCommission}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ width: '100%' }} />
          <Grid item xs={6} sx={{marginLeft: '20px'}}>
            <Typography variant='subtitle2'>Buyer Details</Typography>
            <Typography variant='body2' fontWeight={600}>{buyerData?.buyerName }</Typography>
            <Typography variant='body2'>
              {buyerData.phoneNo}
            </Typography>
            <Typography variant='body2'>
              {buyerData.email}
            </Typography>
          </Grid>
           <Divider orientation='vertical' flexItem sx={{ borderRightWidth: 2, borderColor: '#DADCE0' }}/>
          <Grid item xs={5}>
            <Typography variant='subtitle2'>Contact Person & Address</Typography>
            <Typography variant='body2' fontWeight={600}>{buyerData?.contactPerson}</Typography>
            <Typography variant='body2'>
              {buyerData?.address || 'No Address'}, {buyerData?.city}, {buyerData?.state}, {buyerData?.country}, {buyerData?.zipCode}
            </Typography>
          </Grid>
          {/* <Divider sx={{ width: '100%'}} />
          <Grid item xs={12} sx={{marginLeft: '20px'}}>
            <Typography variant='subtitle2'>Comments</Typography>
            <Typography
              p={1}
              paddingLeft={0}
              variant='body2'
              fontWeight='500'
            >
              {saleData?.comments || 'No Comments Added'}
            </Typography>
          </Grid> */}
        </Grid>
      </Card>
      <SaleAddEdit
        open={openModal === 'edit'}
        onClose={handleCloseModal}
        saleData={saleData}
        buyerData={buyerData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
        saleId={saleId}
      />
      <LeadDeleteConfirmation
        name={'Sale Space'}
        from={`from building ${selectedBuilding?.buildingName}`}
        open={openModal === 'delete'}
        onClose={handleCloseModal}
        handleSubmit={handleDelete}
      />
    </div>
  );
};

const styles = {
  saleCard: {
    border: '1px solid #e0e0e0',
    borderRadius: 8,
    backgroundColor: '#F8F9FA',
  },
  section: {
    borderTop: '1px solid #e0e0e0',
    paddingTop: 8,
    marginTop: 8,
    width: '100%',
  },
};

export default SaleCard;
