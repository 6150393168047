import React, { useState } from 'react';
import { Box, Typography, styled, IconButton } from '@mui/material';
import GCManagersView from './GCManagersView';
import GCChangeOrderView from './GCChangeOrderView';
import GCPaymentsView from './GCPaymentsView';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GeneralContractorAddEdit from './GeneralContractorAddEdit';
import { StyledEditIcon, Title } from 'app/common/Typography';
import { GCManagerKeys } from 'app/utils/constant';
import { EditIcon } from 'app/common/icons';

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '18%',
  marginTop: '-1px',
  rowGap: '20px',
  borderRadius: '5px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'var(--key-text)',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: 'var(--value-text)',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const GeneralContractorView = ({ contractor, handleBack }) => {
  const [isOpenDialog, setOpenDialog] = useState(false);

  if (!contractor) {
    return <Typography>No contractor data available</Typography>;
  }

  const contractorDetails = contractor?.contractorDetails;

  const contract = contractorDetails
  ? [
      { key: 'Contractor Name', value: contractorDetails.contractName || '' },
      { key: 'LLC Name', value: contractorDetails.llcName || '' },
      { key: 'Email Address', value: contractorDetails.emailId || '' },
      { key: 'Phone Number', value: contractorDetails.phoneNum || '' },
      {
        key: 'Address',
        value: [
          [contractorDetails?.address || '', contractorDetails?.city || ''].filter(Boolean).join(', '),
          [contractorDetails?.state || '', contractorDetails?.zipCode || '', contractorDetails?.country || '']
            .filter(Boolean)
            .join(', '),
        ]
          .filter((line) => line.trim() !== '')
          .join('\n'),
      },
    ]
  : [];


  const toggleDialog = () => setOpenDialog((prevState) => !prevState);

  return (
    <>
      <div className='viewlead'>
        <IconButton style={{ margin: '1%' }} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>

        <Box marginLeft={3} marginRight={3}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Title>General Contractor</Title>
              <StyledEditIcon
                  variant='outlined'
                  startIcon={<EditIcon />}
                  onClick={toggleDialog}
                  style={{ marginLeft: '20px' }}
                >
                  General Contractor
              </StyledEditIcon>
            </Box>
            <Box marginTop={0} marginLeft={2}>
              <InfoContainer>
                {contract?.map(({ key, value }, index) => (
                  <Box key={index}>
                    <Key>{key}</Key>
                    <ValueContainer>
                      <Value>{value}</Value>
                    </ValueContainer>
                  </Box>
                ))}
              </InfoContainer>
            </Box>
          </Box>
        </Box>
        <div style={{ marginTop: '2vh' }}>
          <GCManagersView contractor={contractor} managerKey={GCManagerKeys.gcManager} />
        </div>
        <div style={{ marginTop: '2vh' }}>
          <GCManagersView contractor={contractor} managerKey={GCManagerKeys.gcSiteManager} />
        </div>
        <div style={{ marginTop: '2vh' }}>
          <GCChangeOrderView contractor={contractor} />
        </div>
        <div style={{ marginTop: '2vh' }}>
          <GCPaymentsView contractor={contractor} />
        </div>
      </div>
      {isOpenDialog && (
        <GeneralContractorAddEdit contractor={contractor} closeDialog={toggleDialog} />
      )}
    </>
  );
};

export default GeneralContractorView;
