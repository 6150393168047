import { themes } from '../Theme/initThemes';
import layoutSettings from './BasicLayout/LayoutSettings';

export const LayoutSettings = {
  activeLayout: 'layout',
  activeTheme: 'blue',
  perfectScrollbar: false,

  themes: themes,
  layoutSettings,

  secondarySidebar: {
    show: true,
    open: false,
    theme: 'slateDark1',
  },
};
