import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { useMemo } from 'react';

const ProductTable = styled(Table)(({ theme }) => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none', color: '#272937', fontSize: '0.875rem', fontWeight: 500 },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  fontSize: '1rem',
  '& th': {
    fontSize: '0.875rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
    '& th': {
      fontSize: '0.8125rem',
    },
    '& td': {
      fontSize: '0.8125rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75rem',
    '& th': {
      fontSize: '0.75rem',
    },
    '& td': {
      fontSize: '0.75rem',
    },
  },
}));

const TableHeader = () => (
  <TableHead bgcolor='#F2F9FC'>
    <TableRow>
      {['S.NO.', 'MEMBER NAME', 'ROLES', 'MOBILE NUMBER', 'EMAIL ADDRESS', 'STATUS', ''].map(
        (header, index) => (
          <TableCell
            key={index}
            sx={{ color: '#64748B', px: index === 0 ? 2 : 'inherit' }}
            colSpan={index === 0 || index > 4 ? 1 : 3}
          >
            {header}
          </TableCell>
        ),
      )}
    </TableRow>
  </TableHead>
);

function MembersTable({ members, isOnHoldOrCancelled, handleMenuOpen }) {
  const renderedTable = useMemo(
    () => (
      <TableBody>
        {members.map((member, index) => (
          <TableRow key={index} hover>
            <TableCell>{`${(index + 1).toString().padStart(2, '0')}`}</TableCell>
            <TableCell colSpan={3}>{member.memberName}</TableCell>
            <TableCell colSpan={3}>
              {member.userRoles.map((role, roleIndex, array) => (
                <Chip
                  key={roleIndex}
                  label={role}
                  sx={{ mt: 0.5, mr: roleIndex < array.length - 1 ? 0.5 : 0 }}
                />
              ))}
            </TableCell>
            {[member.phone, member.email, member.status].map((value, cellIndex) => (
              <TableCell key={cellIndex} colSpan={cellIndex < 2 ? 3 : 1}>
                {value}
              </TableCell>
            ))}
            <TableCell align='center'>
              <IconButton
                aria-label='more'
                onClick={(event) => handleMenuOpen(event, member)}
                disabled={isOnHoldOrCancelled}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    ),
    [members, isOnHoldOrCancelled, handleMenuOpen],
  );

  return (
    <div>
      <Box overflow='auto' m={2} mt={0} border='2px solid #E5E5E7'>
        <ProductTable>
          <TableHeader />
          {renderedTable}
        </ProductTable>
      </Box>
    </div>
  );
}

export default MembersTable;
