import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { handleAxiosError } from 'app/utils/helpers';
import {
  InactiveModalButtonBox,
  InactiveModalCancleBtn,
  InactiveModalInactiveBtn,
  InactiveModalSubTitle,
  InactiveModalTitle,
  OrgCardModalBox2,
  OrgStatusModal,
  StyledCardBox1,
  StyledCrossIcon,
} from 'app/views/dashboard/profile/StyleComponents';
import { useState } from 'react';

const LeadDeleteConfirmation = ({ name, from, open, onClose, handleSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleYesProceed = async () => {
    try {
      setIsSubmitting(true);
      await handleSubmit();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <StyledCardBox1>
      <Modal open={open} onClose={onClose}>
        <OrgStatusModal>
          <OrgCardModalBox2>
            <InactiveModalTitle>Delete {name}</InactiveModalTitle>
            <StyledCrossIcon onClick={onClose}>
              <CloseIcon />
            </StyledCrossIcon>
          </OrgCardModalBox2>
          <InactiveModalSubTitle>Do you want to delete {from} {name}?</InactiveModalSubTitle>
          <InactiveModalButtonBox>
            <InactiveModalCancleBtn onClick={onClose}>Cancel</InactiveModalCancleBtn>
            <InactiveModalInactiveBtn onClick={handleYesProceed} loading={isSubmitting}>
              Delete
            </InactiveModalInactiveBtn>
          </InactiveModalButtonBox>
        </OrgStatusModal>
      </Modal>
    </StyledCardBox1>
  );
};

export default LeadDeleteConfirmation;
