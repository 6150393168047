import React, { useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import LeadAddBuilding from './LeadAddBuilding';
import { Title } from 'app/common/Typography';
import { LoadingButton } from '@mui/lab';
import { useProject } from 'app/contexts/ProjectContext';
import LeadBuildingDetails from './LeadBuildingDetails';

const LeadLeaseSales = () => {
  const [buildings, setBuildings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [view, setView] = useState('list'); // Track the current view
  const [selectedBuilding, setSelectedBuilding] = useState(null); // Track the selected building
  const [buildingIndex, setBuildingIndex] = useState(null);
  const { leasesales } = useProject();

  const handleViewUnits = (building, Index) => {
    setSelectedBuilding(building);
    setBuildingIndex(Index);
    setView('details');
  };

  const handleBackToBuildings = () => {
    setView('list');
    setSelectedBuilding(null);
  };

  const handleSaveBuilding = (newBuilding) => {
    if (newBuilding && newBuilding.name) {
      setBuildings([...buildings, newBuilding]);
      setShowModal(false);
    } else {
      console.error('Invalid building data:', newBuilding);
    }
  };

  return (
    <div
      style={{
        height: '56vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {view === 'list' ? (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px',
            }}
          >
            <h2 style={{ margin: 0 }}>Buildings</h2>
            <LoadingButton variant='text' color='primary' onClick={() => setShowModal(true)}>
              + Add Building
            </LoadingButton>
          </Box>

          <Box
            style={{
              flex: 1,
              padding: '20px',
              overflowX: 'auto', // Enables horizontal scrolling
            }}
          >
            {!leasesales ? (
              <div style={{ textAlign: 'center' }}>
                <Typography fontSize='16px' fontWeight='600' align='center'>
                  No Buildings Available
                </Typography>
                <Typography fontSize='14px' fontWeight='400' align='center'>
                  It seems you have not added any buildings
                </Typography>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '20px',
                  maxWidth: '100%', // Limits the width of the row
                }}
              >
                {leasesales &&
                  leasesales.map((building, index) => (
                    <Card
                      key={building.id}
                      style={{
                        border: '1px solid #E3EDF5',
                        borderRadius: '5px',
                        padding: '10px',
                        width: '300px',
                        flex: '1 1 calc(33.33% - 20px)', // Ensures three cards per row with spacing
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <Title style={{ margin: 0, fontSize: '16px', color: '#272937' }}>
                          {building.buildingName}
                        </Title>
                        <img
                          src='/assets/images/units.png'
                          alt='Building'
                          style={{ width: '50px', height: '50px' }}
                        />
                      </div>
                      <Typography
                        variant='subtitle1'
                        style={{ margin: '0px', color: '#888', fontSize: '14px' }}
                      >
                        Created on {new Date(building.createdDate).toLocaleDateString()}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        style={{
                          marginTop: '10px',
                          color: '#0EB730',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        No of Units: {building?.units?.length}
                      </Typography>
                      <LoadingButton
                        style={{
                          padding: '5px 15px',
                          backgroundColor: '#4F90F0',
                          color: '#fff',
                          border: 'none',
                          borderRadius: '3px',
                          marginTop: '10px',
                        }}
                        onClick={() => handleViewUnits(building, index)}
                      >
                        View Units
                      </LoadingButton>
                    </Card>
                  ))}
              </div>
            )}
          </Box>
        </>
      ) : (
        <LeadBuildingDetails
          selectedBuilding={selectedBuilding}
          setSelectedBuilding={setSelectedBuilding}
          handleBackToBuildings={handleBackToBuildings}
          buildingIndex={buildingIndex}
        />
      )}

      <LeadAddBuilding
        open={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleSaveBuilding}
      />
    </div>
  );
};

export default LeadLeaseSales;
