import React, { useState, useEffect } from "react";
import { Box, CardContent, Modal, CircularProgress } from "@mui/material";
import { StyledCard, StyledBox, LoadingCircle, TextBox, NoOrgTitle, StyledTitle, StyledDivider, StyledIcon, StyleAddOrgButton } from "../StyleComponents";
import OrganizationModal from "./OrganizationModal";
import OrganizationDetailCard from "./OrganizationDetailCard";
import { useUserAuth } from "app/hooks/userUserAuth";
import { useProfileLLC } from "../hooks/useProfileLLC";

const OrganizationTab = () => {
  const [openModal, setOpenModal] = useState(false);
  const { userNameJWT } = useUserAuth();
  const { data: llcdata, isLoading, isError } = useProfileLLC(userNameJWT);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <StyledCard
        variant="outlined"
      >
        <CardContent>
          <StyledBox>
            <StyledTitle>
              Organization(s)
            </StyledTitle>
            <StyleAddOrgButton
              onClick={handleOpenModal}
              startIcon={
                <StyledIcon />
              }
            >
              Organization
            </StyleAddOrgButton>
          </StyledBox>
          <StyledDivider />
          <Box>
            {isLoading ? (
              <LoadingCircle
              >
                <CircularProgress />
              </LoadingCircle>
            ) : llcdata && llcdata.length > 0 ? (
              llcdata.map((data) => (
                <React.Fragment key={data?.id}>
                  <StyledDivider />
                  <OrganizationDetailCard
                    orgName={data?.llcName}
                    licenseNumber={data?.einNum}
                    address1={data?.address1}
                    address2={data?.address2}
                    state={data?.state}
                    country={data?.country}
                    city={data?.city}
                    zipCode={data?.zipCode}
                    id={data?.id}
                    llcdata={data}
                    // fetchData={fetchData}
                  />
                </React.Fragment>
              ))
            ) : (
              <>
                <TextBox>
                  <NoOrgTitle>
                    No Organization available
                  </NoOrgTitle>
                </TextBox>
              </>
            )}
          </Box>

        </CardContent>
      </StyledCard>
      <Modal open={openModal} onClose={handleCloseModal}>
        <OrganizationModal handleCloseModal={handleCloseModal} openModal={openModal} />
      </Modal>
    </>
  );
};

export default OrganizationTab;
