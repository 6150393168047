import React, { useEffect, useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Button,
  Container,
  Modal,
  Typography,
  Icon,
} from '@mui/material';
import { SideNavMenu } from 'app/components/index.js';
import { Span } from 'app/common/Typography.js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useProject } from 'app/contexts/ProjectContext';
import PMSingleInfoModal from './PMSingleInfoModal';
import PropertyManagementSingleInfo from './PropertyManagementSingleInfo';
import { Title, Paragraph } from 'app/common/Typography';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const PropertyManagementCompleteInfo = () => {
  const { propertyManagement } = useProject();
  const [pmData, setPmData] = useState(null);
  const [open, setOpen] = useState(false);
  const [allPM, setAllPM] = useState(propertyManagement);


  useEffect(() => {
    setAllPM(propertyManagement);
  }, [propertyManagement]);

  const handleView = (Index) => {
    setPmData(Index);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <SubtitleDivider />
      <Box alignItems='center' mt={3}>
        {(allPM?.length === 0) ? (
          <>
            <Title>Property Management</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }} >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }} variant="body1" color="#272937" gutterBottom>
                  No details added
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It seems you have not added any details related to Property Management
                </Typography>
                <Button onClick={handleClickOpen} variant="contained" color="primary">
                  Add Details
                </Button>
              </Box>
            </Container>
            <Modal open={open} onClose={handleClose}>
              {/* <GeneralContractorDetailsModal  contractors={allGC} handleCloseModal={handleClose} /> */}
              <PMSingleInfoModal properties={allPM} handleCloseModal={handleClose} />
            </Modal>
          </>
        ) : (pmData !== null && pmData !== undefined) ? (<PropertyManagementSingleInfo propertyManagements={allPM} id={pmData} setPmData={handleView} />) : (
          <>
            <Box
              marginLeft={3}
              marginRight={3}
              marginTop={1}
              justifyContent={'space-between'}
              display={'flex'}
              alignItems={'center'}
            >
              <Title sx={{ marginLeft: '0px' }}>Property Management Details</Title>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Button onClick={handleClickOpen}>+ ADD</Button>
              </Box>
            </Box>
            <Modal open={open} onClose={handleClose}>
              {/* <GeneralContractorDetailsModal  contractors={allGC} handleCloseModal={handleClose} /> */}
              <PMSingleInfoModal properties={allPM} handleCloseModal={handleClose} />
            </Modal>
            <Box overflow='auto'
              border={'2px solid #E5E5E7'}
              marginLeft={3}
              marginRight={3}
              marginTop={1}
              marginBottom={1}>
              <ProductTable>
                <TableHead bgcolor='#F2F9FC'>
                  <TableRow color='#64748B'>
                    <TableCell sx={{ px: 2, color: '#64748B' }}>S.NO</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>PROPERTY NAME</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>EMAIL ADDRESS</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>PHONE NUMBER</TableCell>
                    <TableCell sx={{ px: 0, width: '5%', color: '#64748B' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allPM.map((PMdata, index) => (
                    <TableRow key={PMdata.id} hover>
                      <TableCell
                        align='left'
                        sx={{
                          px: 2,
                          textTransform: 'capitalize',
                          fontWeight: 500,
                          fontSize: '14px',
                          color: '#272937',
                        }}
                      >
                        {`${(index + 1).toString().padStart(2, '0')}`}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {PMdata?.propertyName}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {PMdata?.email}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {PMdata?.phoneNumber}
                      </TableCell>

                      <TableCell
                        align='left'
                        sx={{ color: '#272937' }}
                      >
                        <SideNavMenu
                          menuButton={
                            <UserMenu>
                              <MoreVertIcon />
                            </UserMenu>
                          }>
                          <StyledItem onClick={() => handleView(index)} >
                            <Icon><VisibilityIcon /></Icon>
                            <Span> View </Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ProductTable>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default PropertyManagementCompleteInfo;
