import { Box, Button, Grid, styled, Table, TableRow, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AddOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const StyledBox = styled(Box)(({ texttransformstyle, ellipsis }) => ({
  textTransform: texttransformstyle || 'none',
  whiteSpace: ellipsis ? 'nowrap' : 'normal',
  overflow: ellipsis ? 'hidden' : '',
  textOverflow: ellipsis ? 'ellipsis' : '',
}));

export const CenteredText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const Title = styled('h1')(() => ({
  fontSize: '20px',
  fontWeight: '600',
  color: 'var(--Title-color)',
  textTransform: 'capitalize',
  paddingLeft: '1rem',
}));
export const H1 = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({ [className || '']: true })}
      component='h1'
      mb={0}
      mt={0}
      fontSize='28px'
      fontWeight='500'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Paragraph = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='p'
      mb={0}
      mt={0}
      fontSize='14px'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Small = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='small'
      fontSize='12px'
      fontWeight='500'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const Span = ({ children, className, ellipsis, textTransform, ...props }) => {
  return (
    <StyledBox
      texttransformstyle={textTransform}
      ellipsis={ellipsis}
      className={clsx({
        [className || '']: true,
      })}
      component='span'
      lineHeight='1.5'
      {...props}
    >
      {children}
    </StyledBox>
  );
};

export const H6 = ({ children, className, ellipsis, ...props }) => {
  return (
    <StyledBox
      mb={0}
      mt={0}
      component="h6"
      fontSize="13px"
      fontWeight="500"
      lineHeight="1.5"
      ellipsis={ellipsis}
      className={clsx({ [className || ""]: true })}
      {...props}>
      {children}
    </StyledBox>
  );
};

export const H5 = ({ children, className, ellipsis, ...props }) => {
  return (
    <StyledBox
      mb={0}
      mt={0}
      component="h5"
      fontSize="14px"
      fontWeight="500"
      lineHeight="1.5"
      textAlign={'left'}
      ellipsis={ellipsis}
      className={clsx({ [className || ""]: true })}
      {...props}>
      {children}
    </StyledBox>
  );
};


export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export const CustomGCExpensesInputText = ({ placeholder, error, touched, ...props }) => {
  return (
    <>
      <input
        type='text'
        placeholder={placeholder}
        style={{
          padding: '10px',
          borderRadius: '5px',
          border: '0px',
          outline: 'none',
          width: '100%',
          backgroundColor: '#F8F8F8',
        }}
        {...props}
      />
      {error && (
        <Typography color='error' variant='body2'>
          {props.helperText}
        </Typography>
      )}
    </>
  );
};

export const CustomInputText = ({ placeholder, ...props }) => {
  return (
    <>
      <input
        type='text'
        placeholder={placeholder}
        style={{
          padding: '10px',
          borderRadius: '5px',
          border: '0px',
          outline: 'none',
          width: '100%',
          backgroundColor: '#F8F8F8',
        }}
        {...props}
      />
    </>
  );
};

export const CustomTextInput = ({ placeholder, ...props }) => {
  return (
    <TextField
      type='text'
      placeholder={placeholder}
      sx={{
        '& .MuiInputBase-root': {
          height: '40px',
        },
      }}
      {...props}
    />
  );
};

export const customNumberInput = {
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
};

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '4px',
  width: '31vw',
  marginTop: '10px',
  border: '2px dashed lightgray',
});

export const DocContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderRadius: '4px',
  width: '100%',
  marginTop: '10px',
  border: '2px dashed lightgray',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

export const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
});

export const FileContainer = styled('div')({
  alignItems: 'center',
  padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: '#F9FBFC',
  borderRadius: '4px',
  margin: '20px',
  marginLeft: '20px',
});
export const FileContainerDetails = styled('div')({
  // maxHeight: '300vh',
  // overflowY: 'auto',
  width: '100%',
});

export const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // marginLeft: '8px',
  flexGrow: 1,
});

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D9D9D9',
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: '#0277BD',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0277BD',
    },
    '&.Mui-error fieldset': {
      borderColor: '#F61410',
    },
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.primary,
  },
  '& .MuiInputBase-input:not(:placeholder-shown)': {
    borderColor: '#0277BD',
  },
}));

export const CustomLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  fontWeight: 500,
  color: theme.palette.text.textfield,
  marginBottom: '8px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },
}));

export const ContainerHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
}));

export const Header = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '38px',
}));


export const StyleAddOrgButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.text.lavendorblue,
  color: theme.palette.text.white,
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "0.875rem",
  fontWeight: "500",
  cursor: 'pointer',
  padding:'8px 1rem 8px 1rem',
  "&:hover": {
      backgroundColor: theme.palette.text.lavendorblue,
  },
  [theme.breakpoints.down('sm')]: {
      width: '100%',
  },
}));

export const StyledIcon = styled(AddCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.text.white,
  fontSize: "1.25rem",
}));

export const StyledLinkIcon = styled(AddCircleOutlineIcon)(({ theme }) => ({
  color: theme.palette.text.lavendorblue,
  fontSize: '1.25rem', // Icon size
  backgroundColor: theme.palette.text.white, // Background color of the circle
  borderRadius: '50%',
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  letterSpacing: "-1%",
  color: theme.palette.text.title,
  marginLeft: "0.5rem",
  [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
      marginLeft: "1%",
  },
}));

export const Divider = styled(Box)(({ theme }) => ({
  width: '100%',  // Ensures the divider spans the full width of its parent container
  borderTop: '1px solid #D3D3D3',  // Light grey border
  margin: '20px 0',  // Space before and after the divider
}));

export const ModalBox = styled('div')({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "20px",
  width: "35%",
  borderRadius: "8px",
  boxShadow: 24,
});

export const ModalTitle = styled('h2')({
  fontWeight: "bold",
  textAlign: "left",
  margin: 0,
});

export const ModalSubtitle = styled('p')({
  textAlign: "left",
  margin: "10px 0",
});

export const RadioGroupStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',  // Arrange radio buttons vertically (columnwise)
  margin: "20px 0",
});

export const ModalButtons = styled('div')({
  display: "flex",
  justifyContent: "flex-end",
  gap: 10
});

export const CloseIcon = styled('span')({
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
});



export const TableWrapper = styled(Box)({
  overflowX: 'auto',
  width: '100%',
});

export const StyledTable = styled(Table)({
  minWidth: 900,
  // marginTop: '-1rem',
  borderCollapse: 'separate',
  // borderSpacing: '0 1rem',
  backgroundColor: '#F3F4F6',
  '& .MuiTableHead-root': {
    '& .MuiTableCell-head': {
      backgroundColor: '#FFFFFF',
      borderBottom: '0.7rem solid #F3F4F6',
      color: '#282A2D',
      fontSize: '1.1rem',
      fontWeight: 600,
      padding: '16px',
      '&:first-of-type': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
      '&:last-child': {
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },
    },
    '& .MuiTableRow-root': {
      backgroundColor: '#FFFFFF',
    },
  },
  '& .MuiTableBody-root': {
    backgroundColor: '#FFFFFF',

    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: '#F9FAFB',
      },
    },
    '& .MuiTableCell-body': {
      padding: '16px',
      fontSize: '0.875rem',
      color: '#282A2D',
      borderBottom: '0.3rem solid #F3F4F6',
    },
  },
});

export const AvatarName = styled(Box)({
  color: '#645BBB',
  fontSize: '1rem',
  fontWeight: 500,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const StatusChip = styled(Box)(({ status }) => {
  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'active':
        return {
          bg: '#4CB64C',
        };
      case 'pending':
        return {
          bg: '#7697F2',
        };
      default:
        return {
          bg: '#4CB64C',
        };
    }
  };

  const colors = getStatusColor(status);

  return {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 500,
    backgroundColor: colors.bg,
    color: '#FFF',
  };
});

export const HeaderStatusChip = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '2px 8px',
  borderRadius: '4px',
  fontSize: '0.75rem',
  fontWeight: 500,
  backgroundColor: '#4caf50',
  color: '#FFFFFF',
});

export const ContactDetails = styled(Box)({
  '& > *:first-of-type': {
    fontSize: '0.875rem',
    fontWeight: 500,
    marginBottom: '4px',
  },
  '& > *:last-child': {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
});

export const StyledTableRow = styled(TableRow)({
  backgroundColor: '#FFFFFF',
  '& td': {
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #E5E7EB',
    '&:first-of-type': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
  '&:hover td': {
    backgroundColor: '#F9FAFB',
  },
});

export const ModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const Footer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 0',
  marginTop: 'auto',
});

export const FixedFooter = styled(Box)({
  position: 'absolute',
  bottom: '20px',
  left: '20px',
  right: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 0',
});

export const StyledEditIcon = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.text.lavendorblue,
  border: `1px solid ${theme.palette.text.lavendorblue}`,
  borderRadius: '6px',
  fontWeight: '500',
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
    width: '100%',
  },
}));

export const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  fontSize: '0.875rem',
  color: theme.palette.text.primary,
  marginTop: '1rem',
}))

export const ModalStyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  right: '0%',
  transform: 'translate(0%, -50%)',
  backgroundColor: '#fff',
  boxShadow: 24,
  width: '40%',
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
}));

export const ModalStyledSubBox = styled(Box)(({ theme }) => ({
  padding: 15,
  paddingBottom: 0,
  justifyContent: 'space-between',
  display: 'flex',
}));


export const ModalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1em',
  borderRadius: '4px',
  marginTop: '10px',
});

export const StyledBoxFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  float: 'right',
  gap: '8%',
  marginTop: '4%',
  marginBottom: '3%',
}));

export const ModalCancel = styled(LoadingButton)(({ theme }) => ({
  boxShadow: 'none',
  height: '30px',
  backgroundColor: theme.palette.text.cancleButtonBG,
  color: theme.palette.text.cancleButton,
  width: '88px',
  border: 'none',
  padding: '20px',
  textTransform: 'none',
}));

export const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});


export const ModalSave = styled(LoadingButton)(({ theme }) => ({
  boxShadow: 'none',
  height: '30px',
  padding: '20px',
  marginRight: '2%',
  backgroundColor: theme.palette.text.buttonbg,
  color: theme.palette.text.white,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.text.buttonbg,
  },
}));