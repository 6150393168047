import React, { useState } from 'react';
import { Box, styled, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ModalDivider, StyledEditIcon } from 'app/common/Typography';
import EditIcon from '@mui/icons-material/Edit';
import {
  OrgDetailsBox1,
  OrgDetailsBox2,
  StyledCardBox1,
  StyledCardBox2,
  StyledCardBox3,
  StyledOrgContentKey,
  StyledOrgContentValue,
} from 'app/views/dashboard/profile/StyleComponents';
import SaleAmenitiesSection from './SaleAmenitiesTable';
import SaleAddEdit from './SaleAddEdit';
import SingleFamilyAddEdit from './SingleFamilyAddEdit';
import BuyerAddEdit from './BuyerAddEdit';
import BuyerInvestmentModal from './NuyerinvestmentAddEdit';
import TitleCompanyAddEdit from './TitleCompanyAddEdit';
import { useProjectBasic } from '../Basic/hooks/useProjectBasic';
import { useProject } from 'app/contexts/ProjectContext';
import SaleInvestmentSection from './Investments/SaleInvestmentSection';

const Title = styled('span')({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
});

const SaleDetailsView = ({
  saleData,
  onClose,
  selectedUnit,
  selectedBuilding,
  buyerData,
  saleId,
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);
  const [openSingleFamilyModal, setOpenSingleFamilyModal] = useState(false);
  const [openBuyerModal, setOpenBuyerModal] = useState(false);
  const [openBuyerInvestmentModal, setOpenBuyerInvestmentModal] = useState(false);
  const [openTitleCompanyModal, setOpenTitleCompanyModal] = useState(false);
  const handleAddSaleClick = () => {
    setIsOpenDialog(true);
  };
  const { projectId } = useProject();
  const { data: basic, isPending } = useProjectBasic(projectId);
  const isResidential = basic?.subType === 'Residential - Single Family';

  return (
    <>
      <div className='viewlead' style={{ backgroundColor: '#fff', borderRadius: '5px' }}>
        <Box>
          <Box item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '5px',
                alignItems: 'center',
                padding: '20px',
                // paddingTop: '10px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <IconButton
                    style={{ marginRight: '10px' }} // Adding some gap between the back icon and the title
                    onClick={onClose}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <Title>Sale Details</Title>
                </div>

                <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                  <Typography
                    fontSize='14px'
                    fontWeight='500'
                    variant='body2'
                    color='var(--typography-price-text)'
                    marginLeft={7}
                  >
                    {saleData?.salePrice === 'INR' ? '₹' : '$'}
                    {saleData?.salePrice} price • {saleData?.buyerSaleCommission} Buyer Sale
                    Commission • {saleData?.sellerSaleCommission} Seller Sale Commission
                  </Typography>
                </Box>
              </div>

              <StyledEditIcon
                variant='outlined'
                startIcon={<EditIcon />}
                onClick={toggleDialog}
                style={{ marginLeft: '20px' }} // Adding some space before the Edit button
              >
                Edit Details
              </StyledEditIcon>
            </Box>

            <ModalDivider />
            {isResidential && (
              <>
                <Box sx={{ padding: '20px' }}>
                  <StyledCardBox1>
                    <StyledCardBox2>
                      <Title variant='h6'>Sale Lot Details</Title>
                    </StyledCardBox2>

                    <StyledCardBox3>
                      <StyledEditIcon
                        variant='outlined'
                        startIcon={<EditIcon />}
                        onClick={() => setOpenSingleFamilyModal(true)}
                      >
                        Lot Details
                      </StyledEditIcon>
                    </StyledCardBox3>
                  </StyledCardBox1>

                  <OrgDetailsBox1>
                    <OrgDetailsBox2 sx={{ marginBottom: '16px' }}>
                      <Box>
                        <StyledOrgContentKey>Lot Number:</StyledOrgContentKey>
                        <StyledOrgContentValue>{'Architect'}</StyledOrgContentValue>
                      </Box>

                      <Box>
                        <StyledOrgContentKey>Lot Size</StyledOrgContentKey>
                        <StyledOrgContentValue>1234</StyledOrgContentValue>
                      </Box>
                      <Box sx={{ marginRight: '20%' }}>
                        <StyledOrgContentKey>Home sqft</StyledOrgContentKey>
                        <StyledOrgContentValue>1234</StyledOrgContentValue>
                      </Box>
                    </OrgDetailsBox2>
                    <Box sx={{ marginBottom: '16px' }}>
                      <StyledOrgContentKey>Address:</StyledOrgContentKey>
                      <StyledOrgContentValue>
                        55, Music Concourse Drive,San Francisco,California,941181,United States
                      </StyledOrgContentValue>
                    </Box>
                  </OrgDetailsBox1>
                </Box>

                <ModalDivider />
              </>
            )}
            <Box sx={{ padding: '20px' }}>
              <StyledCardBox1>
                <StyledCardBox2>
                  <Title variant='h6'>Buyer Details</Title>
                </StyledCardBox2>

                <StyledCardBox3>
                  <StyledEditIcon
                    variant='outlined'
                    startIcon={<EditIcon />}
                    onClick={() => setOpenBuyerModal(true)}
                  >
                    Buyer Details
                  </StyledEditIcon>
                </StyledCardBox3>
              </StyledCardBox1>
              <OrgDetailsBox1>
                <OrgDetailsBox2 sx={{ marginBottom: '16px' }}>
                  <Box>
                    <StyledOrgContentKey>Name:</StyledOrgContentKey>
                    <StyledOrgContentValue>{buyerData?.buyerName}</StyledOrgContentValue>
                  </Box>

                  <Box>
                    <StyledOrgContentKey>Email</StyledOrgContentKey>
                    <StyledOrgContentValue>{buyerData?.email}</StyledOrgContentValue>
                  </Box>
                  <Box sx={{ marginRight: '20%' }}>
                    <StyledOrgContentKey>Phone Number</StyledOrgContentKey>
                    <StyledOrgContentValue>{buyerData?.phoneNo}</StyledOrgContentValue>
                  </Box>
                </OrgDetailsBox2>
                <Box sx={{ marginBottom: '16px' }}>
                  <StyledOrgContentKey>Address:</StyledOrgContentKey>
                  <StyledOrgContentValue>
                    {buyerData?.address1},{buyerData?.address2 ? buyerData?.address2 + "," : ""}{buyerData?.city},{buyerData?.state},{buyerData?.zipCode},{buyerData?.country}
                  </StyledOrgContentValue>
                </Box>
              </OrgDetailsBox1>
            </Box>
            <ModalDivider />
            <Box sx={{ padding: '20px' }}>
              <StyledCardBox1>
                <StyledCardBox2>
                  <Title variant='h6'>Realtor Details</Title>
                </StyledCardBox2>

                <StyledCardBox3>
                  <StyledEditIcon
                    variant='outlined'
                    startIcon={<EditIcon />}
                    onClick={() => setOpenBuyerInvestmentModal(true)}
                  >
                    Realtor Details
                  </StyledEditIcon>
                </StyledCardBox3>
              </StyledCardBox1>

              <OrgDetailsBox1>
                <OrgDetailsBox2 sx={{ marginBottom: '16px' }}>
                  <Box>
                    <StyledOrgContentKey>Name:</StyledOrgContentKey>
                    <StyledOrgContentValue>{'Zabee Rahman'}</StyledOrgContentValue>
                  </Box>

                  <Box>
                    <StyledOrgContentKey>Email</StyledOrgContentKey>
                    <StyledOrgContentValue>mohamed@gmail.com</StyledOrgContentValue>
                  </Box>
                  <Box sx={{ marginRight: '20%' }}>
                    <StyledOrgContentKey>Phone Number</StyledOrgContentKey>
                    <StyledOrgContentValue>+916302124074</StyledOrgContentValue>
                  </Box>
                </OrgDetailsBox2>
                <Box sx={{ marginBottom: '16px' }}>
                  <StyledOrgContentKey>Address:</StyledOrgContentKey>
                  <StyledOrgContentValue>
                    55, Music Concourse Drive,San Francisco,California,941181,United States
                  </StyledOrgContentValue>
                </Box>
              </OrgDetailsBox1>
            </Box>
            <ModalDivider />
            <Box sx={{ padding: '20px' }}>
              <StyledCardBox1>
                <StyledCardBox2>
                  <Title variant='h6'>Title Company Details</Title>
                </StyledCardBox2>

                <StyledCardBox3>
                  <StyledEditIcon
                    variant='outlined'
                    startIcon={<EditIcon />}
                    onClick={() => setOpenTitleCompanyModal(true)}
                  >
                    Title Company
                  </StyledEditIcon>
                </StyledCardBox3>
              </StyledCardBox1>

              <OrgDetailsBox1>
                <OrgDetailsBox2 sx={{ marginBottom: '16px' }}>
                  <Box>
                    <StyledOrgContentKey>Name:</StyledOrgContentKey>
                    <StyledOrgContentValue>{'Zabee Rahman'}</StyledOrgContentValue>
                  </Box>

                  <Box>
                    <StyledOrgContentKey>Email</StyledOrgContentKey>
                    <StyledOrgContentValue>mohamed@gmail.com</StyledOrgContentValue>
                  </Box>
                  <Box sx={{ marginRight: '20%' }}>
                    <StyledOrgContentKey>Phone Number</StyledOrgContentKey>
                    <StyledOrgContentValue>+916302124074</StyledOrgContentValue>
                  </Box>
                </OrgDetailsBox2>
                <OrgDetailsBox2 sx={{ marginBottom: '16px' }}>
                  <Box>
                    <StyledOrgContentKey>Contact Person Name:</StyledOrgContentKey>
                    <StyledOrgContentValue>{'Zabee Rahman'}</StyledOrgContentValue>
                  </Box>

                  <Box sx={{ marginRight: '20%' }}>
                    <StyledOrgContentKey>Contact Person Email</StyledOrgContentKey>
                    <StyledOrgContentValue>mohamed@gmail.com</StyledOrgContentValue>
                  </Box>
                  <Box sx={{ marginRight: '10%' }}></Box>
                </OrgDetailsBox2>
                <Box sx={{ marginBottom: '16px' }}>
                  <StyledOrgContentKey>Address:</StyledOrgContentKey>
                  <StyledOrgContentValue>
                    55, Music Concourse Drive,San Francisco,California,941181,United States
                  </StyledOrgContentValue>
                </Box>
              </OrgDetailsBox1>
            </Box>
            <ModalDivider />
          </Box>
        </Box>
        <SaleAmenitiesSection />
        {/* <ModalDivider sx={{marginTop: '20px', marginBottom: '20px'}}/> */}
        <SaleInvestmentSection 
          buyerData={buyerData}
          selectedUnit={selectedUnit}
          selectedBuilding={selectedBuilding}
          saleId={saleId} 
        />
      </div>
      <SaleAddEdit
        open={isOpenDialog}
        onClose={toggleDialog}
        saleData={saleData}
        buyerData={buyerData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
        saleId={saleId}
        handleAddSaleClick={handleAddSaleClick}
        isEdit={true}
      />
      <SingleFamilyAddEdit
        open={openSingleFamilyModal}
        onClose={() => setOpenSingleFamilyModal(false)}
        handleOpenTitleCompanyModal={() => setOpenSingleFamilyModal(false)}
        buyerData={buyerData}
        saleData={saleData}
        onBack={() => setOpenSingleFamilyModal(false)}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        saleId={saleId}
        isSingleResidential={true}
        isEdit={true}
        showPrevious={false}
      />
      <BuyerAddEdit
        open={openBuyerModal}
        handleCancel={() => setOpenBuyerModal(false)}
        onClose={() => setOpenBuyerModal(false)}
        saleData={saleData}
        buyerData={buyerData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
        onBack={() => setOpenBuyerModal(false)}
        saleId={saleId}
        handleBuyerSaleFormClick={() => setOpenBuyerModal(false)}
        isEdit={true}
        showPrevious={false}
      />
      <BuyerInvestmentModal
        open={openBuyerInvestmentModal}
        onClose={() => setOpenBuyerInvestmentModal(false)}
        buyerData={buyerData}
        saleData={saleData}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        saleId={saleId}
        onBack={() => setOpenBuyerInvestmentModal(false)}
        handleBuyerModalOpen={() => setOpenBuyerInvestmentModal(false)}
        isEdit={true}
        showPrevious={false}
      />
      <TitleCompanyAddEdit
        open={openTitleCompanyModal}
        onClose={() => setOpenTitleCompanyModal(false)}
        handleOpenRealtorInvestment={() => setOpenTitleCompanyModal(false)}
        buyerData={buyerData}
        saleData={saleData}
        onBack={() => setOpenTitleCompanyModal(false)}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        saleId={saleId}
        isEdit={true}
        showPrevious={false}
      />
    </>
  );
};

export default SaleDetailsView;
