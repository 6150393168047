import CustomToast from 'app/common/CustomToast';
import { toast } from 'react-toastify';

// Map to track active toast IDs
const activeToasts = new Map();

// Function to show a toast
const showToast = (message, severity, options = {}) => {
  const { toastId } = options;

  if (toastId) {
    // Only show the toast if it is not already active
    if (activeToasts.has(toastId)) return;

    activeToasts.set(toastId, true);
    toast(<CustomToast message={message} severity={severity} />, {
      ...options,
      onClose: () => activeToasts.delete(toastId), // Clean up the ID when toast is closed
    });
    return;
  }

  // If no toastId is provided, show the toast without tracking
  toast(<CustomToast message={message} severity={severity} />, options);
};

const notify = {
  success: (message, options = {}) => showToast(message, 'success', options),
  error: (message, options = {}) => showToast(message, 'error', options),
  info: (message, options = {}) => showToast(message, 'info', options),
  warning: (message, options = {}) => showToast(message, 'warning', options),

  isActive: (toastId) => activeToasts.has(toastId),
};

export default notify;
