import * as Yup from 'yup';
import dayjs from 'dayjs';
import { MOBILE_NUMBER_REGEX } from './constant';

const isAtLeastOneDayAfter = (laterDate, earlierDate) => {
  if (!laterDate || !earlierDate) return true;
  return dayjs(laterDate).diff(dayjs(earlierDate), 'day') >= 1;
};

const testFeasibilityDate = function (value) {
  return isAtLeastOneDayAfter(value, this.parent.effectiveDate);
};

const testClosingDate = function (value) {
  return isAtLeastOneDayAfter(value, this.parent.feasibilityEndDate);
};

export const validateCompleteDate = (date) => {
  if (date && dayjs(date).isValid()) {
    const day = dayjs(date).date();
    const month = dayjs(date).month() + 1;
    const year = dayjs(date).year();
    return day > 0 && month > 0 && year > 0;
  }
  return false;
};

export const validationSchemas = {
  login: generateLoginSchema,
  otp: generateOTPSchema,
  register: generateRegisterSchema,
  profile: generateProfileSchema,
  members: generateMembersSchema,
  investNow: generateInvestNowSchema,
  editInvestments: generateEditInvestmentsSchema,
  contract: generateContractSchema,
  newProject: generateNewProjectSchema,
  newProjectStep1: generateNewProjectSchemaStep1,
  newProjectStep2: generateNewProjectSchemaStep2,
  requestDecline: generateRequestDeclineSchema,
  llc: generateLLCSchema,
  location: generateLocationSchema,
  addBuilding: generateAddBuildingSchema,
  lease: generateLeaseSchema,
  tenant: generateTenantSchema,
  sale: generateSaleSchema,
  buyer: generateBuyerSchema,
  documents: generateDocumentsSchema,
  closing: generateClosingSchema,
  company: generateCompanySchema,
  lender: generateLenderSchema,
  expenses: generateExpensesSchema,
  property: generatePropertySchema,
  PMContactPerson: generatePMContactPersonSchema,
  generalContractor: generateGeneralContractorSchema,
  gcManager: generateGCManagerSchema,
  gcChangeOrder: generateGCChangeOrderSchema,
  gcPayment: generateGCPaymentSchema,
  constructionLender: generateConstructionLenderSchema,
  clLoanPayment: generateCLLoanPaymentSchema,
  clLoanWithdrawal: generateCLLoanWithdrawalSchema,
  role: generateRoleSchema,
};

function generateLoginSchema() {
  return Yup.object().shape({
    email: Yup.string()
      .required('Email is required!')
      .test('email', 'Invalid Email', function (value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      }),
  });
}

function generateOTPSchema() {
  return Yup.object().shape({
    otp: Yup.array()
      .of(Yup.number().typeError('Each OTP digit must be a number'))
      .length(6, 'OTP must be exactly 6 digits')
      .test('otp-required', 'OTP is required', function (value) {
        if (value && value.some((digit) => digit === undefined || digit === null || digit === '')) {
          return this.createError({ message: 'OTP is required' });
        }
        return true;
      }),
  });
}

function generateProfileSchema() {
  return Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'Name can only contain letters and spaces!')
      .required('First name is required!'),
    surname: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'Last Name can only contain letters!')
      .required('Last Name is required!'),
    dob: Yup.date()
      .required('Date of Birth is required!')
      .nullable()
      .test('dob', 'You must be at least 18 years old', (value) => {
        return dayjs().diff(dayjs(value), 'year') >= 18;
      }),
    phone: Yup.string()
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      )
      .required('Mobile number is required!'),
    email: Yup.string()
      .required('Email Address is required!')
      .test('email', 'Invalid Email Address', function (value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      }),
    address1: Yup.string().required('Address1 is required!'),
    country: Yup.string().required('Country is required!'),
    city: Yup.string().required('City is required!'),
    state: Yup.string().required('State is required!'),
    zipCode: Yup.string().required('Zip Code is required!'),
  });
}

function generateRegisterSchema() {
  return Yup.object().shape({
    email: Yup.string()
      .required('Email Address is required!')
      .test('email', 'Invalid Email Address', function (value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      }),
  });
}

function generateEditInvestmentsSchema({ investedAmount, investedShares }) {
  return Yup.object().shape({
    eachSharePrice: Yup.number()
      .required('Each share price is required')
      .positive('Each share price must be greater than 0')
      .max(
        Yup.ref('totalInvestment'),
        'Each share price must be less than or equal to total investment',
      )
      .test(
        'divisible-total-investment',
        'Share price must result in a whole number of shares',
        function (value) {
          const totalInvestment = this.parent.totalInvestment;
          return !value || Number.isInteger(totalInvestment / value);
        },
      ),
    landCost: Yup.number()
      .required('Land cost is required')
      .positive('Land cost must be greater than 0'),
    softCost: Yup.number()
      .required('Soft cost is required')
      .positive('Soft cost must be greater than 0'),
    totalInvestment: Yup.number()
      .required('Total investment is required')
      .positive('Total investment must be greater than 0')
      .min(
        investedAmount,
        `Total investment cannot be less than existing invested amount (${investedAmount})`,
      ),
    totalShare: Yup.number()
      .required('Total share is required')
      .positive('Total share must be greater than 0')
      .integer('Total share must be a whole number')
      .min(
        investedShares,
        `Total share cannot be less than existing invested shares (${investedShares})`,
      ),
  });
}

function generateMembersSchema(projectMembers = [], isEditing = false) {
  return Yup.object().shape({
    members: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^[a-zA-Z\s]+$/, 'Name can only contain letters and spaces!')
          .required('Name is required!'),
        phone: Yup.string()
          .matches(
            MOBILE_NUMBER_REGEX,
            'Please specify country code with valid mobile number (10-12 digits)',
          )
          .required('Mobile number is required!')
          .test('unique-phone', 'This phone number is already in use', function (value) {
            if (isEditing) return true;

            const { createError } = this;
            const members = this.from[1].value.members;
            const phoneCount = members.filter((member) => member.phone === value).length;

            if (phoneCount > 1) {
              return createError({ message: 'This phone number is already in use in the form' });
            }

            const existsInProjectMembers = projectMembers.some((member) => member.phone === value);

            if (existsInProjectMembers) {
              return createError({
                message: 'This phone number is already associated with a project member',
              });
            }

            return true;
          }),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required!')
          .test('unique-email', 'This email is already in use', function (value) {
            if (isEditing) return true;

            const { createError } = this;
            const members = this.from[1].value.members;
            const emailCount = members.filter((member) => member.email === value).length;

            if (emailCount > 1) {
              return createError({ message: 'This email is already in use in the form' });
            }

            const existsInProjectMembers = projectMembers.some((member) => member.email === value);

            if (existsInProjectMembers) {
              return createError({
                message: 'This email is already associated with a project member',
              });
            }

            return true;
          }),
        userRoles: Yup.array().min(1, 'At least one role should be selected!'),
      }),
    ),
  });
}

function generateInvestNowSchema({
  isAcceptedInvestor,
  remainingAmount,
  availableShares,
  eachSharePrice,
}) {
  const baseAmountSchema = Yup.number()
    .positive('Amount must be greater than zero!')
    .max(remainingAmount, 'Amount cannot be more than the remaining investment!')
    .test(
      'divisible-by-share-price',
      'You entered a non-divisible amount. Please adjust to match whole shares.',
      (value) => !value || Number.isInteger(value / eachSharePrice),
    );

  const baseSharesSchema = Yup.number()
    .positive('Number of shares must be greater than zero!')
    .max(availableShares, 'Number of shares cannot be more than the available shares!')
    .integer('Number of shares must be a whole number');

  return Yup.object().shape({
    interestedAmount: Yup.number().when([], {
      is: () => !isAcceptedInvestor,
      then: () => baseAmountSchema.required('Interested amount is required!'),
      otherwise: () => Yup.number().notRequired(),
    }),

    interestedShares: Yup.number().when([], {
      is: () => !isAcceptedInvestor,
      then: () => baseSharesSchema.required('Number of interested shares is required!'),
      otherwise: () => Yup.number().notRequired(),
    }),

    investedAmount: baseAmountSchema.when(['$self'], {
      is: (investedAmount) => isAcceptedInvestor || investedAmount,
      then: () => baseAmountSchema.required('Invested amount is required!'),
      otherwise: () => baseAmountSchema.notRequired(),
    }),

    investedShares: baseSharesSchema.when(['$self'], {
      is: (investedShares) => isAcceptedInvestor || investedShares,
      then: () => baseSharesSchema.required('Number of shares is required!'),
      otherwise: () => baseSharesSchema.notRequired(),
    }),

    paymentDate: Yup.date().when(['investedAmount', 'investedShares'], {
      is: (investedAmount, investedShares) => investedAmount || investedShares,
      then: () =>
        Yup.date().required('Payment date is required!').typeError('Please select a valid date'),
      otherwise: () => Yup.date().notRequired(),
    }),

    paymentMode: Yup.string().when(['investedAmount', 'investedShares'], {
      is: (investedAmount, investedShares) => investedAmount || investedShares,
      then: () => Yup.string().required('Payment mode is required!'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });
}

function generateNewProjectSchema() {
  return Yup.object().shape({
    landCost: Yup.number()
      .positive('Land cost must be greater than zero')
      .required('Land cost is required'),

    softCost: Yup.number()
      .positive('Soft cost must be greater than zero')
      .required('Soft cost is required'),

    totalInvestment: Yup.number()
      .positive('Total investment must be greater than zero')
      .required('Total investment is required'),

    eachSharePrice: Yup.number()
      .positive('Share price must be greater than zero')
      .required('Share price is required')
      .test(
        'divisible-total-investment',
        'Share price must result in a whole number of shares',
        function (value) {
          const totalInvestment = this.parent.totalInvestment;
          return !value || Number.isInteger(totalInvestment / value);
        },
      ),

    totalShare: Yup.number()
      .positive('Total shares must be greater than zero')
      .required('Total shares is required')
      .integer('Total shares must be a whole number'),

    effectiveDate: Yup.date()
      .nullable()
      .typeError('Effective date is required')
      .required('Effective date is required'),

    feasibilityEndDate: Yup.date()
      .nullable()
      .typeError('Feasibility end date is required')
      .test(
        'min-days-after-effective',
        'Feasibility end date must be after effective date',
        testFeasibilityDate,
      )
      .required('Feasibility end date is required'),

    estimatedClosingDate: Yup.date()
      .nullable()
      .typeError('Estimated closing date is required')
      .test(
        'min-days-after-feasibility',
        'Estimated closing date must be after feasibility end date',
        testClosingDate,
      )
      .required('Estimated closing date is required'),

    earnestMoney: Yup.number()
      .positive('Earnest money must be greater than zero')
      .required('Earnest money is required'),

    optionsMoney: Yup.number()
      .positive('Options money must be greater than zero')
      .required('Options money is required'),

    // documents: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       docName: Yup.string().trim().required('Document name is required'),
    //     }),
    //   )
    //   .min(1, 'At least one document is required'),
  });
}

function generateContractSchema() {
  return Yup.object().shape({
    effectiveDate: Yup.date()
      .nullable()
      .typeError('Effective date is required')
      .required('Effective date is required'),

    feasibilityEndDate: Yup.date()
      .nullable()
      .typeError('Feasibility end date is required')
      .test(
        'min-days-after-effective',
        'Feasibility end date must be after effective date',
        testFeasibilityDate,
      )
      .required('Feasibility end date is required'),

    estimatedClosingDate: Yup.date()
      .nullable()
      .typeError('Estimated closing date is required')
      .test(
        'min-days-after-feasibility',
        'Estimated closing date must be after feasibility end date',
        testClosingDate,
      )
      .required('Estimated closing date is required'),

    earnestMoney: Yup.number()
      .positive('Earnest money must be greater than zero')
      .required('Earnest money is required'),

    optionsMoney: Yup.number()
      .positive('Options money must be greater than zero')
      .required('Options money is required'),
  });
}

function generateRequestDeclineSchema() {
  return Yup.object({
    declineReason: Yup.string().required('Reason for decline is required'),
  });
}

function generateLLCSchema() {
  return Yup.object().shape({
    llcName: Yup.string().required('LLC Name is required'),
    einNum: Yup.string().required('EIN Number is required'),
    address1: Yup.string().required('Address1 is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('Zip Code is required'),
  });
}

function generateLocationSchema() {
  return Yup.object().shape({
    address1: Yup.string().required('Address1 is required!'),
    city: Yup.string().required('City is required!'),
    state: Yup.string().required('State is required!'),
    zipCode: Yup.string().required('Zip Code is required!'),
    country: Yup.string().required('Country is required'),
  });
}

function generateDocumentsSchema() {
  return Yup.object().shape({
    documents: Yup.array().of(
      Yup.object().shape({
        file: Yup.mixed().required('Document is required'),
        docName: Yup.string().required('Document Name is required'),
        type: Yup.string().required('Document Type is required'),
      }),
    ),
  });
}

function generateClosingSchema() {
  return Yup.object().shape({
    purchasePrice: Yup.number()
      .positive('Purchase price must be greater than zero')
      .required('Purchase price is required'),
    closingCost: Yup.number()
      .positive('Closing cost must be greater than zero')
      .required('Closing cost is required'),
    realtorRebate: Yup.number()
      .positive('Realtor rebate must be greater than zero')
      .required('Realtor rebate is required'),
    closingDate: Yup.date()
      .nullable()
      .typeError('Closing date is required')
      .required('Closing date is required'),
  });
}

function generateCompanySchema() {
  return Yup.object().shape({
    companyName: Yup.string().required('Company name is required'),
    contactPerson: Yup.string().required('Contact person is required'),
    contactPersonNumber: Yup.string()
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      )
      .required('Mobile number is required'),
    contactPersonEmail: Yup.string().email('Invalid email address').required('Email is required!'),
  });
}

function generateLenderSchema() {
  return Yup.object().shape({
    lenderName: Yup.string().required('Lender name is required'),
    lenderContactNumber: Yup.string()
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      )
      .required('Mobile number is required'),
    lenderEmail: Yup.string().email('Invalid email address').required('Email is required!'),
    leaseTerm: Yup.number().required('Lease term is required'),
    downPayment: Yup.number()
      .positive('Down payment must be greater than zero')
      .required('Down payment is required'),
    loanAmount: Yup.number()
      .positive('Loan amount must be greater than zero')
      .required('Loan amount is required'),
    interestRate: Yup.number()
      .positive('Interest rate must be greater than zero')
      .required('Interest rate is required'),
  });
}

function generateExpensesSchema() {
  return Yup.object().shape({
    expensesName: Yup.string().required('Expenses Name is required'),
    totalAmount: Yup.number()
      .required('Amount is required')
      .positive('Amount must be greater than zero'),
    paidAmount: Yup.number()
      .required('Paid Amount is required')
      .positive('Paid Amount must be greater than zero')
      .max(Yup.ref('totalAmount'), 'Paid Amount cannot be greater than Total Amount'),
    invoiceNo: Yup.string().required('InvoiceNo is required'),
    expenseType: Yup.string().required('Expense Type is required'),
    modeOfPayment: Yup.string().required('Mode of Payment is required'),
    paidTo: Yup.string().required('PaidTo is required'),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          docName: Yup.string().trim().required('Document name is required'),
        }),
      )
      .min(1, 'Document is required'),
  });
}

function generatePropertySchema() {
  return Yup.object().shape({
    propertyName: Yup.string().required('Property name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string()
      .required('Mobile number is required!')
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      ),
    address1: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('ZIP Code is required'),
  });
}

function generatePMContactPersonSchema() {
  return Yup.object().shape({
    name: Yup.string().required('Contact Person is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone Number is required')
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      ),
  });
}

function generateGeneralContractorSchema() {
  return Yup.object().shape({
    llcName: Yup.string().required('LLC Name is required'),
    contractName: Yup.string().required('Contractor Name is required'),
    emailId: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNum: Yup.string()
      .required('Phone Number is required')
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      ),
    address1: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    zipCode: Yup.string().required('ZIP Code is required'),
  });
}

function generateGCManagerSchema() {
  return Yup.object().shape({
    contactPerson: Yup.string().required('Contact Person is required'),
    emailId: Yup.string().email('Invalid email format').required('Email is required'),
    phoneNo: Yup.string()
      .required('Phone Number is required')
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      ),
  });
}

function generateGCChangeOrderSchema() {
  return Yup.object().shape({
    changeOrderName: Yup.string().required('Change Order Name is required'),
    initialEstimate: Yup.number()
      .required('Initial Estimates is required')
      .positive('Estimates must be positive'),
    cost: Yup.number().required('Cost is required').positive('Cost must be greater than zero'),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          docName: Yup.string().trim().required('Document name is required'),
        }),
      )
      .min(1, 'Document is required'),
  });
}

function generateGCPaymentSchema() {
  return Yup.object().shape({
    totalAmount: Yup.number()
      .required('Total Amount is required')
      .positive('Total Amount must greater than zero'),
    amountPaid: Yup.number()
      .required('Paid Amount is required')
      .positive('Paid Amount must greater than zero')
      .max(Yup.ref('totalAmount'), 'Paid Amount cannot be greater than Total Amount'),
    paidBy: Yup.string().required('Paid By is required'),
    paidTo: Yup.string().required('Paid To is required'),
    paymentType: Yup.string().required('Mode of Payment is required'),
  });
}

function generateConstructionLenderSchema() {
  return Yup.object().shape({
    lenderName: Yup.string().required('Lender Name is required'),
    lenderEmail: Yup.string().email('Invalid email format').required('Email is required'),
    lenderPhone: Yup.string()
      .required('Phone Number is required')
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      ),
    address1: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('Zip code is required'),
    country: Yup.string().required('Country is required'),
    lenderContactPersonName: Yup.string().required('Contact Person Name is required'),
    lenderContactPersonPhone: Yup.string()
      .required('Phone Number is required')
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      ),
    lenderContactPersonEmail: Yup.string()
      .email('Invalid email format')
      .required('Contact Person Email is required'),
    loanApprovedAmount: Yup.number()
      .positive('Approved Amount must be greater than zero')
      .required('Loan Approved Amount is required'),
    loanWithdrawalAmount: Yup.number()
      .positive('Withdrawal Amount must be greater than zero')
      .max(
        Yup.ref('loanApprovedAmount'),
        'Withdrawal Amount cannot be greater than Loan Approved Amount',
      )
      .required('Loan Withdrawal Amount is required'),
    interestRate: Yup.number()
      .positive('Interest Rate must be greater than zero')
      .required('Interest Rate is required'),
    loanType: Yup.string().required('Loan Type is required'),
    loanStartDate: Yup.date()
      .required('Loan Start Date is required')
      .typeError('Invalid Date format'),
    loanTerm: Yup.number()
      .integer()
      .positive('Loan Term must be greater than zero')
      .required('Loan Term is required'),
    // documents: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       file: Yup.mixed().required('File is required'),
    //       docName: Yup.string().required('Document name is required'),
    //     })
    //   )
    //   .min(1, 'At least one file must be uploaded'),
  });
}

function generateCLLoanPaymentSchema() {
  return Yup.object().shape({
    loanMonthlyPayment: Yup.number()
      .required('Loan Monthly Payment is required')
      .positive('Payment must be greater than zero'),
    loanPayment: Yup.date().required('Paid date is required').typeError('Invalid Date format'),
    paidBy: Yup.string().required('Paid By is required'),
  });
}

function generateCLLoanWithdrawalSchema() {
  return Yup.object().shape({
    withdrawalAmount: Yup.number()
      .required('Withdrawal Amount is required')
      .positive('Withdrawal Amount must be greater than zero'),
    invoiceNumber: Yup.string().required('Invoice Number is required'),
    paidDate: Yup.date().required('Paid Date is required').typeError('Invalid Date formate'),
    paidTo: Yup.string().required('Paid To is required'),
  });
}

function generateRoleSchema() {
  return Yup.object().shape({
    name: Yup.string().required('Role name is required'),
    services: Yup.array().min(1, 'At least one service must be selected'),
    permissions: Yup.lazy((permissions) =>
      Yup.object().shape(
        Object.keys(permissions).reduce((shape, serviceName) => {
          shape[serviceName] = Yup.object().test(
            'service-permissions',
            `Please select at least one permission for this service or deselect it`,
            ({ read, write }) => read.length || write.length,
          );

          return shape;
        }, {}),
      ),
    ),
  });
}

function generateNewProjectSchemaStep1() {
  return Yup.object().shape({
    effectiveDate: Yup.date().required('Effective date is required'),
    feasibilityEndDate: Yup.date().required('Feasibility due date is required'),
    earnestMoney: Yup.number().required('Earnest money is required').positive().integer(),
    optionsMoney: Yup.number().required('Options money is required').positive().integer(),
  });
}

function generateNewProjectSchemaStep2() {
  return Yup.object().shape({
    landCost: Yup.number().required('Land cost is required').positive().integer(),
    softCost: Yup.number().required('Soft cost is required').positive().integer(),
    totalInvestment: Yup.number().required('Total investment is required').positive().integer(),
    eachSharePrice: Yup.number().required('Each share price is required').positive().integer(),
    totalShare: Yup.number().required('Total share is required').positive().integer(),
  });
}
//PropertyDetailsModal
export const PropertyDetailsSchema = Yup.object().shape({
  PropertyType: Yup.string().required('Property Type is required'),
  subType: Yup.string().required('sub type is required'),
  Area: Yup.string().required('Area in acres is required'),
  Zone: Yup.string().required('Zone is required'),
  EstimatedPrice: Yup.string().required('Estimated Price is required'),
});
function generateAddBuildingSchema() {
  return Yup.object({
    buildingName: Yup.string().required('Building name is required'),
    units: Yup.array()
      .of(
        Yup.object({
          unitName: Yup.string().required('Unit name is required'),
          space: Yup.number()
            .required('Space is required')
            .positive('Space must be greater than 0'),
        }),
      )
      .min(1, 'At least one unit is required'),
  });
}

function generateLeaseSchema() {
  return Yup.object().shape({
    rentSqFtPerYear: Yup.string().required('Rent/SqFt is Required'),
    leaseTerm: Yup.string().required('Lease Term is Required'),
    rentIncreasePerYear: Yup.string().required('Rent Increase/Year is Required'),
    leaseExecutionDate: Yup.date()
      .typeError('Lease Execution Date is Required')
      .test('complete-date', 'Lease Execution Date is required!', validateCompleteDate),
    leaseFixturizationDate: Yup.date()
      .typeError('lease Fixturization Date is required!')
      .test('complete-date', 'lease Fixturization Date is required!', validateCompleteDate),
    leaseStartDate: Yup.date()
      .typeError('Lease Start Date is Required')
      .test('complete-date', 'Lease Start Date is required!', validateCompleteDate),
    leaseEndDate: Yup.date()
      .typeError('Lease End Date is Required')
      .test('complete-date', 'Lease End Date is required!', validateCompleteDate),
    currentMonthRent: Yup.string().required('Current Month Rent is Required'),
    freeRentMonths: Yup.string().required('Free Rent Months is Required'),
    nnn: Yup.string().required('NNN is Required'),
    securityDeposit: Yup.string().required('Security Deposit is Required'),
    firstMonthRentDate: Yup.date()
      .typeError('First Month Rent Date is Required')
      .test('complete-date', 'First Month Rent date is required!', validateCompleteDate),
    realtorCommission: Yup.string().required('Realtor Commission is Required'),
    realtorRebate: Yup.string().required('Realtor Rebate is Required'),
    comments: Yup.string().optional(),
    leaseDocuments: Yup.array().of(
      Yup.object().shape({
        file: Yup.mixed().required('Document is required'),
        docName: Yup.string().required('Document Name is required'),
      }),
    ),
  });
}

function generateTenantSchema() {
  return Yup.object().shape({
    LLCName: Yup.string().required('LLC Name is required'),
    LLCNumber: Yup.string()
      .matches(/^\d+$/, 'LLC Number must be numeric')
      .required('LLC Number is required'),
    LLCPhoneNumber: Yup.string()
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      )
      .required('Mobile number is required!'),
    LLCEmail: Yup.string().email('Invalid email format').required('Email is required'),
    LLCAddress1: Yup.string().required('LLC address1 is required'),
    LLCCity: Yup.string().required('city is required'),
    LLCState: Yup.string().required('state is required'),
    LLCCountry: Yup.string().required('country is required'),
    LLCZIPCode: Yup.string().required('Zip Code is required'),
    contactPerson: Yup.string().required('Contact Person is required'),
    tenantEmail: Yup.string().email('Invalid email format').required('Email is required'),
    tenantPhoneNo: Yup.string()
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      )
      .required('Mobile number is required!'),
    address1: Yup.string().required('address1 is required'),
    city: Yup.string().required('city is required'),
    state: Yup.string().required('state is required'),
    country: Yup.string().required('country is required'),
    zipCode: Yup.string().required('Zip Code is required'),
  });
}

function generateBuyerSchema() {
  return Yup.object().shape({
    buyerName: Yup.string().required('Buyer Name is required'),
    buyerContactPerson: Yup.string().required('Contact Person is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phoneNo: Yup.string()
      .matches(
        MOBILE_NUMBER_REGEX,
        'Please specify country code with valid mobile number (10-12 digits)',
      )
      .required('Phone Number is required'),
    address1: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zipCode: Yup.string().required('Zip Code is required'),
  });
}

function generateSaleSchema() {
  return Yup.object().shape({
    buyerRealtor: Yup.string().required('Buyer Realtor is required'),
    unitNo: Yup.string().required('Unit No is required'),
    salePrice: Yup.number().required('Sale Price is required'),
    buyerSaleCommission: Yup.number().required('Buyer Sale Commission is required'),
    sellerSaleCommission: Yup.number().required('Seller Sale Commission is required'),
    closingCost: Yup.number().required('Closing Cost is required'),
    titlePolicyCost: Yup.number().required('Title Policy Cost is required'),
    comments: Yup.string().optional(),
  });
}
