import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { StyledIcon, Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useCallback, useReducer, useRef } from 'react';
import RolesTable from './RolesTable';
import RoleAddEdit from './RoleAddEdit';
import RoleDeleteConfirmation from './RoleDeleteConfirmation';
import { useProjectRoles } from './hooks/useProjectRoles';
import { predefinedRoles } from 'app/utils/constant';
import RoleView from './RoleView';
import { useUserAuth } from 'app/hooks/userUserAuth';
import TableFilter from 'app/common/TableFilter';
import { StyledAddButton } from '../../listings/StyledComponent';
import { Card } from '@mui/material';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const SET_MENU_AND_MODAL = 'SET_MENU_AND_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

// TODO: chopstxx - custom hook for modal state (members section also)
const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  anchorEl: null,
  modalState: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
};

const menuActions = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
};

function RolesSection() {
  const { isOnHoldOrCancelled, projectId } = useProject();
  const { services } = useUserAuth();
  const { data: roles } = useProjectRoles(projectId, services);

  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedRoleRef = useRef(null);

  const openModal = (action) => {
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: {
        modalState: { [action]: true },
      },
    });
  };

  const closeModal = (action) => {
    selectedRoleRef.current = null;
    dispatch({ type: CLOSE_MODAL, payload: action });
  };

  const handleMenuOpen = useCallback((event, role) => {
    selectedRoleRef.current = role;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: event.currentTarget, modalState: {} },
    });
  }, []);

  const handleMenuClose = () => {
    selectedRoleRef.current = null;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: null, modalState: {} },
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ px: 2, py: 1 }} elevation={0}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item display='flex' justifyContent='flex-start' alignItems='center' gap={0.5}>
                <Title>Roles</Title>
              </Grid>
              <Grid item>
                <StyledAddButton startIcon={<StyledIcon />} onClick={() => openModal('add')}>
                  Roles
                </StyledAddButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableFilter title={'Roles'} />
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <RolesTable
            roles={roles}
            isOnHoldOrCancelled={isOnHoldOrCancelled}
            handleMenuOpen={handleMenuOpen}
          />
        </Grid>
      </Grid>
      <Menu anchorEl={state.anchorEl} open={Boolean(state.anchorEl)} onClose={handleMenuClose}>
        {Object.values(menuActions).map((action) => (
          <MenuItem
            key={action}
            onClick={() => openModal(action)}
            disabled={
              [menuActions.EDIT, menuActions.DELETE].includes(action) &&
              selectedRoleRef.current?.name === predefinedRoles.OWNER_ROLE
            }
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </MenuItem>
        ))}
      </Menu>
      {state.modalState.view && (
        <RoleView role={selectedRoleRef.current} closeModal={() => closeModal('view')} />
      )}
      {(state.modalState.add || state.modalState.edit) && (
        <RoleAddEdit
          projectId={projectId}
          selectedRole={selectedRoleRef.current}
          closeModal={() => closeModal(state.modalState.add ? 'add' : menuActions.EDIT)}
        />
      )}
      {state.modalState.delete && (
        <RoleDeleteConfirmation
          role={selectedRoleRef.current}
          closeModal={() => closeModal(menuActions.DELETE)}
        />
      )}
    </>
  );
}

export default RolesSection;
