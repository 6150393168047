import { Title } from 'app/common/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import SouthIcon from '@mui/icons-material/South';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import CSVExporter from 'app/utils/CSVExporter';
import notify from 'app/utils/notify';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

function MembersExport({ projectTitle, filteredMembers }) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => {
    if (!filteredMembers.length > 0) {
      notify.error('There are no members to export');
      return;
    }
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const handleYesProceed = () => {
    const fields = ['memberName', 'userRoles', 'phone', 'email', 'status'];
    const fileName = `${projectTitle}_members_export`;

    const exporter = new CSVExporter(fields);
    exporter.createAndDownloadCSV(filteredMembers, fileName);

    closeModal();
  };

  return (
    <>
      <Box
        marginLeft={3}
        marginRight={3}
        justifyContent='flex-end'
        display={'flex'}
        alignItems={'center'}
        onClick={openModal}
      >
        <SouthIcon
          variant='text'
          style={{
            boxShadow: 'none',
            cursor: 'pointer',
            color: 'black',
            fontSize: '18px',
            backgroundColor: 'transparent',
          }}
        ></SouthIcon>
        <Typography style={{ cursor: 'pointer', color: 'black', fontSize: '15px' }}>
          Export
        </Typography>
      </Box>
      <Modal open={isOpenModal} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '32%',
          }}
        >
          <SubtitleDivider />
          <Box
            sx={{
              py: 1,
              px: 2,
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Title>Members Export</Title>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box
            sx={{
              marginLeft: '4px',
            }}
          >
            <Box sx={{ p: 2, paddingLeft: 3, paddingRight: 3 }}>
              <Typography style={{ fontSize: '16px', fontWeight: '450' }}>
                Are you sure want export selected members?
              </Typography>
            </Box>

            <ModalDivider />
            <Box
              sx={{
                justifyContent: 'flex-end',
                display: 'flex',
                gap: 2,
                p: 2,
              }}
            >
              <LoadingButton color='error' variant='outlined' onClick={closeModal}>
                Cancel
              </LoadingButton>
              <LoadingButton variant='contained' color='primary' onClick={handleYesProceed}>
                Yes Proceed
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default MembersExport;
