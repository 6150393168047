import React, { useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledBox1,
  StyledModalTitle,
  StyledBox2,
  StyledCrossIcon,
  StyledBox3,
  StyledTextField,
  StyledLabel,
  StyledBox4,
  ModalButtonCancle,
  ModalButtonSave,
  UploadButton,
  CenteredBox
} from '../StyleComponents';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { handleAxiosError } from 'app/utils/helpers';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { useProfileEditDetails } from '../hooks/useProfileEditDetails';
import { ModalDivider, ModalStyledBox, ModalStyledSubBox } from 'app/common/Typography';

const PersonalInfoModal = ({ openModal, handleCloseModal }) => {
  const { user, userNameJWT } = useUserAuth();
  const { mutate: userEditDetails } = useProfileEditDetails(userNameJWT);
  const [isLoading, setIsLoading] = useState(false);
  console.log("user",user)
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .min(2, 'First Name must be at least 2 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .min(2, 'Last Name must be at least 2 characters'),
    dateOfBirth: Yup.date()
      .required('Date of Birth is required')
      .max(new Date(), 'Date of Birth cannot be in the future'),
    ssn: Yup.string()
      .matches(/^[0-9]{9}$/, 'SSN must be exactly 9 digits')
      .nullable(),
    image: Yup.mixed()
      .nullable()
      .test('fileSize', 'File size is too large', (value) => {
        return !value || (value && value.size <= 2 * 1024 * 1024);
      })
      .test('fileType', 'Unsupported file format', (value) => {
        return (
          !value ||
          (value && ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type))
        );
      }),
  });

  const initialValues = {
    firstName: user?.name || '',
    lastName: user?.surname || '',
    dateOfBirth: user?.dob || '',
    ssn: user?.ssn || '',
    image: null,  // Initially no image selected
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      await userEditDetails(values);
      handleCloseModal();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalStyledBox>
      <ModalStyledSubBox>
        <StyledModalTitle variant="h6">Personal Info</StyledModalTitle>
        <StyledCrossIcon onClick={handleCloseModal}>
          <CloseIcon />
        </StyledCrossIcon>
      </ModalStyledSubBox>
      {/* <ModalDivider /> */}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <CenteredBox>
              <UploadButton component="label">
                {values.image ? (
                  <Avatar
                    src={URL.createObjectURL(values.image)}
                    sx={{ width: '100%', height: '100%' }}
                    alt="Uploaded"
                  />
                ) : (
                  <Typography variant="body2" color="#17181B">
                    Upload Image
                  </Typography>
                )}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFieldValue('image', file);
                  }}
                />
              </UploadButton>
              {errors.image && touched.image && (
                <Typography variant="caption" color="error">
                  {errors.image}
                </Typography>
              )}
            </CenteredBox>

            <StyledBox3>
              <Box>
                <StyledLabel>First Name</StyledLabel>
                <StyledTextField
                  type="text"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                />

                <StyledLabel>Last Name</StyledLabel>
                <StyledTextField
                  type="text"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                />

                <StyledLabel>Date of Birth</StyledLabel>
                <StyledTextField
                  type="date"
                  name="dateOfBirth"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dateOfBirth}
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={touched.dateOfBirth && !!errors.dateOfBirth}
                  helperText={touched.dateOfBirth && errors.dateOfBirth}
                />

                <StyledLabel>Social Security Number (Optional)</StyledLabel>
                <StyledTextField
                  type="text"
                  name="ssn"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ssn}
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={touched.ssn && !!errors.ssn}
                  helperText={touched.ssn && errors.ssn}
                />

                <StyledBox4 sx={{marginTop:'6.5rem'}}>
                  <ModalButtonCancle
                    variant="outlined"
                    onClick={handleCloseModal}
                    disabled={isLoading}
                  >
                    Cancel
                  </ModalButtonCancle>
                  <ModalButtonSave
                    type="submit"
                    loading={isLoading}
                  >
                    Save
                  </ModalButtonSave>
                </StyledBox4>
              </Box>
            </StyledBox3>
          </form>
        )}
      </Formik>
    </ModalStyledBox>
  );
};

export default PersonalInfoModal;
