import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  IconButton,
  styled,
  Typography,
  Button,
  Autocomplete,
  Input,
  Grid,
  TextField,
} from '@mui/material';
import { Formik, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import notify from 'app/utils/notify';
import { Title, CustomGCExpensesInputText } from 'app/common/Typography';
import { DownloadDragIcon, PdfIcon, DocIcon } from 'app/common/icons';
import { useProject } from 'app/contexts/ProjectContext';
import { paymentOptions, expenseTypes } from 'app/utils/constant';


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1em',
  borderRadius: '4px',
  marginTop: '10px',
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '3px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: '#F8F8F8',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '22vh',
  overflowY: 'auto',
  width: '100%',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
  flexGrow: 1,
});

const AutoComplete = styled(Autocomplete)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
  },
}));

const validationSchema = Yup.object().shape({
  expensesName: Yup.string().required('Expenses Name is required'),
  totalAmount: Yup.number().required('Amount is required').positive('Amount must be positive'),
  paidAmount: Yup.number()
    .required('Paid Amount is required')
    .min(0, 'Paid Amount cannot be negative')
    .test('is-less-than-total', 'Paid Amount cannot be greater than Total Amount', function (value) {
      const { totalAmount } = this.parent;
      return value <= totalAmount;
    }),
  invoiceNo: Yup.string().required('InvoiceNo is required'),
  expenseType: Yup.string().required('Expense Type is required'),
  modeOfPayment: Yup.string().required('Mode of Payment is required'),
  comments: Yup.string(),
  paidTo: Yup.string().required('PaidTo is required'),
  documents: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().required('Document is required'),
      docName: Yup.string().required('Document Name is required'),
    }),
  ),
});

const LeadsExpensesModal = ({ handleCloseModal, expense }) => {
  const [selecteOption, setSelecteOption] = useState(null);
  const [selectedExpenseType, setSelectedExpenseType] = useState(null);
  const { uploadDocuments, addExpenses, documents, updateExpense } = useProject();
  const [latestPaymentDetails, setLatestPaymentDetails] = useState(expense?.paymentDetails.length);
  const [initialDocumentName, setInitialDocumentName] = useState('');

  React.useEffect(() => {
    if (expense) {
      setSelecteOption(paymentOptions.find(option => option.label === expense?.paymentDetails[latestPaymentDetails - 1]?.modeOfPayment));
      setSelectedExpenseType(expenseTypes.find(option => option.label === expense?.paymentDetails[latestPaymentDetails - 1]?.expenseType))
    }
  }, [expense]);

  function handleFileChange(event, values, setFieldValue) {
    const files = Array.from(event.target.files);
    const updatedDocuments = [...values.documents, ...files.map((file) => ({ file, docName: '' }))];
    setFieldValue('documents', updatedDocuments);
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDeleteFile = (index, values, setFieldValue) => {
    const updatedDocuments = [...values.documents];
    updatedDocuments.splice(index, 1);
    setFieldValue('documents', updatedDocuments);
  };

  const handleDrop = (event, values, setFieldValue) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const updatedDocuments = [...values.documents, ...files.map((file) => ({ file, docName: '' }))];
    setFieldValue('documents', updatedDocuments);
  };


  function base64ToFile(base64String, fileName, mimeType) {
    const byteString = atob(base64String);
    const byteNumbers = new Array(byteString.length)
      .fill(null)
      .map((_, i) => byteString.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], fileName, { type: mimeType });
  }

  const initialValues = {
    expensesName: expense?.paymentDetails[latestPaymentDetails - 1].expensesName || '',
    totalAmount: expense?.paymentDetails[latestPaymentDetails - 1].totalAmount || '',
    paidAmount: expense?.paymentDetails[latestPaymentDetails - 1].paidAmount || '',
    balanceAmount: expense?.paymentDetails[latestPaymentDetails - 1]?.balance,
    prevBalance: expense?.paymentDetails[latestPaymentDetails - 2]?.balance,
    invoiceNo: expense?.invoiceNo || '',
    expenseType: expense?.paymentDetails[latestPaymentDetails - 1]?.expenseType || '',
    paidTo: expense?.paymentDetails[latestPaymentDetails - 1].paidTo || '',
    modeOfPayment: expense?.paymentDetails[latestPaymentDetails - 1].modeOfPayment || '',
    comments: expense?.paymentDetails[latestPaymentDetails - 1].comments || '',
    documents: documents.filter((doc) => expense?.paymentDetails[latestPaymentDetails - 1].paymentDocuments?.[0]?.includes(doc.metadata.fileId))
      .map((doc, index) => {
        const mimeType = doc?.metadata?.documentType || 'application/pdf';
        const extension = mimeType === 'application/pdf' ? 'pdf' : 'docx';
        return {
          file: base64ToFile(doc.content, `${doc.metadata.documentName}.${extension}`, mimeType),
          docName: doc.metadata.documentName || `Document ${index + 1}`,
          docContent: doc.content
        };
      }),
  };
  const handleFormSubmit = async (values, { setSubmitting }) => {

    if (values.documents.length === 0) {
      notify.error('Please add at least one document.');
      setSubmitting(false);
      return;
    }

    const data = new FormData();
    const documentIds = [];
    for (let index = 0; index < values.documents.length; index++) {
      const doc = values.documents[index];
      const isExistingDocument = initialValues?.documents.some(
        (existingDoc) => existingDoc.docName === doc.docName && existingDoc.docContent === doc.docContent
      );
      if (!isExistingDocument && doc.file instanceof File) {
        data.append(`docReq[${index}].file`, doc.file);
        data.append(`docReq[${index}].docName`, doc.docName);
        data.append(`docReq[${index}].type`, "Expense documents");
      } else {
        documentIds.push(expense?.paymentDetails[latestPaymentDetails - 1]?.paymentDocuments?.[index]);
      }
    }
    try {
      if (data.has('docReq[0].file')) {
        const uploadResponse = await uploadDocuments(data);
        const uploadedDocumentIds = uploadResponse.data
          .match(/Document IDs: \[([^\]]+)\]/)[1]
          .split(', ');
        documentIds.push(...uploadedDocumentIds);
      }

      const expenseDetails = {
        expensesName: values.expensesName,
        totalAmount: values.totalAmount,
        paidAmount: values.paidAmount,
        paidTo: values.paidTo,
        expenseType: values.expenseType,
        modeOfPayment: values.modeOfPayment,
        comments: values.comments,
        paymentDocuments: documentIds,
      };

      if (expense) {
        const updatedExpense = {
          ...expenseDetails,
        };
        await updateExpense(expense?.projectId, expense?.invoiceNo, expense?.id, updatedExpense);
      } else {
        const newexpense = {
          invoiceNo: values.invoiceNo,
          paymentDetails: [expenseDetails]
        }
        await addExpenses(newexpense);
      }
      handleCloseModal();
    } catch (error) {
      if (error.response.data.error) {
        notify.error(error.response.data.errors[0].errorMessage);
      } else {
        notify.error(`Error in creating expense: ${error.message}`);
      }
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{expense ? 'Update' : 'Add'} Expenses</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: '#272937',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
            overflowY: 'auto',
            maxHeight: 'calc(100% - 75px)',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label='Expenses Name'
                        type='text'
                        name='expensesName'
                        value={values.expensesName}
                        InputProps={{
                          readOnly: Boolean(initialValues.expensesName),
                          sx: Boolean(initialValues.expensesName)
                            ? {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                            : {},
                        }}
                        onChange={handleChange}
                        placeholder='e.g. : Sample expenses'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.expensesName && errors.expensesName)}
                        helperText={touched.expensesName && errors.expensesName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type='number'
                        name='totalAmount'
                        value={values.totalAmount}
                        InputProps={{
                          readOnly: Boolean(initialValues.totalAmount),
                          sx: Boolean(initialValues.totalAmount)
                            ? {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                            : {},
                        }}
                        onChange={handleChange}
                        placeholder='e.g. $2000.00'
                        label='Amount'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.totalAmount && errors.totalAmount)}
                        helperText={touched.totalAmount && errors.totalAmount}
                        inputProps={{ min: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name='paidAmount'
                        value={values.paidAmount}
                        onChange={handleChange}
                        label='Paid Amount'
                        placeholder='e.g. : $18000.00'
                        fullWidth
                        variant='outlined'
                        inputProps={{ min: 0 }}
                        size='small'
                        error={Boolean(touched.paidAmount && errors.paidAmount)}
                        helperText={touched.paidAmount && errors.paidAmount}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type='number'
                        name='blanceAmount'
                        value={values.balanceAmount ? values.balanceAmount : values.balanceAmount === 0.00 && expense?.paymentDetails[latestPaymentDetails - 1].status === 'Paid' ? '0.00' : (values.totalAmount - values.paidAmount)}
                        onChange={handleChange}
                        placeholder='e.g. $2000.00'
                        label='Balance Amount'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                          readOnly: true,
                          sx: {
                            fontWeight: 'bold',
                            backgroundColor: '#f0f0f0f0',
                          },
                        }}
                        inputProps={{ min: 0 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name='invoiceNo'
                        value={values.invoiceNo}
                        onChange={handleChange}
                        label='InvoiceNo'
                        placeholder='e.g. : $INV700'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                          readOnly: Boolean(initialValues.invoiceNo),
                          sx: Boolean(initialValues.expensesName)
                            ? {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                            : {},
                        }}
                        error={Boolean(touched.invoiceNo && errors.invoiceNo)}
                        helperText={touched.invoiceNo && errors.invoiceNo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AutoComplete
                        size='small'
                        value={selectedExpenseType}
                        onChange={(event, newValue) => {
                          setSelectedExpenseType(newValue || expenseTypes[0]);
                          setFieldValue('expenseType', newValue ? newValue.label : '');
                        }}
                        options={expenseTypes}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            fullWidth
                            label='Expense Type'
                            className='textfiled'
                            style={{ width: '87%' }}
                            error={Boolean(touched.expenseType && errors.expenseType)}
                            helperText={touched.expenseType && errors.expenseType}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type='text'
                        name='paidTo'
                        value={values.paidTo}
                        onChange={handleChange}
                        label='Paid To'
                        placeholder='e.g. : John doe'
                        fullWidth
                        variant='outlined'
                        inputProps={{ min: 0 }}
                        size='small'
                        error={Boolean(touched.paidTo && errors.paidTo)}
                        helperText={touched.paidTo && errors.paidTo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AutoComplete
                        size='small'
                        value={selecteOption}
                        onChange={(event, newValue) => {
                          setSelecteOption(newValue || paymentOptions[0]);
                          setFieldValue('modeOfPayment', newValue ? newValue.label : '');
                        }}
                        options={paymentOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            fullWidth
                            label='Mode Of Payment'
                            className='textfiled'
                            style={{ width: '87%' }}
                            error={Boolean(touched.modeOfPayment && errors.modeOfPayment)}
                            helperText={touched.modeOfPayment && errors.modeOfPayment}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Container>
                <Typography style={{ fontSize: '15px', fontWeight: '450', marginTop: '8px' }}>
                  Expense Document
                </Typography>
                <Container
                  style={{ border: '2px dashed lightgray', height: '0.8%', width: '97%' }}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, values, setFieldValue)}
                >
                  <Typography variant='body1' color={'#64748B'} fontSize={'1.2em'}>
                    <DownloadDragIcon />
                    {` Drag and Drop file here or `}
                    <label htmlFor='outlined-button-file'>
                      <StyledButton
                        style={{
                          fontSize: '1em',
                        }}
                        disabled={values.documents.length > 0}
                        variant='outlined'
                        component='span'
                      >
                        Browse files
                      </StyledButton>
                    </label>
                  </Typography>
                  <Input
                    id={`outlined-button-file`}
                    sx={{
                      display: 'none',
                    }}
                    type='file'
                    size='small'
                    name='file'
                    onChange={(e) => handleFileChange(e, values, setFieldValue)}
                    inputProps={{ accept: '.pdf, .doc, .docx' }}
                    error={Boolean(
                      getIn(errors, `documents[0].file`) && getIn(touched, `documents[0].file`),
                    )}
                    helperText={
                      getIn(errors, `documents[0].file`) && getIn(touched, `documents[0].file`)
                        ? 'Document is required'
                        : ''
                    }
                  />
                </Container>
                <Typography variant='body1' color={'#64748B'} fontSize={'12px'} pt={2}>
                  {`File format only word or Pdf, Size should not exceed 10MB`}
                </Typography>
                <FileContainerDetails>
                  <FieldArray name='documents'>
                    {({ remove }) =>
                      values.documents.map((doc, index) => (
                        <FileContainer key={index}>
                          <ImageContainer>
                            {doc.file.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                            <FileDetails>
                              <Typography
                                variant='body1'
                                style={{ fontWeight: 'bold', marginLeft: '10px' }}
                              >
                                {doc.file.name}
                              </Typography>
                              <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                {(doc.file.size / 1024).toFixed(2)} KB
                              </Typography>
                            </FileDetails>
                            <IconButton
                              onClick={() => handleDeleteFile(index, values, setFieldValue)}
                            >
                              <DeleteIcon style={{ color: 'red' }} />
                            </IconButton>
                          </ImageContainer>
                          <ModalDivider />
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <CustomGCExpensesInputText
                                name={`documents[${index}].docName`}
                                placeholder='Document Name'
                                touched={touched}
                                value={doc.docName}
                                onChange={handleChange}
                                error={Boolean(
                                  getIn(errors, `documents[${index}].docName`) &&
                                  getIn(touched, `documents[${index}].docName`),
                                )}
                                helperText={
                                  getIn(errors, `documents[${index}].docName`) &&
                                    getIn(touched, `documents[${index}].docName`)
                                    ? 'Document Name is required'
                                    : ''
                                }
                              />
                            </Grid>
                          </Grid>
                        </FileContainer>
                      ))
                    }
                  </FieldArray>
                </FileContainerDetails>
                <Grid item xs={8}>
                  <TextField
                    label='Comments'
                    name='comments'
                    value={values.comments}
                    onChange={handleChange}
                    fullWidth
                    size='small'
                    type='text'
                    variant='outlined'
                    placeholder='Write Here'
                    multiline
                    rows={2}
                    sx={{ mb: 1, mt: 1 }}
                  />
                </Grid>
                <Box
                  sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <LoadingButton
                    className='cancelButton'
                    color='error'
                    variant='outlined'
                    onClick={handleCloseModal}
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    style={{ boxShadow: 'none', marginRight: '10px' }}
                    type='submit'
                    loading={isSubmitting}
                  >
                    {expense ? 'Update' : 'Save'}
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  )
}
export default LeadsExpensesModal;



