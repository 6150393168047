import { useProject } from 'app/contexts/ProjectContext';
import { requestStatusMessages, requestsTypes } from 'app/utils/constant';
import RequestsList from 'app/views/dashboard/Requests/RequestsList';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { useProjectRequests } from './hooks/useProjectRequests';
import { Container, Grid, styled } from '@mui/material';
import { Title } from 'app/common/Typography';

const currentType = 'PENDING';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});
function RequestsSection() {
  const { projectId } = useProject();
  const { data: requests } = useProjectRequests(projectId);

  const filteredAndMappedRequests = useMemo(
    () =>
      requests
        .filter(
          (request) =>
            request.status === currentType &&
            (request.invitationType === requestsTypes.INVESTMENT_MAIL ||
              request.invitationType === requestsTypes.PAYMENT_REQUEST_MAIL),
        )
        .map((request) => ({
          ...request,
          projectTitle: 'this project',
        })),
    [requests],
  );

  return (
    <>
    <SubtitleDivider />
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        spacing={2}
        sx={{ px: 3, py: 1 }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid
            container
            spacing={2}
            alignItems='center'
            sx={{ justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' } }}
          >
            <Grid item>
              <Title>Requests</Title>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    <Box sx={{ overflowY: 'auto' }}>
      {filteredAndMappedRequests.length > 0 ? (
        <RequestsList requests={filteredAndMappedRequests} />
      ) : (
        <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '380px',
                width: '1035px',
                marginLeft: '8%',
                borderRadius: '8px',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{ fontWeight: '600' }}
                variant='body1'
                color='var(--noDetails-text)'
                gutterBottom
              >
                No Requests
              </Typography>
              <Typography variant='body2' color='textSecondary' paragraph>
                {requestStatusMessages[currentType].message}
              </Typography>
            </Box>
          </Container>
      )}
    </Box>
    </>
  );
}

export default RequestsSection;
