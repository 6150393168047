import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Tooltip, Link } from '@mui/material';
import AddUpdateExpensesDialog from './AddUpdateExpensesDialog';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { dateFormats } from 'app/utils/constant';
import { useProjectExpenseInvoice } from './hooks/useProjectExpenseInvoice';

const ExpenseInvoice = ({ selectedExpense, expensesDocuments }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const { data: expenseInvoice, isPending } = useProjectExpenseInvoice(
    selectedExpense?.projectId,
    selectedExpense?.invoiceNo,
    selectedExpense?.id,
  );

  const isInvoicePaid = selectedExpense?.paymentDetails?.[0].status === 'Paid';

  if (!selectedExpense) {
    return <Typography>No expense data available</Typography>;
  }

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '1%' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography>
            {selectedExpense?.expensesName} (Invoice: {selectedExpense?.invoiceNo})
          </Typography>
          <Link
            component='button'
            sx={{
              marginRight: '1%',
              color: isInvoicePaid ? 'var(--update-Balance-paid)' : 'var(--update-Balance-notPaid)',
              textDecoration: isInvoicePaid ? 'none' : 'underline',
              cursor: isInvoicePaid ? 'not-allowed' : 'pointer',
            }}
            onClick={toggleDialog}
            disabled={isInvoicePaid}
          >
            Update Balance
          </Link>
        </Box>

        {isPending ? (
          <Typography variant='h6'>Loading Expense Details ...</Typography>
        ) : expenseInvoice.length > 0 ? (
          expenseInvoice.map((paymentDetail) => (
            <Paper
              elevation={3}
              sx={{
                p: 2,
                width: '100%',
                marginBottom: '1%',
              }}
              key={paymentDetail.updatedDate}
            >
              <Typography
                color='var(--updatedTime)'
                align='center'
                bgcolor='var(--updatedTime-bg)'
                fontWeight='500'
                width='155px'
                gutterBottom
              >
                {convertUTCDateToLocal(paymentDetail.updatedDate, dateFormats.time12.full)}
              </Typography>
              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Total Amount
                  </Typography>
                  <Typography variant='h6'>${paymentDetail.totalAmount.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Paid Amount
                  </Typography>
                  <Typography variant='h6' color='primary'>
                    ${paymentDetail?.paidAmount.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Balance Amount
                  </Typography>
                  <Typography variant='h6' color='var(--expense-balance)'>
                    ${(paymentDetail?.balance).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Payment Mode
                  </Typography>
                  <Typography variant='h6'>{paymentDetail?.modeOfPayment}</Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Paid To
                  </Typography>
                  <Typography variant='h6'>{paymentDetail.paidTo}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Expense Type
                  </Typography>
                  <Typography variant='h6'>{paymentDetail?.expenseType}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Status
                  </Typography>
                  <Typography variant='h6'>{paymentDetail?.status}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Comments
                  </Typography>
                  <Typography variant='body2'>
                    {paymentDetail.comments?.split(' ').length > 10 ? (
                      <>
                        {paymentDetail.comments.split(' ').slice(0, 10).join(' ')}&nbsp;
                        <Tooltip title={paymentDetail?.comments || ''} placement='top'>
                          <span
                            style={{
                              cursor: 'pointer',
                              color: 'var(--comments-toolTip)',
                              fontSize: '1.5em',
                            }}
                          >
                            ...
                          </span>
                        </Tooltip>
                      </>
                    ) : (
                      paymentDetail?.comments
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Typography>No payment details available</Typography>
        )}
      </Box>
      {isOpenDialog && (
        <AddUpdateExpensesDialog
          selectedExpense={selectedExpense}
          closeDialog={toggleDialog}
          expensesDocuments={expensesDocuments}
        />
      )}
    </>
  );
};

export default ExpenseInvoice;
