import NewLead from '../views/dashboard/leads/NewLeads/NewLead';
import ProjectProvider from 'app/contexts/ProjectContext';
import RequestsLayout from 'app/components/Layout/Requests/RequestsLayout';
import ProfileTabs from 'app/views/dashboard/profile/ProfileTabs';
import Dashboard from 'app/components/Layout/BasicLayout/Dashboard';
import ListingLayout from 'app/views/dashboard/leads/listings/ListingLayout';
import ProjectTabs from 'app/views/dashboard/leads/listings/ProjctTabs';
import Projects from '../views/dashboard/leads/Projects';
import PropertyDetailsView from 'app/views/dashboard/leads/listings/ProjectDetailsView';
import LeadsContractDetails from 'app/views/dashboard/leads/LeadsData/Contract/ContractSection';
import LeadsClosingDetails from 'app/views/dashboard/leads/LeadsData/Closing/ClosingSection';
import RequestsSection from 'app/views/dashboard/leads/LeadsData/Requests/RequestsSection';
import RolesSection from 'app/views/dashboard/leads/LeadsData/Roles/RolesSection';
import ExpensesSection from 'app/views/dashboard/leads/LeadsData/Expenses/ExpensesSection';
import GeneralContactorSection from 'app/views/dashboard/leads/LeadsData/GC/GeneralContactorSection';
import PMSection from 'app/views/dashboard/leads/LeadsData/PM/PMSection';
import ConstructionLenderSection from 'app/views/dashboard/leads/LeadsData/ContructionLender/ConstructionLenderSection';
import { Navigate } from 'react-router-dom';
import LeaseSaleSection from 'app/views/dashboard/leads/LeadsData/Lease&Sale/LeaseSalesSection';
import MembersPage from 'app/views/dashboard/leads/LeadsData/Members/MembersPage';
import ChatHistory from 'app/components/Layout/BasicLayout/ChatHistory';
import AmenitiesSection from 'app/views/dashboard/leads/LeadsData/Amenities/AmenitiesSection';
import InvestmentPage from 'app/views/dashboard/leads/LeadsData/Investments/InvestmentPage';

const dashboardRoutes = [
  { path: 'listing/newlead', element: <NewLead type='lead' /> },
  { path: 'myprojects/newlead', element: <NewLead type='newproject' /> },
  { path: '/', element: <Dashboard /> },
  { path: 'listing', element: <ListingLayout type='lead' /> },
  { path: 'myprojects', element: <ListingLayout type='newproject' /> },
  { path: 'ongoing', element: <Projects type='ongoing' /> },
  { path: 'cancelled', element: <Projects type='cancelled' /> },
  { path: 'onhold', element: <Projects type='onhold' /> },
  { path: 'completed', element: <Projects type='completed' /> },
  {
    path: 'requests',
    element: <RequestsLayout />,
  },
  // { path: 'profile', element: <Profile /> },
  { path: 'profile/*', element: <ProfileTabs /> },
  { path: 'chathistory', element: <ChatHistory /> },
  {
    path: 'listing/:projectId/*',
    element: (
      <ProjectProvider>
        <ProjectTabs />
      </ProjectProvider>
    ),
    children: [
      { index: true, element: <Navigate to='propertyinformation' replace /> },
      { path: 'propertyinformation', element: <PropertyDetailsView lead={true} /> },
      { path: 'requests', element: <RequestsSection /> },
      { path: 'roles', element: <RolesSection /> },
      { path: 'amenities', element: <AmenitiesSection /> },
      { path: 'members', element: <MembersPage title='Members' /> },
      { path: 'generalpartners', element: <MembersPage title='General Partners' /> },
    ],
  },
  {
    path: 'myprojects/:projectId/*',
    element: (
      <ProjectProvider>
        <ProjectTabs />
      </ProjectProvider>
    ),
    children: [
      { index: true, element: <Navigate to='propertyinformation' replace /> },
      { path: 'propertyinformation', element: <PropertyDetailsView lead={false} /> },
      { path: 'contractdetails', element: <LeadsContractDetails /> },
      { path: 'closingdetails', element: <LeadsClosingDetails /> },
      { path: 'requests', element: <RequestsSection /> },
      { path: 'roles', element: <RolesSection /> },
      { path: 'amenities', element: <AmenitiesSection /> },
      { path: 'expenses', element: <ExpensesSection /> },
      { path: 'generalcontractor', element: <GeneralContactorSection /> },
      { path: 'lease&sale', element: <LeaseSaleSection /> },
      { path: 'propertymanagement', element: <PMSection /> },
      { path: 'constructionlender', element: <ConstructionLenderSection /> },
      { path: 'members', element: <MembersPage title='Members' /> },
      { path: 'generalpartners', element: <MembersPage title='General Partners' /> },
      { path: 'investors', element: <InvestmentPage /> },
    ],
  },
];

export default dashboardRoutes;
