import { styled } from "@mui/material/styles";
import { Box, MenuItem } from '@mui/material';
import { ProfileIcon } from '../../../common/icons.js';

//LayoutSidenav.jsx

export const SideNavContainer = styled("div")(({ theme }) => ({
  width: "6.75rem",
  height: "100vh",
  backgroundColor: theme.palette.text.TopNavbg,
  color: theme.palette.text.TopNavColor,
  display: "flex",
  flexDirection: "column",
  borderRight: `1px solid ${theme.palette.text.border}`,
  // position: 'fixed',
  left: 0,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top',
  backgroundSize: 'cover',
  zIndex: 111,
  overflow: 'hidden',
}));

export const NavList = styled("ul")({
  listStyle: "none",
  marginTop: '0.75rem',
  padding: 0,
  flexGrow: 1,
});

export const NavItem = styled("li")(({ isActive,theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: '0.65rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  cursor: "pointer",
  borderRadius: '8px 0 0 8px',
  width: '99px',
  height: '74px',
  color: isActive ? theme.palette.text.buttonbg:theme.palette.text.cancleButton,
  backgroundColor: isActive ? theme.palette.text.white :theme.palette.text.TopNavbg,
  borderLeft: isActive ? `5px solid ${theme.palette.text.buttonbg}` : `5px solid ${theme.palette.text.TopNavbg}`,
  boxShadow: isActive
    ? `0 3px 4px ${theme.palette.text.boxShadow}`
    : "none",
}));

export const NavIcon = styled("span")(({ isActive }) => ({
  fontSize: "1.5rem",
}));

export const NavLabel = styled("span")({
  marginTop: "4px",
  fontWeight: '500',
  fontSize: "14px",
});

//LayoutTopbar.jsx
export const TopBarContainer = styled("div")(({theme})=>({
  width: "100%",
  height: "72px",
  top: 0,
  zIndex: 96,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 1rem",
  backgroundColor:theme.palette.text.buttonbg,
  color:theme.palette.text.white,
  boxSizing: "border-box",
}));

export const UserMenu = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: 24,
  margin: 10,
}));

export const Profileicon=styled(ProfileIcon)(()=>({
  cursor:'pointer',
  height:'31px',
  width:'31px',
}));

export const Logo = styled("h1")({
  fontSize: "1.8rem",
  width: '98px',
  height: '24px',
  marginLeft: '0.75rem',
  marginTop: '-0.1px'
});

export const ActionContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  justifyContent: "flex-end",
  width: "100%",
});

export const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));


//BasicLayout.jsx
export const LayoutRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow:'hidden',
  height: "100vh",
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
}));

export const SidebarContainer = styled(Box)(({ theme }) => ({
  width: "108px",
  height: "calc(100vh - 64px)",
  backgroundColor:theme.palette.text.white,
  borderRight: `1px solid ${theme.palette.divider}`,
  // position: "fixed",
  left: 0,
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: "auto",
  backgroundColor: '#F9F9F9',
  // marginLeft: "108px", // Space for Sidebar
  // marginTop: "64px",
}));