import React from 'react';
import { Box, TextField,Modal, Typography, Avatar, Link, Button, IconButton, Grid, Card, Divider, ListItemText, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { PDSubHeadings, PDSubValues } from '../StyledComponent';
import { EditIcon } from 'app/common/icons';
import {StyledBox1,StyledModalTitle,StyledCrossIcon,StyledBox2,
    StyledEditIcon
} from './StyledComponents';

const ViewPropertyDetailsModal = ({ handleCloseViewModal,handleOpenEditModal,property }) => {
    return (
        <>
            <StyledBox1>
                <StyledBox2  >
                    <StyledModalTitle>Property Details</StyledModalTitle>
                    <StyledCrossIcon onClick={handleCloseViewModal}>
                        <CloseIcon />
                    </StyledCrossIcon>
                </StyledBox2>
                <Box sx={{ marginTop: '30px', marginLeft: '22px' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <PDSubHeadings>
                                Property Type
                            </PDSubHeadings>
                            <PDSubValues>{property?.projectType}</PDSubValues>
                        </Grid>
                        <Grid item xs={4}>
                            <PDSubHeadings>
                                Sub Type
                            </PDSubHeadings>
                            <PDSubValues>{property?.subType}</PDSubValues>
                        </Grid>
                        <Grid item xs={4}>
                            <PDSubHeadings>
                                Area(in acres)
                            </PDSubHeadings>
                            <PDSubValues>{property?.size}</PDSubValues>
                        </Grid>
                        <Grid item xs={4}>
                            <PDSubHeadings>
                                Zone
                            </PDSubHeadings>
                            <PDSubValues>-</PDSubValues>
                        </Grid>
                        <Grid item xs={8}>
                            <PDSubHeadings>
                                Estimated Price
                            </PDSubHeadings>
                            <PDSubValues>
                                ${property?.estimatedPrice}
                            </PDSubValues>
                        </Grid>
                    </Grid>
                </Box>
                {/* Description */}
                <Box sx={{ marginTop: '50px', marginLeft: '20px' }}>
                    <Typography sx={{ fontWeignt: '500', fontSize: '20px', color: '#17181B' }}>
                        Description :
                    </Typography>
                    <Typography
                        sx={{ fontWeignt: '500', fontSize: '16px', color: '#5F5F64', marginTop: '11px' }}
                    >
                        {property?.description}
                    </Typography>
                </Box>
                <Box sx={{ marginTop: '20px', marginLeft: '20px' }}>
                    <Typography sx={{ fontWeignt: '500', fontSize: '20px', color: '#17181B' }}>
                        Reference link :
                    </Typography>
                    <Typography
                        sx={{ fontWeignt: '500', fontSize: '16px', color: '#5F5F64', marginTop: '11px' }}
                    >
                        {property?.referenceLink}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#fff',
                        padding: '1rem',
                        borderTop: '1px solid #D6DEE9',
                        textAlign: 'center',
                        height: '66px'
                    }}
                >
                    <StyledEditIcon
                        variant="outlined"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={handleOpenEditModal}
                    >
                        Property Details
                    </StyledEditIcon>
                </Box>
            </StyledBox1>
        </>
    )
}

export default ViewPropertyDetailsModal;