import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, IconButton, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import LeadLeaseSuccess from './LeadLeaseSuccess';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const validationSchema = Yup.object({
  buildingName: Yup.string().required('Building name is required'),
  units: Yup.array().of(
    Yup.object({
      unitName: Yup.string().required('Unit name is required'),
      space: Yup.number().required('Space is required').min(1, 'Space must be greater than 0'),
    }),
  ),
});

const LeadAddBuilding = ({ open, onClose, onSave, initialBuildingName, handleViewUnits, buildingId }) => {
  const [successOpen, setSuccessOpen] = useState(false);
  const { updateLeaseSales } = useProject();
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [submittedBuildingName, setSubmittedBuildingName] = useState(initialBuildingName);

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    onClose();
  };

  useEffect(() => {
    let timer;
    if (successOpen) {
      timer = setTimeout(() => {
        handleSuccessClose();
      }, 1000); // Auto close after 3 seconds
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [successOpen]);

  return (
    <Dialog open={open}>
      <Formik
        initialValues={{
          buildingName: initialBuildingName || '',
          units: [],
          buildingId: buildingId || '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const buildingData = {
            buildingName: values.buildingName,
            units: values.units,
            ...(values.buildingId ? { buildingId: values.buildingId } : {}),
          };

          if (!initialBuildingName) {
            setSubmittedBuildingName(values.buildingName);
          }

          updateLeaseSales(buildingData);
          onSave(buildingData);
          setSuccessOpen(true);
          resetForm();
        }}
      >
        {({ values, errors, touched, setFieldValue, setTouched }) => (
          <Form style={{ width: '580px', height: '500px', position: 'relative' }}>
            <Box
              sx={{
                paddingY: 2,
                paddingX: 1,
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Title fontSize='22px' fontWeight='600' color='#272937'>
                Add Building
              </Title>
              <IconButton onClick={onClose}>
                <CloseIcon style={{ fontSize: 20, color: '#272937' }} />
              </IconButton>
            </Box>
            <ModalDivider />

            <Field
              as={TextField}
              fullWidth
              name='buildingName'
              placeholder='Enter Building name or number'
              variant='standard'
              size='small'
              sx={{ marginTop: 2, paddingX: 2 }}
              error={touched.buildingName && Boolean(errors.buildingName)}
              helperText={<ErrorMessage name='buildingName' />}
              InputProps={{
                readOnly: !!initialBuildingName,
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#E3EDF5',
                marginBottom: '10px',
                marginTop: '20px',
                paddingInline: '20px',
              }}
            >
              <Typography variant='subtitle2' fontSize='20px' fontWeight='600'>
                Unit(s)
              </Typography>
              <Typography
                onClick={() => {
                  setTouched({
                    buildingName: true,
                    units: values.units.map((_, index) => ({
                      unitName: true,
                      space: true,
                    })),
                  });

                  if (!values.buildingName.trim()) {
                    setFieldValue('buildingName', values.buildingName, true);
                  } else {
                    const lastUnit = values.units[values.units.length - 1];
                    if (!lastUnit || (lastUnit.unitName && lastUnit.space)) {
                      setFieldValue('units', [...values.units, { unitName: '', space: '' }]);
                    }
                  }
                }}
                variant='text'
                color='#4F90F0'
                fontWeight='500'
                fontSize='14'
                style={{ cursor: 'pointer' }}
              >
                + Add Unit
              </Typography>
            </div>

            <div
              style={{
                maxHeight: '200px',
                overflowY: 'auto',
                paddingInline: '20px',
                marginBottom: '20px',
              }}
            >
              {values.units.map((unit, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    marginTop: '10px',
                  }}
                >
                  <Field
                    as={TextField}
                    name={`units[${index}].unitName`}
                    label='Unit Name'
                    size='small'
                    fullWidth
                    error={
                      touched.units &&
                      touched.units[index]?.unitName &&
                      Boolean(errors.units?.[index]?.unitName)
                    }
                    helperText={<ErrorMessage name={`units[${index}].unitName`} />}
                    style={{ marginRight: '10px' }}
                  />
                  <Field
                    as={TextField}
                    name={`units[${index}].space`}
                    label='Space (Sq ft)'
                    size='small'
                    type='number'
                    fullWidth
                    inputProps={{ min: 1 }}
                    error={
                      touched.units &&
                      touched.units[index]?.space &&
                      Boolean(errors.units?.[index]?.space)
                    }
                    helperText={<ErrorMessage name={`units[${index}].space`} />}
                  />
                  <IconButton
                    onClick={() => {
                      const updatedUnits = values.units.filter((_, i) => i !== index);
                      setFieldValue('units', updatedUnits);
                    }}
                    color='error'
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>

            <div
              style={{
                position: 'absolute',
                bottom: '20px',
                left: '20px',
                right: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button onClick={onClose} variant='outlined' color='error'>
                Cancel
              </Button>
              <Button type='submit' variant='contained' color='primary'>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <LeadLeaseSuccess
        open={successOpen}
        handleClose={handleSuccessClose}
        buildingName={submittedBuildingName}
        onGoToUnits={handleViewUnits}
      />
    </Dialog>
  );
};

export default LeadAddBuilding;
