import React, { useState, useMemo } from 'react';
import { Autocomplete, styled, Box, Typography, Grid, Card, Divider } from '@mui/material';
import { useProject } from 'app/contexts/ProjectContext';
import { StyledIcon, Title } from 'app/common/Typography';
import AddUpdateExpensesDialog from './AddUpdateExpensesDialog.jsx';
import { documentTypes, expensesTypes } from 'app/utils/constant.js';
import { notify } from 'app/services/notify.js';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments.js';
import { useProjectExpenses } from './hooks/useProjectExpenses.js';
import { useSearchParams } from 'react-router-dom';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent.js';
import MembersFilter from '../Members/MembersFilter.jsx';
import ExpensesTable from './Expensestable.jsx';

const expenseTypeParam = 'expenseType';

const ExpensesSection = () => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: documents } = useProjectDocuments(projectId);
  const { data: expenses } = useProjectExpenses(projectId);
  console.log('expenses', expenses?.length);
  const [openRow, setOpenRow] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const expensesDocuments = useMemo(
    () => documents && documents.filter((doc) => doc.metadata.type === documentTypes.EXPENSES),
    [documents],
  );

  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);

  const toggleModal = () => setIsOpenDialog((prevState) => !prevState);

  const handleInvoiceDownload = (expense) => {
    try {
      expensesDocuments.forEach((doc) => {
        if (
          expense?.paymentDetails[expense?.paymentDetails?.length - 1].paymentDocuments?.includes(
            doc.metadata.fileId,
          )
        ) {
          handleDownload(doc.content, doc.metadata.documentName, doc.metadata.documentType);
        }
      });
    } catch (error) {
      notify.error(error);
    }
  };

  const handleDownload = (content, fileName, mimeType) => {
    try {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notify.error(error);
    }
  };

  const availableExpensesTypes = expensesTypes.map((type) => type.label);

  const handleFilterChange = (_, newValue) =>
    setSearchParams((searchParams) => {
      availableExpensesTypes.includes(newValue)
        ? searchParams.set(expenseTypeParam, newValue)
        : searchParams.delete(expenseTypeParam);
      return searchParams;
    });

  const expensesFilter = useMemo(() => {
    const expensesFilterURLString = searchParams.get(expenseTypeParam);
    const validFilter = availableExpensesTypes.includes(expensesFilterURLString)
      ? expensesFilterURLString
      : '';

    return validFilter;
  }, [searchParams, availableExpensesTypes]);

  const filteredExpenses = useMemo(
    () =>
      expensesFilter
        ? expenses?.filter((expense) =>
            expense.paymentDetails.some((detail) => detail.expenseType === expensesFilter),
          )
        : expenses,
    [expenses, expensesFilter],
  );

  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <>
          {(!expenses || (Array.isArray(expenses) && expenses.length === 0)) && (
            <>
              <Box
                sx={{ margin: '20px 38px 20px 20px' }}
                display='flex'
                justifyContent='space-between'
              >
                <StledCardTitle>Expenses</StledCardTitle>
                <StyledAddButton startIcon={<StyledIcon />} onClick={toggleModal}>
                  Expenses
                </StyledAddButton>
              </Box>
              <Divider />
            </>
          )}

          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {!expenses || !Array.isArray(expenses) || expenses?.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Expenses Available
                </Typography>
              </div>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <Card sx={{ px: 2, py: 1 }} elevation={0}> */}
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid
                        item
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Title>Expenses</Title>
                      </Grid>
                      <Grid item>
                        <StyledAddButton startIcon={<StyledIcon />} onClick={toggleModal}>
                          Expenses
                        </StyledAddButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <MembersFilter title={'Expense'} />
                    </Grid>
                    {/* </Card> */}
                  </Grid>

                  <Grid item xs={12}>
                    <ExpensesTable
                      expenses={filteredExpenses}
                      onExpenseClick={handleRowClick}
                      openRow={openRow}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </>
      </Card>
      {isOpenDialog && <AddUpdateExpensesDialog closeDialog={toggleModal} />}
    </>
  );
};

export default ExpensesSection;
