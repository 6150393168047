import React from 'react';
import {
  Grid,
  styled,
  MenuItem,
  Select,
  // Autocomplete,
  InputAdornment,
  TextField,
  Autocomplete,
} from '@mui/material';
import { Paragraph, H1 } from '../../../../common/Typography';
import { Formik } from 'formik';
import { useEffect } from 'react';
import '../../dashboard.css';
import * as Yup from 'yup';

// const Autocomplete = styled(Autocomplete)(() => ({
//   width: '100%',
//   marginBottom: '16px',
//   animation: 'none',
//   transition: 'none',
// }));



const LeadInformation = ({ formData, setFormData, setDisable }) => {


  useEffect(() => {
    if (!formData || typeof formData !== 'object') {
      setDisable(true);
      return;
    }

    const isPropertyTypeSelected = !!formData.propertyType;
    const isSubTypeSelected = !!formData.subType;

    const otherRequiredFields = ['projectName', 'estimatedPrice', 'size'];
    const isOtherFieldsEmpty = otherRequiredFields.some((field) => {
      const value = formData[field];
      if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
      }
      return value === '';
    });

    setDisable(!isPropertyTypeSelected || !isSubTypeSelected || isOtherFieldsEmpty);
  }, [formData, setDisable]);

  const propertyTypeOptions = [{ label: 'Land' }];

  const handleChangeDropDown = (name, value) => {
    if (formData[name] !== value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const subTypeOptions = [
    { label: 'Commercial' },
    { label: 'Residential' },
    { label: 'Industrial ' },
  ];
  const sizeOptions = [{ value: 'Acres', label: 'Acres' }];

  const initialValues = {
    projectName: '',
    estimatedPrice: '',
    size: '',
    unit: '',
    propertyType: '',
    subType: '',
    // referenceLink: "",
  };

  const {
    projectName = '',
    description = '',
    estimatedPrice = '',
    size = '',
    propertyType = '',
    subType = '',
    referenceLink = '',
  } = formData;

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required('Project Name is required!'),
    estimatedPrice: Yup.number().required('Estimated Price is required!'),
    size: Yup.string().required('Size is required!'),
    propertyType: Yup.string().required('Property Type is required!'),
    subType: Yup.string().required('Sub Type is required!'),
  });

  return (
    <div className='lead'>
      <H1>Lead Information</H1>
      <Paragraph>Your lead will be a draft state until it is published</Paragraph>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ marginTop: '15px' }}>
            <Grid container columnSpacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  size='small'
                  type='text'
                  name='projectName'
                  variant='outlined'
                  label='Project Name'
                  placeholder='Project Name'
                  onBlur={handleBlur}
                  value={projectName}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  helperText={touched.projectName && errors.projectName}
                  error={Boolean(errors.projectName && touched.projectName)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  size='small'
                  type='text'
                  name='description'
                  variant='outlined'
                  label='Description'
                  placeholder='Description'
                  value={description}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  multiline
                  rows={4}
                  sx={{ mb: 3 }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={2}>
                <Autocomplete
                  className='blinkinglabel'
                  options={propertyTypeOptions}
                  size='small'
                  getOptionLabel={(option) => option.label || ''}
                  value={propertyType || ''}
                  onChange={(e, newValue) => {
                    handleChangeDropDown('propertyType', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Property Type'
                      variant='outlined'
                      className='textfiled'
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Autocomplete
                  options={subTypeOptions}
                  size='small'
                  getOptionLabel={(option) => option.label || ''}
                  value={subType || ''}
                  onChange={(e, newValue) => {
                    handleChangeDropDown('subType', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Sub Type'
                      className='textfiled'
                      variant='outlined'
                      fullWidth
                      name='subType'
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  fullWidth
                  size='small'
                  type='text' // Keep type as text to control input
                  name='estimatedPrice'
                  variant='outlined'
                  label='Estimated Price'
                  placeholder='Estimated Price'
                  onBlur={handleBlur}
                  value={estimatedPrice}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow digits and a single decimal point
                    if (/^\d*\.?\d*$/.test(value)) { // Check if value consists only of digits and at most one decimal point
                      handleChange(e); // Call handleChange to manage form state
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        [e.target.name]: value, // Update state with the valid value
                      }));
                    }
                  }}
                  helperText={touched.estimatedPrice && errors.estimatedPrice}
                  error={Boolean(errors.estimatedPrice && touched.estimatedPrice)}
                  inputProps={{ min: 0 }}
                />
              </Grid>



              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  size='small'
                  type='number'
                  fullWidth
                  name='size'
                  variant='outlined'
                  label='Size'
                  sx={{ mb: 3 }}
                  className='input-padding'
                  placeholder='Size'
                  onBlur={handleBlur}
                  value={size || ''}  // Ensure size is always a string
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      size: newValue,  // Update size correctly
                    }));
                    handleChange(e); // Call your handler as well
                  }}
                  helperText={touched.size && errors.size}
                  error={Boolean(errors.size && touched.size)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Select
                          name="unit"
                          onChange={(e) => {
                            const newUnit = e.target.value;
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              unit: newUnit,  // Update unit properly
                            }));
                            handleChange({
                              target: { name: 'unit', value: newUnit },
                            });
                          }}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Size unit' }}
                          style={{
                            height: '36px',
                            backgroundColor: 'lightgrey',
                            borderColor: 'red',
                            width: '100%',
                          }}
                          value={formData.unit || sizeOptions[0].value}  // Use formData.unit for correct binding
                        >
                          {sizeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                    inputProps: {
                      onKeyDown: (e) => {
                        if (!/[0-9.]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                          e.preventDefault();
                        }
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  size='small'
                  type='url'
                  name='referenceLink'
                  variant='outlined'
                  label='Reference Link'
                  placeholder='Reference Link'
                  value={referenceLink}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LeadInformation;
