import React, { useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import AddEditBuilding from './AddEditBuilding';
import { StyledIcon, Title } from 'app/common/Typography';
import { LoadingButton } from '@mui/lab';
import BuildingView from './BuildingView';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectBuldings } from './hooks/useProjectBuldings';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';

const LeaseSaleSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [view, setView] = useState('list');
  const [selectedBuildingIndex, setSelectedBuildingIndex] = useState(null);
  const { projectId } = useProject();
  const { data: buildings } = useProjectBuldings(projectId);

  const handleViewUnits = (index) => {
    setSelectedBuildingIndex(index);
    setView('details');
  };

  const handleBackToBuildings = () => {
    setView('list');
    setSelectedBuildingIndex(null);
  };

  const toggleModal = () => setShowModal((prevState) => !prevState);

  return (
    <div
      style={{
        height: '56vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {view === 'list' ? (
        <>
          <Box
            sx={{ margin: '20px 38px 20px 20px' }}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <StledCardTitle>Buildings</StledCardTitle>
            <StyledAddButton startIcon={<StyledIcon />} onClick={() => setShowModal(true)}>
              Buildings
            </StyledAddButton>
          </Box>
          <Box
            style={{
              flex: 1,
              padding: '20px',
              overflowX: 'auto',
            }}
          >
            {!buildings ? (
              <div style={{ textAlign: 'center' }}>
                <Typography fontSize='16px' fontWeight='600' align='center'>
                  No Buildings Available
                </Typography>
                <Typography fontSize='14px' fontWeight='400' align='center'>
                  It seems you have not added any buildings
                </Typography>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '20px',
                  maxWidth: '100%', // Limits the width of the row
                }}
              >
                {buildings &&
                  buildings.map((building, index) => (
                    <Card
                      key={building.id}
                      style={{
                        border: '1px solid #E3EDF5',
                        borderRadius: '5px',
                        padding: '10px',
                        width: '300px',
                        flex: '1 1 calc(33.33% - 20px)', // Ensures three cards per row with spacing
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        <Title
                          style={{
                            margin: 0,
                            fontSize: '16px',
                            color: 'var(--title-BuildingName)',
                          }}
                        >
                          {building.buildingName}
                        </Title>
                        {/* <img
                          src='/assets/images/units.png'
                          alt='Building'
                          style={{ width: '50px', height: '50px' }}
                        /> */}
                        <StyledAddButton
                          startIcon={<StyledIcon />}
                          onClick={() => setShowModal(true)}
                        >
                          Unit(s)
                        </StyledAddButton>
                      </div>
                      <Typography
                        variant='subtitle1'
                        style={{
                          margin: '0px',
                          color: 'var(--typography-created-text)',
                          fontSize: '14px',
                        }}
                      >
                        Created on {convertUTCDateToLocal(new Date(...building.createdDate))}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        style={{
                          marginTop: '10px',
                          color: 'var(--typography-Noof-units-text)',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        No of Units: {building?.units?.length}
                      </Typography>
                      <LoadingButton
                        style={{
                          padding: '5px 15px',
                          backgroundColor: 'var(--loadingButton-bg)',
                          color: 'var(--loadingButton-color)',
                          border: 'none',
                          borderRadius: '3px',
                          marginTop: '10px',
                        }}
                        onClick={() => handleViewUnits(index)}
                        sx={{ textTransform: 'none' }}
                      >
                        View Units
                      </LoadingButton>
                    </Card>
                  ))}
              </div>
            )}
          </Box>
        </>
      ) : (
        <BuildingView
          selectedBuilding={buildings?.at(selectedBuildingIndex)}
          handleBackToBuildings={handleBackToBuildings}
        />
      )}

      <AddEditBuilding open={showModal} onClose={toggleModal} />
    </div>
  );
};

export default LeaseSaleSection;
