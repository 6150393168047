import React, { useState } from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Autocomplete,
  Typography,
  IconButton,
} from '@mui/material';
import { CustomLabel, StyledTextField, Divider } from '../../../../common/Typography';
import { Formik } from 'formik';
import { useEffect } from 'react';
import '../../dashboard.css';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AddLinkButton, LinkContainer } from '../listings/Modals/StyledComponents';
import { StyledLabel } from '../../profile/StyleComponents';
import { LinksAddIcon, StyledcrossIcon } from 'app/common/icons';

const LeadInformation = ({ formData, setFormData, setDisable }) => {
  useEffect(() => {
    if (!formData || typeof formData !== 'object') {
      setDisable(true);
      return;
    }

    const isPropertyTypeSelected = !!formData.propertyType;
    const isSubTypeSelected = !!formData.subType;

    const otherRequiredFields = ['projectName', 'estimatedPrice', 'size'];
    const isOtherFieldsEmpty = otherRequiredFields.some((field) => {
      const value = formData[field];
      if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
      }
      return value === '';
    });

    setDisable(!isPropertyTypeSelected || !isSubTypeSelected || isOtherFieldsEmpty);
  }, [formData, setDisable]);

  const propertyTypeOptions = [{ label: 'Land' }];
  const [visibility, setVisibility] = useState('Public');
  const handleChangeDropDown = (name, value) => {
    if (formData[name] !== value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const subTypeOptions = [
    { label: 'Commercial' },
    { label: 'Residential - Single Family' },
    { label: 'Residential - Multi Family' },
    { label: 'Industrial ' },
  ];
  const sizeOptions = [{ value: 'Acres', label: 'Acres' }];

  const initialValues = {
    projectName: '',
    estimatedPrice: '',
    size: '',
    unit: '',
    propertyType: '',
    subType: '',
  };

  const {
    projectName = '',
    description = '',
    zoning = '',
    estimatedPrice = '',
    size = '',
    propertyType = '',
    subType = '',
    referenceLink = '',
  } = formData;

  const [links, setLinks] = React.useState([referenceLink || '']);
  const handleAddLink = () => {
    setLinks([...links, '']);
  };
  const handleRemoveLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
  };

  const handleChangeLink = (index, value) => {
    const updatedLinks = [...links];
    updatedLinks[index] = value;
    setLinks(updatedLinks);
    setFormData((prevFormData) => ({
      ...prevFormData,
      referenceLink: updatedLinks[0] || '',
    }));
  };
  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required('Project Name is required!'),
    estimatedPrice: Yup.number().required('Estimated Price is required!'),
    size: Yup.string().required('Size is required!'),
    propertyType: Yup.string().required('Property Type is required!'),
    subType: Yup.string().required('Sub Type is required!'),
  });

  return (
    <div className='lead'>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ marginTop: '15px' }}>
            <Grid container columnSpacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={2}>
                <CustomLabel>Project Name</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  type='text'
                  name='projectName'
                  variant='outlined'
                  placeholder='Project Name'
                  onBlur={handleBlur}
                  value={projectName}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  helperText={touched.projectName && errors.projectName}
                  error={Boolean(errors.projectName && touched.projectName)}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={2}>
                <CustomLabel>Zoning</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  type='text'
                  name='zoning'
                  variant='outlined'
                  placeholder='zoning'
                  value={zoning}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} mb={2}>
                <Autocomplete
                  className='blinkinglabel'
                  options={propertyTypeOptions}
                  size='small'
                  getOptionLabel={(option) => option.label || ''}
                  value={propertyType || ''}
                  onChange={(e, newValue) => {
                    handleChangeDropDown('propertyType', newValue);
                  }}
                  renderInput={(params) => (
                    <>
                      <CustomLabel>Property Type</CustomLabel>
                      <StyledTextField
                        {...params}
                        // label='Property Type'
                        variant='outlined'
                        className='textfiled'
                        fullWidth
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Autocomplete
                  options={subTypeOptions}
                  size='small'
                  getOptionLabel={(option) => option.label || ''}
                  value={subType || ''}
                  onChange={(e, newValue) => {
                    handleChangeDropDown('subType', newValue);
                  }}
                  disabled={!propertyType}
                  renderInput={(params) => (
                    <>
                      <CustomLabel>Sub Type</CustomLabel>
                      <StyledTextField
                        {...params}
                        // label='Sub Type'
                        className='textfiled'
                        variant='outlined'
                        fullWidth
                        name='subType'
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomLabel>Area (In Acres)</CustomLabel>
                <StyledTextField
                  size='small'
                  type='number'
                  fullWidth
                  name='size'
                  variant='outlined'
                  sx={{ mb: 3 }}
                  className='input-padding'
                  placeholder='Size'
                  onBlur={handleBlur}
                  value={size || ''}
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      size: newValue,
                    }));
                    handleChange(e);
                  }}
                  helperText={touched.size && errors.size}
                  error={Boolean(errors.size && touched.size)}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomLabel>Estimated Price</CustomLabel>
                <StyledTextField
                  fullWidth
                  size='small'
                  type='text' // Keep type as text to control input
                  name='estimatedPrice'
                  variant='outlined'
                  // label='Estimated Price'
                  placeholder='Estimated Price'
                  onBlur={handleBlur}
                  value={estimatedPrice}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow digits and a single decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      // Check if value consists only of digits and at most one decimal point
                      handleChange(e); // Call handleChange to manage form state
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        [e.target.name]: value, // Update state with the valid value
                      }));
                    }
                  }}
                  helperText={touched.estimatedPrice && errors.estimatedPrice}
                  error={Boolean(errors.estimatedPrice && touched.estimatedPrice)}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              {/* <H1>Project Visibility:</H1> */}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl component='fieldset'>
                  {/* <FormLabel component="legend">Project Visibility:</FormLabel> */}
                  <Typography variant='h5' gutterBottom>
                    Project Visibility:
                  </Typography>
                  <RadioGroup
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                    row
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                      <FormControlLabel value='Public' control={<Radio />} label='Public' />
                      <div
                        style={{
                          marginTop: '5px',
                          marginLeft: '30px',
                          fontSize: '14px',
                          color: '#5F5F64',
                        }}
                      >
                        Project details will be visible to all the members
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <FormControlLabel value='Private' control={<Radio />} label='Private' />
                      <div
                        style={{
                          marginTop: '5px',
                          marginLeft: '30px',
                          fontSize: '14px',
                          color: '#5F5F64',
                        }}
                      >
                        Project will be visible only to the added members
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CustomLabel>Description</CustomLabel>
                <ReactQuill
                  style={{ height: "120px", marginBottom: '35px' }}
                  value={description}
                  onChange={(content, delta, source, editor) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      description: editor.getHTML(),
                    }));
                  }}
                  modules={{
                    toolbar: [
                      [{ header: '1' }, { header: '2' }, { font: [] }],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ align: [] }],
                      ['bold', 'italic', 'underline'],
                      ['link'],
                      ['blockquote'],
                    ],
                  }}
                  placeholder='Description'
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <StyledLabel sx={{ marginLeft: '2%' }}>Reference link</StyledLabel>
                  {links.map((link, index) => (
                    <LinkContainer key={index}>
                      <StyledTextField
                        name='referenceLink'
                        onChange={(e) => handleChangeLink(index, e.target.value)}
                        value={link}
                        fullWidth
                        variant='outlined'
                        sx={{ marginLeft: '2%' }}
                      />
                      <IconButton
                        onClick={() => handleRemoveLink(index)}
                        disabled={links.length === 1}
                      >
                        <StyledcrossIcon />
                      </IconButton>
                    </LinkContainer>
                  ))}
                  <AddLinkButton
                    startIcon={
                      links.some((link) => link.trim() === '') ? (
                        <LinksAddIcon color='#A9A9A9' />
                      ) : (
                        <LinksAddIcon />
                      )
                    }
                    onClick={handleAddLink}
                    disabled={links.some((link) => link.trim() === '')}
                  >
                    Reference Links
                  </AddLinkButton>
                </Grid>
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <StyledTextField
                  fullWidth
                  size='small'
                  type='url'
                  name='referenceLink'
                  variant='outlined'
                  label='Reference Link'
                  placeholder='Reference Link'
                  value={referenceLink}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </Grid> */}
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LeadInformation;
