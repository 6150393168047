import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Card, Button, IconButton, Modal } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PMContactPersonDetails from './PMContactPersonDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PMSingleInfoModal from './PMSingleInfoModal';

const Title = styled('span')({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
});

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '18%',
  marginTop: '-1px',
  rowGap: '20px',
  borderRadius: '5px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});


const PropertyManagementSingleInfo = ({propertyManagements,id,setPmData}) => {
  const [open, setOpen] = useState(false);
  const[pmanagements,setPmanagements]=useState(propertyManagements);
  const propertyManagement=propertyManagements[id];

  if(!propertyManagement){
    return <Typography>No Property Data Available</Typography>
  }

  const property=[
    {key:'Property Name', value:propertyManagement?.propertyName},
    {key:'EMAIL ADDRESS',value:propertyManagement?.email},
    {key:'PHONE NUMBER',value:propertyManagement?.phoneNumber},
    {key:'ADDRESS', value: [
      [propertyManagement?.address, propertyManagement?.city].filter(Boolean).join(', '),
      [propertyManagement?.state, propertyManagement?.zipCode, propertyManagement?.country].filter(Boolean).join(', ')
    ].filter(Boolean).join('\n')}
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <div className='viewlead'>
        <SubtitleDivider />
        <IconButton style={{ margin: '1%' }} onClick={() => setPmData(null)}>
          <ArrowBackIcon />
        </IconButton>
        
        <Box marginLeft={3} marginRight={3}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
              
              <Title>Property Management Details</Title>
             
              <Button
                style={{ color: '#4F90F0', fontSize: '14px' }}
                onClick={handleClickOpen}
              >
                <BorderColorIcon sx={{ fontSize: '13.5px' }} />
                Edit
              </Button>
            </Box>
            <Modal open={open} onClose={handleClose}>
              <PMSingleInfoModal  property={propertyManagement} handleCloseModal={handleClose}  />
            </Modal>
            <Box marginTop={0} marginLeft={2}>
              
              <InfoContainer>
                {property?.map(({ key, value }, index) => (
                  <Box key={index}>
                    <Key>{key}</Key>
                    <ValueContainer>
                      <Value>{value}</Value>
                    </ValueContainer>
                  </Box>
                ))}
              </InfoContainer>
            </Box>
          </Box>
        </Box>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          {/* <GCManagerDetails gcManager={contractors}  gcManagerIndex={id} /> */}
          <PMContactPersonDetails pmContactPerson={propertyManagements} pmContactPersonIndex={id} />
        </Card>
      </div>
    </>
  )
}

export default PropertyManagementSingleInfo
