import { TableHead, TableRow, TableCell, TableBody, Box, Collapse } from '@mui/material';
  import { StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  import KeyboardArrowRightIcon from '@mui/icons-material//KeyboardArrowRight';
  import { useState } from 'react';
import SaleInvestmetnInvoice from './SaleInvestmentInvoise';
  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Total Amount</TableCell>
        <TableCell>Paid Amount</TableCell>
        <TableCell>Balance</TableCell>
        {/* <TableCell>Type</TableCell> */}
        <TableCell>Paid To</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Transaction Number</TableCell>
        {/* <TableCell>Invoice</TableCell> */}
      </TableRow>
    </TableHead>
  );
  
  function SaleInvestmentTableData({ investments, onExpenseClick }) {
    const [openRow, setOpenRow] = useState(null);
  
    const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);
  
    return (
      <TableWrapper>
        <StyledTable>
          <TableHeader />
          <TableBody>
            {investments &&
              investments.map((investment, index) => {
                const latestPaymentDetail =
                  investment?.paymentDetails?.length > 0 ? investment?.paymentDetails?.at(-1) : {};
                return (
                  <>
                    <StyledTableRow key={latestPaymentDetail.id}>
                      <TableCell>
                        <div
                          style={{
                            fontWeight: 100,
                            fontSize: '14px',
                            align: 'left',
                            width: '60px',
                          }}
                          onClick={() => handleRowClick(investment.id)}
                        >
                          <span>
                            {openRow === investment.id ? (
                              <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />
                            ) : (
                              <KeyboardArrowRightIcon sx={{ marginBottom: '-7px' }} />
                            )}
                          </span>
                          <span>
                            {latestPaymentDetail?.expensesName}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>{investment?.totalAmount}</TableCell>
                      <TableCell>{latestPaymentDetail?.paidAmount}</TableCell>
                      <TableCell>{latestPaymentDetail?.balance || '00'}</TableCell>
                      {/* <TableCell>{latestPaymentDetail?.expenseType}</TableCell> */}
                      <TableCell>{latestPaymentDetail?.paidTo}</TableCell>
                      <TableCell>{latestPaymentDetail?.status}</TableCell>
                      <TableCell>{investment?.invoiceNo}</TableCell>
                      {/* <TableCell></TableCell> */}
                    </StyledTableRow>
                  {openRow === investment.id && 
                    <TableRow>
                      <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
                        <Collapse in={openRow === investment.id} timeOut='auto' unmountOnExit>
                          <Box>
                            <SaleInvestmetnInvoice
                              selectedInvoice={investment}
                              // expensesDocuments={expensesDocuments}
                            />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  }
                  </>
                );
              })}
          </TableBody>
        </StyledTable>
      </TableWrapper>
    );
  }
  
  export default SaleInvestmentTableData;
  