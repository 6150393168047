export const topBarHeight = 64;
export const sideNavWidth = 213;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const clientId = 'YOUR_CLIENT_ID.apps.googleusercontent.com'; //will be updated once we get this
export const googleMapsApiKey = 'AIzaSyCDUIkyDMVrgLh4Ax0Ki8DHcWAktv7qPTs';
export const DEFAULT_CENTER = { lat: 40.7128, lng: -74.006 }; //  New York City coords
export const dueDiligence = 'Due Diligence';
export const contractDocuments = 'Contract documents';
export const closingDocuments = 'Closing documents';
export const paymentOptions = [{ label: 'Cash' }, { label: 'Online' }, { label: 'Card' }];

export const expenseTypes = [
  { label: 'Architect' },
  { label: 'Engineer' },
  { label: 'Construction' },
];
export const requestsTypes = {
  INVESTMENT_MAIL: 'INVESTMENT_MAIL',
  PAYMENT_REQUEST_MAIL: 'PAYMENT_REQUEST_MAIL',
  OUTGOING_USER_INVESTMENT: 'OUTGOING_USER_INVESTMENT',
  OUTGOING_PROJECT_INVITATION: 'OUTGOING_PROJECT_INVITATION',
};

export const roles = {
  OWNER: 'Owner',
  GENERAL_PARTNER: 'General Partner',
  INVESTOR: 'Investor',
  ENGINEER: 'Engineer',
  ARCHITECT: 'Architect',
  ATTORNEY: 'Attorney',
};

export const investorRoles = [roles.INVESTOR, roles.OWNER, roles.GENERAL_PARTNER];

export const statuses = {
  ACCEPTED: 'ACCEPTED',
  GRANTED: 'GRANTED',
  PENDING: 'PENDING',
  DECLINED: 'DECLINED',
};

export const requestStatusMessages = {
  PENDING: {
    message: 'We will notify you when you get any pending request',
  },
};
