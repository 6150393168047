const textLight = {
  primary: 'rgba(52, 49, 76, 1)',
  secondary: 'rgba(52, 49, 76, 0.54)',
  disabled: 'rgba(52, 49, 76, 0.38)',
  lightdark: '#282A2D',
  hint: 'rgba(52, 49, 76, 0.38)',
};

const textDark = {
  primary: '#17181B',
  lightdarkprimary: '#456DB3',
  secondary: '#5F6368',
  lightdark: '#282A2D',
  textfield: '#2E3134',
  black: '#000',
  white: '#fff',
  buttonbg: '#0277BD',
  lavendorblue: '#7375C7',
  disabled: 'rgba(255, 255, 255, 0.64)',
  hint: 'rgba(255, 255, 255, 0.64)',
  title:'#0E1013',
  link:'#645BBB',
  delete:'#FC3F1D',
  border:'#DADCE0',
  cancleIcon:'#272937',
  cancleButtonBG:'#EBEEF0',
  cancleButton:'#5F5F64',
  TopNavbg:"#F7F8FC",
  TopNavColor:"#5F5F64",
  boxShadow:'#44414126',
  subTitle:'#6D7C92',
  LoadingIcon:'#28B446'
};

const secondaryColor = {
  light: '#f9a352',
  main: '#ff9e43',
  dark: '#ff932e',
  contrastText: textLight.primary,
};

const errorColor = {
  main: '#FF3D57',
};

export const themeColors = {
  whiteBlue: {
    palette: {
      type: 'light',
      primary: {
        main: '#ffffff',
        contrastText: textLight.primary,
      },
      secondary: {
        main: '#1976d2',
        contrastText: '#ffffff',
      },
      background: {
        paper: '#fff',
        default: '#fafafa',
      },
      text: textLight,
    },
  },
  slateDark1: {
    palette: {
      type: 'dark',
      primary: {
        main: '#1976D2',
        contrastText: '#ffffff',
      },
      secondary: secondaryColor,
      error: errorColor,
      background: {
        paper: '#FFFFFF',
        default: '#121828',
      },
      text: textDark,
    },
    typography: {
      fontFamily: 'Proxima Nova, Arial, sans-serif',
    },
  },
};