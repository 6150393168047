import { useQuery } from '@tanstack/react-query';
import { getProjectLLC } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectLLC(projectId) {
  return useQuery({
    queryKey: [...queryKeys.project.llc, projectId],
    queryFn: () => getProjectLLC(projectId),
  });
}
