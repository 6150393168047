import { TableHead, TableRow, TableCell, TableBody, IconButton, Avatar, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  StyledMembersTable,
  MemberName,
  StatusChip,
  ContactDetails,
  StyledTableRow,
  TableWrapper,
} from './StyledComponents';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>S.No.</TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Contact Details</TableCell>
      <TableCell>Roles</TableCell>
      <TableCell>Added On</TableCell>
      <TableCell>Status</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function MembersTable({ members, isOnHoldOrCancelled, handleMenuOpen, onMemberClick }) {
  return (
    <TableWrapper>
      <StyledMembersTable>
        <TableHeader />
        <TableBody>
          {members.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align='center'>
                No members available
              </TableCell>
            </TableRow>
          ) : (
            members.map((member, index) => (
              <StyledTableRow key={member.id}>
                <TableCell>{`${(index + 1).toString().padStart(2, '0')}`}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Avatar src={''} alt={member.memberName} sx={{ fontSize: '14px' }}>
                      {member.memberName.split(' ')[0]?.[0].toUpperCase() || ''}
                      {member.memberName.split(' ')[1]?.[0].toUpperCase() || ''}
                    </Avatar>
                    <MemberName onClick={() => onMemberClick(member)}>
                      {member.memberName}
                    </MemberName>
                  </Box>
                </TableCell>
                <TableCell>
                  <ContactDetails>
                    <div>{member.phone}</div>
                    <div>{member.email}</div>
                  </ContactDetails>
                </TableCell>
                <TableCell>{member.userRoles.join(', ')}</TableCell>
                <TableCell>{member.addedOn}</TableCell>
                <TableCell>
                  <StatusChip status={member.status}>{member.status}</StatusChip>
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    size='small'
                    onClick={(event) => handleMenuOpen(event, member)}
                    disabled={isOnHoldOrCancelled}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </StyledMembersTable>
    </TableWrapper>
  );
}

export default MembersTable;
