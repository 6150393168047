import { createContext, useEffect, useReducer } from 'react';
import {
  editUserDetails,
  getUserDetails,
  login,
  register,
  resendConfirmationOTP,
  sendConfirmationOTPWithEmail,
  sendOTPWithEmailAndSession,
} from './userAuthActions';
import axiosService from './axiosService';
import notify from 'app/utils/notify';
import { jwtDecode } from 'jwt-decode';
import { handleAxiosError } from 'app/utils/helpers';
import { fetchProfileDocument } from './leadActions';
import { API_ENDPOINTS } from './apiConstants';

const initialState = {
  user: null,
  profileImage: null,
};

const getSession = () => localStorage.getItem('sessionAuth');
const getAuthToken = () => localStorage.getItem('authToken');

const setSession = (sessionAuth) => {
  if (sessionAuth) {
    localStorage.setItem('sessionAuth', sessionAuth);
  } else {
    localStorage.removeItem('sessionAuth');
  }
};

const setAuthToken = (authToken) => {
  if (authToken) {
    localStorage.setItem('authToken', authToken);
    axiosService.setAuthToken(authToken);
  } else {
    localStorage.removeItem('authToken');
    axiosService.setAuthToken(null);
  }
};

const decodeJWTAuthToken = () => {
  try {
    const authToken = getAuthToken();
    if (authToken) {
      const decodedToken = jwtDecode(authToken);
      return { userNameJWT: decodedToken.username, expirationTokenTime: decodedToken.exp };
    }
    return { userNameJWT: null, expirationTokenTime: null };
  } catch (error) {
    notify.error(error);
    return { userNameJWT: null, expirationTokenTime: null };
  }
};

const rememberEmail = (email) => {
  localStorage.removeItem('email');
  localStorage.setItem('email', email);
};

const getRememberedEmail = () => {
  return localStorage.getItem('email');
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'user/reload':
      return {
        ...state,
      };
    case 'user/login':
      return {
        ...state,
        user: action.payload,
      };
    case 'user/setProfileImage':
      return {
        ...state,
        profileImage: action.payload,
      };
    case 'user/logout':
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

const UserAuthContext = createContext();

export const UserAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { userNameJWT, expirationTokenTime } = decodeJWTAuthToken();

  const authToken = getAuthToken();
  const isTokenFresh =
    !!authToken && (expirationTokenTime ? Date.now() < expirationTokenTime * 1000 : false);

  useEffect(() => {
    const initializeAuth = async () => {
      if (!state.user && isTokenFresh) {
        try {
          await userGetDetails();
          await userFetchProfileImage();
        } catch (error) {
          handleAxiosError(error);
        }
      }
    };
    initializeAuth();
  }, [state.user, isTokenFresh]);

  const userLoginWithEmail = async (email) => {
    try {
      rememberEmail(email);
      const response = await login(email);
      setSession(response.session);
    } catch (error) {
      throw error;
    }
  };

  const userRegister = async (userData) => {
    try {
      await register(userData);
    } catch (error) {
      throw error;
    }
  };

  const userFetchProfileImage = async () => {
    try {
      const profileData = await fetchProfileDocument();
      dispatch({
        type: 'user/setProfileImage',
        payload: profileData,
      });
    } catch (error) {
      console.error('Failed to fetch profile image:', error);
    }
  };

  const profileDocumentInformation = async (obj) => {
    try {
      const response = await axiosService.Post(
        API_ENDPOINTS.DOCUMENTS.UPLOAD_PROFILE_DOCUMENT(),
        obj,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      await userFetchProfileImage();
      return response.data;
    } catch (error) {
      console.error('API error:', error);
      throw error;
    }
  };

  const userConfirmRegistration = async (confirmationCode) => {
    try {
      const response = await sendConfirmationOTPWithEmail({
        email: getRememberedEmail(),
        confirmationCode,
      });
      // const authToken = response.headers.get('Authorization'); // NOTE: also should be added session tokens (not yet implemented from backend)
      // setAuthToken(authToken);
      // dispatch({ type: 'user/reload' });
    } catch (error) {
      const session = error?.response?.data?.data?.session;
      setSession(session);
      throw error;
    }
  };

  const userSendOTP = async (otpCode) => {
    try {
      const response = await sendOTPWithEmailAndSession({
        email: getRememberedEmail(),
        otpCode,
        session: getSession(),
      });
      const authToken = response.headers.get('Authorization');
      setAuthToken(authToken);
      dispatch({ type: 'user/reload' });
    } catch (error) {
      const session = error?.response?.data?.data?.session;
      setSession(session);
      throw error;
    }
  };

  const userResendConfirmationOTP = async () => {
    try {
      await resendConfirmationOTP(getRememberedEmail());
    } catch (error) {
      throw error;
    }
  };

  const userLogout = () => {
    setAuthToken(null);
    setSession(null);
    dispatch({ type: 'user/logout' });
  };

  const userGetDetails = async () => {
    try {
      const authToken = getAuthToken();
      setAuthToken(authToken);
      const userDetails = await getUserDetails();
      dispatch({
        type: 'user/login',
        payload: userDetails,
      });
    } catch (error) {
      throw error;
    }
  };

  const userEditDetails = async (userData) => {
    try {
      await editUserDetails(userNameJWT, {
        ...state.user,
        ...userData,
      });
      await userGetDetails();
    } catch (error) {
      throw error;
    }
  };

  return (
    <UserAuthContext.Provider
      value={{
        ...state,
        expirationTokenTime,
        userNameJWT,
        getRememberedEmail,
        getAuthToken,
        userLoginWithEmail,
        userRegister,
        userLogout,
        userConfirmRegistration,
        userSendOTP,
        userEditDetails,
        userResendConfirmationOTP,
        profileDocumentInformation,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthContext;
