import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Divider,
  Grid,
} from '@mui/material';
import { StyledIcon, Title } from 'app/common/Typography';
import GCPaymentAddEdit from './GCPaymentAddEdit';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';
import GCPaymentViewTable from './GCPaymentViewTable';


const GCPaymentsView = ({ contractor }) => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [openRow, setOpenRow] = useState(null);
  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const payments = contractor?.payments;

  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <>
          {(!payments || (Array.isArray(payments) && payments.length === 0)) && (
            <>
              <Box
                sx={{ margin: '20px 38px 20px 20px' }}
                display='flex'
                justifyContent='space-between'
              >
                <StledCardTitle>Payment Details</StledCardTitle>
                <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                  Payment Details
                </StyledAddButton>
              </Box>
              <Divider />
            </>
          )}

          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {!payments || !Array.isArray(payments) || payments.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Payment Details Available
                </Typography>
              </div>
            ) : (
            <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid
                        item
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Title>Payment Details</Title>
                      </Grid>
                      <Grid item>
                        <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                          Payment
                        </StyledAddButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TableFilter title={'Payment Details'} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <GCPaymentViewTable
                      contractor={contractor}
                      payments={payments}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </>
      </Card>
      {isOpenDialog && (
        <GCPaymentAddEdit contractor={contractor} closeDialog={toggleDialog} />
      )}
    </>
  );
};

export default GCPaymentsView;
