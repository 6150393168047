import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Modal, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { ModalCancel, ModalContent, ModalSave, StyledBoxFooter } from 'app/common/Typography';
import { StyledLabel } from '../../listings/Modals/StyledComponents';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { parseAddressComponents } from 'app/utils/helpers';
import TitleCompanyAddEdit from './TitleCompanyAddEdit';
import { LoadingButton } from '@mui/lab';

const BuyerInvestmentModal = ({
  open,
  onClose,
  saleData,
  buyerData,
  handleBackToSaleForm,
  selectedBuilding,
  selectedUnit,
  saleId,
  onBack,
  handleBuyerModalOpen,
  isEdit,
  showPrevious = true,
}) => {
  const [openTitleCompanyModal, setOpenTitleCompanyModal] = useState(false);
  const searchInputRef = React.useRef(null);
  const handleClose = () => {
    onClose();
  };

  const handleCloseRealtorInvestment = () => {
    setOpenTitleCompanyModal(false);
  };

  const handleOpenRealtorInvestment = () => {
    setOpenTitleCompanyModal(true);
  };

  const handleBackToBuyerInvestmentModal = () => {
    setOpenTitleCompanyModal(false);
    handleBuyerModalOpen();
  };

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };
  const formik = useFormik({
    initialValues: {
      buyerName: '',
      buyerAddress: '',
      buyerPhone: '',
      buyerEmail: '',
      buyerCity: '',
      buyerState: '',
      buyerZip: '',
      buyerCountry: '',
      totalPrice: '',
      paidAmount: '',
      balanceAmount: '',
      paymentDate: '',
      modeOfPayment: '',
      transactionNumber: '',
    },
    // validationSchema: validationSchemas.buyerInvestment(),
    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      setSubmitting(false);
      //   handleClose();
      setOpenTitleCompanyModal(true);
      handleClose();
    },
  });

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '0%',
            transform: 'translate(0%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            height: '100%',
            paddingLeft: '40px',
          }}
        >
          <ModalContent>
            <Box sx={{ justifyContent: 'space-between', display: 'flex', marginBottom: '20px' }}>
              <Typography variant='h6'>{`${
                isEdit ? 'Edit' : 'Add'
              } Realtor and Investment Information`}</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
              </IconButton>
            </Box>

            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={2}>
                {/* Buyer Information Section */}
                <Grid item xs={6}>
                  <StyledLabel>Realtor Name</StyledLabel>
                  <TextField
                    id='buyerName'
                    name='buyerName'
                    size='small'
                    fullWidth
                    value={formik.values.buyerName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.buyerName && Boolean(formik.errors.buyerName)}
                    helperText={formik.touched.buyerName && formik.errors.buyerName}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Realtor Phone</StyledLabel>
                  <TextField
                    id='buyerPhone'
                    name='buyerPhone'
                    size='small'
                    fullWidth
                    value={formik.values.buyerPhone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.buyerPhone && Boolean(formik.errors.buyerPhone)}
                    helperText={formik.touched.buyerPhone && formik.errors.buyerPhone}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Realtor Email</StyledLabel>
                  <TextField
                    id='buyerEmail'
                    name='buyerEmail'
                    size='small'
                    fullWidth
                    value={formik.values.buyerEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.buyerEmail && Boolean(formik.errors.buyerEmail)}
                    helperText={formik.touched.buyerEmail && formik.errors.buyerEmail}
                  />
                </Grid>

                {/* Address Search Section */}
                <Grid item xs={12}>
                  <StyledLabel>Address</StyledLabel>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                    onUnmount={() => (searchInputRef.current = null)}
                    onPlacesChanged={() => handlePlacesChanged(formik.setValues)}
                  >
                    <TextField
                      id='address1'
                      name='address1'
                      size='small'
                      fullWidth
                      value={formik.values.address1}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (!value) {
                          formik.setValues((prevState) => ({
                            ...prevState,
                            search: '',
                            address1: '',
                            country: '',
                            city: '',
                            state: '',
                            zipCode: '',
                            latitude: null,
                            longitude: null,
                          }));
                        }
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.address1 && Boolean(formik.errors.address1)}
                      helperText={formik.touched.address1 && formik.errors.address1}
                    />
                  </StandaloneSearchBox>
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>City</StyledLabel>
                  <TextField
                    name='city'
                    id='city'
                    size='small'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>State</StyledLabel>
                  <TextField
                    name='state'
                    id='state'
                    size='small'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Country</StyledLabel>
                  <TextField
                    id='country'
                    name='country'
                    size='small'
                    fullWidth
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Zip Code</StyledLabel>
                  <TextField
                    name='zipCode'
                    size='small'
                    id='zipCode'
                    type='number'
                    onKeyDown={(e) => {
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    value={formik.values.zipCode}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (Number(newValue) >= 0) {
                        formik.setFieldValue('zipCode', newValue);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                  />
                </Grid>

                {/* Investment Section */}
                <Grid item xs={6}>
                  <StyledLabel>Total Price</StyledLabel>
                  <TextField
                    id='totalPrice'
                    name='totalPrice'
                    size='small'
                    type='number'
                    fullWidth
                    value={formik.values.totalPrice}
                    onChange={(e) => formik.setFieldValue('totalPrice', Math.abs(e.target.value))}
                    onBlur={formik.handleBlur}
                    error={formik.touched.totalPrice && Boolean(formik.errors.totalPrice)}
                    helperText={formik.touched.totalPrice && formik.errors.totalPrice}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Paid Amount</StyledLabel>
                  <TextField
                    id='paidAmount'
                    name='paidAmount'
                    size='small'
                    type='number'
                    fullWidth
                    value={formik.values.paidAmount}
                    onChange={(e) => formik.setFieldValue('paidAmount', Math.abs(e.target.value))}
                    onBlur={formik.handleBlur}
                    error={formik.touched.paidAmount && Boolean(formik.errors.paidAmount)}
                    helperText={formik.touched.paidAmount && formik.errors.paidAmount}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Balance Amount</StyledLabel>
                  <TextField
                    id='balanceAmount'
                    name='balanceAmount'
                    size='small'
                    type='number'
                    fullWidth
                    value={formik.values.balanceAmount}
                    onChange={(e) =>
                      formik.setFieldValue('balanceAmount', Math.abs(e.target.value))
                    }
                    onBlur={formik.handleBlur}
                    error={formik.touched.balanceAmount && Boolean(formik.errors.balanceAmount)}
                    helperText={formik.touched.balanceAmount && formik.errors.balanceAmount}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Payment Date</StyledLabel>
                  <TextField
                    id='paymentDate'
                    name='paymentDate'
                    type='date'
                    size='small'
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formik.values.paymentDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.paymentDate && Boolean(formik.errors.paymentDate)}
                    helperText={formik.touched.paymentDate && formik.errors.paymentDate}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Mode Of Payment</StyledLabel>
                  <TextField
                    id='modeOfPayment'
                    name='modeOfPayment'
                    size='small'
                    fullWidth
                    value={formik.values.modeOfPayment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.modeOfPayment && Boolean(formik.errors.modeOfPayment)}
                    helperText={formik.touched.modeOfPayment && formik.errors.modeOfPayment}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Transaction Number</StyledLabel>
                  <TextField
                    id='transactionNumber'
                    name='transactionNumber'
                    size='small'
                    fullWidth
                    value={formik.values.transactionNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
              {showPrevious ? (
                <Grid
                  item
                  xs={12}
                  textAlign='center'
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Button
                      variant='text'
                      color='inherit'
                      onClick={onBack}
                      // startIcon={<ArrowBackIcon size='small' />}
                      disabled={formik.isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Previous
                    </Button>
                  </Box>
                  <StyledBoxFooter>
                    <ModalCancel
                      className='cancelButton'
                      variant='outlined'
                      onClick={handleClose}
                      style={{ boxShadow: 'none' }}
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </ModalCancel>
                    <ModalSave
                      color='primary'
                      size='large'
                      variant='contained'
                      type='submit'
                      sx={{ textTransform: 'none', marginLeft: 2 }}
                    >
                      Save
                    </ModalSave>
                  </StyledBoxFooter>
                </Grid>
              ) : (
                <StyledBoxFooter>
                  <ModalCancel
                    className='cancelButton'
                    variant='outlined'
                    onClick={handleClose}
                    style={{ boxShadow: 'none' }}
                    sx={{ textTransform: 'none' }}
                  >
                    Cancel
                  </ModalCancel>
                  <ModalSave
                    color='primary'
                    size='large'
                    variant='contained'
                    type='submit'
                    sx={{ textTransform: 'none', marginLeft: 2 }}
                  >
                    Save
                  </ModalSave>
                </StyledBoxFooter>
              )}
            </form>
          </ModalContent>
        </Box>
      </Modal>
      <TitleCompanyAddEdit
        open={openTitleCompanyModal}
        onClose={handleCloseRealtorInvestment}
        handleOpenRealtorInvestment={handleOpenRealtorInvestment}
        buyerData={buyerData}
        saleData={saleData}
        onBack={handleBackToBuyerInvestmentModal}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        saleId={saleId}
        isEdit={isEdit}
      />
    </>
  );
};

export default BuyerInvestmentModal;
