import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createUpdateProjectBuildingSale } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectBuildingUnitSaleCreateUpdate(projectId, saleId) {
  const queryClient = useQueryClient();

  const isEditingMode = saleId !== undefined && saleId !== null;

  return useMutation({
    mutationFn: async ({ buyerData, updatedSaleData, selectedBuilding, selectedUnit, saleId }) => {
      const buyerDetails = {
        buyerName: buyerData?.buyerName,
        contactPerson: buyerData?.buyerContactPerson,
        email: buyerData?.email,
        phoneNo: buyerData?.phoneNo,
        address: buyerData?.address1,
        country: buyerData?.country,
        state: buyerData?.state,
        city: buyerData?.city,
        zipCode: buyerData?.zipCode,
      };

      const saleDetails = {
        buyerRealtor: updatedSaleData?.buyerRealtor,
        saleSpace: updatedSaleData?.saleSpace,
        unitNo: updatedSaleData?.unitNo,
        salePrice: updatedSaleData?.salePrice,
        saleExecutionDate: updatedSaleData?.saleExecutionDate,
        saleFixturizationDate: updatedSaleData?.saleFixturizationDate,
        saleStartDate: updatedSaleData?.saleStartDate,
        saleEndDate: updatedSaleData?.saleEndDate,
        buyerSaleCommission: updatedSaleData?.buyerSaleCommission,
        sellerSaleCommission: updatedSaleData?.sellerSaleCommission,
        closingCost: updatedSaleData?.closingCost,
        titlePolicyCost: updatedSaleData?.titlePolicyCost,
        comments: updatedSaleData?.comments,
      };

      const unitDetails = {
        buyerDetails,
        saleDetails,
      };

      const buildingDetails = {
        buildingName: selectedBuilding?.buildingName,
        buildingId: selectedBuilding?.id,
        unitId: selectedUnit?.unitId,
      };

      const createdPayload = {
        ...buildingDetails,
        unitDetails,
      };

      const updatedPayload = {
        ...buildingDetails,
        id: saleId,
        unitDetails,
      };

      const payloadData = isEditingMode ? updatedPayload : createdPayload;

      return await createUpdateProjectBuildingSale(projectId, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.buildingUnitSales, projectId],
      }),
  });
}
