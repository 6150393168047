export const topBarHeight = 64;
export const sideNavWidth = 213;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const clientId = 'YOUR_CLIENT_ID.apps.googleusercontent.com'; //will be updated once we get this
export const googleMapsApiKey = 'AIzaSyCDUIkyDMVrgLh4Ax0Ki8DHcWAktv7qPTs';
export const DEFAULT_CENTER = { lat: 40.7128, lng: -74.006 }; //  New York City coords
export const MOBILE_NUMBER_REGEX = /^\+\d{10,12}$/;

export const paymentOptions = [{ label: 'Cash' }, { label: 'Online' }, { label: 'Card' }];
export const designationOptions=[{label:'Architect'},{label:'Investor'},{label:'General Partner'},{label:'Realtor'}];
export const propertyType=[{label:'Land'}];
export const SubType=[{label:'Commercial'},{label:'industrial'},{label:'Residential - Multi Family'}, {label:'Residential - Single Family'}]
export const selectAllOptions = {
  services: {
    name: 'allServices',
    displayName: 'Select all',
    description: '',
  },
  permissions: {
    identifier: 'allPermissions',
    displayName: 'Select all',
    description: '',
  },
};

export const AmenitiesOptions = {
  services:
  [
    {
      name: 'allAmenities',
      displayName: 'Select all',
      description: '',
    },
    {
      name: 'Flooring',
      displayName: 'Flooring',
      description: '',
    },
    {
      name: 'Gym',
      displayName: 'Gym',
      description: '',
    },
    {
      name: 'Swimming Pool',
      displayName: 'Swimming Pool',
      description: '',
    },
  ]
};

export const fullAccessPermission = {
  resource: '*',
  resourceId: '*',
  action: '*',
  verb: '*',
};

export const projectTypes = {
  LEAD: 'lead',
  NEW_PROJECT: 'newproject',
  ON_GOING: 'ongoing',
  ON_HOLD: 'onhold',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  PREVIOUS: 'previous',
};

export const documentTypes = {
  DUE_DILIGENCE: 'Due Diligence',
  CONTRACT: 'Contract documents',
  CLOSING: 'Closing documents',
  EXPENSES: 'Expense documents',
  GC_CHANGE_ORDERS: 'ChangeOrderDetails documents',
  CONSTRUCTION_LENDER: 'Construction Lender documents',
  LEASE_DOCUMENTS: 'Lease Documents',
};

export const expensesTypes = [
  { label: 'Architect' },
  { label: 'Engineer' },
  { label: 'Construction' },
];

export const loanTypes = [
  { label: 'Owner Finance' },
  { label: 'Third Party Lending' },
];


export const countryCodes = [
  { code: "+1", country: "USA" },
  { code: "+44", country: "UK" },
  { code: "+91", country: "India" },
];
export const requestsTypes = {
  INVESTMENT_MAIL: 'INVESTMENT_MAIL',
  PAYMENT_REQUEST_MAIL: 'PAYMENT_REQUEST_MAIL',
  OUTGOING_USER_INVESTMENT: 'OUTGOING_USER_INVESTMENT',
  OUTGOING_PROJECT_INVITATION: 'OUTGOING_PROJECT_INVITATION',
};

export const predefinedRoles = {
  OWNER_ROLE: 'OWNER_ROLE',
  GENERAL_PARTNER: 'General Partner',
  INVESTOR: 'Investor',
  ENGINEER: 'Engineer',
  ARCHITECT: 'Architect',
  ATTORNEY: 'Attorney',
};

export const investorRoles = [
  predefinedRoles.INVESTOR,
  predefinedRoles.OWNER_ROLE,
  predefinedRoles.GENERAL_PARTNER,
];

export const statuses = {
  ACCEPTED: 'ACCEPTED',
  GRANTED: 'GRANTED',
  PENDING: 'PENDING',
  DECLINED: 'DECLINED',
};

export const requestActions = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
};

export const requestStatusMessages = {
  PENDING: {
    message: 'We will notify you when you get any pending request',
  },
};

export const dateFormats = {
  full: 'MMMM D, YYYY', // January 1, 2024
  short: 'MM/DD/YYYY', // 01/01/2024
  time12: {
    full: 'MMMM D, YYYY h:mm A', // January 1, 2024 2:30 PM
    short: 'MM/DD/YYYY h:mm A', // 01/01/2024 2:30 PM
    timeOnly: 'h:mm A', // 2:30 PM
  },
  time24: {
    full: 'MMMM D, YYYY HH:mm', // January 1, 2024 14:30
    short: 'MM/DD/YYYY HH:mm', // 01/01/2024 14:30
    timeOnly: 'HH:mm', // 14:30
  },
};

export const GCManagerKeys = {
  gcManager: 'gcManager',
  gcSiteManager: 'gcSiteManager',
};

export const GCManagerTypes = {
  [GCManagerKeys.gcManager]: 'GC_MANAGER',
  [GCManagerKeys.gcSiteManager]: 'GC_SITE_MANAGER',
};

export const managersTitles = {
  [GCManagerKeys.gcManager]: 'GC Manager Details',
  [GCManagerKeys.gcSiteManager]: 'GC Site Manager Details',
};


export const handleDocDownload = (content, fileName, mimeType) => {
  try {
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Download failed:', error);
  }
};