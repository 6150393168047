import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useProjectDocumentsUpload } from '../../Documents/hooks/useProjectDocumentsUpload';
import { createProjectExpenses, updateProjectExpensesByInvoice } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export default function useProjectExpenseCreateUpdate(projectId, selectedExpense) {
  const queryClient = useQueryClient();
  const { mutateAsync: uploadDocument } = useProjectDocumentsUpload(projectId);

  const isUpdatingMode = !!selectedExpense;

  return useMutation({
    mutationFn: async ({ data, isDocumentSameAsPrevious, previousDocumentID }) => {
      const documentID = [];

      if (!isDocumentSameAsPrevious) {
        const response = await uploadDocument(data.documents);
        const parsedDocumentID = response.match(/Document IDs: \[([^\]]+)\]/)[1];

        documentID.push(parsedDocumentID);
      } else {
        documentID.push(previousDocumentID);
      }

      const expenseDetails = {
        expensesName: data.expensesName,
        totalAmount: Number(data.totalAmount),
        paidAmount: Number(data.paidAmount),
        paidTo: data.paidTo,
        expenseType: data.expenseType,
        modeOfPayment: data.modeOfPayment,
        comments: data.comments,
        paymentDocuments: documentID,
      };

      return isUpdatingMode
        ? await updateProjectExpensesByInvoice(
            projectId,
            data.invoiceNo,
            selectedExpense.id,
            expenseDetails,
          )
        : await createProjectExpenses(projectId, {
            invoiceNo: data.invoiceNo,
            paymentDetails: [expenseDetails],
          });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.expenses, projectId],
      }),
  });
}
