import { TableHead, TableRow, TableCell, TableBody, Chip, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import React from 'react';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Role Name</TableCell>
      <TableCell>Services</TableCell>
      <TableCell>Assigned To</TableCell>
      <TableCell>Created By</TableCell>
      <TableCell>Updated On</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function RolesTable({ roles, isOnHoldOrCancelled, handleMenuOpen }) {

  return (
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {roles?.length > 0 &&
            roles.map((role, index) => {
              return (
                <>
                  <StyledTableRow key={role.id}>
                    <TableCell>{role.name}</TableCell>
                    <TableCell>
                      {role?.services.map(([serviceName, service], serviceIndex, array) => (
                        <Chip
                          key={serviceName}
                          label={service.displayName}
                          sx={{ mt: 0.5, mr: serviceIndex < array.length - 1 ? 0.5 : 0 }}
                        />
                      ))}
                    </TableCell>
                    <TableCell>
                    {/* {role.assignedTo.map((member, memberIndex, array) => (
                    <Chip
                      key={memberIndex}
                      label={member}
                      sx={{ mt: 0.5, mr: memberIndex < array.length - 1 ? 0.5 : 0 }}
                    />
                  ))} */}
                  </TableCell>
                  <TableCell>{role.createdBy}</TableCell>
                  <TableCell>{/* {convertUTCDateToLocal(role.updatedOn)} */}</TableCell>
                  <TableCell align='center'>
                    <IconButton
                      aria-label='more'
                      onClick={(event) => handleMenuOpen(event, role)}
                      disabled={isOnHoldOrCancelled}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                  </StyledTableRow>
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

export default RolesTable;
