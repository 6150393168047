import React from "react";
import { Box, Modal, CardContent, CircularProgress } from "@mui/material";
import { NoOrgTitle, AddressTextBox, AddressBox, StyledDivider, LoadingCircle, StyleAddOrgButton, StyledEditIcon, StyledIcon, StyledCard, StyledBox, StyledTitle } from "../StyleComponents";
import AddressModal from "./AddressModal";
import EditIcon from "@mui/icons-material/Edit";
import { useUserAuth } from "app/hooks/userUserAuth";

const UserAddress = () => {
    const [openModal, setOpenModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { user } = useUserAuth();  // Get the user data

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    return (
        <>
            <StyledCard variant="outlined">
                <CardContent>
                    <StyledBox>
                        <StyledTitle>
                            Address
                        </StyledTitle>
                        {user && user.address1 ? (  // Check if user data exists and has address1
                            <StyledEditIcon
                                variant="outlined"
                                onClick={handleOpenModal}
                                startIcon={<EditIcon />}
                            >
                                Edit Address
                            </StyledEditIcon>
                        ) : (
                            <StyleAddOrgButton
                                onClick={handleOpenModal}
                                startIcon={
                                    <StyledIcon />
                                }
                            >   
                                Address
                            </StyleAddOrgButton>
                        )}
                    </StyledBox>
                    <StyledDivider />
                    <Box>
                        {loading ? (
                            <LoadingCircle>
                                <CircularProgress />
                            </LoadingCircle>
                        ) : user && user.address1 ? (  // Use user data directly
                            <React.Fragment>
                                <AddressBox>
                                    {user.address1},{user.address2 ? user.address2 + "," : ""}{user.city},{user.state},{user.zipCode},{user.country}
                                </AddressBox>
                            </React.Fragment>
                        ) : (
                            <AddressTextBox>
                                <NoOrgTitle>
                                    No details available
                                </NoOrgTitle>
                            </AddressTextBox>
                        )}
                    </Box>
                </CardContent>
            </StyledCard>
            <Modal open={openModal} onClose={handleCloseModal}>
                <AddressModal handleCloseModal={handleCloseModal} openModal={openModal} />
            </Modal>
        </>
    );
};

export default UserAddress;
