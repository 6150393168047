// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Box from '@mui/material/Box';
// import { Button, Collapse, Container, styled, Typography } from '@mui/material';
// import AmenitySubType from './AmenitySubType';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { useState } from 'react';

// const ProductTable = styled(Table)(({ theme }) => ({
//   minWidth: 400,
//   whiteSpace: 'pre',
//   '& small': {
//     width: 50,
//     height: 15,
//     borderRadius: 500,
//     boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
//   },
//   '& td': { borderBottom: 'none', color: '#272937', fontSize: '0.875rem', fontWeight: 500 },
//   '& td:first-of-type': { paddingLeft: '16px !important' },
//   fontSize: '1rem',
//   '& th': {
//     fontSize: '0.875rem',
//   },
//   [theme.breakpoints.down('md')]: {
//     fontSize: '0.875rem',
//     '& th': {
//       fontSize: '0.8125rem',
//     },
//     '& td': {
//       fontSize: '0.8125rem',
//     },
//   },
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '0.75rem',
//     '& th': {
//       fontSize: '0.75rem',
//     },
//     '& td': {
//       fontSize: '0.75rem',
//     },
//   },
// }));

// const cellStyle = {
//   width: '12.5%',
//   textAlign: 'left',
//   padding: '8px',
// };

// const tableBodyCellStyle = {
//   fontWeight: 500,
//   fontSize: '14px',
//   align: 'left',
//   paddingLeft: '0.7%',
// };

// const TableHeader = () => (
//   <TableHead>
//     <TableRow>
//       <TableCell>Role Name</TableCell>
//       <TableCell>Services</TableCell>
//       <TableCell>Assigned To</TableCell>
//       <TableCell>Created By</TableCell>
//       <TableCell>Updated On</TableCell>
//       <TableCell align='right' style={{ width: 48 }}></TableCell>
//     </TableRow>
//   </TableHead>
// );

// function AmenitiesTable({ roles, isOnHoldOrCancelled, handleMenuOpen }) {
//   const [openRow, setOpenRow] = useState(null);
//   const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);
//   return (
//     <div>
//       <Box overflow='auto' m={2} mt={0} border='2px solid #E5E5E7'>
//         {!dummyRoles || dummyRoles?.length === 0 ? (
//           <>
//             <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   height: '380px',
//                   width: '1035px',
//                   marginLeft: '8%',
//                   borderRadius: '8px',
//                   textAlign: 'center',
//                 }}
//               >
//                 <Typography
//                   sx={{ fontWeight: '600' }}
//                   variant='body1'
//                   color='var(--noDetails-text)'
//                   gutterBottom
//                 >
//                   No details added
//                 </Typography>
//                 <Typography variant='body2' color='textSecondary' paragraph>
//                   It seems you have not added any details related to Amenities.
//                 </Typography>
//                 <Button variant='contained' color='primary' sx={{ textTransform: 'none' }}>
//                   Add Details
//                 </Button>
//               </Box>
//             </Container>
//           </>
//         ) :
//           <ProductTable>
//             <Box
//               sx={{
//                 marginLeft: 3,
//                 marginRight: 3,
//                 marginTop: 1,
//                 marginBottom: 1,
//                 overflowY: 'auto',
//                 border: '2px solid var(--border-color)',
//                 maxHeight: '400px',
//                 position: 'relative',
//               }}
//             >
//               <Table>
//                 <TableHead
//                   sx={{
//                     position: 'sticky',
//                     top: 0,
//                     zIndex: 1,
//                     backgroundColor: 'var(--tableHead-bg-color)',
//                   }}
//                 >
//                   <TableRow color='var(--tableHead-row-color)'>
//                     <TableCell sx={{ ...cellStyle, paddingLeft: '1.5%  ' }}>S.NO.</TableCell>
//                     <TableCell sx={cellStyle}>Amenity Name</TableCell>
//                    <TableCell sx={cellStyle}>Standard</TableCell>
//                      {/* <TableCell sx={cellStyle}>Default Sub Type</TableCell>
//                     <TableCell sx={cellStyle}>Price</TableCell> */}
//                     <TableCell sx={cellStyle}>Created By</TableCell>
//                     <TableCell sx={cellStyle}>Updated By</TableCell>
//                   </TableRow>
//                 </TableHead>

//                 <TableBody>
//                   {dummyRoles &&
//                     dummyRoles.map((amenity, index) => {
//                       return (
//                         <>
//                           <TableRow
//                             hover
//                             key={amenity?.id}
//                             sx={{ cursor: 'pointer', color: 'var(--tableBody-row-color)' }}
//                             onClick={() => handleRowClick(amenity?.id)}
//                           >
//                             <TableCell sx={tableBodyCellStyle}>
// <div
//   style={{
//     fontWeight: 100,
//     fontSize: '14px',
//     align: 'left',
//     width: '60px',
//     color: 'var(--tableBody-cell-color)',
//   }}
// >
//   <span>
//     {openRow === amenity?.id ? (
//       <KeyboardArrowUpIcon sx={{ marginBottom: '-7px' }} />
//     ) : (
//       <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />
//     )}
//   </span>
//   <span style={{ textDecoration: 'underline' }}>
//     {(index + 1).toString().padStart(2, '0')}
//   </span>
// </div>
//                             </TableCell>
//                             <TableCell sx={tableBodyCellStyle}>
//                               {amenity?.name}
//                             </TableCell>
//                             <TableCell sx={tableBodyCellStyle}>Cardio</TableCell>
//                             {/* <TableCell sx={tableBodyCellStyle}>
//                               {amenity?.subType}
//                             </TableCell>
//                             <TableCell sx={tableBodyCellStyle}>
//                               {amenity?.price || '00'}
//                             </TableCell> */}
//                             <TableCell sx={tableBodyCellStyle}>
//                               {amenity?.createdBy}
//                             </TableCell>
//                             <TableCell sx={tableBodyCellStyle}>
//                               {amenity?.UpdatedBy}
//                             </TableCell>
//                           </TableRow>
// <TableRow>
//   <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
//     <Collapse in={openRow === amenity?.id} timeOut='auto' unmountOnExit>
//       <Box>
//         <AmenitySubType
//           selectedAmenity={amenity}
//         />
//       </Box>
//     </Collapse>
//   </TableCell>
// </TableRow>
//                         </>
//                       );
//                     })}
//                 </TableBody>
//               </Table>
//             </Box>
//           </ProductTable>
//         }
//       </Box>
//     </div>
//   );
// }
// export default AmenitiesTable;

import { TableHead, TableRow, TableCell, TableBody, Box, Collapse, Icon } from '@mui/material';
import { Span, StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AmenitySubType from './AmenitySubType';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import { SideNavMenu } from 'app/components';
import UpdateIcon from '@mui/icons-material/Update';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Amenity Name</TableCell>
      <TableCell>Standard</TableCell>
      <TableCell>Created By</TableCell>
      <TableCell>Updated By</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function AmenitiesTable({ openModal }) {
  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);

  return (
    <>
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {dummyRoles &&
            dummyRoles.map((amenity, index) => {
              return (
                <>
                  <StyledTableRow key={amenity?.id}>
                    <TableCell>
                      <div
                        style={{
                          fontWeight: 100,
                          fontSize: '14px',
                          align: 'left',
                          color: '#645BBB',
                        }}
                        onClick={() => handleRowClick(amenity?.id)}
                      >
                        <span>
                          {openRow === amenity?.id ? (
                            <KeyboardArrowUpIcon sx={{ marginBottom: '-7px' }} />
                          ) : (
                            <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />
                          )}
                        </span>
                        <span style={{ cursor: 'pointer' }}>{amenity?.name}</span>
                      </div>
                    </TableCell>
                    <TableCell>{amenity?.subType}</TableCell>
                    <TableCell> {amenity?.createdBy}</TableCell>
                    <TableCell> {amenity?.updatedBy}</TableCell>
                    <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
                      <SideNavMenu
                        menuButton={
                          <UserMenu>
                            <MoreVertIcon />
                          </UserMenu>
                        }
                      >
                        <StyledItem onClick={() => openModal('add')}>
                          <Icon>
                            <UpdateIcon />
                          </Icon>
                          <Span>Update</Span>
                        </StyledItem>
                      </SideNavMenu>
                    </TableCell>
                  </StyledTableRow>
                  {openRow === amenity?.id && (
                    <TableRow>
                      <TableCell sx={{ paddingBottom: '0', paddingTop: '0', backgroundColor: '#fff' }} colSpan={12}>
                        <Collapse in={openRow === amenity?.id} timeOut='auto' unmountOnExit>
                            <AmenitySubType selectedAmenity={amenity} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
    </>
  );
}

export default AmenitiesTable;


const dummyRoles = [
  {
    id: 1, // Added ID for amenity uniqueness
    name: 'Gym Facility',
    type: 'Premium',
    subType: 'Cardio', // default sub type
    price: '$100',
    createdBy: 'Admin',
    updatedBy: 'Admin',
    subtypes: [
      { name: 'Cardio', price: '$50' },
      { name: 'Strength Training', price: '$70' },
    ],
  },
  {
    id: 2, // Added ID for amenity uniqueness
    name: 'Swimming Pool',
    type: 'Basic',
    subType: 'Adult', // default sub type
    price: '$80',
    createdBy: 'Admin',
    updatedBy: 'Admin',
    subtypes: [
      { name: 'Adult', price: '$60' },
      { name: 'Kids', price: '$50' },
    ],
  },
  // {
  //   id: 3, // Added ID for amenity uniqueness
  //   name: 'Tennis Court',
  //   type: 'Standard',
  //   subType: 'Singles', // default sub type
  //   price: '$150',
  //   createdBy: 'Admin',
  //   updatedBy: 'Admin',
  //   subtypes: [
  //     { name: 'Singles', price: '$75' },
  //     { name: 'Doubles', price: '$100' },
  //   ],
  // },
  // {
  //   id: 4, // Added ID for amenity uniqueness
  //   name: 'Yoga Studio',
  //   type: 'Premium',
  //   subType: 'Morning', // default sub type
  //   price: '$120',
  //   createdBy: 'Admin',
  //   updatedBy: 'Admin',
  //   subtypes: [
  //     { name: 'Morning', price: '$70' },
  //     { name: 'Evening', price: '$90' },
  //   ],
  // },
];
