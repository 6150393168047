import { TableHead, TableRow, TableCell, TableBody, Icon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Span, StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import { useRef, useState } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import { SideNavMenu } from 'app/components';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import GCManagerAddEdit from './GCManagerAddEdit';
const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>S.no.</TableCell>
      <TableCell>Contact Person</TableCell>
      <TableCell>Email Address</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function GCManagerViewTable({ managers, managerKey, contractor }) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const selectedManager = useRef(null);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleClickUpdate = (manager) => {
    selectedManager.current = manager;
    toggleDialog();
  };
  return (
    <>
      <TableWrapper>
        <StyledTable>
          <TableHeader />
          <TableBody>
            {managers &&
              managers.map((manager, index) => {
                return (
                  <>
                    <StyledTableRow key={manager.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{manager.contactPerson}</TableCell>
                      <TableCell>{manager.emailId}</TableCell>
                      <TableCell>{manager.phoneNo}</TableCell>
                      <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
                        <SideNavMenu
                          menuButton={
                            <UserMenu>
                              <MoreVertIcon />
                            </UserMenu>
                          }
                        >
                          <StyledItem onClick={() => handleClickUpdate(manager)}>
                            <Icon>
                              <UpdateIcon />
                            </Icon>
                            <Span>Update</Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                    </StyledTableRow>
                  </>
                );
              })}
          </TableBody>
        </StyledTable>
      </TableWrapper>
      {isOpenDialog && (
        <GCManagerAddEdit
          contractor={contractor}
          manager={selectedManager.current}
          managerKey={managerKey}
          closeDialog={toggleDialog}
        />
      )}
    </>
  );
}

export default GCManagerViewTable;
