import React, { useState } from 'react';
import {
  Box,
  styled,
  Button,
  Container,
  Typography,
  Grid,
  Card,
} from '@mui/material';
import { StyledIcon } from 'app/common/Typography.js';
import PropertyAddEdit from './PropertyAddEdit';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectPMAll } from './hooks/useProjectPMAll';
import { StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';
import PMTable from './PMTable';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const PMSection = () => {
  const { projectId } = useProject();
  const { data: properties } = useProjectPMAll(projectId);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  return (
    <>
      <SubtitleDivider />
      <Box alignItems='center' mt={3}>
        {properties?.length === 0 ? (
          <>
            <Title>Property Management</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Property Management
                </Typography>
                <Button onClick={toggleDialog} variant='contained' color='primary'>
                  Add Details
                </Button>
              </Box>
            </Container>
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card sx={{ px: 2, py: 1 }} elevation={0}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid
                      item
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      gap={0.5}
                    >
                      <Title>Property Management Details</Title>
                    </Grid>
                    <Grid item>
                      <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                        Property Management
                      </StyledAddButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableFilter title={'Property Management Details'}/>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <PMTable
                  properties={properties}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <PropertyAddEdit isOpenDialog={isOpenDialog} closeDialog={toggleDialog} />
    </>
  );
};

export default PMSection;
