import React from "react";
import {
  Box,
  IconButton,
  Modal,
  Typography
} from "@mui/material";
import { CopyIcon, LCDeleteIcon } from "app/common/icons";
import ReferenceLinksModal from "./Modals/ReferenceLinksModal";
import {
  StyledAddButton, StyledIcon, IconDivider, StledCardTitle,
  ReferenceLinksBody, ReferListItem, ReferLinkText, ReferIconsSec
} from "./StyledComponent";

const ReferenceLinks = () => {

  const [openModal, setOpenModal] = React.useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  }
  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const [links, setLinks] = React.useState([]);

  const handleAddLink = () => {
    const newLink = {
      id: Date.now(),
      url: "www.samplelink.com/newlink",
    };
    setLinks([...links, newLink]);
  };

  const handleDeleteLink = (id) => {
    setLinks(links.filter((link) => link.id !== id));
  };

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <Box sx={{ margin: '20px 38px 20px 20px' }} display="flex" justifyContent="space-between" alignItems="center">
        <StledCardTitle>Reference Links</StledCardTitle>
        <StyledAddButton
          startIcon={
            <StyledIcon />
          }
          onClick={handleOpenModal}
        >
          Reference Links
        </StyledAddButton>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <ReferenceLinksModal handleCloseModal={handleCloseModal} />
      </Modal>

      <IconDivider orientation="vertical" />

      {links.length === 0 ? (
        // Display message if no links are added
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100px" }} // Adjust height as needed
        >
          <Typography variant="h6" color="textSecondary">
            No Reference Link Added
          </Typography>
        </Box>
      ) : (
        <ReferenceLinksBody>
          {links.map((link) => (
            <ReferListItem key={link.id}>
              <ReferLinkText>{link.url}</ReferLinkText>
              <ReferIconsSec>
                <IconButton
                  color="primary"
                  onClick={() => handleCopyLink(link.url)}
                >
                  <CopyIcon />
                </IconButton>

                <IconDivider orientation="vertical" flexItem />

                <IconButton
                  color="error"
                  onClick={() => handleDeleteLink(link.id)}
                >
                  <LCDeleteIcon />
                </IconButton>
              </ReferIconsSec>
            </ReferListItem>
          ))}
        </ReferenceLinksBody>
      )}
    </>
  );
};

export default ReferenceLinks;
