import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Modal,
  IconButton,
  InputAdornment,
  Input,
  styled,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  ImageContainer,
  FileContainer,
  FileDetails,
  StyledButton,
  FileContainerDetails,
  Container,
} from 'app/common/Typography';
import { DownloadDragIcon, PdfIcon, DocIcon } from 'app/common/icons';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import LeadAddLeaseForm from './LeadAddLeaseForm';
import { Formik, getIn, FieldArray } from 'formik';
import dayjs from 'dayjs';
import { CustomGCExpensesInputText } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import notify from 'app/utils/notify';

const ModalDivider = styled('div')({
  height: '1px',
  marginBottom: '20px',
  backgroundColor: '#D5D5DD',
});

const currencies = [
  { value: 'INR', label: 'INR' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
];

const CustomDropdown = styled(TextField)({
  '& .MuiInputBase-root': {
    '&::after': {
      backgroundColor: '#DBE9FF',
    },
  },
});
const validateCompleteDate = (date) => {
  if (date && dayjs(date).isValid()) {
    const day = dayjs(date).date();
    const month = dayjs(date).month();
    const year = dayjs(date).year();
    return day && month && year;
  }
  return false;
};

const validationSchema = Yup.object().shape({
  rentSqFtPerYear: Yup.string().required('Rent/SqFt is Required'),
  leaseTerm: Yup.string().required('Lease Term is Required'),
  rentIncreasePerYear: Yup.string().required('Rent Increase/Year is Required'),
  leaseExecutionDate: Yup.date()
    .typeError('Lease Execution Date is Required')
    .test('complete-date', 'Lease Execution Date is required!', validateCompleteDate),
  leaseFixturizationDate: Yup.date()
    .typeError('lease Fixturization Date is required!')
    .test('complete-date', 'lease Fixturization Date is required!', validateCompleteDate),
  leaseStartDate: Yup.date()
    .typeError('Lease Start Date is Required')
    .test('complete-date', 'Lease Start Date is required!', validateCompleteDate),
  leaseEndDate: Yup.date()
    .typeError('Lease End Date is Required')
    .test('complete-date', 'Lease End Date is required!', validateCompleteDate),
  currentMonthRent: Yup.string().required('Current Month Rent is Required'),
  freeRentMonths: Yup.string().required('Free Rent Months is Required'),
  nnn: Yup.string().required('NNN is Required'),
  securityDeposit: Yup.string().required('Security Deposit is Required'),
  firstMonthRentDate: Yup.date()
    .typeError('First Month Rent Date is Required')
    .test('complete-date', 'First Month Rent date is required!', validateCompleteDate),
  realtorCommission: Yup.string().required('Realtor Commission is Required'),
  realtorRebate: Yup.string().required('Realtor Rebate is Required'),
  comments: Yup.string().optional(),
  leaseDocuments: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().required('Document is required'),
      docName: Yup.string().required('Document Name is required'),
    }),
  ),
});

const LeadLeaseForm = ({
  open,
  onClose,
  selectedBuilding,
  selectedUnit,
  leaseDetails1,
  tenantDetails,
  selectedunitIndex,
  buildingIndex,
  leaseIndex,
}) => {
  const [currency, setCurrency] = useState('INR');
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [leaseDetails, setLeaseDetails] = useState(null);
  const [leaseDetailsList, setLeaseDetailsList] = useState([]);
  const { documents } = useProject();
  const [dropdownValue, setDropdownValue] = useState('Months');

  function formatDate(isoString) {
    const date = new Date(isoString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  function calculateLeaseEndDate(startDate, leaseTerm, unit) {
    if (!startDate || !leaseTerm) return null;
    let newEndDate = dayjs(startDate);
    
    switch (unit) {
      case 'Months':
        newEndDate = newEndDate.add(leaseTerm, 'month');
        break;
      case 'Years':
        newEndDate = newEndDate.add(leaseTerm, 'year');
        break;
      case 'Days':
        newEndDate = newEndDate.add(leaseTerm, 'day');
        break;
      default:
        return null;
    }
    return newEndDate;
  }

  const handleLeaseTermChange = (e, values, setFieldValue) => {
    const leaseTerm = e.target.value;
    setFieldValue('leaseTerm', leaseTerm);

    const endDate = calculateLeaseEndDate(
      values.leaseStartDate,
      leaseTerm,
      dropdownValue
    );
    if (endDate) setFieldValue('leaseEndDate', endDate);
  };

  const handleLeaseStartDateChange = (date, values, setFieldValue) => {
    setFieldValue('leaseStartDate', date);

    const endDate = calculateLeaseEndDate(date, values.leaseTerm, dropdownValue);
    if (endDate) setFieldValue('leaseEndDate', endDate);
  };


  function base64ToFile(base64String, fileName, mimeType) {
    const byteString = atob(base64String);
    const byteNumbers = new Array(byteString.length)
      .fill(null)
      .map((_, i) => byteString.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], fileName, { type: mimeType });
  }
  const initialValues = {
    leaseSpace: selectedUnit?.space || '',
    rentSqFtPerYear: leaseDetails1?.rentSqFtPerYear || '',
    leaseTerm: leaseDetails1?.leaseTerm || '',
    rentIncreasePerYear: leaseDetails1?.rentIncreasePerYear || '',
    leaseExecutionDate: formatDate(leaseDetails1?.leaseExecutionDate) || null,
    leaseFixturizationDate: formatDate(leaseDetails1?.leaseFixturizationDate) || null,
    leaseStartDate: formatDate(leaseDetails1?.leaseStartDate) || null,
    leaseEndDate: formatDate(leaseDetails1?.leaseEndDate) || null,
    currentMonthRent: leaseDetails1?.currentMonthRent || '',
    freeRentMonths: leaseDetails1?.freeRentMonths || '',
    nnn: leaseDetails1?.nnn || '',
    securityDeposit: leaseDetails1?.securityDeposit || '',
    firstMonthRentDate: formatDate(leaseDetails1?.firstMonthRentDate) || null,
    realtorCommission: leaseDetails1?.realtorCommission || '',
    realtorRebate: leaseDetails1?.realtorRebate || '',
    comments: leaseDetails1?.comments || '',
    leaseDocuments: documents
      ?.filter((doc) => leaseDetails1?.leaseDocuments?.[0]?.includes(doc.metadata.fileId))
      .map((doc, index) => {
        const mimeType = doc.metadata.mimeType || 'application/pdf';
        const extension = mimeType === 'application/pdf' ? 'pdf' : 'docx';
        return {
          file: base64ToFile(doc.content, `${doc.metadata.documentName}.${extension}`, mimeType),
          docName: doc.metadata.documentName || `Document ${index + 1}`,
        };
      }),
  };

  const handleClose = () => {
    onClose();
  };


  const handleBackToSaleForm = () => {
    setOpenModal(false); // Go back to the main form
  };

  function handleFileChange(event, values, setFieldValue) {
    const files = Array.from(event.target.files);
    const updatedDocuments = [
      ...values.leaseDocuments,
      ...files.map((file) => ({ file, docName: '' })),
    ];
    setFieldValue('leaseDocuments', updatedDocuments);
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDeleteFile = (index, values, setFieldValue) => {
    const updatedDocuments = [...values.leaseDocuments];
    updatedDocuments.splice(index, 1);
    setFieldValue('leaseDocuments', updatedDocuments);
  };

  const handleDrop = (event, values, setFieldValue) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const updatedDocuments = [
      ...values.leaseDocuments,
      ...files.map((file) => ({ file, docName: '' })),
    ];
    setFieldValue('leaseDocuments', updatedDocuments);
  };

  const handleFormSubmit = (values) => {
    if (values?.leaseDocuments.length === 0) {
      notify.error('Please add at least one document.');
      return;
    }
    setLeaseDetails(values);
    setOpenModal(true);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} disableEnforceFocus={true}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '35%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            overflowY: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
            <Typography variant='h6'>
              Lease Details
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ fontSize: 18, color: '#272937' }} />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex' }} mb={2}>
            <Typography sx={{ fontWeight: 500 }}>1/2 Lease Details</Typography>
            <Typography sx={{ fontWeight: 500, color: '#5C5D64', ml: 1 }}>
              Next: LLC Details
            </Typography>
          </Box>
          <ModalDivider />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
            validateOnBlur={true}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      label="Lease space (sq ft)"
                      type="number"
                      name="leaseSpace"
                      size="small"
                      value={selectedUnit?.space || leaseDetails1?.leaseSpace || ''}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      onKeyDown={(e) => {
                        // Block unwanted keys
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      error={touched.leaseSpace && Boolean(errors.leaseSpace)}
                      helperText={touched.leaseSpace && errors.leaseSpace}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Rent/Sq ft per year"
                      name="rentSqFtPerYear"
                      size="small"
                      value={values.rentSqFtPerYear}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      error={touched.rentSqFtPerYear && Boolean(errors.rentSqFtPerYear)}
                      helperText={touched.rentSqFtPerYear && errors.rentSqFtPerYear}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CustomDropdown
                              select
                              value={currency}
                              onChange={(e) => setCurrency(e.target.value)}
                              variant="standard"
                              style={{ minWidth: 50 }}
                            >
                              {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </CustomDropdown>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency === "INR" ? "₹" : "$"}
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        // Block unwanted keys
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Lease Term"
                      name="leaseTerm"
                      size="small"
                      type="number"
                      value={values.leaseTerm}
                      onChange={(e) => handleLeaseTermChange(e, values, setFieldValue)}
                      fullWidth
                      error={touched.leaseTerm && Boolean(errors.leaseTerm)}
                      helperText={touched.leaseTerm && errors.leaseTerm}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CustomDropdown
                              select
                              value={dropdownValue}
                              variant="standard"
                              onChange={(e) => setDropdownValue(e.target.value)}
                              style={{ minWidth: 40 }}
                            >
                              <MenuItem value="Months">Months</MenuItem>
                              <MenuItem value="Years">Years</MenuItem>
                              <MenuItem value="Days">Days</MenuItem>
                            </CustomDropdown>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{ min: 0 }}
                      onKeyDown={(e) => {
                        const blockedKeys = [
                          'e', 'E', '+', '-', '.', '/', "'", '[', ']', '{', '}', '\\', ';', ':',
                        ];
                        if (blockedKeys.includes(e.key)) e.preventDefault();
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      type='number'
                      label='Rent Increase/Year'
                      name='rentIncreasePerYear'
                      size='small'
                      value={values.rentIncreasePerYear}
                      onChange={handleChange}
                      fullWidth
                      error={touched.rentIncreasePerYear && Boolean(errors.rentIncreasePerYear)}
                      helperText={touched.rentIncreasePerYear && errors.rentIncreasePerYear}
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        // Block unwanted keys
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Lease Execution Date"
                        name='leaseExecutionDate'
                        value={dayjs(values.leaseExecutionDate)}
                        onChange={(date) => {
                          setFieldValue('leaseExecutionDate', date);
                          if (dayjs(values.leaseFixturizationDate).isBefore(date)) {
                            setFieldValue('leaseFixturizationDate', null);
                          }
                          if (dayjs(values.leaseStartDate).isBefore(date)) {
                            setFieldValue('leaseStartDate', null);
                          }
                          if (dayjs(values.leaseEndDate).isBefore(date)) {
                            setFieldValue('leaseEndDate', null);
                          }
                        }}
                        minDate={dayjs()}
                        slotProps={{
                          textField: {
                            size: 'small',
                            onBlur: handleBlur,
                            fullWidth: true,
                            variant: 'outlined',
                            error: Boolean(touched.leaseExecutionDate && errors.leaseExecutionDate),
                            helperText: touched.leaseExecutionDate && errors.leaseExecutionDate,
                          },
                        }}

                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='Lease Fixturization Date'
                        value={dayjs(values.leaseFixturizationDate)}
                        onChange={(date) => {
                          setFieldValue('leaseFixturizationDate', date);
                          if (dayjs(values.leaseStartDate).isBefore(date)) {
                            setFieldValue('leaseStartDate', null);
                          }
                          if (dayjs(values.leaseEndDate).isBefore(date)) {
                            setFieldValue('leaseEndDate', null);
                          }
                        }}
                        minDate={values.leaseExecutionDate ? dayjs(values.leaseExecutionDate) : dayjs()}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            variant: 'outlined',
                            error: Boolean(
                              touched.leaseFixturizationDate && errors.leaseFixturizationDate,
                            ),
                            helperText:
                              touched.leaseFixturizationDate && errors.leaseFixturizationDate,
                          },
                        }}

                      />
                    </LocalizationProvider>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='Lease Start Date'
                        value={dayjs(values.leaseStartDate)}
                        onChange={(date) => {
                          setFieldValue('leaseStartDate', date);
                          if (dayjs(values.leaseEndDate).isBefore(date)) {
                            setFieldValue('leaseEndDate', null);
                          }
                        }}
                        minDate={values.leaseFixturizationDate ? dayjs(values.leaseFixturizationDate) : dayjs()}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            variant: 'outlined',
                            error: Boolean(touched.leaseStartDate && errors.leaseStartDate),
                            helperText: touched.leaseStartDate && errors.leaseStartDate,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='Lease End Date'
                        value={dayjs(values.leaseEndDate)}
                        onChange={(date) => setFieldValue('leaseEndDate', date)}
                        minDate={values.leaseStartDate ? dayjs(values.leaseStartDate) : dayjs()}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            variant: 'outlined',
                            error: Boolean(touched.leaseEndDate && errors.leaseEndDate),
                            helperText: touched.leaseEndDate && errors.leaseEndDate,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid> */}

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Lease Start Date"
                        value={dayjs(values.leaseStartDate)}
                        onChange={(date) => handleLeaseStartDateChange(date, values, setFieldValue)}
                        minDate={dayjs()}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            variant: 'outlined',
                            error: Boolean(touched.leaseStartDate && errors.leaseStartDate),
                            helperText: touched.leaseStartDate && errors.leaseStartDate,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Lease End Date"
                        value={dayjs(values.leaseEndDate)}
                        onChange={(date) => setFieldValue('leaseEndDate', date)}
                        minDate={values.leaseStartDate ? dayjs(values.leaseStartDate) : dayjs()}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            variant: 'outlined',
                            error: Boolean(touched.leaseEndDate && errors.leaseEndDate),
                            helperText: touched.leaseEndDate && errors.leaseEndDate,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type='number'
                      label='Current Month Rent'
                      name='currentMonthRent'
                      size='small'
                      value={values.currentMonthRent}
                      onChange={handleChange}
                      fullWidth
                      error={touched.currentMonthRent && Boolean(errors.currentMonthRent)}
                      helperText={touched.currentMonthRent && errors.currentMonthRent}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <CustomDropdown
                              select
                              value={currency}
                              onChange={(e) => setCurrency(e.target.value)}
                              variant='standard'
                              style={{ minWidth: 50 }}
                            >
                              {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </CustomDropdown>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency === 'INR' ? '₹' : '$'}
                          </InputAdornment>
                        ),
                      }}

                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        // Block unwanted keys
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type='number'
                      label='Free Rent Months'
                      name='freeRentMonths'
                      size='small'
                      value={values.freeRentMonths}
                      onChange={handleChange}
                      fullWidth
                      error={touched.freeRentMonths && Boolean(errors.freeRentMonths)}
                      helperText={touched.freeRentMonths && errors.freeRentMonths}
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        // Block unwanted keys
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label='NNN'
                      type='number'
                      name='nnn'
                      size='small'
                      value={values.nnn}
                      onKeyDown={(e) => {
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={handleChange}
                      fullWidth
                      error={touched.nnn && Boolean(errors.nnn)}
                      helperText={touched.nnn && errors.nnn}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type='number'
                      label='Security Deposit'
                      name='securityDeposit'
                      size='small'
                      value={values.securityDeposit}
                      onChange={handleChange}
                      fullWidth
                      error={touched.securityDeposit && Boolean(errors.securityDeposit)}
                      helperText={touched.securityDeposit && errors.securityDeposit}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <CustomDropdown
                              select
                              value={currency}
                              onChange={(e) => setCurrency(e.target.value)}
                              variant='standard'
                              style={{ minWidth: 50 }}
                            >
                              {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </CustomDropdown>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency === 'INR' ? '₹' : '$'}
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        // Block unwanted keys
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='First Month Rent Date'
                        value={dayjs(values.firstMonthRentDate)}
                        onChange={(date) => setFieldValue('firstMonthRentDate', date)}
                        minDate={values.leaseStartDate ? dayjs(values.leaseStartDate) : dayjs()}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                            variant: 'outlined',
                            error: Boolean(touched.firstMonthRentDate && errors.firstMonthRentDate),
                            helperText: touched.firstMonthRentDate && errors.firstMonthRentDate,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type='number'
                      label='Realtor Commission'
                      name='realtorCommission'
                      size='small'
                      value={values.realtorCommission}
                      onChange={handleChange}
                      fullWidth
                      // onBlur={handleBlur}
                      error={touched.realtorCommission && Boolean(errors.realtorCommission)}
                      helperText={touched.realtorCommission && errors.realtorCommission}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <CustomDropdown
                              select
                              value={currency}
                              onChange={(e) => setCurrency(e.target.value)}
                              variant='standard'
                              style={{ minWidth: 50 }}
                            >
                              {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </CustomDropdown>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency === 'INR' ? '₹' : '$'}
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: 0,
                      }}
                      onKeyDown={(e) => {
                        // Block unwanted keys
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Realtor Rebate"
                      name="realtorRebate"
                      size="small"
                      type='number'
                      value={values.realtorRebate}
                      onChange={handleChange}
                      fullWidth
                      onKeyDown={(e) => {
                        const blockedKeys = [
                          "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      error={touched.realtorRebate && Boolean(errors.realtorRebate)}
                      helperText={touched.realtorRebate && errors.realtorRebate}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CustomDropdown
                              select
                              value={currency}
                              onChange={(e) => setCurrency(e.target.value)}
                              variant="standard"
                              style={{ minWidth: 50 }}
                            >
                              {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </CustomDropdown>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency === "INR" ? "₹" : "$"}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid style={{ marginTop: '1px', marginLeft: '25px' }} spacing={2} xs={10}>
                    <Typography
                      style={{
                        fontSize: '15px',
                        fontWeight: '450',
                        marginTop: '0.8em',
                        marginBottom: '0.5em',
                      }}
                    >
                      Lease Document
                    </Typography>
                    <Container
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, values, setFieldValue)}
                    >
                      <Typography variant='body1' marginTop={1} color={'#64748B'} fontSize={'16px'}>
                        <DownloadDragIcon />
                        {` Drag and Drop file here or `}
                        <label htmlFor='outlined-button-file'>
                          <StyledButton
                            disabled={values.leaseDocuments?.length > 0}
                            variant='outlined'
                            component='span'
                          >
                            Browse files
                          </StyledButton>
                        </label>
                      </Typography>
                      <Input
                        id={`outlined-button-file`}
                        sx={{
                          display: 'none',
                        }}
                        type='file'
                        size='small'
                        name='file'
                        onChange={(e) => handleFileChange(e, values, setFieldValue)}
                        inputProps={{ accept: '.pdf, .doc, .docx' }}
                        error={Boolean(
                          getIn(errors, `leaseDocuments[0].file`) &&
                          getIn(touched, `leaseDocuments[0].file`),
                        )}
                        helperText={
                          getIn(errors, `leaseDocuments[0].file`) &&
                            getIn(touched, `leaseDocuments[0].file`)
                            ? 'Document is required'
                            : ''
                        }
                      />
                    </Container>
                    <Typography variant='body1' color={'#64748B'} fontSize={'12px'} pt={1}>
                      {`File format only word or Pdf, Size should not exceed 10MB`}
                    </Typography>
                  </Grid>
                  <FileContainerDetails>
                    <FieldArray name='leaseDocuments'>
                      {({ remove }) =>
                        values?.leaseDocuments?.map((doc, index) => (
                          <FileContainer key={index}>
                            <ImageContainer>
                              {doc.file.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                              <FileDetails>
                                <Typography
                                  variant='body1'
                                  style={{ fontWeight: 'bold', marginLeft: '10px' }}
                                >
                                  {doc.file.name}
                                </Typography>
                                <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                  {(doc.file.size / 1024).toFixed(2)} KB
                                </Typography>
                              </FileDetails>
                              <IconButton
                                onClick={() => handleDeleteFile(index, values, setFieldValue)}
                              >
                                <DeleteIcon style={{ color: 'red' }} />
                              </IconButton>
                            </ImageContainer>
                            <ModalDivider />
                            <Grid container spacing={2}>
                              <Grid paddingLeft={2} xs={12}>
                                <CustomGCExpensesInputText
                                  name={`leaseDocuments[${index}].docName`}
                                  placeholder='Document Name'
                                  touched={touched}
                                  value={doc.docName}
                                  onChange={handleChange}
                                  error={Boolean(
                                    getIn(errors, `leaseDocuments[${index}].docName`) &&
                                    getIn(touched, `leaseDocuments[${index}].docName`),
                                  )}
                                  helperText={
                                    getIn(errors, `leaseDocuments[${index}].docName`) &&
                                      getIn(touched, `leaseDocuments[${index}].docName`)
                                      ? 'Document Name is required'
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                          </FileContainer>
                        ))
                      }
                    </FieldArray>
                  </FileContainerDetails>
                  <Grid item xs={12}>
                    <TextField
                      label='Comments'
                      rows={2}
                      name='comments'
                      value={values.comments}
                      onChange={handleChange}
                      multiline
                      fullWidth
                      error={touched.comments && Boolean(errors.comments)}
                      helperText={touched.comments && errors.comments}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    textAlign='center'
                    display='flex'
                    justifyContent='space-between'
                  >
                    <Button variant='outlined' color='error' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ boxShadow: 'none', marginRight: '0.1em' }}
                      type='submit'
                    >
                      {isEdit ? 'Update' : 'Next'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>

      <LeadAddLeaseForm
        openModal={openModal}
        leaseDetails={leaseDetails}
        onBack={handleBackToSaleForm} // Pass back button handler
        handleCancel={() => {
          setOpenModal(false);
          onClose();
        }}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        setLeaseDetailsList={setLeaseDetailsList}
        tenantDetails1={tenantDetails}
        selectedunitIndex={selectedunitIndex}
        buildingIndex={buildingIndex}
        leaseIndex={leaseIndex}
      />
    </>
  );
};

export default LeadLeaseForm;
