import React from 'react';
import { Box, Button, Grid, TextField, Modal, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { ModalCancel, ModalContent, ModalSave, StyledBoxFooter } from 'app/common/Typography';
import { StyledLabel } from '../../listings/Modals/StyledComponents';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { parseAddressComponents } from 'app/utils/helpers';
import { useProjectBuildingUnitSaleCreateUpdate } from './hooks/useProjectBuildingUnitSaleCreateUpdate';
import { useProject } from 'app/contexts/ProjectContext';
const SingleFamilyAddEdit = ({
  open,
  onClose,
  saleData,
  buyerData,
  handleOpenTitleCompanyModal,
  selectedBuilding,
  selectedUnit,
  saleId,
  isSingleResidential,
  isEdit,
  showPrevious = true,
}) => {
  const searchInputRef = React.useRef(null);
  const { projectId } = useProject();
  const { mutate: createUpdateSale } = useProjectBuildingUnitSaleCreateUpdate(projectId, saleData);
  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };
  const formik = useFormik({
    initialValues: {
      buyerName: '',
      buyerAddress: '',
      buyerPhone: '',
      buyerEmail: '',
      buyerCity: '',
      buyerState: '',
      buyerZip: '',
      buyerCountry: '',
      totalPrice: '',
      paidAmount: '',
      balanceAmount: '',
      paymentDate: '',
      modeOfPayment: '',
      transactionNumber: '',
    },
    // validationSchema: validationSchemas.buyerInvestment(),
    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      createUpdateSale(
        {
          buyerData,
          updatedSaleData: saleData,
          selectedBuilding,
          selectedUnit,
          saleId,
        },
        {
          onSettled: () => setSubmitting(false),
          onSuccess: handleClose,
        },
      );
      setSubmitting(false);
      handleClose(); // Close the modal after submission
    },
  });

  const handleUpgradeSelection = (event, amenity) => {
    const selectedUpgrade = event.target.checked;
    // Update the formik values or state with the selected upgrade for this amenity
    // Example: formik.setFieldValue('upgrades', updatedUpgrades);
  };

  const handleChangeDropDown = (name, value) => {
    // if (formData[name] !== value) {
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     [name]: value,
    //   }));
    // }
  };

  const subTypeOptions = [
    { label: 'Flooring', upgrades: ['Granite', 'Marble', 'Wood'] },
    { label: 'Gym', upgrades: ['Cardiac', 'Free Weights', 'Yoga'] },
    { label: 'Swimming Pool', upgrades: ['Adult Pool', 'Kids Pool', 'Hot Tub'] },
  ];

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '0%',
          transform: 'translate(0%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          //   boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          height: '100%',
          paddingLeft: '40px',
        }}
      >
        <ModalContent>
          <Box sx={{ justifyContent: 'space-between', display: 'flex', marginBottom: '20px' }}>
            <Typography variant='h6'>
              {isSingleResidential
                ? `${isEdit ? 'Edit' : 'Add'} Single Residential Family Information`
                : `${isEdit ? 'Edit' : 'Add'} Multi Residential Family Information`}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
            </IconButton>
          </Box>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={2}>
              {/* Buyer Information Section */}
              {isSingleResidential && (
                <>
                  <Grid item xs={6}>
                    <StyledLabel>Lot Number</StyledLabel>
                    <TextField
                      id='buyerName'
                      name='buyerName'
                      size='small'
                      fullWidth
                      value={formik.values.buyerName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.buyerName && Boolean(formik.errors.buyerName)}
                      helperText={formik.touched.buyerName && formik.errors.buyerName}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledLabel>Lot Size</StyledLabel>
                    <TextField
                      id='buyerPhone'
                      name='buyerPhone'
                      size='small'
                      fullWidth
                      value={formik.values.buyerPhone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.buyerPhone && Boolean(formik.errors.buyerPhone)}
                      helperText={formik.touched.buyerPhone && formik.errors.buyerPhone}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledLabel>Home sqft</StyledLabel>
                    <TextField
                      id='buyerEmail'
                      name='buyerEmail'
                      size='small'
                      fullWidth
                      value={formik.values.buyerEmail}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.buyerEmail && Boolean(formik.errors.buyerEmail)}
                      helperText={formik.touched.buyerEmail && formik.errors.buyerEmail}
                    />
                  </Grid>
                </>
              )}

              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <StyledLabel>Amenities</StyledLabel>
                <CustomDropdown />
              </Grid> */}
              {isSingleResidential && (
                <>
                  <Grid item xs={12}>
                    <StyledLabel>Lot Address</StyledLabel>
                    <StandaloneSearchBox
                      onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                      onUnmount={() => (searchInputRef.current = null)}
                      onPlacesChanged={() => handlePlacesChanged(formik.setValues)}
                    >
                      <TextField
                        id='address1'
                        name='address1'
                        size='small'
                        fullWidth
                        value={formik.values.address1}
                        onChange={(e) => {
                          const { value } = e.target;

                          if (!value) {
                            formik.setValues((prevState) => ({
                              ...prevState,
                              search: '',
                              address1: '',
                              country: '',
                              city: '',
                              state: '',
                              zipCode: '',
                              latitude: null,
                              longitude: null,
                            }));
                          }
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address1 && Boolean(formik.errors.address1)}
                        helperText={formik.touched.address1 && formik.errors.address1}
                      />
                    </StandaloneSearchBox>
                  </Grid>

                  <Grid item xs={6}>
                    <StyledLabel>City</StyledLabel>
                    <TextField
                      name='city'
                      id='city'
                      size='small'
                      fullWidth
                      onKeyDown={(e) => {
                        const blockedKeys = [
                          '+',
                          '-',
                          '.',
                          '/',
                          "'",
                          '[',
                          ']',
                          '{',
                          '}',
                          '\\',
                          ';',
                          ':',
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledLabel>State</StyledLabel>
                    <TextField
                      name='state'
                      id='state'
                      size='small'
                      fullWidth
                      onKeyDown={(e) => {
                        const blockedKeys = [
                          '+',
                          '-',
                          '.',
                          '/',
                          "'",
                          '[',
                          ']',
                          '{',
                          '}',
                          '\\',
                          ';',
                          ':',
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.state && Boolean(formik.errors.state)}
                      helperText={formik.touched.state && formik.errors.state}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledLabel>Country</StyledLabel>
                    <TextField
                      id='country'
                      name='country'
                      size='small'
                      fullWidth
                      onKeyDown={(e) => {
                        const blockedKeys = [
                          '+',
                          '-',
                          '.',
                          '/',
                          "'",
                          '[',
                          ']',
                          '{',
                          '}',
                          '\\',
                          ';',
                          ':',
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.country && Boolean(formik.errors.country)}
                      helperText={formik.touched.country && formik.errors.country}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <StyledLabel>Zip Code</StyledLabel>
                    <TextField
                      name='zipCode'
                      size='small'
                      id='zipCode'
                      type='number'
                      onKeyDown={(e) => {
                        const blockedKeys = [
                          'e',
                          'E',
                          '+',
                          '-',
                          '.',
                          '/',
                          "'",
                          '[',
                          ']',
                          '{',
                          '}',
                          '\\',
                          ';',
                          ':',
                        ];
                        if (blockedKeys.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      fullWidth
                      value={formik.values.zipCode}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (Number(newValue) >= 0) {
                          formik.setFieldValue('zipCode', newValue);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                      helperText={formik.touched.zipCode && formik.errors.zipCode}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {showPrevious ? (
              <Grid
                item
                xs={12}
                textAlign='center'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Button
                    variant='text'
                    color='inherit'
                    onClick={handleOpenTitleCompanyModal}
                    // startIcon={<ArrowBackIcon size='small' />}
                    disabled={formik.isSubmitting}
                    sx={{ textTransform: 'none' }}
                  >
                    Previous
                  </Button>
                </Box>
                <StyledBoxFooter>
                  <ModalCancel
                    className='cancelButton'
                    variant='outlined'
                    onClick={handleClose}
                    style={{ boxShadow: 'none' }}
                    sx={{ textTransform: 'none' }}
                  >
                    Cancel
                  </ModalCancel>
                  <ModalSave
                    color='primary'
                    size='large'
                    variant='contained'
                    type='submit'
                    sx={{ textTransform: 'none', marginLeft: 2 }}
                  >
                    Save
                  </ModalSave>
                </StyledBoxFooter>
              </Grid>
            ) :
            <StyledBoxFooter>
                  <ModalCancel
                    className='cancelButton'
                    variant='outlined'
                    onClick={handleClose}
                    style={{ boxShadow: 'none' }}
                    sx={{ textTransform: 'none' }}
                  >
                    Cancel
                  </ModalCancel>
                  <ModalSave
                    color='primary'
                    size='large'
                    variant='contained'
                    type='submit'
                    sx={{ textTransform: 'none', marginLeft: 2 }}
                  >
                    Save
                  </ModalSave>
                </StyledBoxFooter>
                }
          </form>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default SingleFamilyAddEdit;
