import React, { useState } from 'react';
import {
  Box,
  styled,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { handleAxiosError } from 'app/utils/helpers';
import { Formik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useProject } from 'app/contexts/ProjectContext';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '90px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const validateCompleteDate = (date) => {
  if (date && dayjs(date).isValid()) {
    const day = dayjs(date).date();
    const month = dayjs(date).month();
    const year = dayjs(date).year();
    return day && month && year;
  }
  return false;
};

const validationSchema = Yup.object().shape({
  effectiveDate: Yup.date()
    .typeError('Effective date is required!')
    .test('complete-date', 'Effective date is required!', validateCompleteDate),
  feasibilityEndDate: Yup.date()
    .typeError('Feasibility end date is required!')
    .test('complete-date', 'Feasibility end date is required!', validateCompleteDate),
  estimatedClosingDate: Yup.date()
    .typeError('Estimated closing date is required!')
    .test('complete-date', 'Estimated closing date is required!', validateCompleteDate),
  earnestMoney: Yup.number().required('Earnest money is required'),
  optionsMoney: Yup.number().required('Options money is required'),
});

const LeadsContractDetails = () => {
  const { isOnHoldOrCancelled, contract, updateContract } = useProject();
  const [open, setOpen] = useState(false);

  const initialValues = {
    ...contract,
    effectiveDate: dayjs(contract.effectiveDate),
    feasibilityEndDate: dayjs(contract.feasibilityEndDate),
    estimatedClosingDate: dayjs(contract.estimatedClosingDate),
  };

  const infoPairs = [
    {
      key: 'EFFECTIVE DATE',
      value: initialValues.effectiveDate,
    },
    { key: 'FEASIBILITY DUE DATE', value: initialValues.feasibilityEndDate },
    { key: 'CLOSING DATE', value: initialValues.estimatedClosingDate },
    { key: 'EARNEST MONEY', value: initialValues.earnestMoney },
    { key: 'OPTIONS MONEY', value: initialValues.optionsMoney },
  ];

  const handleEditClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateTomorrow = (date) => {
    if (date) return date.add(1, 'day');
  };

  const handleChangeWithValidation = (e, handleChange) => {
    const { value } = e.target;
    if (value >= 0) {
      handleChange(e);
      return;
    }
    return;
  };

  const handleFormikSubmit = async (values) => {
    const formattedDates = {
      effectiveDate: values.effectiveDate.toISOString(),
      feasibilityEndDate: values.feasibilityEndDate.toISOString(),
      estimatedClosingDate: values.estimatedClosingDate.toISOString(),
    };

    try {
      await updateContract({ ...values, ...formattedDates });
      handleClose();
    } catch (error) {
      handleAxiosError(error);
    }
  };

  return (
    <div className='viewlead'>
      <SubtitleDivider />
      <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title>Contract Details :</Title>
          <Button
            style={{ color: '#4F90F0', fontSize: '14px' }}
            onClick={handleEditClick}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} />
            Edit
          </Button>
        </Box>
        <InfoContainer item lg={6} md={6} sm={12} xs={12}>
          {infoPairs.map(({ key, value }, index) => {
            let output;
            switch (true) {
              case value instanceof dayjs:
                output = value.format('DD.MM.YYYY');
                break;
              case !!value:
                output = `$${value.toFixed(2)}`;
                break;
              default:
                output = '$0.00';
                break;
            }
            return (
              <Box key={index}>
                <Key>{key}</Key>
                <Value className='basicvalues'>{output}</Value>
              </Box>
            );
          })}
        </InfoContainer>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          onSubmit={handleFormikSubmit}
        >
          {({
            values,
            isSubmitting,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>Update Contract Details</DialogTitle>
              <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Effective Date'
                    name='effectiveDate'
                    value={values.effectiveDate}
                    onChange={(value) => {
                      setValues((prevValues) => ({
                        ...prevValues,
                        effectiveDate: value,
                        feasibilityEndDate: calculateTomorrow(value),
                        estimatedClosingDate: calculateTomorrow(calculateTomorrow(value)),
                      }));
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        margin: 'normal',
                        onBlur: handleBlur,
                        helperText: touched.effectiveDate && errors.effectiveDate,
                        error: Boolean(errors.effectiveDate && touched.effectiveDate),
                      },
                    }}
                    disabled={isSubmitting}
                  />
                  <DatePicker
                    label='Feasibility Due Date'
                    name='feasibilityEndDate'
                    minDate={calculateTomorrow(values.effectiveDate)}
                    value={values.feasibilityEndDate}
                    onChange={(value) => {
                      setValues((prevValues) => ({
                        ...prevValues,
                        feasibilityEndDate: value,
                        estimatedClosingDate: calculateTomorrow(value),
                      }));
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        margin: 'normal',
                        onBlur: handleBlur,
                        helperText: touched.feasibilityEndDate && errors.feasibilityEndDate,
                        error: Boolean(errors.feasibilityEndDate && touched.feasibilityEndDate),
                      },
                    }}
                    disabled={isSubmitting}
                  />
                  <DatePicker
                    label='Closing Date'
                    name='estimatedClosingDate'
                    minDate={calculateTomorrow(values.feasibilityEndDate)}
                    value={values.estimatedClosingDate}
                    onChange={(value) => setFieldValue('estimatedClosingDate', value)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        margin: 'normal',
                        onBlur: handleBlur,
                        helperText: touched.estimatedClosingDate && errors.estimatedClosingDate,
                        error: Boolean(errors.estimatedClosingDate && touched.estimatedClosingDate),
                      },
                    }}
                    disabled={isSubmitting}
                  />
                </LocalizationProvider>
                <TextField
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  type='number'
                  label='Earnest money'
                  name='earnestMoney'
                  value={values.earnestMoney}
                  inputProps={{ min: 0 }}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  placeholder='e.g. $30000.00'
                  helperText={touched.earnestMoney && errors.earnestMoney}
                  error={Boolean(errors.earnestMoney && touched.earnestMoney)}
                  disabled={isSubmitting}
                />
                <TextField
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  type='number'
                  label='Options money'
                  name='optionsMoney'
                  value={values.optionsMoney}
                  inputProps={{ min: 0 }}
                  onChange={(e) => handleChangeWithValidation(e, handleChange)}
                  onBlur={handleBlur}
                  placeholder='e.g. $30000.00'
                  helperText={touched.optionsMoney && errors.optionsMoney}
                  error={Boolean(errors.optionsMoney && touched.optionsMoney)}
                  disabled={isSubmitting}
                />
              </DialogContent>
              <DialogActions>
                <LoadingButton onClick={handleClose} variant='outlined' disabled={isSubmitting}>
                  Cancel
                </LoadingButton>
                <LoadingButton
                  type='submit'
                  color='primary'
                  variant='outlined'
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default LeadsContractDetails;
