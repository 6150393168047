import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './app/App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastContainer.css';
import routes from './app/routes';

const router = createBrowserRouter([
  {
    element: <App />,
    children: routes,
  },
]);

const root = createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <RouterProvider router={router} />
    <CssBaseline />
    <ToastContainer autoClose={5000} hideProgressBar closeOnClick pauseOnHover draggable />
  </StyledEngineProvider>,
);
