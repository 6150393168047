import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  styled,
  Table,
  Modal,
  Typography,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import Slider from '@mui/material/Slider';
import { LoadingButton } from '@mui/lab';
import { FilterList } from '@mui/icons-material';
import { CustomTextInput, Paragraph, Title } from 'app/common/Typography';

function valuetext(value, number) {
  return `${value}°C`;
}

const marks = [
  {
    value: 0,
    label: '0 acres',
  },
  {
    value: 100,
    label: '2500 acres',
  },
];

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  borderRadius: '4px',
  marginTop: '10px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const LeadsFiltericon = ({ leads }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [highlightedText, setHighlightedText] = useState(null);

  const handleTextClick = (text) => {
    setHighlightedText(text);
  };

  return (
    <>
      <FilterList
        variant='contained'
        style={{
          boxShadow: 'none',
          marginLeft: '95%',
          color: 'var(--filter-List)',
          cursor: 'pointer',
          marginTop: '5px',
          border: '1px solid gray',
          borderRadius: '5px',
          backgroundColor: 'var(--filter-bg)',
          padding: '5px', // Increase padding
          height: '30px',
          width: '30px',
        }}
        onClick={handleOpenModal}
      ></FilterList>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '32%',
            height: '81%',
          }}
        >
          <Box
            sx={{
              p: 2,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Filters</Title>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: 'var(--closeIcon)',
                }}
              />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box
            sx={{
              p: 1,
              marginLeft: '15px',
            }}
          >
            <Container>
              <Typography>
                <h3>Property Type</h3>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div
                      onClick={() => handleTextClick('Text 1')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: highlightedText === 'Text 1' ? 'var(--commercial-land-noText)' : 'var(--commercial-land-Text)',
                        borderRadius: '5%',
                        width: 'fit-content',
                        padding: '5px',
                        display: 'inline-block',
                      }}
                    >
                      <Typography
                        style={{ color: highlightedText === 'Text 1' ? 'var(--commercial-land-Text)' : 'var(--commercial-land-noText)' }}
                      >
                        Commercial Land
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      onClick={() => handleTextClick('Text 2')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: highlightedText === 'Text 2' ? 'var(--commercial-land-noText)' : 'var(--commercial-land-Text)',
                        borderRadius: '5%',
                        width: 'fit-content',
                        padding: '5px',
                        display: 'inline-block',
                      }}
                    >
                      <Typography
                        style={{ color: highlightedText === 'Text 2' ? 'var(--commercial-land-Text)' : 'var(--commercial-land-noText)' }}
                      >
                        Open Plot
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      onClick={() => handleTextClick('Text 3')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: highlightedText === 'Text 3' ? 'var(--commercial-land-noText)' : 'var(--commercial-land-Text)',
                        borderRadius: '5%',
                        width: 'fit-content',
                        padding: '5px',
                        display: 'inline-block',
                      }}
                    >
                      <Typography
                        style={{ color: highlightedText === 'Text 3' ? 'var(--commercial-land-Text)' : 'var(--commercial-land-noText)' }}
                      >
                        Apartments
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Typography>
              <Typography>
                <h3>Sub Type</h3>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <div
                      onClick={() => handleTextClick('Text 4')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: highlightedText === 'Text 4' ? 'var(--commercial-land-noText)' : 'var(--commercial-land-Text)',
                        borderRadius: '5%',
                        width: 'fit-content',
                        padding: '5px',
                        display: 'inline-block',
                      }}
                    >
                      <Typography
                        style={{ color: highlightedText === 'Text 4' ? 'var(--commercial-land-Text)' : 'var(--commercial-land-noText)' }}
                      >
                        Sub Type1
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      onClick={() => handleTextClick('Text 5')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: highlightedText === 'Text 5' ? 'var(--commercial-land-noText)' : 'var(--commercial-land-Text)',
                        borderRadius: '5%',
                        width: 'fit-content',
                        padding: '5px',
                        display: 'inline-block',
                      }}
                    >
                      <Typography
                        style={{ color: highlightedText === 'Text 5' ? 'var(--commercial-land-Text)' : 'var(--commercial-land-noText)' }}
                      >
                        Sub Type2{' '}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      onClick={() => handleTextClick('Text 6')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: highlightedText === 'Text 6' ? 'var(--commercial-land-noText)' : 'var(--commercial-land-Text)',
                        borderRadius: '5%',
                        width: 'fit-content',
                        padding: '5px',
                        display: 'inline-block',
                      }}
                    >
                      <Typography
                        style={{ color: highlightedText === 'Text 6' ? 'var(--commercial-land-Text)' : 'var(--commercial-land-noText)' }}
                      >
                        Sub Type3
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div
                      onClick={() => handleTextClick('Text 7')}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: highlightedText === 'Text 7' ? 'var(--commercial-land-noText)' : 'var(--commercial-land-Text)',
                        borderRadius: '5%',
                        width: 'fit-content',
                        padding: '5px',
                        display: 'inline-block',
                      }}
                    >
                      <Typography
                        style={{ color: highlightedText === 'Text 7' ? 'var(--commercial-land-Text)' : 'var(--commercial-land-noText)' }}
                      >
                        Sub Type4
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Typography>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <h3>Estimated Price</h3>
                <TextField
                  type='number'
                  size='small'
                  name='text'
                  placeholder='Min amount'
                  validators={['required']}
                  errorMessages={['This field is required']}
                />
                <TextField
                  type='number'
                  size='small'
                  name='text'
                  placeholder='Max amount'
                  style={{ marginLeft: '10px' }}
                  validators={['required']}
                  errorMessages={['This field is required']}
                />
              </Grid>
              <Box sx={{ width: 350 }}>
                <h3>Size Range</h3>
                <Slider
                  getAriaLabel={() => 'Temperature range'}
                  // orientation="vertical"
                  getAriaValueText={valuetext}
                  defaultValue={[20, 37]}
                  valueLabelDisplay='auto'
                  marks={marks}
                />
              </Box>
            </Container>
            <ModalDivider />
            <Box
              sx={{
                justifyContent: 'space-between',
                marginLeft: '220px',
                pt: 2,
              }}
            >
              <LoadingButton
                className='cancelButton'
                color='error'
                variant='outlined'
                onClick={handleCloseModal}
                sx={{ textTransform: 'none' }}
                style={{ boxShadow: 'none' }}
              >
                Clear All
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                // onClick={}
                sx={{ textTransform: 'none' }}
                style={{ boxShadow: 'none', marginLeft: '15px' }}
              >
                Apply
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LeadsFiltericon;
