import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field } from 'formik';
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  ListItemText,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  Modal,
} from '@mui/material';
import { selectAllOptions } from 'app/utils/constant';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useUserAuth } from 'app/hooks/userUserAuth';
import {
  AddLinkButton,
  LinkContainer,
  StyledBox3,
  StyledLabel,
} from '../../listings/Modals/StyledComponents';
import { Footer, ModalContent, StyledTextField } from 'app/common/Typography';
import { LinksAddIcon, StyledcrossIcon } from 'app/common/icons';

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});
const CloseIconButton = styled(IconButton)(({ theme }) => ({
  // marginLeft: theme.spacing(1),
}));

function AmenityAddEdit({ projectId, selectedRole, closeModal }) {
  const { services } = useUserAuth();
  const isEditingMode = !!selectedRole;

  const [links, setLinks] = useState([{ subtype: '', type: 'Select Amenity Type', price: '' }]);

  const handleAddLink = () => {
    setLinks([...links, '']);
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
  };

  const handleChangeLink = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };

  const selectAllOption = [selectAllOptions.services.name, selectAllOptions.services];

  const isAllServicesSelected = selectedRole?.services?.length === services.length;

  const initialServices = isAllServicesSelected
    ? [selectAllOption, ...selectedRole?.services]
    : selectedRole?.services || [];

  const initialValues = {
    searchTerm: '',
    name: selectedRole?.name || '',
    services: initialServices,
    permissions: selectedRole?.permissions || {},
    amenityType: 'Standard',
    price: '',
  };

  const handleSubmit = (values, { setSubmitting }) => {
    // handleSubmit logic here
  };

  const servicesOptions = [selectAllOption, ...services];

  return (
    <Modal open={true} onClose={closeModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '0%',
          transform: 'translate(0%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '60%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <ModalContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <DialogTitle>{`${isEditingMode ? 'Edit' : 'Add'} Amenities`}</DialogTitle>
            <IconButton onClick={closeModal} sx={{ marginRight: 2 }}>
              <Close />
            </IconButton>
          </Box>
          <DialogDivider />
          <DialogContent>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas.role()}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
                setValues,
              }) => {
                return (
                  <Form>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs={12}>
                        <InputLabel
                          htmlFor='name'
                          sx={{
                            fontWeight: 700,
                            fontSize: 14,
                            mb: 1,
                            color: '#272937',
                          }}
                        >
                          Amenity name
                        </InputLabel>
                        <TextField
                          fullWidth
                          id='name'
                          name='name'
                          placeholder='e.g. Flooring'
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                          disabled={isSubmitting}
                          InputProps={{
                            readOnly: isEditingMode,
                          }}
                        />
                      </Grid>
                      <StyledBox3>
                        <StyledLabel>Sub Type</StyledLabel>
                        {links.map((link, index) => (
                          <LinkContainer
                            key={index}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              gap: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <StyledTextField
                              name='subtype'
                              placeholder='e.g. : Flooring - marbles'
                              onChange={(e) => handleChangeLink(index, 'subtype', e.target.value)}
                              value={link.subtype}
                              variant='outlined'
                              sx={{ flex: 1 }}
                            />

                            <Select
                              value={link.type}
                              onChange={(e) => handleChangeLink(index, 'type', e.target.value)}
                              variant='outlined'
                              placeholder='Type'
                            >
                              <MenuItem value='Select Amenity Type'>Select Amenity Type</MenuItem>
                              <MenuItem value='Standard'>Standard</MenuItem>
                              <MenuItem value='Upgradable'>Upgradable</MenuItem>
                            </Select>

                            {link.type === 'Upgradable' && (
                              <StyledTextField
                                name='price'
                                placeholder='e.g. 10000'
                                onChange={(e) => handleChangeLink(index, 'price', e.target.value)}
                                value={link.price}
                                variant='outlined'
                                sx={{ flex: 1 }} // Allows the field to take available space
                              />
                            )}

                            <CloseIconButton
                              onClick={() => handleRemoveLink(index)}
                              sx={{ flexShrink: 0 }}
                            >
                              <StyledcrossIcon />
                            </CloseIconButton>
                          </LinkContainer>
                        ))}
                        <AddLinkButton startIcon={<LinksAddIcon />} onClick={handleAddLink}>
                          Add Sub Type
                        </AddLinkButton>
                      </StyledBox3>
                    </Grid>
                    <Footer>
                      <LoadingButton
                        className='cancelButton'
                        color='error'
                        variant='outlined'
                        onClick={closeModal}
                        style={{ boxShadow: 'none' }}
                        disabled={isSubmitting}
                        sx={{ textTransform: 'none' }}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        color='primary'
                        size='large'
                        variant='contained'
                        type='submit'
                        loading={isSubmitting}
                        sx={{ textTransform: 'none', marginLeft: 2 }}
                      >
                        Save
                      </LoadingButton>
                    </Footer>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </ModalContent>
      </Box>
    </Modal>
  );
}

export default AmenityAddEdit;
