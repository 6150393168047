import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import { convertDateArrayToFormattedString, convertUTCDateToLocal } from 'app/utils/helpers';
import { handleDocDownload } from 'app/utils/constant';
import { Downloadicon } from 'app/common/icons';
const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Effective Date</TableCell>
      <TableCell>Feasibility Due Date</TableCell>
      <TableCell>Estimated Closing date</TableCell>
      <TableCell>Earnest Money</TableCell>
      <TableCell>Options money</TableCell>
      <TableCell>Contract Document</TableCell>
    </TableRow>
  </TableHead>
);

function ContractDetailsTable({ contract, document }) {
  const initialValues = {
    ...contract,
    effectiveDate: contract?.effectiveDate
      ? convertDateArrayToFormattedString(contract?.effectiveDate)
      : null,
    feasibilityEndDate: contract?.feasibilityEndDate
      ? convertDateArrayToFormattedString(contract?.feasibilityEndDate)
      : null,
    estimatedClosingDate: contract?.estimatedClosingDate
      ? convertDateArrayToFormattedString(contract?.estimatedClosingDate)
      : null,
  };
  return (
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {contract && (
            <>
              <StyledTableRow>
                <TableCell>
                  <div
                    style={{
                      fontWeight: 100,
                      fontSize: '14px',
                      align: 'left',
                      width: '60px',
                    }}
                  >
                    <span>
                      {initialValues?.effectiveDate
                        ? convertUTCDateToLocal(new Date(initialValues?.effectiveDate))
                        : 'N/A'}
                    </span>
                  </div>
                </TableCell>
                <TableCell>
                  {initialValues?.feasibilityEndDate
                    ? convertUTCDateToLocal(new Date(initialValues?.feasibilityEndDate))
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  {initialValues?.estimatedClosingDate
                    ? convertUTCDateToLocal(new Date(initialValues?.estimatedClosingDate))
                    : '/N/A'}
                </TableCell>
                <TableCell>{initialValues.earnestMoney}</TableCell>
                <TableCell>{initialValues.optionsMoney}</TableCell>
                <TableCell>
                  {document ? (
                    <Box display='flex' alignItems='center' justifyContent='left'>
                      {document?.metadata?.documentName}
                      <IconButton onClick={() =>
                        handleDocDownload(
                            document.content,
                            `${document.metadata.documentName}`,
                            document.metadata.documentType,
                            )
                        }>
                        <Downloadicon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Typography fontSize={14}>N/A</Typography>
                  )}
                </TableCell>
              </StyledTableRow>
            </>
          )}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

export default ContractDetailsTable;
