import React, { useEffect } from 'react';
import { Button, Grid, IconButton, Input, Paper, Typography, TextField } from '@mui/material';
import { CustomLabel, H1, Paragraph } from 'app/common/Typography';
import { Formik, Field, Form } from 'formik';
import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import './styles.css'; // Import the CSS file
import { LinksAddIcon } from 'app/common/icons';
import { AddLinkButton } from '../listings/Modals/StyledComponents';
import { documentTypes } from 'app/utils/constant';

const Document = ({ formData, setFormData, setDisable }) => {
  useEffect(() => {
    const isAnyDocnameEmpty = formData.documents.some(
      (doc) => doc.docName.trim() === '' || doc.file === null,
    );
    setDisable(isAnyDocnameEmpty);
  }, [formData.documents, setDisable]);

  const handleDeleteFile = (index) => {
    const updatedDocuments = [...formData.documents];
    updatedDocuments[index] = {
      ...updatedDocuments[index],
      file: null,
    };
    setFormData({
      ...formData,
      documents: updatedDocuments,
    });

    const fileInput = document.getElementById(`file-input-${index}`);
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleDeleteDocument = (index) => {
    const updatedDocuments = [...formData.documents];
    updatedDocuments.splice(index, 1);
    setFormData({
      ...formData,
      documents: updatedDocuments,
    });
  };

  const handleChanged = (event, index) => {
    const { name, value, files } = event.target;

    const updatedDocuments = [...formData.documents];
    if (name === `documents[${index}].docName`) {
      updatedDocuments[index] = {
        ...updatedDocuments[index],
        docName: value,
      };
    } else {
      updatedDocuments[index] = {
        ...updatedDocuments[index],
        [name]: name === 'file' ? files[0] : value,
        type: documentTypes.DUE_DILIGENCE, 
      };
    }

    setFormData({
      ...formData,
      documents: updatedDocuments,
    });
  };

  const handleAddDocument = () => {
    setFormData((prevState) => ({
      ...prevState,
      documents: [
        ...prevState.documents,
        { docName: '', file: null, type: documentTypes.DUE_DILIGENCE },
      ],
    }));
  };
  

  return (
    <div className='lead'>
      <Formik initialValues={formData}>
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            {formData.documents.map((document, index) => (
              <Grid container spacing={3} key={index}>
                <Grid item lg={6} md={5} sm={12} xs={12} sx={{ mt: 2 }}>
                  <CustomLabel>Document Name</CustomLabel>
                  <TextField
                    // as={TextField}
                    fullWidth
                    sx={{ mb: 4 }}
                    type='text'
                    size='small'
                    placeholder={`e.g. Document ${index + 1}`}
                    name={`documents[${index}].docName`}
                    value={document?.docName}
                    onChange={(e) => {
                      handleChange(e);
                      handleChanged(e, index);
                    }}
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={12} xs={12} sx={{ mt: 2 }}>
                  <CustomLabel>File</CustomLabel>
                  {document?.file && (
                    <Paper className='StyledPaper'>
                      <Typography variant='body1' sx={{ flex: 1, ml: 2 }}>
                        {document?.file?.name}
                      </Typography>
                      <IconButton onClick={() => handleDeleteFile(index)}>
                        <Icon
                          icon='material-symbols-light:delete-rounded'
                          fontSize='22'
                          color='var(--deleteIcon)'
                        />
                      </IconButton>
                    </Paper>
                  )}

                  <Input
                    id={`file-input-${index}`}
                    type='file'
                    sx={{
                      display: 'none',
                      '& + label': {
                        display: document.file ? 'none' : 'block',
                        border: document.file ? 'none' : `1px solid lightgrey`,
                        borderRadius: '5px',
                        padding: document.file ? '0' : '8px',
                        cursor: 'pointer',
                      },
                    }}
                    size='small'
                    name='file'
                    onChange={(e) => handleChanged(e, index)}
                    inputProps={{ accept: '.pdf, .doc, .docx' }}
                    validators={['required']}
                    errorMessages={['This field is required']}
                  />
                  <label htmlFor={`file-input-${index}`} className='file-input-label'>
                    {document.file ? '' : 'Choose File'}
                  </label>
                </Grid>
                <Grid item lg={1} md={2} sm={2} xs={2} sx={{ mt: 6 }}>
                  {formData.documents.length > 1 && (
                    <IconButton onClick={() => handleDeleteDocument(index)}>
                      <CloseIcon
                        style={{
                          color: 'red',
                          fontSize: 18,
                          border: '1px solid red',
                          borderRadius: '50%',
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}

            <AddLinkButton startIcon={formData.documents.some(
                (doc) => doc?.docName?.trim() === '' || doc?.file === null
              ) ? (
                <LinksAddIcon color='#A9A9A9' />
              ) : (
                <LinksAddIcon />
              )} onClick={handleAddDocument} disabled={formData.documents.some((doc) => doc?.docName?.trim() === '' || doc?.file === null)}>
              Document
            </AddLinkButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Document;
