import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import './ProfileTabs.css';
import OrganizationTab from './Organization/OrganizationTab';
import UserInfo from './UserDetails/UserInfo';

const tabRoutes = ['/profile', '/profile/organization', '/profile/referral'];

const ProfileTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;
  const initialTabIndex = tabRoutes.indexOf(currentPath);
  const [tabIndex, setTabIndex] = useState(initialTabIndex >= 0 ? initialTabIndex : 0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    navigate(tabRoutes[newValue]); 
  };

  useEffect(() => {
    const newIndex = tabRoutes.indexOf(location.pathname);
    if (newIndex !== tabIndex) {
      setTabIndex(newIndex);
    }
  }, [location.pathname, tabIndex, tabRoutes]);

  const referralData = [
    { id: 1, name: 'Referral 1', description: 'Description for referral 1' },
    { id: 2, name: 'Referral 2', description: 'Description for referral 2' },
  ];

  return (
    <Box sx={{ maxWidth: '99%', marginTop: '1rem' }}>
      <Box className="tabs-container">
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="left-aligned tabs"
          className="tab-items"
        >
          <Tab label="User Details" className="tab-item" />
          <Tab label="Organization" className="tab-item" />
          <Tab label="Referral" className="tab-item" />
        </Tabs>
        <Box sx={{ border: '1px solid #DADCE0' }} />
      </Box>

      {tabIndex === 0 && <UserInfo />}
      {tabIndex === 1 && (
        <Box className="tab-content" sx={{ maxHeight: '450px', overflowY: 'auto' }}>
        <OrganizationTab />
      </Box>
      
      )}
      {tabIndex === 2 && (
        <Box className="tab-content">
          <Typography variant="h6">Referrals</Typography>
          {referralData.length > 0 ? (
            referralData.map((referral) => (
              <Box key={referral.id} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">{referral.name}</Typography>
                <Typography variant="body2">{referral.description}</Typography>
              </Box>
            ))
          ) : (
            <Typography>No referrals available</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProfileTabs;