import { Box,styled,Typography,IconButton,TextField,List,ListItem,
    ListItemIcon,Button
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

//Common Modal stylings
export const StyledBox1 = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '49.95%',
    left: '82.5%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.text.white,
    borderRadius: '4px',
    border: '1px solid #D6DEE9',
    width: '35%',
    overflow: 'auto',
    height: '100vh',
    overflowX: 'hidden'
}));

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.75rem',
    fontWeight: '500',
    color: theme.palette.text.primary,
    textTransform: 'none',
    marginLeft: '4%',
}))

export const StyledCrossIcon = styled(IconButton)(({ theme }) => ({
    fontSize: '1.25rem',
    color: theme.palette.text.cancleIcon
}))

export const InactiveModalTitle = styled(Typography)(({ theme }) => ({
    fontWeight: '700',
    fontSize: '1.875rem',
}))

export const InactiveModalSubTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    marginTop: '7%',
    marginBottom: '7%'

}))

export const InactiveModalButtonBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
}))
export const InactiveModalCancleBtn = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.text.cancleButtonBG,
    color: theme.palette.text.cancleButton,
    border: 'none',
    borderRadius: '6px',
    width: '80px',
}))

export const InactiveModalInactiveBtn = styled(Button)(({ theme }) => ({
    backgroundColor: '#CE443C',
    color: theme.palette.text.white,
    border: 'none',
    borderRadius: '6px',
    width: '125px',
    "&:hover": {
        backgroundColor: "#CE443C",
    },
    textTransform: 'none'
}))

export const OrgStatusModal = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    backgroundColor: theme.palette.text.white,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.12)',
    padding: '24px',
    borderRadius: '16px'
}))

export const OrgDetailsBox1 = styled(Box)(({ theme }) => ({
    marginTop: '16px',
    marginLeft: '2%'
}))

export const OrgCardModalBox2 = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px'
}))

export const StyledBox2 = styled(Box)(({ theme }) => ({
    padding: '4px',
    paddingBottom: '0',
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: '5%'
}))

export const StyledBox3 = styled(Box)(({ theme }) => ({
    padding: '4px',
    paddingTop: '0',
    margin: '0 10px 0 10px',
    overflowY: 'auto',
    maxHeight: 'calc(100%-75px)'
}))

export const StyledDocumentBox = styled(Box)(({ theme }) => ({
    padding: '4px',
    paddingTop: '0',
    margin: '0 10px 0 10px'
}))

export const StyledBox4 = styled(Box)(({ theme }) => ({
    display: 'flex',
    float: 'right',
    gap: '8%',
    marginTop: '15%',
    marginRight: '3%',
    marginBottom: '3%'
}))

export const FileDropArea = styled(Box)(({ theme }) => ({
    border: `1px dashed #9AA0A6`,
    borderRadius: '3px',
    width: '98%',
    height: ' 6.3125rem',
    marginLeft: '2%',
    color: theme.palette.text.textfield,
    display: 'flex',
    textAlign: "center",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2rem'
}));

export const DragDropText=styled(Typography)(({theme})=>({
    cursor:'pointer',
    fontWeight:'500',
    fontSize:'0.875rem',
    color:'#282A2D'
}))

export const SupportedFilesText=styled(Typography)(({theme})=>({
    fontWeight:'500',
    fontSize:'0.75rem',
    color:'#282A2D',
    mmarginLeft:'4%',
    marginTop:'0.5rem',
    marginBottom:'2rem'
}))

export const UploadLink=styled(Typography)(({theme})=>({
    fontWeight:'500',
    fontSize:'0.875rem',
    color:'#282A2D',
    marginLeft:'4%',
    mrginTop:'2rem'
}))

export const StyledLabel = styled('label')(({ theme }) => ({
    display: 'block',
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    marginTop: '1rem',
}))

export const StyledLabelPropertyView = styled('label')(({ theme }) => ({
    display: 'block',
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    marginTop: '1rem',
    marginLeft: '2%'
}))

export const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        height: '44px',
    },
    width: '98%',
    color: theme.palette.text.textfield,
    marginLeft: '2%',
    borderRadius: '3px',
    border: '1px  #BDC1C6',
    '& .MuiInputLabel-root': {
        color: theme.palette.text.textfield,
    },
}));

export const UploadedList = styled(List)(({ theme }) => ({
    borderRadius: '3px',
    width: '96%',
    marginLeft: '2%',
}));

export const ModalButtonCancle = styled(LoadingButton)(({ theme }) => ({
    boxShadow: 'none',
    height: '30px',
    backgroundColor: theme.palette.text.cancleButtonBG,
    color: theme.palette.text.cancleButton,
    width: '88px',
    border: 'none',
    textTransform: 'none'
}))

export const ModalButtonSave = styled(LoadingButton)(({ theme }) => ({
    boxShadow: 'none',
    height: '30px',
    backgroundColor: theme.palette.text.buttonbg,
    color: theme.palette.text.white,
    textTransform: 'none',
    "&:hover": {
        backgroundColor: theme.palette.text.buttonbg,
    },
}))

export const FilesListSec=styled(ListItem)(({thyeme})=>({
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'1',
    marginBottom:'0.8125rem',
    backgroundColor:'#F9FBFC',
    borderRadius:'4px'
})) 

export const ItemIcon=styled(ListItemIcon)(({theme})=>({
    colro:'#4A90E2',
    fontSize:'1.75rem'
}))

export const ItemText=styled(Typography)(({theme})=>({
    fontSize:'1rem',
    color:'#2A3C4E',
    fontWeight:'600'
}));

export const PDSubHeadings = styled(Typography)(({ theme }) => ({
    fontWeight: '500',
    color: '#17181B',
    fontSize: '13px'
}));

export const PDSubValues = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    fontSize: '16px',
    color: '#282A2D'
}));

export const StyledEditIcon = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    color: theme.palette.text.lavendorblue,
    border: `1px solid ${theme.palette.text.lavendorblue}`,
    borderRadius: '6px',
    fontWeight: '500',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      width: '100%',
    },
    [theme.breakpoints.between('md')]: {
      fontSize: '0.9375rem',
      padding: '8px 16px',
      width: 'auto',
      border: '1px solid red'
    },
  }));
  
//ChangeOrgModal.jsx
export const StyledBox = styled(Box)(({ theme, isSelected }) => ({
    border: `2px solid ${isSelected ? theme.palette.primary.main : '#ddd'}`,
    borderRadius: '8px',
    marginBottom: '8px',
    padding: '16px',
    cursor: 'pointer',
    '&:hover': {
        borderColor: theme.palette.primary.light,
    },
}));

export const SectionContainer = styled(Box)({
    marginBottom: '16px',
});

export const SectionTitle = styled(Typography)({
    fontWeight: '600',
    fontSize: '14px',
    marginTop: '2rem',
    marginBottom: '1rem',
    color: '#5F6368'
});

//ImagesViewModal.jsx
export const StyledModalContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "65%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    outline: "none",
    padding: 3,
}));

export const DeleteIcon = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    border: '1px solid red',
    backgroundColor: '#fff',
    top: '9%',
    right: '6%',
}));

export const MainImageContainer = styled(Box)(() => ({
    position: "relative"
}));

export const MainImage = styled("img")(() => ({
    width: "100%",
    maxHeight: "500px",
    objectFit: "contain",
    borderRadius: "4px",
}));

export const ThumbnailsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    overflowX: "scroll",
    gap: theme.spacing(1),
    width: '90.5%',
    marginLeft: '4.5%',
    padding: 1,
    "&::-webkit-scrollbar": {
        height: "0.5rem",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#C8E1E8",
        borderRadius: "3.5px",
    },
    "&::-webkit-scrollbar-track": {
        background: '#E0F7FE',
        borderRadius: '4.5px'
    },
}));


export const ThumbnailImage = styled("img")(({ isSelected, theme }) => ({
    width: "100px",
    height: "70px",
    objectFit: "cover",
    borderRadius: "4px",
    cursor: "pointer",
    border: isSelected
        ? `3px solid ${theme.palette.primary.main}`
        : "3px solid transparent",
    transition: "border 0.2s ease-in-out",
}));

export const ImagesViewBox2 = styled(Box)(({ theme }) => ({
    padding: '4px',
    paddingBottom: '0',
    display: 'flex',
    float: 'right',
    marginTop: '5%'
}))

export const ImagesStyledCross = styled(IconButton)(({ theme }) => ({
    fontSize: '1.25rem',
    color: theme.palette.text.cancleIcon,
    float: 'right',
    zIndex: '3'
}))

//ReferenceLinksModal.jsx
export const LinkContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
}));

export const AddLinkButton = styled(Button)(({ theme }) => ({
    marginLeft:'2%',
    color:'#7375C7',
    fontSize:'14px',
    fontWeight:'500',
    textTransform:'none',
}));

//EditPropertyDetailsModal.jsx
export const StyledTextAreaField = styled("textarea")(({ theme }) => ({
    width: "98%",
    height: "150px", // Approximate height for 6 rows
    padding: "10px",
    fontSize: "14px",
    borderRadius: "3px",
    border: "1px solid #BDC1C6",
    color: theme.palette.text.textfield,
    backgroundColor: theme.palette.background.paper,
    marginLeft: "2%",
    resize: "none", // Disable resizing
    fontFamily: "Roboto, Arial, sans-serif", // Same font family as Material-UI
    "&:focus": {
        outline: "none",
        borderColor: theme.palette.primary.main,
    },
    "&::placeholder": {
        color: "#BDC1C6",
    },
}));