import React, { useState } from 'react';
import { 
  Box, Button, Dialog, DialogActions, DialogContent, 
  DialogTitle, TextField, styled 
} from '@mui/material';
import { useProject } from 'app/contexts/ProjectContext';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { formatCurrency, handleAxiosError } from 'app/utils/helpers';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const InfoBox = styled(Box)({
  flex: '1 1 calc(25% - 10px)', 
  marginBottom: '20px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: '#4F90F0',
});

const LeadsLenderDetails = () => {
  const { isOnHoldOrCancelled, closing, updateClosing } = useProject();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [successOpen, setSuccessOpen] = useState(false); 
  const [formData, setFormData] = useState({
    lenderName: closing?.lenderDetails?.lenderName || '',
    lenderContactNumber: closing?.lenderDetails?.lenderContactNumber || '',
    lenderEmail: closing?.lenderDetails?.lenderEmail || '',
    leaseTerm: closing?.lenderDetails?.leaseTerm || '',
    downPayment: closing?.lenderDetails?.downPayment || '',
    loanAmount: closing?.lenderDetails?.loanAmount || '',
    interestRate: closing?.lenderDetails?.interestRate || '',
  });
  
  const [tempFormData, setTempFormData] = useState({ ...formData });
  const [errors, setErrors] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTempFormData({ ...formData });
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
      setTempFormData((prevData) => ({
        ...prevData,
        [name]: name === 'downPayment' || name === 'loanAmount' || name === 'interestRate' ? parseFloat(value) || '' : value,
      }));
  };
  
  const handleSave = async () => {
    if (Object.values(errors).some((error) => error)) {
      return;
    }

    setLoading(true); 
    try {
      const updatedFormData = {
        companyDetails: closing.companyDetails,
        purchasePrice: closing.purchasePrice,
        closingCost: closing.closingCost,
        closingDate: closing.closingDate,
        id: closing.id,
        lenderDetails: tempFormData,
      };

      await updateClosing(updatedFormData); 
      setFormData(tempFormData); // Update formData only after successful save
      setSuccessOpen(true); 
      setOpen(false); 
    } catch (error) {
      handleAxiosError(error); 
    } finally {
      setLoading(false); 
    }
  };

  const infoPairs = [
    { key: 'LENDER NAME', value: formData.lenderName },
    { key: 'CONTACT NUMBER', value: formData.lenderContactNumber },
    { key: 'EMAIL ADDRESS', value: formData.lenderEmail },
    { key: 'LEASE TERM', value: formData.leaseTerm },
    { key: 'DOWN PAYMENT', value: formatCurrency(formData.downPayment) },
    { key: 'LOAN AMOUNT', value: formatCurrency(formData.loanAmount) },
    { key: 'INTEREST RATE', value: formData.interestRate },
  ];

  return (
    <div className='viewlead'>
      <SubtitleDivider />
      <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title>Lender Details :</Title>
          <Button
            onClick={handleOpen}
            style={{ color: '#4F90F0', fontSize: '14px' }}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
          </Button>
        </Box>
        <InfoContainer>
          {infoPairs.map(({ key, value }, index) => (
            <InfoBox key={index}>
              <Key>{key}</Key>
              <ValueContainer>
                <Value className='basicvalues'>{value ?? ''}</Value>
              </ValueContainer>
            </InfoBox>
          ))}
        </InfoContainer>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Edit Lender Details</DialogTitle>
        <DialogContent>
          {[
            { name: 'lenderName', label: 'Lender Name' },
            { name: 'lenderContactNumber', label: 'Contact Number' },
            { name: 'lenderEmail', label: 'Email Address' },
            { name: 'leaseTerm', label: 'Lease Term' },
            { name: 'downPayment', label: 'Down Payment' },
            { name: 'loanAmount', label: 'Loan Amount' },
            { name: 'interestRate', label: 'Interest Rate' },
          ].map((field) => (
            <TextField
              key={field.name}
              margin='dense'
              name={field.name}
              label={field.label}
              fullWidth
              value={tempFormData[field.name]}
              onChange={handleChange}
              error={!!errors[field.name]}
              helperText={errors[field.name] || ''}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='error'>
            Cancel
          </Button>
          <Button 
            onClick={handleSave} 
            color='primary' 
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeadsLenderDetails;
