import { InputAdornment } from '@mui/material';
import { Container, FilterButton, SearchInput } from '../../listings/StyledComponent';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

export default function MembersFilter({title}) {
  return (
    <Container>
      <SearchInput
        placeholder={`Search with ${title} Name or ${title} ID`}
        variant='outlined'
        size='small'
        sx={{ width: '30%' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon fontSize='small' style={{ color: '#6B7280' }} />
            </InputAdornment>
          ),
        }}
      />

      <FilterButton variant='outlined' size='small' startIcon={<TuneIcon fontSize='small' />}>
        Filters
      </FilterButton>
    </Container>
  );
}
