import { useMemo, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { Formik, Form, FieldArray } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { Loading } from 'app/components';
import { useProject } from 'app/contexts/ProjectContext';
import { useAllUsers } from './hooks/useAllUsers';
import { useProjectMembersAdd } from './hooks/useProjectMembersAdd';
import { useProjectMemberUpdateRole } from './hooks/useProjectMemberUpdateRole';
import RoleAddEdit from '../Roles/RoleAddEdit';
import { Button, Modal } from '@mui/material';
import {
  ModalCancel,
  ModalContent,
  ModalDivider,
  ModalSave,
  ModalStyledBox,
  ModalStyledSubBox,
  StyledBoxFooter,
  Title,
} from 'app/common/Typography';
import { AddLinkButton, StyledLabel } from '../../listings/Modals/StyledComponents';
import CloseIcon from '@mui/icons-material/Close';
import { LinksAddIcon } from 'app/common/icons';

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 500,
}));

const MembersAddEdit = ({ selectedMember, roles, projectMembers, closeModal, title }) => {
  const { projectId } = useProject();
  const { data: allUsers, isPending: areUsersLoading } = useAllUsers(selectedMember);
  console.log("allUsers", allUsers);
  const { mutate: addMembers } = useProjectMembersAdd(projectId);
  const { mutate: updateMemberRole } = useProjectMemberUpdateRole(projectId, selectedMember);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const isEditing = !!selectedMember;

  const initialValues = isEditing
    ? {
        members: [
          {
            name: selectedMember?.memberName,
            phone: selectedMember?.phone,
            email: selectedMember?.email,
            userRoles: selectedMember?.userRoles.map((role) => ({ name: role })),
          },
        ],
      }
    : {
        members: [{ name: '', phone: '', email: '', userRoles: [], userId: '' }],
      };

  const usersMap = useMemo(() => {
    const users = new Map(allUsers?.map((user) => [user.email, user]));

    projectMembers.forEach((member) => {
      users.delete(member.email);
    });

    return users;
  }, [allUsers, projectMembers]);

  const selectedUsersMap = useMemo(() => {
    const selectedUsers = new Map();

    projectMembers.forEach((member) => {
      selectedUsers.set(member.email, member);
    });

    return selectedUsers;
  }, [projectMembers]);

  const isUserExists = (member) => {
    const user = selectedUsersMap.get(member.email);

    if (user) {
      const existingFullName = `${user.name} ${user.surName}`.toLowerCase().trim();
      const inputFullName = member.name.toLowerCase().trim();
      const existingPhone = user.contactPhone;
      const inputPhone = member.phone;

      return (
        existingFullName === inputFullName &&
        existingPhone === inputPhone &&
        user.email === member.email
      );
    }
    return false;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const membersData = values.members.map((member) => ({
      ...member,
      userRoles: member.userRoles.map((role) => role.name),
    }));
    const mutationOptions = {
      onSuccess: closeModal,
      onSettled: () => setSubmitting(false),
    };

    !isEditing
      ? addMembers(membersData, mutationOptions)
      : updateMemberRole(membersData[0], mutationOptions);
  };

  const isPending = areUsersLoading;
  const isGeneralPartner = title === 'General Partner';
  return (
    <>
      <Modal open={true} onClose={closeModal}>
        <ModalStyledBox>
          <ModalContent>
            {isPending ? (
              <Loading />
            ) : (
              <>
                <ModalStyledSubBox>
                  <Title>{isEditing ? `Edit ${title}` : `Add ${title}`}</Title>
                  <IconButton onClick={closeModal}>
                    <CloseIcon
                      style={{
                        fontSize: 20,
                        color: 'var(--closeIcon)',
                      }}
                    />
                  </IconButton>
                </ModalStyledSubBox>
                <ModalDivider />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchemas.members(projectMembers, isEditing)}
                  validateOnMount={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    setFieldValue,
                  }) => {
                    function selectUserInMap(email, value) {
                      selectedUsersMap.set(email, value);
                      usersMap.delete(email);
                    }

                    function removeUserFromMap(email) {
                      const deletedMember = selectedUsersMap.get(email);
                      if (deletedMember) {
                        usersMap.set(email, deletedMember);
                        selectedUsersMap.delete(email);
                      }
                    }

                    function handleUserSelection(value, index) {
                      if (value) {
                        selectUserInMap(value.email, value);

                        setFieldValue(`members[${index}].name`, `${value.name} ${value.surName}`);
                        setFieldValue(`members[${index}].phone`, value.contactPhone);
                        setFieldValue(`members[${index}].email`, value.email);
                        setFieldValue(`members[${index}].userId`, value.cognitoUserId);
                      }
                    }

                    function handleUserRemoval(index) {
                      const member = values.members[index];
                      removeUserFromMap(member.email);

                      setFieldValue(`members[${index}].phone`, '');
                      setFieldValue(`members[${index}].email`, '');
                      setFieldValue(`members[${index}].userRoles`, []);
                      setFieldValue(`members[${index}].userId`, '');
                    }

                    function filterUserOptions(options, state) {
                      const inputValue = state.inputValue.trim().toLowerCase();
                      if (inputValue.length < 3) return [];
                      const inputParts = inputValue.split(/\s+/);
                      return options.filter((option) => {
                        const fullName = `${option.name} ${option.surName}`.toLowerCase();
                        return inputParts.every((part) => fullName.includes(part));
                      });
                    }

                    if (isGeneralPartner && values.members[0].userRoles.length === 0) {
                      setFieldValue('members[0].userRoles', [{ name: 'OWNER_ROLE' }]);
                    }
                    return (
                      <Form>
                        <DialogContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FieldArray name='members'>
                                {({ push, remove }) => (
                                  <>
                                    {values.members.map((member, index) => (
                                      <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                        <Grid item xs={12} sm={6}>
                                          <StyledLabel>{title} Name</StyledLabel>
                                          <Autocomplete
                                            freeSolo
                                            readOnly={isEditing}
                                            options={Array.from(usersMap.values())}
                                            getOptionLabel={(user) =>
                                              `${user.name} ${user.surName}`
                                            }
                                            noOptionsText={member.name?.length < 3 && ''}
                                            inputValue={member.name}
                                            filterOptions={filterUserOptions}
                                            renderOption={(props, user) => (
                                              <Box
                                                component='li'
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                {...props}
                                                key={user.email}
                                              >
                                                <Avatar
                                                  sx={{
                                                    bgcolor: 'var(--avatar-bgColor)',
                                                    width: 30,
                                                    height: 30,
                                                    marginRight: 2,
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  {`${user?.name?.[0]?.toUpperCase()}${user?.surName?.[0]?.toUpperCase()}`}
                                                </Avatar>
                                                <Box>
                                                  <Typography variant='subtitle2' noWrap>
                                                    {`${user?.name} ${user?.surName}`}
                                                  </Typography>
                                                  <Typography
                                                    variant='body2'
                                                    color='text.secondary'
                                                    noWrap
                                                  >
                                                    {user?.email}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            )}
                                            onChange={(_, value) =>
                                              handleUserSelection(value, index)
                                            }
                                            onInputChange={(_, value) => {
                                              setFieldValue(`members[${index}].name`, value);
                                              if (!value) {
                                                handleUserRemoval(index);
                                              }
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                name={`members[${index}].name`}
                                                onBlur={handleBlur}
                                                error={
                                                  touched.members?.[index]?.name &&
                                                  Boolean(errors.members?.[index]?.name)
                                                }
                                                helperText={
                                                  touched.members?.[index]?.name &&
                                                  errors.members?.[index]?.name
                                                }
                                                fullWidth
                                                InputProps={{
                                                  ...params.InputProps,
                                                  readOnly: isEditing,
                                                }}
                                              />
                                            )}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'flex-start',
                                            }}
                                          >
                                            <StyledLabel>Select Roles</StyledLabel>
                                            {values.members.length > 1 && (
                                              <IconButton
                                                onClick={() => {
                                                  handleUserRemoval(index);
                                                  remove(index);
                                                }}
                                                size='small'
                                                sx={{ padding: 0 }}
                                                aria-label='removeMember'
                                              >
                                                <Close style={{ color: 'var(--close-icon)' }} />
                                              </IconButton>
                                            )}
                                          </Box>
                                          <Autocomplete
                                            multiple
                                            options={[...(roles || []), { name: 'Add Role', id: 'add-role' }]} // Append "Add Role"
                                            filterSelectedOptions={true}
                                            getOptionLabel={(role) => role.name}
                                            isOptionEqualToValue={(option, value) =>
                                              option.name === value.name
                                            }
                                            onChange={(event, newValue) => {
                                              const lastItem = newValue[newValue.length - 1];
                                              if (lastItem?.id === 'add-role') {
                                                toggleDialog();
                                                return;
                                              }
                                              setFieldValue(`members[${index}].userRoles`, newValue);
                                            }}
                                            value={member.userRoles}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                size='medium'
                                                variant='outlined'
                                                name={`members[${index}].userRoles`}
                                                onBlur={handleBlur}
                                                error={
                                                  touched.members?.[index]?.userRoles &&
                                                  Boolean(errors.members?.[index]?.userRoles)
                                                }
                                                helperText={
                                                  touched.members?.[index]?.userRoles &&
                                                  errors.members?.[index]?.userRoles
                                                }
                                                fullWidth
                                              />
                                            )}
                                            renderTags={(tagValue, getTagProps) =>
                                              tagValue.map((option, index) => {
                                                const { key, ...rest } = getTagProps({ index });
                                                return (
                                                  <Chip key={key} label={option.name} {...rest} />
                                                );
                                              })
                                            }
                                            renderOption={(props, option) => (
                                              <li {...props} key={option.id || option.name}>
                                                {option.id === 'add-role' ? (
                                                  <AddLinkButton
                                                    startIcon={<LinksAddIcon />}
                                                    onClick={(e) => {
                                                      e.stopPropagation();  // Stop event propagation here
                                                      toggleDialog();
                                                    }}
                                                  >
                                                    Role
                                                  </AddLinkButton>
                                                ) : (
                                                  option.name
                                                )}
                                              </li>
                                            )}
                                            disabled={isGeneralPartner}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <StyledLabel>Contact Number</StyledLabel>
                                          <TextField
                                            fullWidth
                                            name={`members[${index}].phone`}
                                            value={member.phone}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={handleBlur}
                                            error={
                                              touched.members?.[index]?.phone &&
                                              Boolean(errors.members?.[index]?.phone)
                                            }
                                            helperText={
                                              touched.members?.[index]?.phone &&
                                              errors.members?.[index]?.phone
                                            }
                                            inputProps={{
                                              readOnly: isEditing || isUserExists(member),
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <StyledLabel>Email Address</StyledLabel>
                                          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <TextField
                                              fullWidth
                                              name={`members[${index}].email`}
                                              value={member.email}
                                              onChange={(e) => handleChange(e)}
                                              error={
                                                touched.members?.[index]?.email &&
                                                Boolean(errors.members?.[index]?.email)
                                              }
                                              onBlur={handleBlur}
                                              helperText={
                                                touched.members?.[index]?.email &&
                                                errors.members?.[index]?.email
                                              }
                                              inputProps={{
                                                readOnly: isEditing || isUserExists(member),
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    ))}
                                    {!isEditing && (
                                      <Grid item xs={12}>
                                        <AddLinkButton
                                          startIcon={
                                            isSubmitting || Boolean(errors?.members?.length) ? (
                                              <LinksAddIcon color='#A9A9A9' />
                                            ) : (
                                              <LinksAddIcon />
                                            )
                                          }
                                          onClick={() =>
                                            push({ name: '', phone: '', email: '', userRoles: [] })
                                          }
                                          disabled={
                                            isSubmitting || Boolean(errors?.members?.length)
                                          }
                                        >
                                          {title}
                                        </AddLinkButton>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </FieldArray>
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <StyledBoxFooter>
                          <ModalCancel
                            size='large'
                            className='cancelButton'
                            sx={{ textTransform: 'none' }}
                            disabled={isSubmitting}
                            onClick={closeModal}
                            variant='outlined'
                          >
                            Cancel
                          </ModalCancel>
                          <ModalSave
                            color='primary'
                            size='large'
                            sx={{ textTransform: 'none' }}
                            variant='contained'
                            type='submit'
                            loading={isSubmitting}
                          >
                            Save
                          </ModalSave>
                        </StyledBoxFooter>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
          </ModalContent>
        </ModalStyledBox>
      </Modal>
      {isOpenDialog && <RoleAddEdit projectId={projectId} closeModal={toggleDialog} />}
    </>
  );
};

export default MembersAddEdit;
