import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import NavigationHeader from 'app/common/navigationHeader';
import { requestStatusMessages, topBarHeight } from 'app/utils/constant';
import styles from './RequestsLayout.module.css';
import { useRequests } from 'app/contexts/RequestsContext';
import NoRequests from 'app/views/dashboard/Requests/NoRequests';
import RequestsList from 'app/views/dashboard/Requests/RequestsList';
import { useMemo } from 'react';

function RequestsLayout() {
  const { requests, respondToRequest } = useRequests();
  const currentType = 'PENDING';

  const filteredRequests = useMemo(
    () => requests.filter((request) => request.status === currentType.toUpperCase()),
    [requests, currentType],
  );

  return (
    <Box className={styles.container} style={{ height: `calc(100vh - ${topBarHeight}px)` }}>
      <NavigationHeader requests={true} requestsCount={filteredRequests.length} />
      <Card elevation={3} sx={{ m: 1, borderRadius: 1 }} className={styles.card}>
        <Box className={styles.content}>
          {filteredRequests.length > 0 ? (
            <RequestsList requests={filteredRequests} respondToRequest={respondToRequest} />
          ) : (
            <NoRequests type={currentType} message={requestStatusMessages[currentType].message} />
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default RequestsLayout;
