import { Box, IconButton, ImageList, ImageListItem, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StepperImages from './StepperImages';
import { useState } from 'react';
import useImagesContext from 'app/hooks/useImagesContext';
import styles from './ImageGalleryModal.module.css';

const ImageGalleryModal = ({ children }) => {
  const { imageElements, handleImageClick } = useImagesContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div onClick={handleOpen}>{children}</div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box className={styles.modal}>
          <Box className={styles.header}>
            <Typography variant='h6'>All images</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon
                style={{
                  fontSize: 18,
                  color: '#272937',
                }}
              />
            </IconButton>
          </Box>
          <Box className={styles.content} sx={{ backgroundColor: 'background.paper' }}>
            <StepperImages>
              <div className={styles.imageList} onClick={handleImageClick}>
                {imageElements.map((image) => (
                  <div className={styles.imageListItem} key={image.alt}>
                    {image}
                  </div>
                ))}
              </div>
            </StepperImages>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ImageGalleryModal;
