import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
import DocumentSection from './DocumentSection';
import GPSection from './GPSection';
import MembersSection from './MembersSection';
import ReferenceLinks from './ReferenceLinks';
import AuditLogsSection from './AuditLogsSection';
import PropertyInfoSection from './PropertyInfoSection';
import MapViewSection from './MapViewSection';
import OrganizationSection from './OrganizationSection';
import { AuditLogSecCard } from './StyledComponent';
import InvestmentsSection from '../LeadsData/Investments/InvestmentsSection';
import MyInvestmentsSection from '../LeadsData/Investments/MyInvestments';

const PropertyDetailsView = ({ lead }) => {
  return (
    <Box>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12} md={6}>
          <Paper sx={{ height: '536px', border: '1px solid #DADCE0' }}>
            <MapViewSection />
          </Paper>

          <Paper sx={{ marginTop: 2, border: '1px solid #DADCE0' }}>
            <OrganizationSection />
          </Paper>
          {!lead && (
            <Paper sx={{ height: '22.3125rem', marginTop: 2, border: '1px solid #DADCE0' }}>
              <InvestmentsSection />
            </Paper>
          )}

          <Paper sx={{ marginTop: 2, border: '1px solid #DADCE0' }}>
            <MembersSection title={'General Partner'} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ height: '536px', border: '1px solid #DADCE0' }}>
            <PropertyInfoSection />
          </Paper>

          {/* <Paper sx={{ marginTop: 2, border: '1px solid #DADCE0' }}>
            <ReferenceLinks />
          </Paper> */}
           
          <Paper sx={{ marginTop: 2, border: '1px solid #DADCE0' }}>
            <DocumentSection />
          </Paper>
          {!lead &&
            <Paper sx={{ height: "22.3125rem", marginTop: 2, border: '1px solid #DADCE0' }}>
              <MyInvestmentsSection />
            </Paper>
          }    
          <Paper sx={{  marginTop: 2, border: '1px solid #DADCE0' }}>
            <MembersSection title={"Members"}/>
          </Paper>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: '2.5rem' }}>
          <AuditLogSecCard>
            <AuditLogsSection />
          </AuditLogSecCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PropertyDetailsView;
