import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  MenuItem,
  ListItemIcon,
  styled,
  Divider,
  CircularProgress,
  TablePagination,
  ListItemText,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  StyledListItemText,
  OrgStatus,
  StyledInactiveIcon,
  StyledDeleteIcon,
  StyledListItemDeleteText,
  ImageContainer,
  StyledMenu,
  StyledTableBox,
  StyledBox2,
  ProjectHeader,
  TableHeaderText,
  TableBodyText,
  ProjTitle,
  StyledCard,
  ProjectImage,
  LoadingCircle,
  VerticalDivider,
  TableBody,
} from './StyledComponent';
import StatusChangeModal from 'app/common/StatusChangeModal';
import OnHoldDialog from '../LeadsData/Stages/OnHoldDialog';
import NewProjectDialog from '../LeadsData/Stages/NewProjectDialog';
import LeadsCancel from '../LeadsData/Stages/CancelDialog';
import { useProject } from 'app/contexts/ProjectContext';
import { GPTableMoveIcon, NoListingsImage, ViewIcon } from 'app/common/icons';
import GPSearchFilter from './GPSearchFilter';
import { useLocation } from 'react-router-dom';
import OnGoingConfirmationModal from '../LeadsData/Stages/OnGoingConfirmationDialog';
import LeadDeleteConfirmation from 'app/common/LeadsDeleteConfirmation';
import { projectDeleteLeadInformation } from 'app/contexts/projectActions';
import { handleAxiosError } from 'app/utils/helpers';

const GeneralPartnertTable = ({ listingData, onProjectClick, loading, fetchProjectsData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const open = Boolean(anchorEl);
  const [modalState, setModalState] = useState({
    open: false,
    onHold: false,
    cancel: false,
    newProject: false,
    onGoing: false,
    delete: false,
  });
  const [selectedProject, setSelectedProject] = useState(null);
  const handleMenuOpen = (event, project) => {
    setAnchorEl(event.currentTarget);
    console.log('project', project);
    setSelectedProject(project);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id) => {
    if (id) {
      try {
        await projectDeleteLeadInformation(id);
        fetchProjectsData();
        handleCloseModal();
      } catch (error) {
        handleAxiosError(error);
      }
    }
  };
  const handleOpenModal = () => setModalState({ ...modalState, open: true });
  const handleOpenDeleteModal = () => setModalState({ ...modalState, delete: true });
  const handleCloseModal = () =>
    setModalState({ open: false, onHold: false, cancel: false, newProject: false, delete: false });

  const handleProceed = (option) => {
    if (option === 'on_hold') {
      setModalState({ ...modalState, onHold: true });
    } else if (option === 'cancelled') {
      setModalState({ ...modalState, cancel: true });
    } else if (option === 'new_project') {
      setModalState({ ...modalState, newProject: true });
    } else if (option === 'on_going') {
      setModalState({ ...modalState, onGoing: true });
    }
    setModalState((prevState) => ({ ...prevState, open: false }));
  };
  let radioOptions = [];
  if (location.pathname === '/listing') {
    radioOptions = [
      { value: 'new_project', label: 'New Project' },
      { value: 'on_hold', label: 'On Hold' },
      { value: 'cancelled', label: 'Cancelled' },
    ];
  } else if (location.pathname === '/myprojects') {
    radioOptions = [
      { value: 'on_going', label: 'Ongoing Project' },
      { value: 'on_hold', label: 'On Hold' },
      { value: 'cancelled', label: 'Cancelled' },
    ];
  }

  const getStatusDetails = (status) => {
    switch (status) {
      case 'newproject':
        return { color: '#4caf50', text: 'New Project' };
      case 'ongoing':
        return { color: '#0277BD', text: 'Ongoing' };
      case 'cancelled':
        return { color: '#F44336', text: 'Cancelled' };
      case 'onhold':
        return { color: '#FF9800', text: 'On Hold' };
      default:
        return { color: '#9E9E9E', text: 'Unknown' };
    }
  };

  const formatCreatedDate = (dateArray) => {
    const [year, month, day, hours, minutes, seconds] = dateArray;
    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };

  const headers = [
    { label: 'Project', xs: 12, sm: 3.5, md: 4.7 },
    { label: 'Area', xs: 6, sm: 1.5, md: 1 },
    { label: 'Type & Sub Type', xs: 6, sm: 2, md: 1.7 },
    { label: 'Estimated Cost', xs: 6, sm: 2, md: 1.5 },
    { label: 'Status', xs: 6, sm: 1.5, md: 1 },
    { label: 'Created On', xs: 6, sm: 1.2, md: 1.2 },
    { label: '', xs: 6, sm: 0.5, md: 0.5 },
  ];

  const limitDescription = (description, wordLimit = 8) => {
    if (!description) {
      return '';
    }

    const words = description.split(' ');
    if (words.length <= wordLimit) {
      return description;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };

  return (
    <StyledTableBox>
      <StyledBox2>
        {loading ? (
          <LoadingCircle>
            <CircularProgress />
          </LoadingCircle>
        ) : listingData && listingData.records.length > 0 ? (
          <>
            <GPSearchFilter />
            <ProjectHeader>
              <Grid container spacing={2}>
                {headers.map((header, index) => (
                  <Grid item key={index} xs={header.xs} sm={header.sm} md={header.md}>
                    <TableHeaderText>{header.label}</TableHeaderText>
                  </Grid>
                ))}
              </Grid>
            </ProjectHeader>
            <TableBody>
              {listingData?.records?.map((project, index) => (
                <StyledCard key={index}>
                  <Grid container alignItems='center' textAlign='left'>
                    {/* Table body content */}
                    <Grid item xs={12} sm={3.6} md={4.6}>
                      <ImageContainer onClick={() => onProjectClick(project?.id)}>
                      <ProjectImage image={project?.projectImage} title={project?.projectTitle} />
                        <Box>
                          <ProjTitle>{project?.projectTitle}</ProjTitle>
                          {project?.projectType &&
                            getStatusDetails(project?.projectType).text !== 'Unknown' && (
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#fff',
                                  backgroundColor: getStatusDetails(project?.projectType).color,
                                  padding: '4px 8px',
                                  borderRadius: '4px',
                                  display: 'inline-block',
                                  marginBottom: '6px',
                                }}
                              >
                                {getStatusDetails(project?.projectType).text}
                              </Typography>
                            )}
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: '500', color: '#5F6368' }}
                            dangerouslySetInnerHTML={{
                              __html: limitDescription(project?.description),
                            }}
                          ></Typography>
                        </Box>
                      </ImageContainer>
                    </Grid>
                    <VerticalDivider sx={{ height: '10rem' }} orientation='vertical' flexItem />
                    <Grid item xs={6} sm={1.5} md={1}>
                      <TableBodyText align='center'>{project?.size} acres</TableBodyText>
                    </Grid>
                    <VerticalDivider sx={{ height: '10rem' }} orientation='vertical' flexItem />
                    <Grid item xs={6} sm={2} md={1.7}>
                      <TableBodyText align='center'>
                        {project?.propertyType}-{project?.subType}
                      </TableBodyText>
                    </Grid>
                    <VerticalDivider sx={{ height: '10rem' }} orientation='vertical' flexItem />
                    <Grid item xs={5} sm={1} md={1.5}>
                      <TableBodyText align='center' variant='body2'>
                        {project?.estimatedPrice}
                      </TableBodyText>
                    </Grid>
                    <VerticalDivider sx={{ height: '10rem' }} orientation='vertical' flexItem />
                    <Grid
                      item
                      xs={6}
                      sm={1.5}
                      md={1}
                      sx={{ display: 'flex', justifyContent: 'space-around' }}
                    >
                      <OrgStatus sx={{ backgroundColor: `${'Active' ? '#4caf50' : '#4caf50'}` }}>
                        {project?.status}
                      </OrgStatus>
                    </Grid>
                    <VerticalDivider sx={{ height: '10rem' }} orientation='vertical' flexItem />
                    <Grid
                      item
                      xs={6}
                      sm={1.5}
                      md={1.5}
                      sx={{ fontSize: '16px', fontWeight: '500' }}
                    >
                      <TableBodyText>
                        {project?.createdDate ? formatCreatedDate(project?.createdDate) : 'No Date'}
                      </TableBodyText>
                    </Grid>
                    <VerticalDivider sx={{ height: '10rem' }} orientation='vertical' flexItem />
                    <Grid
                      item
                      xs={12}
                      sm={0.5}
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <IconButton size='small' onClick={(event) => handleMenuOpen(event, project)}>
                        <MoreVertIcon />
                      </IconButton>

                      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                        <MenuItem onClick={() => onProjectClick(project?.id)}>
                          <ListItemIcon>
                            {/* <IconButton> */}
                            <ViewIcon fontSize='small' />
                            {/* </IconButton> */}
                          </ListItemIcon>
                          <ListItemText primary='View' />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleMenuClose();
                            handleOpenModal();
                          }}
                        >
                          <ListItemIcon>
                            <GPTableMoveIcon color='#7375C7' />
                          </ListItemIcon>
                          <StyledListItemText primary='Move to' color='#7375C7' />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleMenuClose();
                            handleOpenDeleteModal();
                          }}
                        >
                          <ListItemIcon>
                            <StyledDeleteIcon />
                          </ListItemIcon>
                          <StyledListItemDeleteText primary='Delete' />
                        </MenuItem>
                      </StyledMenu>
                    </Grid>
                  </Grid>
                  <StatusChangeModal
                    open={modalState.open}
                    onClose={handleCloseModal}
                    onProceed={handleProceed}
                    radioOptions={radioOptions}
                    proceedText='Proceed'
                  />
                  <OnHoldDialog
                    openModal={modalState.onHold}
                    projectTitle={selectedProject?.projectTitle}
                    projectId={selectedProject?.id}
                    previousPType={selectedProject?.previousProjectType}
                    handleCancel={() => setModalState({ ...modalState, onHold: false })}
                    fetchProjectsData={fetchProjectsData}
                  />
                  <LeadsCancel
                    openModal={modalState.cancel}
                    projectTitle={selectedProject?.projectTitle}
                    projectId={selectedProject?.id}
                    previousPType={selectedProject?.previousProjectType}
                    handleCancel={() => setModalState({ ...modalState, cancel: false })}
                    fetchProjectsData={fetchProjectsData}
                  />
                  {modalState.newProject && (
                    <NewProjectDialog
                      openModal={modalState.newProject}
                      projectId={selectedProject?.id}
                      previousPType={selectedProject?.previousProjectType}
                      closeModal={() => setModalState({ ...modalState, newProject: false })}
                    />
                  )}
                  {modalState.onGoing && (
                    <OnGoingConfirmationModal
                      openModal={modalState.onGoing}
                      handleCancel={() => setModalState({ ...modalState, onGoing: false })}
                      projectId={selectedProject?.id}
                      fetchProjectsData={fetchProjectsData}
                    />
                  )}
                  {modalState.delete && (
                    <LeadDeleteConfirmation
                      name={`${selectedProject?.projectTitle}`}
                      from={``}
                      open={modalState.delete}
                      onClose={() => setModalState({ ...modalState, delete: false })}
                      handleSubmit={() => handleDelete(selectedProject?.id)}
                    />
                  )}
                </StyledCard>
              ))}
            </TableBody>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'cenetr',
              textAlign: 'center',
              marginTop: '6%',
            }}
          >
            <div>
              <NoListingsImage />
            </div>
            <div style={{ fontWeight: '600', fontSize: '18px' }}>No Listings Added Yet</div>
          </Box>
        )}
      </StyledBox2>
    </StyledTableBox>
  );
};

export default GeneralPartnertTable;
