import { TableHead, TableRow, TableCell, TableBody, Icon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Span, StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import ConstructionLenderView from './ConstructionLenderView';
import React from 'react';
import { SideNavMenu } from 'app/components';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Lender Name</TableCell>
      <TableCell>Email Address</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function ConstructionLenderTable({ constructionLenders }) {
  const [selectedLenderIndex, setSelectedLenderIndex] = React.useState(null);

  const handleSelectLender = (index) => setSelectedLenderIndex(index);

  const selectedLender = constructionLenders && constructionLenders[selectedLenderIndex];
  return selectedLender ? (
    <ConstructionLenderView lender={selectedLender} handleBack={() => handleSelectLender(null)} />
  ) : (
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {constructionLenders &&
            constructionLenders.map((lender, index) => {
              return (
                <>
                  <StyledTableRow key={lender.id}>
                    <TableCell>{lender?.lenderName}</TableCell>
                    <TableCell>{lender?.lenderEmail}</TableCell>
                    <TableCell>{lender?.lenderPhone}</TableCell>
                    <TableCell align='left' sx={{ color: 'var(--tableBody-row-color)' }}>
                        <SideNavMenu
                          menuButton={
                            <UserMenu>
                              <MoreVertIcon />
                            </UserMenu>
                          }
                        >
                          <StyledItem onClick={() => handleSelectLender(index)}>
                            <Icon>
                              <VisibilityIcon />
                            </Icon>
                            <Span>View</Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                  </StyledTableRow>
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

export default ConstructionLenderTable;
