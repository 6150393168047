import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InvestNow from './InvestNow';
import { Title } from 'app/common/Typography';
import { formatCurrency, handleAxiosError } from 'app/utils/helpers';
import UserInvestmentsHistory from './UserInvestmentsHistory';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { userGetInvestmentDetails } from 'app/contexts/leadActions';
import { Loading } from 'app/components';
import useUserAuth from 'app/hooks/userUserAuth';

const SectionDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

function UserInvestments({ member }) {
  const { user } = useUserAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [memberInvestmentLatest, setMemberInvestmentLatest] = useState(null);

  const isCurrentUser = member.email === user.email;

  useEffect(() => {
    const getMemberInvestmentLatest = async () => {
      try {
        setIsLoading(true);
        const memberInvestmentsList = await userGetInvestmentDetails(
          member.projectId,
          member.userId,
        );
        setMemberInvestmentLatest(memberInvestmentsList.at(-1));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getMemberInvestmentLatest();
  }, [member.projectId, member.userId]);

  const infoPairs = [
    {
      key: 'INTERESTED AMOUNT',
      value: formatCurrency(memberInvestmentLatest?.interestedAmount),
    },
    {
      key: 'INTERESTED SHARES',
      value: memberInvestmentLatest?.interestedShares || 0,
    },
    {
      key: 'TOTAL INVESTED AMOUNT',
      value: formatCurrency(memberInvestmentLatest?.accumulatedAmount),
    },
    {
      key: 'TOTAL INVESTED SHARES',
      value: memberInvestmentLatest?.accumulatedShares || 0,
    },
    {
      key: 'REMAINING AMOUNT',
      value: formatCurrency(memberInvestmentLatest?.remainingAmount),
    },
  ];

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <SectionDivider />
      <Box display='flex' flexDirection='column' sx={{ mt: 0, px: 1 }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Title sx={{ my: 0 }}>{isCurrentUser ? 'My investments' : `Investments:`}</Title>
          <InvestNow memberInvestmentLatest={memberInvestmentLatest} />
        </Box>
        <Grid container columnSpacing={3} rowSpacing={2} my={0} justifyContent='flex-start'>
          {infoPairs.map(({ key, value }, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <span className='key'>{key}</span>
              <span className='value'>{value ?? ''}</span>
            </Grid>
          ))}
        </Grid>
        {memberInvestmentLatest?.paymentDetails?.length > 0 && (
          <UserInvestmentsHistory paymentDetails={memberInvestmentLatest?.paymentDetails} />
        )}
      </Box>
    </>
  );
}

export default UserInvestments;
