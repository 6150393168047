// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepperImages_modal__Oqn5g {
  width: 70%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24;
  gap: 1rem;
}

.StepperImages_header__RtDPL {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.StepperImages_content__MeW-a {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.StepperImages_stepperImage__z8-eF {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0 2rem;
}

.StepperImages_stepperImage__z8-eF img {
  max-width: 100%;
  max-height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/StepperImages.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,cAAc;EACd,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,wBAAgB;EAAhB,gBAAgB;EAChB,MAAM;EACN,UAAU;AACZ;;AAEA;EACE,SAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".modal {\r\n  width: 70%;\r\n  height: 90vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  box-shadow: 24;\r\n  gap: 1rem;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 16px;\r\n  background-color: #fff;\r\n  position: sticky;\r\n  top: 0;\r\n  z-index: 1;\r\n}\r\n\r\n.content {\r\n  flex: 1;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.stepperImage {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  overflow: hidden;\r\n  padding: 0 2rem;\r\n}\r\n\r\n.stepperImage img {\r\n  max-width: 100%;\r\n  max-height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `StepperImages_modal__Oqn5g`,
	"header": `StepperImages_header__RtDPL`,
	"content": `StepperImages_content__MeW-a`,
	"stepperImage": `StepperImages_stepperImage__z8-eF`
};
export default ___CSS_LOADER_EXPORT___;
