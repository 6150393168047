import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Title } from 'app/common/Typography';
import MembersExport from './MembersExport';
import AddEditMembers from './MembersAddEdit';
import { useProject } from 'app/contexts/ProjectContext';
import LeadMemberDeleteConfirmation from './MemberDeleteConfirmation';
import MemberView from './MemberView';
import MembersTable from './MembersTable';
import { useRef, useReducer, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const SET_MENU_AND_MODAL = 'SET_MENU_AND_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  anchorEl: null,
  modalState: {
    view: false,
    add: false,
    edit: false,
    export: false,
    delete: false,
  },
};

const menuActions = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
};

const rolesParam = 'roles';

export default function MembersSection() {
  const { basic, members, roles, isOnHoldOrCancelled } = useProject();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedMemberRef = useRef(null);
  const isOwnerMember = selectedMemberRef.current?.userRoles.some((role) => role === 'Owner');

  const openModal = (action) => {
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: {
        modalState: { [action]: true },
      },
    });
  };

  const closeModal = (action) => {
    selectedMemberRef.current = null;
    dispatch({ type: CLOSE_MODAL, payload: action });
  };

  const handleMenuOpen = useCallback((event, member) => {
    selectedMemberRef.current = member;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: event.currentTarget, modalState: {} },
    });
  }, []);

  const handleMenuClose = () => {
    selectedMemberRef.current = null;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: null, modalState: {} },
    });
  };

  const availableRoles = roles.map((role) => role.name);

  const membersFilters = useMemo(() => {
    const rolesURLString = searchParams.get(rolesParam);
    const rolesArray = rolesURLString ? rolesURLString.split(',') : [];
    const validRoles = rolesArray.filter((role) => availableRoles.includes(role));

    return validRoles;
  }, [searchParams, availableRoles]);

  const handleRoleChange = (event, newValues) => {
    setSearchParams((searchParams) => {
      newValues.length ? searchParams.set(rolesParam, newValues) : searchParams.delete(rolesParam);
      return searchParams;
    });
  };

  const filteredMembers = useMemo(
    () =>
      membersFilters.length
        ? members.filter((member) =>
            membersFilters.some((filter) => member.userRoles.includes(filter)),
          )
        : members,
    [members, membersFilters],
  );

  return (
    <>
      <SubtitleDivider />
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        spacing={2}
        sx={{ px: 3, py: 1 }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid
            container
            spacing={2}
            alignItems='center'
            sx={{ justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' } }}
          >
            <Grid item>
              <Title>Members</Title>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
              <Autocomplete
                multiple
                options={availableRoles}
                filterSelectedOptions={true}
                isOptionEqualToValue={(option, value) => option === value}
                value={membersFilters}
                onChange={handleRoleChange}
                renderInput={(params) => (
                  <TextField {...params} variant='outlined' label='Select filter' size='small' />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const { key, ...rest } = getTagProps({ index });
                    return <Chip key={key} label={option} {...rest} size='small' />;
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid
            container
            alignItems='center'
            spacing={2}
            justifyContent='flex-end'
            sx={{ justifyContent: { xs: 'center', sm: 'center', md: 'flex-end' } }}
          >
            <Grid item>
              <MembersExport projectTitle={basic.projectTitle} filteredMembers={filteredMembers} />
            </Grid>
            <Grid item>
              <LoadingButton
                color='primary'
                variant='contained'
                sx={{ boxShadow: 'none' }}
                onClick={() => openModal('add')}
                disabled={isOnHoldOrCancelled}
              >
                + Add Members
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MembersTable
        members={filteredMembers}
        isOnHoldOrCancelled={isOnHoldOrCancelled}
        handleMenuOpen={handleMenuOpen}
      />
      <Menu anchorEl={state.anchorEl} open={Boolean(state.anchorEl)} onClose={handleMenuClose}>
        {Object.values(menuActions).map((action) => (
          <MenuItem
            key={action}
            onClick={() => openModal(action)}
            disabled={isOwnerMember && action === menuActions.DELETE}
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </MenuItem>
        ))}
      </Menu>
      {state.modalState.view && (
        <MemberView member={selectedMemberRef.current} closeModal={() => closeModal('view')} />
      )}
      {(state.modalState.add || state.modalState.edit) && (
        <AddEditMembers
          selectedMember={selectedMemberRef.current}
          closeModal={() => closeModal(state.modalState.add ? 'add' : menuActions.EDIT)}
        />
      )}
      {state.modalState.delete && (
        <LeadMemberDeleteConfirmation
          member={selectedMemberRef.current}
          closeModal={() => closeModal(menuActions.DELETE)}
        />
      )}
    </>
  );
}
