import { styled } from '@mui/material/styles';
import {
  Box,
  Menu,
  Typography,
  Button,
  Tabs,
  Tab,
  ListItemText,
  ListItem,
  Link,
  Stack,
  Card,
  Avatar,
  IconButton,
  Grid,
  CardMedia,
  TextField,
  Paper,
  Chip,
  Divider,
} from '@mui/material';
import { AddIcon } from 'app/common/icons';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';

//Common Styles
export const IconDivider = styled(Box)(({ theme }) => ({
  border: '1px solid #DADCE0',
  width: '100%',
}));

export const StyleAddOrgButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.text.lavendorblue,
  color: theme.palette.text.white,
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: '500',
  cursor: 'pointer',
  padding: '8px 1rem 8px 1rem',
  '&:hover': {
    backgroundColor: theme.palette.text.lavendorblue,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledIcon = styled(AddIcon)(({ theme }) => ({
  color: theme.palette.text.white,
  fontSize: '1.25rem',
}));

export const StledCardTitle = styled(Typography)(({ theme }) => ({
  color: '#17181B',
  fontSize: '1.5rem',
  fontWeight: '600',
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginLeft: '1.625rem',
  '& .MuiTabs-indicator': {
    backgroundColor: '#82B485',
    height: '3px',
    borderRadius: '2px',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 500,
  color: '#80868B',
  '&.Mui-selected': {
    color: '#17181B',
    fontWeight: 700,
  },
}));

export const MoreText = styled(Typography)(({ theme }) => ({
  color: '#17181B',
  fontWeight: '600',
  fontSize: '1rem',
}));

export const ViewAllLink = styled(Link)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '600',
  color: '#645BBB',
  textDecoration: 'underLine',
  cursor: 'pointer',
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#90caf9',
  color: '#000',
  width: '5.625rem',
  height: '5.625rem',
  margin: '0 auto',
  fontWeight: 'bold'
}));

export const GPName = styled(Typography)(({ theme }) => ({
  marginTop: '0.4375rem',
  color: '#17181B',
  fontWeight: '600',
  fontSize: '1.125rem',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const GPRole = styled(Typography)(({ theme }) => ({
  color: '#5F5F64',
  fontSize: '0.875rem',
  fontWeight: '400',
}));
export const GPPhoneNumber = styled(Typography)(({ theme }) => ({
  color: '#282A2D',
  fontSize: '0.875rem',
  fontWeight: '500',
  marginTop: '0.75rem',
}));

export const GPEmailId = styled(Typography)(({ theme }) => ({
  color: '#7375C7',
  fontWeight: '500',
  fontSize: '0.875rem',
}));
export const StyledMenuIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '0.2rem',
  right: '0.4rem',
  color: '#17181B',
  zIndex: 1,
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  letterSpacing: '-1%',
  color: theme.palette.text.title,
  marginLeft: '2rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
    marginLeft: '1%',
  },
}));

export const OrgStatus = styled(Box)(({ theme }) => ({
  color: theme.palette.text.white,
  padding: '2px 8px',
  borderRadius: '4px',
  fontWeight: 'bold',
}));

export const StyledInactiveIcon = styled(VisibilityOffIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '20px',
  height: '20px',
}));

export const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.text.delete,
  width: '20px',
  height: '20px',
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: '#7375C7',
  },
}));

export const StyledListItemDeleteText = styled(ListItemText)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette.text.delete,
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
    borderRadius: '5px',
  },
}));

export const StyledEditIcon = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.text.lavendorblue,
  border: `1px solid ${theme.palette.text.lavendorblue}`,
  borderRadius: '6px',
  fontWeight: '500',
  fontSize: '1rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
    width: '100%',
  },
  [theme.breakpoints.between('md')]: {
    fontSize: '0.9375rem',
    padding: '8px 16px',
    width: 'auto',
    border: '1px solid red',
  },
}));

export const PDSubHeadings = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  color: '#17181B',
  fontSize: '14px',
}));

export const PDSubValues = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '18px',
  color: '#282A2D',
}));

export const StyledAddButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.text.lavendorblue,
  color: theme.palette.text.white,
  borderRadius: '8px',
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: '500',
  padding: '8px 1rem 8px 1rem',
  cursor: 'pointer',
  margin: '0',
  '&:hover': {
    backgroundColor: theme.palette.text.lavendorblue,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

//Heading Titles
export const HeadingTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '24px',
  marginBottom: '20px',
  marginLeft: '20px',
  marginTop: '20px',
}));

//DocumentSection.jsx
export const DocumetCardBody = styled(Box)(({ theme }) => ({
  marginTop: '2%',
  width: '90%',
  marginLeft: '5%',
  overflow: 'auto',
}));

export const StyledDocList = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '1',
  marginBottom: ' 0.8125rem',
  backgroundColor: '#F9FBFC',
  borderRadius: '4px',
}));

export const DocTextPrimary = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#2A3C4E',
  fontWeight: '600',
}));

export const DocTextSecondary = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: '#5F5F64',
  fontWeight: '400',
}));

export const DocListIconSec = styled(Box)(({ theme }) => ({
  border: '1px solid #DADCE0',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
}));

export const DocFooterSec = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  marginLeft: '3%',
  marginTop: '3%',
}));

//GPSection.jsx
export const GPCardBody = styled(Stack)(({ theme }) => ({
  disply: 'flex',
  flexDirection: 'row',
  gap: '1.4375rem',
  marginTop: '1rem',
  marginLeft: '2rem',
}));

export const GPBodyCard = styled(Card)(({ theme }) => ({
  width: '11.875rem',
  height: '17.375rem',
  position: 'relative',
}));

export const GPContactButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#B1DEF5',
  color: '#645BBB',
  fontSize: '0.875rem',
  fontWeight: '600',
  textTransform: 'none',
}));

//MapViewSection.jsx

export const MapSecTitle = styled(Typography)(({ theme }) => ({
  color: '#17181B',
  fontSize: '1.5rem',
  fontWeight: '600',
  margin: '1.375rem 0 1.375rem 1.375rem',
}));
export const MapFooterSec = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',
  textAlign: 'center',
}));

export const MapContainer = styled(Box)({
  position: 'relative',
  height: '23rem',
  borderRadius: '8px',
  overflow: 'hidden',
});

export const AddressContainer = styled(Box)({
  position: 'absolute',
  bottom: '2.1875rem',
  left: '1rem',
  color: '#ffffff',
  fontWeight: '500',
  fontSize: '1.125rem',
  lineHeight: '1.3125rem',
  width: '14.75rem',
  zIndex: 10,
});

export const ControlsContainer = styled(Box)({
  position: 'absolute',
  bottom: '2.1875rem',
  right: '1rem',
  display: 'flex',
  gap: '0.5rem',
});

export const FooterContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '16.5%',
});

export const FooterRightSec = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  width: '50%',
  justifyContent: 'space-between',
}));

export const FlyerText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '500',
  color: '#0E1013',
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}));

export const StyledReplaceIcon = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.text.lavendorblue,
  border: `1px solid ${theme.palette.text.lavendorblue}`,
  borderRadius: '6px',
  fontWeight: '500',
  fontSize: '1rem',
}));

export const RightFlexIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #DADCE0',
  borderRadius: '6px',
  overflow: 'hidden',
}));

export const StyledOverImageIcons = styled(Button)(({ theme }) => ({
  // backgroundColor: '#ffffff',
  // color: '#000000',
  cursor: 'pointer',
}));

//MembersSection.jsx
export const MembersCardBody = styled(Stack)(({ theme }) => ({
  disply: 'flex',
  flexDirection: 'row',
  gap: '1.4375rem',
  marginTop: '1rem',
  marginLeft: '2rem',
  marginBottom: '1rem',
}));

export const MembersBodyCard = styled(Card)(({ theme }) => ({
//   width: '11.875rem',
//   height: '14.9375rem',
  position: 'relative',
  backgroundColor: '#F8F9FA',
  border: '1px solid #DADCE0'
}));

//OrganizationSection.jsx

export const OrgSec = styled(Grid)(({ theme }) => ({
  alignItems: 'cenetr',
  justifyContent: 'space-between',
  marginBottom: '2.875rem',
  marginTop: '1.25rem',
}));

export const OrgHeaderSec = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: '1.375rem',
  marginRight: '1.375rem',
  marginBottom: '1rem',
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  marginLeft: '1.375rem',
  marginTop: '1.4375rem',
}));

export const OrgMainHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: '600',
  color: '#0E1013',
}));

export const OrgSubHeading = styled(Typography)(({ theme }) => ({
  fontSixe: '0.875rem',
  fontWeight: '500',
  color: '#17181B',
}));

export const OrgSubValue = styled(Typography)(({ theme }) => ({
  fontSize: '0.9375rem',
  fontWeight: '600',
  color: '#282A2D',
}));

//ReferenceLinks.jsx
export const ReferenceLinksBody = styled(Box)(({ theme }) => ({
  marginTop: '1.5625rem',
  width: '90%',
  marginLeft: '5%',
}));

export const ReferListItem = styled(Box)(({ theme }) => ({
  padding: '1',
  marginBottom: '0.8125rem',
  backgroundColor: '#F9FBFC',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ReferLinkText = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '16px',
  color: '#282A2D',
}));

export const ReferIconsSec = styled(Box)(({ theme }) => ({
  border: '1px solid #DADCE0',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
}));

//GeneralPartnerTable.jsx
export const VerticalDivider = styled(Divider)(({ theme }) => ({
  border: '1px solid #DADCE0',
}));

export const StyledTableBox = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
}));

export const StyledBox2 = styled(Box)(({ theme }) => ({
  // maxHeight: 'calc(100vh - 240px)',
  // overflow: 'auto',
}));

export const LicenceNum = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginBottom: '6px',
}));

export const ProjTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '600',
  marginBottom: '6px',
}));

export const ProjectHeader = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: '0.75rem',
  fontWeight: 'bold',
  position: 'sticky',
  boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
  border: '1px solid #D9D7DF',
  top: 0,
  zIndex: 1,
  marginBottom: '1rem',
}));

export const TableHeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '1rem',
  alignItems: 'center',
}));

export const TableBodyText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
}));

export const StyledCard = styled(Box)(({ theme }) => ({
  marginBottom: '0.5rem',
  boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
  border: '1px solid #D9D7DF',
  borderRadius: '5px',
  height: '10.25rem',
  backgroundColor: '#fff',
}));

export const TableBody = styled(Box)(({ theme }) => ({
  maxHeight: 'calc(100vh - 360px)',
  overflow: 'auto',
}));

export const ProjectImage = styled(CardMedia)({
  aspectRatio: '4/3',
  borderRadius: '4px',
  width: '40%',
  height: '50%',
  objectFit: 'cover',
  flexShrink: 0,
});
export const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1%',
  cursor: 'pointer',
  width: '100%',
  gap: '4%',
  maxWidth: '100%',
}));

export const LoadingCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '6.25rem',
  [theme.breakpoints.down('sm')]: {
    height: '5rem',
  },
}));

//PropertyInfoSection.jsx
export const PropertyBodySec = styled(Box)(({ theme }) => ({
  marginTop: '2.125rem',
  marginLeft: '1.25rem',
}));

export const SecHeading = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '1.25rem',
  color: '#17181B',
}));

export const SecValue = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '0.9rem',
  color: '#5F5F64',
  marginTop: ' 0.6875rem',
}));

export const PropertBodySec1 = styled(Box)(({ theme }) => ({
  marginTop: '1.875rem',
  marginLeft: '1.875rem',
}));

export const BodylastSecValue = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '1rem',
  color: '#5F5F64',
}));

export const BodylastSecKey = styled(Typography)(({ tyheme }) => ({
  fontWeight: '600',
  fontSize: '1rem',
  color: '#17181B',
  marginTop: '0.6875rem',
}));

//GPSearchFilter.jsx
export const Container = styled(Box)(({ theme }) => ({
  padding: '1px 16px',
  backgroundColor: '#EEEEF4',
  borderRadius: '10px',
  width: '100%',
  height: '4.375rem',
  marginBottom: '0.5rem',
}));

export const SearchInput = styled(TextField)(({ theme }) => ({
  flex: 1,
  marginTop: '17px',
  marginBottom: '17px',
  '& .MuiOutlinedInput-root': {
    height: '35px',
    borderRadius: '17.5px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    '& fieldset': {
      border: '1px solid #BDC1C6',
    },
  },
  '& input::placeholder': {
    fontSize: '14px',
    color: '#80868B',
  },
}));

export const FilterButton = styled(Button)(({ theme }) => ({
  height: '36px',
  borderRadius: '20px',
  textTransform: 'none',
  border: '1px solid #BDC1C6',
  color: '#111827',
  padding: '0 16px',
  backgroundColor: '#FFFFFF',
  fontSize: '13px',
  marginTop: '17px',
  marginLeft: '26px',
  '&:hover': {
    borderColor: '#D1D5DB',
    backgroundColor: '#F9FAFB',
  },
}));

//ListingLayout.jsx
export const LayoutStyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid #DADCE0`,
  fontWeight: '500',
  textTransform: 'none',
  '& .MuiTabs-indicator': {
    backgroundColor: '#82B485',
    height: '3px',
    width: '7.8125rem',
  },
  '& .Mui-selected': {
    fontWeight: '700',
  },
}));

export const LayoutStyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '0.875rem',
}));

export const StyledTabPanel = styled(Box)(({ theme }) => ({
  //   padding: theme.spacing(2),
}));

export const ListingItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

//ProjectDetailsTabs.jsx
export const MainHeader = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 58px)',
  overflowY: 'auto',
  backgroundColor: '#f4f4f4',
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  backgroundColor: '#fff',
  padding: theme.spacing(2),
}));

export const HeaderTabsBox = styled(Box)(({ theme }) => ({
  paddingLeft: '1rem',
  paddingRight: '1rem',
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

export const StatusChip = styled(Chip)(({ theme }) => ({
    fontWeight: "500",
    borderRadius: "5px",
    fontSize: '14px',
    padding: "5px",
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
}));

export const ProjectTabs = styled(Tabs)(({ theme }) => ({
  marginLeft: '1.625rem',
  marginTop: '1.2rem',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export const ProjectTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '0.875rem',
  fontWeight: 500,
  color: '#17181B',
  minHeight: '2rem',
  padding: '0.25rem 0.75rem',
  '&.Mui-selected': {
    borderRadius: '5px',
    color: '#FFFFFF !important',
    fontWeight: 700,
    fontSize: '0.875rem',
    backgroundColor: '#17181B',
    minHeight: '1.75rem',
    padding: '0.125rem 0.625rem',
  },
}));

//ProjectDetailsView.jsx
export const AuditLogSecCard = styled(Paper)(({ theme }) => ({
  // height: "50.25rem",
  overflow: 'auto',
  border: '1px solid #DADCE0',
  marginBottom: '2.5rem',
}));
