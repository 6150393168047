import CommentForm from './commentForm';
import React from 'react';
const Comment = ({
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  addComment,
  parentId = null,
  currentUserId,
}) => {
  const isEditing =
    activeComment && activeComment.id === comment.id && activeComment.type === 'editing';
  const isReplying =
    activeComment && activeComment.id === comment.id && activeComment.type === 'replying';
  const canDelete = currentUserId === comment.userId && replies.length === 0;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment.userId;
  const replyId = parentId ? parentId : comment.id;
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const createdAt = new Date(comment.createdAt).toLocaleDateString('en-US', options);
  return (
    <div key={comment.id} className='comment'>
      <div className='comment-container'></div>
      <div className='comment-right-part'>
        <div className='comment-content'>
          <div className='comment-author'>{'Name`'}</div>
          <div className='comment-date'>{createdAt}</div>
        </div>
        {!isEditing && <div className='comment-text'>{comment.content}</div>}
        {isEditing && (
          <CommentForm
            initialText={comment.content}
            handleSubmit={(text) => updateComment(text, comment.id)}
          />
        )}
        <div className='comment-actions'>
          {canReply && (
            <div
              className='comment-action'
              onClick={() => setActiveComment({ id: comment.id, type: 'replying' })}
            >
              Reply
            </div>
          )}
          {canEdit && (
            <div
              className='comment-action'
              onClick={() => setActiveComment({ id: comment.id, type: 'editing' })}
            >
              Edit
            </div>
          )}
          {canDelete && (
            <div className='comment-action-delete' onClick={() => deleteComment(comment.id)}>
              Delete
            </div>
          )}
        </div>
        {isReplying && <CommentForm handleSubmit={(text) => addComment(text, replyId)} />}
        {replies.length > 0 && (
          <div className='replies'>
            {replies.map((reply) => (
              <Comment
                comment={reply}
                key={reply.id}
                setActiveComment={setActiveComment}
                activeComment={activeComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                addComment={addComment}
                parentId={comment.id}
                replies={[]}
                currentUserId={currentUserId}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
