import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField, Modal } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form, Formik } from 'formik';
import { Footer, ModalContent, Title } from 'app/common/Typography';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectCLLoanWithdrawalAddEdit } from './hooks/useProjectCLLoanWithdrawalAddEdit';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '0.1em',
  backgroundColor: 'var(--modalDivider)',
});

const CLLoanWithdrawalAddEdit = ({ lender, withdrawal, closeDialog }) => {
  const { mutate: addEditCLLoanWithdrawal } = useProjectCLLoanWithdrawalAddEdit(lender, withdrawal);

  const isEditingMode = !!withdrawal;

  const initialValues = {
    balanceLoanAmount: lender?.loanBalance,
    withdrawalAmount: withdrawal?.withdrawalAmount || '',
    invoiceNumber: withdrawal?.invoiceNumber || '',
    paidDate: withdrawal?.paidDate || '',
    paidTo: withdrawal?.paidTo || '',
  };

  const handleSubmit = (values, { setSubmitting }) => {
    addEditCLLoanWithdrawal(values, {
      onSuccess: closeDialog,
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <Modal open={true} onClose={closeDialog}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '0%',
          transform: 'translate(0%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '30%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <ModalContent>
          <Box
            sx={{
              p: 2,
              pb: 0,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>{isEditingMode ? 'Edit' : 'Add'} Loan Withdrawal Details</Title>
            <IconButton onClick={closeDialog}>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: 'var(--closeIcon)',
                }}
              />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box
            sx={{
              p: 2,
              pt: 0,
              marginLeft: '4px',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchemas.clLoanWithdrawal()}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, handleChange, handleBlur }) => (
                <Form>
                  <Container>
                    <Grid container sx={{ marginTop: '0.1em' }} spacing={1.5}>
                      <Grid item xs={12}>
                        <InputLabel>Loan Available Amount</InputLabel>
                        <TextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          sx={{
                            fontWeight: 'bold',
                            backgroundColor: 'var(--TextField-bg-color)',
                          }}
                          name='balanceLoanAmount'
                          value={values.balanceLoanAmount}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Withdrawal Amount</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={
                            withdrawal?.balance === 0 && {
                              fontWeight: 'bold',
                              backgroundColor: 'var(--TextField-bg-color)',
                            }
                          }
                          placeholder='e.g. : 20000'
                          name='withdrawalAmount'
                          value={values.withdrawalAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.withdrawalAmount && errors.withdrawalAmount)}
                          helperText={touched.withdrawalAmount && errors.withdrawalAmount}
                          InputProps={{
                            readOnly: withdrawal?.balance === 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={1.5}>
                      <Grid item xs={12}>
                        <InputLabel>Invoice No</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          placeholder='e.g: INC1234'
                          name='invoiceNumber'
                          value={values.invoiceNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                          helperText={touched.invoiceNumber && errors.invoiceNumber}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <InputLabel>Paid Date</InputLabel>
                          <DatePicker
                            name='paidDate'
                            value={dayjs(values.paidDate)}
                            onChange={(value) =>
                              handleChange({
                                target: { name: 'paidDate', value },
                              })
                            }
                            onBlur={handleBlur}
                            slotProps={{
                              textField: {
                                size: 'small',
                                fullWidth: true,
                                variant: 'outlined',
                                error: Boolean(errors.paidDate && touched.paidDate),
                                helperText: touched.paidDate && errors.paidDate,
                              },
                            }}
                            disabled={isSubmitting}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={1.5}>
                      <Grid item xs={12}>
                        <InputLabel>Paid To</InputLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          placeholder='e.g: John'
                          name='paidTo'
                          value={values.paidTo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.paidTo && errors.paidTo)}
                          helperText={touched.paidTo && errors.paidTo}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </Container>

                  <Footer>
                    <LoadingButton
                      className='cancelButton'
                      color='error'
                      variant='outlined'
                      onClick={closeDialog}
                      style={{ boxShadow: 'none' }}
                      // disabled={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      type='submit'
                      loading={isSubmitting}
                      sx={{ textTransform: 'none', marginLeft: 2 }}
                    >
                      {isEditingMode ? 'Update' : 'Save'}
                    </LoadingButton>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Box>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default CLLoanWithdrawalAddEdit;
