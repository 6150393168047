import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Tooltip, Link } from '@mui/material';
// import AddUpdateExpensesDialog from './AddUpdateExpensesDialog';
import { convertDateArrayToFormattedString, convertUTCDateToLocal } from 'app/utils/helpers';
import { dateFormats } from 'app/utils/constant';
import InvestmentModal from './InvestmentAddEdit';
// import { useProjectExpenseInvoice } from './hooks/useProjectExpenseInvoice';

const expenseInvoice = [
    {
      updatedDate: '2023-03-01T12:00:00Z',
      totalAmount: 5000,
      paidAmount: 2000,
      balance: 3000,
      modeOfPayment: 'Bank Transfer',
      paidTo: 'Electricity Company',
      expenseType: 'Utility',
      status: 'Paid',
      comments: 'Paid for electricity usage in the last month, covering the period from January 1 to January 31.',
    },
    {
      updatedDate: '2023-02-15T14:00:00Z',
      totalAmount: 10000,
      paidAmount: 5000,
      balance: 5000,
      modeOfPayment: 'Cheque',
      paidTo: 'Landlord',
      expenseType: 'Rent',
      status: 'Pending',
      comments: 'Monthly rent for February. Payment was partially made with the remaining balance due.',
    },
    {
      updatedDate: '2023-01-28T11:30:00Z',
      totalAmount: 1500,
      paidAmount: 1500,
      balance: 0,
      modeOfPayment: 'Cash',
      paidTo: 'Maintenance Company',
      expenseType: 'Service',
      status: 'Paid',
      comments: 'Full payment made for maintenance services for the month of January.',
    },
    {
      updatedDate: '2023-03-10T09:45:00Z',
      totalAmount: 2000,
      paidAmount: 1000,
      balance: 1000,
      modeOfPayment: 'Credit Card',
      paidTo: 'Internet Provider',
      expenseType: 'Utility',
      status: 'Pending',
      comments: 'Internet bill for the month of February. Half of the payment was made, the rest is due.',
    },
  ];
    
const SaleInvestmetnInvoice = ({ selectedInvoice, expensesDocuments }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

//   const { data: expenseInvoice, isPending } = useProjectExpenseInvoice(
//     selectedExpense?.projectId,
//     selectedExpense?.invoiceNo,
//     selectedExpense?.id,
//   );

  const isInvoicePaid = selectedInvoice?.paymentDetails?.[0].status === 'Paid';

  if (!selectedInvoice) {
    return <Typography>No expense data available</Typography>;
  }

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '1%' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography>
            {selectedInvoice?.expensesName} (Invoice: {selectedInvoice?.invoiceNo})
          </Typography>
          <Link
            component='button'
            sx={{
              marginRight: '1%',
              color: isInvoicePaid ? 'var(--update-Balance-paid)' : 'var(--update-Balance-notPaid)',
              textDecoration: isInvoicePaid ? 'none' : 'underline',
              cursor: isInvoicePaid ? 'not-allowed' : 'pointer',
            }}
            onClick={toggleDialog}
            disabled={isInvoicePaid}
          >
            Update Balance
          </Link>
        </Box>

        {expenseInvoice.length > 0 ? (
          expenseInvoice.map((paymentDetail) => (
            <Paper
              elevation={3}
              sx={{
                p: 2,
                width: '100%',
                marginBottom: '1%',
              }}
              key={paymentDetail.updatedDate}
            >
              <Typography
                color='var(--updatedTime)'
                align='center'
                bgcolor='var(--updatedTime-bg)'
                fontWeight='500'
                width='155px'
                gutterBottom
              >
                {convertUTCDateToLocal(convertDateArrayToFormattedString(paymentDetail.updatedDate))}
              </Typography>
              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Total Amount
                  </Typography>
                  <Typography variant='h6'>${paymentDetail.totalAmount.toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Paid Amount
                  </Typography>
                  <Typography variant='h6' color='primary'>
                    ${paymentDetail?.paidAmount.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Balance Amount
                  </Typography>
                  <Typography variant='h6' color='var(--expense-balance)'>
                    ${(paymentDetail?.balance).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Payment Mode
                  </Typography>
                  <Typography variant='h6'>{paymentDetail?.modeOfPayment}</Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Paid To
                  </Typography>
                  <Typography variant='h6'>{paymentDetail.paidTo}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Status
                  </Typography>
                  <Typography variant='h6'>{paymentDetail?.status}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Comments
                  </Typography>
                  <Typography variant='body2'>
                    {paymentDetail.comments?.split(' ').length > 10 ? (
                      <>
                        {paymentDetail.comments.split(' ').slice(0, 10).join(' ')}&nbsp;
                        <Tooltip title={paymentDetail?.comments || ''} placement='top'>
                          <span
                            style={{
                              cursor: 'pointer',
                              color: 'var(--comments-toolTip)',
                              fontSize: '1.5em',
                            }}
                          >
                            ...
                          </span>
                        </Tooltip>
                      </>
                    ) : (
                      paymentDetail?.comments
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Typography>No payment details available</Typography>
        )}
      </Box>
      <InvestmentModal
        open={isOpenDialog}
        onClose={toggleDialog}
        isEdit={true}
        />
    </>
  );
};

export default SaleInvestmetnInvoice;
