class CSVExporter {
  constructor(fields) {
    this.fields = fields;
  }

  createCSVContent(data) {
    let csvContent = this.fields.join(',') + '\n';

    data.forEach((item) => {
      const row = this.fields.map((field) => {
        let value = item[field];
        if (Array.isArray(value)) {
          return `"${value.join(',')}"`;
        }
        return value;
      });
      csvContent += row.join(',') + '\n';
    });

    return csvContent;
  }

  formatFileName(fileName) {
    return fileName.replace(/\s+/g, '_').toLowerCase() + '.csv';
  }

  downloadCSV(csvContent, fileName) {
    const formattedFileName = this.formatFileName(fileName);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', formattedFileName);
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  createAndDownloadCSV(data, fileName) {
    const csvContent = this.createCSVContent(data);
    this.downloadCSV(csvContent, fileName);
  }
}

export default CSVExporter;
