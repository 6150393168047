import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Button } from '@mui/material';
import { handleAxiosError } from 'app/utils/helpers';
import LLCDialog from 'app/common/LLCDialog';
import { useProject } from 'app/contexts/ProjectContext';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '130px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: '#4F90F0',
});

const LeadsLLCinformation = () => {
  const { isOnHoldOrCancelled, llc, updateLLC } = useProject();
  const [open, setOpen] = useState(false);

  const initialValues = {
    ...llc,
  };
  // const handleOpen = () => setOpen(true);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = async (values) => {
    try {
      await updateLLC(values);
      handleClose();
    } catch (error) {
      handleAxiosError(error);
    }
  };

  const formatLLCAddress = () => {
    const { address1, address2, city, state, zipCode, country } = initialValues;
    const addressParts = [address1, address2, city, state, country, zipCode];
    return addressParts.filter((part) => part).join(' \n');
  };

  const address = formatLLCAddress();

  const infoPairs = [
    { key: 'LLC Name', value: initialValues.llcName },
    { key: 'EIN NUMBER', value: initialValues.einNum },
    { key: 'Address', value: address },
  ];

  return (
    <div className='viewlead'>
      <SubtitleDivider />
      <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title>LLC Details :</Title>
          <Button
            onClick={handleOpen}
            style={{ color: '#4F90F0', fontSize: '14px' }}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} />
            Edit
          </Button>
        </Box>
        <InfoContainer item lg={6} md={6} sm={12} xs={12}>
          {infoPairs.map(({ key, value }, index) => (
            <Box key={index}>
              <Key>{key}</Key>
              <ValueContainer>
                <Value className='basicvalues'>{value ?? ''}</Value>
              </ValueContainer>
            </Box>
          ))}
        </InfoContainer>
      </Box>
      <LLCDialog
        open={open}
        onClose={handleClose}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default LeadsLLCinformation;
