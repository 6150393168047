import { useQuery } from '@tanstack/react-query';
import { getProfileLLC } from 'app/contexts/userAuthActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProfileLLC(userId) {
  return useQuery({
    queryKey: [queryKeys.profileLLC, userId],  // Ensure you pass userId as part of queryKey for caching
    queryFn: () => getProfileLLC(userId),     // Fetch function
    placeholderData: [],                      // Initial data if available
    staleTime: Infinity,                      // Prevent re-fetching unless invalidated
    cacheTime: Infinity,                      // Prevent garbage collection
  });
}
