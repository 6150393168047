import React, { useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Container,
  Button,
  Modal,
  Icon,
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span } from 'app/common/Typography';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import GCSiteManagerDetailsModal from './GCSiteManagerDetailsModal';
import UpdateIcon from '@mui/icons-material/Update';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));



const GCSiteManagerDetails = ({gcSiteManager,gcSiteManagerIndex}) => {
  const [open, setOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);

  const handleClickOpen = () => {
    setSelectedManager(null);
    setOpen(true);
  };

  const handleClickUpdate = (manager) => {
    setSelectedManager(manager);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SubtitleDivider />
      {gcSiteManager[gcSiteManagerIndex]?.gcSiteManager?.length===0?(<>
        <Box>
            <Title sx={{ marginLeft: '2%' }}>GC Site Manager Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }} >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }} variant="body1" color="#272937" gutterBottom>
                  No details added
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It seems you have not added any details related to GC Site Manager Details.
                </Typography>
                <Button onClick={handleClickOpen} variant="contained" color="primary">
                  Add Details
                </Button>
              </Box>
            </Container>
            <Modal open={open} onClose={handleClose}>
              <GCSiteManagerDetailsModal SiteManagerDetail={selectedManager} handleCloseModal={handleClose} gcSiteManager={gcSiteManager} gcSiteManagerIndex={gcSiteManagerIndex} />
            </Modal>
          </Box>
      </>):(
        <>
        <Box
        marginLeft={3}
        marginRight={3}
        marginTop={1}
        justifyContent={'space-between'}
        display={'flex'}
        alignItems={'center'}
      >
        <Title sx={{ marginLeft: '0px' }}>GC Site Manager Details</Title>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Button style={{ color: '#4F90F0', fontSize: '14px' }} onClick={handleClickOpen}>
            + ADD
          </Button>
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <GCSiteManagerDetailsModal
        SiteManagerDetail={selectedManager}
         handleCloseModal={handleClose}
          gcSiteManager={gcSiteManager}
          gcSiteManagerIndex={gcSiteManagerIndex}
        />
      </Modal>
      <Box
        marginLeft={3}
        marginRight={3}
        marginTop={1}
        marginBottom={1}
        overflow='auto'
        border={'2px solid #E5E5E7'}
      >
        <ProductTable marginLeft={3} marginRight={3}>
          <TableHead bgcolor='#F2F9FC'>
            <TableRow color='#64748B'>
              <TableCell sx={{ px: 2, color: '#64748B' }}>S.NO.</TableCell>
              <TableCell sx={{ px: 0, color: '#64748B' }}>CONTACT PERSON</TableCell>
              <TableCell sx={{ px: 0, color: '#64748B' }}>EMAIL ADDRESS</TableCell>
              <TableCell sx={{ px: 0, color: '#64748B' }}>PHONE NUMBER</TableCell>
              <TableCell sx={{ px: 0, width: '5%', color: '#64748B' }}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {gcSiteManager[gcSiteManagerIndex]?.gcSiteManager?.map((GCSMdetail, index) => (
              <TableRow hover key={GCSMdetail.id}>
                <TableCell
                  align='left'
                  sx={{
                    px: 2,
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    fontSize: '14px',
                    color: '#272937',
                  }}
                >
                  {index + 1}
                </TableCell>

                <TableCell align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                  {GCSMdetail.contactPerson}
                </TableCell>

                <TableCell
                  sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                  align='left'
                >
                  {GCSMdetail.emailId}
                </TableCell>

                <TableCell
                  sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                  align='left'
                >
                  {GCSMdetail.phoneNo}
                </TableCell>
                <TableCell sx={{ color: '#272937' }} align='right'>
                  <SideNavMenu
                    menuButton={
                      <UserMenu>
                        <MoreVertIcon />
                      </UserMenu>
                    }
                  >
                    <StyledItem onClick={() => handleClickUpdate(GCSMdetail)}>
                      <Icon>
                        <UpdateIcon />
                      </Icon>
                      <Span>Update</Span>
                    </StyledItem>
                  </SideNavMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ProductTable>
      </Box>
        </>
      )}
    </>
  );
};

export default GCSiteManagerDetails;
