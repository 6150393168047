import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import '../styles.css';
import { Box, Card, Drawer, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Title } from './Typography';
import Close from '@mui/icons-material/Close';
import AuditLog from 'app/components/Activities/AuditLog';
import CommentsList from 'app/components/Activities/commentsList';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { useUserAuth } from 'app/hooks/userUserAuth';

const CardActivityHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const SubtitleActivityDivider = styled('div')(({ theme }) => ({
  height: '4px',
  backgroundColor: '#4F90F0',
}));

const projectsRoutes = ['/', '/newproject', '/ongoing', '/onhold', '/cancelled', '/completed','/listing'];

// REVIEW: consider to rebuild layout with only one navigationHeader call for the whole app
const NavigationHeader = ({
  project,
  lead,
  showNewLeadButton,
  showLead,
  customLeadName,
  showChangeStatus,
  showtoggle,
  handleAction,
  leadId,
  projectType,
  previousProjectType,
  requests,
  requestsCount,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { userNameJWT } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isLeadsPage = projectsRoutes.includes(location.pathname);
  const [state, setState] = React.useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [showComments, setShowComments] = useState(true);
  const [showActives, setShowActives] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCommentsClick = () => {
    setShowComments(true);
    setShowActives(false);
  };

  const handleActivesClick = () => {
    setShowActives(true);
    setShowComments(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const previousTypes = {
    lead: 'Lead',
    newproject: 'New Project',
    ongoing: 'On-Going',
  };

  const getMenuItems = () => {
    const menuItems = [];

    switch (projectType) {
      case 'lead':
        menuItems.push(
          { type: 'newproject', label: 'New Project' },
          { type: 'onhold', label: 'Hold' },
          { type: 'cancelled', label: 'Cancel' },
        );
        break;
      case 'newproject':
        menuItems.push(
          { type: 'ongoing', label: 'Ongoing Project' },
          { type: 'onhold', label: 'Hold' },
          { type: 'cancelled', label: 'Cancel' },
        );
        break;
      case 'ongoing':
        menuItems.push({ type: 'onhold', label: 'Hold' }, { type: 'cancelled', label: 'Cancel' });
        break;
      case 'onhold':
        menuItems.push(
          { type: 'restore', label: `Restore to ${previousTypes[previousProjectType]}` },
          { type: 'cancelled', label: 'Cancel' },
        );
        break;
      default:
        break;
    }

    return menuItems;
  };

  const list = (anchor) => (
    <Box sx={{ width: 330 }} role='presentation' open={openModal} onClose={handleCloseModal}>
      <div className='activityDetails'>
        <Card open={openModal} onClose={handleCloseModal} elevation={3}>
          <SubtitleActivityDivider />
          <CardActivityHeader>
            <Title>Activities</Title>
            <IconButton onClick={toggleDrawer(anchor, false)}>
              <Close
                className='closeButton'
              />
            </IconButton>
          </CardActivityHeader>

          <Box sx={{ padding: '24px' }}>
            <Box className='box' sx={{}}>
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant='subtitle1'
                  onClick={handleCommentsClick}
                  className={`comments-1 ${showComments ? 'active' : ''}`}
                >
                  Comments
                </Typography>
                <Typography
                  variant='subtitle1'
                  className={`comments-1 ${showActives ? 'active' : ''}`}
                  onClick={handleActivesClick}
                >
                  Audit Log
                </Typography>
              </Box>
              {showComments && (
                <Box sx={{ marginTop: 2 }}>
                  <CommentsList currentUserId={userNameJWT} projectId={leadId} />
                </Box>
              )}
              {showActives && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant='h5'>
                    <AuditLog />
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      </div>
    </Box>
  );

  const hasProject = !!project;
  const hasLead = !!lead;
  const hasCustomLeadName = !!customLeadName;

  return (
    <div
      className='navigation-headerName'
      style={{
        borderBottom: '1px solid lightgrey',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flex: '1 1 auto',
          minWidth: '0',
          marginBottom: isMobile ? theme.spacing(0) : '0',
          gap: theme.spacing(1),
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {requests && (
          <span
            style={{ color: 'var(--header-pathLinks)', fontWeight: '600', fontSize: isMobile ? '14px' : '16px' }}
          >
            Requests {requestsCount > 0 && `(${requestsCount})`}
          </span>
        )}
        {hasProject && (
          <>
            <span
              style={{
                color: hasLead || hasCustomLeadName ? 'var(--header-path-links)' : 'var(--header-pathLinks)',
                fontWeight: '600',
                fontSize: '16px',
              }}
            >
              {project}
            </span>
            {showLead && hasLead && (
              <>
                <span> {'>'}</span>
                <span
                  style={{
                    color: hasCustomLeadName ? 'var(--header-path-links)' : 'var(--header-pathLinks)',
                    fontWeight: '500',
                    fontSize: '16px',
                  }}
                >
                  <NavLink
                    to='/'
                    style={{
                      color: hasCustomLeadName ?'var(--header-path-links)':'var(--header-pathLinks)',
                      fontWeight: '500',
                      fontSize: isMobile ? '14px' : '16px',
                    }}
                  >
                    {lead}
                  </NavLink>
                </span>
              </>
            )}
          </>
        )}
        {hasCustomLeadName && (
          <>
            {hasProject && hasLead && <span> {'>'}</span>}
            <NavLink
              to='/newlead'
              style={{
                color: 'var( --header-pathLinks)',
                fontWeight: '500',
                marginLeft: '5px',
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
            >
              {customLeadName}
            </NavLink>
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: isMobile ? theme.spacing(1) : '10px',
          whiteSpace: 'nowrap',
        }}
      >
        {isLeadsPage && showNewLeadButton && (
          <LoadingButton
            color='primary'
            variant='contained'
            sx={{ textTransform: 'none' }}
            onClick={() => {
              navigate('../listing/newlead');
            }}
            style={{ boxShadow: 'none', fontSize: isMobile ? '12px' : '14px' }}
          >
            + New Lead
          </LoadingButton>
        )}

        {showChangeStatus && (
          <>
            <LoadingButton
              variant='outlined'
              style={{
                borderColor: 'var(--loadingbutton)',
                color: 'var(--loadingbutton)',
                boxShadow: 'none',
                fontWeight: '500',
                fontSize: isMobile ? '12px' : '14px',
                marginRight: '1px',
              }}
              sx={{ textTransform: 'none' }}
              onClick={handleClick}
            >
              Change Status
            </LoadingButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ width: '300px', marginTop: '3px' }}
            >
              {getMenuItems().map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleAction(item.type);
                    handleClose();
                  }}
                >
                  <Box display='flex' justifyContent='space-between' width='100%'>
                    <span>{item.label}</span>
                    <NavigateNextRoundedIcon />
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}

        {showtoggle && (
          <div>
            {['right'].map((anchor) => (
              <React.Fragment key={anchor}>
                <div
                  style={{
                    border: '2px solid #4F90F0',
                    borderRadius: '7%',
                    padding: '2px',
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <ListRoundedIcon
                    style={{
                      cursor: 'pointer',
                      color: 'var(--ListRoundedIcon)',
                      fontSize: isMobile ? '20px' : '24px',
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    {anchor}
                  </ListRoundedIcon>
                </div>
                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavigationHeader;
