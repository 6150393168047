import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Loading } from 'app/components';
import { useUserDetailsById } from './hooks/useUserDetailsById';
import {
  StyledBox1,
  ViewOrgDetailsBox,
  ViewOrgTitle,
} from 'app/views/dashboard/profile/StyleComponents';
import { StyledBox2 } from '../../listings/StyledComponent';
import { Box, Divider, Modal, styled } from '@mui/material';

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.text.border,
}));

function DetailSection({ title, details }) {
  let content = (
    <>
      <Typography variant='h6'>{title}</Typography>
      <StyledDivider />

      {details.map(({ key, value }) => {
        return (
          <>
            <Typography variant='subtitle1'>{key}</Typography>
            <Typography variant='subtitle1' fontWeight='bold'>
              {value}
            </Typography>
          </>
        );
      })}
    </>
  );

  if (title === 'Address') {
    content = (
      <>
        <Typography variant='h6'>{title}</Typography>
        <StyledDivider />
        <Typography variant='subtitle1' fontWeight='bold'>
          {details[0].value}
        </Typography>
      </>
    );
  }

  if (title === 'Personal Details') {
    content = (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
          <Avatar sx={{ width: 100, height: 100 }}>
            {details[0].value.split(' ')?.[0]?.[0].toUpperCase() || ''}
            {details[0].value.split(' ')?.[1]?.[0].toUpperCase() || ''}
          </Avatar>
          <Box>
            <Typography variant='h5' fontWeight='bold'>
              {details[0].value}
            </Typography>
            <Typography variant='subtitle1' color='text.secondary'>
              {details[1].value[0]}
            </Typography>
          </Box>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box>
            <Typography variant='subtitle1'>{details[2].key}</Typography>
            <Typography variant='subtitle1' fontWeight='bold'>
              {details[2].value}
            </Typography>
          </Box>
          <Box>
            <Typography variant='subtitle1'>{details[3].key}</Typography>
            <Typography variant='subtitle1' fontWeight='bold'>
              {details[3].value}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }
  return <ViewOrgDetailsBox>{content}</ViewOrgDetailsBox>;
}

function MemberView({ member, closeModal }) {
  const { data: memberDetails, isPending, isError } = useUserDetailsById(member.userId);

  if (isError) {
    closeModal();
  }

  let details = [
    {
      title: 'Personal Details',
      details: [
        { key: 'Name', value: member.memberName },
        { key: 'Roles', value: member.userRoles },
        { key: 'Date of Birth', value: '-' },
        { key: 'Social Security Number', value: '-' },
      ],
    },
    {
      title: 'Contact Details',
      details: [
        { key: 'Email', value: member.email },
        { key: 'Contact number', value: member.phone },
      ],
    },
    {
      title: 'Address',
      details: [
        {
          value: memberDetails?.address1,
        },
      ],
    },
    {
      title: 'Organization',
      details: [
        { key: 'EIN Number', value: '-' },
        { key: 'Address', value: '-' },
      ],
    },
  ];

  return (
    <Modal open={true} onClose={closeModal}>
      {isPending ? (
        <Loading />
      ) : (
        <StyledBox1>
          <StyledBox2>
            <ViewOrgTitle sx={{ mt: 1 }}>View Member</ViewOrgTitle>
          </StyledBox2>
          <Box display='flex' flexDirection='column' gap={2}>
            {details.map(({ title, details }) => (
              <DetailSection title={title} details={details} />
            ))}
          </Box>
        </StyledBox1>
      )}
    </Modal>
  );
}

export default MemberView;
