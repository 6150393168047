import React, { useState, useRef } from 'react';
import {
  Grid,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { handleAxiosError, parseAddressComponents } from 'app/utils/helpers';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useProject } from 'app/contexts/ProjectContext';
import { LoadingButton } from '@mui/lab';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const LeadsLeasing = () => {
  const { isOnHoldOrCancelled, leasesales, updateLeaseSales } = useProject();

  const [open, setOpen] = useState(false);
  const searchInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      tenantName: leasesales?.tenantName,
      contactPerson: leasesales?.contactPerson,
      email: leasesales?.email,
      phone: leasesales?.phone,
      address1: leasesales?.address1,
      address2: leasesales?.address2,
      country: leasesales?.country,
      state: leasesales?.state,
      city: leasesales?.city,
      zipCode: leasesales?.zipCode,
    },
    validationSchema: Yup.object({
      tenantName: Yup.string().required('Tenant Name is required'),
      contactPerson: Yup.string().required('Contact Person is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone: Yup.string().required('Phone Number is required'),
      address1: Yup.string().required('Address Line 1 is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      country: Yup.string().required('Country is required'),
      zipCode: Yup.string().required('ZIP Code is required'),
    }),
    onSubmit: async (values) => {
      try {
        await updateLeaseSales(values);
        handleClose();
      } catch (error) {
        handleAxiosError(error);
      }
    },
  });

  const handlePlacesChanged = () => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);
    formik.setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  const handleEditClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearAddress = () => {
    formik.setValues((prevState) => ({
      ...prevState,
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      latitude: null,
      longitude: null,
    }));
  };

  const infoPairs = [
    { key: 'TENANT NAME', value: formik.values.tenantName },
    { key: 'CONTACT PERSON', value: formik.values.contactPerson },
    { key: 'EMAIL ADDRESS', value: formik.values.email },
    { key: 'PHONE NUMBER', value: formik.values.phone },
    { key: 'ADDRESS', value: formik.values.address1 },
  ];

  return (
    <div className='viewlead'>
      <SubtitleDivider />
      <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Title>Tenant Details:</Title>
          <Button
            onClick={handleEditClick}
            style={{ color: '#4F90F0', fontSize: '14px' }}
            disabled={isOnHoldOrCancelled}
          >
            <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
          </Button>
        </Box>
        <InfoContainer item lg={6} md={6} sm={12} xs={12}>
          {infoPairs.map(({ key, value }, index) => (
            <Box key={index}>
              <Key>{key}</Key>
              <ValueContainer>
                <Value className='basicvalues'>{value ?? ''}</Value>
              </ValueContainer>
            </Box>
          ))}
        </InfoContainer>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Tenant Details</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              label='Tenant Name'
              name='tenantName'
              value={formik.values.tenantName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.tenantName && Boolean(formik.errors.tenantName)}
              helperText={formik.touched.tenantName && formik.errors.tenantName}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Contact Person'
              name='contactPerson'
              value={formik.values.contactPerson}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contactPerson && Boolean(formik.errors.contactPerson)}
              helperText={formik.touched.contactPerson && formik.errors.contactPerson}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Email address'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Phone Number'
              name='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              fullWidth
              margin='normal'
            />
            <StandaloneSearchBox
              onLoad={(searchBox) => (searchInputRef.current = searchBox)}
              onUnmount={() => (searchInputRef.current = null)}
              onPlacesChanged={handlePlacesChanged}
            >
              <TextField
                label='Address Line 1'
                placeholder='Search With Place, Landmark'
                type='search'
                name='address1'
                value={formik.values.address1}
                onChange={(e) => {
                  const { value } = e.target;
                  if (!value) {
                    handleClearAddress();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                helperText={formik.touched.address1 && formik.errors.address1}
                fullWidth
                margin='normal'
              />
            </StandaloneSearchBox>
            <TextField
              label='Address Line 2'
              name='address2'
              value={formik.values.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
              fullWidth
              margin='normal'
            />
            <TextField
              label='City'
              name='city'
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              fullWidth
              margin='normal'
            />
            <TextField
              label='State'
              name='state'
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Country'
              name='country'
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              fullWidth
              margin='normal'
            />
            <TextField
              label='ZIP Code'
              name='zipCode'
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              helperText={formik.touched.zipCode && formik.errors.zipCode}
              fullWidth
              margin='normal'
            />
            <DialogActions>
              <LoadingButton
                onClick={handleClose}
                color='error'
                variant='outlined'
                disabled={formik.isSubmitting}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                type='submit'
                color='primary'
                variant='outlined'
                loading={formik.isSubmitting}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LeadsLeasing;
