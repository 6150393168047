import React, { useState } from 'react';
import {
  Box,
  styled,
  Typography,
  Grid,
  Card,
  Divider,
} from '@mui/material';
import { StyledIcon } from 'app/common/Typography.js';
import PropertyAddEdit from './PropertyAddEdit';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectPMAll } from './hooks/useProjectPMAll';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';
import PMTable from './PMTable';

const PMSection = () => {
  const { projectId } = useProject();
  const { data: properties } = useProjectPMAll(projectId);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <>
          {(!properties || (Array.isArray(properties) && properties?.length === 0)) && (
              <>
                <Box
                  sx={{ margin: '20px 38px 20px 20px' }}
                  display='flex'
                  justifyContent='space-between'
                >
                  <StledCardTitle>Property Management</StledCardTitle>
                  <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                  Property Management
                  </StyledAddButton>
                </Box>
                <Divider />
              </>
            )}
          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {!properties || properties?.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Property Management Available
                </Typography>
              </div>
            ) : (
              <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <Card sx={{ px: 2, py: 1 }} elevation={0}> */}
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid
                      item
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      gap={0.5}
                    >
                      <Title>Property Management Details</Title>
                    </Grid>
                    <Grid item>
                      <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                        Property Management
                      </StyledAddButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableFilter title={'Property Management Details'}/>
                  </Grid>
                {/* </Card> */}
              </Grid>

              <Grid item xs={12}>
                <PMTable
                  properties={properties}
                />
              </Grid>
            </Grid>
          </>
            )}
          </Box>
        </>
      </Card>
      {isOpenDialog && <PropertyAddEdit isOpenDialog={isOpenDialog} closeDialog={toggleDialog} />}
    </>
  );
};

export default PMSection;
