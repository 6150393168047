import React, { useState } from 'react';
import { Box, styled, Card, Button, IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropertyAddEdit from './PropertyAddEdit';
import PropertyContactPersons from './PropertyContactPersons';

const Title = styled('span')({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
});

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '18%',
  marginTop: '-1px',
  rowGap: '20px',
  borderRadius: '5px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'key(--key-text)',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: 'var(--value-text)',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const PropertyView = ({ property, handleBack }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const propertyDetails = [
    { key: 'Property Name', value: property?.propertyName },
    { key: 'Email Address', value: property?.email },
    { key: 'Phone Number', value: property?.phoneNumber },
    {
      key: 'Address',
      value: [
        [property?.address, property?.city].filter(Boolean).join(', '),
        [property?.state, property?.zipCode, property?.country].filter(Boolean).join(', '),
      ]
        .filter(Boolean)
        .join('\n'),
    },
  ];

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  return (
    <>
      <div className='viewlead' style={{backgroundColor: '#fff', borderRadius: '5px'}}>
        {/* <SubtitleDivider /> */}
        <IconButton style={{ margin: '1%' }} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Box marginLeft={3} marginRight={3}>
          <Box item lg={6} md={6} sm={12} xs={12} marginRight={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Title>Property Management Details</Title>
              <Button
                style={{ color: 'var(--editButton)', fontSize: '16px', textTransform: 'none' }}
                onClick={toggleDialog}
              >
                <BorderColorIcon sx={{ fontSize: '16px', marginRight: '5px' }} />
                Property Management
              </Button>
            </Box>
            <Box marginTop={0} marginLeft={2}>
              <InfoContainer>
                {propertyDetails?.map(({ key, value }, index) => (
                  <Box key={index}>
                    <Key>{key}</Key>
                    <ValueContainer>
                      <Value>{value}</Value>
                    </ValueContainer>
                  </Box>
                ))}
              </InfoContainer>
            </Box>
          </Box>
        </Box>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'var(--pm-card-bg)' }}>
          <PropertyContactPersons property={property} />
        </Card>
      </div>
      <PropertyAddEdit isOpenDialog={isOpenDialog} closeDialog={toggleDialog} property={property} />
    </>
  );
};

export default PropertyView;
