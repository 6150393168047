import React, { useState } from 'react';
import { Modal, Typography, Grid, IconButton, Box, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Title } from 'app/common/Typography';
import BuyerAddEdit from './BuyerAddEdit';

const SectionTitle = styled(Typography)(({ theme }) => ({
  height: '37px',
  backgroundColor: 'var(--section-Title)',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  fontSize: '14px',
  fontWeight: '500',
}));

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const SaleView = ({ open, onClose, saleData, buyerData, selectedUnit, selectedBuilding }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleEditClick = () => setIsOpenModal(true);

  const handleFormClose = () => setIsOpenModal(false);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '0%',
            transform: 'translate(0%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            //   boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            height: '100%',
            paddingLeft: '40px',
          }}
        >
          <Box
            sx={{
              p: 1,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Sale Details View</Title>
            <IconButton onClick={onClose}>
              <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box container spacing={2} p={1}>
            <Grid
              mb={2}
              p={1}
              item
              xs={12}
              sx={{
                border: '1px solid var(--LeadSaleDetailModel-grid-border)',
                height: '93px',
                marginTop: '10px',
                backgroundColor: 'var(--LeadSaleDetailModel-grid-color)',
              }}
            >
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h6'>
                  Sale Space: <strong>{saleData?.saleSpace} Sq fts</strong>
                </Typography>
                <Box display='flex' alignItems='center'>
                  <Typography
                    sx={{
                      marginRight: 2,
                      color: 'var(--editButton)',
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                    onClick={handleEditClick}
                  >
                    <BorderColorOutlinedIcon fontSize='14px' /> Edit
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' justifyContent='space-between' alignItems='left'>
                <Typography
                  fontSize='14px'
                  fontWeight='500'
                  variant='body2'
                  color='var(--typography-price-text)'
                >
                  {saleData?.salePrice === 'INR' ? '₹' : '$'}
                  {saleData?.salePrice} price • {saleData?.buyerSaleCommission} Buyer Sale
                  Commission • {saleData?.sellerSaleCommission} Seller Sale Commission
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              xs={12}
              mb={2}
              sx={{
                border: '1px solid var(--LeadSaleDetailModel-grid-border)',
                height: 'auto',
                backgroundColor: 'var(--LeadSaleDetailModel-grid-color)',
              }}
            >
              <Grid item xs={12}>
                <SectionTitle>Sale Details:</SectionTitle>
                <Box display='flex' p={1} justifyContent='space-between'>
                  <Box>
                    <Typography color='var(--title-content-color)' fontWeight='500' variant='body2'>
                      Sale Price
                    </Typography>
                    <Typography variant='body2'>
                      {saleData?.salePrice === 'INR' ? '₹' : '$'}
                      {saleData?.salePrice || 'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color='var(--title-content-color)' fontWeight='500' variant='body2'>
                      Buyer Commission
                    </Typography>
                    <Typography variant='body2'>
                      {saleData?.buyerSaleCommission || 'N/A'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color='var(--title-content-color)' fontWeight='500' variant='body2'>
                      Seller Commission
                    </Typography>
                    <Typography variant='body2'>
                      {saleData?.sellerSaleCommission || 'N/A'}
                    </Typography>
                  </Box>{' '}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <SectionTitle>Realtor Details:</SectionTitle>
                <Box display='flex' p={1} justifyContent='space-between'>
                  <Box>
                    <Typography color='var(--title-content-color)' fontWeight='500' variant='body2'>
                      Buyer Realtor
                    </Typography>
                    <Typography variant='body2'>{saleData?.buyerRealtor || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography color='var(--title-content-color)' fontWeight='500' variant='body2'>
                      Unit No
                    </Typography>
                    <Typography variant='body2'>{saleData?.unitNo || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography color='var(--title-content-color)' fontWeight='500' variant='body2'>
                      Closing Cost
                    </Typography>
                    <Typography variant='body2'>{saleData?.closingCost || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography color='var(--title-content-color)' fontWeight='500' variant='body2'>
                      TitlePolicy Cost
                    </Typography>
                    <Typography variant='body2'>{saleData?.titlePolicyCost || 'N/A'}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <SectionTitle>Comments:</SectionTitle>
                <Typography
                  p={1}
                  variant='body2'
                  fontWeight='500'
                  color='var(--title-content-color)'
                >
                  {saleData?.comments || 'No Comments Added'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              mt={2}
              p={2}
              sx={{ border: '1px solid #DFDFE7', backgroundColor: '#FCFEFF' }}
            >
              <Grid item xs={6}>
                <Typography
                  variant='subtitle2'
                  fontSize='14px'
                  color='var(--subTitle2-color)'
                  fontWeight='500'
                >
                  BUYER DETAILS
                </Typography>
                <Typography fontSize='14px'>
                  <strong>{buyerData?.buyerName || 'N/A'}</strong>
                </Typography>
                <Typography fontSize='14px'>
                  {buyerData?.phoneNo} • {buyerData?.email}
                </Typography>
                <Typography variant='body2'>
                  {buyerData?.address || 'No Address'} • {buyerData?.city}• {buyerData?.state}
                </Typography>
                <Typography variant='body2'>
                  {buyerData?.country} • {buyerData?.zipCode}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  variant='subtitle2'
                  fontSize='14px'
                  color='var(--subTitle2-color)'
                  fontWeight='500'
                >
                  CONTACT PERSON
                </Typography>
                <Typography fontSize='14px'>
                  <strong>{buyerData?.contactPerson || 'N/A'}</strong>
                </Typography>
                <Typography fontSize='14px'>
                  {buyerData?.phoneNo} • {buyerData?.email}
                </Typography>
                <Typography variant='body2'>
                  {buyerData?.address || 'No Address'} • {buyerData?.city}• {buyerData?.state}
                </Typography>
                <Typography variant='body2'>
                  {buyerData?.country} • {buyerData?.zipCode}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <BuyerAddEdit
        open={isOpenModal}
        handleCancel={() => setIsOpenModal(false)}
        onClose={handleFormClose}
        saleData={saleData}
        buyerData={buyerData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
      />
    </>
  );
};

export default SaleView;
