import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import EditInvestments from './EditInvestments';
import { useState } from 'react';
import { formatCurrency } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { investorRoles } from 'app/utils/constant';
import { useProjectInvestments } from './hooks/useProjectInvestments';
import { useProjectMembers } from '../Members/hooks/useProjectMembers';
import {
  MembersCardBody,
  PDSubHeadings,
  StyledEditIcon,
  ViewAllLink,
} from '../../listings/StyledComponent';
import { EditIcon } from 'app/common/icons';
import { useNavigate } from 'react-router-dom';

const InvestmentsSection = ({ isShowView = false}) => {
  const { user } = useUserAuth();
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { data: investments } = useProjectInvestments(projectId);
  const { data: members } = useProjectMembers(projectId);
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const currentUserMember = members.find((member) => member?.email === user?.email);
  const isCurrentMemberInvestor = currentUserMember?.userRoles.some((role) =>
    investorRoles.includes(role),
  );

  const {
    totalInvestment = 0,
    landCost = 0,
    softCost = 0,
    investedAmount = 0,
    remainingAmount = 0,
  } = investments || {};

  const { totalShare = 0, availableShares = 0, eachSharePrice = 0 } = investments || {};

  const soldShares = totalShare - availableShares;

  const toggleModal = () => setIsOpenModal(!isOpenModal);

  return (
    <>
      <div className='viewlead'>
        <Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ margin: '20px 20px 20px 20px' }}
          >
            <PDSubHeadings sx={{ fontWeight: '600', fontSize: '24px', color: '#17181B' }}>
              Investment Details
            </PDSubHeadings>
            <StyledEditIcon
              variant='outlined'
              size='small'
              startIcon={<EditIcon />}
              onClick={toggleModal}
              disabled={isOnHoldOrCancelled}
            >
              Investment Details
            </StyledEditIcon>
          </Box>
          <Divider />

          {/* Investment Summary Section */}
          <Box display='flex' flexDirection='column' p={2} paddingLeft={3} gap={2}>
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Total Investment
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {`$${formatCurrency(totalInvestment)}`}
              </Typography>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Typography variant='body2' fontWeight={'bold'}>
                1 Share = {`${eachSharePrice}`}
              </Typography>
              <Typography variant='body2' fontWeight={'bold'}>
                Total Shares: {`${totalShare}`}
              </Typography>
              <Typography variant='body2' fontWeight={'bold'}>
                Available Shares: {`${availableShares}`}
              </Typography>
            </Box>

            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Typography variant='body2' color='text.secondary'>
                {`$${formatCurrency(landCost)}(Land Cost)`} +{' '}
                {`$${formatCurrency(softCost)}(Soft Cost)`}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box display='flex' justifyContent='space-around' p={2} gap={3}>
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Invested Amount
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {`$${formatCurrency(investedAmount)}`}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                In shares value: {formatCurrency(investedAmount) * formatCurrency(eachSharePrice)}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={14} color={'#17181B'}>
                Remaining Amount
              </Typography>
              <Typography variant='h5' fontWeight='bold'>
                {`$${formatCurrency(remainingAmount)}`}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                In shares value: {formatCurrency(remainingAmount) * formatCurrency(eachSharePrice)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
      {isShowView &&
        <MembersCardBody>
          <ViewAllLink type='button' onClick={() => navigate('../investors')}>
            View more
          </ViewAllLink>
        </MembersCardBody>
      }
      {isOpenModal && <EditInvestments investments={investments} toggleModal={toggleModal} />}
    </>
  );
};

export default InvestmentsSection;
