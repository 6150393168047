import React, { useRef } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  Container,
  TableRow,
  Table,
  Button,
  Icon,
  Grid,
  Card,
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span, StyledIcon } from 'app/common/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import CLLoanPaymentAddEdit from './CLLoanPaymentAddEdit';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { dateFormats } from 'app/utils/constant';
import { StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';
import CLLoanPaymentsTable from './CLLoanPaymentsTable';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const CLLoanPayments = ({ lender }) => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const selectedPayment = useRef(null);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleClickOpen = () => {
    selectedPayment.current = null;
    toggleDialog();
  };

  const handleClickUpdate = (payment) => {
    selectedPayment.current = payment;
    toggleDialog();
  };

  const loanPayments = lender?.loanPayments;

  return (
    <Card
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 'none',
              border: '1px solid #DADCE0',
            }}
          >
    <>
      {loanPayments?.length === 0 ? (
        <>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>Loan Payment Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Loan Payment Details.
                </Typography>
                <Button onClick={handleClickOpen} variant='contained' color='primary' sx={{textTransform:'none'}}>
                  Add Loan Payment
                </Button>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        <>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card sx={{ px: 2, py: 1 }} elevation={0}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid
                      item
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      gap={0.5}
                    >
                      <Title>Loan Payment Details</Title>
                    </Grid>
                    <Grid item>
                      <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                        Loan Payment Details
                      </StyledAddButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableFilter title={'Loan Payment Details'}/>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <CLLoanPaymentsTable
                  loanPayments={loanPayments}
                  lender={lender}
                />
              </Grid>
            </Grid>
        </>
      )}
      {isOpenDialog && (
        <CLLoanPaymentAddEdit
          lender={lender}
          payment={selectedPayment.current}
          closeDialog={toggleDialog}
        />
      )}
    </>
    </Card>
  );
};

export default CLLoanPayments;
