import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';
import { purple, brown } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import { auditLogs } from 'app/contexts/leadActions';
import notify from 'app/utils/notify';
import { convertToSystemLocalTime } from 'app/utils/helpers';

const actions = [
  { user: 'Michelle John', action: 'Added a new member', date: 'Feb 12 2024', color: '#AB84B0' },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
  },
  { user: 'Michelle John', action: 'Added a new member', date: 'Feb 12 2024', color: '#AB84B0' },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
  },
  { user: 'Michelle John', action: 'Added a new member', date: 'Feb 12 2024', color: '#AB84B0' },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
  },
];

const AuditLog = ({ leads }) => {
  const[auditLog,setAuditLog]=React.useState([]);
  const [loading, setLoading] =React.useState(true); 
  const { projectId } = useParams();

  React.useEffect(()=>{
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await auditLogs(projectId); 
        setAuditLog(response); 
        setLoading(false);
      } catch (err) {
        // setError(err);
        setLoading(false);
        notify.error(err);
      }
    };

    fetchData();
  },[projectId]);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <List>
        {auditLog.map((action, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: action.color }}>
                {action?.eventData?.country
                  .split(' ')
                  .map((name) => name[0])
                  .join('')}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='primary' component='span' sx={{ fontSize: '0.875rem' }}>
                  {action?.eventData?.id}
                </Typography>
              }
              secondary={
                <>
                  <Typography component='span' color='black' sx={{ fontSize: '0.75rem' }}>
                    Location Added or Updated to {action?.eventData?.state}
                  </Typography>
                  <Typography
                    component='span'
                    variant='body2'
                    color='textSecondary'
                    sx={{ fontSize: '0.75rem',padding:'10%' }}
                  >
                    {convertToSystemLocalTime(action?.eventData?.updatedDate)}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};
export default AuditLog;
