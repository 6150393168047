import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveProfileLLC } from 'app/contexts/userAuthActions';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProfileUpdateLLC() {
  const queryClient = useQueryClient();

  const { userNameJWT: userId } = useUserAuth();
  return useMutation({
    mutationFn: ({ userId, data }) => saveProfileLLC(userId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.profileLLC, userId] }),
  });
}
