import React, { useRef, useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Container,
  Table,
  Button,
  Icon,
  Grid,
  Card,
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span, StyledIcon } from 'app/common/Typography';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import PMContactPersonAddEdit from './PMContactPersonAddEdit.jsx';
import UpdateIcon from '@mui/icons-material/Update';
import { StyledAddButton } from '../../listings/StyledComponent.js';
import TableFilter from 'app/common/TableFilter.js';
import PMContactPersonTable from './PMContactPersonTable.jsx';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const PropertyContactPersons = ({ property }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const selectedContactPerson = useRef(null);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleClickOpen = () => {
    selectedContactPerson.current = null;
    toggleDialog();
  };

  const handleClickUpdate = (person) => {
    selectedContactPerson.current = person;
    toggleDialog();
  };

  const contactPersons = property?.contactPersons;

  return (
    <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
    <>
      {contactPersons?.length === 0 ? (
        <>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>Contact Person Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--tableBody-row-color)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Contact Person Details.
                </Typography>
                <Button onClick={handleClickOpen} variant='contained' color='primary' sx={{textTransform:'none'}}>
                  Add Contact Person
                </Button>
              </Box>
            </Container>
          </Box>
        </>
      ) : (
        // <>
        //   <Box
        //     marginLeft={3}
        //     marginRight={3}
        //     marginTop={1}
        //     justifyContent={'space-between'}
        //     display={'flex'}
        //     alignItems={'center'}
        //   >
        //     <Title sx={{ marginLeft: '0px' }}>Contact Person Details</Title>
        //     <Box display='flex' justifyContent='space-between' alignItems='center'>
        //       <Button
        //         style={{ color: 'vaar(--addButton)', fontSize: '14px' }}
        //         onClick={handleClickOpen}
        //       >
        //         + ADD
        //       </Button>
        //     </Box>
        //   </Box>
        //   <Box
        //     marginLeft={3}
        //     marginRight={3}
        //     marginTop={1}
        //     marginBottom={1}
        //     overflow='auto'
        //     border={'2px solid #E5E5E7'}
        //   >
        //     <ProductTable marginLeft={3} marginRight={3}>
        //       <TableHead style={{ backgroundColor: 'var(--tableHead-bg-color)' }}>
        //         <TableRow color='var(--tableHead-row-color)'>
        //           <TableCell sx={{ px: 2, color: 'var(--tableHead-row-color)' }}>S.NO.</TableCell>
        //           <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
        //             CONTACT PERSON
        //           </TableCell>
        //           <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
        //             EMAIL ADDRESS
        //           </TableCell>
        //           <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
        //             PHONE NUMBER
        //           </TableCell>
        //           <TableCell
        //             sx={{ px: 0, width: '5%', color: 'var(--tableHead-row-color)' }}
        //           ></TableCell>
        //         </TableRow>
        //       </TableHead>

        //       <TableBody>
        //         {contactPersons?.map((person, index) => (
        //           <TableRow hover key={person.contactId}>
        //             <TableCell
        //               align='left'
        //               sx={{
        //                 px: 2,
        //                 textTransform: 'capitalize',
        //                 fontWeight: 500,
        //                 fontSize: '14px',
        //                 color: 'var(--tableBody-row-color)',
        //               }}
        //             >
        //               {`${(index + 1).toString().padStart(2, '0')}`}
        //             </TableCell>
        //             <TableCell align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
        //               {person.name}
        //             </TableCell>
        //             <TableCell
        //               sx={{
        //                 px: 0,
        //                 fontWeight: 500,
        //                 fontSize: '14px',
        //                 color: 'var(--tableBody-row-color)',
        //               }}
        //               align='left'
        //             >
        //               {person.email}
        //             </TableCell>
        //             <TableCell
        //               sx={{
        //                 px: 0,
        //                 fontWeight: 500,
        //                 fontSize: '14px',
        //                 color: 'var(--tableBody-row-color)',
        //               }}
        //               align='left'
        //             >
        //               {person.phoneNumber}
        //             </TableCell>
        //             <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
        //               <SideNavMenu
        //                 menuButton={
        //                   <UserMenu>
        //                     <MoreVertIcon />
        //                   </UserMenu>
        //                 }
        //               >
        //                 <StyledItem onClick={() => handleClickUpdate(person)}>
        //                   <Icon>
        //                     <UpdateIcon />
        //                   </Icon>
        //                   <Span>Update</Span>
        //                 </StyledItem>
        //               </SideNavMenu>
        //             </TableCell>
        //           </TableRow>
        //         ))}
        //       </TableBody>
        //     </ProductTable>
        //   </Box>
        // </>
        <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ px: 2, py: 1 }} elevation={0}>
              <Grid container justifyContent='space-between' alignItems='center'>
                <Grid
                  item
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  gap={0.5}
                >
                  <Title>Contact Person Details</Title>
                </Grid>
                <Grid item>
                  <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                   Contact Person
                  </StyledAddButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableFilter title={'Contact Person'}/>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <PMContactPersonTable
              contactPersons={contactPersons}
              handleClickUpdate={handleClickUpdate}
            />
          </Grid>
        </Grid>
      </>
      )}
      <PMContactPersonAddEdit
        property={property}
        contactPerson={selectedContactPerson.current}
        isOpenDialog={isOpenDialog}
        closeModal={toggleDialog}
      />
    </>
    </Card>
  );
};

export default PropertyContactPersons;
