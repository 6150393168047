import React from 'react';
import {
  Typography,
  Grid,
} from '@mui/material';

const AmenitySubType = ({ selectedAmenity }) => {
  const isAmenityAvailable = selectedAmenity?.subtypes?.length > 0;

  return (
    <>

        {isAmenityAvailable ? (
          <>
            <Typography
              color='var(--updatedTime)'
              align='center'
              bgcolor='var(--updatedTime-bg)'
              fontWeight='500'
              width='155px'
              gutterBottom
            >
              Upgradables
            </Typography>

              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    CrossFit
                  </Typography>
                  <Typography variant='h6' color='primary'>$12000</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Yoga classes
                  </Typography>
                  <Typography variant='h6' color='primary'>
                    $14000
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Pilates
                  </Typography>
                  <Typography variant='h6' color='primary'>$12000</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Personal Training
                  </Typography>
                  <Typography variant='h6' color='primary'>
                    $14000
                  </Typography>
                </Grid>
              </Grid>
          </>
        ) : (
          <Typography>No subtypes available for this amenity</Typography>
        )}
      </>
  );
};

export default AmenitySubType;
