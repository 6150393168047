import React from 'react';
import { Box, styled, Button, Container, Typography, Grid, Card, Divider } from '@mui/material';
import { StyledIcon } from 'app/common/Typography.js';
import ConstructionLenderAddEdit from './ConstructionLenderAddEdit';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectCL } from './hooks/useProjectCL';
import TableFilter from 'app/common/TableFilter';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';
import ConstructionLenderTable from './ConstructionLenderTable';

const ConstructionLenderSection = () => {
  const { projectId } = useProject();
  const { data: constructionLenders } = useProjectCL(projectId);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);
  console.log('constructionLenders', constructionLenders);
  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <>
          {(!constructionLenders || (Array.isArray(constructionLenders) && constructionLenders.length === 0)) && (
              <>
                <Box
                  sx={{ margin: '20px 38px 20px 20px' }}
                  display='flex'
                  justifyContent='space-between'
                >
                  <StledCardTitle>Construction Lender</StledCardTitle>
                  <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                    Construction Lender
                  </StyledAddButton>
                </Box>
                <Divider />
              </>
            )}
          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {!constructionLenders || constructionLenders?.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Construction Lender Available
                </Typography>
              </div>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card sx={{ px: 2, py: 1 }} elevation={0}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid
                          item
                          display='flex'
                          justifyContent='flex-start'
                          alignItems='center'
                          gap={0.5}
                        >
                          <Title>Construction Lender</Title>
                        </Grid>
                        <Grid item>
                          <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                            Construction Lender
                          </StyledAddButton>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TableFilter title={'Construction Lender'} />
                      </Grid>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <ConstructionLenderTable constructionLenders={constructionLenders} />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </>
      </Card>
      {isOpenDialog && <ConstructionLenderAddEdit closeDialog={toggleDialog} />}
    </>
  );
};

export default ConstructionLenderSection;
