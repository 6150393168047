import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Card,
  Box,
  Rating,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import NavigationHeader from 'app/common/navigationHeader';
import { LoadingButton } from '@mui/lab';
import { EditProfileIcon, DeleteIcon } from 'app/common/icons';
import ProfileUpload from './ProfileUpload';
import './styles.css';
import LLCDialog from 'app/common/LLCDialog';
import { deleteUserLLCInformation, fetchUserLLC, saveUserLLCInfo } from 'app/contexts/leadActions';
import ProfileEdit from './ProfileEdit';
import useUserAuth from 'app/hooks/userUserAuth';

const Profile = () => {
  const { user, profileImage } = useUserAuth();
  const [showUserLLCDialog, setShowUserLLCDialog] = useState(false);
  const [llcData, setLlcData] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditOpened, setIsEditOpened] = useState(false);
  const [selectedLLC, setSelectedLLC] = useState(null);
  const [userLLCValues, setUserLLCValues] = useState({
    llcName: '',
    einNum: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
  });

  const infoPairs = [
    { key: '', value: <ProfileUpload profileImage={profileImage} /> },
    { key: 'FIRST NAME', value: user?.name },
    { key: 'LAST NAME', value: user?.surname },
    { key: 'EMAIL', value: user?.email },
    { key: 'MOBILE NUMBER', value: user?.phone },
    { key: 'DATE OF BIRTH', value: user?.dob },
    { key: 'ADDRESS 1', value: user?.address1 },
    { key: 'ADDRESS 2', value: user?.address2 },
    { key: 'CITY', value: user?.city },
    { key: 'STATE', value: user?.state },
    { key: 'COUNTRY', value: user?.country },
    { key: 'ZIPCODE', value: user?.zipCode },
  ];

  useEffect(() => {
    const fetchUserLLCDetails = async () => {
      try {
        const userLLCDetails = await fetchUserLLC();
        setLlcData(userLLCDetails);
      } catch (error) {
        console.error('Failed to fetch LLC details:', error);
      }
    };

    fetchUserLLCDetails();
  }, []);

  const handleshowUserLLCDialog = (llc = null) => {
    if (llc) {
      setUserLLCValues({
        id: llc.id || '',
        llcName: llc.llcName,
        einNum: llc.einNum || '',
        address1: llc.address1 || '',
        address2: llc.address2 || '',
        country: llc.country || '',
        state: llc.state || '',
        city: llc.city || '',
        zipCode: llc.zipCode || '',
      });
    } else {
      setUserLLCValues({
        llcName: '',
        einNum: '',
        address1: '',
        address2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
      });
    }
    setShowUserLLCDialog(true);
  };

  const handleUserLLCClose = () => {
    setShowUserLLCDialog(false);
  };

  const handleUserLLCSubmit = async (values) => {
    await saveUserLLCInfo(values);
    setShowUserLLCDialog(false);
    const userLLCDetails = await fetchUserLLC();
    setLlcData(userLLCDetails);
  };

  const handleDeleteClick = (llc) => {
    setSelectedLLC(llc);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedLLC) {
      setDeleteDialogOpen(false);
      setSelectedLLC(null);
      await deleteUserLLCInformation(selectedLLC.id);
      const userLLCDetails = await fetchUserLLC();
      setLlcData(userLLCDetails);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setSelectedLLC(null);
  };

  const renderAddress = (llc) => {
    const addressParts = [llc.address1, llc.address2, llc.city, llc.state, llc.zipCode, llc.country].filter(
      (part) => part,
    );
    return addressParts.join(', ');
  };

  const handleEditOpenClose = () => {
    setIsEditOpened((prevState) => !prevState);
  };

  return (
    <Fragment>
      <NavigationHeader project='My Profile' showNewLeadButton={false} showLead={false} />
      <Grid container spacing={2} p={1}>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <div className='subtitle-activity-divider' />
            <div className='main-container'>
              <div className='card-activity-header'>
                <span className='title'>Personal Details :</span>
                <div className='flex-end-center'>
                  <EditProfileIcon size={14} />
                  <LoadingButton
                    color='primary'
                    variant='outlined'
                    style={{ border: 'none', fontWeight: 500, padding: 3 }}
                    onClick={handleEditOpenClose}
                  >
                    Edit Profile
                  </LoadingButton>
                </div>
              </div>
              <div className='info-container'>
                <Grid
                  container
                  columnSpacing={{ xs: 3, sm: 4, md: 5 }}
                  rowSpacing={{ xs: 2, sm: 3, md: 4 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  {infoPairs.map(({ key, value }, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      display='flex'
                      flexDirection='column'
                    >
                      <span className='key'>{key}</span>
                      <span className='value text-wrap'>{value ?? ''}</span>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <div className='subtitle-activity-divider' />
            <div className='main-container'>
              <div className='card-activity-header'>
                <span className='title'>About</span>
              </div>
              <div className='about-us-content'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries,
              </div>
              <span className='review-container'>
                <span className='review-title'>Reviews :</span>
                <Rating
                  name='customized-4'
                  defaultValue={4}
                  max={5}
                  readOnly
                  style={{ marginBottom: '15px' }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '15px' }}>
                  <small className='small'>Professional</small>
                  <small className='small'>Expertise</small>
                  <small className='small'>Honest</small>
                </div>
                <div className='about-us-content'>
                  “ Lorem ipsum is a dummy content that is placed in the text”
                </div>
              </span>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card elevation={3}>
            <div className='subtitle-activity-divider' />
            <div className='main-container'>
              <div className='card-activity-header' style={{ marginBottom: '10px' }}>
                <span className='title'>LLC Details</span>
                <LoadingButton
                  color='primary'
                  variant='contained'
                  className='loading-button'
                  onClick={() => handleshowUserLLCDialog(null)}
                >
                  + Add LLC
                </LoadingButton>
              </div>
              <Grid container spacing={2}>
                {llcData.length === 0 ? (
                  <Grid item xs={12}>
                    <Box textAlign='center' p={3}>
                      <Typography variant='h6' color='textSecondary'>
                        No LLC details added.
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  llcData.map((llc) => (
                    <Grid item xs={12} sm={6} md={4} key={llc.id}>
                      <Card elevation={3} className='card-margin-bottom'>
                        <div className='card-activity-header'>
                          <span className='llc-title'>{llc.llcName}</span>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                              style={{
                                display: 'flex',
                                marginRight: '15px',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleshowUserLLCDialog(llc)}
                            >
                              <EditProfileIcon size={14} />
                            </div>
                            <div
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              onClick={() => handleDeleteClick(llc)}
                            >
                              <DeleteIcon size={14} />
                            </div>
                          </div>
                        </div>
                        <div className='llc-details-box'>
                          <span className='value'>EIN Number - {llc.einNum}</span>
                        </div>
                        <div className='llc-details-box'>
                          <span className='value'>Address - {renderAddress(llc)}</span>
                        </div>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
      <LLCDialog
        open={showUserLLCDialog}
        onClose={handleUserLLCClose}
        initialValues={userLLCValues}
        onSubmit={handleUserLLCSubmit}
      />
      <ProfileEdit isOpened={isEditOpened} closeModal={handleEditOpenClose} />
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete LLC</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the LLC "{selectedLLC?.llcName}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color='primary'>
            No
          </Button>
          <Button onClick={handleDeleteConfirm} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Profile;
