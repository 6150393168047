import { TableHead, TableRow, TableCell, TableBody, Icon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Span, StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import React, { useState } from 'react';
import { SideNavMenu } from 'app/components';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropertyView from './PropertyView';
import UpdateIcon from '@mui/icons-material/Update';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>S.No</TableCell>
      <TableCell>Contact Person</TableCell>
      <TableCell>Email Address</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function PMContactPersonTable({ contactPersons, handleClickUpdate }) {
  const [selectedPropertyIndex, setSelectedPropertyIndex] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const handleSelectProperty = (propertyIndex) => setSelectedPropertyIndex(propertyIndex);

  const selectedProperty = contactPersons?.[selectedPropertyIndex];

  return selectedProperty ? (
    <PropertyView property={selectedProperty} handleBack={() => handleSelectProperty(null)} />
  ) : (
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {contactPersons &&
            contactPersons.map((person, index) => {
              return (
                <>
                  <StyledTableRow key={person?.id}>
                    <TableCell>{`${index + 1}`.padStart(2, '0')}</TableCell>
                    <TableCell> {person?.name}</TableCell>
                    <TableCell>{person?.email}</TableCell>
                    <TableCell>{person?.phoneNumber}</TableCell>
                    <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
                      <SideNavMenu
                        menuButton={
                          <UserMenu>
                            <MoreVertIcon />
                          </UserMenu>
                        }
                      >
                        <StyledItem onClick={() => handleClickUpdate(person)}>
                          <Icon>
                            <UpdateIcon />
                          </Icon>
                          <Span>Update</Span>
                        </StyledItem>
                      </SideNavMenu>
                    </TableCell>
                  </StyledTableRow>
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

export default PMContactPersonTable;
