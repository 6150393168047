import React from 'react';
import {
  Box,
  styled,
  Button,
  Container,
  Typography,
  Grid,
  Card,
} from '@mui/material';
import { StyledIcon } from 'app/common/Typography.js';
import ConstructionLenderAddEdit from './ConstructionLenderAddEdit';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectCL } from './hooks/useProjectCL';
import TableFilter from 'app/common/TableFilter';
import { StyledAddButton } from '../../listings/StyledComponent';
import ConstructionLenderTable from './ConstructionLenderTable';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const ConstructionLenderSection = () => {
  const { projectId } = useProject();
  const { data: constructionLenders } = useProjectCL(projectId);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  return (
    <>
      <SubtitleDivider />
      <Box alignItems='center' mt={3}>
        {(!constructionLenders || constructionLenders.length === 0) ? (
          <>
            <Title>Construction Lender</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '380px',
                  width: '1035px',
                  marginLeft: '8%',
                  borderRadius: '8px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{ fontWeight: '600' }}
                  variant='body1'
                  color='var(--noDetails-text)'
                  gutterBottom
                >
                  No details added
                </Typography>
                <Typography variant='body2' color='textSecondary' paragraph>
                  It seems you have not added any details related to Construction Lender
                </Typography>
                <Button onClick={toggleDialog} variant='contained' color='primary'>
                  Add Details
                </Button>
              </Box>
            </Container>
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card sx={{ px: 2, py: 1 }} elevation={0}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid
                      item
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      gap={0.5}
                    >
                      <Title>Construction Lender</Title>
                    </Grid>
                    <Grid item>
                      <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                        Construction Lender
                      </StyledAddButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TableFilter title={'Construction Lender'}/>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <ConstructionLenderTable
                  constructionLenders={constructionLenders}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {isOpenDialog && <ConstructionLenderAddEdit closeDialog={toggleDialog} />}
    </>
  );
};

export default ConstructionLenderSection;
