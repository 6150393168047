import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { invitationsGetAll, respondToInvestment, respondToInvitation } from './requestsActions';
import { handleAxiosError } from 'app/utils/helpers';
import { fetchLeadsById } from './leadActions';
import { Loading } from 'app/components';
import { useDispatch } from 'react-redux';
import { fetchProjectsCounts } from 'app/redux/store';
import { requestsTypes } from 'app/utils/constant';

const RequestsContext = createContext();

const initialState = {
  requests: [],
};

function requestsReducer(state, action) {
  switch (action.type) {
    case 'requests/set':
      return { ...state, requests: action.payload };
    case 'request/respond':
      return {
        ...state,
        requests: state.requests.filter((request) => request.id !== action.payload.invitationId),
      };
    default:
      return state;
  }
}

function RequestsProvider({ children }) {
  const [state, dispatch] = useReducer(requestsReducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    const fetchRequests = async () => {
      setIsLoading(true);
      try {
        const invitations = await invitationsGetAll();

        const invitationsWithDetails = await Promise.all(
          invitations.map(async (invitation) => {
            if (invitation.status === 'PENDING') {
              const projectBasicDetails = await fetchLeadsById(invitation.projectId);
              return { ...invitation, projectTitle: projectBasicDetails.projectTitle };
            } else {
              return invitation;
            }
          }),
        );

        dispatch({ type: 'requests/set', payload: invitationsWithDetails });
      } catch (error) {
        handleAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRequests();
  }, []);

  const respondToRequest = async (request, action, declineReason) => {
    const { id: invitationId, token: invitationToken, projectId, invitationType } = request;
    const isInvestmentRequest =
      invitationType === requestsTypes.INVESTMENT_MAIL ||
      invitationType === requestsTypes.PAYMENT_REQUEST_MAIL;

    const response = {
      invitationId,
      invitationToken,
      invitationAction: action.toUpperCase(),
      projectId,
      declineReason,
    };

    try {
      isInvestmentRequest
        ? await respondToInvestment(response)
        : await respondToInvitation(response);
      dispatch({ type: 'request/respond', payload: { invitationId } });

      if (response.invitationAction === 'ACCEPT' && !isInvestmentRequest) {
        await reduxDispatch(fetchProjectsCounts()).unwrap();
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <RequestsContext.Provider
      value={{
        ...state,
        respondToRequest,
      }}
    >
      {isLoading ? <Loading /> : children}
    </RequestsContext.Provider>
  );
}

export const useRequests = () => {
  const context = useContext(RequestsContext);
  if (context === undefined) throw new Error('Requests context was used outside of provider');
  return context;
};

export default RequestsProvider;
