import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField, Modal } from '@mui/material';
import { Form, Formik } from 'formik';
import { Title } from 'app/common/Typography';
import { useProjectGCManagerAddUpdate } from './hooks/useProjectGCManagerAddUpdate';
import {  managersTitles } from 'app/utils/constant';
import { validationSchemas } from 'app/utils/validationSchemas';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '5%',
  paddingRight: '5%',
  marginBottom: '4%',
  borderRadius: '4px',
  marginTop: '0px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const GCManagerAddEdit = ({ contractor, manager, managerKey, closeDialog }) => {
  const { mutate: addUpdateGCManager } = useProjectGCManagerAddUpdate(
    contractor,
    manager,
    managerKey,
  );
  
  const isEditingMode = !!manager;

  const initialValues = {
    contactPerson: manager?.contactPerson || '',
    emailId: manager?.emailId || '',
    phoneNo: manager?.phoneNo || '',
  };

  const handleFormSubmit = (values, { setSubmitting }) =>
    addUpdateGCManager(values, {
      onSuccess: closeDialog,
      onSettled: () => setSubmitting(false),
    });

  const title = managersTitles[managerKey];

  return (
    <Modal open={true} onClose={closeDialog}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '35%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>
            {isEditingMode ? 'Edit' : 'Add'} {title}
          </Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.gcManager()}
          onSubmit={handleFormSubmit}
        >
          {({ values, isSubmitting, touched, errors, handleChange, handleBlur }) => (
            <Form>
              <Container>
                <Grid style={{ marginTop: '1px' }} container spacing={2}>
                  <Grid item xs={6}>
                    <Inputlabel>
                      <InputLabel>CONTACT PERSON</InputLabel>
                    </Inputlabel>
                    <TextField
                      fullWidth
                      size='small'
                      variant='outlined'
                      placeholder='Contact Person'
                      name='contactPerson'
                      value={values.contactPerson}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.contactPerson && Boolean(errors.contactPerson)}
                      helperText={touched.contactPerson && errors.contactPerson}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Inputlabel>
                      <InputLabel>EMAIL ADDRESS</InputLabel>
                    </Inputlabel>
                    <TextField
                      fullWidth
                      size='small'
                      variant='outlined'
                      placeholder='Email Address'
                      name='emailId'
                      value={values.emailId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.emailId && Boolean(errors.emailId)}
                      helperText={touched.emailId && errors.emailId}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: '1px' }} container spacing={2}>
                  <Grid item xs={6}>
                    <Inputlabel>
                      <InputLabel>PHONE NUMBER</InputLabel>
                    </Inputlabel>
                    <TextField
                      fullWidth
                      size='small'
                      variant='outlined'
                      placeholder='Phone Number'
                      name='phoneNo'
                      value={values.phoneNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phoneNo && Boolean(errors.phoneNo)}
                      helperText={touched.phoneNo && errors.phoneNo}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Container>
              <Box
                marginBottom={2.5}
                marginLeft={3.5}
                marginRight={2.5}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <LoadingButton
                  className='cancelButton'
                  color='error'
                  variant='outlined'
                  onClick={closeDialog}
                  style={{ boxShadow: 'none' }}
                  disabled={isSubmitting}
                  sx={{ textTransform: 'none' }}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  style={{ boxShadow: 'none', marginRight: '10px' }}
                  type='submit'
                  loading={isSubmitting}
                  sx={{ textTransform: 'none' }}
                >
                  {isEditingMode ? 'Update' : 'Save'}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default GCManagerAddEdit;
