import * as Yup from 'yup';

export const validationSchemas = {
  members: generateMembersSchema,
  investNow: generateInvestNowSchema,
  editInvestments: generateEditInvestmentsSchema,
};

function generateEditInvestmentsSchema({ investedAmount, investedShares }) {
  return Yup.object().shape({
    eachSharePrice: Yup.number()
      .required('Each share price is required')
      .positive('Each share price must be greater than 0')
      .max(
        Yup.ref('totalInvestment'),
        'Each share price must be less than or equal to total investment',
      )
      .test(
        'divisible-total-investment',
        'Share price must result in a whole number of shares',
        function (value) {
          const totalInvestment = this.parent.totalInvestment;
          return !value || Number.isInteger(totalInvestment / value);
        },
      ),
    landCost: Yup.number()
      .required('Land cost is required')
      .positive('Land cost must be greater than 0'),
    softCost: Yup.number()
      .required('Soft cost is required')
      .positive('Soft cost must be greater than 0'),
    totalInvestment: Yup.number()
      .required('Total investment is required')
      .positive('Total investment must be greater than 0')
      .min(
        investedAmount,
        `Total investment cannot be less than existing invested amount (${investedAmount})`,
      ),
    totalShare: Yup.number()
      .required('Total share is required')
      .positive('Total share must be greater than 0')
      .integer('Total share must be a whole number')
      .min(
        investedShares,
        `Total share cannot be less than existing invested shares (${investedShares})`,
      ),
  });
}

function generateMembersSchema(projectMembers = [], isEditing = false) {
  return Yup.object().shape({
    members: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^[a-zA-Z\s]+$/, 'Name can only contain letters and spaces!')
          .required('Name is required!'),
        phone: Yup.string()
          .matches(
            /^\+\d{10,12}$/,
            'Please specify country code with valid mobile number (10-12 digits)',
          )
          .required('Mobile number is required!')
          .test('unique-phone', 'This phone number is already in use', function (value) {
            if (isEditing) return true;

            const { createError } = this;
            const members = this.from[1].value.members;
            const phoneCount = members.filter((member) => member.phone === value).length;

            if (phoneCount > 1) {
              return createError({ message: 'This phone number is already in use in the form' });
            }

            const existsInProjectMembers = projectMembers.some((member) => member.phone === value);

            if (existsInProjectMembers) {
              return createError({
                message: 'This phone number is already associated with a project member',
              });
            }

            return true;
          }),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required!')
          .test('unique-email', 'This email is already in use', function (value) {
            if (isEditing) return true;

            const { createError } = this;
            const members = this.from[1].value.members;
            const emailCount = members.filter((member) => member.email === value).length;

            if (emailCount > 1) {
              return createError({ message: 'This email is already in use in the form' });
            }

            const existsInProjectMembers = projectMembers.some((member) => member.email === value);

            if (existsInProjectMembers) {
              return createError({
                message: 'This email is already associated with a project member',
              });
            }

            return true;
          }),
        roles: Yup.array().min(1, 'At least one role should be selected!'),
      }),
    ),
  });
}

function generateInvestNowSchema({
  isAcceptedInvestor,
  remainingAmount,
  availableShares,
  eachSharePrice,
}) {
  const baseAmountSchema = Yup.number()
    .positive('Amount must be greater than zero!')
    .max(remainingAmount, 'Amount cannot be more than the remaining investment!')
    .test(
      'divisible-by-share-price',
      'You entered a non-divisible amount. Please adjust to match whole shares.',
      (value) => !value || Number.isInteger(value / eachSharePrice),
    );

  const baseSharesSchema = Yup.number()
    .positive('Number of shares must be greater than zero!')
    .max(availableShares, 'Number of shares cannot be more than the available shares!')
    .integer('Number of shares must be a whole number');

  return Yup.object().shape({
    interestedAmount: Yup.number().when([], {
      is: () => !isAcceptedInvestor,
      then: () => baseAmountSchema.required('Interested amount is required!'),
      otherwise: () => Yup.number().notRequired(),
    }),

    interestedShares: Yup.number().when([], {
      is: () => !isAcceptedInvestor,
      then: () => baseSharesSchema.required('Number of interested shares is required!'),
      otherwise: () => Yup.number().notRequired(),
    }),

    investedAmount: baseAmountSchema.when(['$self'], {
      is: (investedAmount) => isAcceptedInvestor || investedAmount,
      then: () => baseAmountSchema.required('Invested amount is required!'),
      otherwise: () => baseAmountSchema.notRequired(),
    }),

    investedShares: baseSharesSchema.when(['$self'], {
      is: (investedShares) => isAcceptedInvestor || investedShares,
      then: () => baseSharesSchema.required('Number of shares is required!'),
      otherwise: () => baseSharesSchema.notRequired(),
    }),

    paymentDate: Yup.date().when(['investedAmount', 'investedShares'], {
      is: (investedAmount, investedShares) => investedAmount || investedShares,
      then: () =>
        Yup.date().required('Payment date is required!').typeError('Please select a valid date'),
      otherwise: () => Yup.date().notRequired(),
    }),

    paymentMode: Yup.string().when(['investedAmount', 'investedShares'], {
      is: (investedAmount, investedShares) => investedAmount || investedShares,
      then: () => Yup.string().required('Payment mode is required!'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });
}
