import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, styled, Modal, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import LeadsDetailStatus from './LeadsDetailStatus';
import { useProject } from 'app/contexts/ProjectContext';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const LeadsConfirmPage = ({ openModal, handleCancel }) => {
  const {
    basic: { projectTitle },
  } = useProject();
  const [openDetailStatus, setOpenDetailStatus] = useState(false);

  const handleYesProceedClick = () => {
    setOpenDetailStatus(true);
  };

  const handleCloseDetailStatus = () => {
    setOpenDetailStatus(false);
    handleCancel();
  };

  return (
    <>
      <SubtitleDivider />
      <Modal open={openModal} onClose={handleCancel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '32%',
          }}
        >
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title>Confirmation</Title>
            <IconButton onClick={handleCancel}>
              <Close />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box
            sx={{
              marginLeft: '4px',
            }}
          >
            <Box sx={{ p: 2, paddingLeft: 3, paddingRight: 3 }}>
              <Typography style={{ fontSize: '16px', fontWeight: '450' }}>
                {`Are you sure want to move the ${projectTitle} from
                Lead to the New Project`}
              </Typography>
            </Box>

            <ModalDivider />
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                p: 2,
              }}
            >
              <LoadingButton
                color='error'
                sx={{ height: '40px', mt: 0.5 }}
                variant='outlined'
                onClick={handleCancel}
                style={{ boxShadow: 'none' }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                onClick={handleYesProceedClick}
                style={{ boxShadow: 'none' }}
              >
                Yes Proceed
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>

      <LeadsDetailStatus
        openModal={openDetailStatus}
        handleChangeStatus={handleCloseDetailStatus}
      />
    </>
  );
};

export default LeadsConfirmPage;
