import { API_ENDPOINTS } from './apiConstants';
import axiosService from './axiosService';

export const login = async (email) => {
  try {
    const response = await axiosService.Post(API_ENDPOINTS.AUTH.LOGIN, { email });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (userData) => {
  try {
    await axiosService.Post(API_ENDPOINTS.REGISTRATION.REGISTER, userData);
  } catch (error) {
    throw error;
  }
};

export const sendOTPWithEmailAndSession = async (data) => {
  try {
    const response = await axiosService.Post(API_ENDPOINTS.AUTH.SEND_OTP, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendConfirmationOTPWithEmail = async (data) => {
  try {
    await axiosService.Post(API_ENDPOINTS.REGISTRATION.CONFIRM_REGISTRATION, data);
  } catch (error) {
    throw error;
  }
};

export const resendConfirmationOTP = async (email) => {
  try {
    await axiosService.Post(API_ENDPOINTS.REGISTRATION.RESEND_CONFIRMATION_CODE, { email });
  } catch (error) {
    throw error;
  }
};

export const getUserDetails = async () => {
  try {
    const response = await axiosService.Get(API_ENDPOINTS.USERS.GET_USER_DETAILS);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const editUserDetails = async (userNameJWT, userDetails) => {
  try {
    await axiosService.Put(API_ENDPOINTS.USERS.EDIT_USER_DETAILS(userNameJWT), userDetails);
  } catch (error) {
    throw error;
  }
};
