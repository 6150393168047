
import { TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import { useState } from 'react';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Amenity Name</TableCell>
      <TableCell>Upgrade</TableCell>
      <TableCell>Price</TableCell>
      <TableCell>Created By</TableCell>
      <TableCell>Updated By</TableCell>
    </TableRow>
  </TableHead>
);

function SaleAmenitiesTableData() {

  return (
    <>
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {dummyRoles &&
            dummyRoles.map((amenity, index) => {
              return (
                <>
                  <StyledTableRow key={amenity?.id}>
                    <TableCell>{amenity?.name}</TableCell>
                    <TableCell>{amenity?.subType}</TableCell>
                    <TableCell>{amenity?.price}</TableCell>
                    <TableCell> {amenity?.createdBy}</TableCell>
                    <TableCell> {amenity?.updatedBy}</TableCell>
                  </StyledTableRow>
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
    </>
  );
}

export default SaleAmenitiesTableData;


const dummyRoles = [
  {
    id: 1, // Added ID for amenity uniqueness
    name: 'Gym Facility',
    type: 'Premium',
    subType: 'Cardio',
    price: '$100',
    createdBy: 'Admin',
    updatedBy: 'Admin',
    subtypes: [
      { name: 'Cardio', price: '$50' },
      { name: 'Strength Training', price: '$70' },
    ],
  },
  {
    id: 2, // Added ID for amenity uniqueness
    name: 'Swimming Pool',
    type: 'Basic',
    subType: 'Adult', // default sub type
    price: '$80',
    createdBy: 'Admin',
    updatedBy: 'Admin',
    subtypes: [
      { name: 'Adult', price: '$60' },
      { name: 'Kids', price: '$50' },
    ],
  },
  // {
  //   id: 3, // Added ID for amenity uniqueness
  //   name: 'Tennis Court',
  //   type: 'Standard',
  //   subType: 'Singles', // default sub type
  //   price: '$150',
  //   createdBy: 'Admin',
  //   updatedBy: 'Admin',
  //   subtypes: [
  //     { name: 'Singles', price: '$75' },
  //     { name: 'Doubles', price: '$100' },
  //   ],
  // },
  // {
  //   id: 4, // Added ID for amenity uniqueness
  //   name: 'Yoga Studio',
  //   type: 'Premium',
  //   subType: 'Morning', // default sub type
  //   price: '$120',
  //   createdBy: 'Admin',
  //   updatedBy: 'Admin',
  //   subtypes: [
  //     { name: 'Morning', price: '$70' },
  //     { name: 'Evening', price: '$90' },
  //   ],
  // },
];