import React, { useState } from 'react';
import { Autocomplete, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Formik } from 'formik';
import { propertyType, SubType } from 'app/utils/constant';
import {
  StyledBox3,
  StyledTextField,
  StyledTextAreaField,
  LinkContainer,
  AddLinkButton,
} from './StyledComponents';
import { PropertyDetailsSchema } from 'app/utils/validationSchemas';
import { useProjectBasicUpdate } from '../hooks/useProjectBasicUpdate';
import { LinksAddIcon, StyledcrossIcon } from 'app/common/icons';
import {
  ModalButtonCancle,
  ModalButtonSave,
  StyledBox4,
} from 'app/views/dashboard/profile/StyleComponents';
import {
  ModalCancel,
  ModalContainer,
  ModalDivider,
  ModalSave,
  ModalStyledBox,
  ModalStyledSubBox,
  StyledBoxFooter,
  StyledLabel,
  Title,
} from 'app/common/Typography';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';

const EditPropertyDetailsModal = ({ handleCloseEditModal, property }) => {
  const [selectPropType, setSelectPropType] = useState(null);
  const [selectSubType, setSelectSubType] = useState(null);
  const { mutate: updateBasicDetails, isPending: isUpdating } = useProjectBasicUpdate(property?.id);

  const [links, setLinks] = React.useState([property?.referenceLink || '']);
  const initialValues = {
    PropertyType: property?.propertyType || '',
    subType: property?.subType || '',
    Area: property?.size || '',
    Zone: '-',
    referenceLink: property?.referenceLink || '',
    EstimatedPrice: property?.estimatedPrice || '',
    Description: property?.description || '',
  };

  React.useEffect(() => {
    if (property?.subType) {
      const initialSubType = SubType.find((option) => option.label === property.subType);
      setSelectSubType(initialSubType || null);
    }
    if (property?.propertyType) {
      const initialPropType = propertyType.find((option) => option.label === property.propertyType);
      setSelectPropType(initialPropType || null);
    }
  }, [property]);

  const handleAddLink = () => {
    setLinks([...links, '']);
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
  };

  const handleChangeLink = (index, value) => {
    const updatedLinks = [...links];
    updatedLinks[index] = value;
    setLinks(updatedLinks);
  };
  const handleFormSubmit = (values, { setSubmitting }) => {
    const updatedPropertyDetails = {
      ...property,
      estimatedPrice: values.EstimatedPrice,
      size: values.Area,
      propertyType: values.PropertyType,
      subType: values.subType,
      description: values.Description,
      referenceLink: links[0] || '',
    };
    updateBasicDetails(updatedPropertyDetails, {
      onSuccess: handleCloseEditModal(),
      onSettled: () => setSubmitting(false),
    });
  };

  return (
    <>
      <ModalStyledBox>
        <ModalStyledSubBox>
          <Title>{'Update'} Property Details</Title>
          <IconButton onClick={handleCloseEditModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
        <StyledBox3>
          <Formik
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={PropertyDetailsSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form>
                  <ModalContainer>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <StyledLabel sx={{marginLeft: '2%'}}>Property Type</StyledLabel>
                        <Autocomplete
                          size='small'
                          value={selectPropType}
                          onChange={(event, newValue) => {
                            setSelectPropType(newValue || null);
                            setFieldValue('PropertyType', newValue ? newValue.label : '');
                          }}
                          options={propertyType}
                          getOptionLabel={(option) => option.label || ''}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              variant='outlined'
                              fullWidth
                              placeholder='Select Property Type'
                              error={touched.PropertyType && !!errors.PropertyType}
                              helperText={touched.PropertyType && errors.PropertyType}
                              disabled={isSubmitting}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <StyledLabel sx={{marginLeft: '2%'}}>Sub Type</StyledLabel>
                        <Autocomplete
                          size='small'
                          value={selectSubType}
                          onChange={(event, newValue) => {
                            setSelectSubType(newValue || null);
                            setFieldValue('subType', newValue ? newValue.label : '');
                          }}
                          options={SubType}
                          getOptionLabel={(option) => option.label || ''}
                          renderInput={(params) => (
                            <StyledTextField
                              {...params}
                              variant='outlined'
                              fullWidth
                              placeholder='Select Sub Type'
                              error={touched.subType && !!errors.subType}
                              helperText={touched.subType && errors.subType}
                              disabled={isSubmitting}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <StyledLabel sx={{marginLeft: '2%'}}>Area(in acres)</StyledLabel>
                        <StyledTextField
                          type='text'
                          name='Area'
                          onChange={handleChange}
                          value={values.Area}
                          fullWidth
                          variant='outlined'
                          size='small'
                          error={touched.Area && !!errors.Area}
                          helperText={touched.Area && errors.Area}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <StyledLabel sx={{marginLeft: '2%'}}>Zone</StyledLabel>
                        <StyledTextField
                          type='text'
                          name='Zone'
                          onChange={handleChange}
                          value={values.Zone}
                          fullWidth
                          variant='outlined'
                          size='small'
                          error={touched.Zone && !!errors.Zone}
                          helperText={touched.Zone && errors.Zone}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <StyledLabel sx={{marginLeft: '2%'}}>Estimated Price</StyledLabel>
                        <StyledTextField
                          type='text'
                          name='EstimatedPrice'
                          onChange={handleChange}
                          value={values.EstimatedPrice}
                          fullWidth
                          variant='outlined'
                          size='small'
                          error={touched.EstimatedPrice && !!errors.EstimatedPrice}
                          helperText={touched.EstimatedPrice && errors.EstimatedPrice}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <StyledLabel sx={{marginLeft: '2%'}}>Description</StyledLabel>
                        <ReactQuill 
                          value={values.Description}
                          // onChange={handleChange}
                          onChange={(content, delta, source, editor) => {
                            setFieldValue('Description', editor.getHTML());
                          }}
                          style={{marginLeft: '2%', width: '95%', height: "100px", marginBottom: '65px'}}
                          modules={{
                            toolbar: [
                              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                              [{ 'align': [] }],
                              ['bold', 'italic', 'underline'],
                              ['link'],
                              ['blockquote']
                            ],
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <StyledLabel sx={{marginLeft: '2%'}}>Reference link</StyledLabel>
                        {links.map((link, index) => (
                          <LinkContainer key={index}>
                            <StyledTextField
                              name='referenceLink'
                              onChange={(e) => handleChangeLink(index, e.target.value)}
                              value={link}
                              fullWidth
                              variant='outlined'
                            />
                            <IconButton onClick={() => handleRemoveLink(index)} disabled={links.length === 1}>
                              <StyledcrossIcon />
                            </IconButton>
                          </LinkContainer>
                        ))}
                        <AddLinkButton startIcon={links.some((link) => link.trim() === '') ? <LinksAddIcon color='#A9A9A9'/> : <LinksAddIcon />} onClick={handleAddLink} disabled={links.some((link) => link.trim() === '')}>
                          Reference Links
                        </AddLinkButton>
                      </Grid>
                    </Grid>
                  </ModalContainer>
                  <StyledBoxFooter>
                    <ModalCancel
                      variant='outlined'
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </ModalCancel>
                    <ModalSave
                      type='submit'
                      loading={isSubmitting}
                    >
                      Update
                    </ModalSave>
                  </StyledBoxFooter>
              </Form>
            )}
          </Formik>
        </StyledBox3>
      </ModalStyledBox>
    </>
  );
};

export default EditPropertyDetailsModal;
