import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './slices/searchSlice';
import projectsCounterReducer from './slices/projectsCounterSlice';

export const store = configureStore({
  reducer: {
    search: searchReducer,
    projectsCounter: projectsCounterReducer,
  },
});

export * from './thunks/fetchProjectsCounts';
export { setSearchValue, selectSearchValue } from './slices/searchSlice';
