import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  styled,
  Modal,
} from '@mui/material';
import LeadsExpensesModal from './LeadsExpensesModal';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});
const LeadsExpenseForm = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <SubtitleDivider />
      <Box display={'flex'} alignItems={'center'}>
        <LoadingButton
          onClick={handleOpenModal}
          variant='contained'
          style={{
            boxShadow: 'none',
            cursor: 'pointer',
            padding: '1px',
            backgroundColor: 'transparent',
          }}
        >
          + Add Expenses
        </LoadingButton>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <LeadsExpensesModal handleCloseModal={handleCloseModal} openModal={openModal} />
      </Modal>
    </>
  );
};
export default LeadsExpenseForm;
