import React, { useRef, useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Container,
  Table,
  Button,
  Icon,
  Card,
  Divider,
  Grid,
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span, StyledIcon } from 'app/common/Typography';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import GCManagerAddEdit from './GCManagerAddEdit';
import UpdateIcon from '@mui/icons-material/Update';
import { GCManagerKeys, managersTitles } from 'app/utils/constant';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';
import GCManagerViewTable from './GCManagerViewTable';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: 'var(--subtitleDivider)',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const GCManagersView = ({ contractor, managerKey }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const selectedManager = useRef(null);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleClickOpen = () => {
    selectedManager.current = null;
    toggleDialog();
  };

  const handleClickUpdate = (manager) => {
    selectedManager.current = manager;
    toggleDialog();
  };

  const managers = contractor?.[managerKey];
  const title = managersTitles[managerKey];

  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <>
          {(!managers || (Array.isArray(managers) && managers.length === 0)) && (
            <>
              <Box
                sx={{ margin: '20px 38px 20px 20px' }}
                display='flex'
                justifyContent='space-between'
              >
                <StledCardTitle>{title}</StledCardTitle>
                <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                {title}
                </StyledAddButton>
              </Box>
              <Divider />
            </>
          )}

          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {!managers || !Array.isArray(managers) || managers?.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No {title} Available
                </Typography>
              </div>
            ) : (
            <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid
                        item
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Title>{title}</Title>
                      </Grid>
                      <Grid item>
                        <StyledAddButton startIcon={<StyledIcon />} onClick={handleClickOpen}>
                          {title}
                        </StyledAddButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TableFilter title={title} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <GCManagerViewTable
                      managers={managers}
                      contractor={contractor}
                      managerKey={managerKey}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </>
      </Card>
      {isOpenDialog && (
        <GCManagerAddEdit
          contractor={contractor}
          manager={selectedManager.current}
          managerKey={managerKey}
          closeDialog={toggleDialog}
        />
      )}
    </>
  );
};

export default GCManagersView;
