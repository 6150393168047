import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, Card, Tabs, Tab, Divider } from '@mui/material';
import LeaseAddEdit from './LeaseAddEdit';
import AddEditBuilding from './AddEditBuilding';
import LeaseCard from './LeaseCard';
import SaleCard from './SaleCard';
import SectionLoader from 'app/common/SectionLoader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useProjectBuildingUnitLeases } from './hooks/useProjectBuldingUnitLeases';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectBuildingUnitSales } from './hooks/useProjectBuldingUnitSales';
import { StyledAddButton, StyledUnitAddButton } from '../../listings/StyledComponent';
import { StyledIcon } from 'app/common/Typography';
import SaleAddEdit from './SaleAddEdit';
import { useProjectBasic } from '../Basic/hooks/useProjectBasic';
import SaleDetailsView from './SaleDetailsView';

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--modalDivider)',
});

const BuildingView = ({ selectedBuilding, handleBackToBuildings }) => {
  const [showLeaseForm, setShowLeaseForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUnit, setSelectedUnit] = useState(selectedBuilding.units?.[0]);
  const [viewMode, setViewMode] = useState('lease');
  const [showLeadSaleForm, setShowLeadSaleForm] = useState(false);
  const { projectId } = useProject();
  const { data: basic, isPending } = useProjectBasic(projectId);

  useEffect(() => {
    if(basic?.subType === 'Residential - Single Family' || basic?.subType === 'Residential - Multi Family') {
      setViewMode('sale')
    } else {
      setViewMode('lease')
    }
  },[basic])

  const { data: unitLeases, isPending: isPendingLease } = useProjectBuildingUnitLeases(
    projectId,
    selectedUnit?.unitId,
  );
  const { data: unitSales, isPending: isPendingSale } = useProjectBuildingUnitSales(
    projectId,
    selectedUnit?.unitId,
  );

  useEffect(() => {
    console.log("data refetch", unitSales);
  
    // Check if selectedSale exists and if its id matches any sale in the refetched unitSales
    if (selectedSale) {
      const updatedSale = unitSales.find((sale) => sale.id === selectedSale.id);
      if (updatedSale) {
        setSelectedSale(updatedSale); // Update selectedSale with the refetched sale
      }
    }
  }, [unitSales]); // Add selectedSale as a dependency
  

  const isLoading = isPendingLease || isPendingSale;

  const handleSelectUnit = (unit) => setSelectedUnit(unit);

  const [viewModalState, setViewModalState] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);

  const handleViewSaleDetails = (saleId) => {
    const selected = unitSales.find((sale) => sale.id === saleId);
    setSelectedSale(selected);  
    setViewModalState(true);
  };

  const handleCloseSaleDetailsView = () => {
    setViewModalState(false);  // Close the modal
    setSelectedSale(null);  // Clear the selected sale
  };

  const handleAddSaleClick = () => {
    setShowLeadSaleForm(true);
  };

  const handleCloseForm = () => {
    setShowLeadSaleForm(false);
  };

  const handleOpenLeaseForm = () => {
    setShowLeaseForm(true);
  };

  const handleSaveBuilding = () => setShowModal(false);

  const handleCloseLeaseForm = () => {
    setShowLeaseForm(false);
  };

  const filteredUnits = (selectedBuilding?.units || []).filter((unit) =>
    unit.unitName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleTabChange = (event, newValue) => {
    setViewMode(newValue); // Change the view mode based on selected tab
  };

  return (
    <>
      <Box style={{ padding: '20px', backgroundColor: '#F8F9FA' }}>
        <Typography variant='h6' style={{ marginTop: '3px', marginBottom: '15px' }}>
          <span style={{ cursor: 'pointer' }} onClick={handleBackToBuildings}>
            <ArrowBackIcon sx={{ marginBottom: '-4px', width: '5%' }} />
          </span>
          {selectedBuilding?.buildingName || 'Building Name'}
        </Typography>

        <Card
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'auto',
            backgroundColor: '#fff',
            boxShadow: 'none',
            border: '1px solid #DADCE0',
          }}
        >
          <Box
            style={{
              width: '280px',
              padding: '20px',
            }}
          >
            <div
              style={{
                backgroundColor: '#EEEEF4',
                padding: '20px',
                border: '1px solid #EEEEF4',
                borderRadius: '10px',
                marginBottom: '10px',
              }}
            >
              <input
                type='text'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size='small'
                placeholder='Search with unit name...'
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '17px',
                  border: '1px solid #BDC1C6',
                }}
              />
            </div>
            <StyledUnitAddButton startIcon={<StyledIcon />} onClick={() => setShowModal(true)}>
              New Unit
            </StyledUnitAddButton>

            <Box
              style={{
                // maxHeight: '300px',
                overflowY: 'auto',
                marginTop: '10px',
              }}
            >
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {filteredUnits.length > 0 ? (
                  filteredUnits.map((unit, index) => (
                    <li
                      key={unit.unitId}
                      onClick={() => handleSelectUnit(unit)}
                      style={{
                        backgroundColor:
                          selectedUnit?.unitId === unit.unitId
                            ? 'var(--li-backgroundColor)'
                            : 'transparent',
                        marginBottom: '8px',
                        padding: '10px',
                        borderLeft:
                          selectedUnit?.unitId === unit.unitId && '5px solid var(--li-borderLeft)',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      <div>
                        <strong style={{ fontSize: '18px' }}>
                          {unit.unitName || `Unit ${index + 1}`}
                        </strong>
                        <div style={{ fontSize: '12px', color: '#6c757d' }}>
                          {unit.space ? `${unit.space} sq feet` : 'No space information'}
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li>No units available</li>
                )}
              </ul>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2, borderColor: '#DADCE0' }} />
          <Box
            style={{
              width: '100%',
              // maxHeight: '300px',
              overflowY: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', padding: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <strong style={{ fontSize: '20px', color: '#17181B' }}>
                  {selectedUnit?.unitName || 'Unit Name'}
                </strong>
                <Typography variant='body2' style={{ margin: '0 7px' }}>
                  •
                </Typography>
                <div style={{ fontSize: '14px', color: '#202124', marginTop: '2px' }}>
                  {selectedUnit?.space
                    ? `${selectedUnit.space} Sq ft`
                    : 'Space (Sq ft) not available'}
                </div>
              </div>
              <StyledAddButton startIcon={<StyledIcon />} onClick={viewMode === 'lease' ? handleOpenLeaseForm : handleAddSaleClick}>
                {viewMode === 'lease' ? `Lease Details` : `Sale Details` }
              </StyledAddButton>
            </Box>
            <ModalDivider />
            <Tabs
              value={viewMode}
              onChange={handleTabChange}
              aria-label='Lease/Sale Tabs'
              textColor='#17181B'
              sx={{ backgroundColor: '#F9F9F9', paddingLeft: '15px', borderBottom: '1px solid #DADCE0',
                '& .MuiTabs-indicator': {
                  backgroundColor: '#82B485',
                },
               }}
            >
              {basic?.subType !== 'Residential - Single Family' && basic?.subType !== 'Residential - Multi Family' &&
                <Tab
                  label='Lease Details'
                  value='lease'
                  sx={{
                    textTransform: 'none',
                    fontWeight: '700',
                    color: viewMode === 'lease' ? '#17181B' : '#80868B',
                    padding: '10px 20px',
                  }}
                />
              }
              <Tab
                label='Sale Details'
                value='sale'
                sx={{
                  textTransform: 'none',
                  fontWeight: '700',
                  color: viewMode === 'sale' ? '#17181B' : '#80868B',
                  padding: '10px 20px',
                }}
              />
            </Tabs>
            {viewMode === 'lease' ? (
              <>
                <Box style={{ padding: '20px' }}>
                  {isLoading ? (
                    <SectionLoader />
                  ) : unitLeases && unitLeases.length > 0 ? (
                    unitLeases.map((lease) => (
                      <LeaseCard
                        key={lease?.id}
                        leaseId={lease?.id}
                        unitDetails={lease?.unitDetails}
                        selectedBuilding={selectedBuilding}
                        selectedUnit={selectedUnit}
                      />
                    ))
                  ) : (
                    <Typography fontWeight={500} fontSize={'14px'} color={'#3C4043'}>No Lease Details Available</Typography>
                  )}
                </Box>
              </>
            ) : (
              <>

                <Box>
                  {isLoading ? (
                    <SectionLoader />
                  ) : unitSales && unitSales.length > 0 && !viewModalState ? (
                    unitSales.map((sale) => {
                      if (!sale.unitDetails) return null;
                      return (
                        <Box style={{ padding: '20px' }}>
                          <SaleCard
                            key={sale?.id}
                            saleId={sale?.id}
                            unitDetails={sale?.unitDetails}
                            selectedBuilding={selectedBuilding}
                            selectedUnit={selectedUnit}
                            viewModalState={viewModalState}  // Pass viewModalState down
                            handleViewSaleDetails={handleViewSaleDetails}
                          />
                        </Box>
                      );
                    })
                  ) : viewModalState ? (
                    <SaleDetailsView
                      selectedUnit={selectedUnit}
                      selectedBuilding={selectedBuilding}
                      onClose={() => setViewModalState(false)}
                      saleData={selectedSale.unitDetails?.saleDetails}
                      buyerData={selectedSale.unitDetails?.buyerDetails}
                      saleId={selectedSale?.id}
                    />
                  ) : (
                    <Box style={{ padding: '20px' }}>
                      <Typography fontWeight={500} fontSize={'14px'} color={'#3C4043'}>No Sale Details Available</Typography>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Card>
      </Box>
      <LeaseAddEdit
        open={showLeaseForm}
        onClose={handleCloseLeaseForm}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        isEdit = {false}
      />
      <SaleAddEdit
        open={showLeadSaleForm}
        onClose={handleCloseForm}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
        handleAddSaleClick={handleAddSaleClick}
      />
      <AddEditBuilding
        open={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleSaveBuilding}
        selectedBuilding={selectedBuilding}
      />
    </>
  );
};

export default BuildingView;
