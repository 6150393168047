import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Title } from 'app/common/Typography';
import AddEditMembers from './MembersAddEdit';
import { useProject } from 'app/contexts/ProjectContext';
import LeadMemberDeleteConfirmation from './MemberDeleteConfirmation';
import MemberView from './MemberView';
import MembersTable from './MembersTable';
import { useRef, useReducer, useCallback, useMemo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useProjectMembers } from './hooks/useProjectMembers';
import { useProjectRoles } from '../Roles/hooks/useProjectRoles';
import { useServices } from 'app/hooks/useServices';
import { predefinedRoles } from 'app/utils/constant';
import { StyledAddButton, StyledIcon } from '../../listings/StyledComponent';
import { Card, IconButton } from '@mui/material';
import MembersFilter from './MembersFilter';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const SET_MENU_AND_MODAL = 'SET_MENU_AND_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  anchorEl: null,
  modalState: {
    view: false,
    add: false,
    edit: false,
    export: false,
    delete: false,
  },
};

const menuActions = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
};

const rolesParam = 'roles';

export default function MembersPage({ title }) {
  const { basic, projectId, isOnHoldOrCancelled } = useProject();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data: members } = useProjectMembers(projectId);
  const { data: services } = useServices();
  const { data: roles } = useProjectRoles(projectId, services);

  const selectedMemberRef = useRef(null);
  const isOwnerMember = selectedMemberRef.current?.userRoles.some(
    (role) => role === predefinedRoles.OWNER_ROLE,
  );

  const openModal = (action) => {
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: {
        modalState: { [action]: true },
      },
    });
  };

  const closeModal = (action) => {
    selectedMemberRef.current = null;
    dispatch({ type: CLOSE_MODAL, payload: action });
  };

  const handleMenuOpen = useCallback((event, member) => {
    selectedMemberRef.current = member;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: event.currentTarget, modalState: {} },
    });
  }, []);

  const handleMenuClose = () => {
    selectedMemberRef.current = null;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: null, modalState: {} },
    });
  };

  const availableRoles = useMemo(() => roles?.map((role) => role.name) || [], [roles]);

  const membersFilters = useMemo(() => {
    const rolesURLString = searchParams.get(rolesParam);
    const rolesArray = rolesURLString ? rolesURLString.split(',') : [];
    const validRoles = rolesArray.filter((role) => availableRoles.includes(role));

    return validRoles;
  }, [searchParams, availableRoles]);


  // will be uncommented once receive confirmation about filtering logic
  // const filteredMembers = useMemo(
  //   () =>
  //     membersFilters.length
  //       ? members.filter((member) =>
  //           membersFilters.some((filter) => member.userRoles.includes(filter)),
  //         )
  //       : members,
  //   [members, membersFilters],
  // );

  const filteredMembers = members?.filter((member) => {
    if (title === 'General Partners') {
      return member.userRoles.includes('OWNER_ROLE');
    }
    return !member.userRoles.includes('OWNER_ROLE');
  });

  const handleMemberClick = useCallback((member) => {
    selectedMemberRef.current = member;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { modalState: { view: true } },
    });
  }, []);

  useEffect(() => {
    if (filteredMembers.length === 0) {
      navigate('./propertyinformation');
    }
  }, [filteredMembers, navigate]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ px: 2, py: 1 }} elevation={0}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid
                item
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                gap={0.5}
                onClick={() => navigate('..')}
              >
                <IconButton sx={{ color: 'var(--backButton)' }}>
                  <KeyboardBackspaceIcon />
                </IconButton>
                <Title>{title}</Title>
              </Grid>
              <Grid item>
                <StyledAddButton startIcon={<StyledIcon />} onClick={() => openModal('add')}>
                  {title === 'Members' ? 'Member(s)' : 'General Partner(s)'}
                </StyledAddButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MembersFilter title={'Member'}/>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <MembersTable
            members={filteredMembers}
            isOnHoldOrCancelled={isOnHoldOrCancelled}
            handleMenuOpen={handleMenuOpen}
            onMemberClick={handleMemberClick}
          />
        </Grid>
      </Grid>

      <Menu anchorEl={state.anchorEl} open={Boolean(state.anchorEl)} onClose={handleMenuClose}>
        {Object.values(menuActions).map((action) => (
          <MenuItem
            key={action}
            onClick={() => openModal(action)}
            disabled={isOwnerMember && action === menuActions.DELETE}
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </MenuItem>
        ))}
      </Menu>

      {state.modalState.view && (
        <MemberView member={selectedMemberRef.current} closeModal={() => closeModal('view')} />
      )}

      {(state.modalState.add || state.modalState.edit) && (
        <AddEditMembers
          selectedMember={selectedMemberRef.current}
          roles={roles}
          projectMembers={members}
          closeModal={() => closeModal(state.modalState.add ? 'add' : 'edit')}
          title={title}
        />
      )}

      {state.modalState.delete && (
        <LeadMemberDeleteConfirmation
          member={selectedMemberRef.current}
          closeModal={() => closeModal('delete')}
        />
      )}
    </>
  );
}