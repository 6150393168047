import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteProjectDocument } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectDocumentDelete(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteProjectDocument,
    onMutate: (fileId) => {
      const previousDocuments = queryClient.getQueryData([
        ...queryKeys.project.documents,
        projectId,
      ]);

      queryClient.setQueryData([...queryKeys.project.documents, projectId], (oldData) =>
        oldData.filter((document) => document.metadata.fileId !== fileId),
      );

      return { previousDocuments };
    },
    onError: (_, __, { previousDocuments }) =>
      queryClient.setQueryData([...queryKeys.project.documents, projectId], previousDocuments),
  });
}
