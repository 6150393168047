import React, { useState } from 'react';
import { Autocomplete, Typography, IconButton, TextField, Grid, Input } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { documentTypes } from 'app/utils/constant';
import { DocIcon, PdfIcon } from 'app/common/icons';
import {
  FileDropArea,
  StyledDocumentBox,
} from './StyledComponents';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CustomTextInput,
  FileContainer,
  FileContainerDetails,
  FileDetails,
  ModalCancel,
  ModalDivider,
  ModalSave,
  ModalStyledBox,
  ModalStyledSubBox,
  StyledBoxFooter,
  Title,
} from 'app/common/Typography';
import { ImageContainer } from '../StyledComponent';
import { FieldArray, Formik, getIn } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProjectDocumentsUpload } from '../hooks/useProjectDocumentsUpload';
import { notify } from 'app/services/notify';
import { useLocation } from 'react-router-dom';

// const documentLabels = [
//   { label: documentTypes.DUE_DILIGENCE },
//   { label: documentTypes.CONTRACT },
//   { label: documentTypes.CLOSING },
// ];

const DocumentModal = ({ handleCloseModal, projectId }) => {
  const { mutate: uploadDocuments } = useProjectDocumentsUpload(projectId);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const isListingPage = location.pathname.includes('/listing');

  const documentLabels = isListingPage 
    ? [{ label: documentTypes.DUE_DILIGENCE }]
    : [
        { label: documentTypes.DUE_DILIGENCE },
        { label: documentTypes.CONTRACT },
        { label: documentTypes.CLOSING },
      ];
  const handleSave = (values) => {
    setIsLoading(true);
    if (values.documents.length === 0) {
      notify.error('Please upload at least one document!');
      setIsLoading(false);
      return;
    }

    uploadDocuments(values.documents, {
      onSuccess: () => {
        notify.success('Documents uploaded successfully!');
        setIsLoading(false);
        handleCloseModal();
      },
      onError: (error) => {
        setIsLoading(false);
        console.error('An error occurred while uploading the documents.');
      },
    });
  };

  const getFileIcon = (mimeType) => {
    switch (mimeType) {
      case 'application/pdf':
        return <PdfIcon />;
      case 'application/msword':
        return <DocIcon />;
      default:
        return <DocIcon />;
    }
  };

  return (
    <>
      <ModalStyledBox sx={{ width: '40%' }}>
        <ModalStyledSubBox>
          <Title>Add Document</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon style={{ fontSize: 20, color: 'var(--closeIcon)' }} />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
        <StyledDocumentBox>
          <Formik
            initialValues={{
              documents: [],
            }}
            validationSchema={validationSchemas.documents()}
            onSubmit={(values) => handleSave(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              validateForm,
            }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validateForm().then((errors) => {
                    if (Object.keys(errors).length > 0) {
                      notify.error('Document name and type are required!');
                    } else {
                      handleSubmit(e);
                    }
                  });
                }}
              >
                <FileDropArea>
                  <Input
                      id="file-upload"
                      type='file'
                      sx={{
                        display: 'none',
                        '& + label': {
                          display: document.file ? 'none' : 'block',
                          padding: document.file ? '0' : '8px',
                          cursor: 'pointer',
                        },
                      }}
                      size='small'
                      name='file'
                      onChange={(event) => {
                        const files = Array.from(event.target.files);
                        files.forEach((file) => {
                          setFieldValue('documents', [
                            ...values.documents,
                            { file, docName: '', type: '' },
                          ]);
                        });
                      }}
                      inputProps={{ accept: '.pdf, .doc, .docx' }}
                      validators={['required']}
                      errorMessages={['This field is required']}
                    />
                  <label htmlFor="file-upload">
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#282A2D',
                      }}
                    >
                      Drag and Drop or{' '}
                      <span style={{ textDecoration: 'underline' }}>Click To Upload</span>
                    </Typography>
                  </label>
                </FileDropArea>
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#282A2D',
                    marginLeft: '4%',
                    marginTop: '0.5rem',
                    marginBottom: '2rem',
                  }}
                >
                  Supported files: .pdf, .doc, .xls, .text | Max file size: 10MB
                </Typography>
                <FileContainerDetails>
                  <FieldArray name="documents">
                    {({ remove }) =>
                      values.documents.map((doc, index) => (
                        <FileContainer key={index}>
                          <ImageContainer>
                            {getFileIcon(doc.file.type)}
                            <FileDetails>
                              <Typography
                                variant="body1"
                                style={{ fontWeight: 'bold', marginLeft: '10px' }}
                              >
                                {doc.file.name}
                              </Typography>
                              <Typography variant="caption" style={{ marginLeft: '10px' }}>
                                {(doc.file.size / 1024).toFixed(2)} KB
                              </Typography>
                            </FileDetails>
                            <IconButton onClick={() => remove(index)}>
                              <DeleteIcon style={{ color: 'var(--deleteIcon)' }} />
                            </IconButton>
                          </ImageContainer>
                          <ModalDivider />
                          <Grid container spacing={2} m={0.3}>
                            <Grid item xs={6}>
                              <CustomTextInput
                                name={`documents[${index}].docName`}
                                placeholder="Document Name"
                                value={doc.docName}
                                onChange={handleChange}
                                error={Boolean(
                                  getIn(errors, `documents[${index}].docName`) &&
                                    getIn(touched, `documents[${index}].docName`)
                                )}
                                helperText={
                                  getIn(errors, `documents[${index}].docName`) &&
                                  getIn(touched, `documents[${index}].docName`)
                                    ? 'Document Name is required'
                                    : ''
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Autocomplete
                                size="small"
                                sx={{ width: '220px' }}
                                options={documentLabels}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                  setFieldValue(`documents[${index}].type`, newValue?.label || '');
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Document Type"
                                    className="textfiled"
                                    error={Boolean(
                                      getIn(errors, `documents[${index}].type`) &&
                                        getIn(touched, `documents[${index}].type`)
                                    )}
                                    helperText={
                                      getIn(errors, `documents[${index}].type`) &&
                                      getIn(touched, `documents[${index}].type`)
                                        ? 'Document Type is required'
                                        : ''
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </FileContainer>
                      ))
                    }
                  </FieldArray>
                </FileContainerDetails>
                
                <StyledBoxFooter sx={{ marginTop: '7rem' }}>
                  <ModalCancel variant="outlined" onClick={handleCloseModal}>
                    Cancel
                  </ModalCancel>
                  <ModalSave
                    type="submit"
                    loading={isLoading}
                  >
                    Save
                  </ModalSave>
                </StyledBoxFooter>
              </form>
            )}
          </Formik>
        </StyledDocumentBox>
      </ModalStyledBox>
    </>
  );
};

export default DocumentModal;
