import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  IconButton,
  styled,
  Grid,
  Button,
  Typography,
  InputLabel,
  Input,
  TextField,
} from '@mui/material';
import { CustomGCExpensesInputText } from 'app/common/Typography';
import { DownloadDragIcon, PdfIcon, DocIcon } from 'app/common/icons';
import notify from 'app/utils/notify';
import { Formik, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '4px',
});
const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

const ModalDivider = styled('div')({
  height: '0.1em',
  backgroundColor: '#D5D5DD',
});

const Inputlabel = styled('div')({
  marginBottom: '0.5em',
});
const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  // padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: '#F8F8F8',
  borderRadius: '4px',
  marginTop: '10px',
});
const FileContainerDetails = styled('div')({
  maxHeight: '20vh',
  overflowY: 'auto',
  width: '100%',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // marginLeft: '8px',
  flexGrow: 1,
});

const validationSchema = Yup.object().shape({
  changeOrderName: Yup.string().required('Change Order Name is required'),
  initialEstimate: Yup.number()
    .required('Initial Estimates is required')
    .positive('Estimates must be positive'),
  cost: Yup.number().required('cost is required').positive('cost must be positive'),
  documents: Yup.array().of(
    Yup.object().shape({
      file: Yup.mixed().required('Document is required'),
      docName: Yup.string().required('Document Name is required'),
    }),
  ),
});

const ChangeOrderDetailsModal = ({
  changeOrderDetails,
  handleCloseModal,
  gcCChangeOrders,
  gcChangeOrderIndex,
}) => {
  const { uploadDocuments, addGcChangeOrders, documents, updateChangeOrderDetails } = useProject();

  function handleFileChange(event, values, setFieldValue) {
    const files = Array.from(event.target.files);
    const updatedDocuments = [...values.documents, ...files.map((file) => ({ file, docName: '' }))];
    setFieldValue('documents', updatedDocuments);
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDeleteFile = (index, values, setFieldValue) => {
    const updatedDocuments = [...values.documents];
    updatedDocuments.splice(index, 1);
    setFieldValue('documents', updatedDocuments);
  };

  const handleDrop = (event, values, setFieldValue) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const updatedDocuments = [...values.documents, ...files.map((file) => ({ file, docName: '' }))];
    setFieldValue('documents', updatedDocuments);
  };

  function base64ToFile(base64String, fileName, mimeType) {
    const byteString = atob(base64String);
    const byteNumbers = new Array(byteString.length)
      .fill(null)
      .map((_, i) => byteString.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], fileName, { type: mimeType });
  }

  const initialValues = {
    changeOrderName: changeOrderDetails?.changeOrderName || '',
    initialEstimate: changeOrderDetails?.initialEstimate || '',
    cost: changeOrderDetails?.cost || '',
    documents: documents
      .filter((doc) => changeOrderDetails?.documentIds?.[0].includes(doc.metadata.fileId))
      .map((doc, index) => {
        const mimeType = doc?.metadata?.documentType || 'application/pdf';
        const extension = mimeType === 'application/pdf' ? 'pdf' : 'docx';
        return {
          file: base64ToFile(doc.content, `${doc.metadata.documentName}.${extension}`, mimeType),
          docName: doc.metadata.documentName || `Document ${index + 1}`,
          docContent: doc.content
        };
      }),
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (values.documents.length === 0) {
      notify.error('Please add at least one document.');
      setSubmitting(false);
      return;
    }
    const data = new FormData();
    const documentIds = [];

    for (let index = 0; index < values.documents.length; index++) {
      const doc = values.documents[index];
      const isExistingDocument = initialValues?.documents.some(
        (existingDoc) => existingDoc.docName === doc.docName && existingDoc.docContent === doc.docContent
      );

      if (!isExistingDocument && doc.file instanceof File) {
        data.append(`docReq[${index}].file`, doc.file);
        data.append(`docReq[${index}].docName`, doc.docName);
        data.append(`docReq[${index}].type`, 'ChangeOrderDetails documents');
      } else {
        // documentIds.push(expense?.paymentDetails[latestPaymentDetails - 1]?.paymentDocuments?.[index]);
        documentIds.push(changeOrderDetails?.documentIds?.[index]);
      }
    }

    try {
      if (data.has('docReq[0].file')) {
        const uploadResponse = await uploadDocuments(data);
        const uploadedDocumentIds = uploadResponse.data
          .match(/Document IDs: \[([^\]]+)\]/)[1]
          .split(', ');
        documentIds.push(...uploadedDocumentIds);
      }
      const changeOrderDetailsgc = {
        changeOrderName: values.changeOrderName,
        initialEstimate: values.initialEstimate,
        cost: values.cost,
        documentIds,
      };

      if (changeOrderDetails) {
        const updatedCOD = {
          changeOrderId: changeOrderDetails?.changeOrderId,
          ...changeOrderDetailsgc,
        };
        await updateChangeOrderDetails(
          gcCChangeOrders[gcChangeOrderIndex]?.projectId,
          gcCChangeOrders[gcChangeOrderIndex]?.id,
          updatedCOD,
        );
      } else {
        await addGcChangeOrders(
          gcCChangeOrders[gcChangeOrderIndex]?.projectId,
          gcCChangeOrders[gcChangeOrderIndex]?.id,
          changeOrderDetailsgc,
        );
      }
      handleCloseModal();
    } catch (error) {
      if(error.response.data.error){
        notify.error(error.response.data.errors[0].errorMessage);
      }else{
        notify.error(`Error in Manager Details: ${error.message}`);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{changeOrderDetails ? 'Edit' : 'Add'} Change Order Details</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: '#272937',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <Grid container sx={{ marginTop: '0.1em' }} spacing={1.5}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Change Order Name</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='changeOrderName'
                        value={values.changeOrderName}
                        onChange={handleChange}
                        placeholder='e.g. : John Doe'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.changeOrderName && errors.changeOrderName)}
                        helperText={touched.changeOrderName && errors.changeOrderName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Initial Estimates</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='number'
                        name='initialEstimate'
                        value={values.initialEstimate}
                        onChange={handleChange}
                        placeholder='e.g: $20000'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.initialEstimate && errors.initialEstimate)}
                        helperText={touched.initialEstimate && errors.initialEstimate}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={1.5}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>cost</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='number'
                        name='cost'
                        value={values.cost}
                        onChange={handleChange}
                        placeholder='e.g: $30000'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.cost && errors.cost)}
                        helperText={touched.cost && errors.cost}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px', marginLeft: '3%' }} container spacing={2}>
                    <Typography
                      style={{
                        fontSize: '15px',
                        fontWeight: '450',
                        marginTop: '0.8em',
                        marginBottom: '0.5em',
                      }}
                    >
                      Change Order Document
                    </Typography>
                    <Container
                      style={{ border: '2px dashed lightgray', height: '3em', width: '97%' }}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, values, setFieldValue)}
                    >
                      <Typography variant='body1' marginTop={1} color={'#64748B'} fontSize={'16px'}>
                        <DownloadDragIcon />
                        {` Drag and Drop file here or `}
                        <label htmlFor='outlined-button-file'>
                          <StyledButton
                            disabled={values.documents.length > 0}
                            variant='outlined'
                            component='span'
                          >
                            Browse files
                          </StyledButton>
                        </label>
                      </Typography>
                      <Input
                        id={`outlined-button-file`}
                        sx={{
                          display: 'none',
                        }}
                        type='file'
                        size='small'
                        name='file'
                        onChange={(e) => handleFileChange(e, values, setFieldValue)}
                        inputProps={{ accept: '.pdf, .doc, .docx' }}
                        error={Boolean(
                          getIn(errors, `documents[0].file`) && getIn(touched, `documents[0].file`),
                        )}
                        helperText={
                          getIn(errors, `documents[0].file`) && getIn(touched, `documents[0].file`)
                            ? 'Document is required'
                            : ''
                        }
                      />
                    </Container>
                    <Typography variant='body1' color={'#64748B'} fontSize={'12px'} pt={1}>
                      {`File format only word or Pdf, Size should not exceed 10MB`}
                    </Typography>
                  </Grid>
                  <FileContainerDetails>
                    <FieldArray name='documents'>
                      {({ remove }) =>
                        values.documents.map((doc, index) => (
                          <FileContainer key={index}>
                            <ImageContainer>
                              {doc.file.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                              <FileDetails>
                                <Typography
                                  variant='body1'
                                  style={{ fontWeight: 'bold', marginLeft: '10px' }}
                                >
                                  {doc.file.name}
                                </Typography>
                                <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                  {(doc.file.size / 1024).toFixed(2)} KB
                                </Typography>
                              </FileDetails>
                              <IconButton
                                onClick={() => handleDeleteFile(index, values, setFieldValue)}
                              >
                                <DeleteIcon style={{ color: 'red' }} />
                              </IconButton>
                            </ImageContainer>
                            <ModalDivider />
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <CustomGCExpensesInputText
                                  name={`documents[${index}].docName`}
                                  placeholder='Document Name'
                                  touched={touched}
                                  value={doc.docName}
                                  onChange={handleChange}
                                  error={Boolean(
                                    getIn(errors, `documents[${index}].docName`) &&
                                      getIn(touched, `documents[${index}].docName`),
                                  )}
                                  helperText={
                                    getIn(errors, `documents[${index}].docName`) &&
                                    getIn(touched, `documents[${index}].docName`)
                                      ? 'Document Name is required'
                                      : ''
                                  }
                                />
                              </Grid>
                            </Grid>
                          </FileContainer>
                        ))
                      }
                    </FieldArray>
                  </FileContainerDetails>
                </Container>
                <Box
                  marginTop={1.5}
                  sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <LoadingButton
                    className='cancelButton'
                    color='error'
                    variant='outlined'
                    onClick={handleCloseModal}
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    style={{ boxShadow: 'none', marginRight: '0.1em' }}
                    type='submit'
                    loading={isSubmitting}
                  >
                    {changeOrderDetails ? 'Update' : 'Save'}
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default ChangeOrderDetailsModal;
