import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useProjectDocumentsUpload } from '../../Documents/hooks/useProjectDocumentsUpload';
import { addProjectGCChangeOrder, updateProjectGCChangeOrder } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export default function useProjectGCChangeOrderAddUpdate(contractor, selectedChangeOrder) {
  const queryClient = useQueryClient();
  // const { mutateAsync: uploadDocument } = useProjectDocumentsUpload(contractor?.projectId);

  const isEditingMode = !!selectedChangeOrder;

  return useMutation({
    mutationFn: async ({ data, isDocumentSameAsPrevious, previousDocumentID }) => {
      const documentIds = [];

      // if (!isDocumentSameAsPrevious) {
      //   const response = await uploadDocument(data.documents);
      //   const parsedDocumentID = response.match(/Document IDs: \[([^\]]+)\]/)[1];

      //   documentIds.push(parsedDocumentID);
      // } else {
      //   documentIds.push(previousDocumentID);
      // }

      const payloadData = {
        ...data,
        documentIds,
      };

      return isEditingMode
        ? await updateProjectGCChangeOrder(contractor?.projectId, contractor?.id, {
            ...payloadData,
            changeOrderId: selectedChangeOrder?.changeOrderId,
          })
        : await addProjectGCChangeOrder(contractor?.projectId, contractor?.id, payloadData);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...queryKeys.project.generalContractor, contractor?.projectId],
      }),
  });
}
