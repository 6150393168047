import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import notify from 'app/utils/notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '0.1em',
  backgroundColor: '#D5D5DD',
});

const Inputlabel = styled('div')({
  marginBottom: '0.5em',
});

const validationSchema = Yup.object().shape({
  withdrawalAmount: Yup.number().required('Withdrawal Amount is required').min(0, 'Withdrawal Amount must be Positive or at least 0'),
  invoiceNumber: Yup.string().required('Invoice Number is required'),
  paidDate: Yup.date().required('PaidDate is required').typeError('Invalid Date formate'),
  paidTo: Yup.string().required('PaidTo is required'),
});

const LoanWithdrawalModal = ({
  loandetails,
  handleCloseModal,
  clLoanWithdrawlDetailsIndex,
  clLoanWithdrawlDetails,
  formatDate,
}) => {
  const { addCLLoanWithdrawal, updateCLLoanWithdrawals } = useProject();

  const initialValues = {
    balanceLoanAmount: clLoanWithdrawlDetails[clLoanWithdrawlDetailsIndex]?.loanBalance,
    withdrawalAmount: loandetails?.withdrawalAmount !== null && loandetails?.withdrawalAmount !== undefined
      ? loandetails?.withdrawalAmount
      : '',
    invoiceNumber: loandetails?.invoiceNumber || '',
    paidDate: loandetails?.paidDate || '',
    paidTo: loandetails?.paidTo || '',
  };

  const convertToUTC = (date) => {
    const localDate = new Date(date);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    return utcDate.toISOString();
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    const loandetailscl = {
      withdrawalAmount: values.withdrawalAmount,
      invoiceNumber: values.invoiceNumber,
      paidDate: convertToUTC(values.paidDate),
      paidTo: values.paidTo,
    };
    try {
      if (loandetails) {
        const upDatedLoanData = {
          withdrawalId: loandetails?.withdrawalId,
          ...loandetailscl,
        };
        await updateCLLoanWithdrawals(
          clLoanWithdrawlDetails[clLoanWithdrawlDetailsIndex]?.projectId,
          clLoanWithdrawlDetails[clLoanWithdrawlDetailsIndex]?.id,
          upDatedLoanData,
        );
      } else {
        await addCLLoanWithdrawal(
          clLoanWithdrawlDetails[clLoanWithdrawlDetailsIndex]?.projectId,
          clLoanWithdrawlDetails[clLoanWithdrawlDetailsIndex]?.id,
          loandetailscl,
        );
      }
      handleCloseModal();
    } catch (error) {
      if (error.response.data.error) {
        notify.error(error.response.data.errors[0].errorMessage);
      } else {
        notify.error(`Error in Construction Lender: ${error.message}`);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{loandetails ? 'Edit' : 'Add'} Loan Withdrawal Details</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: '#272937',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize={true}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              handleBlur,
              setValues,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <Grid container sx={{ marginTop: '0.1em' }} spacing={1.5}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Loan Available Amount</InputLabel>
                      </Inputlabel>
                      <TextField
                        name='balanceLoanAmount'
                        value={values.balanceLoanAmount}
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                          readOnly: true,
                          sx: {
                            fontWeight: 'bold',
                            backgroundColor: '#f0f0f0f0',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Withdrawal Amount</InputLabel>
                      </Inputlabel>
                      <TextField
                        name='withdrawalAmount'
                        value={values.withdrawalAmount}
                        onChange={handleChange}
                        InputProps={{
                          readOnly: Boolean(loandetails?.balance)||loandetails?.balance===0,
                          sx: Boolean(loandetails?.balance)||loandetails?.balance===0
                            ? {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                            : {},
                        }}
                        placeholder='e.g. : 20000'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.withdrawalAmount && errors.withdrawalAmount)}
                        helperText={touched.withdrawalAmount && errors.withdrawalAmount}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={1.5}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Invoice No</InputLabel>
                      </Inputlabel>
                      <TextField
                        name='invoiceNumber'
                        value={values.invoiceNumber}
                        onChange={handleChange}
                        placeholder='e.g: INC1234'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                        helperText={touched.invoiceNumber && errors.invoiceNumber}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Inputlabel>
                          <InputLabel>Paid Date</InputLabel>
                        </Inputlabel>
                        <DatePicker
                          name='paidDate'
                          value={dayjs(values.paidDate)}
                          onChange={(newvalue) => setFieldValue('paidDate', newvalue)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              fullWidth: true,
                              variant: 'outlined',
                              error: Boolean(errors.paidDate && touched.paidDate),
                              helperText: touched.paidDate && errors.paidDate,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={1.5}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Paid To</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='paidTo'
                        value={values.paidTo}
                        onChange={handleChange}
                        placeholder='e.g: John'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.paidTo && errors.paidTo)}
                        helperText={touched.paidTo && errors.paidTo}
                      />
                    </Grid>
                  </Grid>
                </Container>
                <Box
                  marginTop={1.5}
                  sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <LoadingButton
                    className='cancelButton'
                    color='error'
                    variant='outlined'
                    onClick={handleCloseModal}
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    style={{ boxShadow: 'none', marginRight: '0.1em' }}
                    type='submit'
                    loading={isSubmitting}
                  >
                    {loandetails ? 'Update' : 'Save'}
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default LoanWithdrawalModal;
