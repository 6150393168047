import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useRef, useReducer, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useServices } from 'app/hooks/useServices';
import { predefinedRoles } from 'app/utils/constant';
import { Card, IconButton } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TableFilter from 'app/common/TableFilter';
import ReferralTable from './ReferralTable';

const SET_MENU_AND_MODAL = 'SET_MENU_AND_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  anchorEl: null,
  modalState: {
    view: false,
    add: false,
    edit: false,
    export: false,
    delete: false,
  },
};

const menuActions = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
};

export default function ReferralTab({ title }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const { data: services } = useServices();

  const selectedMemberRef = useRef(null);
  const isOwnerMember = selectedMemberRef.current?.userRoles.some(
    (role) => role === predefinedRoles.OWNER_ROLE,
  );

  const openModal = (action) => {
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: {
        modalState: { [action]: true },
      },
    });
  };


  const handleMenuOpen = useCallback((event, member) => {
    selectedMemberRef.current = member;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: event.currentTarget, modalState: {} },
    });
  }, []);

  const handleMenuClose = () => {
    selectedMemberRef.current = null;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: null, modalState: {} },
    });
  };

  const handleMemberClick = useCallback((member) => {
    selectedMemberRef.current = member;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { modalState: { view: true } },
    });
  }, []);

  const dummyMembers = [
    {
      id: 1,
      memberName: 'John Doe',
      phone: '123-456-7890',
      email: 'johndoe@example.com',
      address: '123 Main St, Cityville',
      status: 'Active',
    },
    {
      id: 2,
      memberName: 'Jane Smith',
      phone: '987-654-3210',
      email: 'janesmith@example.com',
      address: '456 Elm St, Townsville',
      status: 'Pending',
    },
    {
      id: 3,
      memberName: 'Michael Johnson',
      phone: '555-678-1234',
      email: 'michaelj@example.com',
      address: '789 Oak St, Villageton',
      status: 'Inactive',
    },
  ];
  
  return (
    <>
      <Grid container spacing={2} sx={{ px: 4 }}>
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: '#F9F9F9' }} elevation={0}>
            <Grid container justifyContent='space-between' alignItems='center' p={1}>
              <Grid item>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableFilter title={'Referral'}/>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ReferralTable
            members={dummyMembers}
            // isOnHoldOrCancelled={isOnHoldOrCancelled}
            handleMenuOpen={handleMenuOpen}
            onMemberClick={handleMemberClick}
          />
        </Grid>
      </Grid>

      <Menu anchorEl={state.anchorEl} open={Boolean(state.anchorEl)} onClose={handleMenuClose}>
        {Object.values(menuActions).map((action) => (
          <MenuItem
            key={action}
            onClick={() => openModal(action)}
            disabled={isOwnerMember && action === menuActions.DELETE}
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </MenuItem>
        ))}
      </Menu>

      {/* {state.modalState.view && (
        <MemberView member={selectedMemberRef.current} closeModal={() => closeModal('view')} />
      )} */}

      {/* {(state.modalState.add || state.modalState.edit) && (
        <AddEditMembers
          selectedMember={selectedMemberRef.current}
          roles={roles}
          projectMembers={members}
          closeModal={() => closeModal(state.modalState.add ? 'add' : 'edit')}
          title={title}
        />
      )}

      {state.modalState.delete && (
        <LeadMemberDeleteConfirmation
          member={selectedMemberRef.current}
          closeModal={() => closeModal('delete')}
        />
      )} */}
    </>
  );
}