import React, { useState } from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LCDeleteIcon } from 'app/common/icons';
import {
  StyledModalContainer,
  DeleteIcon,
  MainImageContainer,
  MainImage,
  ThumbnailsContainer,
  ThumbnailImage,
  ImagesStyledCross,
} from './StyledComponents';
import { useParams } from 'react-router-dom';
import { useProjectDocumentDelete } from '../hooks/useProjectDocumentDelete';
import LeadDeleteConfirmation from 'app/common/LeadsDeleteConfirmation';

const ImagesViewModal = ({ handleCloseImageModal, imageDoc }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { projectId } = useParams();

  const { mutate: deleteDocument, isPending: isDeleting } = useProjectDocumentDelete(projectId);

  // Convert Base64 content to proper image URLs
  const imageUrls = imageDoc
    .filter((doc) => doc.metadata.documentType.startsWith('image/')) // Ensure it's an image
    .map((doc) => ({
      src: `data:${doc.metadata.documentType};base64,${doc.content}`,
      name: doc.metadata.documentName || 'Image',
      metadata: doc.metadata, // Keep original document metadata
    }));

  // Default to the first image
  const [selectedImage, setSelectedImage] = useState(
    imageUrls.length > 0 ? imageUrls[0] : null
  );

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleOpenDeleteModal = (document) => {
    setSelectedDocument(document); // Set the full document
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedDocument(null);
  };

  const handleDelete = () => {
    if (selectedDocument) {
      deleteDocument(selectedDocument.metadata.fileId);
      handleCloseDeleteModal();
    }
  };

  return (
    <>
      <StyledModalContainer>
        {/* Close Button */}
        <ImagesStyledCross onClick={handleCloseImageModal}>
          <CloseIcon />
        </ImagesStyledCross>

        {/* Main Image Display */}
        <MainImageContainer>
          {selectedImage ? (
            <MainImage src={selectedImage.src} alt={selectedImage.name} />
          ) : (
            <p>No images available</p>
          )}
          {selectedImage && (
            <DeleteIcon onClick={() => handleOpenDeleteModal(selectedImage)}>
              <LCDeleteIcon />
            </DeleteIcon>
          )}
        </MainImageContainer>

        {/* Thumbnails */}
        <ThumbnailsContainer>
          {imageUrls.length > 0 ? (
            imageUrls.map((image, index) => (
              <Box key={index} onClick={() => handleImageClick(image)}>
                <ThumbnailImage
                  src={image.src}
                  alt={image.name}
                  isSelected={image.src === selectedImage?.src}
                />
              </Box>
            ))
          ) : (
            <p>No thumbnails available</p>
          )}
        </ThumbnailsContainer>
      </StyledModalContainer>

      {/* Delete Confirmation Modal */}
      <LeadDeleteConfirmation
        name={`${selectedDocument?.name || ''} Document`}
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        handleSubmit={handleDelete}
      />
    </>
  );
};

export default ImagesViewModal;
