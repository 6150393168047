import React, { useState } from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Container,
  Table,
  Button,
  Modal,
  Icon,
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span } from 'app/common/Typography';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
import PMContactPersonModal from './PMContactPersonModal';
import UpdateIcon from '@mui/icons-material/Update';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const PMContactPersonDetails = ({ pmContactPerson, pmContactPersonIndex }) => {
  const [open, setOpen] = useState(false);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);

  const handleClickOpen = () => {
    setSelectedContactPerson(null);
    setOpen(true);
  };
  const handleClickUpdate = (manager) => {
    setSelectedContactPerson(manager);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <>
      <SubtitleDivider />
      {!pmContactPerson[pmContactPersonIndex]?.contactPersons || pmContactPerson[pmContactPersonIndex].contactPersons.length === 0 ?(
        <>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>Contact Person Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }} >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }} variant="body1" color="#272937" gutterBottom>
                  No details added
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It seems you have not added any details related to Contact Person Details.
                </Typography>
                <Button onClick={handleClickOpen} variant="contained" color="primary">
                  Add Details
                </Button>
              </Box>
            </Container>
            <Modal open={open} onClose={handleClose}>
              <PMContactPersonModal contactPersonDetails={selectedContactPerson} handleCloseModal={handleClose} pmContactPerson={pmContactPerson} pmContactPersonIndex={pmContactPersonIndex} />
            </Modal>
          </Box>
        </>) : (
          <>
            <Box
              marginLeft={3}
              marginRight={3}
              marginTop={1}
              justifyContent={'space-between'}
              display={'flex'}
              alignItems={'center'}
            >
              <Title sx={{ marginLeft: '0px' }}>Contact Person Details</Title>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Button style={{ color: '#4F90F0', fontSize: '14px' }} onClick={handleClickOpen}>
                  + ADD
                </Button>
              </Box>
            </Box>
            <Modal open={open} onClose={handleClose}>
              <PMContactPersonModal contactPersonDetails={selectedContactPerson} handleCloseModal={handleClose} pmContactPerson={pmContactPerson} pmContactPersonIndex={pmContactPersonIndex} />
            </Modal>
            <Box
              marginLeft={3}
              marginRight={3}
              marginTop={1}
              marginBottom={1}
              overflow='auto'
              border={'2px solid #E5E5E7'}
            >
              <ProductTable marginLeft={3} marginRight={3}>
                <TableHead bgcolor='#F2F9FC'>
                  <TableRow color='#64748B'>
                    <TableCell sx={{ px: 2, color: '#64748B' }}>S.NO.</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>CONTACT PERSON</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>EMAIL ADDRESS</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>PHONE NUMBER</TableCell>
                    <TableCell sx={{ px: 0, width: '5%', color: '#64748B' }}></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {pmContactPerson[pmContactPersonIndex].contactPersons?.map((GCMdetails, index) => (
                    <TableRow hover key={GCMdetails.id}>
                      <TableCell
                        align='left'
                        sx={{
                          px: 2,
                          textTransform: 'capitalize',
                          fontWeight: 500,
                          fontSize: '14px',
                          color: '#272937',
                        }}
                      >
                        {`${(index + 1).toString().padStart(2, '0')}`}
                      </TableCell>

                      <TableCell align='left' sx={{ px: 0, textTransform: 'capitalize' }}>
                        {GCMdetails.name}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {GCMdetails.email}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {GCMdetails.phoneNumber}
                      </TableCell>
                      <TableCell sx={{ color: '#272937' }} align='right'>
                        <SideNavMenu
                          menuButton={
                            <UserMenu>
                              <MoreVertIcon />
                            </UserMenu>
                          }
                        >
                          <StyledItem onClick={() => handleClickUpdate(GCMdetails)}>
                            <Icon>
                              <UpdateIcon />
                            </Icon>
                            <Span>Update</Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </ProductTable>
            </Box>
          </>
        )}
    </>
  )
}

export default PMContactPersonDetails
