import { TableHead, TableRow, TableCell, TableBody, Icon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Span, StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import React, { useRef } from 'react';
import { SideNavMenu } from 'app/components';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import CLLoanPaymentAddEdit from './CLLoanPaymentAddEdit';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { dateFormats } from 'app/utils/constant';
import UpdateIcon from '@mui/icons-material/Update';

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell sx={{ px: 2, color: 'var(--tableHead-row-color)' }}>S.No.</TableCell>
      <TableCell>Loan Monthly Income</TableCell>
      <TableCell>Loan Payment Date</TableCell>
      <TableCell>Paid By</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function CLLoanPaymentsTable({ loanPayments, lender }) {

  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
    const selectedPayment = useRef(null);
  
    const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);
  
    const handleClickUpdate = (payment) => {
      selectedPayment.current = payment;
      toggleDialog();
    };

  return (
    <>
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {loanPayments &&
            loanPayments.map((payment, index) => {
              return (
                <>
                  <StyledTableRow key={payment.id}>
                  <TableCell
                      align='left'
                      sx={{
                        px: 2,
                        textTransform: 'capitalize',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                    >
                      {`${(index + 1).toString().padStart(2, '0')}`}
                    </TableCell>
                    <TableCell>{payment.loanMonthlyPayment}</TableCell>
                    <TableCell>  {convertUTCDateToLocal(payment?.loanPayment, dateFormats.short)}</TableCell>
                    <TableCell>{payment.paidBy}</TableCell>
                    <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
                      <SideNavMenu
                        menuButton={
                          <UserMenu>
                            <MoreVertIcon />
                          </UserMenu>
                        }
                      >
                        <StyledItem onClick={() => handleClickUpdate(payment)}>
                          <Icon>
                            <UpdateIcon />
                          </Icon>
                          <Span>Update</Span>
                        </StyledItem>
                      </SideNavMenu>
                    </TableCell>
                  </StyledTableRow>
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
    {isOpenDialog && (
        <CLLoanPaymentAddEdit
          lender={lender}
          payment={selectedPayment.current}
          closeDialog={toggleDialog}
        />
      )}
    </>
  );
}

export default CLLoanPaymentsTable;
