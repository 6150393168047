import React, { useState } from "react";
import { Modal, Typography, Grid, IconButton, Box, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Title } from "app/common/Typography";
import LeadSaleForm from './LeadSaleForm';

const SectionTitle = styled(Typography)(({ theme }) => ({
    height: '37px',
    backgroundColor: '#E3ECFF',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    fontSize: '14px',
    fontWeight: '500',
}));

const ModalDivider = styled('div')({
    height: '1px',
    backgroundColor: '#D5D5DD',
});

const LeadSaleDetailModel = ({ open, onClose, initialVallues, buyerDetails, onSave, selectedunitIndex, buildingIndex, saleIndex }) => {
    const [editMode, setEditMode] = useState(false);

    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleFormClose = () => {
        setEditMode(false);
        // onClose(); // Close the modal when form is closed
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        width: '48%',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            justifyContent: 'space-between',
                            display: 'flex',
                        }}
                    >
                        <Title>Sale Details View</Title>
                        <IconButton onClick={onClose}>
                            <CloseIcon style={{ fontSize: 18, color: '#272937' }} />
                        </IconButton>
                    </Box>
                    <ModalDivider />
                    <Box container spacing={2} p={1}>
                        {/* Sale Space and Edit */}
                        <Grid mb={2} p={1} item xs={12} sx={{ border: '1px solid #DFDFE7', height: '93px', marginTop: '10px', backgroundColor: '#FCFEFF' }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">
                                    Sale Space: <strong>{initialVallues?.saleSpace} Sq fts</strong>
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <Typography sx={{ marginRight: 2, color: '#4F90F0', fontSize: '14px', cursor: 'pointer' }} onClick={handleEditClick}>
                                        <BorderColorOutlinedIcon fontSize="14px" /> Edit
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="left">
                                <Typography fontSize='14px' fontWeight='500' variant="body2" color="#5C5D64">
                                    {initialVallues?.salePrice === 'INR' ? '₹' : '$'}
                                    {initialVallues?.salePrice} price • {initialVallues?.buyerSaleCommission} Buyer Sale Commission • {initialVallues?.sellerSaleCommission} Seller Sale Commission
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container xs={12} mb={2} sx={{ border: '1px solid #DFDFE7', height: 'auto', backgroundColor: '#FCFEFF' }}>
                                                <Grid item xs={12}>
                                <SectionTitle>Sale Details:</SectionTitle>
                                <Box display="flex" p={1} justifyContent="space-between">
                                    <Box>
                                        <Typography color="#64748B" fontWeight="500" variant="body2">
                                            Sale Price
                                        </Typography>
                                        <Typography variant="body2">
                                            {initialVallues?.salePrice === 'INR' ? '₹' : '$'}
                                            {initialVallues?.salePrice || 'N/A'}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <Typography color="#64748B" fontWeight="500" variant="body2">
                                            Buyer Commission
                                        </Typography>
                                        <Typography variant="body2">
                                            {initialVallues?.buyerSaleCommission || 'N/A'}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="#64748B" fontWeight="500" variant="body2">
                                            Seller Commission
                                        </Typography>
                                        <Typography variant="body2">
                                            {initialVallues?.sellerSaleCommission || 'N/A'}
                                        </Typography>
                                    </Box>                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <SectionTitle>Realtor Details:</SectionTitle>
                                <Box display="flex" p={1} justifyContent="space-between">
                                    <Box>
                                        <Typography color="#64748B" fontWeight="500" variant="body2">
                                            Buyer Realtor
                                        </Typography>
                                        <Typography variant="body2">{initialVallues?.buyerRealtor || 'N/A'}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="#64748B" fontWeight="500" variant="body2">
                                            Unit No
                                        </Typography>
                                        <Typography variant="body2">{initialVallues?.unitNo || 'N/A'}</Typography>
                                    </Box>   
                                    <Box>
                                        <Typography color="#64748B" fontWeight="500" variant="body2">
                                            Closing Cost
                                        </Typography>
                                        <Typography variant="body2">{initialVallues?.closingCost || 'N/A'}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="#64748B" fontWeight="500" variant="body2">
                                        TitlePolicy Cost
                                        </Typography>
                                        <Typography variant="body2">{initialVallues?.titlePolicyCost || 'N/A'}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <SectionTitle>Comments:</SectionTitle>
                                <Typography p={1} variant="body2" fontWeight="500" color="#64748B">
                                    {initialVallues?.comments || 'No Comments Added'}
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Buyer and Contact Person Details */}
                        <Grid container spacing={2} mt={2} p={2} sx={{ border: '1px solid #DFDFE7', backgroundColor: '#FCFEFF' }}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" fontSize='14px' color='#6D718A' fontWeight='500'>BUYER DETAILS</Typography>
                                <Typography fontSize='14px'><strong>{buyerDetails?.buyerName || 'N/A'}</strong></Typography>
                                <Typography fontSize='14px'>{buyerDetails?.phoneNo} • {buyerDetails?.email}</Typography>
                                <Typography variant="body2">{buyerDetails?.address || 'No Address'} • {buyerDetails?.city}• {buyerDetails?.state}</Typography>
                                <Typography variant="body2">{buyerDetails?.country } • {buyerDetails?.zipCode}</Typography>

                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="subtitle2" fontSize='14px' color='#6D718A' fontWeight='500'>CONTACT PERSON</Typography>
                                <Typography fontSize='14px'><strong>{buyerDetails?.contactPerson || 'N/A'}</strong></Typography>
                                <Typography fontSize='14px'>{buyerDetails?.phoneNo} • {buyerDetails?.email}</Typography>
                                <Typography variant="body2">{buyerDetails?.address || 'No Address'} • {buyerDetails?.city}• {buyerDetails?.state}</Typography>
                                <Typography variant="body2">{buyerDetails?.country } • {buyerDetails?.zipCode}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>

            {/* Show the form in edit mode */}
            {editMode && (
                <LeadSaleForm
                    open={editMode}
                    handleCancel={() => setEditMode(false)}
                    onClose={handleFormClose}
                    initialValues={initialVallues}
                    buyerDetails={buyerDetails}
                    onSave={onSave}
                    selectedunitIndex={selectedunitIndex}
                    buildingIndex={buildingIndex}
                    saleIndex={saleIndex}
                />
            )}
        </>
    );
};

export default LeadSaleDetailModel;
