// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .StyledPaper {
    display: flex;
    align-items: center;
    height: 37px;
    border: 1px solid lightgrey;
  }
  
  .Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 4px;
    margin-top: 10px;
    border: 2px dashed lightgray;
  }
  
  .ImageContainer {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 8px;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .FileDetails {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    flex-grow: 1;
  }
  
  .StyledButton {
    margin: 8px;
    align-items: center;
    justify-content: center;
  }
  
  .ContentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
  }
  
  .noleads {
    color: var(--text-primary-color);
  }
  
  .noleadssub {
    color: var(--text-secondary-color);
    font-weight: bold;
  }
  
  .ProjectCardContainer {
    flex: 0 0 33%;
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/app/views/dashboard/leads/NewLeads/styles.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;EAC7B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;EAC9B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,kCAAkC;IAClC,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,eAAe;EACjB","sourcesContent":["  .StyledPaper {\r\n    display: flex;\r\n    align-items: center;\r\n    height: 37px;\r\n    border: 1px solid lightgrey;\r\n  }\r\n  \r\n  .Container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 20px;\r\n    border-radius: 4px;\r\n    margin-top: 10px;\r\n    border: 2px dashed lightgray;\r\n  }\r\n  \r\n  .ImageContainer {\r\n    display: flex;\r\n    align-items: center;\r\n    height: 60px;\r\n    padding: 8px;\r\n    border: 1px solid lightgray;\r\n    border-radius: 4px;\r\n    margin-top: 10px;\r\n  }\r\n  \r\n  .FileDetails {\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin-left: 8px;\r\n    flex-grow: 1;\r\n  }\r\n  \r\n  .StyledButton {\r\n    margin: 8px;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .ContentBox {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    min-height: 80vh;\r\n  }\r\n  \r\n  .noleads {\r\n    color: var(--text-primary-color);\r\n  }\r\n  \r\n  .noleadssub {\r\n    color: var(--text-secondary-color);\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .ProjectCardContainer {\r\n    flex: 0 0 33%;\r\n    box-sizing: border-box;\r\n    padding: 0;\r\n    cursor: pointer;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
