import React, { useMemo, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Divider,
  Grid,
} from '@mui/material';
import { StyledIcon } from 'app/common/Typography';
import { Title } from 'app/common/Typography';
import GCChangeOrderAddEdit from './GCChangeOrderAddEdit';
import { useProject } from 'app/contexts/ProjectContext';
import { documentTypes } from 'app/utils/constant';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments';
import { StledCardTitle, StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';
import GCChangeOrderViewTable from './GCChangeOrderViewTable';

const GCChangeOrderView = ({ contractor }) => {
  const { projectId } = useProject();
  const { data: documents } = useProjectDocuments(projectId);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const selectedChangeOrder = useRef(null);

  const changeOrderDocuments = useMemo(
    () => documents?.filter((doc) => doc.metadata.type === documentTypes.GC_CHANGE_ORDERS),
    [documents],
  );

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleClickOpen = () => {
    selectedChangeOrder.current = null;
    toggleDialog();
  };

  return (
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <>
          {(!contractor?.changeOrders || (Array.isArray(contractor?.changeOrders) && contractor?.changeOrders.length === 0)) && (
            <>
              <Box
                sx={{ margin: '20px 38px 20px 20px' }}
                display='flex'
                justifyContent='space-between'
              >
                <StledCardTitle>Change Order Details</StledCardTitle>
                <StyledAddButton startIcon={<StyledIcon />} onClick={toggleDialog}>
                Change Order
                </StyledAddButton>
              </Box>
              <Divider />
            </>
          )}

          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {!contractor?.changeOrders  || !Array.isArray(contractor?.changeOrders) || contractor?.changeOrders.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Change Order Details Available
                </Typography>
              </div>
            ) : (
            <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid
                        item
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Title>Change Order Details</Title>
                      </Grid>
                      <Grid item>
                        <StyledAddButton startIcon={<StyledIcon />} onClick={handleClickOpen}>
                          Change Order
                        </StyledAddButton>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TableFilter title={'Change Order Details'} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <GCChangeOrderViewTable
                      contractor={contractor}
                      changeorders={contractor?.changeOrders}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </>
      </Card>
      {isOpenDialog && (
        <GCChangeOrderAddEdit
          contractor={contractor}
          changeOrder={selectedChangeOrder.current}
          changeOrderDocuments={changeOrderDocuments}
          closeDialog={toggleDialog}
        />
      )}
    </>
    // <>
    //   {contractor?.changeOrders.length === 0 ? (
    //     <>
    //       <Box>
    //         <Title sx={{ marginLeft: '2%' }}>Change Order Details</Title>
    //         <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }}>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               flexDirection: 'column',
    //               alignItems: 'center',
    //               justifyContent: 'center',
    //               height: '150px',
    //               borderRadius: '8px',
    //               float: 'center',
    //               textAlign: 'center',
    //             }}
    //           >
    //             <Typography
    //               sx={{ fontWeight: '600' }}
    //               variant='body1'
    //               color='var(--noDetails-text)'
    //               gutterBottom
    //             >
    //               No details added
    //             </Typography>
    //             <Typography variant='body2' color='textSecondary' paragraph>
    //               It seems you have not added any details related to Change Order Details.
    //             </Typography>
    //             <Button onClick={handleClickOpen} variant='contained' color='primary' sx={{textTransform:'none'}}>
    //               Add Change Order
    //             </Button>
    //           </Box>
    //         </Container>
    //       </Box>
    //     </>
    //   ) : (
    //     <>
    //       <Box
    //         marginLeft={3}
    //         marginRight={3}
    //         marginTop={1}
    //         marginBottom={1}
    //         justifyContent={'space-between'}
    //         display={'flex'}
    //         alignItems={'center'}
    //       >
    //         <Title sx={{ marginLeft: '0px' }}>Change Order Details</Title>
    //         <Box display='flex' justifyContent='space-between' alignItems='center'>
    //           <Button onClick={handleClickOpen}>+ ADD</Button>
    //         </Box>
    //       </Box>
    //       <Box
    //         overflow='auto'
    //         border={'2px solid #E5E5E7'}
    //         marginLeft={3}
    //         marginRight={3}
    //         marginBottom={1}
    //       >
    //         <ProductTable marginLeft={3} marginRight={3}>
    //           <TableHead style={{ backgroundColor: 'var(--tableHead-bg-color)' }}>
    //             <TableRow color='var(--tableHead-row-color)'>
    //               <TableCell sx={{ px: 2, color: 'var(--tableHead-row-color)' }}>S.NO.</TableCell>
    //               <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
    //                 CHANGE ORDER NAME
    //               </TableCell>
    //               <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
    //                 INITIAL ESTIMATES
    //               </TableCell>
    //               <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>COST</TableCell>
    //               <TableCell sx={{ px: 0, color: 'var(--tableHead-row-color)' }}>
    //                 DOCUMENT
    //               </TableCell>
    //               <TableCell
    //                 sx={{ px: 0, width: '5%', color: 'var(--tableHead-row-color)' }}
    //               ></TableCell>
    //             </TableRow>
    //           </TableHead>

    //           <TableBody>
    //             {contractor?.changeOrders.map((changeOrder, index) => (
    //               <TableRow hover>
    //                 <TableCell
    //                   align='left'
    //                   sx={{
    //                     px: 2,
    //                     textTransform: 'capitalize',
    //                     fontWeight: 500,
    //                     fontSize: '14px',
    //                     color: 'var(--tableBody-row-color)',
    //                   }}
    //                 >
    //                   {`${(index + 1).toString().padStart(2, '0')}`}
    //                 </TableCell>

    //                 <TableCell
    //                   align='left'
    //                   sx={{
    //                     px: 0,
    //                     fontWeight: 500,
    //                     fontSize: '14px',
    //                     color: 'var(--tableBody-row-color)',
    //                   }}
    //                 >
    //                   {changeOrder?.changeOrderName}
    //                 </TableCell>

    //                 <TableCell
    //                   sx={{
    //                     px: 0,
    //                     fontWeight: 500,
    //                     fontSize: '14px',
    //                     color: 'var(--tableBody-row-color)',
    //                   }}
    //                   align='left'
    //                 >
    //                   {changeOrder?.initialEstimate}
    //                 </TableCell>

    //                 <TableCell
    //                   sx={{
    //                     px: 0,
    //                     fontWeight: 500,
    //                     fontSize: '14px',
    //                     color: 'var(--tableBody-row-color)',
    //                   }}
    //                   align='left'
    //                 >
    //                   {changeOrder?.cost}
    //                 </TableCell>
    //                 <TableCell
    //                   sx={{
    //                     px: 0,
    //                     fontWeight: 500,
    //                     fontSize: '14px',
    //                     color: 'var(--tableBody-row-color)',
    //                     display: 'flex',
    //                     marginTop: '1%',
    //                   }}
    //                   align='left'
    //                 >
    //                   <div style={{ marginTop: '2%' }}>
    //                     {changeOrderDocuments
    //                       ?.filter((doc) =>
    //                         changeOrder?.documentIds[0]?.includes(doc.metadata.fileId),
    //                       )
    //                       .map((doc) => (
    //                         <div key={doc.metadata.fileId}>{doc.metadata.documentName}</div>
    //                       ))}
    //                   </div>
    //                   <IconButton
    //                     onClick={() =>
    //                       handleChangeOrderDetailsdownload(changeOrderDocuments, changeOrder)
    //                     }
    //                   >
    //                     <DownloadIcon />
    //                   </IconButton>
    //                 </TableCell>
    //                 <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
    //                   <SideNavMenu
    //                     menuButton={
    //                       <UserMenu>
    //                         <MoreVertIcon />
    //                       </UserMenu>
    //                     }
    //                   >
    //                     <StyledItem onClick={() => handleClickUpdate(changeOrder)}>
    //                       <Icon>
    //                         <UpdateIcon />
    //                       </Icon>
    //                       <Span>Update</Span>
    //                     </StyledItem>
    //                   </SideNavMenu>
    //                 </TableCell>
    //               </TableRow>
    //             ))}
    //           </TableBody>
    //         </ProductTable>
    //       </Box>
    //     </>
    //   )}
    //   {isOpenDialog && (
    //     <GCChangeOrderAddEdit
    //       contractor={contractor}
    //       changeOrder={selectedChangeOrder.current}
    //       changeOrderDocuments={changeOrderDocuments}
    //       closeDialog={toggleDialog}
    //     />
    //   )}
    // </>
  );
};

export default GCChangeOrderView;
