import React, { useState } from 'react';
import { 
  Box, Button, Dialog, DialogActions, DialogContent, 
  DialogTitle, TextField, styled 
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useProject } from 'app/contexts/ProjectContext';
import { LoadingButton } from '@mui/lab';
import { handleAxiosError } from 'app/utils/helpers';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '130px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: '#4F90F0',
});

const LeadCompanyDetails = () => {
  const { 
    isOnHoldOrCancelled, 
    closing, 
    updateClosing
  } = useProject();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false); 

  const [formValues, setFormValues] = useState({
    companyName: closing?.companyDetails?.companyName || '',
    contactPerson: closing?.companyDetails?.contactPerson || '',
    contactPersonNumber: closing?.companyDetails?.contactPersonNumber || '',
    contactPersonEmail: closing?.companyDetails?.contactPersonEmail || '',
  });

  const [tempFormValues, setTempFormValues] = useState({ ...formValues });

  // Error states for validation
  const [errors, setErrors] = useState({
    contactPersonNumber: '',
    contactPersonEmail: '',
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTempFormValues({ ...formValues });
    setErrors({ contactPersonNumber: '', contactPersonEmail: '' }); // Reset errors
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validations
    if (name === 'contactPersonNumber') {
      const isValidNumber = /^[0-9]*$/.test(value);
      setErrors((prev) => ({
        ...prev,
        contactPersonNumber: isValidNumber ? '' : 'Please enter a valid number',
      }));
    }

    if (name === 'contactPersonEmail') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setErrors((prev) => ({
        ...prev,
        contactPersonEmail: isValidEmail ? '' : 'Please enter a valid email',
      }));
    }

    setTempFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    // Final validation check before saving
    if (errors.contactPersonNumber || errors.contactPersonEmail) {
      return;
    }

    setLoading(true); 
    try {
      const updatedFormData = {
        companyDetails: tempFormValues,
        purchasePrice: closing.purchasePrice,
        closingCost: closing.closingCost,
        closingDate: closing.closingDate,
        id: closing.id,
        lenderDetails: closing.lenderDetails,
      };

      await updateClosing(updatedFormData);
      setFormValues(tempFormValues); // Update formValues only after save
      setSuccessOpen(true); 
      setOpen(false); 
    } catch (error) {
      handleAxiosError(error); 
    } finally {
      setLoading(false); 
    }
  };

  const infoPairs = [
    { key: 'TITLE COMPANY NAME', value: formValues.companyName },
    { key: 'CONTACT PERSON', value: formValues.contactPerson },
    { key: 'CONTACT NUMBER', value: formValues.contactPersonNumber },
    { key: 'EMAIL ADDRESS', value: formValues.contactPersonEmail },
  ];

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title>Title Company Details :</Title>
            <Button
              onClick={handleOpen}
              style={{ color: '#4F90F0', fontSize: '14px' }}
              disabled={isOnHoldOrCancelled}
            >
              <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
            </Button>
          </Box>
          <InfoContainer item lg={6} md={6} sm={12} xs={12}>
            {infoPairs.map(({ key, value }, index) => (
              <Box key={index}>
                <Key>{key}</Key>
                <ValueContainer>
                  <Value className='basicvalues'>{value}</Value>
                </ValueContainer>
              </Box>
            ))}
          </InfoContainer>
        </Box>
      </div>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Edit Company Details</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="companyName"
            label="Title Company Name"
            fullWidth
            value={tempFormValues.companyName}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            margin="dense"
            name="contactPerson"
            label="Contact Person"
            fullWidth
            value={tempFormValues.contactPerson}
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            margin="dense"
            name="contactPersonNumber"
            label="Contact Number"
            fullWidth
            value={tempFormValues.contactPersonNumber}
            onChange={handleChange}
            variant="outlined"
            error={!!errors.contactPersonNumber}
            helperText={errors.contactPersonNumber}
          />
          <TextField
            margin="dense"
            name="contactPersonEmail"
            label="Email Address"
            fullWidth
            value={tempFormValues.contactPersonEmail}
            onChange={handleChange}
            variant="outlined"
            error={!!errors.contactPersonEmail}
            helperText={errors.contactPersonEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error" disabled={loading}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSave}
            color="primary"
            loading={loading}
            variant="outlined"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeadCompanyDetails;
