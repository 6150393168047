import NewLead from './leads/NewLeads/NewLead';
import ProjectDetails from './leads/ProjectDetails';
import Profile from './profile/Profile';
import ProjectProvider from 'app/contexts/ProjectContext';
import RequestsLayout from 'app/components/Layout/Requests/RequestsLayout';
import Loadable from 'app/common/Loadable';
import Projects from './leads/Projects';
import { lazy } from 'react';

const RequestsProvider = Loadable(lazy(() => import('app/contexts/RequestsContext')));

const dashboardRoutes = [
  { path: 'newlead', element: <NewLead /> },

  { index: true, element: <Projects type='lead' /> },
  { path: 'newproject', element: <Projects type='newproject' /> },
  { path: 'ongoing', element: <Projects type='ongoing' /> },
  { path: 'cancelled', element: <Projects type='cancelled' /> },
  { path: 'onhold', element: <Projects type='onhold' /> },
  { path: 'completed', element: <Projects type='completed' /> },
  {
    path: 'project/:projectId',
    element: (
      <ProjectProvider>
        <ProjectDetails />
      </ProjectProvider>
    ),
  },
  {
    path: 'requests',
    element: (
      <RequestsProvider>
        <RequestsLayout />
      </RequestsProvider>
    ),
  },
  { path: 'profile', element: <Profile /> },
];

export default dashboardRoutes;
