import { Title } from 'app/common/Typography';
import { Box, Grid, IconButton, Modal, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import LeadsSuccess from './LeadsSuccess';
import { useState } from 'react';
import { useProject } from 'app/contexts/ProjectContext';
import { delay, handleAxiosError } from 'app/utils/helpers';
import notify from 'app/utils/notify';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

function LeadRestore({ openModal, handleCancel }) {
  const {
    basic: { projectTitle },
    previousProjectType,
    projectMoveToPreviousType,
  } = useProject();
  // const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const previousTypes = {
    lead: 'Lead',
    newproject: 'New Project',
    ongoing: 'On-Going',
  };

  const handleYesProceedClick = async () => {
    try {
      setLoading(true);
      await projectMoveToPreviousType();
      notify.success(
        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
          Project successfully moved to
          <Grid>{`the ${previousTypes[previousProjectType]} Project`}</Grid>
        </Typography>
      );
      // setSuccessModalOpen(true);
      // await delay(5000);
      // setSuccessModalOpen(false);
      handleCancel();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SubtitleDivider />
      <Modal open={openModal} onClose={handleCancel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '32%',
          }}
        >
          <Box
            sx={{
              p: 2,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>{previousTypes[previousProjectType]}</Title>
            <IconButton onClick={handleCancel}>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: '#272937',
                }}
              />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box
            sx={{
              marginLeft: '4px',
            }}
          >
            <Box sx={{ p: 2, paddingLeft: 3, paddingRight: 3 }}>
              <Typography style={{ fontSize: '16px', fontWeight: '450' }}>
                {`Are you sure you want to restore ${projectTitle} to ${previousTypes[previousProjectType]}?`}
              </Typography>
            </Box>

            <ModalDivider />
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                p: 2,
              }}
            >
              <LoadingButton
                color='error'
                sx={{ height: '40px', mt: 0.5 }}
                variant='outlined'
                onClick={handleCancel}
                style={{ boxShadow: 'none' }}
                disabled={loading}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                onClick={handleYesProceedClick}
                style={{ boxShadow: 'none' }}
                loading={loading}
              >
                Restore
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* <LeadsSuccess
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        to={previousTypes[previousProjectType]}
      /> */}
    </>
  );
}

export default LeadRestore;
