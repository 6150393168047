import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteProjectMember } from 'app/contexts/projectActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProjectMemberDelete(projectId) {
  const queryClient = useQueryClient();

  return useMutation({
    onMutate: (memberData) => {
      const previousMembers = queryClient.getQueryData([...queryKeys.project.members, projectId]);

      queryClient.setQueryData([...queryKeys.project.members, projectId], (old) => {
        return old.filter((member) => member.userId !== memberData.userId);
      });

      return { previousMembers };
    },
    mutationFn: (memberData) => deleteProjectMember(projectId, memberData),
    onError: (_, { previousMembers }) =>
      queryClient.setQueryData([...queryKeys.project.members, projectId], previousMembers),
  });
}
