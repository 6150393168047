import React from 'react';
import {
  Box,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Container,
  Button,
  Modal,
  Icon,
} from '@mui/material';
import { SideNavMenu } from 'app/components';
import { Span } from 'app/common/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Title } from 'app/common/Typography';
// import { DownloadIcon } from 'app/common/icons';
import LoanWithdrawalModal from './LoanWithdrawalModal';
// import { useLead } from 'app/contexts/LeadContext';


const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const UserMenu = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 24,
  margin: 10,
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 185,
  '& a': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  '& span': { marginRight: '10px', color: theme.palette.text.primary },
}));

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
}));

const LoanWithDrawalDetails = ({ changeOrderData, clLoanwithdrawlIndex }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedOrderChanges, setSelectedOrderChanges] = React.useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const handleClickOpen = () => {
    setSelectedOrderChanges(null);
    setOpen(true);
  };



  const handleClose = () => {
    setOpen(false);
  };

  const handleClickUpdate = (changeOrder) => {
    setSelectedOrderChanges(changeOrder);
    setOpen(true);
  };



  return (
    <>
      <SubtitleDivider />
      {
        changeOrderData[clLoanwithdrawlIndex]?.loanWithdrawal.length === 0 ? (<>
          <Box>
            <Title sx={{ marginLeft: '2%' }}>Loan Withdrawal Details</Title>
            <Container maxWidth={{ xs: 'sm', md: 'md', lg: 'lg' }} >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '150px',
                  borderRadius: '8px',
                  float: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography sx={{ fontWeight: '600' }} variant="body1" color="#272937" gutterBottom>
                  No details added
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  It seems you have not added any details related to Loan Withdrawal Details.
                </Typography>
                <Button onClick={handleClickOpen} variant="contained" color="primary">
                  Add Details
                </Button>
              </Box>
            </Container>
            <Modal open={open} onClose={handleClose}>
              <LoanWithdrawalModal clLoanWithdrawlDetails={changeOrderData} clLoanWithdrawlDetailsIndex={clLoanwithdrawlIndex} handleCloseModal={handleClose} loandetails={selectedOrderChanges} formatDate={formatDate} />
            </Modal>
          </Box>
        </>) : (
          <>
            <Box
              marginLeft={3}
              marginRight={3}
              marginTop={1}
              marginBottom={1}
              justifyContent={'space-between'}
              display={'flex'}
              alignItems={'center'}
            >
              <Title sx={{ marginLeft: '0px' }}>Loan Withdrawal Details</Title>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Button disabled={changeOrderData[clLoanwithdrawlIndex]?.loanBalance === 0} sx={{
                  
                  color: changeOrderData[clLoanwithdrawlIndex]?.loanBalance === 0 ? '#d1d1d1': "primary",
                }} onClick={handleClickOpen}>+ ADD</Button>
              </Box>
            </Box>
            <Modal open={open} onClose={handleClose}>
              <LoanWithdrawalModal clLoanWithdrawlDetails={changeOrderData} clLoanWithdrawlDetailsIndex={clLoanwithdrawlIndex} handleCloseModal={handleClose} loandetails={selectedOrderChanges} formatDate={formatDate} />
            </Modal>
            <Box
              overflow='auto' border={'2px solid #E5E5E7'}
              marginLeft={3}
              marginRight={3}
              marginBottom={1}
            >
              <ProductTable marginLeft={3} marginRight={3}>
                <TableHead bgcolor='#F2F9FC'>
                  <TableRow color='#64748B' >
                    <TableCell sx={{ px: 2, color: '#64748B' }}>S.NO.</TableCell>
                    <TableCell sx={{ px: 2, color: '#64748B' }}>LOAN AVAILABLE AMOUNT</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>LOAN WITHDRAWAL AMOUNT</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>INVOICE NO</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>PAID DATE</TableCell>
                    <TableCell sx={{ px: 0, color: '#64748B' }}>PAID TO</TableCell>
                    <TableCell sx={{ px: 0, width: '5%', color: '#64748B' }}></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {changeOrderData[clLoanwithdrawlIndex]?.loanWithdrawal?.map((changeOrder, index) => (
                    <TableRow hover>
                      <TableCell
                        align='left'
                        sx={{
                          px: 2,
                          textTransform: 'capitalize',
                          fontWeight: 500,
                          fontSize: '14px',
                          color: '#272937',
                        }}
                      >
                        {`${(index + 1).toString().padStart(2, '0')}`}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {changeOrder?.balance}
                      </TableCell>

                      <TableCell align='left' sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}>
                        {changeOrder?.withdrawalAmount}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {changeOrder?.invoiceNumber}
                      </TableCell>

                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {formatDate(changeOrder?.paidDate)}
                      </TableCell>
                      <TableCell
                        sx={{ px: 0, fontWeight: 500, fontSize: '14px', color: '#272937' }}
                        align='left'
                      >
                        {changeOrder?.paidTo}
                      </TableCell>
                      <TableCell
                        sx={{ color: '#272937' }}
                        align='right'
                      >
                        <SideNavMenu menuButton={<UserMenu>
                          <MoreVertIcon />
                        </UserMenu>}>
                          <StyledItem onClick={() => handleClickUpdate(changeOrder)}>
                            <Icon><UpdateIcon /></Icon>
                            <Span>Update</Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

              </ProductTable>
            </Box>
          </>
        )
      }
    </>
  )
}

export default LoanWithDrawalDetails
