import React, { useRef } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import { StandaloneSearchBox } from '@react-google-maps/api';
import * as Yup from 'yup';
import { handleAxiosError, parseAddressComponents } from 'app/utils/helpers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import styles from './ProfileEdit.module.css';
import useUserAuth from 'app/hooks/userUserAuth';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Name can only contain letters and spaces!')
    .required('First name is required!'),
  surname: Yup.string()
    .matches(/^[a-zA-Z]+$/, 'Last Name can only contain letters!')
    .required('Last Name is required!'),
  dob: Yup.date()
    .required('Date of Birth is required!')
    .nullable()
    .test('dob', 'You must be at least 18 years old', (value) => {
      return dayjs().diff(dayjs(value), 'year') >= 18;
    }),
  phone: Yup.string()
    .matches(/^\+\d{10,12}$/, 'Please specify country code with valid mobile number (10-12 digits)')
    .required('Mobile number is required!'),
  email: Yup.string()
    .required('Email Address is required!')
    .test('email', 'Invalid Email Address', function (value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }),
  address1: Yup.string().required('Address1 is required!'),
  country: Yup.string().required('Country is required!'),
  city: Yup.string().required('City is required!'),
  state: Yup.string().required('State is required!'),
  zipCode: Yup.string().required('Zip Code is required!'),
});

const ProfileEdit = ({ isOpened, closeModal }) => {
  const inputRef = useRef();
  const { user, userEditDetails } = useUserAuth();
  const initialValues = {
    name: '',
    surname: '',
    dob: null,
    phone: '',
    email: '',
    address1: '',
    address2: '',
    country: '',
    city: '',
    state: '',
    zipCode: '',
    ...user,
  };

  const handlePlaceChanged = (setFormValues) => {
    const [place] = inputRef.current.getPlaces(); // BUG: can`t await for getting place (sync task)
    const addressData = parseAddressComponents(place);
    setFormValues((prevValues) => ({
      ...prevValues,
      ...addressData,
    }));
  };

  const handleSubmit = async (values) => {
    try {
      await userEditDetails(values);
      closeModal();
    } catch (error) {
      handleAxiosError(error);
    }
  };

  return (
    <Dialog open={isOpened} onClose={closeModal}>
      <DialogTitle margin='normal'>Edit Profile</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          setValues,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent className={styles.dialogContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size='small'
                        label='First Name'
                        type='name'
                        name='name'
                        variant='outlined'
                        onBlur={handleBlur}
                        value={values.name}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        helperText={touched.name && errors.name}
                        error={Boolean(errors.name && touched.name)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size='small'
                        label='Last Name'
                        type='surname'
                        name='surname'
                        variant='outlined'
                        value={values.surname}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={touched.surname && Boolean(errors.surname)}
                        helperText={touched.surname && errors.surname}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size='small'
                        label='Email Address'
                        type='email'
                        name='email'
                        variant='outlined'
                        onBlur={handleBlur}
                        value={values.email}
                        disabled={true}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                        error={Boolean(errors.email && touched.email)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size='small'
                        type='tel'
                        name='phone'
                        label='Mobile Number'
                        variant='outlined'
                        onBlur={handleBlur}
                        value={values.phone}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        helperText={touched.phone && errors.phone}
                        error={Boolean(errors.phone && touched.phone)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '10%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                          }}
                        >
                          <DatePicker
                            name='dob'
                            label='Date of Birth'
                            value={dayjs(values.dob)}
                            disabled={isSubmitting}
                            onChange={(value) => {
                              handleChange({
                                target: { name: 'dob', value },
                              });
                            }}
                            onBlur={handleBlur}
                            slotProps={{
                              textField: {
                                size: 'small',
                                error: Boolean(errors.dob && touched.dob),
                                helperText: touched.dob && errors.dob,
                                fullWidth: true,
                              },
                            }}
                            sx={{ width: '100%' }}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ height: '100%', borderColor: 'black', marginRight: '17px' }}
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StandaloneSearchBox
                        onLoad={(ref) => (inputRef.current = ref)}
                        onPlacesChanged={() => handlePlaceChanged(setValues)}
                      >
                        <TextField
                          fullWidth
                          type='search'
                          name='address1'
                          size='small'
                          label='Address 1'
                          id='standard-basic'
                          placeholder='Search address'
                          variant='outlined'
                          value={values.address1}
                          disabled={isSubmitting}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            const { value } = e.target;
                            handleChange(e);
                            if (!value)
                              setValues({
                                ...values,
                                address1: '',
                                address2: '',
                                country: '',
                                city: '',
                                state: '',
                                zipCode: '',
                                latitude: '',
                                longitude: '',
                              });
                          }}
                          error={Boolean(errors.address1 && touched.address1)}
                          helperText={touched.address1 && errors.address1}
                        />
                      </StandaloneSearchBox>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size='small'
                        type='address'
                        name='address2'
                        label='Address 2 (optional)'
                        variant='outlined'
                        value={values.address2}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size='small'
                        name='city'
                        label='City'
                        variant='outlined'
                        value={values.city}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.city && errors.city}
                        error={Boolean(errors.city && touched.city)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size='small'
                        name='state'
                        label='State'
                        variant='outlined'
                        value={values.state}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.state && errors.state}
                        error={Boolean(errors.state && touched.state)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size='small'
                        name='zipCode'
                        label='Zipcode'
                        variant='outlined'
                        value={values.zipCode}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.zipCode && errors.zipCode}
                        error={Boolean(errors.zipCode && touched.zipCode)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size='small'
                        name='country'
                        label='Country'
                        variant='outlined'
                        value={values.country}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        helperText={touched.country && errors.country}
                        error={Boolean(errors.country && touched.country)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <DialogActions className={styles.dialogActions}>
                <LoadingButton variant='outlined' onClick={closeModal} disabled={isSubmitting}>
                  Cancel
                </LoadingButton>
                <LoadingButton
                  type='submit'
                  color='primary'
                  variant='outlined'
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </DialogContent>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ProfileEdit;
