import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { ChangeIcon } from 'app/common/icons';
import ChangeOrgModal from './Modals/ChangeOrgModal';
import {
  StyledEditIcon,
  StledCardTitle,
  StyledGrid,
  OrgSubHeading,
  OrgSubValue,
  OrgMainHeading,
  IconDivider,
  OrgSec,
  StyledIcon,
  StyledAddButton,
} from './StyledComponent';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectLLC } from '../LeadsData/LLC/hooks/useProjectLLC';

const OrganizationSection = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { data: llc } = useProjectLLC(projectId);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const formatLLCAddress = () => {
    const { address1, address2, city, state, zipCode, country } = llc;
    const addressParts = [address1, address2, city, state, country, zipCode];
    return addressParts.filter((part) => part).join(' \n');
  };

  const address = llc ? formatLLCAddress() : '';

  // Check if llc data is available or not
  const hasLLCData = llc && (llc.llcName || llc.einNum || address);

  return (
    <>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{ marginTop: '1.25rem' }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent='space-between'
          alignItems='center'
          sx={{ marginLeft: '22px', marginRight: '1.375rem', marginBottom: '1.375rem' }}
        >
          <StledCardTitle>Organization</StledCardTitle>
          {hasLLCData ? (
            <StyledEditIcon
              variant='outlined'
              size='small'
              startIcon={<ChangeIcon />}
              onClick={handleOpenModal}
            >
              Change Organization
            </StyledEditIcon>
          ) : (
            <StyledAddButton startIcon={<StyledIcon />} onClick={handleOpenModal}>
              Organization
            </StyledAddButton>
          )}
        </Grid>
        <Modal open={openModal} onClose={handleCloseModal}>
          <ChangeOrgModal handleCloseModal={handleCloseModal} assignedLLC={llc} />
        </Modal>

        <IconDivider orientation='vertical' />

        {/* Conditional rendering for displaying organization details */}
        {hasLLCData ? (
          <>
            <StyledGrid item xs={12}>
              <OrgMainHeading>{llc?.llcName}</OrgMainHeading>
            </StyledGrid>

            <StyledGrid item xs={12}>
              <OrgSubHeading>EIN/License Number</OrgSubHeading>
              <OrgSubValue>{llc?.einNum}</OrgSubValue>
            </StyledGrid>

            <StyledGrid item xs={12} marginBottom={1.6}>
              <OrgSubHeading>Address</OrgSubHeading>
              <OrgSubValue>{address}</OrgSubValue>
            </StyledGrid>
          </>
        ) : (
          <Grid container justifyContent='center' alignItems='center' sx={{ height: '200px' }}>
            <Typography variant='h6' color='textSecondary'>
              No Organization Added
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default OrganizationSection;
