import notify from './notify';

export const parseAddressComponents = (place) => {
  let address1 = '';
  let address2 = '';
  let country = '';
  let city = '';
  let state = '';
  let zipCode = '';
  let latitude = null;
  let longitude = null;

  if (place.address_components) {
    const {
      address_components,
      geometry: { location },
      name,
    } = place;
    const { lat, lng } = location;
    latitude = lat();
    longitude = lng();

    address_components.forEach((component) => {
      const [type] = component.types;
      const longName = component.long_name;

      switch (type) {
        case 'landmark':
        case 'subpremise':
        case 'premise':
          address2 += address2 ? `, ${longName}` : longName;
          break;
        case 'route':
        case 'street_address':
        case 'street_number':
          address1 += address1 ? `, ${longName}` : longName;
          break;
        case 'country':
          country = longName;
          break;
        case 'locality':
        case 'postal_town':
        case 'administrative_area_level_3':
          city ||= longName;
          break;
        case 'administrative_area_level_1':
          state = longName;
          break;
        case 'postal_code':
          zipCode = longName;
          break;
        default:
          break;
      }
    });

    if (!address1 && name) {
      address1 = name;
    }
  }
  return {
    address1,
    address2,
    country,
    city,
    state,
    zipCode,
    latitude,
    longitude,
  };
};

export function handleAxiosError(error) {
  let errorMessage;

  switch (true) {
    case !!error.response: {
      const { status, data } = error.response;
      errorMessage = `Error ${status}: ${
        data?.message || data?.errors?.[0]?.errorMessage || 'Something went wrong on the server.'
      }`;
      break;
    }
    case !!error.request:
      errorMessage = 'No response from the server. Please check your internet connection.';
      break;
    default:
      errorMessage = `Request Error: ${error.message}`;
      break;
  }

  notify.error(errorMessage);
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// export const formatCurrency = (amount) => `$${(amount || 0).toFixed(2)}`;

export function convertUTCDateToLocalDate(date) {
  const inputDate = new Date(date);
  const newDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60 * 1000);
  return newDate;
}

export const formatDecimal = (value, decimalPlaces) => {
  const floatValue = parseFloat(value);

  if (isNaN(floatValue) || !value) {
    return '';
  }

  const [integer, decimal] = floatValue.toString().split('.');

  if (decimal) {
    return `${integer}.${decimal.slice(0, decimalPlaces)}`;
  }

  return integer;
}

export function convertToSystemLocalTime(date) {
  const inputDate = new Date(date);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');

  let hours = inputDate.getHours();
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  const formattedHours = String(hours).padStart(2, '0');
  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
}

export const formatCurrency = (amount) => {
  const num = parseFloat(amount); // Convert to number
  if (isNaN(num)) return '0.00'; // Handle invalid numbers gracefully
  return num.toFixed(2); // Ensure two decimal places
};
