import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useRef, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useProjectRoles } from '../Roles/hooks/useProjectRoles';
import { useServices } from 'app/hooks/useServices';
import { predefinedRoles } from 'app/utils/constant';
import { Box, Card, IconButton, Paper, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TableFilter from 'app/common/TableFilter';
import EditInvestments from './EditInvestments';
import { useProjectMembers } from '../Members/hooks/useProjectMembers';
import InvestmentsSection from './InvestmentsSection';
import MyInvestmentsSection from './MyInvestments';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const SET_MENU_AND_MODAL = 'SET_MENU_AND_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  anchorEl: null,
  modalState: {
    view: false,
    add: false,
    edit: false,
    export: false,
    delete: false,
  },
};

const menuActions = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
};

export default function InvestmentPage({ title }) {
  const { projectId } = useProject();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const { data: members } = useProjectMembers(projectId);
  const { data: services } = useServices();
  const { data: roles } = useProjectRoles(projectId, services);

  const selectedMemberRef = useRef(null);
  const isOwnerMember = selectedMemberRef.current?.userRoles.some(
    (role) => role === predefinedRoles.OWNER_ROLE,
  );

  const openModal = (action) => {
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: {
        modalState: { [action]: true },
      },
    });
  };

  const closeModal = (action) => {
    selectedMemberRef.current = null;
    dispatch({ type: CLOSE_MODAL, payload: action });
  };

  const handleMenuClose = () => {
    selectedMemberRef.current = null;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: null, modalState: {} },
    });
  };

  return (
    <>
      <Box style={{ padding: '20px' }}>
        <Typography variant='h6' style={{ marginTop: '3px', marginBottom: '15px' }}>
          <span style={{ cursor: 'pointer' }}>
            <ArrowBackIcon
              sx={{ marginBottom: '-4px', width: '5%' }}
              onClick={() => navigate('..')}
            />
          </span>
          {'Invesments'}
        </Typography>

        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12} md={6}>
            <Paper sx={{ height: '24.3125rem', marginTop: 2, border: '1px solid #DADCE0' }}>
              <InvestmentsSection />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ height: '24.3125rem', marginTop: 2, border: '1px solid #DADCE0' }}>
              <MyInvestmentsSection />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <Card sx={{ px: 2, py: 1 }} elevation={0}>
              <Grid container justifyContent='space-between' alignItems='center'>
                <Grid
                  item
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  gap={0.5}
                  onClick={() => navigate('..')}
                >
                  {/* <IconButton sx={{ color: 'var(--backButton)' }}>
                    <KeyboardBackspaceIcon />
                  </IconButton> */}
                  <Title>Investors</Title>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableFilter title={'Investor'} />
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {/* <MembersTable
            members={filteredMembers}
            isOnHoldOrCancelled={isOnHoldOrCancelled}
            handleMenuOpen={handleMenuOpen}
            onMemberClick={handleMemberClick}
          /> */}
            <Box
              style={{
                flex: 1,
                padding: '20px',
                marginTop: '5px',
                marginBottom: '5px',
                overflowX: 'auto',
              }}
            >
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Investment Available
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>

        <Menu anchorEl={state.anchorEl} open={Boolean(state.anchorEl)} onClose={handleMenuClose}>
          {Object.values(menuActions).map((action) => (
            <MenuItem
              key={action}
              onClick={() => openModal(action)}
              disabled={isOwnerMember && action === menuActions.DELETE}
            >
              {action.charAt(0).toUpperCase() + action.slice(1)}
            </MenuItem>
          ))}
        </Menu>

        {(state.modalState.add || state.modalState.edit) && (
          <EditInvestments
            selectedMember={selectedMemberRef.current}
            roles={roles}
            projectMembers={members}
            closeModal={() => closeModal(state.modalState.add ? 'add' : 'edit')}
            title={title}
          />
        )}
      </Box>
    </>
  );
}
