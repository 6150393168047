import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { StyledIcon, Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { useCallback, useReducer, useRef } from 'react';
import AmenitiesTable from './AmenitiesTable';
import RoleAddEdit from './AmenitiesAddEdit';
import RoleDeleteConfirmation from './AmenitiesDeleteConfirmation';
// import { useProjectRoles } from './hooks/useProjectRoles';
import { predefinedRoles } from 'app/utils/constant';
import RoleView from './AmenitiesView';
import { useUserAuth } from 'app/hooks/userUserAuth';
import { Card } from '@mui/material';
import { StyledAddButton } from '../../listings/StyledComponent';
import TableFilter from 'app/common/TableFilter';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const SET_MENU_AND_MODAL = 'SET_MENU_AND_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

// TODO: chopstxx - custom hook for modal state (members section also)
const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  anchorEl: null,
  modalState: {
    view: false,
    add: false,
    edit: false,
    delete: false,
  },
};


// Dummy Data
const dummyRoles = [
  {
    name: 'Role 1',
    createdBy: 'Admin',
    services: [
      ['service1', { displayName: 'Service 1' }],
    ],
    subtypes: [
      { name: 'Sub Type 1', price: '$100' },
      { name: 'Sub Type 2', price: '$150' },
    ],
  },
  {
    name: 'Role 2',
    createdBy: 'Admin',
    services: [
      ['service3', { displayName: 'Service 3' }],
    ],
    subtypes: [
      { name: 'Sub Type A', price: '$200' },
      { name: 'Sub Type B', price: '$250' },
    ],
  },
];


const menuActions = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
};

function AmenitiesSection() {
  const { isOnHoldOrCancelled, projectId } = useProject();
  const { services } = useUserAuth();
//   const { data: roles } = useProjectRoles(projectId, services);

  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedRoleRef = useRef(null);

  const openModal = (action) => {
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: {
        modalState: { [action]: true },
      },
    });
  };

  const closeModal = (action) => {
    selectedRoleRef.current = null;
    dispatch({ type: CLOSE_MODAL, payload: action });
  };

  const handleMenuOpen = useCallback((event, role) => {
    selectedRoleRef.current = role;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: event.currentTarget, modalState: {} },
    });
  }, []);

  const handleMenuClose = () => {
    selectedRoleRef.current = null;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { anchorEl: null, modalState: {} },
    });
  };

  return (
    // <>
    //   {/* <SubtitleDivider /> */}
    //   <Grid
    //     container
    //     alignItems='center'
    //     justifyContent='space-between'
    //     spacing={2}
    //     sx={{ px: 3, py: 1 }}
    //   >
    //     <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
    //       <Grid
    //         container
    //         spacing={2}
    //         alignItems='center'
    //         sx={{ justifyContent: { xs: 'center', sm: 'center', md: 'flex-start' } }}
    //       >
    //         <Grid item>
    //           <Title>Amenities</Title>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //     <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
    //       <Grid
    //         container
    //         alignItems='center'
    //         spacing={2}
    //         justifyContent='flex-end'
    //         sx={{ justifyContent: { xs: 'center', sm: 'center', md: 'flex-end' } }}
    //       >
    //         <Grid item>
    //           <LoadingButton
    //             color='primary'
    //             variant='contained'
    //             sx={{ boxShadow: 'none', textTransform: 'none' }}
    //             onClick={() => openModal('add')}
    //             disabled={isOnHoldOrCancelled}

    //           >
    //             + Add Amenities
    //           </LoadingButton>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //   <RolesTable
    //     roles={dummyRoles}
    //     isOnHoldOrCancelled={isOnHoldOrCancelled}
    //     handleMenuOpen={handleMenuOpen}
    //   />
    //   <Menu anchorEl={state.anchorEl} open={Boolean(state.anchorEl)} onClose={handleMenuClose}>
    //     {Object.values(menuActions).map((action) => (
    //       <MenuItem
    //         key={action}
    //         onClick={() => openModal(action)}
    //         disabled={
    //           [menuActions.EDIT, menuActions.DELETE].includes(action) &&
    //           selectedRoleRef.current?.name === predefinedRoles.OWNER_ROLE
    //         }
    //       >
    //         {action.charAt(0).toUpperCase() + action.slice(1)}
    //       </MenuItem>
    //     ))}
    //   </Menu>
    //   {state.modalState.view && (
    //     <RoleView role={selectedRoleRef.current} closeModal={() => closeModal('view')} />
    //   )}
    //   {(state.modalState.add || state.modalState.edit) && (
    //     <RoleAddEdit
    //       projectId={projectId}
    //       selectedRole={selectedRoleRef.current}
    //       closeModal={() => closeModal(state.modalState.add ? 'add' : menuActions.EDIT)}
    //     />
    //   )}
    //   {state.modalState.delete && (
    //     <RoleDeleteConfirmation
    //       role={selectedRoleRef.current}
    //       closeModal={() => closeModal(menuActions.DELETE)}
    //     />
    //   )}
    // </>
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ px: 2, py: 1 }} elevation={0}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item display='flex' justifyContent='flex-start' alignItems='center' gap={0.5}>
                <Title>Amenities</Title>
              </Grid>
              <Grid item>
                <StyledAddButton startIcon={<StyledIcon />} onClick={() => openModal('add')}>
                  Amenities
                </StyledAddButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableFilter title={'Amenities'} />
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <AmenitiesTable
            roles={dummyRoles}
            isOnHoldOrCancelled={isOnHoldOrCancelled}
            handleMenuOpen={handleMenuOpen}
          />
        </Grid>
      </Grid>
      <Menu anchorEl={state.anchorEl} open={Boolean(state.anchorEl)} onClose={handleMenuClose}>
        {Object.values(menuActions).map((action) => (
          <MenuItem
            key={action}
            onClick={() => openModal(action)}
            disabled={
              [menuActions.EDIT, menuActions.DELETE].includes(action) &&
              selectedRoleRef.current?.name === predefinedRoles.OWNER_ROLE
            }
          >
            {action.charAt(0).toUpperCase() + action.slice(1)}
          </MenuItem>
        ))}
      </Menu>
      {state.modalState.view && (
        <RoleView role={selectedRoleRef.current} closeModal={() => closeModal('view')} />
      )}
      {(state.modalState.add || state.modalState.edit) && (
        <RoleAddEdit
          projectId={projectId}
          selectedRole={selectedRoleRef.current}
          closeModal={() => closeModal(state.modalState.add ? 'add' : menuActions.EDIT)}
        />
      )}
      {state.modalState.delete && (
        <RoleDeleteConfirmation
          role={selectedRoleRef.current}
          closeModal={() => closeModal(menuActions.DELETE)}
        />
      )}
    </>
  );
}

export default AmenitiesSection;
