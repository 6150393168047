import React, { useState, useEffect } from "react";
import Select from "react-select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function CustomDropdown() {
  const [dropdownOptions] = useState([
    {
      id: 17,
      name: "Flooring",
      status: "active",
      created_at: "2024-03-27T10:42:45.000Z",
      updated_at: "2024-03-27T10:42:45.000Z",
      sub_categories: [
        { id: 3, name: "Marbles", status: "active" },
        { id: 4, name: "Granites", status: "active" },
        { id: 5, name: "Wood", status: "active" },
      ],
    },
    {
      id: 19,
      name: "Gym",
      status: "active",
      created_at: "2024-03-27T10:45:14.000Z",
      updated_at: "2024-03-27T10:45:14.000Z",
      sub_categories: [
        { id: 6, name: "Cardiac", status: "active" },
        { id: 7, name: "Free Weights", status: "active" },
        { id: 8, name: "Yoga", status: "active" },
      ],
    },
  ]);

  const [activeCategoriesOptions, setActiveCategoriesOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [expandedParents, setExpandedParents] = useState({});

  useEffect(() => {
    const options = dropdownOptions.map((parent) => ({
      label: parent.name,
      options: parent.sub_categories.map((child) => ({
        value: child.id,
        label: child.name,
        title_category_id: parent.id,
      })),
      parentId: parent.id,
    }));
    setActiveCategoriesOptions(options);
  }, [dropdownOptions]);

  const handleChange = (selectedOptions) => {
    setSelectedValues(selectedOptions);
  };

  const handleChildCheckboxChange = (childOption, checked, parentCategoryId) => {
    let updatedSelectedValues;
    if (checked) {
      updatedSelectedValues = [
        ...selectedValues.filter(
          (item) => item.value !== childOption.value && item.title_category_id !== parentCategoryId
        ),
        childOption,
      ];
    } else {
      updatedSelectedValues = selectedValues.filter(
        (item) => item.value !== childOption.value
      );
    }
    setSelectedValues(updatedSelectedValues);
    handleChange(updatedSelectedValues);
  };

  const toggleParentVisibility = (parentCategoryId) => {
    setExpandedParents((prevState) => ({
      ...prevState,
      [parentCategoryId]: !prevState[parentCategoryId],
    }));
  };

  const customOption = ({ data, isSelected, onChange }) => {
    const parentCategoryId = data.title_category_id;
    const isExpanded = expandedParents[parentCategoryId];

    if (!isExpanded) {
      return null; // Hide child if parent is not expanded
    }

    return (
      <div style={{ paddingLeft: "35px", paddingTop: "5px", paddingBottom: "5px" }}>
        <input
          type="checkbox"
          value={data.value}
          checked={isSelected}
          onChange={() => handleChildCheckboxChange(data, !isSelected, parentCategoryId)}
          style={{ marginRight: "8px" }}
        />
        {data.label}
      </div>
    );
  };

  const customParentOption = ({ data, isSelected, onChange }) => {
    const isExpanded = expandedParents[data.parentId];
    const handleClick = () => toggleParentVisibility(data.parentId);

    return (
      <div
        style={{
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingLeft: "25px",
          paddingRight: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
        onMouseEnter={(e) => e.target.style.color = '#1976d2'}
        onMouseLeave={(e) => e.target.style.color = 'initial'}
      >
        <strong>{data.label}</strong>
        <span>{isExpanded ? <ArrowDropDownIcon /> : <ArrowDropDownIcon />}</span>
      </div>
    );
  };

  return (
    <div className="App">
      <Select
        options={activeCategoriesOptions}
        value={selectedValues}
        onChange={handleChange}
        isMulti
        closeMenuOnSelect={false}
        components={{
          Option: customOption,
          GroupHeading: customParentOption,
        }}
        styles={{
          multiValue: (base) => ({
            ...base,
            backgroundColor: "lightblue",
            marginRight: "5px",
          }),
          groupHeading: (base) => ({ ...base, fontWeight: "bold" }),
          option: (base) => ({ ...base, paddingLeft: "20px" }),
        }}
      />
    </div>
  );
}
