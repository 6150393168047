import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Modal, IconButton, styled, InputLabel } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { useFormik } from 'formik';
import SaleAddEdit from './SaleAddEdit';
import { parseAddressComponents } from 'app/utils/helpers';
import { validationSchemas } from 'app/utils/validationSchemas';

const ModalDivider = styled('div')({
  height: '1px',
  marginBottom: '20px',
  backgroundColor: 'var(--modalDivider)',
});

const BuyerAddEdit = ({ open, onClose, selectedBuilding, selectedUnit, saleData, buyerData, saleId }) => {
  const searchInputRef = React.useRef(null);
  const [openSaleModal, setOpenSaleModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      buyerName: buyerData?.buyerName || '',
      buyerContactPerson: buyerData?.contactPerson || '',
      address1: buyerData?.address || '',
      city: buyerData?.city || '',
      state: buyerData?.state || '',
      country: buyerData?.country || '',
      zipCode: buyerData?.zipCode || '',
      phoneNo: buyerData?.phoneNo || '',
      email: buyerData?.email || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchemas.buyer(),
    onSubmit: () => setOpenSaleModal(true),
  });

  const handleBackToSaleForm = () => setOpenSaleModal(false);

  const handleClose = () => {
    formik.resetForm();
    handleBackToSaleForm();
    onClose();
  };

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));
  };

  return (
    <>
      <Modal open={open && !openSaleModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '0%',
            transform: 'translate(0%, -50%)',
            width: '60%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            height: '100%',
          }}
        >
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Sale Details 1/2</Title>
            <IconButton onClick={handleClose}>
              <CloseIcon
                style={{
                  fontSize: 18,
                  color: 'var(--closeIcon)',
                }}
              />
            </IconButton>
          </Box>

          <ModalDivider />

          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='buyerName'
                >
                  Buyer Name
                </InputLabel>
                <TextField
                  name='buyerName'
                  id='buyerName'
                  placeholder='Enter name'
                  size='small'
                  fullWidth
                  type='text'
                  onKeyDown={(e) => {
                    // Block unwanted keys
                    const blockedKeys = [
                      '+',
                      '-',
                      '.',
                      '/',
                      "'",
                      '[',
                      ']',
                      '{',
                      '}',
                      '\\',
                      ';',
                      ':',
                    ];
                    if (blockedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={formik.values.buyerName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.buyerName && Boolean(formik.errors.buyerName)}
                  helperText={formik.touched.buyerName && formik.errors.buyerName}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='buyerContactPerson'
                >
                  Contact Person
                </InputLabel>
                <TextField
                  name='buyerContactPerson'
                  id='buyerContactPerson'
                  placeholder='Enter name'
                  size='small'
                  type='text'
                  fullWidth
                  onKeyDown={(e) => {
                    // Block unwanted keys
                    const blockedKeys = [
                      '+',
                      '-',
                      '.',
                      '/',
                      "'",
                      '[',
                      ']',
                      '{',
                      '}',
                      '\\',
                      ';',
                      ':',
                    ];
                    if (blockedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={formik.values.buyerContactPerson}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.buyerContactPerson && Boolean(formik.errors.buyerContactPerson)
                  }
                  helperText={formik.touched.buyerContactPerson && formik.errors.buyerContactPerson}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='email'
                >
                  Email
                </InputLabel>
                <TextField
                  name='email'
                  id='email'
                  placeholder='Enter email'
                  size='small'
                  type='email'
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='phoneNo'
                >
                  Phone Number
                </InputLabel>
                <TextField
                  name='phoneNo'
                  id='phoneNo'
                  placeholder='Enter phone number'
                  size='small'
                  fullWidth
                  onKeyDown={(e) => {
                    // Block unwanted keys
                    const blockedKeys = [
                      'e',
                      'E',
                      '-',
                      '.',
                      '/',
                      "'",
                      '[',
                      ']',
                      '{',
                      '}',
                      '\\',
                      ';',
                      ':',
                    ];
                    if (blockedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={formik.values.phoneNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                  helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='address1'
                >
                  Address
                </InputLabel>
                <StandaloneSearchBox
                  onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                  onUnmount={() => (searchInputRef.current = null)}
                  onPlacesChanged={() => handlePlacesChanged(formik.setValues)}
                >
                  <TextField
                    id='address1'
                    name='address1'
                    placeholder='Enter address'
                    size='small'
                    fullWidth
                    value={formik.values.address1}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (!value) {
                        formik.setValues((prevState) => ({
                          ...prevState,
                          search: '',
                          address1: '',
                          country: '',
                          city: '',
                          state: '',
                          zipCode: '',
                          latitude: null,
                          longitude: null,
                        }));
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.address1 && Boolean(formik.errors.address1)}
                    helperText={formik.touched.address1 && formik.errors.address1}
                  />
                </StandaloneSearchBox>
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='city'
                >
                  City
                </InputLabel>
                <TextField
                  name='city'
                  id='city'
                  placeholder='Enter city'
                  size='small'
                  fullWidth
                  onKeyDown={(e) => {
                    const blockedKeys = [
                      '+',
                      '-',
                      '.',
                      '/',
                      "'",
                      '[',
                      ']',
                      '{',
                      '}',
                      '\\',
                      ';',
                      ':',
                    ];
                    if (blockedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='state'
                >
                  State
                </InputLabel>
                <TextField
                  name='state'
                  id='state'
                  placeholder='Enter state'
                  size='small'
                  fullWidth
                  onKeyDown={(e) => {
                    const blockedKeys = [
                      '+',
                      '-',
                      '.',
                      '/',
                      "'",
                      '[',
                      ']',
                      '{',
                      '}',
                      '\\',
                      ';',
                      ':',
                    ];
                    if (blockedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='country'
                >
                  Country
                </InputLabel>
                <TextField
                  id='country'
                  name='country'
                  placeholder='Enter country'
                  size='small'
                  fullWidth
                  onKeyDown={(e) => {
                    const blockedKeys = [
                      '+',
                      '-',
                      '.',
                      '/',
                      "'",
                      '[',
                      ']',
                      '{',
                      '}',
                      '\\',
                      ';',
                      ':',
                    ];
                    if (blockedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>

              <Grid item xs={6}>
                <InputLabel
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                    color: 'var(--inputLabel-color)',
                  }}
                  htmlFor='zipCode'
                >
                  Zip Code
                </InputLabel>
                <TextField
                  name='zipCode'
                  size='small'
                  id='zipCode'
                  placeholder='Enter ZIP Code'
                  type='number'
                  onKeyDown={(e) => {
                    const blockedKeys = [
                      'e',
                      'E',
                      '+',
                      '-',
                      '.',
                      '/',
                      "'",
                      '[',
                      ']',
                      '{',
                      '}',
                      '\\',
                      ';',
                      ':',
                    ];
                    if (blockedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  fullWidth
                  value={formik.values.zipCode}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (Number(newValue) >= 0) {
                      formik.setFieldValue('zipCode', newValue);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              </Grid>
              <ModalDivider />
              <Grid item xs={12} textAlign='center' display='flex' justifyContent='space-between'>
                <Button variant='outlined' color='error' onClick={handleClose}>
                  Cancel
                </Button>
                <Button type='submit' variant='contained'>
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <SaleAddEdit
        open={openSaleModal}
        buyerData={formik.values}
        saleData={saleData}
        onBack={handleBackToSaleForm}
        onClose={handleClose}
        selectedBuilding={selectedBuilding}
        selectedUnit={selectedUnit}
        saleId={saleId}
      />
    </>
  );
};

export default BuyerAddEdit;
