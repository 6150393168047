import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, styled, Modal, Typography, TextField, Grid } from '@mui/material';
import { Title } from 'app/common/Typography';
import { delay, handleAxiosError } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';
import LeadsSuccess from './LeadsSuccess';
import notify from 'app/utils/notify';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});
const LeadsOnHold = ({ openModal, handleCancel }) => {
  const {
    basic: { projectTitle },
    projectMoveToOnHold,
  } = useProject();

  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  // const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await projectMoveToOnHold(reason);
      // setSuccessModalOpen(true);
      // await delay(5000);
      // setSuccessModalOpen(false);
      notify.success(
        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
          Project successfully moved to
          <Grid>{`the OnHold Project`}</Grid>
        </Typography>
      );
      handleCancel();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SubtitleDivider />
      <Modal open={openModal} onClose={handleCancel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '40%',
          }}
        >
          <Box sx={{ p: 2, justifyContent: 'space-between', display: 'flex' }}>
            <Title>On Hold Project</Title>
            <IconButton onClick={handleCancel}>
              <CloseIcon style={{ fontSize: 20, color: '#272937' }} />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box sx={{ marginLeft: '4px' }}>
            <Box sx={{ p: 2, paddingLeft: 3, paddingRight: 3 }}>
              <Typography style={{ fontSize: '16px', fontWeight: '400' }}>
                {`Are you sure you want to OnHold the "${projectTitle}" from the Leads?`}
              </Typography>
              <TextField
                fullWidth
                multiline
                label='Reason'
                minRows={4}
                maxRows={4}
                margin='normal'
                placeholder='Write here...'
                value={reason}
                onChange={handleReasonChange}
                inputProps={{ maxLength: 100 }}
              />
            </Box>

            <Box sx={{ justifyContent: 'space-between', display: 'flex', p: 2 }}>
              <LoadingButton
                color='error'
                sx={{ height: '35px', mt: 0.5 }}
                variant='outlined'
                onClick={handleCancel}
                style={{ boxShadow: 'none' }}
                disabled={loading}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                style={{ boxShadow: 'none' }}
                disabled={reason.trim() === ''}
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* <LeadsSuccess
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        to={'On Hold'}
      /> */}
    </>
  );
};
export default LeadsOnHold;
