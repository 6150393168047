import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Avatar,
  Box,
  Collapse,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material//KeyboardArrowRight';
import { useState } from 'react';
import ExpenseInvoice from './ExpenseInvoice';
import { handleDocDownload } from 'app/utils/constant';
import { Downloadicon } from 'app/common/icons';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments';
import { useProject } from 'app/contexts/ProjectContext';
const TableHeader = () => (
  <TableHead>
    <TableRow>
      {/* <TableCell>S.No.</TableCell> */}
      
      <TableCell>Invoice No.</TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Total Amount</TableCell>
      <TableCell>Paid Amount</TableCell>
      <TableCell>Balance</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Paid To</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Invoice</TableCell>
      {/* <TableCell align='right' style={{ width: 48 }}></TableCell> */}
    </TableRow>
  </TableHead>
);

function ExpensesTable({ expenses, onExpenseClick }) {
  const { projectId } = useProject();
  const { data: documents } = useProjectDocuments(projectId);
  const [openRow, setOpenRow] = useState(null);

  const handleRowClick = (rowId) => setOpenRow(openRow === rowId ? null : rowId);

  return (
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {expenses &&
            expenses.map((expense, index) => {
              const latestPaymentDetail =
                expense?.paymentDetails?.length > 0 ? expense?.paymentDetails?.at(-1) : {};
              return (
                <>
                  <StyledTableRow key={latestPaymentDetail.id}>
                    <TableCell>
                      <div
                        style={{
                          fontWeight: 100,
                          fontSize: '14px',
                          align: 'left',
                          width: '60px',
                          //   color: 'var(--tableBody-cell-color)',
                        }}
                        onClick={() => handleRowClick(expense.id)}
                      >
                        <span>
                          {openRow === expense.id ? (
                            <KeyboardArrowDownIcon sx={{ marginBottom: '-7px' }} />
                          ) : (
                            <KeyboardArrowRightIcon sx={{ marginBottom: '-7px' }} />
                          )}
                        </span>
                        <span>
                          {/* {(index + 1).toString().padStart(2, '0')} */}
                          {expense?.invoiceNo}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell> {latestPaymentDetail?.expensesName}</TableCell>
                    {/* <TableCell>{latestPaymentDetail?.expensesName}</TableCell> */}
                    <TableCell>{expense?.totalAmount}</TableCell>
                    <TableCell>{latestPaymentDetail?.paidAmount}</TableCell>
                    <TableCell>{latestPaymentDetail?.balance || '00'}</TableCell>
                    <TableCell>{latestPaymentDetail?.expenseType}</TableCell>
                    <TableCell>{latestPaymentDetail?.paidTo}</TableCell>
                    <TableCell>{latestPaymentDetail?.status}</TableCell>
                    {/* <TableCell></TableCell> */}
                    <TableCell>
                      {latestPaymentDetail?.paymentDocuments?.length > 0 ? (
                        (() => {
                          const matchedDoc = documents?.find(
                            (doc) =>
                              doc.metadata.fileId === latestPaymentDetail.paymentDocuments[0],
                          );
                          return matchedDoc ? (
                            <Box display='flex' alignItems='center' justifyContent='left'>
                              <Typography fontSize={14} noWrap>
                                {matchedDoc.metadata.documentName}
                              </Typography>
                              <IconButton
                                onClick={() =>
                                  handleDocDownload(
                                    matchedDoc.content,
                                    matchedDoc.metadata.documentName,
                                    matchedDoc.metadata.documentType,
                                  )
                                }
                              >
                                <Downloadicon />
                              </IconButton>
                            </Box>
                          ) : (
                            <Typography fontSize={14}>N/A</Typography>
                          );
                        })()
                      ) : (
                        <Typography fontSize={14}>N/A</Typography>
                      )}
                    </TableCell>

                    {/* <TableCell align='right'>
                      <IconButton
                        size='small'
                        //   onClick={(event) => handleMenuOpen(event, member)}
                        //   disabled={isOnHoldOrCancelled}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell> */}
                  </StyledTableRow>
                  {openRow === expense.id && (
                    <TableRow>
                      <TableCell sx={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
                        <Collapse in={openRow === expense.id} timeOut='auto' unmountOnExit>
                          <Box>
                            <ExpenseInvoice
                              selectedExpense={expense}
                              expensesDocuments={documents}
                            />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

export default ExpensesTable;
