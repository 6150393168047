// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileEdit_dialogContainer__sszKw {
  padding-top: 0.5rem;
  padding-bottom: 0;
}

.ProfileEdit_dialogActions__-2\\+qx {
  padding: 0.8rem 0 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/views/dashboard/profile/ProfileEdit.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".dialogContainer {\r\n  padding-top: 0.5rem;\r\n  padding-bottom: 0;\r\n}\r\n\r\n.dialogActions {\r\n  padding: 0.8rem 0 0.8rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContainer": `ProfileEdit_dialogContainer__sszKw`,
	"dialogActions": `ProfileEdit_dialogActions__-2+qx`
};
export default ___CSS_LOADER_EXPORT___;
