import React, { useState } from 'react';
import { Button, IconButton, Typography, Modal, Box } from '@mui/material';
import { H1, Paragraph } from 'app/common/Typography';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { dueDiligence } from 'app/utils/constant';
import useUserAuth from 'app/hooks/userUserAuth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid lightgrey',
  paddingBottom: '10px',
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
  gap: '10px',
};

const ProfileImageModal = ({ open, handleClose }) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    profileDocumentInformation
  } = useUserAuth();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleDeleteFile = () => {
    setImage(null);
  };

  const handleCancel = () => {
    setImage(null);
    handleClose();
  };

  const handleSaveImage = async () => {
    if (!image) {
      alert('Please add an image before saving.');
      return;
    }

    setLoading(true);
    const data = new FormData();
    data.append('file', image);
    data.append('docName', image.name ? image.name : '');
    data.append('type', dueDiligence);

    try {
      await profileDocumentInformation(data);
      handleClose();
      setImage(null);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      aria-labelledby='image-upload-modal'
      aria-describedby='image-upload-description'
    >
      <Box sx={style}>
        <div style={headerStyle}>
          <H1>Image</H1>
          <IconButton onClick={handleCancel}>
            <Icon icon='mdi:close' style={{ color: 'grey' }} />
          </IconButton>
        </div>

        <div className='lead'>
          <div className='navigation-header'>
            <Paragraph>You need to upload an image with .jpeg format</Paragraph>
            <div className='Container'>
              <img src='/assets/images/uploadImages.png' alt='' />
              <Typography variant='body1'>
                Drag and Drop the file you want to upload from your computer
              </Typography>
              <label htmlFor='outlined-button-file'>
                <Button className='StyledButton' variant='outlined' component='span'>
                  Browse file
                </Button>
              </label>
              <input
                accept='image/*'
                className='input'
                id='outlined-button-file'
                type='file'
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
            <div
              style={{ border: '1px solid lightgrey', marginTop: '20px', marginBottom: '20px' }}
            ></div>

            {image && (
              <div className='ImageContainer'>
                <img
                  src={URL.createObjectURL(image)}
                  alt='Selected File'
                  style={{ height: '100%', borderRadius: '4px', width: '70px' }}
                />
                <div className='FileDetails'>
                  <Typography variant='body1' style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                    {image.name}
                  </Typography>
                  <Typography variant='caption' style={{ marginLeft: '10px' }}>
                    {(image.size / 1024).toFixed(2)} KB
                  </Typography>
                </div>
                <IconButton onClick={handleDeleteFile}>
                  <Icon icon='material-symbols-light:delete-rounded' style={{ color: 'red' }} />
                </IconButton>
              </div>
            )}
          </div>
        </div>

        <div style={footerStyle}>
          <LoadingButton variant='outlined' onClick={handleCancel} color='error'>
            Cancel
          </LoadingButton>
          <LoadingButton
            variant='contained'
            color='primary'
            onClick={handleSaveImage}
            loading={loading}
            disabled={!image || loading}
          >
            Save
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
};

export default ProfileImageModal;
