import React, { useState } from 'react';
import {
  Box,
  ListItemIcon,
  ListItemText,
  IconButton,
  Modal,
  Divider,
  Typography,
} from '@mui/material';
import {
  StyledAddButton,
  StyledIcon,
  IconDivider,
  StledCardTitle,
  StyledTab,
  StyledTabs,
  DocumetCardBody,
  StyledDocList,
  DocTextPrimary,
  DocTextSecondary,
  DocListIconSec,
  DocFooterSec,
  ViewAllLink,
  MoreText,
} from './StyledComponent';
import {
  Downloadicon,
  ViewIcon,
  LCDeleteIcon,
  DescriptionIcon,
  PdfIcon,
  DocIcon,
} from 'app/common/icons';
import DocumentModal from './Modals/DocumentModal';
import { useProjectDocuments } from './hooks/useProjectDocuments';
import { useParams } from 'react-router-dom';
import { useProjectDocumentDelete } from './hooks/useProjectDocumentDelete';
import LeadDeleteConfirmation from 'app/common/LeadsDeleteConfirmation';
import { handleDocDownload } from 'app/utils/constant';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { ModalDivider, ModalStyledBox, ModalStyledSubBox, Title } from 'app/common/Typography';
import CloseIcon from '@mui/icons-material/Close';

const DocumentSection = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [viewAllOpen, setViewAllOpen] = useState(false);
  const [currentViewAllDocs, setCurrentViewAllDocs] = useState([]);

  const [selectedViewDocument, setSelectedViewDocument] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const { projectId } = useParams();
  const { data: allDocuments = [] } = useProjectDocuments(projectId);
  const { mutate: deleteDocument, isPending: isDeleting } = useProjectDocumentDelete(projectId);

  const documents = allDocuments.filter((doc) => !doc.metadata.documentType.startsWith('image/'));

  const documentTypes = [...new Set(documents.map((doc) => doc.metadata.type))];

  const handleViewDocument = (document) => {
    console.log('doc', document);
    setSelectedViewDocument(document);
    setViewModalOpen(true);
  };

  const handleCloseViewModal = () => {
    setViewModalOpen(false);
    setSelectedViewDocument(null);
  };

  const handleViewAll = () => {
    const filteredDocs = documents.filter(
      (doc) => doc.metadata.type === documentTypes[selectedTab],
    );
    setCurrentViewAllDocs(filteredDocs);
    setViewAllOpen(true);
  };

  // Convert base64 to Blob URL
  const base64ToBlob = (base64, mimeType) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: mimeType });
  };

  const getDocUrl = (document) => {
    const blob = base64ToBlob(document.content, document.metadata.documentType);
    return URL.createObjectURL(blob);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenDeleteModal = (document) => {
    setSelectedDocument(document);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedDocument(null);
  };

  const handleDelete = () => {
    deleteDocument(selectedDocument.metadata.fileId);
    handleCloseDeleteModal();
  };

  const getFileIcon = (mimeType) => {
    switch (mimeType) {
      case 'application/pdf':
        return <PdfIcon />;
      case 'application/msword':
        return <DocIcon />;
      default:
        return <DocIcon />;
    }
  };
  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ margin: '20px 38px 20px 20px' }}
      >
        <StledCardTitle>Documents</StledCardTitle>
        <StyledAddButton startIcon={<StyledIcon />} onClick={handleOpenModal}>
          Document
        </StyledAddButton>
        <Modal open={openModal} onClose={handleCloseModal}>
          <DocumentModal handleCloseModal={handleCloseModal} projectId={projectId} />
        </Modal>
      </Box>
      <IconDivider orientation='vertical' />

      {documentTypes.length === 0 ? (
        <Box display='flex' justifyContent='center' alignItems='center' sx={{ height: '200px' }}>
          <Typography variant='h6' color='textSecondary'>
            No Documents Added
          </Typography>
        </Box>
      ) : (
        <>
          <StyledTabs value={selectedTab} onChange={handleTabChange}>
            {documentTypes.map((docType, index) => (
              <StyledTab key={docType} label={docType} />
            ))}
          </StyledTabs>

          <IconDivider orientation='vertical' />

          <DocumetCardBody>
            {documents
              .filter((doc) => doc.metadata.type === documentTypes[selectedTab])
              .slice(0, 3)
              .map((doc) => (
                <StyledDocList key={doc.metadata.fileId}>
                  <ListItemIcon sx={{ marginLeft: '10px' }}>
                    {/* <DescriptionIcon /> */}
                    {getFileIcon(doc.metadata.documentType)}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <DocTextPrimary>
                        {doc.metadata.documentName || 'Unnamed Document'}
                      </DocTextPrimary>
                    }
                    secondary={
                      <DocTextSecondary>
                        {`Updated On ${new Date(doc.uploadDate).toLocaleDateString()}`}
                      </DocTextSecondary>
                    }
                  />
                  <DocListIconSec sx={{ marginRight: '10px' }}>
                    <IconButton
                      color='primary'
                      onClick={() =>
                        handleDocDownload(
                          doc.content,
                          `${doc.metadata.documentName}`,
                          doc.metadata.documentType,
                        )
                      }
                    >
                      <Downloadicon fontSize='small' />
                    </IconButton>

                    <Divider orientation='vertical' flexItem />

                    <IconButton onClick={() => handleViewDocument(doc)}>
                      <ViewIcon fontSize='small' />
                    </IconButton>

                    <Divider orientation='vertical' flexItem />

                    <IconButton onClick={() => handleOpenDeleteModal(doc)}>
                      <LCDeleteIcon fontSize='small' />
                    </IconButton>
                  </DocListIconSec>
                </StyledDocList>
              ))}
          </DocumetCardBody>
          {documents.filter((doc) => doc.metadata.type === documentTypes[selectedTab]).length >
            3 && (
            <DocFooterSec>
              <MoreText>{`+ ${documents.length - 3} more..`}</MoreText>
              <ViewAllLink onClick={handleViewAll}>View All</ViewAllLink>
            </DocFooterSec>
          )}

          <LeadDeleteConfirmation
            name={`${selectedDocument?.metadata?.documentName || ''} Document`}
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            handleSubmit={handleDelete}
          />
        </>
      )}

      <Modal open={viewAllOpen} onClose={() => setViewAllOpen(false)}>
        <ModalStyledBox>
          <ModalStyledSubBox>
            <Title>Documents</Title>
            <IconButton>
              <CloseIcon
                style={{
                  fontSize: 20,
                  color: 'var(--closeIcon)',
                }}
                onClick={() => setViewAllOpen(false)}
              />
            </IconButton>
          </ModalStyledSubBox>
          <ModalDivider />
          <Box
            sx={{
              p: 2,
              pt: 0,
              marginLeft: '4px',
              overflowY: 'auto',
              maxHeight: 'calc(100% - 75px)',
            }}
          >
            {/* <Typography variant='h6' mb={2}>
              {documentTypes[selectedTab]}
            </Typography> */}

            {currentViewAllDocs.map((doc) => (
              <StyledDocList key={doc.metadata.fileId}>
                <ListItemIcon sx={{ marginLeft: '10px' }}>
                  {getFileIcon(doc.metadata.documentType)}
                </ListItemIcon>
                <ListItemText
                  primary={<DocTextPrimary>{doc.metadata.documentName}</DocTextPrimary>}
                  secondary={
                    <DocTextSecondary>
                      {`Updated On ${new Date(doc.uploadDate).toLocaleDateString()}`}
                    </DocTextSecondary>
                  }
                />
                <DocListIconSec sx={{ marginRight: '10px' }}>
                  <IconButton
                    onClick={() =>
                      handleDocDownload(
                        doc.content,
                        `${doc.metadata.documentName}`,
                        doc.metadata.documentType,
                      )
                    }
                  >
                    <Downloadicon fontSize='small' />
                  </IconButton>

                  <Divider orientation='vertical' flexItem />
                  <IconButton onClick={() => handleViewDocument(doc)}>
                    <ViewIcon fontSize='small' />
                  </IconButton>

                  <Divider orientation='vertical' flexItem />
                  <IconButton onClick={() => handleOpenDeleteModal(doc)}>
                    <LCDeleteIcon fontSize='small' />
                  </IconButton>
                </DocListIconSec>
              </StyledDocList>
            ))}
          </Box>
        </ModalStyledBox>
      </Modal>

      <Modal open={viewModalOpen} onClose={handleCloseViewModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            p: 2,
            width: '90%',
            height: '90%',
            overflow: 'auto',
          }}
        >
          {selectedViewDocument && (
            <DocViewer
              documents={[
                {
                  uri: getDocUrl(selectedViewDocument),
                  fileType: selectedViewDocument.metadata.documentType.includes('pdf')
                    ? 'pdf'
                    : selectedViewDocument.metadata.documentType.includes('wordprocessingml')
                    ? 'docx'
                    : selectedViewDocument.metadata.documentType.split('/')[1],
                  fileName: selectedViewDocument.metadata.documentName,
                },
              ]}
              pluginRenderers={DocViewerRenderers}
              style={{ height: '100%' }}
              config={{
                header: {
                  disableHeader: false,
                  disableFileName: false,
                  retainURLParams: false,
                },
              }}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DocumentSection;
