import React from 'react';
import {
    Box,
    Button,
    Grid,
    TextField,
    Modal,
    IconButton,
    styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ModalDivider = styled('div')({
    height: '1px',
    marginBottom: '20px',
    backgroundColor: '#D5D5DD',
});

const LeadSaleDetailForm = ({ open, onClose2, onSave, onClose1, onBack, saleinfo, selectedUnit, fetchSaleDetails }) => {
    const { projectId } = useParams();


    React.useEffect(() => {
        if (projectId) {
            fetchSaleDetails(projectId);
        }
    }, [projectId]);
    const validationSchema = Yup.object({
        buyerRealtor: Yup.number().required('Buyer Realtor is required'),
        // unitName: Yup.string().required('Unit No is required'),
        salePrice: Yup.number().required('Sale Price is required'),
        buyerSaleCommission: Yup.number().required('Buyer Sale Commission is required'),
        sellerSaleCommission: Yup.number().required('Seller Sale Commission is required'),
        closingCost: Yup.number().required('Closing Cost is required'),
        titlePolicyCost: Yup.number().required('Title Policy Cost is required'),
        comments: Yup.string().optional(),
    });


    const formik = useFormik({
        initialValues: {
            buyerRealtor: saleinfo?.buyerRealtor || '',
            saleSpace: selectedUnit?.space || '',
            unitNo: selectedUnit?.unitName || '', 
            salePrice: saleinfo?.salePrice || '',
            saleExecutionDate: saleinfo?.saleExecutionDate || '',
            saleFixturizationDate: saleinfo?.saleFixturizationDate || '',
            saleStartDate: saleinfo?.saleStartDate || '',
            saleEndDate: saleinfo?.saleEndDate || '',
            buyerSaleCommission: saleinfo?.buyerSaleCommission || '',
            sellerSaleCommission: saleinfo?.sellerSaleCommission || '',
            closingCost: saleinfo?.closingCost || '',
            titlePolicyCost: saleinfo?.titlePolicyCost || '',
            comments: saleinfo?.comments || '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            onSave(values);
            onClose2();
            onClose1();
        },
    });

    const handleClose = () => {
        formik.resetForm();
        onClose2();
        onClose1();
    }

    return (
        <Modal open={open} closeAfterTransition>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '35%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    overflowY: 'auto',
                    maxHeight: '90vh',
                }}
            >
                <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                    <Title>Sale Details 2/2</Title>
                    <IconButton onClick={handleClose}>
                        <CloseIcon style={{ fontSize: 18, color: '#272937' }} />
                    </IconButton>
                </Box>

                <ModalDivider />

                <form onSubmit={formik.handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                label="Buyer Realtor"
                                name="buyerRealtor"
                                size="small"
                                fullWidth
                                onKeyDown={(e) => {
                                    // Block unwanted keys
                                    const blockedKeys = [
                                       "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                                    ];
                                    if (blockedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                value={formik.values.buyerRealtor}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.buyerRealtor && Boolean(formik.errors.buyerRealtor)}
                                helperText={formik.touched.buyerRealtor && formik.errors.buyerRealtor}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Sale Space"
                                name="saleSpace"
                                size="small"
                                onKeyDown={(e) => {
                                    // Block unwanted keys
                                    const blockedKeys = [
                                     "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                                    ];
                                    if (blockedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                value={selectedUnit?.space || saleinfo?.saleSpace || ''}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                error={formik.touched.saleSpace && Boolean(formik.errors.saleSpace)}
                                helperText={formik.touched.saleSpace && formik.errors.saleSpace}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Unit Name"
                                name="unitNo"
                                size="small"
                                type='text'
                                fullWidth
                                value={selectedUnit?.unitName || saleinfo?.unitNo || ''}
                                InputProps={{
                                    readOnly: true, // Set to read-only
                                }}
                                error={formik.touched.unitNo && Boolean(formik.errors.unitNo)}
                                helperText={formik.touched.unitNo && formik.errors.unitNo}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Sale Price"
                                name="salePrice"
                                type='number'
                                size="small"
                                onKeyDown={(e) => {
                                    // Block unwanted keys
                                    const blockedKeys = [
                                        "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                                    ];
                                    if (blockedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                fullWidth
                                value={formik.values.salePrice}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
                                helperText={formik.touched.salePrice && formik.errors.salePrice}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Buyer Sale Commission"
                                name="buyerSaleCommission"
                                size="small"
                                type='number'
                                fullWidth
                                onKeyDown={(e) => {
                                    // Block unwanted keys
                                    const blockedKeys = [
                                        "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                                    ];
                                    if (blockedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}

                                value={formik.values.buyerSaleCommission}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.buyerSaleCommission && Boolean(formik.errors.buyerSaleCommission)}
                                helperText={formik.touched.buyerSaleCommission && formik.errors.buyerSaleCommission}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Seller Sale Commission"
                                name="sellerSaleCommission"
                                size="small"
                                type='number'
                                fullWidth
                                onKeyDown={(e) => {
                                    // Block unwanted keys
                                    const blockedKeys = [
                                        "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                                    ];
                                    if (blockedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                value={formik.values.sellerSaleCommission}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.sellerSaleCommission && Boolean(formik.errors.sellerSaleCommission)}
                                helperText={formik.touched.sellerSaleCommission && formik.errors.sellerSaleCommission}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Closing Cost"
                                name="closingCost"
                                size="small"
                                type='number'
                                fullWidth
                                onKeyDown={(e) => {
                                    // Block unwanted keys
                                    const blockedKeys = [
                                        "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                                    ];
                                    if (blockedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                value={formik.values.closingCost}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.closingCost && Boolean(formik.errors.closingCost)}
                                helperText={formik.touched.closingCost && formik.errors.closingCost}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Title Policy Cost"
                                name="titlePolicyCost"
                                size="small"
                                type='number'
                                fullWidth
                                onKeyDown={(e) => {
                                    // Block unwanted keys
                                    const blockedKeys = [
                                        "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                                    ];
                                    if (blockedKeys.includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                                value={formik.values.titlePolicyCost}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.titlePolicyCost && Boolean(formik.errors.titlePolicyCost)}
                                helperText={formik.touched.titlePolicyCost && formik.errors.titlePolicyCost}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Comments"
                                name="comments"
                                size="small"
                                fullWidth
                                multiline
                                rows={4}
                                value={formik.values.comments}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            textAlign="center"
                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >

                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <Button variant="text" color="inherit" onClick={onBack} startIcon={<ArrowBackIcon size='small' />}>
                                    Back
                                </Button>
                                <Button variant="outlined" color="error" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button type="submit" variant="contained">
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};

export default LeadSaleDetailForm;
