import React from 'react';
import { Box, Typography, Grid, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  PDSubHeadings,
  PDSubValues,
  PropertyBodySec,
  SecHeading,
  StyledEditIcon,
} from '../StyledComponent';
import { StyledBox1, StyledModalTitle, StyledCrossIcon, StyledBox2 } from './StyledComponents';
import { ModalStyledBox, ModalStyledSubBox, Title } from 'app/common/Typography';
import { EditIcon } from 'app/common/icons';

const ViewPropertyDetailsModal = ({ handleCloseViewModal, handleOpenEditModal, property }) => {
  const isResidential =
    property?.subType === 'Residential - Single Family' ||
    property?.subType === 'Residential - Multi Family';
  return (
    <>
      <ModalStyledBox>
        <ModalStyledSubBox>
          <Title>Property Details</Title>
          <StyledCrossIcon onClick={handleCloseViewModal}>
            <CloseIcon />
          </StyledCrossIcon>
        </ModalStyledSubBox>
        <Divider sx={{ marginTop: '2px' }} />
        <Box sx={{ marginTop: '30px', marginLeft: '22px' }}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <PDSubHeadings>Property Type</PDSubHeadings>
              <PDSubValues>{property?.projectType}</PDSubValues>
            </Grid>
            <Grid item xs={4}>
              <PDSubHeadings>Sub Type</PDSubHeadings>
              <PDSubValues>{property?.subType}</PDSubValues>
            </Grid>
            <Grid item xs={4}>
              <PDSubHeadings>Area(in acres)</PDSubHeadings>
              <PDSubValues>{property?.size}</PDSubValues>
            </Grid>
            <Grid item xs={4}>
              <PDSubHeadings>Zone</PDSubHeadings>
              <PDSubValues>-</PDSubValues>
            </Grid>
            <Grid item xs={8}>
              <PDSubHeadings>Estimated Price</PDSubHeadings>
              <PDSubValues>${property?.estimatedPrice}</PDSubValues>
            </Grid>
          </Grid>
        </Box>
        {/* Description */}
        {property?.description && (
          <Box sx={{ marginTop: '50px', marginLeft: '20px' }}>
            <Typography sx={{ fontWeignt: '500', fontSize: '20px', color: '#17181B' }}>
              Description:
            </Typography>
            <Typography
              sx={{ fontWeignt: '500', fontSize: '16px', color: '#5F5F64', marginTop: '11px' }}
              dangerouslySetInnerHTML={{
                __html: property?.description,
              }}
            >
              {/* {property?.description} */}
            </Typography>
          </Box>
        )}
        {property?.referenceLink && (
          <Box sx={{ marginTop: '20px', marginLeft: '20px' }}>
            <Typography sx={{ fontWeignt: '500', fontSize: '20px', color: '#17181B' }}>
              Reference link:
            </Typography>
            <Typography
              sx={{ fontWeignt: '500', fontSize: '16px', color: '#5F5F64', marginTop: '11px' }}
            >
              {property?.referenceLink}
            </Typography>
          </Box>
        )}

        {isResidential && (
          <PropertyBodySec sx={{ marginBottom: '20px' }}>
            <SecHeading>Standard Amenities:</SecHeading>
            <Box sx={{ marginTop: '5px' }}>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <PDSubHeadings>Flooring</PDSubHeadings>
                  <PDSubValues>Marbles</PDSubValues>
                </Grid>
                <Grid item xs={4}>
                  <PDSubHeadings>Gym Facility</PDSubHeadings>
                  <PDSubValues>Cardio</PDSubValues>
                </Grid>
                <Grid item xs={4}>
                  <PDSubHeadings>Swimming Pool</PDSubHeadings>
                  <PDSubValues>Adult</PDSubValues>
                </Grid>
                <Grid item xs={4}>
                  <PDSubHeadings>Tennis Court</PDSubHeadings>
                  <PDSubValues>Singles</PDSubValues>
                </Grid>
              </Grid>
            </Box>
          </PropertyBodySec>
        )}
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            backgroundColor: '#fff',
            padding: '1rem',
            borderTop: '1px solid #D6DEE9',
            textAlign: 'center',
            height: '66px',
          }}
        >
          <StyledEditIcon
            variant='outlined'
            size='small'
            startIcon={<EditIcon />}
            onClick={handleOpenEditModal}
          >
            Property Details
          </StyledEditIcon>
        </Box>
      </ModalStyledBox>
    </>
  );
};

export default ViewPropertyDetailsModal;
