import { useState } from 'react';
import {
  Box,
  Card,
  Divider,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import {
  calculateTomorrow,
  convertUTCDateToLocal,
  formatDecimal,
  handleAxiosError,
} from 'app/utils/helpers';
import { Form, Formik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useProject } from 'app/contexts/ProjectContext';
import { validationSchemas } from 'app/utils/validationSchemas';
import {
  ModalCancel,
  ModalSave,
  StyledBoxFooter,
  StyledEditIcon,
  Title,
} from 'app/common/Typography';
import { useProjectContract } from './hooks/useContract';
import { useProjectContractCreateUpdate } from './hooks/useContractCreateUpdate';
import { convertDateArrayToFormattedString } from 'app/utils/helpers';
import { useProjectClosing } from '../Closing/hooks/useProjectClosing';
import EditIcon from '@mui/icons-material/Edit';
import { StyledLabel } from '../../listings/Modals/StyledComponents';
import TableFilter from 'app/common/TableFilter';
import ContractDetailsTable from './ContractDetailsTable';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments';
import { documentTypes } from 'app/utils/constant';

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: 'var(--dialogDivider)',
});

const ProductTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
  },
  '& th, & td': { border: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  backgroundColor: '#fff',
  marginBottom: '20px',
  border: '1px solid #DADCE0',
}));

const LeadsContractDetails = () => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { data: closing } = useProjectClosing(projectId);
  const { data: contract } = useProjectContract(projectId);
  const { mutate: updateContract } = useProjectContractCreateUpdate(projectId);
  const { data: documents } = useProjectDocuments(projectId);
  const firstContractDoc = documents?.find(
    (doc) => doc.metadata?.type === documentTypes.CONTRACT
  );
  
  const [open, setOpen] = useState(false);

  const initialValues = {
    ...contract,
    effectiveDate: contract?.effectiveDate
      ? convertDateArrayToFormattedString(contract?.effectiveDate)
      : null,
    feasibilityEndDate: contract?.feasibilityEndDate
      ? convertDateArrayToFormattedString(contract?.feasibilityEndDate)
      : null,
    estimatedClosingDate: contract?.estimatedClosingDate
      ? convertDateArrayToFormattedString(contract?.estimatedClosingDate)
      : null,
  };


  const infoPairs = [
    {
      key: 'Effective date',
      value: initialValues?.effectiveDate
        ? convertUTCDateToLocal(new Date(initialValues?.effectiveDate))
        : 'N/A',
    },
    {
      key: 'Feasibility due date',
      value: initialValues?.feasibilityEndDate
        ? convertUTCDateToLocal(new Date(initialValues?.feasibilityEndDate))
        : 'N/A',
    },
    {
      key: 'Estimated Closing date',
      value: initialValues?.estimatedClosingDate
        ? convertUTCDateToLocal(new Date(initialValues?.estimatedClosingDate))
        : '/N/A',
    },
    { key: 'Earnest Money', value: initialValues.earnestMoney },
    { key: 'Options money', value: initialValues.optionsMoney },
  ];

  const closingDetailsInfo = [
    { key: 'Purchase Price', value: closing?.purchasePrice },
    { key: 'Closing Cost', value: closing?.closingCost },
    { key: 'Realtor Rebate', value: closing?.realtorRebate },
    { key: 'Closing Date', value: convertDateArrayToFormattedString(closing?.closingDate) },
  ];

  const titleFirmDetails = [
    { key: 'Title Firm Name', value: closing?.companyDetails?.companyName },
    { key: 'Contact Person', value: closing?.companyDetails?.contactPerson },
    { key: 'Contact Number', value: closing?.companyDetails?.contactPersonNumber },
    { key: 'Email', value: closing?.companyDetails?.contactPersonEmail },
  ];

  const lenderDetails = [
    { key: 'Lender Name', value: closing?.lenderDetails?.lenderName },
    { key: 'Contact Number', value: closing?.lenderDetails?.lenderContactNumber },
    { key: 'Down Payment', value: closing?.lenderDetails?.downPayment },
    { key: 'Loan Amount', value: closing?.lenderDetails?.loanAmount },
    { key: 'Intrest Rate', value: closing?.lenderDetails?.interestRate },
    { key: 'Loan Term    ', value: closing?.lenderDetails?.leaseTerm },
  ];

  const toggleModal = () => setOpen((prev) => !prev);

  const handleSubmit = (values, { setSubmitting }) => {
    const formattedDates = {
      effectiveDate: values.effectiveDate.toISOString(),
      feasibilityEndDate: values.feasibilityEndDate.toISOString(),
      estimatedClosingDate: values.estimatedClosingDate.toISOString(),
    };

    updateContract(
      { ...values, ...formattedDates },
      {
        onSuccess: toggleModal,
        onSettled: () => setSubmitting(false),
      },
    );
  };

  return (
    // <div className='viewlead'>
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       justifyContent: 'space-between',
    //       alignItems: 'center',
    //       backgroundColor: '#FFFFFF',
    //       padding: 2,
    //       borderRadius: '6px 6px 0 0',
    //       boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    //       border: '1px solid #DADCE0',
    //     }}
    //   >
    //     <Title sx={{ m: 0 }}>Contract Details</Title>
    //     <StyledEditIcon
    //       variant='outlined'
    //       onClick={toggleModal}
    //       disabled={isOnHoldOrCancelled}
    //       startIcon={<EditIcon />}
    //     >
    //       Contract Details
    //     </StyledEditIcon>
    //   </Box>
    //   <ProductTable>
    //     <TableHead>
    //       <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
    //         {infoPairs.map(({ key }, index) => (
    //           <TableCell
    //             key={index}
    //             sx={{
    //               fontWeight: '0.875rem',
    //               fontSize: '500',
    //               color: '#17181B',
    //               flex: 1, // Ensures each header item aligns correctly
    //             }}
    //           >
    //             {key}
    //           </TableCell>
    //         ))}
    //       </TableRow>
    //     </TableHead>

    //     {/* Table Body */}
    //     <TableBody>
    //       <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
    //         {infoPairs.map(({ key, value }, index) => {
    //           let output;
    //           switch (true) {
    //             case typeof value === 'string':
    //               output = value;
    //               break;
    //             case typeof value === 'number' && !isNaN(value):
    //               output = `$${value.toFixed(2)}`;
    //               break;
    //             default:
    //               output = 'N/A';
    //               break;
    //           }
    //           return (
    //             <TableCell
    //               key={index}
    //               sx={{
    //                 color: '#282A2D',
    //                 fontSize: '1rem',
    //                 fontWeight: '600',
    //                 flex: 1, // Ensures content cells align correctly
    //                 paddingTop: 0, // Ensures no extra padding on top
    //               }}
    //             >
    //               {output}
    //             </TableCell>
    //           );
    //         })}
    //       </TableRow>
    //     </TableBody>
    //   </ProductTable>

    //   {closing &&
    //     Object.keys(closing.companyDetails || {}).length > 0 &&
    //     Object.keys(closing.lenderDetails || {}).length > 0 && (
    //       <>
    //         <Box
    //           sx={{
    //             display: 'flex',
    //             justifyContent: 'space-between',
    //             alignItems: 'center',
    //             backgroundColor: '#FFFFFF',
    //             padding: 2,
    //             borderRadius: '6px 6px 0 0',
    //             boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    //           }}
    //         >
    //           <Title sx={{ m: 0 }}>Closing Details</Title>
    //           <StyledEditIcon
    //             variant='outlined'
    //             onClick={toggleModal}
    //             disabled={isOnHoldOrCancelled}
    //             startIcon={<EditIcon />}
    //           >
    //             Closing Details
    //           </StyledEditIcon>
    //         </Box>
    //         <Divider />
    //         <ProductTable>
    //           <TableHead>
    //             <TableRow>
    //               {closingDetailsInfo.map(({ key }, index) => {
    //                 return (
    //                   <>
    //                     <TableCell
    //                       sx={{ fontWeight: '0.875rem', fontSize: '500', color: '#17181B' }}
    //                     >
    //                       {key}
    //                     </TableCell>
    //                   </>
    //                 );
    //               })}
    //             </TableRow>
    //           </TableHead>
    //           <TableHead>
    //             <TableRow>
    //               {closingDetailsInfo.map(({ key, value }, index) => {
    //                 let output;
    //                 if (typeof value === 'string') {
    //                   output = value;
    //                 } else if (typeof value === 'number' && !isNaN(value)) {
    //                   output = value;
    //                 } else if (value && value.$isDayjsObject) {
    //                   output = value.format('MM-DD-YYYY');
    //                 } else {
    //                   output = 'N/A';
    //                 }

    //                 return (
    //                   <TableCell sx={{ color: '#282A2D', fontSize: '1rem', fontWeight: '600' }}>
    //                     {output}
    //                   </TableCell>
    //                 );
    //               })}
    //             </TableRow>
    //           </TableHead>
    //         </ProductTable>

    //         <Box
    //           sx={{
    //             display: 'flex',
    //             justifyContent: 'space-between',
    //             alignItems: 'center',
    //             backgroundColor: '#FFFFFF',
    //             padding: 2,
    //             borderRadius: '6px 6px 0 0',
    //             boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    //           }}
    //         >
    //           <Title sx={{ m: 0 }}>Title Firm</Title>
    //           <StyledEditIcon
    //             variant='outlined'
    //             onClick={toggleModal}
    //             disabled={isOnHoldOrCancelled}
    //             startIcon={<EditIcon />}
    //           >
    //             Title Firm
    //           </StyledEditIcon>
    //         </Box>
    //         <Divider />
    //         <ProductTable>
    //           <TableHead>
    //             <TableRow>
    //               {titleFirmDetails.map(({ key }, index) => {
    //                 return (
    //                   <>
    //                     <TableCell
    //                       sx={{ fontWeight: '0.875rem', fontSize: '500', color: '#17181B' }}
    //                     >
    //                       {key}
    //                     </TableCell>
    //                   </>
    //                 );
    //               })}
    //             </TableRow>
    //           </TableHead>
    //           <TableHead>
    //             <TableRow>
    //               {titleFirmDetails.map(({ key, value }, index) => {
    //                 let output;

    //                 if (typeof value === 'string') {
    //                   output = value;
    //                 } else if (typeof value === 'number' && !isNaN(value)) {
    //                   output = value;
    //                 } else if (value && value.$isDayjsObject) {
    //                   output = value.format('MM-DD-YYYY');
    //                 } else {
    //                   output = 'N/A';
    //                 }

    //                 return (
    //                   <TableCell sx={{ color: '#282A2D', fontSize: '1rem', fontWeight: '600' }}>
    //                     {output}
    //                   </TableCell>
    //                 );
    //               })}
    //             </TableRow>
    //           </TableHead>
    //         </ProductTable>

    //         <Box
    //           sx={{
    //             display: 'flex',
    //             justifyContent: 'space-between',
    //             alignItems: 'center',
    //             backgroundColor: '#FFFFFF',
    //             padding: 2,
    //             borderRadius: '6px 6px 0 0',
    //             boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    //           }}
    //         >
    //           <Title sx={{ m: 0 }}>Lender Details</Title>
    //           <StyledEditIcon
    //             variant='outlined'
    //             onClick={toggleModal}
    //             disabled={isOnHoldOrCancelled}
    //             startIcon={<EditIcon />}
    //           >
    //             Lender Details
    //           </StyledEditIcon>
    //         </Box>
    //         <Divider />
    //         <ProductTable>
    //           <TableHead>
    //             <TableRow>
    //               {lenderDetails.map(({ key }, index) => {
    //                 return (
    //                   <>
    //                     <TableCell
    //                       sx={{ fontWeight: '0.875rem', fontSize: '500', color: '#17181B' }}
    //                     >
    //                       {key}
    //                     </TableCell>
    //                   </>
    //                 );
    //               })}
    //             </TableRow>
    //           </TableHead>
    //           <TableHead>
    //             <TableRow>
    //               {lenderDetails.map(({ key, value }, index) => {
    //                 let output;

    //                 if (typeof value === 'string') {
    //                   output = value;
    //                 } else if (typeof value === 'number' && !isNaN(value)) {
    //                   output = value;
    //                 } else if (value && value.$isDayjsObject) {
    //                   output = value.format('MM-DD-YYYY');
    //                 } else {
    //                   output = 'N/A';
    //                 }

    //                 return (
    //                   <TableCell sx={{ color: '#282A2D', fontSize: '1rem', fontWeight: '600' }}>
    //                     {output}
    //                   </TableCell>
    //                 );
    //               })}
    //             </TableRow>
    //           </TableHead>
    //         </ProductTable>
    //       </>
    //     )}

    //   <Drawer
    //     anchor='right'
    //     open={open}
    //     onClose={toggleModal}
    //     PaperProps={{
    //       sx: {
    //         width: '40%',
    //         maxWidth: '600px',
    //         height: '100vh',
    //         boxShadow: '-5px 0px 10px rgba(0,0,0,0.2)',
    //         padding: 2,
    //       },
    //     }}
    //   >
    //     <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
    //       <h2>Contract Details</h2>
    //       <IconButton onClick={toggleModal}>
    //         <Close />
    //       </IconButton>
    //     </Box>

    //     <Formik
    //       initialValues={initialValues}
    //       validationSchema={validationSchemas.contract()}
    //       onSubmit={handleSubmit}
    //     >
    //       {({ values, isSubmitting, errors, touched, setFieldValue, handleBlur, handleChange }) => {
    //         const handleDateChange = (name, value) => {
    //           handleChange({ target: { name, value } });
    //         };

    //         const handlePriceChange = (e) => {
    //           const { name, value } = e.target;
    //           setFieldValue(name, formatDecimal(value, 2));
    //         };

    //         return (
    //           <Form style={{ flex: 1, overflowY: 'auto' }}>
    //             <Grid container spacing={2}>
    //               <LocalizationProvider dateAdapter={AdapterDayjs}>
    //                 <Grid item xs={12}>
    //                   <label>Effective Date</label>
    //                   <DatePicker
    //                     disablePast
    //                     name='effectiveDate'
    //                     value={values.effectiveDate}
    //                     onChange={(value) => handleDateChange('effectiveDate', value)}
    //                     slotProps={{
    //                       textField: {
    //                         fullWidth: true,
    //                         onBlur: handleBlur,
    //                         error: Boolean(touched.effectiveDate && errors.effectiveDate),
    //                         helperText: touched.effectiveDate && errors.effectiveDate,
    //                       },
    //                     }}
    //                     disabled={isSubmitting}
    //                   />
    //                 </Grid>
    //                 <Grid item xs={12}>
    //                   <label>Feasibility Due Date</label>
    //                   <DatePicker
    //                     disablePast
    //                     name='feasibilityEndDate'
    //                     minDate={calculateTomorrow(values.effectiveDate)}
    //                     value={values.feasibilityEndDate}
    //                     onChange={(value) => handleDateChange('feasibilityEndDate', value)}
    //                     slotProps={{
    //                       textField: {
    //                         fullWidth: true,
    //                         onBlur: handleBlur,
    //                         helperText: touched.feasibilityEndDate && errors.feasibilityEndDate,
    //                         error: Boolean(errors.feasibilityEndDate && touched.feasibilityEndDate),
    //                       },
    //                     }}
    //                     disabled={isSubmitting}
    //                   />
    //                 </Grid>
    //                 <Grid item xs={12}>
    //                   <label>Closing Date</label>
    //                   <DatePicker
    //                     disablePast
    //                     name='estimatedClosingDate'
    //                     minDate={calculateTomorrow(values.feasibilityEndDate)}
    //                     value={values.estimatedClosingDate}
    //                     onChange={(value) => handleDateChange('estimatedClosingDate', value)}
    //                     slotProps={{
    //                       textField: {
    //                         fullWidth: true,
    //                         onBlur: handleBlur,
    //                         helperText: touched.estimatedClosingDate && errors.estimatedClosingDate,
    //                         error: Boolean(
    //                           errors.estimatedClosingDate && touched.estimatedClosingDate,
    //                         ),
    //                       },
    //                     }}
    //                     disabled={isSubmitting}
    //                   />
    //                 </Grid>
    //               </LocalizationProvider>

    //               <Grid item xs={12}>
    //                 <label>Earnest Money</label>
    //                 <TextField
    //                   fullWidth
    //                   type='number'
    //                   name='earnestMoney'
    //                   value={values.earnestMoney}
    //                   onChange={handlePriceChange}
    //                   onBlur={handleBlur}
    //                   helperText={touched.earnestMoney && errors.earnestMoney}
    //                   error={Boolean(errors.earnestMoney && touched.earnestMoney)}
    //                   disabled={isSubmitting}
    //                 />
    //               </Grid>
    //               <Grid item xs={12}>
    //                 <label>Options Money</label>
    //                 <TextField
    //                   fullWidth
    //                   type='number'
    //                   name='optionsMoney'
    //                   value={values.optionsMoney}
    //                   onChange={handlePriceChange}
    //                   onBlur={handleBlur}
    //                   helperText={touched.optionsMoney && errors.optionsMoney}
    //                   error={Boolean(errors.optionsMoney && touched.optionsMoney)}
    //                   disabled={isSubmitting}
    //                 />
    //               </Grid>
    //             </Grid>

    //             {/* Footer Buttons */}
    //             <StyledBoxFooter>
    //               <ModalCancel variant='outlined' onClick={toggleModal} disabled={isSubmitting}>
    //                 Cancel
    //               </ModalCancel>
    //               <ModalSave variant='contained' color='primary' type='submit' disabled={isSubmitting}>
    //                 Update
    //               </ModalSave>
    //             </StyledBoxFooter>
    //           </Form>
    //         );
    //       }}
    //     </Formik>
    //   </Drawer>
    // </div>
    <>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          border: '1px solid #DADCE0',
        }}
      >
        <>
          {/* {(!expenses || (Array.isArray(expenses) && expenses.length === 0)) && (
            <>
              <Box
                sx={{ margin: '20px 38px 20px 20px' }}
                display='flex'
                justifyContent='space-between'
              >
                <StledCardTitle>Expenses</StledCardTitle>
                <StyledAddButton startIcon={<StyledIcon />} onClick={toggleModal}>
                  Expenses
                </StyledAddButton>
              </Box>
              <Divider />
            </>
          )} */}

          <Box
            style={{
              flex: 1,
              padding: '20px',
              marginTop: '5px',
              marginBottom: '5px',
              overflowX: 'auto',
            }}
          >
            {/* {!expenses || !Array.isArray(expenses) || expenses?.length === 0 ? (
              <div>
                <Typography fontSize='14px' fontWeight='500' color={'#3C4043'}>
                  No Expenses Available
                </Typography>
              </div>
            ) : ( */}
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid
                        item
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='center'
                        gap={0.5}
                      >
                        <Title>Contract Details</Title>
                      </Grid>
                      <Grid item>
                        <StyledEditIcon
                          variant='outlined'
                          onClick={toggleModal}
                          disabled={isOnHoldOrCancelled}
                          startIcon={<EditIcon />}
                        >
                          Contract Details
                        </StyledEditIcon>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TableFilter title={'Contract Details'} />
                    </Grid>
                    {/* </Card> */}
                  </Grid>

                  <Grid item xs={12}>
                    <ContractDetailsTable
                      contract={contract}
                      document={firstContractDoc}
                    />
                  </Grid>
                </Grid>
              </>
            {/* )} */}
          </Box>
        </>
      </Card>
      {/* {isOpenDialog && <AddUpdateExpensesDialog closeDialog={toggleModal} />} */}
      <Drawer
        anchor='right'
        open={open}
        onClose={toggleModal}
        PaperProps={{
          sx: {
            width: '40%',
            maxWidth: '600px',
            height: '100vh',
            boxShadow: '-5px 0px 10px rgba(0,0,0,0.2)',
            padding: 2,
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <h2>Contract Details</h2>
          <IconButton onClick={toggleModal}>
            <Close />
          </IconButton>
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.contract()}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, errors, touched, setFieldValue, handleBlur, handleChange }) => {
            const handleDateChange = (name, value) => {
              handleChange({ target: { name, value } });
            };

            const handlePriceChange = (e) => {
              const { name, value } = e.target;
              setFieldValue(name, formatDecimal(value, 2));
            };

            return (
              <Form style={{ flex: 1, overflowY: 'auto' }}>
                <Grid container spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12}>
                      <label>Effective Date</label>
                      <DatePicker
                        disablePast
                        name='effectiveDate'
                        value={values.effectiveDate}
                        onChange={(value) => handleDateChange('effectiveDate', value)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            onBlur: handleBlur,
                            error: Boolean(touched.effectiveDate && errors.effectiveDate),
                            helperText: touched.effectiveDate && errors.effectiveDate,
                          },
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label>Feasibility Due Date</label>
                      <DatePicker
                        disablePast
                        name='feasibilityEndDate'
                        minDate={calculateTomorrow(values.effectiveDate)}
                        value={values.feasibilityEndDate}
                        onChange={(value) => handleDateChange('feasibilityEndDate', value)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            onBlur: handleBlur,
                            helperText: touched.feasibilityEndDate && errors.feasibilityEndDate,
                            error: Boolean(errors.feasibilityEndDate && touched.feasibilityEndDate),
                          },
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label>Closing Date</label>
                      <DatePicker
                        disablePast
                        name='estimatedClosingDate'
                        minDate={calculateTomorrow(values.feasibilityEndDate)}
                        value={values.estimatedClosingDate}
                        onChange={(value) => handleDateChange('estimatedClosingDate', value)}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            onBlur: handleBlur,
                            helperText: touched.estimatedClosingDate && errors.estimatedClosingDate,
                            error: Boolean(
                              errors.estimatedClosingDate && touched.estimatedClosingDate,
                            ),
                          },
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </LocalizationProvider>

                  <Grid item xs={12}>
                    <label>Earnest Money</label>
                    <TextField
                      fullWidth
                      type='number'
                      name='earnestMoney'
                      value={values.earnestMoney}
                      onChange={handlePriceChange}
                      onBlur={handleBlur}
                      helperText={touched.earnestMoney && errors.earnestMoney}
                      error={Boolean(errors.earnestMoney && touched.earnestMoney)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Options Money</label>
                    <TextField
                      fullWidth
                      type='number'
                      name='optionsMoney'
                      value={values.optionsMoney}
                      onChange={handlePriceChange}
                      onBlur={handleBlur}
                      helperText={touched.optionsMoney && errors.optionsMoney}
                      error={Boolean(errors.optionsMoney && touched.optionsMoney)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>

                {/* Footer Buttons */}
                <StyledBoxFooter>
                  <ModalCancel variant='outlined' onClick={toggleModal} disabled={isSubmitting}>
                    Cancel
                  </ModalCancel>
                  <ModalSave variant='contained' color='primary' type='submit' disabled={isSubmitting}>
                    Update
                  </ModalSave>
                </StyledBoxFooter>
              </Form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};

export default LeadsContractDetails;
