import React, { useReducer, useRef } from 'react';
import {
  Box,
  Typography,
  Stack,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Tooltip,
  styled,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MembersAddEdit from '../LeadsData/Members/MembersAddEdit';
import { useProjectRoles } from '../LeadsData/Roles/hooks/useProjectRoles';
import { useServices } from 'app/hooks/useServices';
import { useProjectMembers } from '../LeadsData/Members/hooks/useProjectMembers';
import { useProject } from 'app/contexts/ProjectContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  StyledAddButton,
  StyledIcon,
  IconDivider,
  StledCardTitle,
  MembersCardBody,
  StyledAvatar,
  MembersBodyCard,
  GPName,
  GPRole,
  GPPhoneNumber,
  GPEmailId,
  StyledMenuIcon,
  ViewAllLink,
} from './StyledComponent';
import MemberView from '../LeadsData/Members/MemberView';
import { MailIcon } from 'app/common/icons';
import ChatHead from '../LeadsData/ChatBox/ChatHead';
import Chatbox from '../LeadsData/ChatBox/ChatBox';

const SET_MENU_AND_MODAL = 'SET_MENU_AND_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MENU_AND_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, ...action.payload.modalState },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalState: { ...state.modalState, [action.payload]: false },
      };
    default:
      return state;
  }
};

const initialState = {
  modalState: {
    add: false,
    edit: false,
    view: false,
  },
};

const MembersSection = ({ title }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedMemberRef = useRef(null);
  const navigate = useNavigate();
  const { projectId } = useParams();

  const { data: members } = useProjectMembers(projectId);
  const { data: services } = useServices();
  const { data: roles } = useProjectRoles(projectId, services);

  // Filter members based on title
  const filteredMembers = members?.filter((member) => {
    if (title === 'General Partner') {
      // For "General Partner", only show members with OWNER_ROLE
      return member.userRoles.includes('OWNER_ROLE');
    }
    // For other titles, exclude members with OWNER_ROLE
    return !member.userRoles.includes('OWNER_ROLE');
  });

  const membersToShow = filteredMembers?.slice(0, 3);

  const handleOpenModal = (action, member = null) => {
    selectedMemberRef.current = member;
    dispatch({
      type: SET_MENU_AND_MODAL,
      payload: { modalState: { [action]: true } },
    });
  };

  const handleCloseModal = (action) => {
    selectedMemberRef.current = null;
    dispatch({ type: CLOSE_MODAL, payload: action });
  };

  const getAvatarInitials = (name) => {
    const names = name.split(' ');
    const initials = names.length > 1 ? `${names[0][0]}${names[1][0]}` : names[0][0];
    return initials.toUpperCase();
  };

  // Function to generate background color based on email
  const getAvatarBackground = (email) => {
    const hashCode = email.split('').reduce((acc, char) => {
      acc = (acc << 5) - acc + char.charCodeAt(0);
      return acc & acc;
    }, 0);

    // Convert hash code to a color (for example purposes)
    return `#${((hashCode >> 24) & 0xff).toString(16)}${((hashCode >> 16) & 0xff).toString(16)}${(
      hashCode & 0xff
    ).toString(16)}`;
  };
  return (
    <>
      <Box
        sx={{ margin: '20px 38px 20px 20px' }}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <StledCardTitle>{title}</StledCardTitle>
        <StyledAddButton startIcon={<StyledIcon />} onClick={() => handleOpenModal('add')}>
          {title}
        </StyledAddButton>
      </Box>

      <IconDivider orientation='vertical' />
      <Box sx={{ overflow: 'auto', display: 'flex'}}>
        {membersToShow && membersToShow.length > 0 ? (
          membersToShow.map((member) => (
            <MembersCardBody key={member.id}>
              <MembersBodyCard>
                <CardContent sx={{ textAlign: 'center' }}>
                  <StyledMenuIcon onClick={() => handleOpenModal('edit', member)}>
                    <MoreVertIcon />
                  </StyledMenuIcon>
                  <StyledAvatar
                    sx={{
                      backgroundColor: getAvatarBackground(member.email),
                    }}
                  >
                    {getAvatarInitials(member.memberName)}
                  </StyledAvatar>
                  <GPName onClick={() => handleOpenModal('view', member)}>
                    {member.memberName}
                  </GPName>
                  <GPRole>{member?.userRoles[0]}</GPRole>
                  <GPPhoneNumber>{member.phone}</GPPhoneNumber>
                  <GPEmailId>{member.email}</GPEmailId>

                  {/* Contact Button */}
                  <Box mt={2}>
                    {/* <Button
                      variant='contained'
                      sx={{
                        backgroundColor: '#87CEEB',
                        color: '#645BBB',
                        width: '100%',
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                      startIcon={<MailIcon />}
                      onClick={() => {
                        const mailtoLink = `mailto:${member.email}`;
                        window.location.href = mailtoLink; // This will open the user's email client (Outlook in this case)
                      }} // replace with your contact logic
                    >
                      Contact
                    </Button> */}
                    <ChatHead
                      icon={
                        // <IconButton size="small" sx={{ my: "12px", color: "primary.contrastText" }}>
                        //   <MailIcon />
                        // </IconButton>

                        <Button
                          variant='contained'
                          sx={{
                            backgroundColor: '#87CEEB',
                            color: '#645BBB',
                            width: '100%',
                            textTransform: 'none',
                            fontWeight: 'bold',
                          }}
                          startIcon={<MailIcon color={"#645BBB"}/>}
                        >
                          Contact
                        </Button>
                      }>
                      <Chatbox />
                    </ChatHead>
                  </Box>
                </CardContent>
              </MembersBodyCard>
            </MembersCardBody>
          ))
        ) : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ width: '100%', height: '270px' }}
          >
            <Typography variant='h6' color='textSecondary'>
              No {title} Added
            </Typography>
          </Box>
        )}
      </Box>

      {membersToShow && membersToShow.length > 0 && (
        <MembersCardBody>
          <ViewAllLink
            type='button'
            onClick={() => navigate(title === 'Members' ? '../members' : '../generalpartners')}
          >
            View all {title.toLowerCase() + (title === 'Members' ? '' : 's')}
          </ViewAllLink>
        </MembersCardBody>
      )}

      {(state.modalState.add || state.modalState.edit) && (
        <MembersAddEdit
          selectedMember={selectedMemberRef.current}
          roles={roles}
          projectMembers={members}
          closeModal={() => handleCloseModal(state.modalState.add ? 'add' : 'edit')}
          title={title}
        />
      )}
      {state.modalState.view && (
        <MemberView
          member={selectedMemberRef.current}
          closeModal={() => handleCloseModal('view')}
        />
      )}
    </>
  );
};

export default MembersSection;
