import React from 'react';
import { Box, Button, Grid, TextField, Modal, IconButton, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'app/common/Typography';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useProject } from 'app/contexts/ProjectContext';
import { useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { parseAddressComponents } from 'app/utils/helpers';
import notify from 'app/utils/notify';
import { getAllLeaseDetais } from 'app/contexts/leadActions';
import { StandaloneSearchBox } from '@react-google-maps/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const ModalDivider = styled('div')({
  height: '1px',
  marginBottom: '5px',
  backgroundColor: '#D5D5DD',
});

const validationSchema = Yup.object().shape({
  LLCName: Yup.string()
    .required('LLC Name is required'),

  LLCNumber: Yup.string()
    .matches(/^\d+$/, 'LLC Number must be numeric')
    .required('LLC Number is required'),

  LLCPhoneNumber: Yup.string()
    .matches(/^\d{10}$/, 'Phone Number must be 10 digits')
    .required('Phone Number is required'),

  LLCEmail: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),

  LLCAddress1: Yup.string().required('LLC address1 is required'),
  LLCAddress2: Yup.string(),

  LLCCity: Yup.string().required('city is required'),
  LLCState: Yup.string().required('state is required'),
  LLCCountry: Yup.string().required('country is required'),
  LLCZIPCode: Yup.string()
    .required('Zip Code is required'),
  contactPerson: Yup.string().required('Contact Person is required'),

  tenantEmail: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),

  tenantPhoneNo: Yup.string()
    .matches(/^\d{10}$/, 'Phone Number must be 10 digits')
    .required('Phone Number is required'),

  address1: Yup.string().required('address1 is required'),
  address2: Yup.string(),

  city: Yup.string().required('city is required'),
  state: Yup.string().required('state is required'),
  country: Yup.string().required('country is required'),

  ZIPCode: Yup.string()
  .required('Zip Code is required'),
  
});


const LeadAddLeaseForm = ({
  openModal,
  handleCancel,
  leaseDetails,
  selectedBuilding,
  selectedUnit,
  setLeaseDetailsList,
  tenantDetails1,
  selectedunitIndex,
  buildingIndex,
  onBack,
  leaseIndex,
}) => {
  const { addLeaseDetails, uploadDocuments, leasesales } = useProject();
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { projectId } = useParams();
  const [leaseDetails1, setLeaseDetails1] = React.useState([]);
  const mapRef = React.useRef(null);
  const markerRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  const mapRef1 = React.useRef(null);
  const markerRef1 = React.useRef(null);
  const searchInputRef1 = React.useRef(null);

  const parseAddressComponents1 = (place) => {
    let LLCAddress1 = '';
    let LLCAddress2 = '';
    let LLCCountry = '';
    let LLCCity = '';
    let LLCState = '';
    let LLCZIPCode = '';
    let latitude = null;
    let longitude = null;
  
    if (place.address_components) {
      const {
        address_components,
        geometry: { location },
        name,
      } = place;
      const { lat, lng } = location;
      latitude = lat();
      longitude = lng();
  
      address_components.forEach((component) => {
        const [type] = component.types;
        const longName = component.long_name;
  
        switch (type) {
          case 'landmark':
          case 'subpremise':
          case 'premise':
            LLCAddress2 += LLCAddress2 ? `, ${longName}` : longName;
            break;
          case 'route':
          case 'street_address':
          case 'street_number':
            LLCAddress1 += LLCAddress1 ? `, ${longName}` : longName;
            break;
          case 'country':
            LLCCountry = longName;
            break;
          case 'locality':
          case 'postal_town':
          case 'administrative_area_level_3':
            LLCCity ||= longName;
            break;
          case 'administrative_area_level_1':
            LLCState = longName;
            break;
          case 'postal_code':
            LLCZIPCode = longName;
            break;
          default:
            break;
        }
      });
  
      if (!LLCAddress1 && name) {
        LLCAddress1 = name;
      }
    }
    return {
      LLCAddress1,
      LLCAddress2,
      LLCCountry,
      LLCCity,
      LLCState,
      LLCZIPCode,
      latitude,
      longitude,
    };
  };

  const handlePlacesChanged = (setValues) => {
    const [place] = searchInputRef.current.getPlaces();
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const addressData = parseAddressComponents1(place);


    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));

    if (markerRef.current) {
      markerRef.current.setPosition({ lat, lng });
    }

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  const convertToUTC = (date) => {
    const localDate = new Date(date);
    const utcDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000);
    return utcDate.toISOString();
  };
  const handlePlacesChanged1 = (setValues) => {
    const [place] = searchInputRef1.current.getPlaces();
    if (!place) return;

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    const addressData = parseAddressComponents(place);

    setValues((prevState) => ({
      ...prevState,
      ...addressData,
    }));

    if (markerRef1.current) {
      markerRef1.current.setPosition({ lat, lng });
    }

    if (mapRef1.current) {
      mapRef1.current.panTo({ lat, lng });
    }
  };

  React.useEffect(() => {
    if (projectId) {
      fetchLeaseDetails();
    }
  }, [projectId]);

  const fetchLeaseDetails = async () => {
    try {
      const leaseData = await getAllLeaseDetais(projectId);
      const filteredSales = leaseData.filter(
        (sale) => sale?.unitId === leasesales[buildingIndex]?.units[selectedunitIndex]?.unitId,
      );
      setLeaseDetails1(filteredSales);
    } catch (error) {
      notify.error(error);
    }
  };

  const initialValues = {
    LLCName: tenantDetails1?.llcDetails?.llcName || '',
    LLCNumber: tenantDetails1?.llcDetails?.llcNumber || '',
    LLCPhoneNumber: tenantDetails1?.llcDetails?.phoneNo || '',
    LLCEmail: tenantDetails1?.llcDetails?.email || '',
    LLCAddress1: tenantDetails1?.llcDetails?.address || '',
    LLCAddress2: tenantDetails1?.llcDetails?.LLCAddress2 || '',
    LLCCity: tenantDetails1?.llcDetails?.city || '',
    LLCState: tenantDetails1?.llcDetails?.state || '',
    LLCCountry: tenantDetails1?.llcDetails?.country || '',
    LLCZIPCode: tenantDetails1?.llcDetails?.zipCode || '',

    contactPerson: tenantDetails1?.contactPerson || '',
    tenantEmail: tenantDetails1?.email || '',
    tenantPhoneNo: tenantDetails1?.phoneNo || '',
    address1: tenantDetails1?.address || '',
    address2: tenantDetails1?.address2 || '',
    city: tenantDetails1?.city || '',
    state: tenantDetails1?.state || '',
    country: tenantDetails1?.country || '',
    zipCode: tenantDetails1?.zipCode || '',
  };

  const handleSave = async (values) => {
    const data = new FormData();
    const leaseDocumentids = [];

    for (let index = 0; index < leaseDetails?.leaseDocuments.length; index++) {
      const doc = leaseDetails?.leaseDocuments[index];

      if (doc.file instanceof File) {
        data.append(`docReq[${index}].file`, doc.file);
        data.append(`docReq[${index}].docName`, doc.docName);
        data.append(`docReq[${index}].type`, 'Lease Documents');
      } else {
        leaseDocumentids.push(leaseDetails?.leaseDocuments[index]);
      }
    }

    try {
      setSubmitting(true);
      if (data.has('docReq[0].file')) {
        const uploadResponse = await uploadDocuments(data);
        const uploadedDocumentsIds = uploadResponse.data
          .match(/Document IDs: \[([^\]]+)\]/)[1]
          .split(', ');
        leaseDocumentids.push(...uploadedDocumentsIds);
      }
      const tenantDetails = {
        contactPerson: values.contactPerson,
        email: values.tenantEmail,
        phoneNo: values.tenantPhoneNo,
        address: values.address1,
        country: values.country,
        state: values.state,
        city: values.city,
        zipCode: values.zipCode,
        llcDetails: {
          llcName: values.LLCName,
          llcNumber: values.LLCNumber,
          email: values.LLCEmail,
          phoneNo: values.LLCPhoneNumber,
          address: values.LLCAddress1,
          country: values.LLCCountry,
          state: values.LLCState,
          city: values.LLCCity,
          zipCode: values.LLCZIPCode,
        },
      };

      const finalObject = {
        buildingName: selectedBuilding?.buildingName,
        buildingId: selectedBuilding?.id,
        unitId: selectedUnit?.unitId,
        unitDetails: {
          tenantDetails: tenantDetails,
          leaseDetails: {
            ...leaseDetails,
            leaseDocuments: leaseDocumentids,
          },
        },
      };

      const updatedObject = {
        buildingName: leaseDetails1[leaseIndex]?.buildingName,
        buildingId: leaseDetails1[leaseIndex]?.buildingId,
        unitId: leaseDetails1[leaseIndex]?.unitId,
        id: leaseDetails1[leaseIndex]?.id,
        unitDetails: {
          tenantDetails: {
            ...leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails,
            contactPerson:
              values.contactPerson ||
              leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.contactPerson,
            email:
              values.tenantEmail ||
              leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.tenantEmail,
            phoneNo:
              values.tenantPhoneNo ||
              leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.tenantPhoneNo,
            address:
              values.address1 || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.address1,
            country:
              values.country || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.country,
            state: values.state || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.state,
            city: values.city || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.city,
            zipCode:
              values.zipCode || leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.zipCode,
            llcDetails: {
              ...leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails,
              llcName:
                values.LLCName ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCName,
              llcNumber:
                values.LLCNumber ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCNumber,
              email:
                values.LLCEmail ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCEmail,
              phoneNo:
                values.LLCPhoneNumber ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCPhoneNumber,
              address:
                values.LLCAddress1 ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCAddress1,
              country:
                values.LLCCountry ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCCountry,
              state:
                values.LLCState ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCState,
              city:
                values.LLCCity ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCCity,
              zipCode:
                values.LLCZIPCode ||
                leaseDetails1[leaseIndex]?.unitDetails?.tenantDetails.llcDetails.LLCZIPCode,
            },
          },
          leaseDetails: {
            ...leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails,
            leaseSpace:
              leaseDetails?.leaseSpace ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseSpace,
            rentSqFtPerYear:
              leaseDetails?.rentSqFtPerYear ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.rentSqFtPerYear,
            leaseTerm:
              leaseDetails?.leaseTerm ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseTerm,
            rentIncreasePerYear:
              leaseDetails?.rentIncreasePerYear ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.rentIncreasePerYear,
            leaseExecutionDate:
              convertToUTC(leaseDetails?.leaseExecutionDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseExecutionDate,
            leaseFixturizationDate:
              convertToUTC(leaseDetails?.leaseFixturizationDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseFixturizationDate,
            leaseStartDate:
              convertToUTC(leaseDetails?.leaseStartDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseStartDate,
            leaseEndDate:
              convertToUTC(leaseDetails?.leaseEndDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseEndDate,
            currentMonthRent:
              leaseDetails?.currentMonthRent ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.currentMonthRent,
            nnn: leaseDetails?.nnn || leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.nnn,
            // securityDeposit: leaseDetails?.securityDeposit || leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.securityDeposit,
            // rentPerYear: leaseDetails?.leaseDetails || leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseDetails,
            securityDeposit:
              leaseDetails?.securityDeposit ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.securityDeposit,
            firstMonthRentDate:
              convertToUTC(leaseDetails?.firstMonthRentDate) ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.firstMonthRentDate,
            realtorCommission:
              leaseDetails?.realtorCommission ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.realtorCommission,
            realtorRebate:
              leaseDetails?.realtorRebate ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.realtorRebate,
            leaseDocuments:
              leaseDocumentids ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.leaseDocuments,
            comments:
              leaseDetails?.comments ||
              leaseDetails1[leaseIndex]?.unitDetails?.leaseDetails.comments,
            rentCommencementDate: '2024-06-01T10:30:00',
            freeRentMonths: 5,
            rentPerYear: '100',
          },
        },
      };

      if (tenantDetails1) {
        await addLeaseDetails(updatedObject);
        handleCancel();
      } else {
        await addLeaseDetails(finalObject);
        setLeaseDetailsList((prevList) => [...prevList, finalObject]);
        handleCancel();
      }
    } catch (error) {
      if (error.response.data.error) {
        notify.error(error.response.data.errors[0].errorMessage);
      } else {
        notify.error(`Error in creating expense: ${error.message}`);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={openModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto',
          height: '90vh',
        }}
      >
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Title>{'Add Tenant & LLC Details'}</Title>
          <IconButton onClick={handleCancel}>
            <CloseIcon style={{ fontSize: 18, color: '#272937' }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', marginLeft: '15px' }} mb={2}>
          <Typography sx={{ fontWeight: '500', fontSize: '16px', color: '#272937' }}>
            LLC Details
          </Typography>
        </Box>
        <ModalDivider />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setValues,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <Grid container spacing={3} p={2}>
                {/* LLC Details Section */}
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Name'
                    type='text'
                    name='LLCName'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\",";",":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }} 
                    error={touched.LLCName && Boolean(errors.LLCName)}
                    helperText={<ErrorMessage name='LLCName' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Number'
                    type='text'
                    name='LLCNumber'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\",";",":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }} 
                    error={touched.LLCNumber && Boolean(errors.LLCNumber)}
                    helperText={<ErrorMessage name='LLCNumber' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    type='number'
                    label='LLC Phone Number'
                    name='LLCPhoneNumber'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCPhoneNumber && Boolean(errors.LLCPhoneNumber)}
                    helperText={<ErrorMessage name='LLCPhoneNumber' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC Email'
                    type='text'
                    name='LLCEmail'
                    fullWidth
                    error={touched.LLCEmail && Boolean(errors.LLCEmail)}
                    helperText={<ErrorMessage name='LLCEmail' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef.current = searchBox)}
                    onUnmount={() => (searchInputRef.current = null)}
                    onPlacesChanged={() => handlePlacesChanged(setValues)}
                  >
                    <TextField
                      sx={{
                        marginTop: '0',
                        '& .MuiOutlinedInput-root': {
                          height: '36px',
                          padding: '0px',
                          '& input': {
                            padding: '8px 12px',
                          },
                        },
                        '& .MuiInputBase-input': {
                          height: 'auto',
                        },
                      }}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      type='search'
                      name='LLCAddress1'
                      label='LLC Address 1'
                      id='standard-basic'
                      placeholder='LLC address1'
                      value={values?.LLCAddress1}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (!value) {
                          setValues((prevState) => ({
                            ...prevState,
                            search: '',
                            LLCAddress1: '',
                            LLCCountry: '',
                            LLCCity: '',
                            LLCState: '',
                            LLCZIPCode: '',
                            latitude: null,
                            longitude: null,
                          }));
                          if (markerRef.current) {
                            markerRef.current.setPosition(null);
                          }
                        }
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      helperText={touched.LLCAddress1 && errors.LLCAddress1}
                      error={Boolean(errors.LLCAddress1 && touched.LLCAddress1)}
                      disabled={isSubmitting}
                    />
                  </StandaloneSearchBox>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    label='LLC Address 2'
                    name='LLCAddress2'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\",";",":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }} 
                    error={touched.LLCAddress2 && Boolean(errors.LLCAddress2)}
                    helperText={<ErrorMessage name='LLCAddress2' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    label='LLC city'
                    name='LLCCity'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}

                    error={touched.LLCCity && Boolean(errors.LLCCity)}
                    helperText={<ErrorMessage name='LLCCity' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='LLC state'
                    type='text'
                    name='LLCState'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCState && Boolean(errors.LLCState)}
                    helperText={<ErrorMessage name='LLCState' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    label='LLC country'
                    name='LLCCountry'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.LLCCountry && Boolean(errors.LLCCountry)}
                    helperText={<ErrorMessage name='LLCCountry' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size="small"
                    label="ZIP Code"
                    name="LLCZIPCode"
                    fullWidth

                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restrict input to numbers only
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}

                    error={touched.LLCZIPCode && Boolean(errors.LLCZIPCode)}
                    helperText={<ErrorMessage name='LLCZIPCode' />}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: '500', fontSize: '16px', color: '#272937' }}
                  >
                    Tenant Contact Information
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    type='text'
                    label='Contact Person'
                    name='contactPerson'
                    fullWidth
                    error={touched.contactPerson && Boolean(errors.contactPerson)}
                    helperText={<ErrorMessage name='contactPerson' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='Email'
                    type='text'
                    name='tenantEmail'
                    fullWidth
                    error={touched.tenantEmail && Boolean(errors.tenantEmail)}
                    helperText={<ErrorMessage name='tenantEmail' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='Phone Number'
                    type='number'
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "e", "E", "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    name='tenantPhoneNo'
                    fullWidth
                    error={touched.tenantPhoneNo && Boolean(errors.tenantPhoneNo)}
                    helperText={<ErrorMessage name='tenantPhoneNo' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StandaloneSearchBox
                    onLoad={(searchBox) => (searchInputRef1.current = searchBox)}
                    onUnmount={() => (searchInputRef1.current = null)}
                    onPlacesChanged={() => handlePlacesChanged1(setValues)}
                  >
                    <TextField
                      sx={{
                        marginTop: '0',
                        '& .MuiOutlinedInput-root': {
                          height: '36px',
                          padding: '0px',
                          '& input': {
                            padding: '8px 12px',
                          },
                        },
                        '& .MuiInputBase-input': {
                          height: 'auto',
                        },
                      }}
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      type='search'
                      name='address1'
                      label='Address 1'
                      id='standard-basic'
                      placeholder='address1'
                      value={values?.address1}
                      onChange={(e) => {
                        const { value } = e.target;

                        if (!value) {
                          setValues((prevState) => ({
                            ...prevState,
                            search: '',
                            address1: '',
                            country: '',
                            city: '',
                            state: '',
                            zipCode: '',
                            latitude: null,
                            longitude: null,
                          }));
                          if (markerRef1.current) {
                            markerRef1.current.setPosition(null);
                          }
                        }
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      helperText={touched.address1 && errors.address1}
                      error={Boolean(errors.address1 && touched.address1)}
                      disabled={isSubmitting}
                    />
                  </StandaloneSearchBox>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='Address 2'
                    name='address2'
                    type='text'
                    fullWidth
                    error={touched.address2 && Boolean(errors.address2)}
                    helperText={<ErrorMessage name='address2' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='city'
                    type='text'
                    name='city'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    error={touched.city && Boolean(errors.city)}
                    helperText={<ErrorMessage name='city' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='state'
                    type='text'
                    name='state'
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    error={touched.state && Boolean(errors.state)}
                    helperText={<ErrorMessage name='state' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='country'
                    name='country'
                    type='text'
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        "+", "-", ".", "/", "'", "[", "]", "{", "}", "\\", ";", ":"
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    error={touched.country && Boolean(errors.country)}
                    helperText={<ErrorMessage name='country' />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    size='small'
                    label='ZIP Code'
                    type='text'
                    name='ZIPCode'
                    fullWidth
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    helperText={<ErrorMessage name='zipCode' />}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >

                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Button variant="text" color="inherit" onClick={onBack} startIcon={<ArrowBackIcon size='small' />}>
                      Back
                    </Button>
                    <LoadingButton
                      variant="outlined"
                      color="error"
                      disabled={isSubmitting}
                      onClick={handleCancel}
                    >
                      Cancel
                    </LoadingButton>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Grid>

              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default LeadAddLeaseForm;
