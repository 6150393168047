// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestActions_respondButtons__FoMGZ {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  gap: 0.7rem;
  & .RequestActions_button__p9\\+Wn {
    border-radius: 2px;
  }
  & .RequestActions_acceptButton__bTyHe {
    background-color: #21c313;
    &:hover {
      background-color: #1ca910;
    }
  }
  & .RequestActions_declineButton__y-mYz {
    background-color: #e93445;

    &:hover {
      background-color: #d12f3e;
    }
  }

  & .RequestActions_cancelButton__5d21F {
    background-color: #e49e27;
    &:hover {
      background-color: #d18d22;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/views/dashboard/Requests/modules/RequestActions.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,WAAW;EACX;IACE,kBAAkB;EACpB;EACA;IACE,yBAAyB;IACzB;MACE,yBAAyB;IAC3B;EACF;EACA;IACE,yBAAyB;;IAEzB;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE,yBAAyB;IACzB;MACE,yBAAyB;IAC3B;EACF;AACF","sourcesContent":[".respondButtons {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  font-weight: 500;\r\n  gap: 0.7rem;\r\n  & .button {\r\n    border-radius: 2px;\r\n  }\r\n  & .acceptButton {\r\n    background-color: #21c313;\r\n    &:hover {\r\n      background-color: #1ca910;\r\n    }\r\n  }\r\n  & .declineButton {\r\n    background-color: #e93445;\r\n\r\n    &:hover {\r\n      background-color: #d12f3e;\r\n    }\r\n  }\r\n\r\n  & .cancelButton {\r\n    background-color: #e49e27;\r\n    &:hover {\r\n      background-color: #d18d22;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"respondButtons": `RequestActions_respondButtons__FoMGZ`,
	"button": `RequestActions_button__p9+Wn`,
	"acceptButton": `RequestActions_acceptButton__bTyHe`,
	"declineButton": `RequestActions_declineButton__y-mYz`,
	"cancelButton": `RequestActions_cancelButton__5d21F`
};
export default ___CSS_LOADER_EXPORT___;
