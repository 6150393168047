import React, { useState } from 'react';
import LeaseAddEdit from './LeaseAddEdit';
import { Grid, Typography, Box, IconButton, Divider, Card } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LeadDeleteConfirmation from 'app/common/LeadsDeleteConfirmation';
import { convertUTCDateToLocal } from 'app/utils/helpers';
import { useProjectBuildingUnitLeaseDelete } from './hooks/useProjectBuildingUnitLeaseDelete';
import { useProject } from 'app/contexts/ProjectContext';
import { SideNavMenu } from 'app/components';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Span } from 'app/common/Typography';
import { DescriptionIcon, EditIcon } from 'app/common/icons';
import DownloadIcon from '@mui/icons-material/Download';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments';
import { handleDocDownload } from 'app/utils/constant';
const LeaseCard = ({ leaseId, unitDetails, selectedBuilding, selectedUnit }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = (action) => setOpenModal(action);
  const handleClose = () => setOpenModal(null);
  const leaseData = unitDetails?.leaseDetails || {};
  const tenantData = unitDetails?.tenantDetails || {};

  const { projectId } = useProject();
  const { mutateAsync: deleteLease } = useProjectBuildingUnitLeaseDelete(projectId);

  const { data: documents } = useProjectDocuments(projectId);

  const matchedDocuments =
    leaseData?.leaseDocuments?.map((docId) => {
      return documents.find((doc) => doc.metadata.fileId === docId);
    }) || [];
  const firstMatchedDocument = matchedDocuments.find((doc) => doc !== undefined);

  const handleDelete = async () => await deleteLease(leaseId);

  const getLeasePeriod = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    return { years, months };
  };

  const { years, months } = getLeasePeriod(
    convertUTCDateToLocal(new Date(...leaseData?.leaseStartDate)),
    convertUTCDateToLocal(new Date(...leaseData?.leaseEndDate)),
  );

  return (
    <div>
      <Card style={styles.leaseCard}>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent='space-between'
              color='#565960'
              alignItems='center'
              paddingX={2}
              paddingY={1}
            >
              <Typography variant='body' color={'#17181B'} fontSize={'16px'}>
                Lease Space: <strong>{leaseData.leaseSpace} Sq fts</strong>
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <SideNavMenu
                  menuButton={
                    <UserMenu>
                      <MoreVertIcon />
                    </UserMenu>
                  }
                >
                  <StyledItem onClick={() => handleOpen('edit')}>
                    <EditIcon />
                    <Span style={{ color: '#645BBB', marginLeft: '5px' }}>Edit</Span>
                  </StyledItem>
                  <StyledItem onClick={() => handleOpen('delete')}>
                    <img
                      src='/assets/images/Delete_icon.svg'
                      alt=''
                      style={{ height: '13px', marginBottom: '1px' }}
                    />
                    <Span style={{ color: '#CE443C', marginLeft: '5px' }}>Delete</Span>
                  </StyledItem>
                </SideNavMenu>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ width: '100%' }} />
          <Grid item xs={12}>
            {/* <Grid
              container
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              paddingX={2}
              paddingBottom={2}
            > */}
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#17181B',
                fontSize: '14px',
                paddingX: '20px',
              }}
              variant='body2'
            >
              <div>
                <span>
                  {leaseData.rentSqFtPerYear && <strong>${leaseData.rentSqFtPerYear}</strong>}
                  {leaseData.rentSqFtPerYear && ' Rent/Sq ft per year'}
                  {leaseData.rentSqFtPerYear && (
                    <span style={{ margin: '0 8px 0px 10px' }}> • </span>
                  )}

                  {leaseData.leaseTerm && <strong>{leaseData.leaseTerm} Months</strong>}
                  {leaseData.leaseTerm && ' Lease Term'}
                  {leaseData.leaseTerm && <span style={{ margin: '0 8px 0px 10px' }}> • </span>}

                  {leaseData?.securityDeposit && <strong>${leaseData.securityDeposit}</strong>}
                  {leaseData.securityDeposit && ' Security Deposit'}
                </span>
              </div>
            </Typography>

            {/* <Typography
                fontSize='13px'
                fontWeight='500'
                variant='body2'
                backgroundColor='#82B485'
                color='#fff'
                borderRadius='5px'
                p={1}
                marginTop={1}
                display='inline-block'
              >
                Lease Period: {years} {years > 1 ? 'Years' : 'Year'}{' '}
                {months > 0 && `and ${months} ${months > 1 ? 'Months' : 'Month'}`} i.e.{' '}
                {convertUTCDateToLocal(new Date(...leaseData?.leaseStartDate))} to{' '}
                {convertUTCDateToLocal(new Date(...leaseData?.leaseEndDate))}
              </Typography> */}
            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
                paddingBottom={2}
                paddingX={2}
              >
                {/* Lease Period Section (Left) */}
                <Grid item xs={8}>
                  <Typography
                    fontSize='13px'
                    fontWeight='500'
                    variant='body2'
                    backgroundColor='#82B485'
                    color='#fff'
                    borderRadius='5px'
                    p={1}
                    marginTop={1}
                    display='inline-block'
                  >
                    Lease Period: {years} {years > 1 ? 'Years' : 'Year'}{' '}
                    {months > 0 && `and ${months} ${months > 1 ? 'Months' : 'Month'}`} i.e.{' '}
                    {convertUTCDateToLocal(new Date(...leaseData?.leaseStartDate))} to{' '}
                    {convertUTCDateToLocal(new Date(...leaseData?.leaseEndDate))}
                  </Typography>
                </Grid>

                {/* Document Section (Right) */}
                {firstMatchedDocument && (
                  <Grid
                    item
                    xs={4}
                    container
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ borderRadius: '8px', backgroundColor: '#66D0B3', marginTop: '10px' }}
                    paddingX={2}
                  >
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                      <DescriptionIcon sx={{ color: '#4A90E2', fontSize: '1.75rem' }} />
                      <Typography
                        fontSize='13px'
                        fontWeight='500'
                        variant='body2'
                        color={'#000'}
                        sx={{ marginLeft: '2px' }}
                      >
                        {firstMatchedDocument
                          ? firstMatchedDocument.metadata.documentName
                          : 'Lease Document'}
                      </Typography>
                    </Grid>

                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        onClick={() =>
                          handleDocDownload(
                            firstMatchedDocument.content,
                            `${firstMatchedDocument.metadata.documentName}`,
                            firstMatchedDocument.metadata.documentType,
                          )
                        }
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton>
                        <VisibilityIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Divider sx={{ width: '100%' }} />
          <Grid item xs={6} sx={{ marginLeft: '20px' }}>
            <Typography variant='subtitle2'>Tenant Details</Typography>
            <Typography variant='body2' fontWeight={600}>
              {tenantData?.llcDetails?.llcName || 'N/A'}
            </Typography>
            <Typography variant='body2'>{tenantData?.llcDetails?.phoneNo}</Typography>
            <Typography variant='body2'>{tenantData?.llcDetails?.email}</Typography>
            <Typography variant='body2'>
              {tenantData?.llcDetails?.address || 'No Address'}, {tenantData?.llcDetails?.city},{' '}
              {tenantData?.llcDetails?.state}, {tenantData?.llcDetails?.country},{' '}
              {tenantData?.llcDetails?.zipCode}
            </Typography>
          </Grid>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ borderRightWidth: 2, borderColor: '#DADCE0' }}
          />
          <Grid item xs={5}>
            <Typography variant='subtitle2'>Contact Person</Typography>
            <Typography variant='body2' fontWeight={600}>
              {tenantData?.contactPerson || 'N/A'}
            </Typography>
            <Typography variant='body2'>{tenantData?.phoneNo}</Typography>
            <Typography variant='body2'>{tenantData?.email}</Typography>
            <Typography variant='body2'>
              {tenantData?.address || 'No Address'}, {tenantData?.city}, {tenantData?.state},{' '}
              {tenantData?.country}, {tenantData?.zipCode}
            </Typography>
          </Grid>
          <Divider sx={{ width: '100%' }} />
          <Grid container spacing={2} sx={{ marginLeft: '20px' }}>
            {/* Left Section: Comments */}
            <Grid item xs={12}>
              <Typography variant='subtitle2'>Comments</Typography>
              <Typography p={1} paddingLeft={0} variant='body2' fontWeight='500'>
                {leaseData?.comments || 'No Comments Added'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <LeaseAddEdit
        open={openModal === 'edit'}
        onClose={handleClose}
        tenantData={tenantData}
        leaseData={leaseData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
        leaseId={leaseId}
        isEdit={true}
      />
      <LeadDeleteConfirmation
        open={openModal === 'delete'}
        onClose={handleClose}
        name={'Lease'}
        from={`from building ${selectedBuilding?.buildingName}`}
        handleSubmit={handleDelete}
      />
    </div>
  );
};

const styles = {
  leaseCard: {
    border: '1px solid #e0e0e0',
    borderRadius: 8,
    backgroundColor: '#F8F9FA',
  },
  section: {
    borderTop: '1px solid #e0e0e0',
    paddingTop: 8,
    marginTop: 8,
  },
};

export default LeaseCard;
