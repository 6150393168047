import React, { useEffect, useState } from 'react';
import { Grid, TablePagination, useTheme } from '@mui/material';
import { Fragment } from 'react';
import NavigationHeader from 'app/common/navigationHeader';
import { CenteredText } from 'app/common/Typography';
import { ProjectCard } from 'app/common/ProjectCard';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  fetchCancelledLeadsWithPagination,
  fetchCompletedLeadsWithPagination,
  fetchLeadsDocByUserId,
  fetchLeadsWithPagination,
  fetchNewLeadsWithPagination,
  fetchOngoingLeadsWithPagination,
  fetchOnHoldLeadsWithPagination,
} from 'app/contexts/leadActions';
import { Loading } from 'app/components';
import LeadsFiltericon from './LeadsData/LeadsFiltericon';
import '../dashboard.css';
import './NewLeads/styles.css';
import { handleAxiosError } from 'app/utils/helpers';
import { selectSearchValue } from 'app/redux/store';
import { useSelector } from 'react-redux';

const projectTypeToFetchFunction = {
  lead: fetchLeadsWithPagination,
  newproject: fetchNewLeadsWithPagination,
  ongoing: fetchOngoingLeadsWithPagination,
  cancelled: fetchCancelledLeadsWithPagination,
  onhold: fetchOnHoldLeadsWithPagination,
  completed: fetchCompletedLeadsWithPagination,
};

const Projects = ({ type }) => {
  const navigate = useNavigate();
  const searchValue = useSelector(selectSearchValue);

  const location = useLocation();
  const { palette } = useTheme();
  const [projects, setProjects] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const fetchProjectsData = async () => {
    try {
      setLoading(true);
      const fetchProjects = projectTypeToFetchFunction[type];
      const projectsList = await fetchProjects(pageNo + 1, pageSize);

      try {
        const projectsImages = await fetchLeadsDocByUserId();

        const filteredImages = projectsImages.filter((image) =>
          image.metadata.documentType.startsWith('image/'),
        );
        const imagesMap = new Map();

        filteredImages.forEach((image) => {
          const projectId = image.metadata.projectId;
          if (!imagesMap.has(projectId)) {
            imagesMap.set(projectId, []);
          }
          imagesMap.get(projectId).push(image);
        });

        projectsList?.records?.forEach((project) => {
          project.images = imagesMap.get(project.id) || [];
        });
      } catch (error) {
        throw error;
      }

      setProjects(projectsList);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjectsData();
  }, [pageNo, pageSize, location.pathname]);

  useEffect(() => {
    const filteredProjects = projects?.records?.filter((project) =>
      project?.projectTitle?.toLowerCase().includes(searchValue?.toLowerCase()),
    );

    setFilteredProjects(filteredProjects || []);
  }, [searchValue, projects]);

  const renderProjects = () => {
    if (loading) {
      return <Loading />;
    }

    if (!filteredProjects || filteredProjects.length === 0) {
      return (
        <div className='ContentBox'>
          <CenteredText>
            <img src='/assets/images/leads.png' alt='No Leads' />
            <div className='noleads'>
              <strong style={{ color: palette.text.primary }}>No Leads available</strong>
            </div>
            <div className='noleadssub'>It seems you have not created any leads yet.</div>
          </CenteredText>
        </div>
      );
    }

    return (
      <Grid container m={0} paddingLeft={2}>
        {filteredProjects.map((project) => (
          <div
            key={project.id}
            className='ProjectCardContainer'
            onClick={() => {
              navigate(`../project/${project.id}`);
            }}
          >
            <ProjectCard
              title={project.projectTitle}
              keyValuePairs={[
                { key: 'PROPERTY TYPE', value: project.propertyType },
                { key: 'SUB TYPE', value: project.subType },
                { key: 'PROPERTY SIZE', value: `${project.size} Acres` },
              ]}
              additionalNumbers={project.ownerId}
              images={project.images}
              reason={project.reason}
            />
          </div>
        ))}
      </Grid>
    );
  };

  const handleChangePage = (_, newPage) => {
    setPageNo(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(+event.target.value);
    setPageNo(0);
  };

  const leadType = {
    lead: 'Leads',
    onhold: 'On Hold',
    cancelled: 'Cancelled',
    newproject: 'New Project',
    ongoing: 'On Going',
    completed: 'Completed',
  };

  const projectTypeDisplayName = leadType[type];

  return (
    <Fragment>
      <NavigationHeader
        project='Projects'
        lead={projectTypeDisplayName}
        showNewLeadButton={true}
        showLead={true}
      />
      {/* <LeadsFiltericon /> */}
      {renderProjects()}
      {projects && projects.totalNoOfRecords && projects.totalNoOfRecords > 5 ? (
        <TablePagination
          sx={{ px: 2 }}
          page={pageNo}
          component='div'
          rowsPerPage={pageSize}
          count={projects.totalNoOfRecords || 0}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        />
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default Projects;
