import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, TextField } from '@mui/material';
import notify from 'app/utils/notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // padding: '5%',
  paddingLeft: '5%',
  paddingRight: '5%',
  marginBottom: '4%',
  borderRadius: '4px',
  // border:'1px solid orange',
  marginTop: '0px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Contact Person is required'),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email domain')
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumber: Yup.string()
  .required('Phone Number is required')
  .matches(/^[0-9]+$/, 'Phone Number must only contain digits')
  .length(10, 'Phone Number must be exactly 10 digits long'),
});
const PMContactPersonModal = ({
  contactPersonDetails,
  handleCloseModal,
  pmContactPerson,
  pmContactPersonIndex,
}) => {
  const { addPMcontactPersons, updatePMcontactPersons } = useProject();

  const initialValues = {
    name: contactPersonDetails?.name || '',
    email: contactPersonDetails?.email || '',
    phoneNumber: contactPersonDetails?.phoneNumber || '',
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const pmData = {
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
      };

      console.log(pmData, 'pmData');
      console.log(pmContactPerson[pmContactPersonIndex], 'pmContactPerson .....');

      if (contactPersonDetails) {
        console.log(contactPersonDetails, 'contactPersonDetails');
        const updatedData = {
          contactId: contactPersonDetails?.contactId,
          ...pmData,
        };
        console.log(contactPersonDetails, 'contactPersonDetails');
        await updatePMcontactPersons(
          pmContactPerson[pmContactPersonIndex]?.projectId,
          pmContactPerson[pmContactPersonIndex]?.id,
          updatedData,
        );
      } else {
        console.log(contactPersonDetails, 'contactPersonDetails');
        await addPMcontactPersons(
          pmContactPerson[pmContactPersonIndex]?.projectId,
          pmContactPerson[pmContactPersonIndex]?.id,
          pmData,
        );
      }
      handleCloseModal();
    } catch (error) {
      notify.error(`Error in Manager Details: ${error.message}`);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        width: '35%',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 0,
          justifyContent: 'space-between',
          display: 'flex',
        }}
      >
        <Title>{contactPersonDetails ? 'Edit' : 'Add'} Contact Person Details</Title>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon
            style={{
              fontSize: 20,
              color: '#272937',
            }}
          />
        </IconButton>
      </Box>
      <ModalDivider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <Grid style={{ marginTop: '1px' }} container spacing={2}>
                <Grid item xs={6}>
                  <Inputlabel>
                    <InputLabel>Contact Person</InputLabel>
                  </Inputlabel>
                  <TextField
                    name='name'
                    size='small'
                    fullWidth
                    variant='outlined'
                    placeholder='Contact Person'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Inputlabel>
                    <InputLabel>Email Address</InputLabel>
                  </Inputlabel>
                  <TextField
                    name='email'
                    size='small'
                    fullWidth
                    variant='outlined'
                    placeholder='Email Address'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
              </Grid>
              <Grid style={{ marginTop: '1px' }} container spacing={2}>
                <Grid item xs={6}>
                  <Inputlabel>
                    <InputLabel>Phone Number</InputLabel>
                  </Inputlabel>
                  <TextField
                    name='phoneNumber'
                    size='small'
                    fullWidth
                    variant='outlined'
                    placeholder='Phone Number'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneNumber}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Grid>
              </Grid>
            </Container>
            <Box
              marginBottom={2.5}
              marginLeft={3.5}
              marginRight={2.5}
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <LoadingButton
                className='cancelButton'
                color='error'
                variant='outlined'
                onClick={handleCloseModal}
                style={{ boxShadow: 'none' }}
                disabled={isSubmitting}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                style={{ boxShadow: 'none', marginRight: '10px' }}
                type='submit'
                loading={isSubmitting}
              >
                {contactPersonDetails ? 'Update' : 'Save'}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default PMContactPersonModal;
