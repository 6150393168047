import { NoRequestsIcon } from 'app/common/icons';
import { Typography } from '@mui/material';
import styles from './modules/NoRequests.module.css';
import Box from '@mui/material/Box';

function NoRequests({ type, message }) {
  return (
    <Box className={styles.container}>
      <NoRequestsIcon />
      <Box className={styles.textContainer}>
        <Typography variant='h6'>
          No {type.charAt(0) + type.slice(1).toLowerCase()} Requests
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

export default NoRequests;
