// import React, { useState } from "react";
// import { Box, Typography, TextField, IconButton, Avatar, List, ListItem, ListItemAvatar, ListItemText, Paper, InputAdornment, useMediaQuery, Drawer, AppBar, Toolbar } from "@mui/material";
// import { styled } from "@mui/system";
// import { FiSend, FiSearch, FiMenu } from "react-icons/fi";

// const StyledContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   height: "100vh",
//   background: "#ffffff", // Changed background to white
//   color: "#222A45" // Changed text color to #222A45
// }));

// const StyledSidebar = styled(Box)(({ theme }) => ({
//   width: "300px",
//   borderRight: "1px solid #333333",
//   overflowY: "auto",
//   background: "#ffffff" // Changed background to white
// }));

// const StyledChatArea = styled(Box)(({ theme }) => ({
//   flex: 1,
//   display: "flex",
//   flexDirection: "column",
//   background: "#ffffff" // Changed background to white
// }));

// const StyledMessageContainer = styled(Box)({
//   overflowY: "auto",
//   padding: "20px",
//   background: "#ffffff" // Changed background to white
// });

// const StyledMessage = styled(Paper)(({ isOwn }) => ({
//   padding: "10px 15px",
//   margin: "8px 0",
//   maxWidth: "70%",
//   width: "fit-content",
//   background: "#f2f2f2", // Changed background color of message
//   color: "#222A45", // Changed text color to #222A45
//   borderRadius: "12px",
//   alignSelf: isOwn ? "flex-end" : "flex-start"
// }));

// const StyledInputArea = styled(Box)({
//   padding: "20px",
//   background: "#ffffff", // Changed background to white
//   marginTop: "-20px", 
// });

// const StyledSearchBox = styled(TextField)({
//   "& .MuiOutlinedInput-root": {
//     color: "#222A45", // Changed text color to #222A45
//     "& fieldset": {
//       borderColor: "#424242"
//     },
//     "&:hover fieldset": {
//       borderColor: "#666666"
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#0d47a1"
//     }
//   }
// });

// const ChatHistory = () => {
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [message, setMessage] = useState("");
//   const isMobile = useMediaQuery("(max-width:600px)");

//   const contacts = [
//     { id: 1, name: "John Doe", avatar: "images.unsplash.com/photo-1599566150163-29194dcaad36", lastMessage: "Hey, how are you?" },
//     { id: 2, name: "Jane Smith", avatar: "images.unsplash.com/photo-1494790108377-be9c29b29330", lastMessage: "Let's meet tomorrow" },
//     { id: 3, name: "Mike Johnson", avatar: "images.unsplash.com/photo-1570295999919-56ceb5ecca61", lastMessage: "Great work!" }
//   ];

//   const messages = [
//     { id: 1, text: "Hey there!", isOwn: false, timestamp: "10:00 AM" },
//     { id: 2, text: "Hi! How are you?", isOwn: true, timestamp: "10:02 AM" },
//     { id: 3, text: "I'm doing great, thanks for asking!", isOwn: false, timestamp: "10:03 AM" },
//     { id: 4, text: "That's wonderful to hear!", isOwn: true, timestamp: "10:05 AM" }
//   ];

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   const handleMessageSend = () => {
//     if (message.trim()) {
//       // Handle message sending logic here
//       setMessage("");
//     }
//   };

//   const sidebar = (
//     <>
//       <Box p={2}>
//         <StyledSearchBox
//           fullWidth
//           variant="outlined"
//           placeholder="Search contacts"
//           size="small"
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <FiSearch color="#222A45" /> {/* Changed icon color */}
//               </InputAdornment>
//             )
//           }}
//         />
//       </Box>
//       <List>
//         {contacts.map((contact) => (
//           <ListItem button key={contact.id} sx={{ "&:hover": { background: "#e0e0e0" } }}>
//             <ListItemAvatar>
//               <Avatar src={`https://${contact.avatar}`} alt={contact.name} />
//             </ListItemAvatar>
//             <ListItemText
//               primary={<Typography variant="subtitle1" sx={{ color: "#222A45" }}>{contact.name}</Typography>}
//               secondary={
//                 <Typography variant="body2" sx={{ color: "#666666" }}>
//                   {contact.lastMessage}
//                 </Typography>
//               }
//             />
//           </ListItem>
//         ))}
//       </List>
//     </>  
//   );

//   return (
//     <StyledContainer>
//       {isMobile && (
//         <AppBar position="static" sx={{ background: "#ffffff", color: "#222A45" }}>
//           <Toolbar>
//             <IconButton
//               color="inherit"
//               edge="start"
//               onClick={handleDrawerToggle}
//               sx={{ mr: 2 }}
//               aria-label="open drawer"
//             >
//               <FiMenu />
//             </IconButton>
//             <Typography variant="h6">Chat App</Typography>
//           </Toolbar>
//         </AppBar>
//       )}
      
//       {isMobile ? (
//         <Drawer
//           variant="temporary"
//           anchor="left"
//           open={mobileOpen}
//           onClose={handleDrawerToggle}
//           ModalProps={{ keepMounted: true }}
//           sx={{ "& .MuiDrawer-paper": { width: 300, background: "#ffffff" } }} // Changed background to white
//         >
//           {sidebar}
//         </Drawer>
//       ) : (
//         <StyledSidebar component="nav">{sidebar}</StyledSidebar>
//       )}

//       <StyledChatArea>
//         <StyledMessageContainer>
//           <Box display="flex" flexDirection="column" gap={1}>
//             {messages.map((msg) => (
//               <Box
//                 key={msg.id}
//                 display="flex"
//                 flexDirection="column"
//                 alignItems={msg.isOwn ? "flex-end" : "flex-start"}
//               >
//                 <StyledMessage isOwn={msg.isOwn}>
//                   <Typography sx={{ color: "#222A45" }}>{msg.text}</Typography> {/* Changed text color */}
//                   <Typography variant="caption" sx={{ color: "#666666" }}>
//                     {msg.timestamp}
//                   </Typography>
//                 </StyledMessage>
//               </Box>
//             ))}
//           </Box>
//         </StyledMessageContainer>

//         <StyledInputArea>
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Type a message"
//             value={message}
//             onChange={(e) => setMessage(e.target.value)}
//             onKeyPress={(e) => e.key === "Enter" && handleMessageSend()}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <IconButton onClick={handleMessageSend} sx={{ color: "#222A45" }}>
//                     <FiSend />
//                   </IconButton>
//                 </InputAdornment>
//               )
//             }}
//             sx={{
//               "& .MuiOutlinedInput-root": {
//                 color: "#222A45", // Changed text color to #222A45
//                 "& fieldset": { borderColor: "#424242" },
//                 "&:hover fieldset": { borderColor: "#666666" },
//                 "&.Mui-focused fieldset": { borderColor: "#0d47a1" }
//               }
//             }}
//           />
//         </StyledInputArea>
//       </StyledChatArea>
//     </StyledContainer>
//   );
// };

// export default ChatHistory;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Badge,
  Drawer,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { styled } from "@mui/system";
import { IoSend, IoMenu } from "react-icons/io5";
import { BsCheck2All } from "react-icons/bs";
import { RiRadioButtonLine } from "react-icons/ri";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  backgroundColor: "#f5f5f5"
}));

const Sidebar = styled(Box)(({ theme }) => ({
  width: 320,
  backgroundColor: "#fff",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  [theme.breakpoints.down("md")]: {
    display: "none"
  }
}));

const ChatArea = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column"
});

const MessageContainer = styled(Box)({
//   flex: 1,
  padding: "20px",
  overflowY: "auto"
});

const Message = styled(Box)(({ sent }) => ({
  display: "flex",
  justifyContent: sent ? "flex-end" : "flex-start",
  marginBottom: "10px"
}));

const MessageBubble = styled(Paper)(({ sent }) => ({
  padding: "10px 15px",
  maxWidth: "70%",
  backgroundColor: sent ? "#1976d2" : "#fff",
  color: sent ? "#fff" : "inherit",
  borderRadius: "15px",
  position: "relative"
}));

const ChatHeader = styled(Box)({
  padding: "15px",
  backgroundColor: "#fff",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  display: "flex",
  alignItems: "center",
  gap: "15px"
});

const InputArea = styled(Box)({
  padding: "20px",
  backgroundColor: "#fff",
  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  display: "flex",
  gap: "10px"
});

const ChatHistory = () => {
  const [message, setMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const contacts = [
    {
      id: 1,
      name: "John Doe",
      avatar: "images.unsplash.com/photo-1599566150163-29194dcaad36",
      lastMessage: "Hey, how are you?",
      online: true
    },
    {
      id: 2,
      name: "Jane Smith",
      avatar: "images.unsplash.com/photo-1494790108377-be9c29b29330",
      lastMessage: "See you tomorrow!",
      online: false
    }
  ];

  const messages = [
    {
      id: 1,
      text: "Hi there!",
      sent: false,
      time: "10:00 AM"
    },
    {
      id: 2,
      text: "Hello! How are you?",
      sent: true,
      time: "10:02 AM"
    }
  ];

  const handleSend = () => {
    if (message.trim()) {
      // Handle sending message
      setMessage("");
    }
  };

  const ContactList = () => (
    <List>
      {contacts.map((contact) => (
        <ListItem button key={contact.id}>
          <ListItemAvatar>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              color={contact.online ? "success" : "error"}
            >
              <Avatar src={`https://${contact.avatar}`} alt={contact.name} />
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primary={contact.name}
            secondary={contact.lastMessage}
            primaryTypographyProps={{
              style: { fontWeight: 500 }
            }}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Container>
      {isMobile && (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box width={320}>
            <ContactList />
          </Box>
        </Drawer>
      )}
      <Sidebar component="aside">
        <ContactList />
      </Sidebar>
      <ChatArea>
        <ChatHeader>
          {isMobile && (
            <IconButton
              onClick={() => setDrawerOpen(true)}
              aria-label="open contacts"
            >
              <IoMenu />
            </IconButton>
          )}
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            color="success"
          >
            <Avatar
              src={`https://${contacts[0].avatar}`}
              alt={contacts[0].name}
            />
          </Badge>
          <Box>
            <Typography variant="h6">{contacts[0].name}</Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ display: "flex", alignItems: "center", gap: 4 }}
            >
              <RiRadioButtonLine
                color={contacts[0].online ? "#4caf50" : "#f44336"}
              />
              {contacts[0].online ? "Online" : "Offline"}
            </Typography>
          </Box>
        </ChatHeader>

        <MessageContainer>
          {messages.map((msg) => (
            <Message key={msg.id} sent={msg.sent}>
              <MessageBubble sent={msg.sent} elevation={1}>
                <Typography>{msg.text}</Typography>
                <Typography
                  variant="caption"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    opacity: 0.7,
                    marginTop: 4
                  }}
                >
                  {msg.time}
                  {msg.sent && <BsCheck2All />}
                </Typography>
              </MessageBubble>
            </Message>
          ))}
        </MessageContainer>

        <InputArea component="form" onSubmit={(e) => e.preventDefault()}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            size="small"
            aria-label="message input"
          />
          <IconButton
            color="primary"
            onClick={handleSend}
            aria-label="send message"
          >
            <IoSend />
          </IconButton>
        </InputArea>
      </ChatArea>
    </Container>
  );
};

export default ChatHistory;