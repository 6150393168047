import React, { useEffect, useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, styled, Card
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useProject } from 'app/contexts/ProjectContext'; // API functions from context
import { LoadingButton } from '@mui/lab'; // Loading button for save
import { formatCurrency, handleAxiosError } from 'app/utils/helpers'; // Utils
import LeadCompanyDetails from './LeadCompanyDetails';
import LeadsLenderDetails from './LeadsLenderDetails';

// Styled Components
const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '130px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'grey',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: '#555',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: '#4F90F0',
});

const LeadsClosingDetails = () => {
  const {
    isOnHoldOrCancelled,
    closing,
    updateClosing,
  } = useProject(); // Use API functions from context

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state for save operation
  const [successOpen, setSuccessOpen] = useState(false);
  const [formData, setFormData] = useState({
    purchasePrice: '',
    closingCost: '',
    realtorRebate: '',
    closingDate: '',
  });

  // Populate form data when 'closing' data is available
  useEffect(() => {
    if (closing) {
      setFormData({
        purchasePrice: closing.purchasePrice || '',
        closingCost: closing.closingCost || '',
        realtorRebate: closing.realtorRebate || '',
        closingDate: closing.closingDate ? closing.closingDate.slice(0, 10) : '',
      });
    }
  }, [closing]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      // Add time to closingDate when sending
      const updatedFormData = {
        ...formData,
        purchasePrice: parseInt(formData.purchasePrice, 10),
        closingCost: parseInt(formData.closingCost, 10),
        closingDate: formData.closingDate + "T00:00:00", // Add time for payload
        id: closing.id,
        companyDetails: closing.companyDetails,
        lenderDetails: closing.lenderDetails,
      };

      await updateClosing(updatedFormData);
      setSuccessOpen(true);
      setOpen(false);
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  const infoPairs = [
    { key: 'PURCHASE PRICE', value: formatCurrency(closing?.purchasePrice) },
    { key: 'CLOSING COST', value: formatCurrency(closing?.closingCost) },
    { key: 'REALTOR REBATE', value: closing?.realtorRebate || '-' },
    {
      key: 'CLOSING DATE',
      value: closing?.closingDate
        ? new Date(closing?.closingDate).toLocaleDateString() // Display full date
        : '-',
    },
  ];

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <Box marginRight={2} padding={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title>Closing Details :</Title>
            <Button
              onClick={handleOpen}
              style={{ color: '#4F90F0', fontSize: '14px' }}
              disabled={isOnHoldOrCancelled}
            >
              <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
            </Button>
          </Box>
          <InfoContainer>
            {infoPairs.map(({ key, value }, index) => (
              <Box key={index}>
                <Key>{key}</Key>
                <ValueContainer>
                  <Value className='basicvalues'>{value}</Value>
                </ValueContainer>
              </Box>
            ))}
          </InfoContainer>
        </Box>

        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <LeadCompanyDetails />
        </Card>
        <Card elevation={3} sx={{ mt: '2vh', backgroundColor: 'white' }}>
          <LeadsLenderDetails />
        </Card>
      </div>

      {/* Edit Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle>Edit Closing Details</DialogTitle>
        <DialogContent>
          <TextField
            margin='dense'
            name='purchasePrice'
            label='Purchase Price'
            fullWidth
            type='number'
            value={formData.purchasePrice}
            onChange={handleChange}
            variant='outlined'
          />
          <TextField
            margin='dense'
            name='closingCost'
            label='Closing Cost'
            fullWidth
            type='number'
            value={formData.closingCost}
            onChange={handleChange}
            variant='outlined'
          />
          <TextField
            margin='dense'
            type='number'
            name='realtorRebate'
            label='Realtor Rebate'
            fullWidth
            value={formData.realtorRebate}
            onChange={handleChange}
            variant='outlined'
          />
          <TextField
            margin='dense'
            name='closingDate'
            label='Closing Date'
            type='date'
            fullWidth
            value={formData.closingDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            variant='outlined'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='error' disabled={loading}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSave}
            color='primary'
            loading={loading}
            variant='outlined'
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeadsClosingDetails;
