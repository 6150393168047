import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography, Button, Modal, MobileStepper } from '@mui/material';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import useImagesContext from 'app/hooks/useImagesContext';
import styles from './StepperImages.module.css';

const StepperImages = ({ children }) => {
  const {
    currentImage,
    totalImagesCount,
    isOpenStepper,
    activeStep,
    handleNext,
    handleBack,
    handleCloseStepper,
  } = useImagesContext();

  return (
    <>
      {children}
      <Modal open={isOpenStepper} onClose={handleCloseStepper}>
        <Box
          className={styles.modal}
          sx={{
            bgcolor: 'background.paper',
          }}
        >
          <Box
            sx={{
              p: 2,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Typography variant='h6'>Images</Typography>
            <IconButton onClick={handleCloseStepper}>
              <CloseIcon
                style={{
                  fontSize: 18,
                  color: '#272937',
                }}
              />
            </IconButton>
          </Box>

          <Box className={styles.stepperImage}>{currentImage}</Box>
          <MobileStepper
            variant='dots'
            steps={totalImagesCount}
            position='static'
            activeStep={activeStep}
            nextButton={
              <Button
                size='small'
                onClick={handleNext}
                disabled={activeStep === totalImagesCount - 1}
              >
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
              </Button>
            }
          />
        </Box>
      </Modal>
    </>
  );
};

export default StepperImages;
