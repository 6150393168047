import React, { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useProject } from 'app/contexts/ProjectContext';
import { LoadingButton } from '@mui/lab';
import { useProjectClosingCreateUpdate } from './hooks/useProjectClosingCreateUpdate';
import { Form, Formik } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';

const Title = styled('span')(() => ({
  fontSize: '18px',
  fontWeight: '600',
  textTransform: 'capitalize',
}));

const InfoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '130px',
  rowGap: '20px',
  marginTop: '5px',
  borderRadius: '5px',
  marginBottom: '24px',
});

const Key = styled('span')({
  fontSize: '0.8rem',
  fontWeight: '500',
  marginRight: '8px',
  color: 'var(--key-text)',
  display: 'block',
});

const Value = styled('span')({
  fontSize: '0.9rem',
  color: 'var(--value-text)',
  fontWeight: 'bold',
});

const ValueContainer = styled('span')({
  display: 'block',
  whiteSpace: 'pre-line',
});

const SubtitleDivider = styled('div')({
  height: '4px',
  backgroundColor: 'var(--subtitleDivider1)',
});

const CompanySection = ({ closing }) => {
  const { projectId, isOnHoldOrCancelled } = useProject();
  const { mutate: updateCompany } = useProjectClosingCreateUpdate(projectId);

  const companyDetails = closing?.companyDetails;

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const initialValues = {
    ...closing?.companyDetails,
  };

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const handleSubmit = (values, { setSubmitting }) => {
    const payloadData = {
      ...closing,
      companyDetails: values,
    };

    updateCompany(payloadData, {
      onSuccess: toggleDialog,
      onSettled: () => setSubmitting(false),
    });
  };

  const infoPairs = [
    { key: 'TITLE COMPANY NAME', value: companyDetails.companyName },
    { key: 'CONTACT PERSON', value: companyDetails.contactPerson },
    { key: 'CONTACT NUMBER', value: companyDetails.contactPersonNumber },
    { key: 'EMAIL ADDRESS', value: companyDetails.contactPersonEmail },
  ];

  return (
    <>
      <div className='viewlead'>
        <SubtitleDivider />
        <Box item lg={6} md={6} sm={12} xs={12} marginRight={2} padding={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Title>Title Company Details:</Title>
            <Button
              onClick={toggleDialog}
              style={{ color: 'var(--isOnHoldOrCancelled-button)', fontSize: '14px' }}
              disabled={isOnHoldOrCancelled}
            >
              <BorderColorIcon sx={{ fontSize: '13.5px' }} /> Edit
            </Button>
          </Box>
          <InfoContainer item lg={6} md={6} sm={12} xs={12}>
            {infoPairs.map(({ key, value }, index) => (
              <Box key={index}>
                <Key>{key}</Key>
                <ValueContainer>
                  <Value className='basicvalues'>{value}</Value>
                </ValueContainer>
              </Box>
            ))}
          </InfoContainer>
        </Box>
      </div>

      <Dialog open={isOpenDialog} onClose={toggleDialog} fullWidth maxWidth='sm'>
        <DialogTitle>Edit Company Details</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas.company()}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleBlur }) => (
            <Form>
              <DialogContent>
                {[
                  { label: 'Title Company Name', name: 'companyName' },
                  { label: 'Contact Person', name: 'contactPerson' },
                  { label: 'Contact Number', name: 'contactPersonNumber' },
                  { label: 'Email Address', name: 'contactPersonEmail' },
                ].map((field) => (
                  <TextField
                    key={field.name}
                    fullWidth
                    margin='dense'
                    label={field.label}
                    name={field.name}
                    value={values[field.name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched[field.name] && errors[field.name])}
                    helperText={touched[field.name] && errors[field.name]}
                    disabled={isSubmitting}
                  />
                ))}
              </DialogContent>
              <DialogActions>
                <LoadingButton onClick={toggleDialog} color='error' sx={{ textTransform: 'none' }} disabled={isSubmitting}>
                  Cancel
                </LoadingButton>
                <LoadingButton
                  variant='outlined'
                  color='primary'
                  type='submit'
                  loading={isSubmitting}
                  sx={{ textTransform: 'none' }}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CompanySection;
