import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  IconButton,
  styled,
  Modal,
  Typography,
  Button,
  Input,
  TextField,
  Grid,
} from '@mui/material';
import dayjs from 'dayjs';
import { CustomInputText, customNumberInput, Title } from 'app/common/Typography';
import { DocIcon, DownloadDragIcon, PdfIcon } from 'app/common/icons';
import LeadsSuccess from './LeadsSuccess';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { delay, formatDecimal, handleAxiosError } from 'app/utils/helpers';
import { useProject } from 'app/contexts/ProjectContext';
import notify from 'app/utils/notify';
import { contractDocuments } from 'app/utils/constant';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '4px',
  marginTop: '10px',
  border: '2px dashed lightgray',
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 0,
    backgroundColor: 'transparent',
  },
}));

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  padding: '8px',
  border: '1px solid lightgray',
  backgroundColor: '#F8F8F8',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '40vh',
  overflowY: 'auto',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
  flexGrow: 1,
});

const LeadsDetailStatus = ({ projectId, handleChangeStatus, openModal }) => {
  const { projectMoveToNew } = useProject();
  const [newDocument, setNewDocuments] = useState({
    documents: [],
  });

  const [effectiveDate, setEffectiveDate] = useState(null);
  const [estimatedClosingDate, setEstimatedClosingDate] = useState(null);
  const [feasibilityEndDate, setFeasibilityEndDate] = useState(null);
  const [earnestMoney, setEarnestMoney] = useState('');
  const [optionsMoney, setOptionsMoney] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalInvestment, setTotalInvestment] = useState('');
  const [landCost, setLandCost] = useState('');
  const [softCost, setSoftCost] = useState('');
  const [totalShare, setTotalShare] = useState('');
  const [eachSharePrice, setEachSharePrice] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.map((file) => ({ file, docname: '', doctype: '' }));
    setNewDocuments((prevState) => ({
      documents: [...prevState.documents, ...newFiles],
    }));
  };

  const handleDocumentNameChange = (event, index) => {
    const { value } = event.target;
    const updatedDocuments = [...newDocument.documents];
    updatedDocuments[index].docname = value;
    setNewDocuments({
      ...newDocument,
      documents: updatedDocuments,
    });
    if (errors[`docname_${index}`]) {
      const updatedErrors = { ...errors };
      delete updatedErrors[`docname_${index}`];
      setErrors(updatedErrors);
    }
  };

  const handleDeleteFile = (index) => {
    const updatedDocuments = [...newDocument.documents];
    updatedDocuments.splice(index, 1);
    setNewDocuments({
      ...newDocument,
      documents: updatedDocuments,
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const newFiles = files.map((file) => ({ file, docname: '' }));
    setNewDocuments((prevState) => ({
      documents: [...prevState.documents, ...newFiles],
    }));
  };

  const handleLandCostChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatDecimal(value, 3);
    setLandCost(formattedValue);
    if (errors.landCost) {
      setErrors((prevErrors) => ({ ...prevErrors, landCost: null }));
    }
    updateTotalInvestment(value, softCost);
  };

  const handleSoftCostChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatDecimal(value, 3);
    setSoftCost(formattedValue);
    if (errors.softCost) {
      setErrors((prevErrors) => ({ ...prevErrors, softCost: null }));
    }
    updateTotalInvestment(landCost, value);
  };

  const updateTotalInvestment = (landCost, softCost) => {
    const total = parseFloat(landCost || 0) + parseFloat(softCost || 0);
    const formattedTotal = formatDecimal(total, 3);
    setTotalInvestment(formattedTotal);
    if (errors.totalInvestment) {
      setErrors((prevErrors) => ({ ...prevErrors, totalInvestment: null }));
    }
  };

  const validateFields = () => {
    let valid = true;
    let errors = {};

    if (!effectiveDate) {
      valid = false;
      errors.effectiveDate = 'Effective date is required';
    }

    if (!estimatedClosingDate) {
      valid = false;
      errors.estimatedClosingDate = 'Estimated closing date is required';
    }

    if (!feasibilityEndDate) {
      valid = false;
      errors.feasibilityEndDate = 'Feasibility end date is required';
    }

    if (!earnestMoney) {
      valid = false;
      errors.earnestMoney = 'Earnest money is required';
    }

    if (!optionsMoney) {
      valid = false;
      errors.optionsMoney = 'Options money is required';
    }

    if (!totalInvestment) {
      valid = false;
      errors.totalInvestment = 'Total investment is required';
    }

    if (!landCost) {
      valid = false;
      errors.landCost = 'Land cost is required';
    }

    if (!softCost) {
      valid = false;
      errors.softCost = 'Soft cost is required';
    }

    if (!totalShare) {
      valid = false;
      errors.totalShare = 'Total share is required';
    }

    if (!eachSharePrice || parseFloat(eachSharePrice) <= 0) {
      valid = false;
      errors.eachSharePrice = 'Each share price is required and must be greater than 0';
    }

    if (parseFloat(eachSharePrice) > parseFloat(totalInvestment)) {
      valid = false;
      errors.eachSharePrice = 'Each share price must be less than total investment';
    }

    const calculatedShares = totalInvestment / parseFloat(eachSharePrice);
    if (!Number.isInteger(calculatedShares)) {
      valid = false;
      errors.eachSharePrice = 'Share price must result in a whole number of shares';
    }

    newDocument.documents.forEach((doc, index) => {
      if (!doc.docname) {
        valid = false;
        errors[`docname_${index}`] = 'Document name is required';
      }
    });

    setErrors(errors);
    if (!valid) {
      setLoading(false);
    }
    return valid;
  };

  const handleEffectiveDateChange = (newValue) => {
    setEffectiveDate(newValue);
    if (errors.effectiveDate) {
      setErrors((prevErrors) => ({ ...prevErrors, effectiveDate: null }));
    }
    if (feasibilityEndDate && dayjs(newValue).isAfter(dayjs(feasibilityEndDate))) {
      setFeasibilityEndDate(dayjs(newValue).add(1, 'day'));
    }
    if (estimatedClosingDate && dayjs(newValue).isAfter(dayjs(estimatedClosingDate))) {
      setEstimatedClosingDate(dayjs(newValue).add(2, 'day'));
    }
  };

  const handleFeasibilityEndDateChange = (newValue) => {
    setFeasibilityEndDate(newValue);
    if (errors.feasibilityEndDate) {
      setErrors((prevErrors) => ({ ...prevErrors, feasibilityEndDate: null }));
    }
    if (estimatedClosingDate && !dayjs(newValue).isBefore(dayjs(estimatedClosingDate))) {
      setEstimatedClosingDate(dayjs(newValue).add(1, 'day'));
    }
  };

  const handleSubmit = async () => {
    const availableShares = totalShare;

    if (newDocument.documents.length === 0) {
      notify.error('Please add at least one document.');
      return;
    }
    if (isSubmitting) return;
    if (!validateFields()) return;

    const formatDateToLocalISOString = (date) => {
      return date
        ? dayjs(date).startOf('day').add(dayjs(date).utcOffset(), 'minute').toISOString()
        : null;
    };

    const contractDetails = {
      effectiveDate: formatDateToLocalISOString(effectiveDate),
      estimatedClosingDate: formatDateToLocalISOString(estimatedClosingDate),
      feasibilityEndDate: formatDateToLocalISOString(feasibilityEndDate),
      earnestMoney,
      optionsMoney,
      extended: false,
    };
    const investments = {
      totalInvestment,
      landCost,
      softCost,
      totalShare,
      eachSharePrice,
      availableShares,
    };

    const documentsData = new FormData();
    let docIndex = 0;

    newDocument.documents.forEach((doc, index) => {
      documentsData.append(`docReq[${docIndex}].file`, doc.file);
      documentsData.append(`docReq[${docIndex}].docName`, doc.docname);
      documentsData.append(`docReq[${docIndex}].type`, contractDocuments);
      docIndex++;
    });
    try {
      setLoading(true);
      setIsSubmitting(true);
      await projectMoveToNew({ documents: documentsData }, contractDetails, investments);
      notify.success(
        <Typography style={{ fontSize: '15px', fontWeight: '500' }}>
          Project successfully moved to
          <Grid>{`the New Project`}</Grid>
        </Typography>,
      );
      handleChangeStatus();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  const handleEachSharePriceChange = (e) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);

    setEachSharePrice(value);

    if (numericValue <= 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        eachSharePrice: 'Share price must be greater than zero',
      }));
      setTotalShare('');
      return;
    }

    if (numericValue > parseFloat(totalInvestment)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        eachSharePrice: 'Each share price should not be greater than total investment',
      }));
      setTotalShare('');
      return;
    }

    // Calculate shares and show error if not whole number
    const calculatedShares = totalInvestment / numericValue;
    setTotalShare(calculatedShares.toString());

    if (!Number.isInteger(calculatedShares)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        eachSharePrice:
          'You entered a non-divisible shares. Please adjust costs or share price to match whole total shares.',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        eachSharePrice: null,
      }));
    }
  };

  return (
    <>
      <Modal open={openModal} onClose={handleChangeStatus}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '40%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              p: 1,
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <Title>Details</Title>
            <IconButton onClick={handleChangeStatus}>
              <CloseIcon
                style={{
                  fontSize: 18,
                  color: '#272937',
                }}
              />
            </IconButton>
          </Box>
          <ModalDivider />
          <Box sx={{ p: 2, overflowY: 'auto', maxHeight: 'calc(100% - 48px)' }}>
            <Grid>
              <Typography style={{ fontSize: '18px', fontWeight: '500' }}>
                Investment Details :
              </Typography>
            </Grid>

            <Grid style={{ marginTop: '1px' }} container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  type='number'
                  label='Land cost'
                  placeholder='e.g. $2000.00'
                  value={landCost}
                  onChange={handleLandCostChange}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.landCost}
                  helperText={errors.landCost}
                  sx={customNumberInput}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label='Soft cost'
                  placeholder='e.g. $2000.00'
                  value={softCost}
                  onChange={handleSoftCostChange}
                  fullWidth
                  type='number'
                  variant='outlined'
                  size='small'
                  error={!!errors.softCost}
                  helperText={errors.softCost}
                  sx={customNumberInput}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  placeholder='e.g. $2000.00'
                  label='Total Investment'
                  type='number'
                  value={totalInvestment}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.totalInvestment}
                  helperText={errors.totalInvestment}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={customNumberInput}
                />
              </Grid>
            </Grid>
            <Grid style={{ marginTop: '1px' }} container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  type='number'
                  label='Each Share Price'
                  placeholder='e.g. $2000.00'
                  value={eachSharePrice}
                  onChange={handleEachSharePriceChange}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.eachSharePrice}
                  helperText={errors.eachSharePrice}
                  sx={customNumberInput}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Total Share'
                  type='number'
                  placeholder='e.g. 100'
                  value={totalShare}
                  fullWidth
                  variant='outlined'
                  size='small'
                  error={!!errors.totalShare}
                  helperText={errors.totalShare}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={customNumberInput}
                />
              </Grid>
            </Grid>
            <Typography style={{ fontSize: '15px', fontWeight: '450', marginTop: '10px' }}>
              Contract Details
            </Typography>
            <Grid container spacing={2} style={{ marginTop: '0.3px' }}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <DatePicker
                      name='effectiveDate'
                      label='Effective Date'
                      value={effectiveDate}
                      onChange={handleEffectiveDateChange}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          error: !!errors.effectiveDate,
                          helperText: errors.effectiveDate,
                        },
                      }}
                      sx={{ width: '100%' }}
                      minDate={dayjs()}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <DatePicker
                      name='feasibilityEndDate'
                      label='Feasibility End Date'
                      value={feasibilityEndDate}
                      onChange={handleFeasibilityEndDateChange}
                      minDate={effectiveDate ? dayjs(effectiveDate).add(1, 'day') : null}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          error: !!errors.feasibilityEndDate,
                          helperText: errors.feasibilityEndDate,
                        },
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '1px' }}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <DatePicker
                      name='estimatedClosingDate'
                      label='Estimated Closing date'
                      value={estimatedClosingDate}
                      onChange={(newValue) => {
                        setEstimatedClosingDate(newValue);
                        if (errors.estimatedClosingDate) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            estimatedClosingDate: null,
                          }));
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          error: !!errors.estimatedClosingDate,
                          helperText: errors.estimatedClosingDate,
                        },
                      }}
                      minDate={feasibilityEndDate ? dayjs(feasibilityEndDate).add(1, 'day') : null}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  onChange={(e) => {
                    setEarnestMoney(e.target.value);
                    if (errors.earnestMoney) {
                      setErrors((prevErrors) => ({ ...prevErrors, earnestMoney: null }));
                    }
                  }}
                  label='Earnest money'
                  fullWidth
                  type='number'
                  placeholder='e.g. $30000.00'
                  variant='outlined'
                  size='small'
                  error={!!errors.earnestMoney}
                  helperText={errors.earnestMoney}
                  sx={customNumberInput}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '1px' }}>
              <Grid item xs={6}>
                <TextField
                  label='Options Money'
                  onChange={(e) => {
                    setOptionsMoney(e.target.value);
                    if (errors.optionsMoney) {
                      setErrors((prevErrors) => ({ ...prevErrors, optionsMoney: null }));
                    }
                  }}
                  fullWidth
                  type='number'
                  placeholder='e.g. $30000.00'
                  variant='outlined'
                  size='small'
                  error={!!errors.optionsMoney}
                  helperText={errors.optionsMoney}
                  sx={customNumberInput}
                />
              </Grid>
            </Grid>

            <Typography style={{ fontSize: '15px', fontWeight: '450', marginTop: '8px' }}>
              Contract Document
            </Typography>
            <Container onDragOver={handleDragOver} onDrop={handleDrop}>
              <Typography variant='body1' color={'#64748B'} fontSize={'16px'}>
                <DownloadDragIcon />
                {` Drag and Drop file here or `}
                <label htmlFor='outlined-button-file'>
                  <StyledButton variant='outlined' component='span'>
                    Browse files
                  </StyledButton>
                </label>
              </Typography>
              <Input
                id='outlined-button-file'
                sx={{
                  display: 'none',
                }}
                type='file'
                size='small'
                name='file'
                onChange={handleFileChange}
                inputProps={{ accept: '.pdf, .doc, .docx' }}
              />
            </Container>
            <Typography variant='body1' color={'#64748B'} fontSize={'12px'} pt={2}>
              {`File format only word or Pdf, Size should not exceed 10MB`}
            </Typography>
            <FileContainerDetails>
              {newDocument &&
                newDocument.documents &&
                newDocument.documents.map((fileData, index) => (
                  <FileContainer key={index}>
                    <ImageContainer>
                      {fileData.file.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                      <FileDetails>
                        {fileData.file && (
                          <>
                            <Typography
                              variant='body1'
                              style={{ fontWeight: 'bold', marginLeft: '10px' }}
                            >
                              {fileData.file.name}
                            </Typography>
                            <Typography variant='caption' style={{ marginLeft: '10px' }}>
                              {(fileData.file.size / 1024).toFixed(2)} KB
                            </Typography>
                          </>
                        )}
                      </FileDetails>
                      <IconButton onClick={() => handleDeleteFile(index)}>
                        <DeleteIcon style={{ color: 'red' }} />
                      </IconButton>
                    </ImageContainer>
                    <ModalDivider />
                    <CustomInputText
                      placeholder='Document Name'
                      value={fileData.docname}
                      onChange={(e) => handleDocumentNameChange(e, index)}
                    />
                    {errors[`docname_${index}`] && (
                      <Typography variant='caption' style={{ color: 'red' }}>
                        {errors[`docname_${index}`]}
                      </Typography>
                    )}
                  </FileContainer>
                ))}
            </FileContainerDetails>

            <ModalDivider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                pt: 2,
                mt: 4,
              }}
            >
              <LoadingButton
                color='error'
                variant='outlined'
                sx={{ height: '40px', mt: 0.5 }}
                onClick={handleChangeStatus}
                style={{ boxShadow: 'none' }}
                disabled={loading}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                style={{ boxShadow: 'none' }}
                sx={{ height: '40px', mt: 0.5, ml: 0.5 }}
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* <LeadsSuccess
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        to='New Project'
      /> */}
    </>
  );
};

export default LeadsDetailStatus;
