import { useState, useEffect, useMemo } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { Formik, Form, FieldArray } from 'formik';
import { fetchAllUsers } from 'app/contexts/leadActions';
import { handleAxiosError } from 'app/utils/helpers';
import useUserAuth from 'app/hooks/userUserAuth';
import { validationSchemas } from 'app/utils/validationSchemas';
import { Loading } from 'app/components';
import { useProject } from 'app/contexts/ProjectContext';

const DialogDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const AddEditMembers = ({ selectedMember, closeModal }) => {
  const { userNameJWT } = useUserAuth();
  const { members: projectMembers, roles, isLead, addMembers, updateMemberRole } = useProject();
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isEditing = !!selectedMember;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (!isEditing) {
          const usersResponse = await fetchAllUsers();
          setAllUsers(usersResponse);
        }
      } catch (error) {
        handleAxiosError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [isEditing]);

  const initialValues = isEditing
    ? {
        members: [
          {
            name: selectedMember.memberName,
            phone: selectedMember.phone,
            email: selectedMember.email,
            roles: selectedMember.userRoles.map((role) => ({ name: role })),
          },
        ],
      }
    : {
        members: [{ name: '', phone: '', email: '', roles: [] }],
      };

  const usersMap = useMemo(() => {
    const users = new Map(allUsers.map((user) => [user.email, user]));

    projectMembers.forEach((member) => {
      users.delete(member.email);
    });

    return users;
  }, [allUsers, projectMembers]);

  const selectedUsersMap = useMemo(() => {
    const selectedUsers = new Map();

    projectMembers.forEach((member) => {
      selectedUsers.set(member.email, member);
    });

    return selectedUsers;
  }, [projectMembers]);

  const availableRoles = useMemo(() => {
    const currentUserRoles = projectMembers.find(
      (member) => member.userId === userNameJWT,
    ).userRoles;

    return roles.filter((role) => {
      if (isLead && role.name === 'Investor') return false;
      if (role === 'Owner' && !currentUserRoles.includes('Owner')) return false;
      return true;
    });
  }, [roles, isLead, userNameJWT, projectMembers]);

  const isUserExists = (member) => {
    const user = selectedUsersMap.get(member.email);

    if (user) {
      const existingFullName = `${user.name} ${user.surName}`.toLowerCase().trim();
      const inputFullName = member.name.toLowerCase().trim();
      const existingPhone = user.contactPhone;
      const inputPhone = member.phone;

      return (
        existingFullName === inputFullName &&
        existingPhone === inputPhone &&
        user.email === member.email
      );
    }
    return false;
  };

  const handleSubmit = async (values) => {
    try {
      !isEditing ? await addMembers(values.members) : await updateMemberRole(values.members);
      closeModal();
    } catch (error) {
      handleAxiosError(error);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={closeModal}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: { xs: '95vw', sm: '80vw', md: '65vw', lg: '55vw' },
          },
        },
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <DialogTitle>{isEditing ? 'Edit Member' : 'Add Members'}</DialogTitle>
            <IconButton onClick={closeModal} sx={{ marginRight: 2 }}>
              <Close />
            </IconButton>
          </Box>
          <DialogDivider />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.members(projectMembers, isEditing)}
            validateOnMount={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => {
              function selectUserInMap(email, value) {
                selectedUsersMap.set(email, value);
                usersMap.delete(email);
              }

              function removeUserFromMap(email) {
                const deletedMember = selectedUsersMap.get(email);
                if (deletedMember) {
                  usersMap.set(email, deletedMember);
                  selectedUsersMap.delete(email);
                }
              }

              function handleUserSelection(value, index) {
                if (value) {
                  selectUserInMap(value.email, value);

                  setFieldValue(`members[${index}].name`, `${value.name} ${value.surName}`);
                  setFieldValue(`members[${index}].phone`, value.contactPhone);
                  setFieldValue(`members[${index}].email`, value.email);
                }
              }

              function handleUserRemoval(index) {
                const member = values.members[index];
                removeUserFromMap(member.email);

                setFieldValue(`members[${index}].phone`, '');
                setFieldValue(`members[${index}].email`, '');
                setFieldValue(`members[${index}].roles`, []);
              }

              function filterUserOptions(options, state) {
                const inputValue = state.inputValue.trim().toLowerCase();
                if (inputValue.length < 3) return [];
                const inputParts = inputValue.split(/\s+/);
                return options.filter((option) => {
                  const fullName = `${option.name} ${option.surName}`.toLowerCase();
                  return inputParts.every((part) => fullName.includes(part));
                });
              }

              return (
                <Form>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FieldArray name='members'>
                          {({ push, remove }) => (
                            <>
                              {values.members.map((member, index) => (
                                <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                  <Grid item xs={12} sm={6}>
                                    <InputLabel
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: 14,
                                        mb: 1,
                                        color: '#272937',
                                      }}
                                    >
                                      Member Name
                                    </InputLabel>
                                    <Autocomplete
                                      freeSolo
                                      readOnly={isEditing}
                                      options={Array.from(usersMap.values())}
                                      getOptionLabel={(user) => `${user.name} ${user.surName}`}
                                      noOptionsText={member.name?.length < 3 && ''}
                                      inputValue={member.name}
                                      filterOptions={filterUserOptions}
                                      renderOption={(props, user) => (
                                        <Box
                                          component='li'
                                          sx={{ display: 'flex', alignItems: 'center' }}
                                          {...props}
                                          key={user.email}
                                        >
                                          <Avatar
                                            sx={{
                                              bgcolor: 'grey',
                                              width: 30,
                                              height: 30,
                                              marginRight: 2,
                                              fontSize: 12,
                                              fontWeight: 700,
                                            }}
                                          >
                                            {`${user?.name?.[0]?.toUpperCase()}${user?.surName?.[0]?.toUpperCase()}`}
                                          </Avatar>
                                          <Box>
                                            <Typography variant='subtitle2' noWrap>
                                              {`${user?.name} ${user?.surName}`}
                                            </Typography>
                                            <Typography
                                              variant='body2'
                                              color='text.secondary'
                                              noWrap
                                            >
                                              {user?.email}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      )}
                                      onChange={(_, value) => handleUserSelection(value, index)}
                                      onInputChange={(_, value) => {
                                        setFieldValue(`members[${index}].name`, value);
                                        if (!value) {
                                          handleUserRemoval(index);
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name={`members[${index}].name`}
                                          placeholder='e.g. John Doe'
                                          onBlur={handleBlur}
                                          error={
                                            touched.members?.[index]?.name &&
                                            Boolean(errors.members?.[index]?.name)
                                          }
                                          helperText={
                                            touched.members?.[index]?.name &&
                                            errors.members?.[index]?.name
                                          }
                                          fullWidth
                                          InputProps={{
                                            ...params.InputProps,
                                            readOnly: isEditing,
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <InputLabel
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: 14,
                                          color: '#272937',
                                          mb: 1,
                                        }}
                                      >
                                        Select Roles
                                      </InputLabel>
                                      {values.members.length > 1 && (
                                        <IconButton
                                          onClick={() => {
                                            handleUserRemoval(index);
                                            remove(index);
                                          }}
                                          size='small'
                                          sx={{ padding: 0 }}
                                          aria-label='removeMember'
                                        >
                                          <Close style={{ color: 'red' }} />
                                        </IconButton>
                                      )}
                                    </Box>
                                    <Autocomplete
                                      multiple
                                      options={availableRoles}
                                      filterSelectedOptions={true}
                                      getOptionLabel={(role) => role.name}
                                      isOptionEqualToValue={(option, value) =>
                                        option.name === value.name
                                      }
                                      onChange={(_, newValue) => {
                                        setFieldValue(`members[${index}].roles`, newValue);
                                      }}
                                      value={member.roles}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='medium'
                                          variant='outlined'
                                          label='Select category'
                                          name={`members[${index}].roles`}
                                          onBlur={handleBlur}
                                          error={
                                            touched.members?.[index]?.roles &&
                                            Boolean(errors.members?.[index]?.roles)
                                          }
                                          helperText={
                                            touched.members?.[index]?.roles &&
                                            errors.members?.[index]?.roles
                                          }
                                          fullWidth
                                        />
                                      )}
                                      renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => {
                                          const { key, ...rest } = getTagProps({ index });
                                          return <Chip key={key} label={option.name} {...rest} />;
                                        })
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <InputLabel
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: 14,
                                        mb: 1,
                                        color: '#272937',
                                      }}
                                    >
                                      Contact Number
                                    </InputLabel>
                                    <TextField
                                      fullWidth
                                      name={`members[${index}].phone`}
                                      value={member.phone}
                                      placeholder='e.g. +08012345678'
                                      onChange={(e) => handleChange(e)}
                                      onBlur={handleBlur}
                                      error={
                                        touched.members?.[index]?.phone &&
                                        Boolean(errors.members?.[index]?.phone)
                                      }
                                      helperText={
                                        touched.members?.[index]?.phone &&
                                        errors.members?.[index]?.phone
                                      }
                                      inputProps={{
                                        readOnly: isEditing || isUserExists(member),
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <InputLabel
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: 14,
                                        mb: 1,
                                        color: '#272937',
                                      }}
                                    >
                                      Email Address
                                    </InputLabel>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                      <TextField
                                        fullWidth
                                        name={`members[${index}].email`}
                                        value={member.email}
                                        onChange={(e) => handleChange(e)}
                                        error={
                                          touched.members?.[index]?.email &&
                                          Boolean(errors.members?.[index]?.email)
                                        }
                                        placeholder='e.g. johndoe@gmail.com'
                                        onBlur={handleBlur}
                                        helperText={
                                          touched.members?.[index]?.email &&
                                          errors.members?.[index]?.email
                                        }
                                        inputProps={{
                                          readOnly: isEditing || isUserExists(member),
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              ))}
                              {!isEditing && (
                                <Grid item xs={12}>
                                  <LoadingButton
                                    color='primary'
                                    variant='text'
                                    disabled={isSubmitting || Boolean(errors?.members?.length)}
                                    onClick={() =>
                                      push({ name: '', phone: '', email: '', roles: [] })
                                    }
                                  >
                                    + Add Member
                                  </LoadingButton>
                                </Grid>
                              )}
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 2,
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 2,
                          }}
                        >
                          <LoadingButton
                            color='error'
                            size='large'
                            className='cancelButton'
                            disabled={isSubmitting}
                            onClick={closeModal}
                          >
                            Cancel
                          </LoadingButton>
                          <LoadingButton
                            color='primary'
                            size='large'
                            variant='contained'
                            type='submit'
                            loading={isSubmitting}
                          >
                            Save
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Dialog>
  );
};

export default AddEditMembers;
