import React from 'react';
import { List, ListItem, Box, ListItemText, ListItemAvatar, Avatar, Typography, CircularProgress } from '@mui/material';
import { purple, brown } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import { auditLogs } from 'app/contexts/projectActions';
import { notify } from 'app/services/notify';
import { convertUTCDateToLocal } from 'app/utils/helpers';

const actions = [
  {
    user: 'Michelle John',
    action: 'Added a new member',
    date: 'Feb 12 2024',
    color: '#AB84B0', field: 'Danny Carie',
    oldValue: 'sample old value',
    newValue: 'sample new value',
  },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
    field: 'Danny Carie',
    oldValue: 'sample old value',
    newValue: 'sample new value',
  },
  {
    user: 'Michelle John',
    action: 'Added a new member',
    date: 'Feb 12 2024',
    color: '#AB84B0',
    field: 'Danny Carie',
    oldValue: 'sample old value',
    newValue: 'sample new value',
  },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
    field: 'Danny Carie',
    oldValue: 'sample old value',
    newValue: 'sample new value',
  },
  {
    user: 'Michelle John',
    action: 'Added a new member',
    date: 'Feb 12 2024',
    color: '#AB84B0',
    field: 'Danny Carie',
    oldValue: 'sample old value',
    newValue: 'sample new value',
  },
  {
    user: 'Danny Carie',
    action: 'uploaded the project plan documents',
    date: 'Feb 12 2024',
    color: '#D5A872',
    field: 'Danny Carie',
    oldValue: 'sample old value',
    newValue: 'sample new Value',
  },
];

const AuditLog = ({ leads }) => {
  const [auditLog, setAuditLog] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { projectId } = useParams();

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await auditLogs(projectId);
        setAuditLog(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const errorMessage =
          err?.response?.data?.message || err?.message || 'Something went wrong!';
        notify.error(errorMessage);
      }
    };

    fetchData();
  }, [projectId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {auditLog.length > 0 ? (
        <Box sx={{ padding: 0, margin: 0 }}>
          <List sx={{ padding: 0,margin:0}}>
            {auditLog.map((log, index) => (
              <ListItem
                key={index}
                alignItems="flex-start"
                sx={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: '#AB84B0' }}>
                    {log?.userId
                      .split(' ')
                      .map((name) => name[0])
                      .join('')}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      color="text.primary"
                    >
                      {log?.userId} {log?.eventType}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="text.secondary">
                        <b>{log?.fieldChanges?.[log?.fieldChanges.length - 1]?.field}:</b>{' '}
                        {(log?.fieldChanges?.[log?.fieldChanges.length - 1]?.oldValue ?? 'None')} →{' '}
                        {(log?.fieldChanges?.[log?.fieldChanges.length - 1]?.newValue ?? 'None')}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ display: 'block'}}
                      >
                        {convertUTCDateToLocal(log?.created)}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            display: 'flex',
            textAlign: 'center',
            height: '200px',
            justifyContent: 'center'
          }}
        >
          No new audit logs.
        </Typography>
      )}
    </Box>
  );
};
export default AuditLog;
