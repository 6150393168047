import React from 'react';
import { Box, styled } from '@mui/material';
import useImagesContext from 'app/hooks/useImagesContext';
import useImagePagination from 'app/hooks/useImagePagination';

const ImageContainerWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '8px',
  marginTop: '8px',
  position: 'relative',
});

const ImageBox = styled(Box)({
  height: '120px',
  overflow: 'hidden',
  borderRadius: '4px',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'block',
    position: 'relative',
  },
});

const ArrowButton = styled(Box)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
});

const ImageContainer = () => {
  const { imageElements, handleImageClick } = useImagesContext();

  const {
    imagesToShow,
    currentPage,
    totalPages,
    handleNextPage,
    handlePrevPage,
    imageContainerRef,
  } = useImagePagination(imageElements);

  return (
    <ImageContainerWrapper ref={imageContainerRef} onClick={handleImageClick}>
      {imagesToShow.map((image) => (
        <ImageBox key={image.alt}>{image}</ImageBox>
      ))}
      {currentPage > 0 && (
        <ArrowButton style={{ left: '10px' }} onClick={handlePrevPage}>
          {'<'}
        </ArrowButton>
      )}
      {currentPage < totalPages - 1 && (
        <ArrowButton style={{ right: '10px' }} onClick={handleNextPage}>
          {'>'}
        </ArrowButton>
      )}
    </ImageContainerWrapper>
  );
};

export default ImageContainer;
