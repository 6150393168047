import React, { useState } from 'react';
import { Box, Typography, Paper, Grid, Table, TableBody, TableCell, TableHead, TableRow, Link } from '@mui/material';

const AmenitySubType = ({ selectedAmenity }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);

  const isAmenityAvailable = selectedAmenity?.subtypes?.length > 0;

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '1%' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography>
            {selectedAmenity?.name} (Type: {selectedAmenity?.type})
          </Typography>
          <Link
            component='button'
            sx={{
              marginRight: '1%',
              color: isAmenityAvailable ? 'var(--update-Balance-paid)' : 'var(--update-Balance-notPaid)',
              textDecoration: 'underline',
              cursor: isAmenityAvailable ? 'pointer' : 'not-allowed',
            }}
            onClick={toggleDialog}
            disabled={!isAmenityAvailable}
          >
            View Subtypes
          </Link>
        </Box>

        {isAmenityAvailable ? (
          <Paper elevation={3} sx={{ p: 2, width: '100%', marginBottom: '1%' }}>
            <Typography
              color='var(--updatedTime)'
              align='center'
              bgcolor='var(--updatedTime-bg)'
              fontWeight='500'
              width='155px'
              gutterBottom
            >
              Amenity Subtypes
            </Typography>

            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  {/* <TableCell><strong>Upgrade Name</strong></TableCell> */}
                  <TableCell><strong>Upgrade Name</strong></TableCell>
                  <TableCell><strong>Price</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedAmenity?.subtypes?.map((subtype, index) => (
                  <TableRow key={index}>
                    {/* <TableCell>{selectedAmenity?.type || 'N/A'}</TableCell> */}
                    <TableCell>{subtype?.name || 'N/A'}</TableCell>
                    <TableCell>{subtype?.price || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <Typography>No subtypes available for this amenity</Typography>
        )}
      </Box>
    </>
  );
};

export default AmenitySubType;
