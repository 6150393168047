import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Grid, InputLabel, Autocomplete, TextField } from '@mui/material';
import notify from 'app/utils/notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { paymentOptions } from 'app/utils/constant';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '0.6250em',
  paddingRight: '0.6250em',
  borderRadius: '4px',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

const Inputlabel = styled('div')({
  marginBottom: '5%',
});

const AutoComplete = styled(Autocomplete)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
  },
}));

const validationSchema = Yup.object().shape({
  totalAmount: Yup.number()
    .required('Total Amount is required')
    .positive('Total Amount must be positive'),
  amountPaid: Yup.number()
    .required('Paid Amount is required')
    .min(0, 'Paid Amount cannot be negative')
    .test(
      'is-less-than-total',
      'Paid Amount cannot be greater than Total Amount',
      function (value) {
        const { totalAmount } = this.parent;
        return value <= totalAmount;
      },
    ),
  paidBy: Yup.string().required('Paid By is required'),
  paidTo: Yup.string().required('Paid To is required'),
  paymentType: Yup.string().required('Mode of Payment is required'),
  comments: Yup.string().optional(),
});

const PaymentDetailsModal = ({ gcpayments, PaymentDetails, handleCloseModal, gcpaymentIndex }) => {
  const { addGcPayments, updatePaymentDetails } = useProject();
  const [selectPaymentType, setSelectPaymentType] = React.useState(null);
  const latestPayment = PaymentDetails?.paymentHistory[PaymentDetails?.paymentHistory.length - 1];


  React.useEffect(() => {
    if (PaymentDetails) {
      setSelectPaymentType(
        paymentOptions.find((option) => option.label === latestPayment?.paymentType)
      );
    }
  }, [PaymentDetails, latestPayment?.paymentType]);

  const initialValues = {
    totalAmount: latestPayment?.totalAmount || '',
    amountPaid: latestPayment?.amountPaid || '',
    balanceAmount:latestPayment?.balance,
    paidBy: latestPayment?.paidBy || '',
    paidTo: latestPayment?.paidTo || '',
    paymentType: latestPayment?.paymentType || '',
  };
  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const gcPaymentDetails = {
        totalAmount: values.totalAmount,
        amountPaid: values.amountPaid,
        paidBy: values.paidBy,
        paidTo: values.paidTo,
        paymentType: values.paymentType,
        comments: values.comments || '',
      };
      if (PaymentDetails) {
        const updatedPayments = {
          ...gcPaymentDetails,
        };
        await updatePaymentDetails(
          gcpayments[gcpaymentIndex]?.projectId,
          gcpayments[gcpaymentIndex]?.id,
          PaymentDetails?.paymentId,
          updatedPayments,
        );
      } else {
        const newPayments = {
          totalAmount: gcPaymentDetails?.totalAmount,
          expenseScreen: false,
          paymentHistory: [gcPaymentDetails]
        }
        await addGcPayments(
          gcpayments[gcpaymentIndex]?.projectId,
          gcpayments[gcpaymentIndex]?.id,
          newPayments,
        );
      }
      handleCloseModal();
    } catch (error) {
      if (error.response.data.error) {
        notify.error(error.response.data.errors[0].errorMessage);
      } else {
        notify.error(`Error in GC PaymentDetails: ${error.message}`);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '40%',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <Title>{PaymentDetails ? 'Edit' : 'Add'} Payment Details</Title>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: '#272937',
              }}
            />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Total Amount</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='number'
                        name='totalAmount'
                        value={values.totalAmount}
                        InputProps={{
                          readOnly: Boolean(initialValues.totalAmount),
                          sx: Boolean(initialValues.totalAmount)
                            ? {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                            : {},
                        }}
                        onChange={handleChange}
                        placeholder='e.g. $2000.00'
                        fullWidth
                        variant='outlined'
                        size='small'
                        error={Boolean(touched.totalAmount && errors.totalAmount)}
                        helperText={touched.totalAmount && errors.totalAmount}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Paid Amount</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='number'
                        name='amountPaid'
                        value={values.amountPaid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='e.g. $1800.00'
                        fullWidth
                        variant='outlined'
                        size='small'
                        inputProps={{ min: 0 }}
                        error={Boolean(touched.amountPaid && errors.amountPaid)}
                        helperText={touched.amountPaid && errors.amountPaid}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Balance Amount</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='number'
                        name='balanceAmount'
                        // value={(values.totalAmount - values.amountPaid).toFixed(2)}
                        value={values.balanceAmount ? values.balanceAmount : values.balanceAmount === 0 && latestPayment?.balance === 0 ? '0.00' : (values.totalAmount - values.amountPaid)}
                        placeholder='e.g. $200.00'
                        fullWidth
                        variant='outlined'
                        size='small'
                        InputProps={{
                          readOnly: true,
                          sx: {
                            fontWeight: 'bold',
                            backgroundColor: '#f0f0f0',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Paid By</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='paidBy'
                        value={values.paidBy}
                        onChange={handleChange}
                        placeholder='e.g. James'
                        fullWidth
                        variant='outlined'
                        size='small'
                        inputProps={{ min: 0 }}
                        error={Boolean(touched.paidBy && errors.paidBy)}
                        helperText={touched.paidBy && errors.paidBy}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: '1px' }} container spacing={2}>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Paid To</InputLabel>
                      </Inputlabel>
                      <TextField
                        type='text'
                        name='paidTo'
                        value={values.paidTo}
                        onChange={handleChange}
                        placeholder='e.g. John'
                        fullWidth
                        variant='outlined'
                        size='small'
                        inputProps={{ min: 0 }}
                        error={Boolean(touched.paidTo && errors.paidTo)}
                        helperText={touched.paidTo && errors.paidTo}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Inputlabel>
                        <InputLabel>Paid Type</InputLabel>
                      </Inputlabel>
                      <AutoComplete
                        size='small'
                        value={selectPaymentType}
                        onChange={(event, newValue) => {
                          setSelectPaymentType(newValue || paymentOptions[0]);
                          setFieldValue('paymentType', newValue ? newValue.label : '');
                        }}
                        options={paymentOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            className='textfield'
                            style={{ width: '87%' }}
                            label='Mode Of Payment'
                            error={Boolean(touched.paymentType && errors.paymentType)}
                            helperText={touched.paymentType && errors.paymentType}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '16px',
                        marginBottom: '10px',
                      }}
                    >
                      <LoadingButton
                        className='cancelButton'
                        color='error'
                        variant='outlined'
                        onClick={handleCloseModal}
                        style={{ boxShadow: 'none' }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        variant='contained'
                        color='primary'
                        type='submit'
                        loading={isSubmitting}
                      >
                        {isSubmitting ? 'Update' : 'Save'}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Container>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </div>
  );
};

export default PaymentDetailsModal;
