import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Modal, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalCancel, ModalContent, ModalSave, StyledBoxFooter } from 'app/common/Typography';
import { useFormik } from 'formik';
import { validationSchemas } from 'app/utils/validationSchemas';
import { useProject } from 'app/contexts/ProjectContext';
import { StyledLabel } from '../../listings/Modals/StyledComponents';
import BuyerAddEdit from './BuyerAddEdit';
import CustomDropdown from './CustomDropdown';

const SaleAddEdit = ({
  open,
  saleData,
  buyerData,
  onClose,
  selectedBuilding,
  selectedUnit,
  saleId,
  handleAddSaleClick,
  isEdit = false,
}) => {
  const [openSaleModal, setOpenSaleModal] = useState(false);

  const handleClose = () => {
    onClose();
  };
  const handleBackToSaleForm = () => {
    setOpenSaleModal(false);
    handleAddSaleClick();
  }

  const handleBuyerSaleFormClick = () => {
    setOpenSaleModal(true);
  }

  const handleSaveSubmit = (values, { setSubmitting }) => {
    handleBuyerSaleFormClick();
    handleClose();
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: {
      buyerRealtor: saleData?.buyerRealtor || '',
      saleSpace: selectedUnit?.space || '',
      unitNo: selectedUnit?.unitName || '',
      salePrice: saleData?.salePrice || '',
      saleExecutionDate: saleData?.saleExecutionDate || '',
      saleFixturizationDate: saleData?.saleFixturizationDate || '',
      saleStartDate: saleData?.saleStartDate || '',
      saleEndDate: saleData?.saleEndDate || '',
      buyerSaleCommission: saleData?.buyerSaleCommission || '',
      sellerSaleCommission: saleData?.sellerSaleCommission || '',
      closingCost: saleData?.closingCost || '',
      titlePolicyCost: saleData?.titlePolicyCost || '',
      comments: saleData?.comments || '',
    },
    validationSchema: validationSchemas.sale(),
    enableReinitialize: true,
    onSubmit: handleSaveSubmit,
  });
  useEffect(()=>{
    if(!isEdit) {
      formik.resetForm();
    }
  },[])

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '0%',
            transform: 'translate(0%, -50%)',
            width: '50%',
            bgcolor: 'background.paper',
            p: 4,
            overflowY: 'auto',
            height: '100%',
            paddingLeft: '40px',
          }}
        >
          <ModalContent>
            <Box sx={{ justifyContent: 'space-between', display: 'flex', marginBottom: '20px' }}>
              <Typography variant='h6'>{`${isEdit ? 'Edit' : 'Add'} Sale Details`} </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ fontSize: 18, color: 'var(--closeIcon)' }} />
              </IconButton>
            </Box>

            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledLabel>Buyer Realtor</StyledLabel>
                  <TextField
                    name='buyerRealtor'
                    id='buyerRealtor'
                    size='small'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.buyerRealtor}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.buyerRealtor && Boolean(formik.errors.buyerRealtor)}
                    helperText={formik.touched.buyerRealtor && formik.errors.buyerRealtor}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledLabel>Sale Space</StyledLabel>
                  <TextField
                    name='saleSpace'
                    id='saleSpace'
                    size='small'
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={selectedUnit?.space || saleData?.saleSpace || ''}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    error={formik.touched.saleSpace && Boolean(formik.errors.saleSpace)}
                    helperText={formik.touched.saleSpace && formik.errors.saleSpace}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Unit Name</StyledLabel>
                  <TextField
                    id='unitNo'
                    name='unitNo'
                    size='small'
                    type='text'
                    fullWidth
                    value={selectedUnit?.unitName || saleData?.unitNo || ''}
                    InputProps={{
                      readOnly: true, // Set to read-only
                    }}
                    error={formik.touched.unitNo && Boolean(formik.errors.unitNo)}
                    helperText={formik.touched.unitNo && formik.errors.unitNo}
                  />
                </Grid>

                <Grid item xs={6}>
                  <StyledLabel>Sale Price</StyledLabel>
                  <TextField
                    id='salePrice'
                    name='salePrice'
                    type='number'
                    size='small'
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    value={formik.values.salePrice}
                    onChange={(e) => {
                      const value = e.target.value;

                      formik.setFieldValue('salePrice', value === '' ? '' : Math.abs(value));
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.salePrice && Boolean(formik.errors.salePrice)}
                    helperText={formik.touched.salePrice && formik.errors.salePrice}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <StyledLabel>Amenities</StyledLabel>
                  <CustomDropdown />
                </Grid>
                <Grid item xs={6}>
                  <StyledLabel>Buyer Sale Commission</StyledLabel>
                  <TextField
                    name='buyerSaleCommission'
                    size='small'
                    type='number'
                    id='buyerSaleCommission'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.buyerSaleCommission}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue(
                        'buyerSaleCommission',
                        value === '' ? '' : Math.abs(value),
                      );
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.buyerSaleCommission &&
                      Boolean(formik.errors.buyerSaleCommission)
                    }
                    helperText={
                      formik.touched.buyerSaleCommission && formik.errors.buyerSaleCommission
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledLabel>Seller Sale Commission</StyledLabel>
                  <TextField
                    id='sellerSaleCommission'
                    name='sellerSaleCommission'
                    size='small'
                    type='number'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.sellerSaleCommission}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue(
                        'sellerSaleCommission',
                        value === '' ? '' : Math.abs(value),
                      );
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.sellerSaleCommission &&
                      Boolean(formik.errors.sellerSaleCommission)
                    }
                    helperText={
                      formik.touched.sellerSaleCommission && formik.errors.sellerSaleCommission
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledLabel>Closing Cost</StyledLabel>
                  <TextField
                    id='closingCost'
                    name='closingCost'
                    size='small'
                    type='number'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.closingCost}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue('closingCost', value === '' ? '' : Math.abs(value));
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.closingCost && Boolean(formik.errors.closingCost)}
                    helperText={formik.touched.closingCost && formik.errors.closingCost}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledLabel>Title Policy Cost</StyledLabel>
                  <TextField
                    id='titlePolicyCost'
                    name='titlePolicyCost'
                    size='small'
                    type='number'
                    fullWidth
                    onKeyDown={(e) => {
                      // Block unwanted keys
                      const blockedKeys = [
                        'e',
                        'E',
                        '+',
                        '-',
                        '.',
                        '/',
                        "'",
                        '[',
                        ']',
                        '{',
                        '}',
                        '\\',
                        ';',
                        ':',
                      ];
                      if (blockedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.titlePolicyCost}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue('titlePolicyCost', value === '' ? '' : Math.abs(value));
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.titlePolicyCost && Boolean(formik.errors.titlePolicyCost)}
                    helperText={formik.touched.titlePolicyCost && formik.errors.titlePolicyCost}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledLabel>Comments</StyledLabel>
                  <TextField
                    id='comments'
                    name='comments'
                    size='small'
                    fullWidth
                    multiline
                    rows={4}
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
              <StyledBoxFooter>
                <ModalCancel
                  className='cancelButton'
                  variant='outlined'
                  onClick={handleClose}
                  style={{ boxShadow: 'none' }}
                  disabled={formik.isSubmitting}
                  sx={{ textTransform: 'none' }}
                >
                  Cancel
                </ModalCancel>
                <ModalSave
                  color='primary'
                  size='large'
                  variant='contained'
                  type='submit'
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  sx={{ textTransform: 'none', marginLeft: 2 }}
                >
                  Next
                </ModalSave>
              </StyledBoxFooter>
            </form>
          </ModalContent>
        </Box>
      </Modal>
      <BuyerAddEdit
        open={openSaleModal}
        handleCancel={() => setOpenSaleModal(false)}
        onClose={() => setOpenSaleModal(false)}
        saleData={formik.values}
        buyerData={buyerData}
        selectedUnit={selectedUnit}
        selectedBuilding={selectedBuilding}
        onBack={handleBackToSaleForm}
        saleId={saleId}
        handleBuyerSaleFormClick={handleBuyerSaleFormClick}
        isEdit={isEdit}
      />
    </>
  );
};

export default SaleAddEdit;
