import { useQuery } from '@tanstack/react-query';
import { getProfileLLC } from 'app/contexts/userAuthActions';
import { queryKeys } from 'app/react-query/queryKeys';

export function useProfileLLC(userId) {
  return useQuery({
    queryKey: [queryKeys.profileLLC, userId], 
    queryFn: () => getProfileLLC(userId),
    placeholderData: [],
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
