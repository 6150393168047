import { createSlice } from '@reduxjs/toolkit';
import { fetchProjectsCounts } from '../store';

const initialState = {
  lead: 0,
  newproject: 0,
  ongoing: 0,
  onhold: 0,
  cancelled: 0,
  completed: 0,
};

const projectsCounterSlice = createSlice({
  name: 'projectsCounter',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchProjectsCounts.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
    });
  },
});

export default projectsCounterSlice.reducer;
