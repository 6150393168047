import React, { useState, useEffect } from 'react';
import { Avatar, Button, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import ProfileImageModal from './ProfileImage';

const ProfileUpload = ({ profileImage }) => {
  const [preview, setPreview] = useState('');
  const [uploadProfile, setUploadProfile] = useState(false);

  useEffect(() => {
    if (profileImage) {
      const base64Image = `data:image/jpeg;base64,${profileImage.content}`;
      setPreview(base64Image);
    }
  }, [profileImage]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    setUploadProfile(!uploadProfile);
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <input
          accept='image/*'
          style={{ display: 'none' }}
          id='upload-photo'
          type='file'
          onChange={handleImageUpload}
        />
        <label htmlFor='upload-photo'>
          <IconButton
            color='primary'
            aria-label='upload picture'
            component='span'
            onClick={handleClick}
          >
            <Avatar src={preview || ''} style={{ width: 100, height: 100 }} />
          </IconButton>
        </label>
        <Stack direction='row' justifyContent='center'>
          <Button variant='text' color='primary' onClick={handleClick}>
            Upload Photo
          </Button>
        </Stack>
      </div>
      <ProfileImageModal open={uploadProfile} handleClose={handleClick} />
    </>
  );
};

ProfileUpload.propTypes = {
  profileImage: PropTypes.shape({
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileUpload;
