import { createContext, useCallback, useEffect, useReducer } from 'react';
import { useContext } from 'react';
import {
  fetchLeadsById,
  fetchLeadsDocById,
  fetchLeadsLLcById,
  fetchLeadsLocationById,
  fetchLeadsMembersById,
  projectBuildingCreateUpdate,
  projectBuildingDetails,
  projectClosingContractDetails,
  projectCreateClosingContract,
  projectCreateUpdateContractDetails,
  projectCreateUpdateInvestments,
  projectDocumentDelete,
  projectExpenseCreateUpdate,
  updateExpenses,
  projectExpenseDetails,
  projectGetContractDetails,
  projectGetInvestmentDetails,
  projectLeadDocumentInformation,
  projectLeadLLCInformation,
  projectLeadLocationInformation,
  projectLeadMemberDelete,
  projectLeadMemberInformation,
  projectUpdateLeadInformation,
  projectUpdateType,
  projectGCDetails,
  projectGCCreate,
  updateGCDetails,
  gcAddChangeOrders,
  projectGetGC,
  gcAddPayments,
  gcAddManagerSiteManager,
  projectBuildingUnitsCreateUpdate,
  addBuildingLease,
  addBuildingSale,
  gcUpdateChangeOrders,
  gcUpdatePayments,
  gcUpdateManagerDetails,
  getAllPM,
  updatePM,
  getSinglePM,
  createPM,
  addPMCP,
  updatePMCP,
  createCL,
  updateCL,
  getAllCL,
  addLoanWithdrawal,
  addLoanPayments,
  updateLoanPayments,
  updateLoanWithdrawal,
  projectLeadMemberUpdateRole,
  projectGetInvitations,
  fetchRoles,
  userGetInvestmentDetails,
  userAddInvestments,
  userAddInvestmentPayment,
  projectGetInvestments,
} from './leadActions';
import { handleAxiosError } from 'app/utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Loading } from 'app/components';
import { respondToInvestment, respondToInvitation } from './requestsActions';
import { fetchProjectsCounts } from 'app/redux/store';
import useUserAuth from 'app/hooks/userUserAuth';
import { requestsTypes } from 'app/utils/constant';

const ProjectContext = createContext();

const initialState = {
  basic: null,
  llc: null,
  location: null,
  documents: null,
  members: null,
  contract: null,
  investments: null,
  investmentDetails: null,
  expenses: [],
  leasesales: null,
  generalContract: [],
  propertyManagement: [],
  constructionLender: [],
  closing: null,
  requests: null,
  roles: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'lead/updateDetails':
      return {
        ...state,
        ...action.payload,
      };
    case 'lead/updateDetails/documents/delete':
      const filteredDocuments = state.documents.filter(
        (document) => document.metadata.fileId !== action.payload,
      );
      return {
        ...state,
        documents: filteredDocuments,
      };
    case 'lead/updateDetails/members/delete':
      const filteredMembers = state.members.filter((member) => member.email !== action.payload);
      return {
        ...state,
        members: filteredMembers,
      };
    default:
      return state;
  }
};

const sectionsMapping = {
  lead: ['location', 'documents', 'llc', 'members', 'requests', 'roles'],
  newproject: [
    'location',
    'documents',
    'llc',
    'members',
    'requests',
    'contract',
    'investments',
    'investmentDetails',
    'expenses',
    'leasesales',
    'generalContract',
    'propertyManagement',
    'constructionLender',
    'roles',
  ],
  ongoing: [
    'location',
    'documents',
    'llc',
    'members',
    'requests',
    'contract',
    'investments',
    'investmentDetails',
    'expenses',
    'leasesales',
    'generalContract',
    'propertyManagement',
    'constructionLender',
    'closing',
    'roles',
  ],
};

const ProjectProvider = ({ children }) => {
  const { projectId } = useParams();
  const { user, userNameJWT } = useUserAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const projectType = state?.basic?.projectType;
  const previousProjectType = state?.basic?.previousProjectType;

  const isLead = projectType === 'lead';
  const isCancelled = projectType === 'cancelled';
  const isOnHoldOrCancelled = projectType === 'onhold' || projectType === 'cancelled';

  const dispatchDetails = (data) => {
    dispatch({ type: 'lead/updateDetails', payload: data });
  };

  const dispatchDocumentDelete = (fileId) => {
    dispatch({ type: 'lead/updateDetails/documents/delete', payload: fileId });
  };

  const dispatchMemberDelete = (email) => {
    dispatch({ type: 'lead/updateDetails/members/delete', payload: email });
  };

  const fetchProjectData = useCallback(
    async function (sections) {
      const fetchers = {
        basic: fetchLeadsById,
        location: fetchLeadsLocationById,
        documents: fetchLeadsDocById,
        llc: fetchLeadsLLcById,
        members: fetchLeadsMembersById,
        contract: projectGetContractDetails,
        investments: projectGetInvestments,
        investmentDetails: projectGetInvestmentDetails,
        expenses: projectExpenseDetails,
        closing: projectClosingContractDetails,
        leasesales: projectBuildingDetails,
        generalContract: projectGCDetails,
        propertyManagement: getAllPM,
        constructionLender: getAllCL,
        requests: projectGetInvitations,
        roles: fetchRoles,
      };
      const fetchPromises = sections.map((section) => {
        const fetcher = fetchers[section];
        return fetcher(projectId, userNameJWT)
          .then((data) => ({ section, data }))
          .catch((error) => {
            return { section, data: null };
          });
      });

      const settledResults = await Promise.allSettled(fetchPromises);

      const results = settledResults.reduce((acc, result) => {
        if (result.status === 'fulfilled' && result.value?.data !== null) {
          acc[result.value.section] = result.value.data;
        }
        return acc;
      }, {});

      return results;
    },
    [projectId, userNameJWT],
  );

  const determineSections = useCallback(async () => {
    try {
      const leadBasic = await fetchLeadsById(projectId);
      const { projectType, previousProjectType } = leadBasic;

      let sections;
      projectType === 'onhold' || projectType === 'cancelled'
        ? (sections = sectionsMapping[previousProjectType])
        : (sections = sectionsMapping[projectType]);

      return { leadBasic, sections };
    } catch (error) {
      throw error;
    }
  }, [projectId]);

  const fetchData = useCallback(async () => {
    try {
      const { leadBasic, sections } = await determineSections();
      const leadDetailsData = await fetchProjectData(sections);
      dispatchDetails({ basic: leadBasic, ...leadDetailsData });
    } catch (error) {
      handleAxiosError(error);
    }
  }, [determineSections, fetchProjectData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetchSections = async (...sections) => {
    try {
      const data = await fetchProjectData(sections);
      dispatchDetails({ ...data });
    } catch (error) {
      throw error;
    }
  };

  const updateDetailsBasic = async (data) => {
    try {
      await updateDetails('basic', data);
    } catch (error) {
      throw error;
    }
  };

  const updateDetailsLocation = async (data) => {
    try {
      await updateDetails('location', data);
    } catch (error) {
      throw error;
    }
  };

  const uploadDocuments = async (documents) => {
    try {
      return await updateDetails('documents/upload', documents);
    } catch (error) {
      throw error;
    }
  };

  const deleteDocument = async (fileId) => {
    try {
      await updateDetails('documents/delete', fileId);
    } catch (error) {
      throw error;
    }
  };

  const addMembers = async (data) => {
    try {
      await updateDetails('members/add', data);
    } catch (error) {
      throw error;
    }
  };

  const updateMemberRole = async (data) => {
    try {
      await updateDetails('members/updateRole', data);
    } catch (error) {
      throw error;
    }
  };

  const deleteMember = async (member) => {
    try {
      await updateDetails('member/delete', member);
    } catch (error) {
      throw error;
    }
  };

  const updateContract = async (data) => {
    try {
      await updateDetails('contract', data);
    } catch (error) {
      throw error;
    }
  };

  const updateLLC = async (data) => {
    try {
      await updateDetails('LLC', data);
    } catch (error) {
      throw error;
    }
  };

  const respondToRequest = async (request, action, declineReason) => {
    const { id: invitationId, token: invitationToken, projectId, invitationType } = request;
    const isInvestmentRequest =
      invitationType === requestsTypes.INVESTMENT_MAIL ||
      invitationType === requestsTypes.PAYMENT_REQUEST_MAIL;

    const response = {
      invitationId,
      invitationToken,
      invitationAction: action.toUpperCase(),
      projectId,
      declineReason,
    };

    try {
      isInvestmentRequest
        ? await respondToInvestment(response)
        : await respondToInvitation(response);

      if (response.invitationAction === 'DECLINE' && !isInvestmentRequest) {
        navigate('/requests');
        return;
      }

      await refetchSections('members', 'requests', 'investments');
      await reduxDispatch(fetchProjectsCounts()).unwrap();
    } catch (error) {
      throw error;
    }
  };

  const updateProjectInvestments = async (data) => {
    try {
      await updateDetails('investments', data);
    } catch (error) {
      throw error;
    }
  };

  const sendInvestmentsRequest = async (data) => {
    const fullName = `${user.name} ${user.surname}`;

    const {
      investorUserId,
      interestedAmount,
      interestedShares,
      investedAmount,
      investedShares,
      paymentMode,
      paymentDate,
    } = data;

    const paymentDetails = [];

    if (investedAmount && investedShares && paymentMode && paymentDate) {
      paymentDetails.push({
        investedAmount,
        investedShares,
        paymentMode: paymentMode.toUpperCase(),
        paymentDate: paymentDate.toISOString(),
      });
    }

    const dataWithCredentials = {
      investorName: fullName,
      investorUserId: investorUserId || userNameJWT,
      interestedAmount,
      interestedShares,
      paymentDetails,
    };

    try {
      await userAddInvestments(projectId, dataWithCredentials);
      await refetchSections('investments', 'requests');
    } catch (error) {
      throw error;
    }
  };

  const sendInvestmentPayment = async (data) => {
    const { investorUserId, investedAmount, investedShares, paymentMode, paymentDate } = data;

    const paymentDetails = {
      investedAmount,
      investedShares,
      paymentDate: paymentDate.toISOString(),
      paymentMode: paymentMode.toUpperCase(),
    };

    try {
      await userAddInvestmentPayment(projectId, investorUserId || userNameJWT, paymentDetails);
      await refetchSections('investments', 'requests');
    } catch (error) {
      throw error;
    }
  };

  const addExpenses = async (data) => {
    try {
      await updateDetails('expenses', data);
    } catch (error) {
      throw error;
    }
  };

  const updateExpense = async (projectId, invoiceNo, expenseId, Data) => {
    try {
      await updateExpenses(projectId, invoiceNo, expenseId, Data);
      await refetchSections('expenses');
    } catch (error) {
      throw error;
    }
  };

  const getAllGCDetails = async (projectId) => {
    try {
      const result = await projectGCDetails(projectId);
      return result;
    } catch (error) {
      throw error;
    }
  };
  const getGCDetails = async (projectId, guId) => {
    try {
      const result = await projectGetGC(projectId, guId);
      return result;
    } catch (error) {
      throw error;
    }
  };
  const updateGC = async (data) => {
    try {
      await updateDetails('generalContract', data);
    } catch (error) {
      throw error;
    }
  };

  const createPMDetails = async (data) => {
    try {
      await updateDetails('propertyManagement', data);
    } catch (error) {
      throw error;
    }
  };

  const createConstructionLender = async (data) => {
    try {
      await updateDetails('constructionLender', data);
    } catch (error) {
      throw error;
    }
  };

  const updateConstructionLender = async (projectId, lenderId, data) => {
    try {
      await updateCL(projectId, lenderId, data);
      await refetchSections('constructionLender');
    } catch (error) {
      throw error;
    }
  };

  const addCLLoanWithdrawal = async (projectId, CLId, data) => {
    try {
      await addLoanWithdrawal(projectId, CLId, data);
      await refetchSections('constructionLender');
    } catch (error) {
      throw error;
    }
  };

  const addCLPaymentDetails = async (projectId, CLId, data) => {
    try {
      await addLoanPayments(projectId, CLId, data);
      await refetchSections('constructionLender');
    } catch (error) {
      throw error;
    }
  };

  const updateCLPaymentDetails = async (projectId, CLId, data) => {
    try {
      await updateLoanPayments(projectId, CLId, data);
      await refetchSections('constructionLender');
    } catch (error) {
      throw error;
    }
  };

  const updateCLLoanWithdrawals = async (projectId, CLId, data) => {
    try {
      await updateLoanWithdrawal(projectId, CLId, data);
      await refetchSections('constructionLender');
    } catch (error) {
      throw error;
    }
  };

  const updatePMDetails = async (projectId, pmId, Data) => {
    try {
      await updatePM(projectId, pmId, Data);
      const data = await refetchSections('propertyManagement');
      return data;
    } catch (error) {
      throw error;
    }
  };
  const updateRowGCDetails = async (projectId, gcId, Data) => {
    try {
      await updateGCDetails(projectId, gcId, Data);
      const data = await refetchSections('generalContract');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const addLeaseDetails = async (Data) => {
    try {
      const data = await addBuildingLease(projectId, Data);
      await refetchSections('leasesales');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const addSaleDetails = async (Data) => {
    try {
      const data = await addBuildingSale(projectId, Data);
      await refetchSections('leasesales');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const addGcChangeOrders = async (projectId, gcId, Data) => {
    try {
      const data = await gcAddChangeOrders(projectId, gcId, Data);
      await refetchSections('generalContract');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const addGcPayments = async (projectId, gcId, Data) => {
    try {
      const data = await gcAddPayments(projectId, gcId, Data);
      await refetchSections('generalContract');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const addGCManagerSiteManager = async (projectId, gcId, Data) => {
    try {
      const data = await gcAddManagerSiteManager(projectId, gcId, Data);
      await refetchSections('generalContract');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const updateChangeOrderDetails = async (projectId, gcId, Data) => {
    try {
      let data = await gcUpdateChangeOrders(projectId, gcId, Data);
      await refetchSections('generalContract');
      return data;
    } catch (error) {
      throw error;
    }
  };
  const updatePaymentDetails = async (projectId, gcId, paymentId, Data) => {
    try {
      let data = await gcUpdatePayments(projectId, gcId, paymentId, Data);
      await refetchSections('generalContract');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const updateManagerDetails = async (projectId, gcId, Data) => {
    try {
      let data = await gcUpdateManagerDetails(projectId, gcId, Data);
      await refetchSections('generalContract');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const getSinglePropertyManagement = async (projectId) => {
    try {
      let data = await getSinglePM(projectId);
      await refetchSections('propertyManagement');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const addPMcontactPersons = async (projectId, pmId, Data) => {
    try {
      await addPMCP(projectId, pmId, Data);
      let data = await refetchSections('propertyManagement');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const updatePMcontactPersons = async (projectId, pmId, Data) => {
    try {
      await updatePMCP(projectId, pmId, Data);
      let data = await refetchSections('propertyManagement');
      return data;
    } catch (error) {
      throw error;
    }
  };

  const updateLeaseSales = async (data) => {
    try {
      await updateDetails('leasesales', data);
    } catch (error) {
      throw error;
    }
  };

  const updateClosing = async (data) => {
    try {
      await updateDetails('closing', data);
    } catch (error) {
      throw error;
    }
  };
  const updateClosingDetails = async (projectId, closingId, data) => {
    // new one for closing contract
    try {
      // await UpdateClosingContract(projectId, closingId, data);
      await refetchSections('closing', data);
    } catch (error) {
      throw error;
    }
  };

  const updateProjectType = async (data) => {
    try {
      await updateDetails('projectType', data);
    } catch (error) {
      throw error;
    }
  };

  const projectMoveToNew = async ({ documents }, contract, investments) => {
    try {
      await updateContract(contract);
      await updateProjectInvestments(investments);
      await uploadDocuments(documents);
      await updateProjectType({
        id: projectId,
        projectType: 'newproject',
        previousProjectType: 'newproject',
      });
    } catch (error) {
      throw error;
    }
  };

  const projectMoveToOngoing = async ({ documents }, closing) => {
    try {
      await updateClosing(closing);
      await uploadDocuments(documents);
      await updateProjectType({
        id: projectId,
        projectType: 'ongoing',
        previousProjectType: 'ongoing',
      });
    } catch (error) {
      throw error;
    }
  };

  const projectMoveToOnHold = async (reason) => {
    try {
      await updateProjectType({
        id: projectId,
        projectType: 'onhold',
        reason,
      });
    } catch (error) {
      throw error;
    }
  };

  const projectMoveToCancelled = async (reason) => {
    try {
      await updateProjectType({
        id: projectId,
        projectType: 'cancelled',
        reason,
      });
    } catch (error) {
      throw error;
    }
  };

  const projectMoveToPreviousType = async () => {
    try {
      await updateProjectType({
        id: projectId,
        projectType: state.basic.previousProjectType,
        reason: '',
      });
    } catch (error) {
      throw error;
    }
  };

  async function updateDetails(section, data) {
    try {
      switch (section) {
        case 'basic':
          await projectUpdateLeadInformation(projectId, { id: projectId, ...data });
          await refetchSections('basic');
          break;
        case 'location':
          await projectLeadLocationInformation(projectId, data);
          await refetchSections('location');
          break;
        case 'documents/upload':
          data.append('projectId', projectId);
          const responseMessage = await projectLeadDocumentInformation(projectId, data);
          await refetchSections('documents');
          return responseMessage;
        case 'documents/delete':
          const success = await projectDocumentDelete(data);
          if (success) {
            dispatchDocumentDelete(data);
          }
          break;
        case 'members/add':
          const members = data;
          const memberRequests = members.map((member) => {
            const memberData = {
              ...member,
              roles: member.roles.map((role) => role.name),
            };
            return projectLeadMemberInformation(projectId, memberData);
          });
          await Promise.all(memberRequests);
          await refetchSections('members');
          break;
        case 'members/updateRole':
          const member = data[0];
          const memberData = {
            ...member,
            roles: member.roles.map((role) => role.name),
          };
          await projectLeadMemberUpdateRole(projectId, memberData);
          await refetchSections('members');
          break;
        case 'member/delete':
          const requestData = {
            userId: data.userId,
            token: data.token,
          };
          await projectLeadMemberDelete(projectId, requestData);
          dispatchMemberDelete(data.email);
          break;
        case 'contract':
          await projectCreateUpdateContractDetails(projectId, data);
          await refetchSections('contract');
          break;
        case 'LLC':
          await projectLeadLLCInformation(projectId, data);
          await refetchSections('llc');
          break;
        case 'investments':
          await projectCreateUpdateInvestments(projectId, data);
          await refetchSections('investments');
          break;
        case 'closing':
          await projectCreateClosingContract(projectId, { projectId: projectId, ...data });
          await refetchSections('closing');
          break;
        case 'expenses':
          await projectExpenseCreateUpdate(projectId, { projectId: projectId, ...data });
          await refetchSections('expenses');
          break;
        case 'leasesales':
          if (data.buildingId) {
            const unitsData = data.units.map((unit) => ({
              unitName: unit.unitName,
              space: String(unit.space),
            }));
            await projectBuildingUnitsCreateUpdate(projectId, data.buildingId, unitsData);
          } else {
            await projectBuildingCreateUpdate(projectId, { projectId: projectId, ...data });
          }
          await refetchSections('leasesales');
          break;
        case 'generalContract':
          await projectGCCreate(projectId, { projectId: projectId, ...data });
          await refetchSections('generalContract');
          break;
        case 'propertyManagement':
          await createPM(projectId, { projectId: projectId, ...data });
          await refetchSections('propertyManagement');
          break;
        case 'constructionLender':
          await createCL(projectId, { projectId: projectId, ...data });
          await refetchSections('constructionLender');
          break;
        case 'projectType':
          await projectUpdateType(projectId, data);
          await refetchSections('basic');
          await reduxDispatch(fetchProjectsCounts()).unwrap();
          break;
        default:
          break;
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <ProjectContext.Provider
      value={{
        ...state,
        projectType,
        previousProjectType,
        isLead,
        isCancelled,
        isOnHoldOrCancelled,
        updateDetailsBasic,
        updateDetailsLocation,
        uploadDocuments,
        deleteDocument,
        addMembers,
        updateMemberRole,
        deleteMember,
        respondToRequest,
        updateContract,
        updateLLC,
        updateProjectInvestments,
        sendInvestmentsRequest,
        sendInvestmentPayment,
        updateExpenses,
        updateLeaseSales,
        addExpenses,
        updateExpense,
        getAllGCDetails,
        updateGC,
        updateRowGCDetails,
        addGcChangeOrders,
        addLeaseDetails,
        addSaleDetails,
        addGcPayments,
        addGCManagerSiteManager,
        updateChangeOrderDetails,
        updateManagerDetails,
        updatePaymentDetails,
        createPMDetails,
        updatePMDetails,
        getSinglePropertyManagement,
        addPMcontactPersons,
        updatePMcontactPersons,
        createConstructionLender,
        updateConstructionLender,
        addCLLoanWithdrawal,
        addCLPaymentDetails,
        updateCLPaymentDetails,
        updateCLLoanWithdrawals,
        updateClosing,
        getGCDetails,
        projectMoveToNew,
        updateClosingDetails,
        projectMoveToOngoing,
        projectMoveToOnHold,
        projectMoveToCancelled,
        projectMoveToPreviousType,
      }}
    >
      {state.basic ? children : <Loading />}
    </ProjectContext.Provider>
  );
};

export const useProject = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) throw new Error('Project context was used outside of provider');
  return context;
};

export default ProjectProvider;
