import { TableHead, TableRow, TableCell, TableBody, Box, Collapse, Icon } from '@mui/material';
import { Span, StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { SideNavMenu } from 'app/components';
import GeneralContractorView from './GeneralContractorView';
const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>S.No.</TableCell>
      <TableCell>Contract Name</TableCell>
      <TableCell>Email Address</TableCell>
      <TableCell>Phone Number</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function GeneralContractorTable({ generalContractors }) {
  const [selectedContractorIndex, setSelectedContractorIndex] = React.useState(null);

  const handleSelectGC = (contractorIndex) => setSelectedContractorIndex(contractorIndex);

  const selectedContractor = generalContractors?.[selectedContractorIndex];
  return selectedContractor ? (
    <GeneralContractorView
      contractor={selectedContractor}
      handleBack={() => handleSelectGC(null)}
    />
  ) : (
    <TableWrapper>
      <StyledTable>
        <TableHeader />
        <TableBody>
          {generalContractors &&
            generalContractors.map((contractor, index) => {
              return (
                <>
                  <StyledTableRow key={contractor.id}>
                    <TableCell
                      align='left'
                      sx={{
                        px: 2,
                        textTransform: 'capitalize',
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                    >
                      {`${(index + 1).toString().padStart(2, '0')}`}
                    </TableCell>
                    {/* <TableCell>{expense?.totalAmount}</TableCell> */}
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {contractor.contractorDetails?.contractName}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {contractor.contractorDetails?.emailId}
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 0,
                        fontWeight: 500,
                        fontSize: '14px',
                        color: 'var(--tableBody-row-color)',
                      }}
                      align='left'
                    >
                      {contractor.contractorDetails?.phoneNum}
                    </TableCell>
                    <TableCell align='left' sx={{ color: 'var(--tableBody-row-color)' }}>
                      <SideNavMenu
                        menuButton={
                          <UserMenu>
                            <MoreVertIcon />
                          </UserMenu>
                        }
                      >
                        <StyledItem onClick={() => handleSelectGC(index)}>
                          <Icon>
                            <VisibilityIcon />
                          </Icon>
                          <Span>View</Span>
                        </StyledItem>
                      </SideNavMenu>
                    </TableCell>
                  </StyledTableRow>
                </>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
}

export default GeneralContractorTable;
