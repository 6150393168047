import { TableHead, TableRow, TableCell, TableBody, Icon, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Span, StyledTable, StyledTableRow, TableWrapper } from 'app/common/Typography';
import { useMemo, useRef, useState } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import { SideNavMenu } from 'app/components';
import { StyledItem, UserMenu } from 'app/components/Layout/BasicLayout/StyledComponent';
import GCChangeOrderAddEdit from './GCChangeOrderAddEdit';
import { documentTypes } from 'app/utils/constant';
import { useProject } from 'app/contexts/ProjectContext';
import { useProjectDocuments } from '../Documents/hooks/useProjectDocuments';
import { DownloadIcon } from 'app/common/icons';
const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>S.no.</TableCell>
      <TableCell>Change Order Name</TableCell>
      <TableCell>Initial Estimates</TableCell>
      <TableCell>Cost</TableCell>
      <TableCell>Document</TableCell>
      <TableCell align='right' style={{ width: 48 }}></TableCell>
    </TableRow>
  </TableHead>
);

function GCChangeOrderViewTable({ contractor, changeorders }) {
  const { projectId } = useProject();
   const { data: documents } = useProjectDocuments(projectId);
   const [isOpenDialog, setIsOpenDialog] = useState(false);
   const selectedChangeOrder = useRef(null);
 
   const changeOrderDocuments = useMemo(
     () => documents?.filter((doc) => doc.metadata.type === documentTypes.GC_CHANGE_ORDERS),
     [documents],
   );
 
   const toggleDialog = () => setIsOpenDialog((prevState) => !prevState);
 
   const handleClickUpdate = (changeOrder) => {
     selectedChangeOrder.current = changeOrder;
     toggleDialog();
   };
 
   const handleChangeOrderDetailsdownload = (initialDocuments, CODetail) => {
     try {
       initialDocuments.forEach((doc) => {
         if (CODetail.documentIds?.includes(doc.metadata.fileId)) {
           handleDownload(doc.content, doc.metadata.documentName, doc.metadata.documentType);
         }
       });
     } catch (error) {
       console.error('Invoice Download Failed', error);
     }
   };
 
   const handleDownload = (content, fileName, mimeType) => {
     try {
       const byteCharacters = atob(content);
       const byteNumbers = new Array(byteCharacters.length);
       for (let i = 0; i < byteCharacters.length; i++) {
         byteNumbers[i] = byteCharacters.charCodeAt(i);
       }
       const byteArray = new Uint8Array(byteNumbers);
       const blob = new Blob([byteArray], { type: mimeType });
 
       const link = document.createElement('a');
       link.href = URL.createObjectURL(blob);
       link.download = fileName;
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
     } catch (error) {
       console.error('Download failed:', error);
     }
   };
  return (
    <>
      <TableWrapper>
        <StyledTable>
          <TableHeader />
          <TableBody>
            {changeorders &&
              changeorders.map((changeOrder, index) => {
                return (
                  <>
                    <StyledTableRow key={changeOrder.id}>
                      <TableCell>{`${(index + 1).toString().padStart(2, '0')}`}</TableCell>
                      <TableCell>{changeOrder?.changeOrderName}</TableCell>
                      <TableCell>{changeOrder?.initialEstimate}</TableCell>
                      <TableCell>{changeOrder?.cost}</TableCell>
                      <TableCell
                        sx={{
                          px: 0,
                          fontWeight: 500,
                          fontSize: '14px',
                          color: 'var(--tableBody-row-color)',
                          display: 'flex',
                          marginTop: '1%',
                        }}
                        align='left'
                      >
                        <div style={{ marginTop: '2%' }}>
                          {changeOrderDocuments
                            ?.filter((doc) =>
                              changeOrder?.documentIds[0]?.includes(doc.metadata.fileId),
                            )
                            .map((doc) => (
                              <div key={doc.metadata.fileId}>{doc.metadata.documentName}</div>
                            ))}
                        </div>
                        <IconButton
                          onClick={() =>
                            handleChangeOrderDetailsdownload(changeOrderDocuments, changeOrder)
                          }
                        >
                          <DownloadIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ color: 'var(--tableBody-row-color)' }} align='right'>
                        <SideNavMenu
                          menuButton={
                            <UserMenu>
                              <MoreVertIcon />
                            </UserMenu>
                          }
                        >
                          <StyledItem onClick={() => handleClickUpdate(changeOrder)}>
                            <Icon>
                              <UpdateIcon />
                            </Icon>
                            <Span>Update</Span>
                          </StyledItem>
                        </SideNavMenu>
                      </TableCell>
                    </StyledTableRow>
                  </>
                );
              })}
          </TableBody>
        </StyledTable>
      </TableWrapper>
      {isOpenDialog && (
        <GCChangeOrderAddEdit
          contractor={contractor}
          changeOrder={selectedChangeOrder.current}
          changeOrderDocuments={changeOrderDocuments}
          closeDialog={toggleDialog}
        />
      )}
    </>
  );
}

export default GCChangeOrderViewTable;
