import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  IconButton,
  styled,
  Typography,
  Button,
  Input,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  Modal,
} from '@mui/material';
import { Formik, FieldArray, Form } from 'formik';
import {
  Title,
  CustomGCExpensesInputText,
  StyledTextField,
  StyledBoxFooter,
  ModalCancel,
  ModalSave,
  ModalDivider,
  ModalStyledBox,
  ModalStyledSubBox,
  ModalContainer,
} from 'app/common/Typography';
import { DownloadDragIcon, PdfIcon, DocIcon } from 'app/common/icons';
import { useProject } from 'app/contexts/ProjectContext';
import { paymentOptions, expensesTypes, documentTypes } from 'app/utils/constant';
import { validationSchemas } from 'app/utils/validationSchemas';
import useProjectExpenseCreateUpdate from './hooks/useProjectExpenseCreateUpdate';
import { base64ToFile } from 'app/utils/helpers';
import { StyledLabel } from '../../listings/Modals/StyledComponents';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  '&:hover': {
    border: 1,
    backgroundColor: 'light red',
  },
}));

const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '3px',
});

const FileContainer = styled('div')({
  alignItems: 'center',
  padding: '8px',
  border: '1px solid var(--fileContainer-border)',
  backgroundColor: 'var(--fileContainer-bg)',
  borderRadius: '4px',
  marginTop: '10px',
});

const FileContainerDetails = styled('div')({
  maxHeight: '22vh',
  overflowY: 'auto',
  width: '100%',
});

const FileDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '8px',
  flexGrow: 1,
});

const AddUpdateExpensesDialog = ({ selectedExpense, expensesDocuments = [], closeDialog }) => {
  const { projectId } = useProject();
  const { mutate: createUpdateExpense } = useProjectExpenseCreateUpdate(projectId, selectedExpense);

  const isEditingMode = !!selectedExpense;

  const latestPaymentDetails = selectedExpense?.paymentDetails?.at(-1);

  const initialValues = {
    expensesName: latestPaymentDetails?.expensesName,
    totalAmount: selectedExpense?.totalAmount,
    paidAmount: latestPaymentDetails?.paidAmount,
    balance: selectedExpense?.balance,
    invoiceNo: selectedExpense?.invoiceNo,
    expenseType: latestPaymentDetails?.expenseType,
    paidTo: latestPaymentDetails?.paidTo,
    modeOfPayment: latestPaymentDetails?.modeOfPayment,
    documents:
    expensesDocuments
    .filter((doc) =>
      latestPaymentDetails?.paymentDocuments?.length > 0 &&
      latestPaymentDetails.paymentDocuments.includes(doc.metadata.fileId)
    )
    .map((doc) => ({
      file: base64ToFile(doc.content, doc.metadata.documentName, doc.metadata.documentType),
      docName: doc.metadata.documentName,
      type: doc.metadata.type,
    })) || [],
    comments: latestPaymentDetails?.comments,
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    const document = values.documents[0];
    const isDocumentSameAsPrevious = initialValues?.documents?.[0]?.docName === document.docName;
    const previousDocumentID = latestPaymentDetails?.paymentDocuments?.[0];

    createUpdateExpense(
      {
        data: values,
        isDocumentSameAsPrevious,
        previousDocumentID,
      },
      {
        onSuccess: closeDialog,
        onSettled: () => setSubmitting(false),
      },
    );
  };

  return (
    <Modal open={true} onClose={closeDialog}>
      <ModalStyledBox>
        <ModalStyledSubBox>
          <Title>{isEditingMode ? 'Update' : 'Add'} Expenses</Title>
          <IconButton onClick={closeDialog}>
            <CloseIcon
              style={{
                fontSize: 20,
                color: 'var(--closeIcon)',
              }}
            />
          </IconButton>
        </ModalStyledSubBox>
        <ModalDivider />
        <Box
          sx={{
            p: 2,
            pt: 0,
            marginLeft: '4px',
            overflowY: 'auto',
            maxHeight: 'calc(100% - 75px)',
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemas.expenses()}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
            }) => {
              const handleFileInput = (event) => {
                event.preventDefault();
                if (isSubmitting) return;

                const fileAccess = {
                  change: event.target.files,
                  drop: event.dataTransfer?.files,
                };

                const inputFile = fileAccess[event.type];

                const newDocument = Array.from(inputFile).map((file) => ({
                  file,
                  docName: '',
                  type: documentTypes.EXPENSES,
                }));

                setFieldValue('documents', [...values.documents, ...newDocument]);
              };

              const handleDragOver = (event) => event.preventDefault();

              return (
                <Form>
                  <ModalContainer>
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <StyledLabel>Invoice Number</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={
                            Boolean(initialValues.invoiceNo) && {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                          }
                          name='invoiceNo'
                          value={values.invoiceNo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.invoiceNo && errors.invoiceNo)}
                          helperText={touched.invoiceNo && errors.invoiceNo}
                          InputProps={{
                            readOnly: Boolean(initialValues.invoiceNo),
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledLabel>Expense Name</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          type='text'
                          name='expensesName'
                          value={values.expensesName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.expensesName && errors.expensesName)}
                          helperText={touched.expensesName && errors.expensesName}
                          InputProps={{
                            readOnly: Boolean(initialValues.expensesName),
                            sx: Boolean(initialValues.expensesName) && {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            },
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>

                    <Grid item xs={6}>
                        <StyledLabel>Total Amount</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={
                            Boolean(initialValues.totalAmount) && {
                              fontWeight: 'bold',
                              backgroundColor: '#f0f0f0f0',
                            }
                          }
                          type='number'
                          name='totalAmount'
                          value={values.totalAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.totalAmount && errors.totalAmount)}
                          helperText={touched.totalAmount && errors.totalAmount}
                          InputProps={{
                            min: 0,
                            readOnly: Boolean(initialValues.totalAmount),
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledLabel>Paid Amount</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          name='paidAmount'
                          value={values.paidAmount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.paidAmount && errors.paidAmount)}
                          helperText={touched.paidAmount && errors.paidAmount}
                          InputProps={{ min: 0 }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      
                    <Grid item xs={6}>
                        <StyledLabel>Balance Amount</StyledLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          sx={{
                            fontWeight: 'bold',
                            backgroundColor: '#f0f0f0f0',
                          }}
                          type='number'
                          name='balance'
                          value={
                            isEditingMode ? values?.balance : values.totalAmount - values.paidAmount
                          }
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          error={touched.expenseType && Boolean(errors.expenseType)}
                        >
                          <StyledLabel htmlFor='expenseTypeInput'>Expense Type</StyledLabel>
                          <Select
                            fullWidth
                            id='expenseTypeInput'
                            size='small'
                            name='expenseType'
                            value={values.expenseType || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={Boolean(initialValues.expenseType) || isSubmitting}
                            sx={{
                              ...(Boolean(initialValues.expenseType) && {
                                fontWeight: 'bold',
                                backgroundColor: '#f0f0f0f0',
                                pointerEvents: 'none',
                              }),
                            }}
                          >
                            {expensesTypes.map(({ label }) => (
                              <MenuItem key={label} value={label}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.expenseType && errors.expenseType && (
                            <FormHelperText>{errors.expenseType}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: '1px' }} container spacing={2}>
                      <Grid item xs={6}>
                        <StyledLabel>Paid To</StyledLabel>
                        <StyledTextField
                          fullWidth
                          size='small'
                          variant='outlined'
                          type='text'
                          name='paidTo'
                          value={values.paidTo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.paidTo && errors.paidTo)}
                          helperText={touched.paidTo && errors.paidTo}
                          InputProps={{ min: 0 }}
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          error={touched.modeOfPayment && Boolean(errors.modeOfPayment)}
                        >
                          <StyledLabel htmlFor='modeOfPaymentInput'>Mode Of Payment</StyledLabel>
                          <Select
                            fullWidth
                            size='small'
                            id='modeOfPaymentInput'
                            name='modeOfPayment'
                            value={values.modeOfPayment || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={isSubmitting}
                          >
                            {paymentOptions.map(({ label }) => (
                              <MenuItem key={label} value={label}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.modeOfPayment && errors.modeOfPayment && (
                            <FormHelperText>{errors.modeOfPayment}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </ModalContainer>
                  <Typography style={{ fontSize: '15px', fontWeight: '450', marginTop: '8px' }}>
                    Expense Document
                  </Typography>
                  <FormControl fullWidth error={touched.documents && Boolean(errors.documents)}>
                    <ModalContainer
                      style={{ border: '2px dashed lightgray', height: '0.8%', width: '97%' }}
                      onDragOver={handleDragOver}
                      onDrop={handleFileInput}
                    >
                      <Typography variant='body1' color={'var(--drag-Drop-Text)'} fontSize={'1em'}>
                        <DownloadDragIcon />
                        {` Drag and Drop file here or `}
                        <label htmlFor='outlined-button-file'>
                          {/* <StyledButton
                            style={{
                              fontSize: '1em',
                            }}
                            disabled={values.documents.length > 0}
                            variant='outlined'
                            component='span'
                          >
                            Browse files
                          </StyledButton> */}
                          <StyledButton
                            disabled={values.documents.length > 0}
                            variant='outlined'
                            component='span'
                            sx={{
                              textTransform: 'none',
                              textDecoration: 'underline',
                              color: '#282A2D',
                            }}
                          >
                            Click to upload
                          </StyledButton>
                        </label>
                      </Typography>
                      <Input
                        id='outlined-button-file'
                        key={values.documents.length}
                        size='small'
                        sx={{
                          display: 'none',
                        }}
                        type='file'
                        name='file'
                        onChange={handleFileInput}
                        inputProps={{ accept: '.pdf, .doc, .docx' }}
                        disabled={isSubmitting || values.documents.length > 0}
                      />
                    </ModalContainer>
                    <Typography
                      variant='body1'
                      color={'var(--fileFormate-Text)'}
                      fontSize={'12px'}
                      pt={2}
                    >
                      File format only Word or PDF, Size should not exceed 10MB
                    </Typography>
                    <FileContainerDetails>
                      <FieldArray name='documents'>
                        {({ remove }) =>
                          values?.documents?.map((doc, index) => (
                            <FileContainer key={index}>
                              <ImageContainer>
                                {doc.file?.type === 'application/pdf' ? <PdfIcon /> : <DocIcon />}
                                <FileDetails>
                                  <Typography
                                    variant='body1'
                                    style={{ fontWeight: 'bold', marginLeft: '10px' }}
                                  >
                                    {doc.file?.name}
                                  </Typography>
                                  <Typography variant='caption' style={{ marginLeft: '10px' }}>
                                    {doc.file ? `${(doc.file?.size / 1024).toFixed(2)} KB` : ''}
                                  </Typography>
                                </FileDetails>
                                <IconButton onClick={() => remove(index)} disabled={isSubmitting}>
                                  <DeleteIcon style={{ color: 'var(--deleteIcon)' }} />
                                </IconButton>
                              </ImageContainer>
                              <ModalDivider />
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <CustomGCExpensesInputText
                                    placeholder='Document Name'
                                    name={`documents[${index}].docName`}
                                    // value={doc.docName}
                                    value={values.documents[index]?.docName || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.documents?.[index]?.docName &&
                                        errors.documents?.[index]?.docName,
                                    )}
                                    helperText={
                                      touched.documents?.[index]?.docName &&
                                      errors.documents?.[index]?.docName
                                    }
                                    disabled={isSubmitting}
                                  />
                                </Grid>
                              </Grid>
                            </FileContainer>
                          ))
                        }
                      </FieldArray>
                      {touched?.documents && errors?.documents && (
                        <FormHelperText>
                          {!Array.isArray(errors.documents) && errors.documents}
                        </FormHelperText>
                      )}
                    </FileContainerDetails>
                  </FormControl>
                  <Grid item xs={12}>
                    <StyledLabel>Comments</StyledLabel>
                    <StyledTextField
                      rows={2}
                      multiline
                      fullWidth
                      size='small'
                      variant='outlined'
                      sx={{ mb: 1, mt: 1 }}
                      type='text'
                      name='comments'
                      value={values.comments}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <StyledBoxFooter>
                    <ModalCancel
                      className='cancelButton'
                      variant='outlined'
                      onClick={closeDialog}
                      style={{ boxShadow: 'none' }}
                      disabled={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </ModalCancel>
                    <ModalSave
                      variant='contained'
                      color='primary'
                      style={{ boxShadow: 'none', marginRight: '10px' }}
                      type='submit'
                      loading={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      {isEditingMode ? 'Update' : 'Save'}
                    </ModalSave>
                  </StyledBoxFooter>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalStyledBox>
    </Modal>
  );
};
export default AddUpdateExpensesDialog;
