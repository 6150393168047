import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Modal, styled, Typography } from '@mui/material';
import { Title } from 'app/common/Typography';
import { useProject } from 'app/contexts/ProjectContext';
import { handleAxiosError } from 'app/utils/helpers';
import { useState } from 'react';

const SubtitleDivider = styled('div')({
  height: '3px',
  backgroundColor: '#4F90F033',
});

const ModalDivider = styled('div')({
  height: '1px',
  backgroundColor: '#D5D5DD',
});

// REVIEW: check to make it reusable along with modals which are used when projectType changed
const MemberDeleteConfirmation = ({ member, closeModal }) => {
  const {
    basic: { projectTitle },
    deleteMember,
  } = useProject();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleYesProceed = async () => {
    try {
      setIsSubmitting(true);
      await deleteMember(member);
      closeModal();
    } catch (error) {
      handleAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={true} onClose={closeModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '32%',
        }}
      >
        <SubtitleDivider />
        <Box
          sx={{
            py: 1,
            px: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Title>Member Delete</Title>
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>
        <ModalDivider />
        <Box
          sx={{
            marginLeft: '4px',
          }}
        >
          <Box sx={{ p: 2, paddingLeft: 3, paddingRight: 3 }}>
            <Typography style={{ fontSize: '16px', fontWeight: '450' }}>
              Are you sure want to delete member {member?.memberName} from the {projectTitle}?
            </Typography>
          </Box>

          <ModalDivider />
          <Box
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
              gap: 2,
              p: 2,
            }}
          >
            <LoadingButton color='error' variant='outlined' onClick={closeModal}>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant='contained'
              color='primary'
              onClick={handleYesProceed}
              loading={isSubmitting}
            >
              Yes Proceed
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default MemberDeleteConfirmation;
