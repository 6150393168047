import { styled } from '@mui/material';
import { Fragment } from 'react';
import NavigationHeader from 'app/common/navigationHeader';
import StepperForm from 'app/common/stepperForm';
import { SimpleCard } from 'app/components';
import { CenteredText } from 'app/common/Typography';
const ContentBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const NewLead = () => {
  return (
    <Fragment>
      <NavigationHeader
        project='Projects'
        lead='Leads'
        showNewLeadButton={false}
        showLead={true}
        customLeadName='New Lead'
      />
      <ContentBox className='analytics' style={{ padding: '10px' }}>
        <CenteredText>
          <SimpleCard
            className='form-1'
            sx={{ border: '1px solid grey', borderRadius: '8px', p: '16px', bgcolor: 'white' }}
          >
            <StepperForm />
          </SimpleCard>
        </CenteredText>
      </ContentBox>
    </Fragment>
  );
};

export default NewLead;
